import React from "react";

import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { ModelCollection } from "@/library/model";
import {
  attachAssignedUserName,
  attachParentEntities,
} from "@/domain/patient/redux/middleware";
import { RemainingDaysChart } from "./charts/remainingDaysChart";
import { RpmChartComponent } from "./charts/rpmChart";
import { UserDashboardForm } from "./form";
import UserDashboardTable from "./table";

import styles from "./styles.module.scss";
import cx from "clsx";

type Props = {};

type State = {
  patients: Patient[];
};

export class DashboardPage extends React.Component<Props, State> {
  state = {
    patients: [],
  };

  setPatientsState = async (
    patientCollection: ModelCollection<PatientModel, Patient>
  ) => {
    const patientsWithAssignedUser = await attachAssignedUserName(
      patientCollection.container.map((patient) => patient.pluckAll())
    );
    const patientWithParentEntities = await attachParentEntities(
      patientsWithAssignedUser
    );
    this.setState(() => ({
      patients: patientWithParentEntities,
    }));
  };

  render() {
    return (
      <div className="card-body p-0">
        <div className={cx(styles.dashboardContent, "row")}>
          <div className="col-md-12">
            <UserDashboardForm
              filteredPatients={this.state.patients}
              setPatientsState={this.setPatientsState}
            />
          </div>

          <div className="col-md-12 mt-34 px-5">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <h6>Distribution of Patients by Observations Completed</h6>
                  <hr />
                </div>
                <div className="charts-area">
                  <RemainingDaysChart patients={this.state.patients} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <h6>Distribution of Patients by RPM Minutes</h6>
                  <hr />
                </div>
                <div className="charts-area">
                  <RpmChartComponent patients={this.state.patients} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-34  px-5">
            <UserDashboardTable patients={this.state.patients} />
          </div>
        </div>
      </div>
    );
  }
}
