import { PatientTab } from "./types";

export const PatientTabs = {
  Chart: PatientTab.Chart as PatientTab,
  Monitoring: PatientTab.Monitoring as PatientTab,
  Thresholds: PatientTab.Thresholds as PatientTab,
  Parameters: PatientTab.Parameters as PatientTab,
  Pathways: PatientTab.Pathways as PatientTab,
  Billing: PatientTab.Billing as PatientTab,
  Audit: PatientTab.Audit as PatientTab,
  Equipment: PatientTab.Equipment as PatientTab,
  Profile: PatientTab.Profile as PatientTab,
  Summary: PatientTab.Summary as PatientTab,
};

export const PatientDetailTabs: Array<PatientTab> = [
  PatientTab.Profile,
  PatientTab.Monitoring,
  PatientTab.Chart,
  PatientTab.Parameters,
  PatientTab.Pathways,
  PatientTab.Billing,
  PatientTab.Summary,
  PatientTab.Audit,
  PatientTab.Equipment,
];
