import { Serializeable, Serializer } from "./../serializer";
import { RCAResourceActions } from "../../core/config/actions";
import { ProviderForm, ProviderModel } from "../../../domain/provider/model";
import { CreateProviderSerializer, ModifyProviderSerializer } from "./";

/**
 * Provider domain serializer. It holds information about all serializers in this domain.
 */
export class ProviderSerializer extends Serializer<
  ProviderForm,
  ProviderModel
> {
  static for(
    action: RCAResourceActions,
    from: ProviderForm
  ): ProviderSerializer {
    return new ProviderSerializer(action, from);
  }

  serialize(): ProviderModel {
    const serializer = new (this.get(this.action))(this.formData);
    return serializer.serialize();
  }

  /**
   * Map of currently supported resource actions to their respective serializers.
   * @protected
   */
  protected serializers = {
    [RCAResourceActions.Create]: CreateProviderSerializer,
    [RCAResourceActions.ModifyBasicInfo]: ModifyProviderSerializer,
  };
}
