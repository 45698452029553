import { useEffect, useState } from "react";

import { Modal } from "@/components/modal";
import { Patient } from "@/domain/patient/model/types";
import AssessmentNoteForm from "@/domain/patient/view/forms/AssessmentNoteForm";
import { ClinicalNoteForm } from "@/domain/patient/view/forms/ClinicalNoteForm";
import ObservationNoteForm from "@/domain/patient/view/forms/ObservationNoteForm";
import { NoteAction } from "@/domain/notes/model/types";
import styles from "./styles.module.scss";

export enum NoteFormType {
  ClinicalNote = "ClinicalNote",
  ObservationNote = "ObservationNote",
  AssessmentNote = "AssessmentNote",
}

interface Props {
  show: boolean;
  patient: Patient;
  handleClose: () => void;
  formType: NoteFormType;
  includeNoteTimer?: boolean;
}

export const CreateNoteModal: React.FC<Props> = (props) => {
  const { show, patient, handleClose, formType } = props;
  const [formik, setFormik]: any = useState();

  const handleCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    setFormik(undefined);
  }, [show]);

  /**
   * Commons props for all modals
   */
  const modalProps = {
    show,
    handleClose: handleCloseModal,
    button: "save",
    actionButton: {
      formik,
      name: "Create",
    },
    heading: "Create a Note",
    className: styles.modal,
  };

  /**
   * Common props for note forms
   */
  const formProps = {
    patient,
    handleClose: handleCloseModal,
    replyToIds: [],
    onSetFormik: (formikInstance: any) => {
      if (!formik) {
        setFormik(formikInstance);
      }
    },
    updateParentNoteInReduxIfChild: () => {},
    includeTimer: props.includeNoteTimer,
  };

  /**
   * Clinical Note Form
   */
  if (formType === NoteFormType.ClinicalNote) {
    return (
      <Modal {...modalProps} description={NoteAction.CreateClinicalNote}>
        {show && <ClinicalNoteForm {...formProps} />}
      </Modal>
    );
  }

  /**
   * Observation Note Form
   */
  if (formType === NoteFormType.ObservationNote) {
    return (
      <Modal {...modalProps} description={NoteAction.CreateObservationNote}>
        {show && <ObservationNoteForm {...formProps} />}
      </Modal>
    );
  }

  /**
   * Assessment Note Form
   */
  if (formType === NoteFormType.AssessmentNote) {
    return (
      <Modal {...modalProps} description={NoteAction.CreateAssessmentNote}>
        {show && <AssessmentNoteForm {...formProps} />}
      </Modal>
    );
  }

  return <></>;
};
