import React from "react";
import { useSelector } from "react-redux";
import cx from "clsx";

import { UserParentTypes } from "@/domain/user/model";
import { Guard } from "@/library/guard/Guard";
import { OrganizationDropdownComponent } from "@/components/dropdown/organizationDropdown.component";
import { selectCurrentUser } from "@/domain/user/redux/selectors";
import { Organization } from "@/domain/organization/model/types";
import PracticeFilter from "@/domain/patient/view/filters/practice.filter";
import ProviderFilter from "@/domain/patient/view/filters/provider.filter";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

interface Props {
  headerTitle: string;

  organizationFilter?: {
    enabled: boolean;
    selectedOrganization: Organization | undefined;
    onSelectOrganization: (organizationId: string) => void;
  };

  practiceFilter?: {
    enabled: boolean;
    onSelectPractice: (practiceIds: string[]) => void;
    selectedPractice: string[] | undefined;
  };

  providerFilter?: {
    enabled: boolean;
    onSelectProvider: (providerIds: string[], descriptions: string[]) => void;
    selectedProviders: string[] | undefined;
  };

  entityButton?: {
    name: string;
    onClick: (withArgs?: any) => {};
  };
}

export const OrganizationFilters: React.FC<Props> = (props) => {
  const {
    organizationFilter,
    practiceFilter,
    providerFilter,
    entityButton,
    headerTitle,
  } = props;
  const currentUser = useSelector(selectCurrentUser);

  const isAdmin = Guard.accessLevel(UserParentTypes.Admin).canActivate(
    currentUser!
  );
  const shouldRenderOrganizationFilter = isAdmin && organizationFilter;

  return (
    <nav
      className={cx(
        "navbar navbar-expand-lg navbar-light sticky-top bg-white shadow-sm  mb-2",
        styles.navbar
      )}
    >
      <h6 className={globalStyles.pageHeading}>{headerTitle}</h6>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarText" role="tablist">
        <ul
          className="navbar-nav mx-auto"
          id="pills-tab"
          style={{ fontSize: "15px" }}
        >
          <li className="nav-item px-3">
            <a className="nav-link">
              {shouldRenderOrganizationFilter && (
                <OrganizationDropdownComponent
                  currentOrganization={
                    organizationFilter?.selectedOrganization?.name
                  }
                  onChange={async (value: string) => {
                    organizationFilter?.onSelectOrganization(value);
                  }}
                />
              )}
            </a>
          </li>
          {practiceFilter && (
            <li className="nav-item px-3">
              <a className="nav-link">
                <PracticeFilter
                  onChange={practiceFilter.onSelectPractice}
                  selectedPracticeIds={practiceFilter.selectedPractice}
                />
              </a>
            </li>
          )}
          {providerFilter && (
            <li className="nav-item px-3">
              <a className="nav-link">
                <ProviderFilter
                  onChange={providerFilter.onSelectProvider}
                  selectedProviderIds={providerFilter.selectedProviders}
                />
              </a>
            </li>
          )}
        </ul>
        {entityButton && (
          <form className="form-inline my-2 my-lg-0">
            <button
              type="button"
              onClick={entityButton.onClick}
              className="btn btn-sm  btn-glow-primary btn-primary rca-btn-sm"
            >
              <span className="text-rca">{entityButton.name}</span>
            </button>
          </form>
        )}
      </div>
    </nav>
  );
};
