import { Patient } from "@/domain/patient/model/types";
import { ManageState } from "@/domain/patient/view/form/manageState";
import { PatientState } from "@/domain/patient/view/form/manageState/types";

interface Props {
  patient: Patient;
  handleReturnKitClick: () => void;
  onSelectStatus: (status: PatientState | undefined) => void;
  onDisableAcceptBtn: (val: boolean) => void;
  onShowConfirmModal: (val: boolean) => void;
}

export const Status: React.FC<Props> = (props) => {
  return (
    <>
      <ManageState
        buttonText={""}
        type="dropdown"
        targetedPatient={props.patient}
        onSelectStatus={props.onSelectStatus}
        onDisableAcceptBtn={props.onDisableAcceptBtn}
        setReturnKitModal={props.handleReturnKitClick}
        onShowConfirmModal={props.onShowConfirmModal}
      />
    </>
  );
};

export default Status;
