import { bloodOxygenLevel } from "@/assets/icons/observations";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import {
  getBannerColorByLastObservation,
  getBannerColorBySeverityScore,
  STALE_READING_TOOLTIP_MESSAGE,
} from "@/domain/patient/view/PatientBanners/constants";
import { dateFormatter } from "@/pipes/date";
import { truncateObservationValue } from "@/domain/observations/helpers";
import { ObservationType } from "@/domain/observations/types";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";

import { PatientState } from "@/library/types/patientState";

import styles from "./styles.module.scss";
import { AlertLevel } from "@/domain/notes/model/types";

export type OwnProps = {
  patient: Patient;
  patientHealth: HealthSnapshot;
  isLifecycleStateInactiveOrCompleted: Function;
};
export type Props = OwnProps & Partial<IconContainerProps>;

export const BloodOxygenLevel: React.FC<Props> = (props) => {
  const {
    patient,
    patientHealth,
    isLifecycleStateInactiveOrCompleted,
    ...rest
  } = props;
  const isObservationCompleted = () => {
    return (
      !!getBannerColorByLastObservation(
        patientHealth.observations.pulseOximetry?.time
      ) || patientHealth?.observations?.pulseOximetry?.isComplete
    );
  };
  const alertLevelByObservationTime = getBannerColorByLastObservation(
    patientHealth.observations.pulseOximetry?.time
  );
  const getBannerColor = () => {
    const alertLevelByTime = alertLevelByObservationTime;
    if (
      patientHealth?.observations?.pulseOximetry === undefined ||
      isLifecycleStateInactiveOrCompleted(patient?.lifecycle?.state)
    ) {
      return AlertLevel.DISABLED_ALERT;
    } else if (alertLevelByTime) {
      return alertLevelByTime;
    }
    return getBannerColorBySeverityScore(
      patientHealth?.observations?.pulseOximetry?.severityScore
    );
  };

  const reportCompliance =
    patient?.lifecycle?.state !== PatientState.Inactive &&
    patient?.lifecycle?.state !== PatientState.Completed &&
    alertLevelByObservationTime;

  const shouldCompleteObservation =
    !isLifecycleStateInactiveOrCompleted(patient?.lifecycle?.state) &&
    isObservationCompleted();

  return (
    <IconContainer
      {...rest}
      id={`obs-blood-oxygen-${patient?.id}`}
      src={bloodOxygenLevel}
      isObservationCompleted={shouldCompleteObservation}
      color={getBannerColor()}
      tooltipContent={
        reportCompliance
          ? STALE_READING_TOOLTIP_MESSAGE(
              patientHealth.observations.pulseOximetry?.time
            )
          : dateFormatter({
              date: patientHealth?.observations?.pulseOximetry
                ?.time as unknown as string,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })
      }
    >
      <div className="text-center">
        <p className={reportCompliance ? styles.whiteText : styles.lead}>
          {truncateObservationValue(
            ObservationType.PulseOximetry,
            patientHealth?.observations?.pulseOximetry?.observation?.value
          )}
        </p>
      </div>
    </IconContainer>
  );
};
