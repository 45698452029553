import {
  HeaderContent,
  SortBy,
  SortedState,
} from "@/components/table/head/sortable";

export const userDashboardTableHead: HeaderContent[] = [
  {
    name: "LAST NAME",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.LastName,
  },
  {
    name: "FIRST NAME",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.FirstName,
  },
  // {
  //   name: "program /n status",
  //   isSortable: true,
  //   sortedState: SortedState.NoSort,
  // },
  {
    name: "PATIENT ID",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "ENTITY / BRANCH",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "PROVIDER",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "CARE TEAM MEMBER",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "careTeam",
  },
  {
    name: "CPT99454/ /n DAYS LEFT",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "CPT99457/ /n CONTACTED",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "(1)",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "(2)",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "(3)",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
];
