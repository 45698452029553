import { Dispatch } from "redux";

import { setUsers } from "./";
import { User, UserModel } from "../model";
import { RoleModel } from "../../role/role.model";
import { UserPolicy } from "../../../library/policy/user.policy";
import { createUser } from "./user.actions";
import { OrganizationModel } from "../../organization/model";
import { PracticeModel } from "../../practice/model";
import { SetUserDispatch } from "../model/types";

/**
 * Outer synchronous function that receives the `userCollection` parameter:
 * @param usersCollection
 */
export function setUsersThunk(
  usersCollection: UserModel[]
): (dispatch: SetUserDispatch) => Promise<void> {
  // And then creates and returns the async thunk function:
  return async (dispatch: SetUserDispatch) => {
    if (!usersCollection.length) {
      dispatch(setUsers([]));
    }
    dispatch(setUsers(usersCollection.map((user) => user.attributes.getAll())));

    const roleIds = usersCollection
      .map((user) => getRoleIdFromUser(user.pluckAll()))
      .filter((roleId) => roleId);
    const roleModels: RoleModel[] = await RoleModel.syncMany(roleIds);

    // Now we can use the userModel value and intercept it before sending it to the action or reducer.
    const users = await Promise.all(
      usersCollection
        .map((user) => user.attributes.getAll())
        .map(async (user: User) => {
          const role: RoleModel | undefined = roleModels.find(
            (role) => role.pluck("id") === getRoleIdFromUser(user)
          );
          if (role) {
            user.roleName = role.pluck("name");
          }
          return user;
        })
    );
    dispatch(setUsers(users));
  };
}

/**
 * Outer synchronous function that receives the `user: User` parameter:
 * @param user
 */
export const createUserThunk = (user: User) => async (dispatch: Dispatch) => {
  const userWithRoleName = await attachUserRoleName(user);
  dispatch(
    createUser(await attachParentEntityAndClassification(userWithRoleName))
  );
};

/**
 * Attached role name to user
 * @param user
 * @returns
 */
export async function attachUserRoleName(user: User) {
  const policy = await UserPolicy.parse(user.policy);
  const role: RoleModel | null = await policy.anyScope.role();
  if (role) {
    user.roleName = role.pluck("name");
  }
  return user;
}

/**
 * Attaches the parent entity and classification to the user.
 * @param user - User to be updated
 * @returns - Promise<User> with attached parent entity and classification
 */
export const attachParentEntityAndClassification = async (
  user: User
): Promise<User> => {
  const { type, id } = user.parentEntities[0];

  if (type === "Organization") {
    const organizationModel: OrganizationModel = await OrganizationModel.sync(
      id
    );
    user.parentEntities[0].name = organizationModel.pluck("name");
    user.parentEntities[0].classification = organizationModel.pluck("type");
  } else {
    const practiceModel: PracticeModel = await PracticeModel.sync(id);
    user.parentEntities[0].name = practiceModel.pluck("name");
    user.parentEntities[0].classification = practiceModel.pluck("type");
  }

  return user;
};

/**
 * Parsing the user policy and plucking the role id
 * @param user
 * @returns user roleId
 */
export const getRoleIdFromUser = (user: User): string => {
  return UserPolicy.parseSync(user.policy).scope.getRoleId();
};
