import React, { forwardRef, useState } from "react";
import cx from "clsx";

import { Label } from "@/components/Label";
import { Props } from "./types";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    label,
    value,
    hasError,
    disabled,
    optional,
    required,
    onChange,
    readOnly,
    errorLabel,
    containerClassName,
    containerStyle,
    className,
    style,
    iconLeft,
    iconRight,
    ...rest
  } = props;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleMouseOver = () => setIsMouseOver(true);
  const handleMouseOut = () => setIsMouseOver(false);

  const renderLeftIcon = () =>
    iconLeft ? (
      <div className={cx(styles.iconContainer, styles.left)}>
        {typeof iconLeft === "function" ? iconLeft(isMouseOver) : iconLeft}
      </div>
    ) : null;
  const renderRightIcon = () =>
    iconRight ? (
      <div className={cx(styles.iconContainer, styles.right)}>
        {typeof iconRight === "function" ? iconRight(isMouseOver) : iconRight}
      </div>
    ) : null;

  const classes = cx(
    globalStyles.row,
    styles.border,
    isMouseOver && styles.isHovered,
    isFocused && styles.isFocused,
    hasError && styles.hasError,
    (readOnly || disabled) && globalStyles.disabled,
    (readOnly || disabled) && styles.disabled,
    className
  );

  return (
    <div
      className={cx(
        globalStyles.formInput,
        styles.container,
        containerClassName
      )}
      style={containerStyle}
    >
      {label ? (
        <Label
          htmlFor={String(name)}
          className={cx(
            globalStyles.truncate,
            hasError ? globalStyles.danger : undefined
          )}
        >
          {label}
          {required ? (
            <span className={cx(globalStyles.danger, globalStyles.bold)}>
              *
            </span>
          ) : null}
          {optional ? ` (optional)` : null}
        </Label>
      ) : null}
      <div
        className={classes}
        onMouseOver={handleMouseOver}
        onFocus={handleFocus}
        onMouseOut={handleMouseOut}
        onBlur={handleBlur}
      >
        {renderLeftIcon()}
        <input
          ref={ref}
          name={name}
          value={value}
          className={styles.input}
          disabled={disabled}
          style={style}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={readOnly}
          {...rest}
        />
        {renderRightIcon()}
      </div>
      {errorLabel ? (
        <Label htmlFor={String(name)} className={globalStyles.danger}>
          {errorLabel}
        </Label>
      ) : null}
    </div>
  );
});
