import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { DateTime } from "luxon";

import {
  Patient,
  PatientFilters,
  PatientObservation,
} from "@/domain/patient/model/types";
import { RootState } from "@/types";
import { PatientId, UserPatientTimerState } from "./types";
import { ObservationType } from "@/domain/observations/types";

export const selectPatients: Selector<RootState, Patient[]> = (state) =>
  state.patient.patients;
export const selectAssignmentPatients: Selector<RootState, Patient[]> = (
  state
) => state.patient.assignmentPatients;
export const selectSelectedPatients: Selector<RootState, Patient[]> = (state) =>
  state.patient.selectedPatients;
export const selectSelectedAssignmentPatients: Selector<
  RootState,
  Patient[]
> = (state) => state.patient.selectedAssignmentPatients;
export const selectObservationsByPatientId: Selector<
  RootState,
  Record<PatientId, PatientObservation[]>
> = (state) => state.patient.observationsByPatientId;
export const selectMonitoringStartDate: Selector<RootState, string> = (state) =>
  state.patient.monitoringStartDate;
export const selectMonitoringEndDate: Selector<RootState, string> = (state) =>
  state.patient.monitoringEndDate;
export const selectMonitoringTabVisibility: Selector<
  RootState,
  Record<ObservationType, boolean>
> = (state) => state.patient.monitoringTabVisibility;
export const selectPatientTimer: Selector<
  RootState,
  Record<string, UserPatientTimerState>
> = (state) => state.patient.timerByUserPatientId;
export const selectTimerByUserPatientId =
  (userPatientId: string) => (state: RootState) =>
    state.patient.timerByUserPatientId[userPatientId];

export const selectObservationsForPatient =
  (id: PatientId) => (state: RootState) =>
    state.patient.observationsByPatientId[id] || [];

export const selectNoteCreatedOn: Selector<RootState, string | null> = (
  state
) => state.patient.noteCreatedOn;

export const selectFirstPatient = createSelector<
  [Selector<RootState, Patient[]>],
  Patient | null
>([selectSelectedPatients], (selectedPatients) => {
  return selectedPatients && selectedPatients.length
    ? selectedPatients[0]
    : null;
});
export const selectPatientFilter = (state: RootState) => state.patient.filters;

export const selectFirstPatientObservations = createSelector<
  [
    Selector<RootState, Patient | null>,
    Selector<RootState, Record<PatientId, PatientObservation[]>>
  ],
  PatientObservation[]
>(
  [selectFirstPatient, selectObservationsByPatientId],
  (patient, observationsByPatientId) => {
    if (!patient || !patient.id) return [];

    return observationsByPatientId[patient.id] || [];
  }
);

export const selectNumDaysBetweenDates = createSelector<
  [Selector<RootState, string>, Selector<RootState, string>],
  number
>(
  [selectMonitoringStartDate, selectMonitoringEndDate],
  (isoStartDate, isoEndDate) => {
    const startDate = DateTime.fromISO(isoStartDate).startOf("day");
    const endDate = DateTime.fromISO(isoEndDate).endOf("day");
    const diff = endDate.diff(startDate, ["days"]);
    const { days = 0 } = diff.toObject();

    return days;
  }
);

export const selectPatientList = createSelector<
  [Selector<RootState, Patient[]>, Selector<RootState, PatientFilters>],
  Patient[]
>([selectPatients, selectPatientFilter], (patients, filters) => {
  if (filters.status?.length) {
    return patients.filter(
      (patient: Patient) =>
        patient.lifecycle?.state &&
        filters.status?.includes(patient.lifecycle?.state)
    );
  }

  return patients;
});

export const selectPatientListPaginationConfig = (rootState: RootState) =>
  rootState.patient.paginationConfig;
