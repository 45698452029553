import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { DateTime } from "luxon";
import { RemoteCareAPISync } from "@/library/core/api";

import {
  selectFirstPatient,
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import { RootState } from "@/types";
import {
  selectRejectedNoteIds,
  selectUnresolvedNotesByPatientId,
} from "@/domain/notes/redux/selectors";
import { Note, SeverityLevel } from "@/domain/notes/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import { Patient } from "@/domain/patient/model/types";
import { AlertTableRowNew, AlertTableAssessmentType } from "./types";
import { PatientNoteTypes } from "@/domain/notes/model/constants";
import { AssessmentType, NoDateDifference } from "./constants";
import { ObservationTypes } from "@/domain/patient/model/constants";
import { ObservationType } from "@/domain/observations/types";
import {
  Assessment,
  AssessmentQuestion,
} from "@/domain/assessment/model/types";
import { Http } from "@/library/core/api/http";
import { AxiosResponse } from "axios";

export const selectAlertsTableData = createSelector<
  [
    Selector<RootState, Patient | null>,
    Selector<RootState, Record<PatientId, Note[]>>,
    Selector<RootState, string[]>,
    Selector<RootState, string>,
    Selector<RootState, string>
  ],
  AlertTableRowNew[]
>(
  [
    selectFirstPatient,
    selectUnresolvedNotesByPatientId,
    selectRejectedNoteIds,
    selectMonitoringStartDate,
    selectMonitoringEndDate,
  ],
  (patient, allUnresolvedNotes, rejectedNoteIds, isoStartDate, isoEndDate) => {
    const startDate = DateTime.fromISO(isoStartDate).startOf("day");
    const endDate = DateTime.fromISO(isoEndDate).endOf("day");

    const patientId = patient?.id;

    if (!patientId) return [];

    const unresolvedNotes: Note[] = allUnresolvedNotes[patientId] || [];

    return unresolvedNotes
      .filter((note: Note) => {
        if (!note?.id || !note._meta?.created) return false;

        const created = DateTime.fromISO(note._meta?.created);

        return note.severityScore > SeverityLevel.SEVERITY_GREEN;
      })
      .map((note: Note) => {
        const {
          id: noteId = "",
          type: noteType,
          observation,
          isComplete = false,
          severityScore,
          comment: _comment,
          _meta,
          data,
        } = note;
        const { observations = [] } = observation || {};
        const { created: _created } = _meta || {};

        const comment = _comment || "";
        let created = _created || "";
        let value = null;
        let type: ObservationType | AlertTableAssessmentType | "" = "";
        let manualNoteId = "";

        if (noteType === PatientNoteTypes.Observation) {
          const observation = observations[NoDateDifference];
          const {
            type: observationType = "",
            timeOfObservation = "",
            value: observationValue,
            systolic,
            diastolic,
            pef,
            fev1,
            unit,
            manualNoteId: _manualNoteId,
          } = observation || ({} as AlertTableRowNew);

          manualNoteId = _manualNoteId || "";

          // Ignore observations with "rejected" notes
          if (
            noteId &&
            (rejectedNoteIds.includes(noteId) ||
              rejectedNoteIds.includes(manualNoteId))
          ) {
            return null;
          }

          switch (observationType) {
            case ObservationTypes.BloodPressure:
              if (systolic && diastolic)
                value = `${systolic.value}/${diastolic.value} ${diastolic.unit}`;
              break;
            case ObservationTypes.Spirometry:
              if (pef && fev1)
                value = `PEF: ${pef.value} ${
                  pef.unit
                } FEV1: ${fev1.value.toFixed(2)} ${fev1.unit}`;
              break;
            default:
              value = `${observationValue} ${unit}`;
              break;
          }

          type = observationType;
          created = timeOfObservation || _created || "";
        } else if (noteType === PatientNoteTypes.Assessment) {
          type = AssessmentType;
        }

        return {
          noteId,
          noteType,
          patientId,
          manualNoteId,
          type,
          value,
          isComplete,
          severityScore,
          comment: comment || "",
          created,
          assessmentId: note?.assessment?.assessment?.id || undefined,
          observation: observation,
          data: data,
        };
      })
      .filter((row) => !!row) as AlertTableRowNew[];
  }
);
