import { SortedStateType } from "../head/sortable";

export interface TableWithPaginationConfig {
  /**
   * Set default custom records per page
   */
  recordsPerPage?: 5 | 10 | 25 | 50;
  /**
   * Make datatable columns sortable
   */
  isSortable?: boolean;
  /**
   * Sort the data array with custom logic
   */
  onSort?: (index: number, sortedState: SortedStateType) => void;
  /**
   * Make datatable searchable
   */
  isSearchable?: boolean;
}

export interface TableWithSort {
  value: string | number | undefined;
  jsx: JSX.Element | undefined | string;
}

/**
 * Sort table records in `Ascending` order according to given index
 * @param records
 * @param index
 * @returns
 */
export const sortInAscending = (records: TableWithSort[][], index: number) => {
  const sortedData = records.sort(function (
    prev: TableWithSort[],
    next: TableWithSort[]
  ) {
    if (prev[index].value === undefined) {
      return 1;
    }
    if (next[index].value === undefined) {
      return -1;
    }
    if (prev[index].value == next[index].value) {
      return 0;
    }
    return prev[index].value! < next[index].value! ? -1 : 1;
  });
  return sortedData;
};

/**
 * Sort table records in `Descending` order according to given index
 * @param records
 * @param index
 * @returns
 */

export const sortInDescending = (records: TableWithSort[][], index: number) => {
  const sortedData = records.sort(function (
    prev: TableWithSort[],
    next: TableWithSort[]
  ) {
    if (prev[index].value === undefined) {
      return -1;
    }
    if (next[index].value === undefined) {
      return 1;
    }
    if (prev[index].value == next[index].value) {
      return 0;
    }

    return prev[index].value! > next[index].value! ? -1 : 1;
  });
  return sortedData;
};

/*************************************** SORING METHODS WITHOUT JSX ************************************************************************** */

/**
 * Sort table records in `Descending` order according to given index without JSX Elements
 * @param records
 * @param index
 * @returns
 */

export const sortAscendingWithoutJSX = (
  records: (string | number | undefined)[][],
  index: number
) => {
  const sortedData = records.sort(function (
    prev: (string | number | undefined)[],
    next: (string | number | undefined)[]
  ) {
    if (prev[index] === undefined) {
      return -1;
    }
    if (next[index] === undefined) {
      return 1;
    }
    if (prev[index] == next[index]) {
      return 0;
    }

    return prev[index]! < next[index]! ? -1 : 1;
  });
  return sortedData;
};

/**
 * Sort table records in `Ascending` order according to given index
 * @param records
 * @param index
 * @returns
 */
export const sortDescendingWithoutJSX = (
  records: (string | number | undefined)[][],
  index: number
) => {
  const sortedData = records.sort(function (
    prev: (string | number | undefined)[],
    next: (string | number | undefined)[]
  ) {
    if (prev[index] === undefined) {
      return 1;
    }
    if (next[index] === undefined) {
      return -1;
    }
    if (prev[index] == next[index]) {
      return 0;
    }
    return prev[index]! > next[index]! ? -1 : 1;
  });
  return sortedData;
};
