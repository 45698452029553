import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Patient, PatientFilters } from "@/domain/patient/model/types";
import { Table } from "@/components/table";
import {
  dispatchDeselectAllPatients,
  dispatchDeselectPatient,
  dispatchSelectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatientFilters,
  dispatchSetTargetPatient,
} from "@/domain/patient/redux/actions";
import { PatientId } from "@/domain/patient/redux/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { TextButton } from "@/components/_buttons/TextButton";
import { User, UserModel } from "@/domain/user/model";
import { Model } from "@/library/model";
import { RootState } from "@/types";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { Props, State } from "./types";

import "./table.styles.css";

class AdminTable extends React.Component<Props, State> {
  state: Readonly<State> = {
    users: [],
    multiSelectConfig: {
      showLoadingIndicator: false,
    },
  };

  // static getDerivedStateFromProps(nextProps: Props, prevState: State) {
  //   const { locationTags } = nextProps;

  //   const locationTagIds = locationTags.map((tag: Tag) => tag._meta.id);

  //   return {
  //     ...prevState,
  //     locationTagIds,
  //   };
  // }

  handleSelectOpen = (fetch: () => Promise<void>) => async () => {
    await this.setState({
      multiSelectConfig: {
        showLoadingIndicator: true,
      },
    });
    await fetch();
    this.setState({
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    });
  };

  handleEditPatient = (targetPatient: PatientId) => {
    this.props.setTargetPatient(targetPatient);
    this.props.onEditPatient();
  };

  fetchUsers = async () => {
    const allOrganizationUserModel: UserModel[] =
      await UserModel.fetchByOrganizationIds([
        this.props.selectedOrganization?._meta?.id!,
      ]);
    let allPracticeUserModel: UserModel[] = [];

    if (this.props.selectedPractices.length) {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        this.props.selectedPractices.map(
          (practice: Practice) => practice._meta?.id
        )
      );
    } else {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        (
          await PracticeModel.fetchByOrganizationIds([
            this.props.selectedOrganization?._meta?.id!,
          ])
        ).map((practiceModel) => practiceModel.pluck("_meta").id)
      );
    }

    this.setState({
      // Removing the duplicate values from assignedUsers array and mapping it in multi select options array.
      users: [
        { value: "unassigned", label: "Unassigned", role: "Filter" },
        ...[
          ...(new Set(
            allPracticeUserModel.concat(allOrganizationUserModel)
          ) as any),
        ].map((model: Model<User>) => {
          return {
            value: model.pluck("id") as string,
            label: `${model.pluck("firstName")} ${model.pluck("lastName")}`,
            role: "Users",
          };
        }),
      ],
    });
  };

  handleResetSelectedPatients = () => {
    this.props.deselectAllPatients();
  };

  render() {
    const {
      headers,
      data,
      execSearch,
      onPageLimitChange,
      pageLimit,
      paginationConfig,
      selectedPatients,
      selectAllPatients,
      deselectAllPatients,
      selectPatient,
      deselectPatient,
      patients,
      setPatientFilters,
    } = this.props;

    const headerContents = [
      <th
        key={"patient-select-all-button"}
        className="bg-primary text-white small"
      >
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="select-all-patients"
            onChange={async (event) => {
              if (event.target.checked) {
                await selectAllPatients();
              } else {
                await deselectAllPatients();
              }
            }}
            checked={
              !!selectedPatients.length &&
              selectedPatients.length === patients.length
            }
          />
          <label
            className="custom-control-label"
            htmlFor="select-all-patients"
          />
        </div>
      </th>,
      ...(headers || []).map((content, key) => (
        <th key={key} className="bg-primary text-white small">
          <span className="th-text">{content}</span>
        </th>
      )),
    ];

    const subHeader = [
      <tr key={"header"}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <div>
            <BasicMultiSelect
              data={this.state.users}
              onChange={async (value: string) => {
                setPatientFilters({
                  ...this.props.filters,
                  assignedUserIds: value as any,
                });
                this.props.execSearch({});
              }}
              placement="auto"
              findValueBy={this.props.filters.assignedUserIds}
              isSearchable={true}
              placeholder="All"
              onOpen={this.handleSelectOpen(this.fetchUsers)}
              groupBy="role"
              picker="check"
              config={this.state.multiSelectConfig}
            />
          </div>
        </td>
        <td></td>
      </tr>,
    ];

    const renderRow = (patient: Patient) => {
      const { locationTags } = this.props;

      const location = patient.tagIds?.length
        ? locationTags.find((tag) => tag._meta.id === patient.tagIds[0])
        : null;

      return (
        <tr key={patient.id} className="td-text">
          <td>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id={patient.id}
                onChange={(event) => {
                  if (event.target.checked) {
                    return selectPatient(patient);
                  }
                  deselectPatient(patient);
                }}
                checked={
                  !!selectedPatients.length &&
                  (selectedPatients.length === patients.length ||
                    !!selectedPatients.filter(
                      (selectedPatients: Patient) =>
                        selectedPatients.id === patient.id
                    ).length)
                }
              />
              <label className="custom-control-label" htmlFor={patient.id} />
            </div>
          </td>
          <td>{patient.demographics.legalName.lastName}</td>
          <td>{patient.demographics.legalName.firstName}</td>
          <td>
            <TextButton
              onClick={() => this.handleEditPatient(patient.id as PatientId)}
            >
              {patient.mrn ?? ""}
            </TextButton>
          </td>
          <td>
            {(() => {
              const primaryCondition = patient.conditions.filter(
                (dx: { type: string }) => dx.type === "PRIMARY"
              );
              if (primaryCondition.length) {
                const condition = primaryCondition[0];
                if (condition) {
                  const displayName = condition.displayName;
                  if (displayName) {
                    return displayName;
                  }
                }
              }
              return "";
            })()}
          </td>
          <td>{patient.practice?.name}</td>
          <td>{location?.value || "-"}</td>
          <td>
            {patient.provider &&
              `${patient.provider?.firstName} ${patient.provider?.lastName}, ${patient.provider?.credentials}`}
          </td>
          <td>{patient.assignedUserName}</td>
          <td>
            <div className="dropleft btn-group">
              <button
                className=""
                style={{ backgroundColor: "transparent", border: "none" }}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-boundary="window"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="feather icon-more-horizontal" />
              </button>
              <div
                className="dropdown-menu dropdown-content hide-scroll"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() =>
                    this.handleEditPatient(patient.id as PatientId)
                  }
                >
                  Edit/View
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    };

    const dataContents = (data || [])
      .map((patient) => patient as Patient)
      .filter((row) => !!row)
      .map(renderRow);

    const tableData = subHeader.concat(dataContents);

    return (
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headerContents}
        data={tableData}
        onNavigate={execSearch}
        resetSelections={this.handleResetSelectedPatients}
        isNonScrollable={true}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedPractices: state.practice.selectedPractices,
    selectedProviders: state.provider.selectedProviders,
    selectedPatients: state.patient.selectedPatients,
    patients: state.patient.patients,
    filters: state.patient.filters,
    selectedOrganization: state.organization.selectedOrganization,
    locationTags: selectLocationTags(state),
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    setTargetPatient: (id: PatientId) => dispatch(dispatchSetTargetPatient(id)),
    selectPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectPatient: (patient: Patient) =>
      dispatch(dispatchDeselectPatient(patient)),
    selectAllPatients: () => dispatch(dispatchSelectAllPatients()),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
    setPatientFilters: (patientFilter: PatientFilters) =>
      dispatch(dispatchSetPatientFilters(patientFilter)),
  };
};

export const PatientAdminTableComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(AdminTable);
