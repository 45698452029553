import { TooltipFormatterContextObject } from "highcharts";
import { Button } from "rsuite";
import { DateTime } from "luxon";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";

import { dateFormatter } from "@/pipes/date";
import { DAYS_LIMIT } from "@/library/constants";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { Patient, PatientCondition } from "@/domain/patient/model/types";
import { ModelCollection } from "@/library/model";
import { NoteModel } from "@/domain/notes/model";
import { AlertLevelNew, Note } from "@/domain/notes/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import {
  getAlertValueLevelBgColorNew,
  PatientNoteTypes,
} from "@/domain/notes/model/constants";
import { ObservationType } from "@/domain/observations/types";
import { truncateObservationValue } from "@/domain/observations/helpers";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import styles from "./styles.module.scss";
import {
  AccessoryEntryGraph,
  ClinicianEntryGraph,
  PatientEntryGraph,
} from "@/assets/icons/dashboard";
import { BASE_DECIMAL_NUMBER } from "./constants";
import { getTimezoneAbbrevation } from "../../AlertsTableNew/helpers";

export const parseTooltipDate = (
  observationType: ObservationType,
  tooltipFormatter: TooltipFormatterContextObject,
  valueSuffix?: string,
  seriesName?: string,
  source?: any
): string => {
  let observationReading = "";
  if (tooltipFormatter.points?.length) {
    tooltipFormatter.points?.forEach((point, index: number) => {
      let color = point.point.options.marker
        ? point.point.options.marker.fillColor
        : null;
      let alertLevelColorBG = getAlertValueLevelBgColorNew(
        color === "#66c793"
          ? AlertLevelNew.GREEN_ALERT
          : color === "#db0b0b"
          ? AlertLevelNew.RED_ALERT
          : AlertLevelNew.YELLOW_ALERT
      );

      observationReading += `<tspan class="${alertLevelColorBG} valueLabel " style="border:1px solid ${color}">
          ${truncateObservationValue(
            observationType,
            point.y,
            valueSuffix === "L"
          )} </tspan> ${
        index <
        (tooltipFormatter.points?.length
          ? tooltipFormatter.points?.length - 1
          : 0)
          ? `<tspan class="dividerSlash">/</tspan>`
          : ""
      } `;
    });
  } else if (tooltipFormatter.point) {
    let color = tooltipFormatter.point.options.marker?.fillColor
      ? tooltipFormatter.point.options.marker?.fillColor
      : null;
    let alertLevelColorBG = getAlertValueLevelBgColorNew(
      color === "#66c793"
        ? AlertLevelNew.GREEN_ALERT
        : color === "#db0b0b"
        ? AlertLevelNew.RED_ALERT
        : AlertLevelNew.YELLOW_ALERT
    );

    observationReading += `<tspan class="${alertLevelColorBG} valueLabel " style="border:1px solid ${color}">
      ${truncateObservationValue(
        observationType,
        tooltipFormatter.point.y
      )}</tspan>`;
  }

  let sourceData = source?.data[0]?.source ?? null;
  return (
    `<div class=" w-100">
      <div class="justify-content-center d-flex align-center w-100"><img src="${
        sourceData === biometryEntryMethod.PatientEntry
          ? PatientEntryGraph
          : sourceData === biometryEntryMethod.Accessory
          ? AccessoryEntryGraph
          : ClinicianEntryGraph
      }" class="tooltipHeaderImage"></img></div>
      <div class="justify-content-center d-flex align-center w-100">${observationReading} <tspan class="unitText">${
      valueSuffix ? valueSuffix : ""
    }</tspan></div>
    </div>` +
    '<tspan class="tooltipDate">' +
    formatDateChart(tooltipFormatter.x) +
    "</tspan > <br/>"
  );
};

export const getStartDate = (): string => {
  const datetime = convertDateToLocalTimezone(DateTime.now().toJSDate());
  return datetime
    .minus({ days: DAYS_LIMIT - 1 })
    .startOf("day")
    .toISODate();
};

export const getEndDate = (): string => {
  return DateTime.now().endOf("day").toISODate();
};

export const exportPatientDetail = (props: {
  patient: Patient;
  provider?: string;
  practice?: string;
  startDate: Date;
  endDate: Date;
}) => {
  const { patient, provider, practice, startDate, endDate } = props;

  if (!patient || !startDate || !endDate) return "";

  return (
    "<b>Patient Name</b>: " +
    patient.demographics.legalName.firstName +
    " " +
    patient.demographics.legalName.lastName +
    " &nbsp; <b>DOB</b>: " +
    patient.demographics.dateOfBirth +
    " &nbsp; <b>MRN</b>: " +
    patient.mrn +
    " &nbsp; <br><b>Diagnosis</b>: " +
    patient.conditions
      .filter((cond: PatientCondition) => {
        if (
          cond.type === "PRIMARY" ||
          (cond.type === "SECONDARY" && !cond.resolved)
        ) {
          return cond;
        }
      })
      .map((condition: PatientCondition) => condition.displayName)
      .join(", ") +
    "<br><b>Provider</b>: " +
    provider +
    " &nbsp; <b>Practice</b>:  " +
    practice +
    "<br><b>Observation Date Range</b>:  " +
    dateFormatter({ date: startDate.toISOString() }) +
    " to " +
    dateFormatter({ date: endDate.toISOString() }) +
    "<br><b>Exported On</b>: " +
    new Date().toLocaleString()
  );
};

export const getAllNotes = async (patientId: PatientId): Promise<Note[]> => {
  if (!patientId)
    return Promise.reject("Cannot fetch notes. Patient not loaded.");

  const noteCollection: ModelCollection<NoteModel, Note> =
    await NoteModel.syncByPatientId(patientId);
  noteCollection.withParams({
    limit: 499,
    types: [PatientNoteTypes.Observation],
  });
  const noteIterator = await noteCollection.getMany();
  const noteModels = await noteIterator.getAll();

  return noteModels.map((noteModel) => noteModel.pluckAll());
};

export const getUnresolvedNotes = async (
  patientId: PatientId
): Promise<Note[]> => {
  const noteCollection: ModelCollection<NoteModel, Note> =
    NoteModel.syncByPatientId(patientId);
  await noteCollection
    .withParams({
      isManual: false,
      isComplete: false,
      types: [PatientNoteTypes.Assessment, PatientNoteTypes.Observation],
    })
    .fetch();

  return noteCollection.container.map((noteModel) => noteModel.pluckAll());
};

export const renderActions =
  (
    handleAccept: (selection: RowSelectionState) => () => void,
    handleReject: (selection: RowSelectionState) => () => void
  ) =>
  (
    columns: ColumnDef<AlertTableRow, unknown>[],
    rowSelection: RowSelectionState
  ) => {
    const numSelected = Object.keys(rowSelection).length;

    return (
      <tr className={styles.actionsTr}>
        <td colSpan={columns.length}>
          <div className={styles.actions}>
            <div className={styles.actionsLeft}>
              {numSelected ? (
                <>{`${numSelected} note${
                  numSelected === 1 ? "" : "s"
                } selected.`}</>
              ) : null}
            </div>
            <div className={styles.actionsRight}>
              <Button
                className={styles.actionBtn}
                appearance="ghost"
                color="green"
                disabled={!numSelected}
                onClick={handleAccept(rowSelection)}
              >
                Accept
              </Button>
              <Button
                className={styles.actionBtn}
                appearance="ghost"
                color="red"
                disabled={!numSelected}
                onClick={handleReject(rowSelection)}
              >
                Reject
              </Button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

export enum biometryEntryMethod {
  Accessory = "ACCESSORY",
  PatientEntry = "PATIENT_ENTRY",
  ClinicianEntry = "CLINICIAN_ENTRY",
}
export const getBiometryEntryIconNew = (source: string) => {
  let entryMethodIcon = " ";
  switch (source) {
    case biometryEntryMethod.PatientEntry:
      entryMethodIcon = PatientEntryGraph;
      break;
    case biometryEntryMethod.Accessory:
      entryMethodIcon = AccessoryEntryGraph;
      break;
    case biometryEntryMethod.ClinicianEntry:
      entryMethodIcon = ClinicianEntryGraph;
      break;
    default:
      entryMethodIcon = "";
      break;
  }
  return entryMethodIcon;
};

export const formatDateChart = (dateVal: number) => {
  let newDate = new Date(dateVal);
  let chartMonth = padValue(newDate.getMonth() + 1);
  let chartDay = padValue(newDate.getDate());
  let chartYear = newDate.getFullYear();
  let chartHour = newDate.getHours();
  let chartSeconds = padValue(newDate.getSeconds());
  let chartMinute = padValue(newDate.getMinutes());
  const localTimzone = DateTime.local().zoneName;
  const zoneNameAbbreviation = getTimezoneAbbrevation(localTimzone);
  return `${chartMonth}-${chartDay}-${chartYear} | ${chartHour}:${chartMinute}:${chartSeconds} (${zoneNameAbbreviation})`;
};

export const padValue = (value: number) => {
  return value < BASE_DECIMAL_NUMBER ? "0" + value : value;
};
