import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "@/domain/tags/model/types";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { TagModel } from "@/domain/tags/model";
import { dispatchSetLocationTags } from "@/domain/tags/redux/actions";
import {
  BasicMultiSelect,
  BasicMultiSelectType,
} from "@/components/form/select/multiSelect";

export type Props = {
  onSetLocationTags?: (tags: Tag[]) => void;
  selectedId?: string | string[];
  selectedPractices?: string | string[];
  name?: string;
  hasError?: boolean;
} & BasicMultiSelectType;

export const LocationSelect: React.FC<Props> = (props) => {
  const {
    onSetLocationTags,
    selectedId,
    selectedPractices,
    data,
    isSearchable,
    ...rest
  } = props;

  const [pending, setPending] = useState(false);

  const practices = useSelector(selectPractices);
  const dispatch = useDispatch();
  const setLocationTags = (tags: Tag[]) =>
    dispatch(dispatchSetLocationTags(tags));
  const [tags, setTags] = useState([] as Tag[]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setPending(true);
        const tags = await TagModel.fetchAllTagsWithIterator({
          type: "location",
          practiceIds:
            selectedPractices || practices.map((practice) => practice.id),
        });

        setTags(tags);
        if (onSetLocationTags) onSetLocationTags(tags);
        setLocationTags(tags);
      } catch (error) {
        console.error(error);
      } finally {
        setPending(false);
      }
    };

    if (!pending) fetch();
  }, [tags, selectedPractices]);

  const options = tags.map((tag: Tag) => ({
    label: tag.value,
    value: tag._meta.id,
  }));

  return (
    <>
      <BasicMultiSelect
        {...rest}
        data={data.length ? data : options}
        isSearchable={isSearchable}
        style={{ width: "100%" }}
        value={selectedId}
      />
    </>
  );
};
