import { Patient } from "@/domain/patient/model/types";

type OwnProps = {
  type: "button" | "text" | "dropdown";
  targetedPatient: Patient;
  buttonText?: string;
  changeProgramStatus?: (status: string) => void;
};

type ConnectedProps = {
  patients: Patient[];
  selectedPatients: Patient[];
};

type DispatchProps = {
  setSelectedPatient: (patient: Patient) => void;
  deselectAllPatients: () => { type: string };
  setPatients: (patients: Patient[]) => { payload: string; type: string };
};

export type ManageProgramProps = OwnProps & DispatchProps & ConnectedProps;

export type Writable<T> = { -readonly [P in keyof T]: T[P] };

export enum ProgramType {
  RPM = "RPM",
}

export enum ProgramActions {
  ADD_PATIENT = "ADD_PATIENT",
  ACTIVATE_PATIENT = "ACTIVATE_PATIENT",
  PATIENT_DECLINED = "PATIENT_DECLINED",
  FIRST_BIOMETRIC = "FIRST_BIOMETRIC",
  DEACTIVATE_PATIENT = "DEACTIVATE_PATIENT",
  REACTIVATE_PATIENT = "REACTIVATE_PATIENT",
  COMPLETE_PATIENT = "COMPLETE_PATIENT",
}

export const transitionDisplayName = {
  [ProgramActions.ADD_PATIENT]: "Add Patient",
  [ProgramActions.ACTIVATE_PATIENT]: "Activate Patient",
  [ProgramActions.PATIENT_DECLINED]: "Patient Declined",
  [ProgramActions.FIRST_BIOMETRIC]: "Simulate First Biometric",
  [ProgramActions.DEACTIVATE_PATIENT]: "Deactivate Patient",
  [ProgramActions.REACTIVATE_PATIENT]: "Reactivate Patient",
  [ProgramActions.COMPLETE_PATIENT]: "Complete Patient",
};

export enum PatientProgramState {
  ADDED = "ADDED",
  PREACTIVE = "PREACTIVE",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  COMPLETED = "COMPLETED",
}

export const PatientCurrentStateByAction = {
  [ProgramActions.ADD_PATIENT]: PatientProgramState.ADDED,
  [ProgramActions.ACTIVATE_PATIENT]: PatientProgramState.PREACTIVE,
  [ProgramActions.PATIENT_DECLINED]: PatientProgramState.COMPLETED,
  [ProgramActions.FIRST_BIOMETRIC]: PatientProgramState.ACTIVE,
  [ProgramActions.DEACTIVATE_PATIENT]: PatientProgramState.INACTIVE,
  [ProgramActions.REACTIVATE_PATIENT]: PatientProgramState.ACTIVE,
  [ProgramActions.COMPLETE_PATIENT]: PatientProgramState.COMPLETED,
};

const patientNextState = {
  [PatientProgramState.ADDED]: {
    allowedActions: [
      ProgramActions.ACTIVATE_PATIENT,
      ProgramActions.PATIENT_DECLINED,
    ],
  },
  [PatientProgramState.PREACTIVE]: {
    allowedActions: [
      ProgramActions.FIRST_BIOMETRIC,
      ProgramActions.PATIENT_DECLINED,
    ],
  },
  [PatientProgramState.ACTIVE]: {
    allowedActions: [
      ProgramActions.DEACTIVATE_PATIENT,
      ProgramActions.COMPLETE_PATIENT,
    ],
  },
  [PatientProgramState.INACTIVE]: {
    allowedActions: [
      ProgramActions.REACTIVATE_PATIENT,
      ProgramActions.COMPLETE_PATIENT,
    ],
  },
  [PatientProgramState.COMPLETED]: {
    allowedActions: [],
  },
};

export const StateDisplayName: { [key: string]: string } = {
  [PatientProgramState.ADDED]: "Added",
  [PatientProgramState.PREACTIVE]: "Pre-active",
  [PatientProgramState.ACTIVE]: "Active",
  [PatientProgramState.INACTIVE]: "Inactive",
  [PatientProgramState.COMPLETED]: "Completed",
};

export const getPatientNextState = (
  currentState: PatientProgramState
): ProgramActions[] => patientNextState[currentState]?.allowedActions || [];
