import * as Yup from "yup";
import { Validation } from "@/components/form";
import { ReturnMethod, Workflow } from "./types";

export const Workflows = {
  fulfillment: "fulfillment" as Workflow,
  return: "return" as Workflow,
};
export const ReturnMethods = {
  ship: "ship",
  pickup: "pickup",
};
export const PickupTimes = {
  AM: "AM",
  PM: "PM",
};
export const OrderType = {
  Return: "RETURN",
};
export const OrderFulfillment = {
  Customer: "CUSTOMER",
};
export const OrderReplacement = {
  Yes: "yes",
  No: "no,",
};
export const FULFILLMENT_METHOD_OPTIONS = [
  { label: "Ship to Patient", value: ReturnMethods.ship },
  { label: "Hand Deliver to Patient", value: ReturnMethods.pickup },
];
export const RETURN_METHOD_OPTIONS = [
  { label: "Ship from patient", value: ReturnMethods.ship },
  { label: "Pickup from patient", value: ReturnMethods.pickup },
];

export const VALIDATION_SCHEMA = Yup.object({
  returnMethod: Yup.string()
    .required("Return method is required.")
    .test({
      message: "Return method is required.",
      test: (value, ctx): any => {
        if (!Object.keys(ReturnMethods).includes(value || "")) {
          return ctx.createError({ message: "Return method is required." });
        }
        return true;
      },
    }),
  // Required for pickup
  pickupName: Yup.string().when("returnMethod", {
    is: ReturnMethods.pickup,
    then: (schema) => schema.required("Pickup name is required."),
  }),
  pickupDate: Yup.string().when("workflow", {
    is: Workflows.return,
    then: (schema) => schema.required("Pickup date is required."),
  }),
  pickupTime: Yup.string()
    .when("returnMethod", {
      is: ReturnMethods.pickup,
      then: (schema) => schema.required("Pickup time is required."),
    })
    .test({
      message: "Pickup time is required.",
      test: (value, ctx): any => {
        if (value && !Object.keys(PickupTimes).includes(value || "")) {
          return false;
        }
        return true;
      },
    }),
  pickupAddress: Yup.object().when("returnMethod", {
    is: ReturnMethods.pickup,
    then: (schema) =>
      schema.shape({
        street: Yup.array().min(1, "Street is required."),
        locality: Yup.string().required("Locality is required."),
        region: Yup.string().required("Region is required."),
        postalCode: Yup.string().required("Postal code is required."),
        country: Yup.string().required("Country is required."),
      }),
  }),
  specialInstructions: Yup.string().notRequired(),

  // Required for ship
  shipToName: Yup.string().when("returnMethod", {
    is: ReturnMethods.ship,
    then: (schema) => schema.required("Ship to name is required."),
  }),
  shipToAddress: Yup.object().when("returnMethod", {
    is: ReturnMethods.ship,
    then: (schema) =>
      schema.shape({
        street: Yup.array().min(1, "Street is required."),
        locality: Yup.string().required("Locality is required."),
        region: Yup.string().required("Region is required."),
        postalCode: Yup.string().required("Postal code is required."),
        country: Yup.string().required("Country is required."),
      }),
  }),
  deliveryDate: Yup.string().when("returnMethod", {
    is: ReturnMethods.ship,
    then: (schema) => schema.required("Delivery date is required."),
  }),
  sendReplacementBoxes: Yup.string().when("workflow", {
    is: (workflow: Workflow) => workflow === Workflows.return,
    then: Yup.string().required("Send replacement boxes is required."),
  }),
  contactEmail: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});

export const MOCK_DATA = {
  name: "RemoteCareLive + Pulse Oximeter, BP Meter, Scale",
  msn: "HAT00458",
  patient: {
    demographics: {
      legalName: {
        firstName: "Greg",
        lastName: "Falcone",
      },
      preferredName: "Old Gregg",
    },
  },
  pickupName: "Greg Falcone",
  pickupAddress: {
    street: ["1245 Street Rd."],
    locality: "The Colony",
    region: "TX",
    postalCode: "75060",
    country: "US",
  },
  shipToName: "Customer Name",
  shipToAddress: {
    street: ["1245 Street Rd.", "Apt 1"],
    locality: "Some City",
    region: "TX",
    postalCode: "75060",
    country: "US",
  },
  pickupDate: "2023-01-11",
  pickupTime: PickupTimes.AM,
};
