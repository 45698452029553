import React, { FC } from "react";
import { ListItemText } from "@mui/material";
import { Organization } from "@/domain/organization/model/types";

export interface PathwayOrganizationReadOnlyProps {
  organization: Organization;
}
export const PathwayOrganizationReadOnly: FC<
  PathwayOrganizationReadOnlyProps
> = ({ organization }) => {
  return <ListItemText primary="Organization" secondary={organization.name} />;
};
