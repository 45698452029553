export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_PROPERTY = "UPDATE_PATIENT_PROPERTY";
export const DESELECT_ALL_ASSIGNMENT_PATIENTS =
  "DESELECT_ALL_ASSIGNMENT_PATIENTS";
export const DESELECT_ASSIGNMENT_PATIENT = "DESELECT_ASSIGNMENT_PATIENT";
export const SELECT_ALL_ASSIGNMENT_PATIENTS = "SELECT_ALL_ASSIGNMENT_PATIENTS";
export const SELECT_ASSIGNMENT_PATIENT = "SELECT_ASSIGNMENT_PATIENT";
export const SET_ASSIGNMENT_PATIENTS = "SET_ASSIGNMENT_PATIENTS";
export const SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT =
  "SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT";
export const SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG =
  "SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG";
export const SET_ASSIGNMENT_PATIENT_FILTERS = "SET_ASSIGNMENT_PATIENT_FILTERS";
export const SET_PATIENT_FILTERS = "SET_PATIENT_FILTERS";
export const SET_FILTER_ITEM_DETAILS = "SET_FILTER_ITEM_DETAILS";
export const DESELECT_ALL_PATIENTS = "DESELECT_ALL_PATIENTS";
export const DESELECT_PATIENT = "DESELECT_PATIENT";
export const SELECT_ALL_PATIENTS = "SELECT_ALL_PATIENTS";
export const SELECT_PATIENT = "SELECT_PATIENT";
export const SET_PATIENTS = "SET_PATIENTS";
export const SET_PATIENTS_PAGE_LIMIT = "SET_PATIENTS_PAGE_LIMIT";
export const SET_PATIENTS_PAGINATIONCONFIG = "SET_PATIENTS_PAGINATIONCONFIG";
export const SET_TARGET_PATIENT = "SET_TARGET_PATIENT";
export const ADD_PATIENT_OBSERVATIONS = "ADD_PATIENT_OBSERVATIONS";
export const SET_PATIENT_OBSERVATIONS = "SET_PATIENT_OBSERVATIONS";
export const SET_MONITORING_START_DATE = "SET_MONITORING_START_DATE";
export const SET_MONITORING_END_DATE = "SET_MONITORING_END_DATE";
export const SET_MONITORING_DATES = "SET_MONITORING_DATES";
export const TOGGLE_MONITORING_TAB_VISIBILITY =
  "TOGGLE_MONITORING_TAB_VISIBILITY";
export const SET_PATIENT_TIMER = "SET_PATIENT_TIMER";
export const SET_ACCEPT_REJECT_NOTES_TOGGLE = "SET_ACCEPT_REJECT_NOTES_TOGGLE";
export const SET_RULES_SELECTED_PATIENT = "SET_RULES_SELECTED_PATIENT";
