import { MILLISECONDS_PER_DAY } from "@/pipes/date/constants";
import { DateTime } from "luxon";

export type ChartOptions = {
  yTitle?: string;
  chart?: Record<string, unknown>;
  events: Record<string, unknown>;
  xAxis?: Record<string, unknown>;
  yAxis?: Record<string, unknown> | Record<string, unknown>[];
  legend?: Record<string, unknown>;
  tooltip?: Record<string, unknown>;
  plotOptions?: Record<string, unknown>;
  series: Record<string, unknown>[];
  caption: Record<string, unknown>;
  colorAxis?: Record<string, unknown>[];
  time?: Record<string, unknown>;
};

export const COLORS = {
  primary: {
    min: "#9bc4e7",
    max: "#0F76B6",
    line: "#7baadf",
  },
  secondary: {
    min: "#e7b8ae",
    max: "#8a1900",
    line: "#cba9aa",
  },
  misc: {
    alert: "#f02973",
  },
};

export const makeChartOptionsNew = (
  props: ChartOptions
): Record<string, any> => {
  const {
    yTitle,
    events,
    chart = {},
    legend = {},
    xAxis = {},
    yAxis = {},
    tooltip = {},
    plotOptions = {},
    series,
    colorAxis = [],
    caption,
    time,
  } = props;

  return {
    options: {
      chart: {
        animation: false,
        height: "26%",
        marginTop: 14,
        events,
        ...chart,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%e. %b",
          year: "%b",
        },
        tickInterval: MILLISECONDS_PER_DAY,
        ...xAxis,

        min: xAxis.min
          ? DateTime.fromISO(xAxis.min as string)
              .startOf("day")
              .toMillis()
          : undefined,
        max: xAxis.max
          ? DateTime.fromISO(xAxis.max as string)
              .endOf("day")
              .toMillis()
          : undefined,
        labels: {
          format: "{value:%m/%d}",
          useHTML: true,
          style: {
            color: "#464646",
            "font-family": "Montserrat",
            "font-size": "10px",
            "font-weight": "500",
          },
        },
        lineColor: "#888787",
        lineWidth: 1,
        gridLineColor: "#E2E2E2",
      },
      yAxis: {
        lineColor: "#888787",
        lineWidth: 1,
        title: {
          text: yTitle,
        },
        labels: {
          useHTML: true,
          style: {
            color: "#464646",
            "font-family": "Montserrat",
            "font-size": "10px",
            "font-weight": "500",
          },
        },
        gridLineColor: "#E2E2E2",
        ...yAxis,
      },
      time: time,
      lang: {
        noData: "No data to display",
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        ...tooltip,
      },
      legend,
      series,
      colorAxis,
      plotOptions: {
        series: {
          marker: {
            radius: 5,
            symbol: "circle",
            enabled: true,
          },
          fillopacity: 1,
        },
        cursor: "pointer",
        ...plotOptions,
      },
      exporting: {
        showTable: false,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
            ],
          },
        },
        caption,
      },
    },
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
    states: {
      hover: {
        lineWidth: 1,
      },
    },
  };
};

export const chartConstants = {
  COLOR: {
    Line: "#030575",
  },
};
