import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Practice, PracticeFilters } from "@/domain/practice/model/types";
import { Table } from "@/components/table";
import {
  dispatchSetTargetPractice,
  dispatchDeselectAllPractices,
  dispatchSetPracticeFilters,
  dispatchSetActivePractice,
} from "@/domain/practice/redux/actions";
import { PracticeId } from "@/domain/practice/redux/types";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { TextButton } from "@/components/_buttons/TextButton";
import { Guard } from "@/library/guard/Guard";
import { ResourceType } from "@/library/core/config/resource";
import { RootState } from "@/types";
import { ORGANIZATION_TYPES, PracticeTableHeader } from "./constants";
import { Props } from "./types";
import { UserParentTypes } from "@/domain/user/model";

export const PracticeTable: React.FC<Props> = (props) => {
  const {
    data,
    execSearch,
    onPageLimitChange,
    pageLimit,
    paginationConfig,
    onCreateProvider,
    onEditPractice,
    onAddLocation,
    onManageOverrides,
  } = props;

  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const practiceFilters = useSelector(
    (state: RootState) => state.practice.practiceFilters
  );
  const sortedState = useSelector(
    (state: RootState) => state.practice.sortedState
  );
  const setTargetPractice = (id: PracticeId) =>
    dispatch(dispatchSetTargetPractice(id));
  const setPracticeFilters = (practiceFilters: PracticeFilters) =>
    dispatch(dispatchSetPracticeFilters(practiceFilters));
  const setActivePractice = (practice: Practice) =>
    dispatch(dispatchSetActivePractice(practice));

  const addProvider = (practiceId: PracticeId) => () => {
    setTargetPractice(practiceId);
    onCreateProvider();
  };
  const editPractice = (practiceId: PracticeId) => () => {
    setTargetPractice(practiceId);
    onEditPractice();
  };
  const addLocation = (practiceId: PracticeId) => () => {
    onAddLocation(practiceId);
  };

  const renderSubHeader = () => [
    <tr key={"practiceTableHeader"}>
      <td className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}>
        <div>
          <BasicMultiSelect
            data={ORGANIZATION_TYPES}
            onChange={async (value: string) => {
              setPracticeFilters({
                ...practiceFilters,
                type: value,
              });
              execSearch({});
            }}
            isSearchable={false}
            onSelect={async (value: string) => {
              setPracticeFilters({
                ...practiceFilters,
                type: value,
              });
              execSearch({});
            }}
            findValueBy={practiceFilters.type}
          />
        </div>
      </td>
      <td className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 8 ? "sorted_col" : ""} />
    </tr>,
  ];

  const renderData = () =>
    (data || [])
      .map((practice) => practice as Practice)
      .map((practice) => {
        return (
          <tr key={practice?.id} className="td-text">
            <td
              className={
                sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""
              }
            >
              <TextButton onClick={editPractice(practice.id as PracticeId)}>
                {practice.name}
              </TextButton>
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""
              }
            >
              {practice.type}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""
              }
            >
              {practice.address.locality}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""
              }
            >
              {practice.address.region}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""
              }
            >
              {practice.contact?.name ?? "-"}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""
              }
            >
              {practice.contact?.email ?? "-"}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""
              }
            >
              {practice.providers}
            </td>
            <td
              className={
                sortedState.sortedColumnIndex === 8 ? "sorted_col" : ""
              }
            >
              <div className="dropleft btn-group">
                <button
                  className=""
                  style={{ backgroundColor: "transparent", border: "none" }}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  data-boundary="window"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="feather icon-more-horizontal" />
                </button>
                <div
                  className="dropdown-menu hide-scroll"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={editPractice(practice.id as PracticeId)}
                  >
                    Edit/View
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={addProvider(practice.id as PracticeId)}
                  >
                    Create Provider
                  </a>
                  {currentUser &&
                    Guard.accessLevel(UserParentTypes.Admin).canActivate(
                      currentUser
                    ) && (
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={addLocation(practice.id as PracticeId)}
                      >
                        Create Location
                      </a>
                    )}
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => manageOverrides(practice as Practice)}
                  >
                    Manage Parameter Overrides
                  </a>
                </div>
              </div>
            </td>
          </tr>
        );
      });

  const manageOverrides = (practice: Practice) => {
    setActivePractice(practice);
    onManageOverrides(practice);
  };

  const tableData = [renderSubHeader(), renderData()];

  return (
    <Table
      paginationConfig={paginationConfig}
      pageLimit={pageLimit}
      onPageLimitChange={onPageLimitChange}
      header={
        <SortableHead
          headers={PracticeTableHeader}
          execSearch={execSearch}
          resourceType={ResourceType.practice}
        />
      }
      data={tableData}
      onNavigate={execSearch}
      resetSelections={dispatchDeselectAllPractices}
      isNonScrollable={true}
    />
  );
};
