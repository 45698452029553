import React, { forwardRef, useState } from "react";
import cx from "clsx";

import { Props } from "./types";
import { Label } from "@/components/Label";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export const TextArea: React.FC<Props> = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      name,
      label,
      value,
      hasError,
      disabled,
      optional,
      onChange,
      errorLabel,
      spellCheck = false,
      containerClassName,
      className,
      style,
      ...rest
    } = props;

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const classes = cx(
      styles.input,
      isFocused && styles.isFocused,
      hasError && styles.hasError,
      disabled && globalStyles.disabled,
      className
    );

    return (
      <div className={cx(styles.container, containerClassName)}>
        {label ? (
          <Label
            htmlFor={String(name)}
            className={hasError ? styles.errorLabel : undefined}
          >
            {optional ? `${label} (optional)` : label}
          </Label>
        ) : null}
        <textarea
          ref={ref}
          name={name}
          value={value}
          disabled={disabled}
          className={classes}
          onChange={onChange}
          spellCheck={spellCheck}
          style={style}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        {errorLabel ? (
          <Label htmlFor={String(name)} className={styles.errorLabel}>
            {errorLabel}
          </Label>
        ) : null}
      </div>
    );
  }
);
