import { FunctionComponent, useState, useEffect } from "react";
import {
  DEFAULT_LAST_ACTIVITY,
  DEFAULT_STATUS,
  mapUserTypeToUserAction,
  User,
  UserActions,
  UserParentTypes,
  UserTableHeader,
} from "../../model";
import "./table.styles.css";
import {
  OrganizationType,
  PaginationConfig,
  UsersFilter,
} from "@/library/types";
import { connect } from "react-redux";
import { Table } from "@/components/table";
import { OrganizationModel } from "../../../organization/model";
import { Organization } from "../../../organization/model/types";
import { MODEL_SYNCED } from "@/library/constants";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { setTargetUser, setUsersSearchFilters } from "../../redux";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { ModelCollection } from "@/library/model";
import { UserModel } from "../../model";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { ResourceType } from "@/library/core/config/resource";
import { SortedState, SortQuery } from "@/components/table/head/sortable";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Guard } from "@/library/guard/Guard";
import { Modal } from "@/components/modal";
import { ResendInviteComponent } from "../resend-invite.component";
import { dateFormatter } from "@/pipes/date";

type TableProps = {
  data: any[];
  execSearch: (query: {}) => any;
  onPageLimitChange: (limit: number) => any;

  pageLimit: number;
  paginationConfig: PaginationConfig;

  setUsersSearchFilters: (payload: UsersFilter) => {
    type: string;
    payload: UsersFilter;
  };
  usersFilter: UsersFilter;

  onEditUser: (
    title: UserActions,
    defaultOrganization: string[],
    defaultPracticeIds: string[],
    syncedUser: UserModel
  ) => void;
  setTargetUser: (id: string) => { type: string; payload: string };
  currentUser: User;

  reset: boolean;
  sortedState: SortQuery;

  selectedOrganization: Organization;
  onEnableDisableUser: (
    title: string,
    selectedUser: User | undefined,
    content: string
  ) => any;
};
const Component: FunctionComponent<TableProps> = ({
  data,
  execSearch,
  onPageLimitChange,
  pageLimit,
  paginationConfig,
  setUsersSearchFilters,
  sortedState,
  onEnableDisableUser,
  ...props
}) => {
  const [parentEntities, setParentEntities] = useState(
    [] as { value: string; label: string }[]
  );
  const [multiSelectConfig, setMultiSelectConfig] = useState({
    showLoadingIndicator: false,
  });
  const withLoader = async (fetch: () => Promise<void>) => {
    await setMultiSelectConfig({ showLoadingIndicator: true });
    await fetch();
    setMultiSelectConfig({ showLoadingIndicator: false });
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as User);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const onShowModal = (modalHeader: string, modalContent: string) => {
    setModalTitle(modalHeader);
    setModalContent(modalContent);
  };

  const onModalClose = () => {
    setShowModal(false);
    setModalTitle("");
  };

  const handleResendInvite = async (user: User) => {
    const hasPendingStatus = UserModel.make(user).hasPendingStatus;
    if (hasPendingStatus) {
      onShowModal("Resend Invite", "Resend Invite to");
    } else {
      onShowModal("Reset Password", "Reset Password for");
    }
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleEnableOrDisableUser = async (user: User) => {
    if (user.isEnabled) {
      onEnableDisableUser(
        "Disable User",
        user,
        `Continue to disable ${user.email}?`
      );
    } else {
      onEnableDisableUser(
        "Enable User",
        user,
        `Continue to enable ${user.email}?`
      );
    }
  };

  const userTypes = [
    { label: "Organization", value: "Organization" },
    { label: "Practice", value: "Practice" },
    { label: "Admin", value: "Admin" },
  ];

  const status = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  /**
   * Fetches organization or practice collections.
   *
   * @param modelCollection
   * @param filter
   */
  const getEntityCollection = async <T, K>(
    modelCollection: ModelCollection<T, K>,
    filter?: { [key: string]: string }
  ): Promise<T[]> => {
    modelCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    await modelCollection.fetch({
      limit: 100, // max limit that api supports
      ...(filter ? filter : {}),
      ...(props.selectedOrganization
        ? { organizationIds: props.selectedOrganization.id }
        : undefined),
    });

    return modelCollection.container;
  };

  /**
   * Underlying setParentEntities logic.
   *
   * @param models
   */
  const populate = async (models: []): Promise<void> => {
    await setParentEntities(
      models.map((model: any) => {
        return {
          value: model.attributes.get("id") as string,
          label: model.attributes.get("name"),
        };
      })
    );
  };

  /**
   * Populates parentEntities based on userType selected.
   *
   * @param filter
   */
  const populateParentEntity = async (filter?: {
    [key: string]: string;
  }): Promise<void> => {
    const isRootUser = Guard.accessLevel(UserParentTypes.Admin).canActivate(
      props.currentUser
    );
    if (props.usersFilter.userType === "Organization") {
      if (props.selectedOrganization && isRootUser) {
        await populate([
          OrganizationModel.make(props.selectedOrganization),
        ] as unknown as []);
        return;
      }
      const orgs: OrganizationModel[] = await getEntityCollection<
        OrganizationModel,
        Organization
      >(OrganizationModel.makeOrganizationCollection(), filter);
      await populate(orgs as []);
      return;
    }

    if (props.usersFilter.userType === "Practice") {
      const practices: PracticeModel[] = await getEntityCollection<
        PracticeModel,
        Practice
      >(PracticeModel.makePracticeCollection(), filter);
      await populate(practices as []);
      return;
    }

    if (props.usersFilter.userType === "Admin") {
      if (props.selectedOrganization && isRootUser) {
        await populate(
          props.selectedOrganization.type === OrganizationType.Root
            ? ([
                OrganizationModel.make(props.selectedOrganization),
              ] as unknown as [])
            : []
        );

        return;
      }
      const orgs: OrganizationModel[] = await getEntityCollection<
        OrganizationModel,
        Organization
      >(OrganizationModel.makeOrganizationCollection(), filter);

      await setParentEntities(
        orgs
          .filter(
            (org: OrganizationModel) =>
              org.pluck("type") === OrganizationType.Root
          )
          .map((model: OrganizationModel) => ({
            value: model.attributes.get("id") as string,
            label: model.attributes.get("name"),
          }))
      );

      return;
    }
  };

  /**
   * Fetches parent entity and populates parentEntities with the result.
   *
   * @param filter
   */
  const fetchParentEntities = async (filter?: {
    [key: string]: string;
  }): Promise<void> => {
    if (
      props.usersFilter.userType &&
      ["Organization", "Practice", "Admin"].includes(props.usersFilter.userType)
    ) {
      return populateParentEntity(filter);
    }

    const isRootUser = Guard.accessLevel(UserParentTypes.Admin).canActivate(
      props.currentUser
    );
    if (isRootUser && props.selectedOrganization) {
      return await compose(
        await getEntityCollection<PracticeModel, Practice>(
          PracticeModel.makePracticeCollection(),
          filter
        ),
        [OrganizationModel.make(props.selectedOrganization)]
      );
    }

    return await compose(
      await getEntityCollection<PracticeModel, Practice>(
        PracticeModel.makePracticeCollection(),
        filter
      ),
      await getEntityCollection<OrganizationModel, Organization>(
        OrganizationModel.makeOrganizationCollection(),
        filter
      )
    );
  };

  /**
   * Sets parentEntities array with composed organization and practices collections.
   *
   * @param practices
   * @param organizations
   */
  const compose = async (
    practices: PracticeModel[],
    organizations: OrganizationModel[]
  ) => {
    const entities = [
      ...practices.map((model) => {
        return {
          value: model.attributes.get("id") as string,
          label: model.attributes.get("name"),
        };
      }),
      ...organizations.map((model) => {
        return {
          value: model.attributes.get("id") as string,
          label: model.attributes.get("name"),
        };
      }),
    ];

    await setParentEntities(entities);
  };

  /**
   * Sets userId to be edited and opens user edit modal.
   */
  const editUser = async (user: User) => {
    props.setTargetUser(user.id!);
    const userModel = UserModel.make(user);
    const syncedTargetUser = await UserModel.sync(user.id!);
    const organizationScope = await userModel.organizationScopeIds();
    const practiceScope = await userModel.practiceScopeIds();
    props.onEditUser(
      mapUserTypeToUserAction[userModel.parentType],
      organizationScope,
      practiceScope,
      syncedTargetUser
    );
  };

  const [headContents, setHeaderContents]: any = useState(<></>);
  const [headers, setHeaders] = useState(UserTableHeader);
  /**
   * Reset filter change the sorted state of all columns to `no sort`.
   */
  useEffect(() => {
    setHeaders(
      UserTableHeader.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      })
    );
    setHeaderContents(
      <SortableHead
        headers={headers}
        execSearch={execSearch}
        resourceType={ResourceType.user}
      />
    );
  }, [props.reset]);

  const dataContents = [
    {
      filterUserType: {
        org: "Organization",
        practice: "Practice",
        admin: "Admin",
        all: "All",
      },
    },
    ...(data || []),
  ].map((user, key) => {
    if (key === 0) {
      return (
        <tr key={key}>
          <td
            className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""}
          />
          <td
            className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}
          />
          <td
            className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
          />
          <td
            className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""}
          >
            <div className="d-none">
              <BasicMultiSelect
                data={userTypes}
                onChange={(value: string) => {
                  setUsersSearchFilters({
                    ...props.usersFilter,
                    userType: value,
                  });
                  execSearch({});
                }}
                findValueBy={props.usersFilter.userType}
                isSearchable={false}
                placeholder="All"
                onSelect={(value: string) => {
                  setUsersSearchFilters({
                    ...props.usersFilter,
                    userType: value,
                  });
                  execSearch({});
                }}
              />
            </div>
          </td>
          <td
            className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""}
          ></td>
          <td
            className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""}
          />
          <td
            className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""}
          />
          <td
            className={sortedState.sortedColumnIndex === 7 ? "sorted_col" : ""}
          />
          <td />
        </tr>
      );
    }
    return (
      <tr className="td-text" key={key}>
        <td className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""}>
          <a className="pointer" key={key} onClick={() => editUser(user)}>
            {user.firstName}
          </a>
        </td>
        <td className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}>
          <a key={key} className="pointer" onClick={() => editUser(user)}>
            {user.lastName}
          </a>
        </td>
        <td className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}>
          {user.email}
        </td>
        <td className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""}>
          {((): string => {
            const classification = user.parentEntities[0].classification;
            if (classification === "Root") {
              return "Admin";
            }
            return user.parentEntities[0].type === "Practice"
              ? "Entity/Branch"
              : user.parentEntities[0].type;
          })()}
        </td>
        <td className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""}>
          {user.parentEntities[0].name}
        </td>
        <td className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""}>
          {user?.roleName ?? "-"}
        </td>
        <td className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""}>
          {UserModel.make(user).isSuspended
            ? "DISABLED"
            : user?.state || DEFAULT_STATUS}
        </td>
        <td className={sortedState.sortedColumnIndex === 7 ? "sorted_col" : ""}>
          {user.lastLoggedInAt ? (
            <>
              {dateFormatter({
                date: user.lastLoggedInAt,
                includeTime: true,
                toLocalTimezone: { enabled: true },
              })}
            </>
          ) : (
            DEFAULT_LAST_ACTIVITY
          )}
        </td>
        <td>
          <div className="dropleft btn-group">
            <button
              className=""
              style={{ backgroundColor: "transparent", border: "none" }}
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              data-boundary="window"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="feather icon-more-horizontal" />
            </button>
            <div
              className="dropdown-menu hide-scroll"
              aria-labelledby="dropdownMenuButton"
            >
              {user.isEnabled && (
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleResendInvite(user)}
                >
                  {UserModel.make(user).hasPendingStatus
                    ? "Resend Invite"
                    : "Reset Password"}
                </a>
              )}
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleEnableOrDisableUser(user)}
              >
                {" "}
                {!user.isEnabled ? "Enable" : "Disable"}
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headContents}
        data={dataContents}
        onNavigate={execSearch}
      />
      <Modal
        handleClose={onModalClose}
        show={showModal}
        heading={modalTitle}
        button={""}
        size="lg"
      >
        {showModal && (
          <ResendInviteComponent
            selectedUser={selectedUser}
            modalContent={modalContent}
            onModalClose={onModalClose}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: {
  user: {
    users: User[];
    currentUser: User;
    filters: UsersFilter;
    paginationConfig: PaginationConfig;
    userPageLimit: number;
    sortedState: SortQuery;
  };
  organization: { selectedOrganization: Organization };
}) => {
  return {
    paginationConfig: state.user.paginationConfig,
    pageLimit: state.user.userPageLimit,
    usersFilter: state.user.filters,
    sortedState: state.user.sortedState,
    selectedOrganization: state.organization.selectedOrganization,
    currentUser: state.user.currentUser,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setUsersSearchFilters: (userFilter: UsersFilter) =>
      dispatch(setUsersSearchFilters(userFilter)),
    setTargetUser: (userId: string) => dispatch(setTargetUser(userId)),
  };
};

export const UserTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
