import react from "react";
import { Timeline } from "rsuite";
import { Edit } from "@rsuite/icons";

import { ObservationReason, PatientNoteType } from "@/library/types/note";
import { DeviceType } from "@/domain/patient/view/tabs/chart/components";
import { ObservationModel } from "@/domain/observations/model";
import { AssessmentModel } from "@/domain/assessment/model";
import NoteReason from "@/domain/patient/view/tabs/chart/components/note-reason";
import { dateFormatter } from "@/pipes/date";
import { NoteModel } from "@/domain/notes/model";
import { Note } from "@/domain/notes/model/types";
import { snake2Camel } from "@/pipes/text/index";

interface Props {
  note: Note;
}

interface State {
  threads: Note[];
}

export class NoteThread extends react.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      threads: [],
    };
  }

  async componentDidMount() {
    if (this.props.note && this.props.note.id) {
      if (
        this.props.note.type === PatientNoteType.Assessment &&
        this.props.note.original
      ) {
        await this.resolveAssessment(this.props.note.original);
      }

      if (
        this.props.note.type === PatientNoteType.Observation &&
        this.props.note.original
      ) {
        await this.resolveObservations(this.props.note.original);
      }

      const threads = await NoteModel.syncByReplyTo(
        this.props.note.id!,
        this.props.note.patientId!
      );
      const resolvedThreads = await Promise.all(
        threads.map(async (childNote: Note) => {
          if (childNote.type === PatientNoteType.Observation) {
            await this.resolveObservations(childNote);
          }
          if (childNote.type === PatientNoteType.Assessment) {
            await this.resolveAssessment(childNote);
          }
          return childNote;
        })
      );
      resolvedThreads.unshift(
        this.props.note.original ? this.props.note.original : this.props.note
      );
      this.setState((previousState) => ({
        ...previousState,
        threads: resolvedThreads,
      }));
    }
  }

  resolveAssessment = async (note: Note): Promise<void> => {
    const assessementId = note.assessment?.assessment?.id;
    if (!!assessementId) {
      try {
        note.resolvedAssessment = (
          await AssessmentModel.sync(assessementId)
        ).pluckAll();
      } catch (e) {}
    }
  };

  resolveObservations = async (note: Note): Promise<void> => {
    if (note.observation && note.observation.observations.length) {
      // grab the first observation
      const observation = note.observation.observations[0];
      const refId =
        note.observation.reason === ObservationReason.Manual
          ? note._meta?.id!
          : observation.legacyDocumentId!;

      note.resolvedObservations = await ObservationModel.syncByRefId(
        note.patientId!,
        refId,
        note.observation.reason
      );
    }
  };

  getThreadLocation = (note: Note): string => {
    switch (note.type) {
      case PatientNoteType.Appointment:
        return (note.appointment?.location || "")
          .toLowerCase()
          .replace("_", " ");
      case PatientNoteType.Call:
        return (note.call?.location || "").toLowerCase().replace("_", " ");
      case PatientNoteType.Message:
        return (note.message?.location || "").toLowerCase().replace("_", " ");
      default:
        return "";
    }
  };

  render() {
    return (
      <div className="modal-body">
        <div className="subject-scroll">
          <div className="row">
            <div className="col-12">
              <Timeline className="custom-timeline">
                {this.state.threads.map((thread: Note, index) => (
                  <Timeline.Item
                    key={`timeline${index}`}
                    dot={<Edit color={"blue"} />}
                  >
                    <div className="row">
                      <div className="col-1">Time: </div>
                      <div className="col-11">
                        {dateFormatter({
                          date: thread._meta?.created!,
                          includeTime: true,
                          toLocalTimezone: { enabled: true },
                        })}
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-1" style={{ fontWeight: 100 }}>
                          Comment:
                        </div>
                        <div className="col-11">{thread.comment}</div>
                      </div>
                    </div>
                    <br />
                    {thread.type === PatientNoteType.Assessment &&
                      thread.resolvedAssessment?.questions.map((question) => (
                        <>
                          <div className="row">
                            <div className="col-1" style={{ fontWeight: 100 }}>
                              Question:{" "}
                            </div>
                            <div className="col-11">{` ${question.prompt}`}</div>
                          </div>
                          <div className="row">
                            <div className="col-1" style={{ fontWeight: 100 }}>
                              Answer:{" "}
                            </div>
                            <div className="col-11">{`${question.answer.value}`}</div>
                          </div>
                        </>
                      ))}
                    <br />
                    <div>
                      <div className="row">
                        <div className="col-1" style={{ fontWeight: 100 }}>
                          EventType:
                        </div>
                        <div className="col-11">{NoteReason(thread)}</div>
                      </div>
                    </div>
                    {thread.type === PatientNoteType.Observation &&
                      !!thread.observation &&
                      thread.observation.observations.map(
                        (patientObservation, key) => (
                          <div className="row" key={key}>
                            <div className="col-1" style={{ fontWeight: 100 }}>
                              <DeviceType observation={patientObservation} />
                            </div>
                            <div className="col-11">
                              <div>
                                {patientObservation.value}{" "}
                                {patientObservation.unit}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    <div className="row">
                      <div className="col-1" style={{ fontWeight: 100 }}>
                        Outcomes:
                      </div>
                      <div className="col-11">
                        {thread.intervention?.map((intervention, key) => (
                          <div className="text-capitalize" key={key}>
                            {snake2Camel(intervention)}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1" style={{ fontWeight: 100 }}>
                        Minutes:
                      </div>
                      <div className="col-11">{thread.minutes ?? 0}</div>
                    </div>
                    <div className="row">
                      <div className="col-1" style={{ fontWeight: 100 }}>
                        Contacted:
                      </div>
                      <div className="col-11">
                        {!!thread.directPatientContact ? "YES" : "NO"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1" style={{ fontWeight: 100 }}>
                        Author:
                      </div>
                      <div className="col-11">{`${
                        thread.author ? thread.author?.name : ""
                      }`}</div>
                    </div>
                    <div className="row">
                      <div className="col-1" style={{ fontWeight: 100 }}>
                        Location:
                      </div>
                      <div className="col-11 text-capitalize">
                        {this.getThreadLocation(thread)}
                      </div>
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
