import { PatientModel } from "@/domain/patient/model";
import { mapAccessoryTypeToDisplay } from "@/domain/patient/view/tabs/equipment/constants";
import { AccessoryType, PatientDevice } from "../models/device.interface";

export const attachPatientNameToDevices = async (devices: PatientDevice[]) => {
  const patientIds = devices.map((d) => d.patientId!).filter((id) => id);
  try {
    const patients = (await PatientModel.syncMany(patientIds)).map((pm) =>
      pm.pluckAll()
    );
    devices.forEach((device) => {
      const patient = patients.find((pat) => pat.id === device.patientId);
      device.patient = patient;
    });
  } catch (error) {
    console.error(error);
  }
};

export const peripheralOptions = Object.keys(AccessoryType).map(
  (accessoryType) => {
    return {
      label: mapAccessoryTypeToDisplay[accessoryType as AccessoryType],
      value: accessoryType,
    };
  }
);

export enum EntityType {
  Practice = "Practice",
  Organization = "Organization",
}
