import { PatientNoteType } from "@/library/types/note";
import { Note } from "@/domain/notes/model/types";

function NoteReason(note: Note) {
  const noteType: PatientNoteType = note.type;
  switch (noteType) {
    case PatientNoteType.Observation:
      return `${noteType} ${
        note.observation?.reason.split("_").join(" ") || ""
      }`;
    case PatientNoteType.Call:
      return `${noteType} ${note.call?.location.split("_").join(" ") || ""}`;
    case PatientNoteType.Assessment:
      return `${noteType} ${
        note && note.assessment && note.assessment.reason
          ? note.assessment?.reason?.split("_").join(" ")
          : ""
      }`;
    case PatientNoteType.Message:
      return `${noteType} ${note.message?.location.split("_").join(" ") || ""}`;
    default:
      return `${noteType.split("_").join(" ") || ""}`;
  }
}

export default NoteReason;
