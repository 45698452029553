import React from "react";
import { PatientNoteTypes } from "@/domain/notes/model/constants";
import { Note, NoteDisposition } from "@/domain/notes/model/types";
import { dateFormatter } from "@/pipes/date";
import { snake2Camel } from "@/pipes/text";
import { AlertTypeLabelMap } from "@/domain/patient/view/AlertsTable/constants";
import { ObservationTypes } from "@/domain/patient/model/constants";
import styles from "./styles.module.scss";
import { PatientNoteType } from "@/library/types/note";

type Props = {
  note: Note;
};

const NoteContent: React.FC<Props> = ({ note }) => {
  const { disposition, intervention, comment, repliedToNotes } = note;
  const renderAlertType = (disposition: NoteDisposition): string => {
    let alertType = "";
    if (note.type === PatientNoteType.Reviewed) {
      return alertType;
    }
    switch (disposition) {
      case NoteDisposition.Accepted:
        alertType = "Accepted Alert(s):";
        break;
      case NoteDisposition.Rejected:
        alertType = "Rejected Alert(s):";
        break;
      case NoteDisposition.Historical:
        alertType = "Historical Alert(s):";
        break;
    }
    return alertType;
  };
  const alertType = (
    <b>
      {(repliedToNotes && repliedToNotes.length !== 0) ||
      disposition === NoteDisposition.Historical
        ? renderAlertType(disposition)
        : ""}
    </b>
  );

  const repliedToNote =
    note.type === PatientNoteType.Reviewed ? (
      <p>Reviewed.</p>
    ) : note.type === PatientNoteType.PatientRequest ? (
      <p>
        <b>Request</b>: Patient requested a call from their console.
      </p>
    ) : (
      <ul className={styles.noteReply}>
        {(repliedToNotes || [])
          .filter((reply) => reply.comment)
          .map((reply) => (
            <li key={`reply-${reply._meta?.id}`}>
              {`${dateFormatter({
                date: reply._meta?.created,
                includeTime: true,
                toLocalTimezone: {
                  enabled: true,
                },
              })} ${reply.comment}`}
            </li>
          ))}
      </ul>
    );

  const interventions = note.type !== PatientNoteType.Reviewed && (
    <span>
      {intervention?.length !== 0 && <b>Interventions: </b>}{" "}
      {intervention
        ?.map((noteIntervention) => {
          return snake2Camel(noteIntervention);
        })
        .join(", ")}
    </span>
  );
  const narrative = (
    <span>
      {comment && <b>Narrative:</b>} {comment}
    </span>
  );

  const renderObservationNote = () => {
    if (note.type !== PatientNoteTypes.Observation) return null;
    const { observations = [] } = note.observation || {};
    const observationLabels: string[] = observations.length
      ? observations
          .filter((a) => !!a)
          .map((observation) => {
            const { type, systolic, diastolic, pef, fev1, value, unit } =
              observation;
            let label = "";
            switch (type) {
              case ObservationTypes.BloodPressure:
                if (systolic && diastolic)
                  label = `${systolic.value}/${diastolic.value} ${systolic.unit}`;
                break;
              case ObservationTypes.Spirometry:
                if (pef && fev1)
                  label = `PEF ${pef.value} ${
                    pef.unit
                  } FEV1 ${fev1.value.toFixed(2)} ${fev1.unit}`;
                break;
              default:
                label = `${value} ${unit}`;
                break;
            }

            return `${AlertTypeLabelMap[observation.type]} ${label}`;
          })
      : [];

    return (
      <>
        <div>
          <strong>Direct Observation(s): </strong>
          {`${observationLabels.join(", ")}`}
        </div>
        <div>
          <strong> Intervention: </strong>
          <span>
            {note.intervention?.length ? note.intervention.join(", ") : "-"}
          </span>
        </div>
        <div>
          <strong> Narrative: </strong>
          <span>{note.comment || "-"}</span>
        </div>
      </>
    );
  };

  const renderDefaultNote = () => (
    <span className="mr-1">
      {alertType}
      {repliedToNote} <span className="mr-1">{interventions}</span> {narrative}
    </span>
  );

  return (
    <div className="note-content">
      {note.type === PatientNoteTypes.Observation
        ? renderObservationNote()
        : renderDefaultNote()}
    </div>
  );
};

export default NoteContent;
