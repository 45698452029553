import { extractphone } from "../../../components/form";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { PracticeForm } from "@/domain/practice/model/types";
import { PracticeType } from "../../types/practiceType";
import { Serializeable } from "../serializer";

/**
 * Serializer for modifying Practice.
 */
export class ModifyPracticeSerializer implements Serializeable<PracticeModel> {
  constructor(protected readonly formData: PracticeForm) {}

  serialize(): PracticeModel {
    return PracticeModel.make({
      id: this.formData.id,
      type: this.formData.type as PracticeType,
      name: this.formData.name,
      address: {
        street: [this.formData.address],
        locality: this.formData.city,
        region: this.formData.state,
        postalCode: this.formData.zip,
        country: this.formData.country,
      },
      contact: {
        name: this.formData.contactName,
        position: this.formData.contactPosition || null,
        email: this.formData.contactEmail || null,
        phone: extractphone(this.formData.contactPhone),
      },
      organizationIds: undefined,
    } as unknown as Practice);
  }
}
