import {
  CREATE_ORGANIZATION,
  DESELECT_ORGANIZATION,
  SELECT_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_PAGINATIONCONFIG,
  SET_ORGANIZATION_PAGE_LIMIT,
  SET_TARGET_ORGANIZATION,
  SET_ORGANIZATIONS_SEARCH_FILTER,
  SET_ORGANIZATIONS_SORTED_STATE,
} from "../../../library/constants";
import {
  OrganizationsFilter,
  OrganizationsSortedState,
  PaginationConfig,
} from "../../../library/types";
import { Organization } from "../model/types";

export const createOrganization = (organization: Organization) => ({
  type: CREATE_ORGANIZATION,
  payload: organization,
});

export const setOrganizationsSearchFilters = (payload: OrganizationsFilter) => {
  return {
    type: SET_ORGANIZATIONS_SEARCH_FILTER,
    payload: payload,
  };
};

export const setOrganizationsSortedState = (
  payload: OrganizationsSortedState
) => {
  return {
    type: SET_ORGANIZATIONS_SORTED_STATE,
    payload: payload,
  };
};

export const setOrganizations = (organizations: Organization[]) => {
  return {
    type: SET_ORGANIZATIONS,
    payload: organizations,
  };
};

export const setOrganizationsPaginationConfig = (
  paginationConfig: PaginationConfig
): { type: string; payload: PaginationConfig } => ({
  type: SET_ORGANIZATIONS_PAGINATIONCONFIG,
  payload: paginationConfig,
});

export const setOrganizationPageLimit = (
  organizationPageLimit: number
): { type: string; payload: number } => ({
  type: SET_ORGANIZATION_PAGE_LIMIT,
  payload: organizationPageLimit,
});

export const dispatchSelectOrganization = (organization?: Organization) => {
  return {
    type: SELECT_ORGANIZATION,
    payload: organization,
  };
};

export const deselectOrganization = () => {
  return {
    type: DESELECT_ORGANIZATION,
  };
};

export const setTargetOrganization = (organization?: Organization) => {
  return {
    type: SET_TARGET_ORGANIZATION,
    payload: organization,
  };
};
