import { Chart, ChartOptions, fireEvent } from "highcharts";
import { PatientObservation } from "../../model/types";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const Exporting = require("highcharts/modules/offline-exporting");
Exporting(Highcharts);
export const sortObservationArrayByDate = (array: PatientObservation[]) => {
  let element1Date = 0;
  let element2Date = 0;
  array?.sort((element1, element2) => {
    element1Date = Date.parse(
      new Date(
        element1.timeOfObservation
          ? element1.timeOfObservation
          : element1._meta.created
      ).toISOString()
    );
    element2Date = Date.parse(
      new Date(
        element2.timeOfObservation
          ? element2.timeOfObservation
          : element2._meta.created
      ).toISOString()
    );
    return new Date(element1Date).valueOf() - new Date(element2Date).valueOf();
  });
};
