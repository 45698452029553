export const billingDescription = [
  "Each CPT field displays the number of billable events in the month for that code.",
  "Enter an optional note include in the report.",
  "Click the Create button to generate the detailed report for the selected month.",
];

export const CPTCodeDescription = {
  CPT99453: `Education and setup of a device - Available once for Program`,
  CPT99454: `Number of 30 day billing periods ending within the selected range with at least 16 unique days of readings`,
  CPT99457: `Tracks direct patient contact and the first 20 minutes of clinical time spent during the billing period`,
  CPT99458: `Tracks direct patient contact and the number of 20 minutes segment of clinical time spent during the billing period, with a maximum of 2`,
};
