import { PatientPriority } from "@/domain/patient/model/types";
import { AlertLevel, SeverityLevel } from "@/domain/notes/model/types";
import { BannerColor } from "./types";
import { DateTime } from "luxon";

export const STALE_READING_HOURS = 36;

export const hoursDiff = (time: string) => {
  const dateTime = DateTime.fromISO(time);
  return Math.abs(dateTime.diffNow("hours").as("hours")).toFixed(0);
};

export const STALE_READING_TOOLTIP_MESSAGE = (time?: string) => {
  if (time) {
    return `No new transmission in ${hoursDiff(time)} hours.`;
  }
  return ``;
};

export const BannerColors = {
  YELLOW: "#feff05",
  RED: "#ff0505e3",
  BLUE: "#4572c4",
  GREEN: "#04b855",
  DEFAULT: "white",
  TOMATO: "rgba(255, 0, 0, 0.795)",
  BLACK: "black",
  GRAY: "#cccccc",
};

export const bannerBorderColor = (priority: PatientPriority) => {
  switch (priority) {
    case PatientPriority.Low:
      return BannerColors.GREEN;
    case PatientPriority.Medium:
      return BannerColors.YELLOW;
    case PatientPriority.High:
      return BannerColors.RED;
    default:
      return BannerColors.BLUE;
  }
};

const iconColor: Partial<Record<AlertLevel, BannerColor>> = {
  [AlertLevel.NO_ALERT as AlertLevel]: BannerColors.DEFAULT,
  [AlertLevel.GREEN_ALERT as AlertLevel]: BannerColors.GREEN,
  [AlertLevel.YELLOW_ALERT as AlertLevel]: BannerColors.YELLOW,
  [AlertLevel.RED_ALERT as AlertLevel]: BannerColors.TOMATO,
  [AlertLevel.NO_ALERT as AlertLevel]: BannerColors.DEFAULT,
  [AlertLevel.BLACK_ALERT as AlertLevel]: BannerColors.BLACK,
  [AlertLevel.DISABLED_ALERT as AlertLevel]: BannerColors.GRAY,
};
export const getBannerIconColor = (alertLevel: AlertLevel) => {
  return iconColor[alertLevel];
};

export const getBannerColorByLastObservation = (time: string | undefined) => {
  if (time) {
    if (parseInt(hoursDiff(time)) > STALE_READING_HOURS)
      return AlertLevel.BLACK_ALERT;
  }
};

export const getBannerColorBySeverityScore = (
  severityScore: number | undefined
) => {
  let alertLevel = AlertLevel.NO_ALERT;
  if (severityScore !== undefined) {
    if (severityScore === 0) {
      alertLevel = AlertLevel.GREEN_ALERT;
    } else if (severityScore >= SeverityLevel.SEVERITY_RED) {
      alertLevel = AlertLevel.RED_ALERT;
    } else if (severityScore >= SeverityLevel.SEVERITY_YELLOW) {
      alertLevel = AlertLevel.YELLOW_ALERT;
    }
  }
  return alertLevel;
};
export const getLabelBySeverityScore = (
  severityScore: number | undefined,
  short = false
) => {
  if (severityScore === undefined) return short ? "-" : "No Alert";
  const label = short ? "" : " Alert";

  if (severityScore >= SeverityLevel.SEVERITY_RED) {
    return `High${label}`;
  } else if (severityScore >= SeverityLevel.SEVERITY_YELLOW) {
    return `Medium${label}`;
  } else if (severityScore !== undefined) {
    return `Low${label}`;
  }

  return "-";
};
