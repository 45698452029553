import { Box, TextField } from "@mui/material";
import { ChangeEventHandler, createRef, FC, useEffect } from "react";
import { useGlobalAppContext } from "@/components/context";

export interface VirtualVisitNoteProps {
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
export const VirtualVisitNote: FC<VirtualVisitNoteProps> = ({ onChange }) => {
  const { virtualVisitNote } = useGlobalAppContext();
  const textInput = createRef<HTMLDivElement>();
  const focusTextInput = () => {
    textInput.current?.focus();
  };
  useEffect(() => {
    focusTextInput();
  }, [focusTextInput]);

  return (
    <Box>
      <TextField
        onChange={onChange}
        value={virtualVisitNote!}
        sx={(theme) => ({ fontSize: theme.spacing(1) })}
        label="Virtual visit note"
        multiline
        fullWidth
        rows={16}
        placeholder="Note will be automatically saved when you leave the call...."
        variant="standard"
        inputRef={textInput}
      />
    </Box>
  );
};
