import React, { useEffect, useState } from "react";
import {
  Cell,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { NoteDispositions } from "@/domain/notes/model/constants";
import { NoteDisposition } from "@/domain/notes/model/types";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import { makeColumns, Row as CustomRow } from "./helpers";

import { AxiosResponse } from "axios";
import { Assessment } from "@/domain/assessment/model/types";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";

export type Props = {
  data: AlertTableRow[];
  disposition?: NoteDisposition;
};

export const AlertsOverview: React.FC<Props> = (props: Props) => {
  const [alertData, setAlertData] = useState<AlertTableRow[]>(props.data);
  useEffect(() => {
    (async () => {
      setAlertData(
        await Promise.all(
          alertData.map(async (assessment: AlertTableRow) => {
            if (assessment.assessmentId) {
              const response: AxiosResponse<Assessment> = await Http().get(
                `${RemoteCareAPISync.host}/assessments/${assessment.assessmentId}`
              );
              assessment.assessmentQuestions = response.data.questions;
              return assessment;
            }
            return assessment;
          })
        )
      );
    })();
  }, []);
  const { disposition } = props;
  let disLabel = "";
  if (disposition === NoteDispositions.Rejected) disLabel = "Rejecting ";
  else if (disposition === NoteDispositions.Accepted) disLabel = "Accepting ";

  const columns = makeColumns();
  const table = useReactTable<AlertTableRow>({
    data: alertData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const { rows } = table.getRowModel();

  return rows.length ? (
    <div className="form-group">
      <label>{`${disLabel}Alert${alertData.length === 1 ? "" : "s"} (${
        alertData.length
      })`}</label>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Type</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Severity</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertData.map((row) => (
              <CustomRow key={row.type} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : null;
};
