import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { days, frequencies, types } from "./options";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material/";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import { Program } from "@/domain/program/model/types";
import {
  AssessmentTemplate,
  AssessmentTemplateMetadata,
} from "@/domain/assessment/model/types";
import {
  AssessmentAction,
  Automation,
  EnrollmentDayTrigger,
  ScheduleTrigger,
} from "@/domain/automation/model/types";
import { Organization } from "@/domain/organization/model/types";
import { RemoteCareAPISync } from "@/library/core/api";
import { Http } from "@/library/core/api/http";
import {
  resolveAssessmentTemplate,
  attachMissingMetadataFields,
} from "../../functions";
import { AssessmentTemplateModel } from "@/domain/assessment/templates/model";

export const automationSortFunc = (a: Automation, b: Automation) => {
  const dayA = (a.actions as AssessmentAction[])[0]?.assessmentTemplate
    ?.metadata?.preferredDays;
  const dayB = (b.actions as AssessmentAction[])[0]?.assessmentTemplate
    ?.metadata?.preferredDays;

  if (dayA && dayB && dayA.length && dayB.length) {
    return daysOfWeek[dayA[0]] - daysOfWeek[dayB[0]];
  }

  return -1;
};

export const daysOfWeek = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

type MetadataFormProps = {
  organization: Organization;
  program: Program;
  save: (automations: Automation[]) => Promise<void>;
};

export function padNumber(n: number) {
  return String(n).padStart(2, "0");
}

export const parseTime = (time: string): { hour: number; minute: number } => {
  const parts = time.split(":");
  return { hour: parseInt(parts[0], 10), minute: parseInt(parts[1], 10) };
};

const fetchAssessmentTemplates = async (): Promise<AssessmentTemplate[]> => {
  return (await AssessmentTemplateModel.getAllAssessmentTemplates({})).map(
    (model: AssessmentTemplateModel) => model.pluckAll()
  );
};

async function filterTemplates(
  types: AssessmentTemplateMetadata["types"],
  frequencies: AssessmentTemplateMetadata["frequencies"],
  preferredDays: AssessmentTemplateMetadata["preferredDays"]
) {
  const templates: AssessmentTemplate[] = await fetchAssessmentTemplates();
  templates.forEach((template: AssessmentTemplate) =>
    attachMissingMetadataFields(template)
  );
  if (types && frequencies && preferredDays) {
    const matchingTemplates = templates
      .filter((template: AssessmentTemplate) => !!template.metadata)
      .filter(
        (template) =>
          (!types.length ||
            types.some((type) =>
              (template.metadata.types || []).includes(type)
            )) &&
          (!frequencies.length ||
            frequencies.some((frequency) =>
              (template.metadata.frequencies || []).includes(frequency)
            )) &&
          (!preferredDays.length ||
            preferredDays.some((day) =>
              (template.metadata.preferredDays || []).includes(day)
            ))
      );
    return matchingTemplates;
  }
  return [];
}

export const PathwayAutomations: React.FC<MetadataFormProps> = ({
  organization,
  program,
  save,
}) => {
  const [automations, setAutomations] = useState<Automation[]>([]);
  const [assessmentTemplatesMetadata, setAssessmentTemplatesMetadata] =
    useState<AssessmentTemplateMetadata[]>([]);

  useEffect(() => {
    (async () => {
      if (program.automations !== null) {
        const assessmentAutomations = program.automations
          .filter((a) => a.actions[0].type === "START_ASSESSMENT")
          .filter((a) =>
            ["SCHEDULE", "ENROLLMENT_DAY"].includes(a.triggers[0].type)
          );
        for (const assessmentAutomationsKey in assessmentAutomations) {
          const action = assessmentAutomations[assessmentAutomationsKey]
            .actions[0] as AssessmentAction;
          if (action.assessmentId !== null) {
            action.assessmentTemplate = await resolveAssessmentTemplate(
              action.assessmentId
            );
          }
        }
        const sortedAssessmentAutomations =
          assessmentAutomations.sort(automationSortFunc);
        setAutomations([...sortedAssessmentAutomations]);
      }
    })();
  }, [program]);

  useEffect(() => {
    if (automations) {
      const assessmentsMetadata = automations.map(
        (a) =>
          (
            (a.actions[0] as AssessmentAction)
              .assessmentTemplate as AssessmentTemplate
          ).metadata
      );
      setAssessmentTemplatesMetadata(assessmentsMetadata);
    }
  }, [automations]);

  const addRow = async () => {
    const template: AssessmentTemplate = (await fetchAssessmentTemplates())[0];
    setAutomations([
      ...automations,
      {
        ...{
          triggers: [
            {
              type: "ENROLLMENT_DAY",
              day: 0,
            } as EnrollmentDayTrigger,
          ],
          actions: [
            {
              type: "START_ASSESSMENT",
              assessmentId: template._meta?.id,
              assessmentTemplate: template,
            } as unknown as AssessmentAction,
          ],
        },
      },
    ]);
  };

  const deleteRow = (index: number) => {
    if (automations) {
      const newAutomations = automations.filter(
        (automation, idx) => idx !== index
      );
      setAutomations(newAutomations);
    }
  };

  return (
    <Box
      sx={(theme) => ({ marginTop: theme.spacing(1) })}
      display="flex"
      flexDirection="column"
    >
      <Box
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          position: "relative",
          maxHeight: "65vh",
        }}
      >
        {automations?.map((automation, index) => {
          const row = (
            (automation.actions[0] as AssessmentAction)
              .assessmentTemplate as AssessmentTemplate
          )?.metadata;
          if (row) {
            return (
              <Stack
                sx={(theme) => ({ marginTop: theme.spacing(1) })}
                direction="row"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Autocomplete
                  disableClearable
                  options={types}
                  value={row.types ? row.types[0] : "SURVEY"}
                  onChange={async (e, newValue) => {
                    const updatedAutomations = [...automations]; // make a copy of the automations array

                    let triggers: (EnrollmentDayTrigger | ScheduleTrigger)[] =
                      [];
                    if (["WELCOME", "SURVEY"].includes(newValue || "")) {
                      triggers = [
                        {
                          type: "ENROLLMENT_DAY",
                          day: 0,
                        } as EnrollmentDayTrigger,
                      ];
                      row.preferredDays = [];
                    } else {
                      triggers = [
                        {
                          type: "SCHEDULE",
                          occurrences: [
                            {
                              day: "SUNDAY",
                              hour: 9,
                              minute: 0,
                            },
                          ],
                        } as unknown as ScheduleTrigger,
                      ];
                    }

                    const updatedAutomation = automations[index];
                    const action = updatedAutomation
                      .actions[0] as AssessmentAction;
                    const template = action.assessmentTemplate;
                    if (template?.metadata) {
                      const metadata = template?.metadata;
                      updatedAutomation.actions = [
                        {
                          ...action,
                          assessmentTemplate: {
                            ...template,
                            metadata: {
                              ...metadata,
                              types: newValue
                                ? ([
                                    newValue,
                                  ] as AssessmentTemplateMetadata["types"])
                                : [],
                            },
                          },
                        },
                      ];
                      automations[index] = updatedAutomation;
                      setAutomations([...automations]);

                      // Update automation action with first assessment template that matches new search criteria
                      const templates = await filterTemplates(
                        newValue
                          ? ([
                              newValue,
                            ] as unknown as AssessmentTemplateMetadata["types"])
                          : [],
                        row.frequencies,
                        row.preferredDays
                      );
                      if (templates.length) {
                        const assessment = templates[0];
                        updatedAutomation.actions[0] = {
                          type: "START_ASSESSMENT",
                          assessmentId: assessment.id,
                          assessmentTemplate: assessment,
                        } as AssessmentAction;
                      }
                    }

                    updatedAutomation.triggers = triggers;

                    updatedAutomations[index] = updatedAutomation;
                    setAutomations(updatedAutomations);
                  }}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Type"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FilterListIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ width: "3000px" }}
                />
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={frequencies}
                  value={row.frequencies ? row.frequencies[0] : "REPEAT"}
                  onChange={(e, newValue) => {
                    const updatedAutomation = automations[index];
                    const action = updatedAutomation
                      .actions[0] as AssessmentAction;
                    const template = action.assessmentTemplate;
                    if (template?.metadata) {
                      const metadata = template?.metadata;
                      updatedAutomation.actions = [
                        {
                          ...action,
                          assessmentTemplate: {
                            ...template,
                            metadata: {
                              ...metadata,
                              frequencies: newValue
                                ? ([
                                    newValue,
                                  ] as AssessmentTemplateMetadata["frequencies"])
                                : [],
                            },
                          },
                        },
                      ];
                      automations[index] = updatedAutomation;
                      setAutomations([...automations]);
                    }
                  }}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Frequency"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <FilterListIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  sx={{ width: "2500px" }}
                />
                {!["WELCOME", "SURVEY"].includes(
                  row.types ? row.types[0] : ""
                ) ? (
                  <Autocomplete
                    disableClearable
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    options={days}
                    value={row.preferredDays ? row.preferredDays[0] : "MONDAY"}
                    onChange={(e, newValue) => {
                      const updatedAutomation = automations[index];
                      const action = updatedAutomation
                        .actions[0] as AssessmentAction;
                      const template = action.assessmentTemplate;
                      if (template?.metadata) {
                        const metadata = template?.metadata;
                        updatedAutomation.actions = [
                          {
                            ...action,
                            assessmentTemplate: {
                              ...template,
                              metadata: {
                                ...metadata,
                                preferredDays: newValue
                                  ? ([
                                      newValue,
                                    ] as AssessmentTemplateMetadata["preferredDays"])
                                  : [],
                              },
                            },
                          },
                        ];
                        updatedAutomation.triggers = newValue
                          ? ([
                              {
                                type: "SCHEDULE",
                                occurrences: [
                                  {
                                    day: newValue,
                                    hour: 9,
                                    minute: 0,
                                  },
                                ],
                              },
                            ] as unknown as ScheduleTrigger[])
                          : [];
                        automations[index] = updatedAutomation;
                        setAutomations([...automations]);
                      }
                    }}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Day"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    sx={{ width: "2200px" }}
                  />
                ) : (
                  <TextField
                    InputProps={{ inputProps: { min: 0 } }}
                    value={(automation.triggers[0] as EnrollmentDayTrigger).day}
                    type="number"
                    label="Enrollment Days"
                    variant="outlined"
                    sx={{ width: "1000px" }}
                    onChange={(e) => {
                      const trigger = automation
                        .triggers[0] as EnrollmentDayTrigger;
                      trigger.day = Number(e.target.value);
                      const action = automations[index]
                        .actions[0] as AssessmentAction;
                      if (action && action.assessmentTemplate) {
                        action.assessmentId = action.assessmentTemplate?._meta
                          ?.id as string;
                      }
                      setAutomations([...automations]);
                    }}
                  />
                )}

                {automation.triggers[0] &&
                  automation.triggers[0].type === "SCHEDULE" && (
                    <TextField
                      value={(() => {
                        const occurrence =
                          automation.triggers[0].occurrences[0];
                        if (occurrence) {
                          const time = `${padNumber(
                            occurrence.hour
                          )}:${padNumber(occurrence.minute)}`;
                          return time;
                        } else {
                          return "09:00";
                        }
                      })()}
                      type="time"
                      variant="outlined"
                      label="Time"
                      sx={{ width: "2100px" }}
                      onChange={(event) => {
                        const time = event.target.value;
                        const updatedRows = [...assessmentTemplatesMetadata];
                        updatedRows[index].preferredTimes = [time];
                        setAssessmentTemplatesMetadata(updatedRows);
                        const trigger = automation
                          .triggers[0] as ScheduleTrigger;
                        const parsedTime = parseTime(time);
                        if (trigger.occurrences[0]) {
                          trigger.occurrences[0].hour = parsedTime.hour;
                          trigger.occurrences[0].minute = parsedTime.minute;
                        }
                        setAutomations([...automations]);
                      }}
                    />
                  )}

                <Autocomplete
                  disableClearable
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  sx={{ width: "2500px" }}
                  value={{
                    id: (automation.actions[0] as AssessmentAction)
                      .assessmentTemplate?.id,
                    label: (automation.actions[0] as AssessmentAction)
                      .assessmentTemplate?.name,
                  }}
                  options={
                    row.pathwayTemplateOptions
                      ? row.pathwayTemplateOptions.map((p) => ({
                          id: p._meta?.id,
                          label: p.name,
                        }))
                      : []
                  }
                  loading={row.pathwayTemplateOptions === null}
                  onOpen={async () => {
                    row.pathwayTemplateOptions = await filterTemplates(
                      row.types,
                      row.frequencies,
                      row.preferredDays
                    );
                    setAutomations([...automations]);
                  }}
                  onClose={async () => {
                    row.pathwayTemplateOptions = null;
                    setAutomations([...automations]);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Pathway" />
                  )}
                  onChange={async (e, newValue) => {
                    if (newValue) {
                      const action = {
                        type: "START_ASSESSMENT",
                        assessmentId: newValue.id,
                        assessmentTemplate: await resolveAssessmentTemplate(
                          newValue.id as string,
                          row
                        ),
                      } as unknown as AssessmentAction;
                      automation.actions = [action];
                      setAutomations([...automations]);
                    }
                  }}
                />
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <IconButton onClick={() => deleteRow(index)} color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Stack>
            );
          }
        })}
      </Box>
      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <IconButton
          onClick={async () => {
            await addRow();
          }}
          color="primary"
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => await save(automations)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
