import { RCAResourceActions } from "../../core/config/actions";
import { Receiver } from "../receiver/receiver";
import { Success } from "./success";

/**
 * Success/Failure Event Handler.
 */
export abstract class Handler<T> {
  constructor(protected successMessage?: string) {}

  abstract handle(
    event: Error | Success,
    receiver?: Partial<Receiver<T>>,
    action?: RCAResourceActions
  ): Promise<void>;
}
