import {
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
  HeaderContext,
} from "@tanstack/react-table";

import { AlertTableRow } from "./types";

type Props = {
  renderCheckboxHeader: ColumnDefTemplate<
    HeaderContext<AlertTableRow, unknown>
  >;
  renderCheckboxCell: ColumnDefTemplate<CellContext<AlertTableRow, unknown>>;
  renderDateCell: ColumnDefTemplate<CellContext<AlertTableRow, unknown>>;
  renderLevelCell: ColumnDefTemplate<CellContext<AlertTableRow, unknown>>;
  renderTypeCell: ColumnDefTemplate<CellContext<AlertTableRow, unknown>>;
  renderResponseCell: ColumnDefTemplate<CellContext<AlertTableRow, unknown>>;
};

export const makeColumns = (props: Props): ColumnDef<AlertTableRow>[] => {
  const {
    renderCheckboxHeader,
    renderCheckboxCell,
    renderDateCell,
    renderLevelCell,
    renderTypeCell,
    renderResponseCell,
  } = props;

  return [
    {
      id: "select",
      header: renderCheckboxHeader,
      cell: renderCheckboxCell,
    },
    {
      id: "created",
      header: "Date/Time",
      accessorKey: "created",
      cell: renderDateCell,
      minSize: 100,
    },
    {
      id: "level",
      header: "Level",
      accessorKey: "severityScore",
      cell: renderLevelCell,
    },
    {
      id: "type",
      header: "Type",
      accessorKey: "type",
      cell: renderTypeCell,
    },
    {
      id: "response",
      header: "Result/Response",
      accessorKey: "comment",
      cell: renderResponseCell,
      minSize: 400,
    },
  ];
};
