import { PaginationProps } from "./types";

export default function DatatablePagination(props: PaginationProps) {
  const { recordsPerPage, totalRecords, paginate } = props;
  const pageNumbers = [];

  for (
    let pages = 1;
    pages <= Math.ceil(totalRecords / recordsPerPage);
    pages++
  ) {
    pageNumbers.push(pages);
  }

  return (
    <div className="row" style={{ padding: "5px 0px" }}>
      <div className="col-md-6">
        <nav aria-label="...">
          <ul className="pagination">
            <li
              className={
                props.currentPage === 1
                  ? "page-item disabled"
                  : "page-item pointer"
              }
            >
              <a
                onClick={() => paginate(props.currentPage - 1)}
                className="page-link"
              >
                Previous
              </a>
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  props.currentPage === number
                    ? "page-item pointer active"
                    : "page-item pointer"
                }
              >
                <a onClick={() => paginate(number)} className="page-link">
                  {number}
                </a>
              </li>
            ))}
            <li
              className={
                props.currentPage === pageNumbers.length
                  ? "page-item disabled"
                  : "page-item pointer"
              }
            >
              <a
                onClick={() => paginate(props.currentPage + 1)}
                className="page-link"
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col-md-6 col-sm-12 col-lg-6 d-flex justify-content-end">
        <div className="p-2">Display</div>
        <div className="form-inline" style={{ height: 30 }}>
          <select
            value={props.recordsPerPage}
            onChange={(e: any) => {
              props.setRecordPerPage(e.target.value);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="p-2">Records</div>
      </div>
    </div>
  );
}
