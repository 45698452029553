import { CellContext } from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import cx from "clsx";

import { ObservationTypes } from "@/domain/patient/model/constants";
import { getSeverityAlertLevel } from "@/domain/notes/model/constants";
import { AlertLevel } from "@/domain/notes/model/types";
import { dateFormatter } from "@/pipes/date";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import {
  AccessoryEntryGraph,
  ClinicianEntryGraph,
  PatientEntryGraph,
} from "@/assets/icons/dashboard";
import { formatDateChart } from "../../tabsNew/monitoring/helpers";
import {
  ObservationsUnitAbbrevationNew,
  ObservationType,
  ObservationTypeNew,
} from "@/domain/observations/types";

export type Props = {
  info: CellContext<any, any>;
  date: string;
  hoveredCellId?: string;
  onHover: () => void;
  onLeave: () => void;
};

export const ObservationCellNew: React.FC<Props> = (props) => {
  const { info, date, hoveredCellId, onHover, onLeave } = props;
  const { row, cell } = info;
  const { original } = row;
  const { type, subType } = original;
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    let element = document.getElementById(`${cell.id}button`);
    if (element) {
      let rect = element.getBoundingClientRect();
      setLeft(rect.left);
      setTop(rect.top);
    }
  }, [cell.id]);

  const valueObj = original[date] || {};
  const { severityScore } = valueObj;
  const alertLevel = getSeverityAlertLevel(severityScore);
  let { value } = valueObj;
  const { biometricEntryIcon } = valueObj;
  const timeOfObservation = dateFormatter({
    date: valueObj.timeOfObservation,
    includeTime: true,
    toLocalTimezone: {
      enabled: true,
    },
  });

  const renderWarning = (value: string) => (
    <span className={cx(styles.mediumValue)}>{value}</span>
  );
  const renderDanger = (value: string) => (
    <span className={cx(styles.highValue)}>{value}</span>
  );
  const renderSuccess = (value: string) => (
    <span className={cx(styles.normalValue)}>{value}</span>
  );

  if (!value) return <span className={styles.cellNoData}>{`-`}</span>;

  switch (type) {
    case ObservationTypes.BloodPressure:
      const split = value?.split("/");
      let first = split[0];
      let second = split[1];
      if (alertLevel === AlertLevel.RED_ALERT) {
        first = renderDanger(first);
        second = renderDanger(second);
      } else if (alertLevel === AlertLevel.YELLOW_ALERT) {
        first = renderWarning(first);
        second = renderWarning(second);
      } else if (alertLevel === AlertLevel.GREEN_ALERT) {
        first = renderSuccess(first);
        second = renderSuccess(second);
      }

      value = (
        <>
          {first}
          <label className={cx(styles.dividerSlash)}>/</label>
          {second}
        </>
      );
      break;
    default:
      if (alertLevel === AlertLevel.RED_ALERT) value = renderDanger(value);
      else if (alertLevel === AlertLevel.YELLOW_ALERT)
        value = renderWarning(value);
      else if (alertLevel === AlertLevel.GREEN_ALERT)
        value = renderSuccess(value);
      break;
  }

  let unit: string | undefined;
  switch (type) {
    case ObservationType.BloodPressure:
      unit = ObservationsUnitAbbrevationNew[ObservationType.BloodPressure];
      break;
    case ObservationType.Spirometry:
      if (subType) {
        unit =
          subType === "fev1"
            ? ObservationsUnitAbbrevationNew[ObservationTypeNew.Spirometry]
            : ObservationsUnitAbbrevationNew[ObservationTypeNew.SpirometryPEF];
      } else {
        unit = ObservationsUnitAbbrevationNew[ObservationType.Spirometry];
      }
      break;
    case ObservationType.GlucoseLevel:
      unit = ObservationsUnitAbbrevationNew[ObservationType.GlucoseLevel];
      break;
    case ObservationType.HeartRate:
      unit = ObservationsUnitAbbrevationNew[ObservationType.HeartRate];
      break;
    case ObservationType.PulseOximetry:
      unit = ObservationsUnitAbbrevationNew[ObservationType.PulseOximetry];
      break;
    case ObservationType.Temperature:
      unit = ObservationsUnitAbbrevationNew[ObservationType.Temperature];
      break;
    case ObservationType.Weight:
      unit = ObservationsUnitAbbrevationNew[ObservationType.Weight];
      break;
    default:
      unit = unit;
  }

  const focused = hoveredCellId === cell.id;

  return (
    <>
      <button
        onFocus={onHover}
        onBlur={onLeave}
        onMouseOver={onHover}
        onMouseOut={onLeave}
        onMouseLeave={onLeave}
        className={styles.buttonReset}
        id={`${cell.id}button`}
      >
        <span data-tooltip-id={cell.id} className={styles.cell}>
          {value}
        </span>
      </button>

      {focused ? (
        <Tooltip
          isOpen
          id={`#${cell.id}`}
          className={styles.tooltip}
          style={{ top: top, left: left - 65 }}
        >
          <div className={styles.arrowTooltip}></div>
          <img src={biometricEntryIcon} alt="tooltipIcon" />
          <div className={cx(styles.tooltipText, styles.value)}>
            {value}
            <label className={cx(styles.unitText)}>{unit}</label>
          </div>
          <div className={styles.tooltipDate}>
            {formatDateChart(valueObj.timeOfObservation)}
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};
