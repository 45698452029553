export const types = ["DAY OF WEEK", "WELCOME", "SURVEY"];
export const frequencies = ["REPEAT", "ONCE"];
export const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const times = ["MORNING", "AFTERNOON", "EVENING"];
