import axios, { AxiosError, AxiosInstance } from "axios";
import { AxiosResponse } from "axios";

/**
 * Configure and return axios instance.
 *
 * @constructor
 */
export const Http = (): AxiosInstance => {
  const axiosInstance = axios.create({
    headers: {
      "Accept-Version": "v1",
    },
    withCredentials: true,
  });

  /**
   * @Interceptor
   * Axios response interceptor can intercept the API response
   */
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      unauthorizedRedirect(response);
      return response;
    },
    (error: AxiosError) => {
      if (error.response) unauthorizedRedirect(error.response);
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const unauthorizedRedirect = (response: AxiosResponse) => {
  const { _env_ } = window as typeof window & {
    _env_: Record<string, string>;
  };

  if (response.status === 401) {
    window.location.assign(
      `${_env_.AUTH_URL}/auth/login?callback=${_env_.MAVERICK_URL}`
    );
  }
};
