export class Str {
  public static CapitalizeFirstLetter(str: string) {
    return str.replace(/^./, str[0].toUpperCase());
  }

  public static Truncate(string: string, length: number) {
    const truncated = string.substring(0, length);
    return truncated + (string.length > length ? "..." : "");
  }
}
