import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Attributes, deserialize, Model } from "@/library/model";
import { OrganizationSerializer } from "@/library/serializers/organization";
import { ModelCollection } from "@/library/model/model.collection";
import { RCAResourceActions } from "@/library/core/config/actions";
import { OrganizationType } from "@/library/types";
import { Http } from "@/library/core/api/http";
import {
  Organization,
  OrganizationForm,
  PostSetParameterOverrides,
  PostUnsetParameterOverrides,
} from "./types";
import { SupportiveInformation } from "@/domain/practice/model/types";

export class OrganizationModel extends Model<Organization> {
  static readonly path = "organizations";

  /**
   * Makes a new instance of OrganizationModel. It requires only the attributes
   * that would be used to instantiate the new Organization Model.
   *
   * @param attributes
   */
  static make(attributes: Organization): OrganizationModel {
    return new OrganizationModel(
      new Attributes<Organization>(attributes),
      new RemoteCareAPISync<Organization>(OrganizationModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of organizations using the provided resource url and a deserializer.
   * The entire organization list can be accessed in the models member of the Collection class.
   */
  static makeOrganizationCollection(): ModelCollection<
    OrganizationModel,
    Organization
  > {
    return new ModelCollection<OrganizationModel, Organization>(
      `${RemoteCareAPISync.host}/${OrganizationModel.path}`,
      (entity: Organization) => {
        const attributes = { ...entity, id: entity._meta?.id };
        return OrganizationModel.make(attributes);
      }
    );
  }

  /**
   * Sync organization by Id.
   * @param id
   */
  static sync = async (id: string): Promise<OrganizationModel> =>
    deserialize(
      (
        await new RemoteCareAPISync<Organization>(OrganizationModel.path).fetch(
          id
        )
      ).data,
      OrganizationModel.make
    );

  /**
   * Takes a form data and serializes it based on intended RemoteCareAPI Resource action.
   *
   * @param data
   * @param action
   */
  static serialize = (
    data: OrganizationForm,
    action: RCAResourceActions
  ): OrganizationModel => {
    return OrganizationSerializer.for(action, data).serialize();
  };

  get isRootOganization() {
    return this.pluck("type") === OrganizationType.Root;
  }

  static async setParameterOverrides(
    orgId: string,
    payload: PostSetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${OrganizationModel.path}/${orgId}/actions/set-parameter-override`,
      payload
    );
  }

  static async unsetParameterOverrides(
    orgId: string,
    payload: PostUnsetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${OrganizationModel.path}/${orgId}/actions/unset-parameter-override`,
      payload
    );
  }

  static updateSupportInformation = async (
    orgId: string,
    supportInformation: SupportiveInformation
  ) => {
    const path = `${RemoteCareAPISync.host}/${OrganizationModel.path}/${orgId}/actions/set-support-info`;
    await Http().post(path, supportInformation);
  };
}
