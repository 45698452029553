import { EventEmitter } from "@/library/core/event";
import { RemoteCareAPISync } from "@/library/core/api";
import {
  Attributes,
  deserialize,
  Model,
  ModelCollection,
} from "@/library/model";
import { AssessmentTemplate } from "@/domain/assessment/model/types";

export class AssessmentTemplateModel extends Model<AssessmentTemplate> {
  static readonly path = "assessment-templates";
  /**
   * Makes a new instance of AssessmentTemplateModel. It requires only the attributes
   * that would be used to instantiate new Assessment Template Model.
   *
   * @param attributes
   */
  static make(attributes: AssessmentTemplate): AssessmentTemplateModel {
    const assessmentTemplateModel = new AssessmentTemplateModel(
      new Attributes<AssessmentTemplate>({ ...attributes }),
      new RemoteCareAPISync<AssessmentTemplate>(AssessmentTemplateModel.path),
      new EventEmitter()
    );

    return assessmentTemplateModel;
  }

  /**
   * Sync Assessment Template by Id.
   * @param id
   */
  static sync = async (id: string): Promise<AssessmentTemplateModel> =>
    deserialize(
      (
        await new RemoteCareAPISync<AssessmentTemplate>(
          AssessmentTemplateModel.path
        ).fetch(id)
      ).data,
      AssessmentTemplateModel.make
    );

  static async getAllAssessmentTemplates(
    params: {
      [key: string]: string | string[] | boolean;
    } = {}
  ): Promise<AssessmentTemplateModel[]> {
    const iterator =
      await AssessmentTemplateModel.makeAssessmentTemplateCollection()
        .withParams(params)
        .getMany();

    return iterator.getAll();
  }

  static makeAssessmentTemplateCollection(): ModelCollection<
    AssessmentTemplateModel,
    AssessmentTemplate
  > {
    return new ModelCollection<AssessmentTemplateModel, AssessmentTemplate>(
      `${RemoteCareAPISync.host}/${AssessmentTemplateModel.path}`,
      (entity: AssessmentTemplate) =>
        deserialize(entity, AssessmentTemplateModel.make)
    );
  }
}
