import { ObservationType } from "@/domain/observations/types";

export enum RuleType {
  Boolean = "BOOLEAN",
  Enabled = "ENABLED",
  Number = "NUMBER",
  String = "STRING",
  Threshold = "THRESHOLD",
  ThresholdAbove = "THRESHOLD_ABOVE",
  ThresholdAboveHigh = "THRESHOLD_ABOVE_HIGH",
  ThresholdAboveMedium = "THRESHOLD_ABOVE_MEDIUM",
  ThresholdBelow = "THRESHOLD_BELOW",
  ThresholdBelowHigh = "THRESHOLD_BELOW_HIGH",
  ThresholdBelowMedium = "THRESHOLD_BELOW_MEDIUM",
  ThresholdOverTime = "THRESHOLD_OVER_TIME",
}

export type VariableDefaultObject = {
  high?: number | null;
  medium?: number | null;
  low?: number | null;
  assessmentId?: string | null;
};

export type VariableDefault =
  | number
  | string
  | boolean
  | null
  | undefined
  | VariableDefaultObject;

export type RuleVariable = {
  type: RuleType;
  default: VariableDefault;
  displayName?: string;
};

export type RuleEnabledKey = {
  isEnabled: {
    type: RuleType;
    default: boolean;
    displayName: string;
  };
  highGlucoseLevelDiscrete?: RuleVariable;
  lowGlucoseLevelDiscrete?: RuleVariable;
  oxygenSaturationDiscrete?: RuleVariable;
  highTemperatureDiscrete?: RuleVariable;
  lowTemperatureDiscrete?: RuleVariable;
  highHeartRateDiscrete?: RuleVariable;
  lowHeartRateDiscrete?: RuleVariable;
  systolicHighBloodPressureDiscrete?: RuleVariable;
  systolicLowBloodPressureDiscrete?: RuleVariable;
  diastolicHighBloodPressureDiscrete?: RuleVariable;
  diastolicLowBloodPressureDiscrete?: RuleVariable;
  fev1Baseline?: RuleVariable;
  pefBaseline?: RuleVariable;
  lowFEV1PercentageDiscrete?: RuleVariable;
  lowPEFPercentageDiscrete?: RuleVariable;
  baselineWeight?: RuleVariable;
  dailyChangeDiscrete?: RuleVariable;
  weeklyChangeDiscrete?: RuleVariable;
};

export type RuleCreated = {
  $date: string;
};

export type Rule = {
  _id?: {
    $oid: string;
  };
  id?: string;
  name: string;
  description: string | null;
  trigger: {
    observationType: ObservationType;
  };
  variables: RuleEnabledKey & Record<string, RuleVariable>;
  script?: string;
  _meta?: {
    id: string;
    created: string | RuleCreated;
    modified: string | RuleCreated;
  };
};

export type VariableOverride = {
  default: boolean | number | VariableDefaultObject | null;
  override: boolean | number | VariableDefaultObject | null;
};

export type RuleOverrides = Record<string, VariableOverride | undefined>;
