export enum OrganizationType {
  HealthSystem = "Health System",
  HomeHealth = "Home Health",
  Hospice = "Hospice",
  PhysicianPractice = "Physician Practice",
  PopulationHealth = "Population Health",
  Root = "Root",
  ThirdPartyMonitoring = "3rd Party Monitoring",
  Other = "Other",
}
