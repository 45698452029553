import { User, UserModel, UserParentTypes } from "../../domain/user/model";
import { PracticeUserRoute, OrganizationUserRoute, RootUserRoute } from "./";
import { Routable } from "./";

/**
 * Builds a route for a given user.
 */
export abstract class Router {
  constructor(private readonly userModel: UserModel) {}

  /**
   * Map of UserType to its corresponding Route.
   *
   * @private
   */
  private readonly routes = {
    [UserParentTypes.Practice]: PracticeUserRoute,
    [UserParentTypes.Organization]: OrganizationUserRoute,
    [UserParentTypes.Admin]: RootUserRoute,
  };

  /**
   * Build and return Route for specified user.
   */
  get route(): Routable {
    return this.routes[this.userModel.parentType].build(this.userModel);
  }
}
