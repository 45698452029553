export const Credentials: Credential[] = [
  {
    abbreviation: "none", //none as string because null as a data type can not be set as an option in html select
    displayName: "None",
    prescriptive: false,
  },
  {
    abbreviation: "RN",
    displayName: "Registered Nurse",
    prescriptive: false,
  },
  {
    abbreviation: "LPN",
    displayName: "Licensed Practical Nurse",
    prescriptive: false,
  },
  {
    abbreviation: "LVN",
    displayName: "Licensed Vocational Nurse",
    prescriptive: false,
  },
  {
    abbreviation: "NP",
    displayName: "Nurse Practitioner",
    prescriptive: true,
  },
  {
    abbreviation: "PA",
    displayName: "Physicians Assistant",
    prescriptive: true,
  },
  {
    abbreviation: "MA",
    displayName: "Medical Assistant",
    prescriptive: false,
  },
  {
    abbreviation: "CA",
    displayName: "Clinical Assistant",
    prescriptive: false,
  },
  {
    abbreviation: "MD",
    displayName: "Medical Doctor",
    prescriptive: true,
  },
  {
    abbreviation: "DO",
    displayName: "Doctor of Osteopathy",
    prescriptive: true,
  },
];

export interface Credential {
  abbreviation: string | undefined;
  displayName: string;
  prescriptive: boolean;
}
