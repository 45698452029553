import React from "react";
import cx from "clsx";

import styles from "./styles.module.scss";

export type Props = {
  htmlFor?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const Label: React.FC<Props> = (props) => {
  const { htmlFor = "", children, className, style } = props;

  return (
    <label
      htmlFor={htmlFor}
      className={cx(styles.label, className)}
      style={style}
    >
      {children}
    </label>
  );
};
