import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { billingDescription, CPTCodeDescription } from "./helper";
import { PatientModel } from "@/domain/patient/model";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Tooltip } from "@/components/Tooltip";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { dateFormatter } from "@/pipes/date";
import {
  CURRENT_DATE,
  PATIENT_BILLING_REPORT_TIMEZONE,
} from "@/library/constants";
import { calculateAge } from "@/domain/patient/view/tabs/summary/constants";
import { DateTime } from "luxon";
import { Note, NoteDisposition } from "@/domain/notes/model/types";
import { getSeverityAlertLevel } from "@/domain/notes/model/constants";
import { ObservationType } from "@/domain/observations/types";
import { Intervention, Interventions } from "@/library/types/note";
import { useSelector } from "react-redux";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";

interface Props {}

export const BillingTab: React.FC<Props> = (props) => {
  const patient = useSelector(selectFirstPatient);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [CPT99453, setCPT99453] = useState(
    patient?.billing?.cpt99453.reimbursableEvents || 0
  );
  const [CPT99454, setCPT99454] = useState(
    patient?.billing?.cpt99454.reimbursableEvents || 0
  );
  const [CPT99457, setCPT99457] = useState(
    patient?.billing?.cpt99457.reimbursableEvents || 0
  );
  const [CPT99458, setCPT99458] = useState(
    patient?.billing?.cpt99458.reimbursableEvents || 0
  );
  const [accumulatedTime, setAccumulatedTime] = useState(
    patient?.billing?.cpt99458.currentMinutes ?? 0
  );
  const [chartDetails, setChartDetails] = useState([{} as Note]);
  const [observationDetails, setObservationDetails] = useState([{} as Note]);
  const [startReportRange, setStartReportRange] = useState(DateTime.now);
  const [endReportRange, setEndReportRange] = useState(
    startReportRange.plus({ month: 1 })
  );
  const [optionalNote, setOptionalNote] = useState("");

  const fetchPatientBillingByMonth = async (month: number, year: number) => {
    LoadingIndicator.fire.show();
    const patientModel = await PatientModel.sync(patient!.id!, {
      billingMonth: month,
      billingYear: year,
    });

    const startDate = DateTime.fromObject(
      {
        year,
        month,
      },
      {
        zone: PATIENT_BILLING_REPORT_TIMEZONE,
      }
    );
    const endDate = startDate.plus({ month: 1 });
    const chartTypes = ["CLINICAL", "TIMELOG"];
    const observationNotes = (
      await getNotes(startDate, endDate, ["OBSERVATION"])
    ).filter((note) => {
      return (
        note.isManual === false &&
        note.observation?.observations.length &&
        note.observation?.observations[0].accessory &&
        note.observation?.observations[0].accessory.interfaceType ===
          "BLUETOOTH"
      );
    });
    const chartNotes = (await getNotes(startDate, endDate, chartTypes)).filter(
      (note) => {
        return (
          (note.disposition === NoteDisposition.Historical &&
            note.minutes &&
            note.minutes > 0) ||
          (note.author?.canAccumulateBillableTime &&
            note.seconds &&
            note.seconds > 0) ||
          note.directPatientContact ||
          note.intervention?.includes(Intervention.DeviceSetupAndTraining)
        );
      }
    );

    setCPT99453(
      patientModel.pluck("billing")?.cpt99453.reimbursableEvents || 0
    );
    setCPT99454(
      patientModel.pluck("billing")?.cpt99454.reimbursableEvents || 0
    );
    setCPT99457(
      patientModel.pluck("billing")?.cpt99457.reimbursableEvents || 0
    );
    setCPT99458(
      patientModel.pluck("billing")?.cpt99458.reimbursableEvents || 0
    );
    setChartDetails(chartNotes);
    setObservationDetails(observationNotes);
    setStartReportRange(startDate.setZone("local"));
    setEndReportRange(endDate.setZone("local"));
    setAccumulatedTime(
      patientModel.pluck("billing")?.cpt99458.currentMinutes ?? 0
    );
    buildBillingReport();
    LoadingIndicator.fire.hide();
  };

  const renderBillingSideDescription = () => (
    <>
      {billingDescription.map((description) => (
        <div className="mt-4 lead">{description}</div>
      ))}
    </>
  );

  const handleFetchCurrentMonth = () => {
    setSelectedMonth(new Date());
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    fetchPatientBillingByMonth(currentMonth, currentYear);
  };

  const renderCPTCodeFields = () => (
    <div className="border p-3">
      <div style={{ textAlign: "center" }}>
        <p>Accumulated Time (minutes)</p>
        <span
          style={{
            display: "inline-block",
            border: "1px solid #2953a8",
            width: 100,
            textAlign: "center",
            background: "#e9ecef",
            padding: 5,
            borderRadius: 5,
          }}
        >
          {accumulatedTime}
        </span>
      </div>
      <div className="row">
        <div className="col-md-3 text-center">
          CPT99453
          <Tooltip tooltipContent={<p>{CPTCodeDescription.CPT99453}</p>}>
            <input
              type="text"
              className="form-control w-100 d-block"
              disabled
              value={CPT99453 || 0}
            />
          </Tooltip>
        </div>
        <div className="col-md-3 text-center">
          CPT99454
          <Tooltip tooltipContent={<p>{CPTCodeDescription.CPT99454}</p>}>
            <input
              type="text"
              className="form-control w-100 d-block"
              disabled
              value={CPT99454 || 0}
            />
          </Tooltip>
        </div>
        <div className="col-md-3 text-center">
          CPT99457
          <Tooltip tooltipContent={<p>{CPTCodeDescription.CPT99457}</p>}>
            <input
              type="text"
              className="form-control w-100 d-block"
              disabled
              value={CPT99457 || 0}
            />
          </Tooltip>
        </div>
        <div className="col-md-3 text-center">
          CPT99458
          <Tooltip tooltipContent={<p>{CPTCodeDescription.CPT99458}</p>}>
            <input
              type="text"
              className="form-control w-100 d-block"
              disabled
              value={CPT99458 || 0}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );

  const renderMonthPicker = () => (
    <div className="float-right">
      <DatePicker
        autoComplete={"new_content"}
        className="form-control"
        name="monthYear"
        selected={selectedMonth}
        onChange={(date: Date) => {
          if (date)
            fetchPatientBillingByMonth(date.getMonth() + 1, date.getFullYear());
          setSelectedMonth(date);
        }}
        placeholderText="MM YYYY"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        maxDate={new Date()}
      />
    </div>
  );

  useEffect(() => {
    notesForCurrentMonth();
    handleFetchCurrentMonth();
  }, []);

  useEffect(() => {
    LoadingIndicator.fire.show();
    if (patient) {
      notesForCurrentMonth();
      setCPT99453(patient.billing?.cpt99453.reimbursableEvents!);
      setCPT99454(patient.billing?.cpt99454.reimbursableEvents!);
      setCPT99457(patient.billing?.cpt99457.reimbursableEvents!);
      setCPT99458(patient.billing?.cpt99458.reimbursableEvents!);
    }
    LoadingIndicator.fire.hide();
  }, [patient]);

  const buildBillingReport = () => {
    let totalBillableMinutes = 0;
    let count = 0;
    if (!patient) return null;
    return (
      <>
        <div className="billing">
          <table id="billingInfo" style={{ display: "none" }}>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  Patient:{" "}
                  {patient.demographics.legalName.lastName +
                    ", " +
                    patient.demographics.legalName.firstName}{" "}
                </td>
                <td>ID: {patient.encounter?.id} </td>
              </tr>
              <tr>
                <td>
                  DOB:
                  {" " +
                    dateFormatter({
                      date: patient.demographics.dateOfBirth,
                      isDateOfBirth: true,
                    })}
                  {" (Age " +
                    calculateAge(patient.demographics.dateOfBirth) +
                    ")"}{" "}
                </td>
                <td>
                  Condition:{" "}
                  {patient.conditions.map((condition, index) => {
                    return (index ? "," : " ") + condition.displayName;
                  })}{" "}
                </td>
              </tr>
              <tr>
                <td>
                  {"Report Date Range: " +
                    dateFormatter({
                      date: startReportRange.toISO(),
                      includeTime: true,
                      toLocalTimezone: {
                        enabled: true,
                        excludeTimezone: false,
                      },
                    }) +
                    " to "}
                </td>
              </tr>
              <tr>
                <td>
                  {dateFormatter({
                    date: endReportRange.toISO(),
                    includeTime: true,
                    toLocalTimezone: {
                      enabled: true,
                      excludeTimezone: false,
                    },
                  })}
                </td>
                <td>Billing Type: {patient.billingType} </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Entity:</td>
                <td>Provider:</td>
              </tr>
              <tr>
                <td>{patient.practice?.name} </td>
                <td>
                  {" "}
                  {patient.provider?.firstName +
                    " " +
                    patient.provider?.lastName +
                    ", " +
                    patient.provider?.credentials}{" "}
                </td>
              </tr>
              <tr>
                <td>{patient.practice?.address.street}</td>
                <td> {patient.provider?.phoneNumber} </td>
              </tr>
              <tr>
                <td>
                  {patient.practice?.address.locality +
                    ", " +
                    patient.practice?.address.region +
                    " " +
                    patient.practice?.address.postalCode}
                </td>
              </tr>
              <tr>
                <td>{patient.practice?.contact?.phone}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>{"Optional Note: "}</td>
              </tr>
              <tr>
                <td colSpan={5}>{optionalNote}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>CPT99453: {CPT99453} </td>
              </tr>
              <tr>
                <td>CPT99454: {CPT99454}</td>
              </tr>
              <tr>
                <td>CPT99457: {CPT99457}</td>
              </tr>
              <tr>
                <td>CPT99458: {CPT99458}</td>
              </tr>
              <tr>
                <td>
                  Total Billable Minutes:{" "}
                  {chartDetails.map((note) => {
                    if (
                      note.author?.canAccumulateBillableTime ||
                      note.disposition === NoteDisposition.Historical
                    ) {
                      totalBillableMinutes +=
                        ((note.minutes ?? 0) * 60 + (note.seconds ?? 0)) / 60;
                    }
                  })}{" "}
                  {Math.floor(totalBillableMinutes)}
                </td>
              </tr>
              <tr>
                <td>
                  Total Observations:{" "}
                  {observationDetails.map((note) => {
                    count++;
                  })}{" "}
                  {count}{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <table id="observationInfo" style={{ display: "none" }}>
            <thead>
              <tr>{renderObservationHeader()}</tr>
            </thead>
            <tbody>{observationDetailRows()}</tbody>
          </table>
          <table id="chartInfo" style={{ display: "none" }}>
            <thead>
              <tr>{renderChartHeader()}</tr>
            </thead>
            <tbody>{chartDetailRows()}</tbody>
          </table>
        </div>
      </>
    );
  };

  const renderObservationHeader = () => {
    const header = ["Date/Time", "Type", "Value", "Alert Status"];

    return header.map((key, index) => (
      <th key={index} className="text" scope="col">
        {key}
      </th>
    ));
  };

  const renderChartHeader = () => {
    const header = [
      "Date/Time",
      "Type",
      "Comment & Interventions",
      "Author",
      "Disposition",
      "Time (sec)",
      "Patient Contact",
    ];

    return header.map((key, index) => (
      <th key={index} className="text" scope="col">
        {key}
      </th>
    ));
  };

  const observationDetailRows = () => {
    return observationDetails.map((note) => {
      return (
        <tr key={note._meta?.id}>
          <td>
            {dateFormatter({
              date: note._meta?.created,
              toLocalTimezone: {
                enabled: true,
                excludeTimezone: false,
              },
              includeTime: true,
            })}{" "}
          </td>
          <td>
            {note.observation?.observations.map((obs) => {
              return (
                <span>
                  {obs.type}
                  <br />
                </span>
              );
            })}
          </td>
          <td>
            {note.observation?.observations.map((obs) => {
              return obs.type === ObservationType.BloodPressure ? (
                <span>
                  {obs.systolic?.value}/{obs.diastolic?.value}{" "}
                  {obs.systolic?.unit}
                  <br />
                </span>
              ) : (
                <span>
                  {obs.value} {obs.unit} <br />
                </span>
              );
            })}{" "}
            <br />
          </td>
          <td>{getSeverityAlertLevel(note.severityScore).toUpperCase()}</td>
        </tr>
      );
    });
  };

  const chartDetailRows = () => {
    return chartDetails.map((note) => {
      return (
        <tr key={note._meta?.id}>
          <td>
            {dateFormatter({
              date: note._meta?.created,
              toLocalTimezone: {
                enabled: true,
                excludeTimezone: false,
              },
              includeTime: true,
            })}
          </td>
          <td>{note.type}</td>
          <td>
            Comment: {note.comment} <br />
            Interventions:{" "}
            {note.intervention?.map((i, index) => {
              return (index ? "," : " ") + Interventions[i];
            })}
          </td>
          <td>
            {note.author?.name ||
              " " +
                " " +
                (note.author?.credentials === null
                  ? " "
                  : note.author?.credentials || " ")}
          </td>
          <td>{note.disposition}</td>
          <td>
            {(note.disposition === NoteDisposition.Historical &&
              note.minutes &&
              note.minutes > 0) ||
            (note.author?.canAccumulateBillableTime &&
              note.seconds &&
              note.seconds > 0)
              ? Math.floor((note.seconds ?? 0) + (note.minutes ?? 0) * 60)
              : " "}
          </td>
          <td>{"" + !note.directPatientContact ? " " : "TRUE"}</td>
        </tr>
      );
    });
  };

  const notesForCurrentMonth = async () => {
    const year = CURRENT_DATE.getFullYear();
    const month = CURRENT_DATE.getMonth() + 1;
    const startDate = DateTime.fromObject(
      {
        year,
        month,
      },
      {
        zone: PATIENT_BILLING_REPORT_TIMEZONE,
      }
    );
    const endDate = startDate.plus({ month: 1 });
    const observationNotes = patient
      ? (await getNotes(startDate, endDate, ["OBSERVATION"])).filter((note) => {
          return (
            note.isManual === false &&
            note.observation?.observations.length &&
            note.observation?.observations[0].accessory &&
            note.observation?.observations[0].accessory.interfaceType ===
              "BLUETOOTH"
          );
        })
      : [];
    const chartNotes = patient
      ? (await getNotes(startDate, endDate, ["CLINICAL", "TIMELOG"])).filter(
          (note) => {
            return (
              (note.disposition === NoteDisposition.Historical &&
                note.minutes &&
                note.minutes > 0) ||
              (note.author?.canAccumulateBillableTime &&
                note.seconds &&
                note.seconds > 0) ||
              note.directPatientContact ||
              note.intervention?.includes(Intervention.DeviceSetupAndTraining)
            );
          }
        )
      : [];
    setObservationDetails(observationNotes);
    setChartDetails(chartNotes);
  };

  const getNotes = async (
    startDate: DateTime,
    endDate: DateTime,
    type: string[]
  ): Promise<Note[]> => {
    let currentPage = 0;
    let lastPage = 1;
    const allNotes: Note[] = [];
    while (currentPage++ < lastPage) {
      const [notes, config] = await PatientModel.getNotes(
        patient?._meta?.id!,
        startDate.toISO(),
        endDate.toISO(),
        type,
        { currentPage }
      );
      notes.map((note) => allNotes.push(note));
      lastPage = config?.lastPage;
    }

    return allNotes;
  };

  const handleExport = async (): Promise<void> => {
    /**
     * PDF Configuration
     * */
    const startX = 6.0;
    const startY = 0.8;
    const fontSize = 17;
    const font = "Helvetica";
    const reportDate =
      "Created: " +
      dateFormatter({
        date: DateTime.now().toISO(),
        includeTime: true,
        toLocalTimezone: {
          enabled: true,
          excludeTimezone: false,
        },
      });
    /**
     * Init JSPDF
     */
    const doc: jsPDF = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: "a3",
    });

    doc.setFont(font, "bold").setFontSize(28);
    doc
      .text("Patient Billing Report", startX, startY)
      .setFont(font, "light")
      .setFontSize(23);
    doc.text(reportDate, 5.8, 1.5);

    autoTable(doc, {
      html: "#billingInfo",
      startY: 1.8,
      styles: { fontSize },
      theme: "plain",
      includeHiddenHtml: true,
    });
    doc.addPage("a3", "landscape");
    doc.setFont(font, "bold").setFontSize(28);
    doc.text("Observation Details", startX, startY);

    autoTable(doc, {
      html: "#observationInfo",
      startY: 1.2,
      styles: { fontSize: 16 },
      includeHiddenHtml: true,
      theme: "striped",
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: 3, overflow: "linebreak" },
        2: { cellWidth: 3, overflow: "linebreak" },
      },
    });
    doc.addPage("a3", "landscape");
    doc.setFont(font, "bold").setFontSize(28);
    doc.text("Chart Details", startX, startY);

    autoTable(doc, {
      html: "#chartInfo",
      startY: 1.5,
      includeHiddenHtml: true,
      useCss: false,
      theme: "striped",
      styles: { fontSize: 16 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: 7, overflow: "linebreak" },
      },
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  return (
    <>
      {buildBillingReport()}
      <div className="container" id="pt_summary">
        <div
          id="interdisciplinary"
          className="tab-pane fade in active show mt-4"
          style={{
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          <h3 className="text-center mb-5">
            Patient Billing Report
            {renderMonthPicker()}
          </h3>
          <div className="row">
            <div className="col-md-4">
              <div className="border mt-4 px-3 py-5">
                {renderBillingSideDescription()}
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="text-center">Selected Billing Month</h4>
              {patient && renderCPTCodeFields()}
              <div className="mt-4">
                <p className="lead">Optional Note</p>
                <textarea
                  className="form-control"
                  rows={6}
                  onChange={(e) => setOptionalNote(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleFetchCurrentMonth}
        >
          Restore Defaults
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleExport}
        >
          Create
        </button>
      </div>
    </>
  );
};
