import React, { useEffect, useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import { useSelector } from "react-redux";
import {
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const HeartRateChart: React.FC<Props> = (props) => {
  const { heartRates, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);
  const [allowChartUpdate, setAllowChartUpdate] = useState(false);

  useEffect(() => {
    setAllowChartUpdate(true);
    window.setTimeout(() => {
      setAllowChartUpdate(false);
    }, 500);
  }, [isoStartDate, isoEndDate]);

  const chartConfig = useMemo(() => {
    return makeChartOptions({
      yTitle: displayObsUnit(ObservationType.HeartRate) as string,
      events: {
        beforePrint: () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        valueSuffix: ObservationsUnitAbbrevation.HEART_RATE,
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(
            ObservationType.HeartRate,
            this,
            ObservationsUnitAbbrevation.HEART_RATE
          );
        },
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
      },
      series: [
        {
          name: "Heart Rate",
          color: COLORS.primary.line,
          data: heartRates.map((hr) => {
            const { severityScore } = hr;
            return {
              x: Date.parse(
                new Date(
                  hr.timeOfObservation ? hr.timeOfObservation : hr._meta.created
                ).toISOString()
              ),
              y: hr.value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
      chart: {
        marginTop: 40,
      },
    });
  }, [heartRates, patientDetail, isPrinting]);

  return (
    <div>
      <LineChart
        containerProps={{ id: "heart-rate-chart" }}
        options={chartConfig.options}
        allowChartUpdate={allowChartUpdate}
      />
    </div>
  );
};

export default HeartRateChart;
