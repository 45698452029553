import {
  SetProviderDispatch,
  setProviders,
  createProvider,
  setProvidersPaginationConfig,
  ProviderState,
} from "./index";
import { Provider, ProviderModel } from "../model";
import { PatientModel } from "../../patient/model";
import { PracticeModel } from "../../practice/model";
import { Practice } from "../../practice/model/types";
import { PracticeState } from "../../practice/redux/types";
import { PaginationConfig } from "../../../library/types";
import { RootState } from "@/types";
import { Dispatch } from "redux";
/**
 * Outer synchronous function that receives the `providerCollection` parameter:
 * @param providerCollection
 */
export const setProvidersThunk =
  (providerModels: ProviderModel[]) =>
  async (dispatch: SetProviderDispatch, getState: () => RootState) => {
    // And then creates and returns the async thunk function:
    /*
     * if prevProps.selectedPractices.length === this.props.selectedPractices.length
     * we'll need to pass an empty array
     */
    if (!providerModels.length) {
      return dispatch(setProviders([]));
    }
    const practiceState = getState().practice.practices;
    const practiceStateIds = practiceState.map(
      (practice: Practice) => practice.id
    );
    let practicesIds = providerModels
      .filter((provider) => provider.pluck("practiceId"))
      .map((provider) => provider.pluck("practiceId"));
    if (
      practiceStateIds &&
      practiceStateIds.length &&
      practiceStateIds.length > 0
    ) {
      /**
       * Removing practices ids if those practices already exists in our redux state.
       */
      practicesIds = practicesIds.filter(
        (practiceId) => !practiceStateIds.includes(practiceId)
      );
    }
    /**
     * Sync provider's practices from the server
     */
    const practices: Practice[] = [
      ...(await PracticeModel.syncMany(practicesIds as string[])).map(
        (practice) => practice.pluckAll()
      ),
      ...practiceState,
    ];

    const providers = await Promise.all(
      providerModels
        .map((providerModel) => providerModel.pluckAll())
        //Attaching practice to provider object
        .map(async (provider: Provider) => {
          const practice: Practice | undefined = practices.find(
            (practice) => practice.id === provider.practiceId
          );
          if (practice) {
            provider.practice = practice;
          }
          return provider;
        })
    );

    const providersWithPatientCount = await attachPatientCountToProviders(
      providers
    );
    dispatch(setProviders(providersWithPatientCount));
  };

/**
 * Intercept the provider object before sending it to redux state and dispatch createProvider action
 * @param providerCollection
 */
export const createProviderThunk =
  (provider: Provider, practicePrevState: PracticeState) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const selectedParentPractice = practicePrevState.practices.find(
      (practice: Practice) => practice.id === provider.practiceId
    );
    if (selectedParentPractice) {
      provider.practice = selectedParentPractice;
      const providerState: ProviderState = getState().provider;
      const paginationConfig: PaginationConfig = providerState.paginationConfig;

      dispatch(setProvidersPaginationConfig(paginationConfig));
      dispatch(createProvider({ ...provider, patients: 0 }));
    }
  };
/**
 * Attaching patients count to the providers
 * @param providers
 * @param practices
 * @returns
 */
export const attachPatientCountToProviders = async (providers: Provider[]) => {
  let practiceIds: string[] = [];
  // first find if any providers don't have patient count already attached
  providers.forEach((provider: Provider) => {
    if (!("patientCount" in provider)) {
      practiceIds.push(`${provider.practiceId}`);
    } else {
      provider.patients = provider.patientCount as number;
    }
  });
  if (practiceIds.length) {
    const patients = (
      await PatientModel.fetchByPracticeIds(practiceIds as string[])
    ).map((patient) => patient.pluckAll());

    providers = providers.map((provider) => {
      if (!provider.patients) {
        provider.patients = patients.filter(
          (patient) => patient.providerId === provider.id
        ).length;
      }
      return provider;
    });
  }
  return providers;
};
