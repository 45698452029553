import {
  Attributes,
  deserialize,
  Model,
  ModelCollection,
} from "@/library/model";
import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Program } from "./types";
import { MODEL_SYNCED } from "@/library/constants";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Iterator } from "@/library/iteration";

export class ProgramModel extends Model<Program> {
  static readonly path = "programs";

  static make(attributes: Program): ProgramModel {
    return new ProgramModel(
      new Attributes<Program>(attributes),
      new RemoteCareAPISync<Program>(ProgramModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of programs using the provided resource url and a deserializer.
   * The entire program list can be accessed in the models member of the Collection class.
   */
  static makeProgramCollection(): ModelCollection<ProgramModel, Program> {
    return new ModelCollection<ProgramModel, Program>(
      `${RemoteCareAPISync.host}/${ProgramModel.path}`,
      (entity: Program) => deserialize(entity, ProgramModel.make)
    );
  }

  static fetchAllProgramsWithIterator = async (params: {}): Promise<
    Program[]
  > => {
    const programCollection: ModelCollection<ProgramModel, Program> =
      ProgramModel.makeProgramCollection();
    programCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    programCollection.withParams(params);

    const iterator: Iterator<ProgramModel, Program> =
      await programCollection.getMany();
    const allPrograms = await iterator.getAll();
    return allPrograms.map((t: ProgramModel) => t.pluckAll());
  };
}
