import {
  HeaderContent,
  SortedState,
  SortBy,
} from "@/components/table/head/sortable";
import { Patient } from "@/domain/patient/model/types";
import { ObservationType } from "../../observations/types";

export interface PatientDevice {
  id?: string;
  _meta: {
    id: string;
    created: string;
    modified: string;
  };
  iccid: string;
  sku: DeviceSku | string;
  msisdn: string;
  msn: string;
  inventory: {
    type: string;
    entity: {
      type: "Organization" | "Practice";
      id: string;
    };
  } | null;
  patientId: string | null;
  patient?: Patient;
  location: {
    latitude: number;
    longitude: number;
    precision: string;
  };
  battery: {
    level: number;
  };
  volume: {
    microphone: number;
    speaker: number;
    ring: number;
  };
  powerSource: string | null;
  serviceLevel: ServiceLevel;
  accessories: Accessories[];
  lastCommunication: string;
  assignedUserId?: null | string;
  tagIds: string[];
  tags: DeviceTag[];
  timezoneOffset: number | null;
  lifecycle?: DeviceLifecycle;
}

export interface PatientDeviceAudit {
  actorId: string;
  assigned: boolean;
  deviceId: string;
  organizationId: string;
  patientId: string;
  practiceId: string;
  time: string;
  msn?: string | null;
  iccid: string;
  type: "DEVICE_ASSIGNMENT";
}
export interface Accessories {
  id: string;
  accessoryType: AccessoryType;
  observationType: ObservationType[] | null;
}

export interface DeviceLifecycle {
  state: DeviceState;
}

/**
 * Possible states in a device's lifecycle.
 */
export enum DeviceState {
  /**
   * The device is issued to a patient or is ready to issue to a patient.
   */
  Active = "ACTIVE",
  /**
   * The device was issued to a patient and needs reprocessing before being
   * reissued.
   */
  Dirty = "DIRTY",
  /**
   * The device is being cleaned (physically and digitally) in preparation
   * for being reissued.
   */
  Reprocessing = "REPROCESSING",
}
/**
 * Possible state transitions in a device's lifecycle.
 */
export enum DeviceTransition {
  /**
   * A patient is completed or a return is started.
   */
  StartReturn = "START_RETURN",
  /**
   * Staff receive a device from a patient and start reprocessing.
   */
  StartReprocess = "START_REPROCESS",
  /**
   * Staff complete reprocessing. The device is ready to reissue.
   */
  FinishReprocess = "FINISH_REPROCESS",
}

export interface DeviceTag {
  _meta: {
    id: string;
    created: Date;
    modified: Date;
  };
  type: string;
  value: string;
  associatedData: {
    key: string;
    value: string;
  }[];
  enabled: boolean;
}

export enum AccessoryType {
  BLOOD_PRESSURE_MONITOR = "BLOOD_PRESSURE_MONITOR",
  PULSE_OXIMETER = "PULSE_OXIMETER",
  SCALE = "SCALE",
  GLUCOMETER = "GLUCOMETER",
  THERMOMETER = "THERMOMETER",
  SPIROMETER = "SPIROMETER",
}

/**
 * Available sizes of accessories.
 */
export enum AccessorySize {
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE",
  ExtraLarge = "EXTRA_LARGE",
  Universal = "UNIVERSAL",
}

export enum DeviceSku {
  ANH1117 = "ANH1117",
  ANF0921 = "ANF0921",
}

export enum ServiceLevel {
  RemoteCare = "RemoteCare",
  RemoteCareLive = "RemoteCareLive",
  Unknown = "Unknown",
}

export const KitTableHeader: HeaderContent[] = [
  {
    name: "Select",
    isSortable: false,
    sortedState: SortedState.NoSort,
    isCheckbox: true,
  },
  {
    name: "kit number",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "msn",
  },
  {
    name: "ICCID",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "iccid",
  },
  {
    name: "service level",
    isSortable: false,
    sortedState: SortedState.NoSort,
    sortBy: "sku",
  },
  {
    name: "status",
    isSortable: false,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Street,
  },
  {
    name: "Entity / Branch",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "location",
    isSortable: false,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Locality,
  },
  {
    name: "patient",
    isSortable: false,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Region,
  },
  {
    name: "actions",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
];
