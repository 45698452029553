import { Serializeable, Serializer } from "./../serializer";
import { RCAResourceActions } from "../../core/config/actions";
import { UserForm, UserModel } from "../../../domain/user/model";
import { CreateUserSerializer, ModifyUserSerializer } from ".";

/**
 * Practice domain serializer. It holds information about all serializers in this domain.
 */
export class UserSerializer extends Serializer<UserForm, UserModel> {
  static for(action: RCAResourceActions, from: UserForm): UserSerializer {
    return new UserSerializer(action, from);
  }

  serialize(): UserModel {
    const serializer = new (this.get(this.action))(this.formData);
    return serializer.serialize();
  }

  /**
   * Map of currently supported resource actions to their respective serializers.
   * @protected
   */
  protected serializers = {
    [RCAResourceActions.Create]: CreateUserSerializer,
    [RCAResourceActions.ModifyBasicInfo]: ModifyUserSerializer,
  };
}
