import React from "react";

type Props = {
  providerNote: string;
  setProviderNote: (note: string) => void;
};

const ProviderComment: React.FC<Props> = ({
  providerNote,
  setProviderNote,
}) => {
  return (
    <>
      <div className="text-center font-weight-bold h6">
        Optional Note to Provider
      </div>

      <div className="px-2">
        <textarea
          value={providerNote}
          style={{ height: "290px" }}
          onChange={(e) => {
            setProviderNote(e.target.value);
          }}
          className="form-control w-100"
          rows={13}
        ></textarea>
      </div>
    </>
  );
};

export default ProviderComment;
