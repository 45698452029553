import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { PracticeModel } from "@/domain/practice/model";
import { RootState, SelectOption } from "@/types";

import styles from "./styles.module.scss";

interface Props {
  onChange: (selectedPracticeIds: string[], descriptions: string[]) => void;
  selectedPracticeIds?: string[];
}

export const PracticeFilter: React.FC<Props> = (props) => {
  const { onChange, selectedPracticeIds } = props;

  const filters = useSelector((state: RootState) => state.patient.filters);
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );

  const [practiceOptions, setPracticeOptions] = useState<SelectOption[]>([]);
  const [pending, setPending] = useState(false);

  const fetchPractices = async () => {
    if (selectedOrganization?.id) {
      setPending(true);
      const practices = await PracticeModel.fetchByOrganizationIds([
        selectedOrganization.id,
      ]);

      const options = practices.map((model: PracticeModel) => {
        return {
          value: model.pluck("id") as string,
          label: model.pluck("name"),
        };
      });

      setPracticeOptions(options);
      setPending(false);
    }
  };

  const getDefaultValues = () => {
    if (selectedPracticeIds) {
      return selectedPracticeIds;
    }
    return filters.practiceIds && filters.practiceIds?.length > 0
      ? filters.practiceIds
      : undefined;
  };

  return (
    <BasicMultiSelect
      data={practiceOptions}
      onChange={async (selectedPracticeIds: string[]) => {
        const descriptions = selectedPracticeIds.map(
          (selectedPracticeId) =>
            practiceOptions.find(
              (practiceOption) => practiceOption.value === selectedPracticeId
            )?.label ?? ""
        );
        onChange(selectedPracticeIds, descriptions);
      }}
      value={getDefaultValues()}
      isSearchable={true}
      placeholder="All Entity/Branch"
      onOpen={fetchPractices}
      picker="check"
      config={{ showLoadingIndicator: pending }}
      className={styles.select}
    />
  );
};

export default PracticeFilter;
