import { format } from "date-fns";
import { dateFormatter } from "@/pipes/date";
import { Patient, PatientConditionType } from "@/domain/patient/model/types";
import { calculateAge } from "../constants";
import styles from "./styles.module.scss";

interface Props {
  pageWidth: number;
  patient: Patient;
  dateRanges: {
    start: Date;
    end: Date;
  };
  providerNote: string;
}

const DATE_TIME_FORMAT = "MM-dd-yy HH:mm";
const DATE_FORMAT = "MM-dd-yy";

const PatientDetailDoc = (props: Props) => {
  const { patient } = props;

  const { firstName, lastName, middleName } = patient.demographics.legalName;
  const { dateOfBirth } = patient.demographics;
  const { conditions, provider } = props.patient;

  const colspan = 3;

  const br = (
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  const primaryConditionName = (): string => {
    let primaryCondition = conditions.find(
      (condition) => condition.type === PatientConditionType.PRIMARY
    )?.displayName;
    return primaryCondition ? `${primaryCondition}` : "";
  };

  const heading = (
    <tr>
      <td colSpan={colspan} className="text-center font-weight-bold lead">
        Patient Summary Report
      </td>
    </tr>
  );

  const createdAt = (
    <tr>
      <td colSpan={colspan} className="text-center mb-5">
        Created:{" "}
        {dateFormatter({
          date: new Date().toISOString(),
          includeTime: true,
          toLocalTimezone: { enabled: true },
        })}
      </td>
    </tr>
  );

  const patientNameAndCondition = (
    <tr>
      <td className="bolder">
        Patient: {lastName}, {firstName} {middleName ? `, ${middleName}` : ""}
      </td>
      <td></td>
      <td>
        <span className={styles.summaryHeadings}>Condition:</span>{" "}
        {primaryConditionName()}
      </td>
    </tr>
  );

  const patientAgeAndProviderName = (
    <tr>
      <td>
        {dateOfBirth &&
          dateFormatter({
            date: dateOfBirth,
            isDateOfBirth: true,
          })}{" "}
        {dateOfBirth && ` / ${calculateAge(dateOfBirth)}`}{" "}
      </td>
      <td></td>
      <td>
        <b> Provider:</b> {provider?.firstName} {provider?.lastName}
        {provider && ","} {provider?.credentials}
      </td>
    </tr>
  );

  const summaryDateRange = (
    <tr>
      <td colSpan={colspan}>
        <div className={styles.summaryHeadings}>Report Date Range: </div>
        {format(props.dateRanges.start, DATE_FORMAT)} -{" "}
        {format(props.dateRanges.end, DATE_FORMAT)}{" "}
      </td>
      <td> </td>
      <td></td>
    </tr>
  );

  const providerNote = (
    <tr>
      <td colSpan={colspan}>
        <div>{props.providerNote}</div>
      </td>
    </tr>
  );

  const biometricData = (
    <tr>
      <td
        colSpan={colspan}
        className={styles.summaryHeadings + " font-weight-bold"}
      >
        Biometric Data:
      </td>
    </tr>
  );
  return (
    <table className="table table-borderless" id="patientDoc">
      <tbody>
        {heading}
        {createdAt}
        {patientNameAndCondition}
        {patientAgeAndProviderName}
        {summaryDateRange}
        {providerNote}
        {br}
        {biometricData}
        {br}
      </tbody>
      <hr />
    </table>
  );
};

export default PatientDetailDoc;
