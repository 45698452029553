import { RootState } from "@/types";
import { Selector } from "react-redux";
import { Practice } from "../model/types";

export const selectPractices: Selector<RootState, Practice[]> = (state) =>
  state.practice.practices;

export const selectSelectedPractices: Selector<RootState, Practice[]> = (
  state
) => state.practice.selectedPractices;

export const selectFirstSelectedPractice: Selector<
  RootState,
  Practice | null
> = (state) =>
  state.practice.selectedPractices.length
    ? state.practice.selectedPractices[0]
    : null;

export const selectCurrentPractice: Selector<
  RootState,
  Practice | undefined
> = (state) => state.practice.selectedPractice;
