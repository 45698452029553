import React from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import cx from "clsx";

import { TextInput } from "@/components/form";
import { OrganizationDropdownComponent } from "@/components/dropdown/organizationDropdown.component";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { User, UserModel, UserParentTypes } from "@/domain/user/model";
import { Guard } from "@/library/guard/Guard";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Organization } from "@/domain/organization/model/types";
import {
  deselectOrganization,
  dispatchSelectOrganization,
} from "@/domain/organization/redux/actions";
import { Provider, ProviderModel } from "@/domain/provider/model";
import { OrganizationModel } from "@/domain/organization/model";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { Model, ModelCollection } from "@/library/model";
import { MODEL_SYNCED } from "@/library/constants";
import { PaginationConfig } from "@/library/types";
import { setPatientsThunk } from "@/domain/patient/redux/middleware";
import { MultiselectConfig } from "@/components/form/select/multiselect.model";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import globalStyles from "@/styles/globals.module.scss";
import styles from "../styles.module.scss";

interface Props {
  selectOrganization: (organization: Organization) => {
    type: string;
    payload: Organization;
  };
  selectedOrganization: Organization | undefined;
  setPatients: (patientModel: PatientModel[]) => void;
  setPatientsState: (
    patientCollection: ModelCollection<PatientModel, Patient>
  ) => void;
  patients: Patient[];
  currentUser: User;
  filteredPatients?: Patient[];
}

interface State {
  globalFilters: {
    practiceIds: string[] | undefined;
    providerIds: string[] | undefined;
    userIds: string[] | undefined;
    userStatuses: string[] | undefined;
  };

  practices: Practice[];
  providers: Provider[];
  users: any[];
  statuses: any[];
  patients: Patient[];
  practicePlaceholder: string;
  multiSelectConfig: MultiselectConfig;
}

class Component extends React.Component<Props, State> {
  private headerRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
        userIds: undefined,
        userStatuses: ["ACTIVE"],
      },
      practices: [],
      providers: [],
      users: [],
      statuses: [
        { label: "Active", value: "ACTIVE" },
        { label: "Completed", value: "COMPLETED" },
      ],
      patients: [],
      practicePlaceholder: "All Entity/Branch",
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    };
    this.headerRef = React.createRef();
  }

  withLoader = async (fetch: () => Promise<void>) => {
    await this.setState({
      multiSelectConfig: {
        showLoadingIndicator: true,
      },
    });
    await fetch();
    this.setState({
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    });
  };

  getInitialValues = () => {
    return {
      currentlyEnrolled: "0",
      enrolledMonth: "0",
      enrolledYear: "0",
      CPT99454: "0",
      CPT99457: "0",
      CPT99458: "0",
      nonBillableCPT99454: "0",
      nonBillableCPT99457: "0",
      nonBillableCPT99458: "0",
    };
  };

  clearGlobalFilters = (resetStatus: Boolean) => {
    this.setState({
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
        userIds: undefined,
        ...{ userStatuses: resetStatus ? ["ACTIVE"] : [] },
      },
      practices: [],
      providers: [],
      users: [],
      // statuses: [],
    });
  };

  fetchPractices = async () => {
    if (this.props.selectedOrganization && this.props.selectedOrganization.id) {
      const allPracticeModels: PracticeModel[] =
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id as string,
        ]);
      this.setState((previousState) => ({
        ...previousState,
        practices: allPracticeModels.map((practiceModel) =>
          practiceModel.pluckAll()
        ),
      }));
    }
  };

  fetchProviders = async () => {
    let practiceIds: string[];
    if (
      !this.state.globalFilters.practiceIds?.length &&
      this.props.selectedOrganization &&
      this.state.practices.length === 0
    ) {
      const practiceModelsInOrganizationScope =
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id!,
        ]);
      practiceIds = practiceModelsInOrganizationScope.map(
        (practiceModel) => practiceModel.pluck("_meta")?.id!
      );
    } else if (this.state.globalFilters.practiceIds) {
      practiceIds = this.state.globalFilters.practiceIds || [];
    } else {
      practiceIds = this.state.practices.map(
        (practice) => practice.id
      ) as string[];
    }

    if (!practiceIds.length) {
      this.setState((previousState) => ({ ...previousState, providers: [] }));
      return;
    }

    const allProviderModels: ProviderModel[] =
      await ProviderModel.fetchByPracticeIds(practiceIds);

    this.setState((previousState) => ({
      ...previousState,
      providers: allProviderModels.map((providerModel) =>
        providerModel.pluckAll()
      ),
    }));
  };

  fetchUsers = async (): Promise<void> => {
    const allOrganizationUserModel: UserModel[] =
      await UserModel.fetchByOrganizationIds([
        this.props.selectedOrganization?.id!,
      ]);
    let allPracticeUserModel: UserModel[] = [];

    if (
      this.state.globalFilters.practiceIds &&
      this.state.globalFilters.practiceIds.length !== 0
    ) {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        this.state.globalFilters.practiceIds
      );
    } else {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        (
          await PracticeModel.fetchByOrganizationIds([
            this.props.selectedOrganization?.id!,
          ])
        ).map((practiceModel) => practiceModel.pluck("_meta").id)
      );
    }

    await this.setState({
      // Removing the duplicate values from assignedUsers array and mapping it in multi select options array.
      users: [
        { value: "unassigned", label: "Unassigned", role: "Filter" },
        ...[
          ...(new Set(
            allPracticeUserModel.concat(allOrganizationUserModel)
          ) as any),
        ].map((model: Model<User>) => {
          return {
            value: model.pluck("id") as string,
            label: `${model.pluck("firstName")} ${model.pluck("lastName")}`,
            role: "Users",
          };
        }),
      ],
    });
  };

  async componentDidMount() {
    if (this.props.selectedOrganization) {
      const path = window.location.pathname;
      window.history.replaceState(
        null,
        ``,
        `${path}?organizationId=${this.props.selectedOrganization.id}`
      );
      this.handleSelectOrganization();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.selectedOrganization &&
      prevProps.selectedOrganization !== this.props.selectedOrganization
    ) {
      this.handleSelectOrganization();
    }
  }

  isFilterSelected = (): boolean => {
    const filters = this.state.globalFilters;
    let isFiltered = false;
    for (const [filter, value] of Object.entries(filters)) {
      if (value && value.length !== 0) {
        isFiltered = true;
        break;
      }
    }
    return isFiltered;
  };

  handleSelectOrganization = async (resetStatus = true) => {
    this.resetFormFields!(this.getInitialValues());
    this.clearGlobalFilters(resetStatus);
    await this.fetchPractices();
    LoadingIndicator.fire.show();
    const patientCollection: ModelCollection<PatientModel, Patient> =
      PatientModel.makePatientCollection();
    patientCollection.setCustomLimit(500);
    patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    const isPracticeUser = Guard.accessLevel(
      UserParentTypes.Practice
    ).canActivate(this.props.currentUser!);
    if (isPracticeUser) {
      await this.setState({
        ...this.state,
        practicePlaceholder: `${this.state.practices[0].name} `,
        globalFilters: {
          ...this.state.globalFilters,
          practiceIds: this.state.practices.map(
            (practice) => practice.id
          ) as string[],
        },
      });
    }
    const practiceIds = this.state.practices.map((practice) => practice.id);

    if (practiceIds && practiceIds.length !== 0) {
      const paginationConfig: PaginationConfig = await patientCollection.fetch({
        practiceIds,
        lifecycleState: resetStatus ? ["ACTIVE"] : ["ACTIVE", "COMPLETED"],
        includeBilling: 1,
      });
      this.setState({
        patients: patientCollection.container.map((patient) =>
          patient.pluckAll()
        ),
      });
      this.props.setPatientsState(patientCollection);
      this.setPatientEnrollmentData();
      this.setPatientCurrentlyBillableCounts();
    } else {
      this.setState({
        patients: patientCollection.container.map((patient) =>
          patient.pluckAll()
        ),
      });
      this.props.setPatientsState(patientCollection);
    }
    patientCollection.trigger(MODEL_SYNCED);
  };

  execPatientSearch = async () => {
    if (this.isFilterSelected()) {
      const { practiceIds, providerIds, userIds, userStatuses } =
        this.state.globalFilters;
      LoadingIndicator.fire.show();
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
      if (
        (practiceIds && practiceIds.length !== 0) ||
        (providerIds && providerIds.length !== 0) ||
        (userIds && userIds.length !== 0) ||
        userStatuses
      ) {
        let options: any = {};
        options.practiceIds = practiceIds;
        options.providerIds = providerIds;
        options.assignedUserIds = userIds;
        options.includeBilling = 1;
        if (userStatuses?.length !== 0) {
          options.lifecycleState = userStatuses;
        } else {
          options.lifecycleState = ["ACTIVE", "COMPLETED"];
        }
        if (
          this.state.practices &&
          this.state.practices.length !== 0 &&
          (!practiceIds || practiceIds.length === 0)
        ) {
          const practiceIds = this.state.practices.map(
            (practice) => practice.id
          );
          options.practiceIds = practiceIds;
        }
        const paginationConfig: PaginationConfig =
          await patientCollection.fetch(options);
        this.setState({
          patients: patientCollection.container.map((patient) =>
            patient.pluckAll()
          ),
        });
        this.props.setPatientsState(patientCollection);
        this.setPatientEnrollmentData();
        this.setPatientCurrentlyBillableCounts();
      }
      patientCollection.trigger(MODEL_SYNCED);
    } else {
      this.handleSelectOrganization(false);
    }
  };

  setFieldValue:
    | ((
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void)
    | undefined;
  resetFormFields: ((initialValues: {}) => void) | undefined;

  setPatientEnrollmentData = () => {
    const patients = this.state.patients;

    const todaysDate = new Date(),
      y = todaysDate.getFullYear(),
      m = todaysDate.getMonth();
    const firstDateOfCurrentMonth = new Date(y, m, 1);
    const firstDayOfCurrentYear = new Date(y, 1, 1);

    const [currentCount, monthCount, yearCount] = [0, 0, 0];

    // currentCount = patients.filter((patient) => {
    //   if (patient.programs.length) {
    //     const isRPM = patient.programs.find(
    //       (program) => program.type === ProgramType.RPM
    //     );
    //     const isAdded = patient.programs.find(
    //       (program) => program.state === PatientProgramState.ADDED
    //     );
    //     if (isRPM && isAdded) return patient;
    //   }
    // }).length;
    // patients.forEach((patient) => {
    /**
     * Counting the number of patient who are added this month.
     */
    // const isCurrentMonthEnrolled = patient.programHistory.find(
    //   (history) =>
    //     history.transition === ProgramActions.ADD_PATIENT &&
    //     history.type === ProgramType.RPM &&
    //     firstDateOfCurrentMonth < new Date(history.time)
    // );

    /**
     * Counting the number of patient are added this year.
     */
    // const isCurrentYearEnrolled = patient.programHistory.find(
    //   (history) =>
    //     history.transition === ProgramActions.ADD_PATIENT &&
    //     history.type === ProgramType.RPM &&
    //     firstDayOfCurrentYear < new Date(history.time)
    // );

    // if (isCurrentMonthEnrolled) {
    //   monthCount++;
    // }
    // if (isCurrentYearEnrolled) {
    //   yearCount++;
    // }
    //  });

    this.setFieldValue!("currentlyEnrolled", currentCount);
    this.setFieldValue!("enrolledMonth", monthCount);
    this.setFieldValue!("enrolledYear", yearCount);
  };

  setPatientCurrentlyBillableCounts() {
    const patients = this.state.patients;
    let [CPT99454, CPT99457, CPT99458] = [0, 0, 0];
    let [nonBillableCPT99454, nonBillableCPT99457, nonBillableCPT99458] = [
      0, 0, 0,
    ];

    patients.forEach((patient) => {
      if (patient.billing) {
        if (
          patient.billing.cpt99454.reimbursableEvents &&
          patient.billing.cpt99454.reimbursableEvents > 0
        ) {
          CPT99454++;
        } else {
          nonBillableCPT99454++;
        }
        if (
          patient.billing.cpt99457.reimbursableEvents &&
          patient.billing.cpt99457.reimbursableEvents > 0
        ) {
          CPT99457++;
        } else {
          nonBillableCPT99457++;
        }
        if (
          patient.billing.cpt99458.reimbursableEvents &&
          patient.billing.cpt99458.reimbursableEvents > 0
        ) {
          CPT99458++;
        } else {
          nonBillableCPT99458++;
        }
      }
    });

    this.setFieldValue!("CPT99454", CPT99454);
    this.setFieldValue!("CPT99457", CPT99457);
    this.setFieldValue!("CPT99458", CPT99458);
    this.setFieldValue!("nonBillableCPT99454", nonBillableCPT99454);
    this.setFieldValue!("nonBillableCPT99457", nonBillableCPT99457);
    this.setFieldValue!("nonBillableCPT99458", nonBillableCPT99458);
  }

  render() {
    return (
      <>
        <Formik initialValues={this.getInitialValues()} onSubmit={() => {}}>
          {(formik) => {
            this.setFieldValue = formik.setFieldValue;
            this.resetFormFields = formik.resetForm;
            return (
              <Form>
                <div>
                  <nav
                    ref={this.headerRef}
                    className="navbar navbar-expand-lg navbar-light sticky-top bg-white shadow-sm nav-filters"
                  >
                    <h6 className={globalStyles.pageHeading}>User Dashboard</h6>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarText"
                      role="tablist"
                    >
                      <ul
                        className="navbar-nav mx-auto"
                        id="pills-tab"
                        style={{ fontSize: "15px" }}
                      >
                        <li className="nav-item px-3">
                          <a className="nav-link">
                            {!Guard.accessLevel(
                              UserParentTypes.Practice
                            ).canActivate(this.props.currentUser!) && (
                              <OrganizationDropdownComponent
                                disabled={
                                  !Guard.accessLevel(
                                    UserParentTypes.Admin
                                  ).canActivate(this.props.currentUser!)
                                }
                                setDefaultOrganization={true}
                                currentOrganization={
                                  this.props.selectedOrganization?.name!
                                }
                                onChange={async (value: string) => {
                                  // Sync organization with organizationId
                                  const orgModel: OrganizationModel =
                                    await OrganizationModel.sync(value);

                                  const path = window.location.pathname;
                                  window.history.replaceState(
                                    null,
                                    ``,
                                    `${path}?organizationId=${value}`
                                  );

                                  // make selected organization the default

                                  await this.props.selectOrganization(
                                    orgModel.pluckAll()
                                  );
                                }}
                              />
                            )}
                          </a>
                        </li>
                        <li className="nav-item px-3">
                          <a className="nav-link">
                            <BasicMultiSelect
                              data={this.state.practices.map(
                                (practice: Practice) => ({
                                  value: practice._meta?.id,
                                  label: practice.name,
                                })
                              )}
                              onChange={async (value: string[]) => {
                                await this.setState({
                                  globalFilters: {
                                    ...this.state.globalFilters,
                                    providerIds: undefined,
                                    userIds: undefined,
                                    practiceIds: value,
                                  },
                                });
                                this.execPatientSearch();
                              }}
                              findValueBy={this.state.globalFilters.practiceIds}
                              value={this.state.globalFilters.practiceIds}
                              isSearchable={true}
                              sortable={true}
                              placeholder={this.state.practicePlaceholder}
                              onOpen={() => {
                                this.fetchPractices();
                              }}
                              picker="check"
                              style={{ width: 200 }}
                              disabled={Guard.accessLevel(
                                UserParentTypes.Practice
                              ).canActivate(this.props.currentUser!)}
                            />
                          </a>
                        </li>
                        <li className="nav-item px-3">
                          <a className="nav-link">
                            <BasicMultiSelect
                              data={this.state.providers.map(
                                (provider: Provider) => ({
                                  value: provider._meta?.id,
                                  label: `${provider.firstName} ${provider.lastName}, ${provider.credentials}`,
                                })
                              )}
                              onChange={async (value: string[]) => {
                                await this.setState({
                                  globalFilters: {
                                    ...this.state.globalFilters,
                                    providerIds: value,
                                    userIds: undefined,
                                  },
                                });
                                this.execPatientSearch();
                              }}
                              findValueBy={this.state.globalFilters.providerIds}
                              value={this.state.globalFilters.providerIds}
                              isSearchable={true}
                              sortable={true}
                              placeholder="All Providers"
                              onOpen={() => {
                                this.fetchProviders();
                              }}
                              picker="check"
                              style={{ width: 200 }}
                            />
                          </a>
                        </li>
                        <li className="nav-item px-3">
                          <a className="nav-link">
                            <BasicMultiSelect
                              data={this.state.users}
                              onChange={async (value: string[]) => {
                                await this.setState({
                                  globalFilters: {
                                    ...this.state.globalFilters,
                                    userIds: value,
                                  },
                                });
                                this.execPatientSearch();
                              }}
                              findValueBy={this.state.globalFilters.userIds}
                              value={this.state.globalFilters.userIds}
                              isSearchable={true}
                              placeholder="All Users"
                              onOpen={async () => {
                                await this.fetchUsers();
                              }}
                              groupBy="role"
                              picker="check"
                              style={{ width: 200 }}
                            />
                          </a>
                        </li>
                        <li className="nav-item px-3">
                          <a className="nav-link">
                            <BasicMultiSelect
                              data={this.state.statuses}
                              onChange={async (value: string[]) => {
                                await this.setState({
                                  globalFilters: {
                                    ...this.state.globalFilters,
                                    userStatuses: value,
                                  },
                                });
                                this.execPatientSearch();
                              }}
                              findValueBy={
                                this.state.globalFilters.userStatuses
                              }
                              value={this.state.globalFilters.userStatuses}
                              isSearchable={false}
                              sortable={false}
                              placeholder="All Patient Status"
                              picker="check"
                              style={{ width: 200 }}
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div className="row align-items-center" />
                  <div
                    className="user-management"
                    style={{ marginBottom: "40px" }}
                  >
                    <div
                      className={cx(
                        styles.userDashboard,
                        "management-data",
                        "mt-3"
                      )}
                    >
                      <div className="row px-1">
                        <div className="col-md-6 text-center">
                          <div className="border p-3">
                            <div className={cx("row", styles.billingDetailRow)}>
                              <div className="col-3">
                                <h6 className="box-heading py-4">
                                  Patients Billable
                                </h6>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99454"
                                    name="CPT99454"
                                    type="text"
                                    placeholder="CPT 99454"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99457"
                                    name="CPT99457"
                                    type="text"
                                    placeholder="CPT 99457"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99458"
                                    name="CPT99458"
                                    type="text"
                                    placeholder="CPT 99458"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 text-center">
                          <div className="border p-3">
                            <div className={cx("row", styles.billingDetailRow)}>
                              <div className="col-3">
                                <h6 className="box-heading py-4">
                                  Patients Not Billable
                                </h6>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99454"
                                    name="nonBillableCPT99454"
                                    type="text"
                                    placeholder="CPT 99454"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99457"
                                    name="nonBillableCPT99457"
                                    type="text"
                                    placeholder="CPT 99457"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <TextInput
                                    className="bg-white"
                                    label="CPT 99458"
                                    name="nonBillableCPT99458"
                                    type="text"
                                    placeholder="CPT 99458"
                                    required={false}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
    deselectOrganization: (): any => dispatch(deselectOrganization()),

    setPatients: (patientCollection: PatientModel[]) =>
      dispatch(setPatientsThunk(patientCollection)),
  };
};

const mapStateToProps = (state: {
  organization: {
    organizations: Organization[];
    selectedOrganization: Organization;
  };
  user: { currentUser: User };
  patient: { patients: Patient[] };
}) => {
  return {
    selectedOrganization: state.organization.selectedOrganization,
    currentUser: state.user.currentUser,
    patients: state.patient.patients,
  };
};

export const UserDashboardForm = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
