import * as Yup from "yup";

import { Validation } from "@/components/form";
import { UserActions } from "@/domain/user/model";

export const makeValidation = (
  userAction: UserActions | undefined,
  isCreateAdminUser: boolean,
  isEditMode: boolean,
  hasAllPracticesAccess: boolean
) =>
  Yup.object({
    id: Validation.id,
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    email: Validation.email,
    organization:
      isCreateAdminUser && hasAllPracticesAccess
        ? Validation.notRequired
        : Validation.required,
    practice:
      userAction !== UserActions.CreatePracticeUser
        ? Validation.notRequired
        : Validation.required,
    phone: Validation.phone,
    hasAutoAssign: Validation.notRequiredBoolean,
    ...(isCreateAdminUser || !isEditMode
      ? { role: Validation.nullRequiredArray }
      : {}),
    location: Validation.id,
  });
