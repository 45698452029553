import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { FormikHelpers } from "formik";
import cx from "clsx";

import { KitShipmentForm } from "@/domain/kits/view/KitShipmentForm";
import { CheckboxOption } from "@/types";
import { Patient } from "@/domain/patient/model/types";
import {
  AccessoryType,
  DeviceState,
  PatientDevice,
  ServiceLevel,
} from "@/domain/kits/models/device.interface";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { Workflows } from "../KitShipmentForm/constants";

import styles from "./styles.module.scss";
import { Search } from "@/components/search";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { ModelCollection } from "@/library/model";
import { DeviceModel } from "@/domain/kits/models/device.model";
import { MODEL_SYNCED } from "@/library/constants";

export type Props = {
  open?: boolean;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
  onSubmit: (
    values: KitShipmentFormValues,
    form: FormikHelpers<KitShipmentFormValues>
  ) => void;
  step: number;
  patient: Patient;
  description?: React.ReactNode;
  serviceLevel?: ServiceLevel;
  deviceOptions: (PatientDevice & CheckboxOption)[];
  onDeviceCheck: (checked: boolean, deviceId?: string) => void;
  selectedPeripherals?: AccessoryType[];
  setDeviceOptions: (devices: (PatientDevice & CheckboxOption)[]) => void;
};

export const KitFulfillmentModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onNext,
    onPrev,
    onSubmit,
    step,
    patient,
    description,
    serviceLevel,
    deviceOptions,
    onDeviceCheck,
    selectedPeripherals,
    setDeviceOptions,
  } = props;

  const title = step === 0 ? "Choose Kit ID" : "Kit Shipment";
  const isSelected = deviceOptions.find((opt) => opt.checked);
  const enableNextBtn = step === 0 && !!isSelected;
  const [searchInput, setSearchInput] = useState<string>("");
  const { practiceId } = patient;
  const renderKitStatus = (assignedUserId: null | string | undefined) => {
    return !assignedUserId ? "Available" : "Assigned";
  };

  const execSearch = async (query?: Record<string, any>) => {
    setSearchInput(query?.name || "");
    LoadingIndicator.fire.show();
    const deviceCollection: ModelCollection<DeviceModel, PatientDevice> =
      DeviceModel.makeDevicesCollection();
    deviceCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    deviceCollection.withParams({
      searchBy: query?.name,
      practiceIds: [practiceId],
      includeTagInfo: 1,
    });
    const iterator = await deviceCollection.getMany();
    const deviceModels = await iterator.getAll();
    const devices = deviceModels.map((dm) => dm.pluckAll());
    const selectedDevices = devices.filter((device) => {
      if (
        device.serviceLevel === serviceLevel &&
        device.lifecycle?.state === DeviceState.Active &&
        !device.patientId
      )
        return device;
    });
    setDeviceOptions(
      selectedDevices.map((device) => {
        return {
          ...device,
          checked: false,
        } as any;
      })
    );

    LoadingIndicator.fire.hide();
  };
  const handleResetClick = () => {
    execSearch();
  };

  useEffect(() => {
    setSearchInput("");
  }, []);

  const renderDeviceTable = () => {
    return (
      <div style={{ minHeight: 120 }}>
        <div className={styles.row}>
          <Search
            pageLimit={300}
            onSearch={execSearch}
            value={searchInput}
            placeholder="Search on ICCID or Kit Number"
            containerClassName={styles.searchMargin}
          >
            <Button
              appearance="subtle"
              onClick={handleResetClick}
              style={{ marginLeft: 7 }}
            >
              <span className="text-rca">Reset Search & Filter</span>
            </Button>
          </Search>
          <div style={{ paddingBottom: 10 }} />
        </div>
        <table
          className={cx(
            styles.deviceTable,
            "table-sm table-striped table-bordered table-light table-hover w-100"
          )}
          style={{ border: 0 }}
        >
          <thead className="thead">
            <tr>
              <th className="bg-primary text-white th-text text-center">
                Select
              </th>
              <th className="bg-primary text-white th-text text-center">
                MSN (Kit ID)
              </th>
              <th className="bg-primary text-white th-text text-center">
                ICCID
              </th>
              <th className="bg-primary text-white th-text text-center">
                Location
              </th>
              <th className="bg-primary text-white th-text text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {deviceOptions.map((device) => (
              <tr key={device.id}>
                <td>
                  <label className={styles.radioContainer}>
                    <input
                      type="radio"
                      name="peripheral-radio"
                      checked={
                        deviceOptions.find((device) => device.checked)?.id ===
                        device.id
                      }
                      onChange={(e) =>
                        onDeviceCheck(e.target.checked, device.id)
                      }
                    />
                  </label>
                </td>
                <td>{device.msn}</td>
                <td>{device.iccid}</td>
                <td>
                  {(device.tags || []).map((tag) => tag.value).join(", ")}
                </td>
                <td>{renderKitStatus(device.patientId)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="lg"
      enforceFocus
      backdrop="static"
      dialogClassName={styles.modalDialog}
    >
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title>{title}</Modal.Title>
        {description}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {step === 0 && (
          <>
            {renderDeviceTable()}

            <div className={styles.actions}>
              <Button appearance="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                appearance="primary"
                onClick={onNext}
                disabled={!enableNextBtn}
                style={{ minWidth: 70 }}
              >
                Next
              </Button>
            </div>
          </>
        )}

        {step === 1 && (
          <KitShipmentForm
            workflow={Workflows.fulfillment}
            patient={patient}
            hasBackButton={{
              enabled: true,
              onClick: onPrev,
            }}
            selectedDevice={deviceOptions.find((device) => device.checked)}
            selectedPeripherals={selectedPeripherals}
            serviceLevel={serviceLevel}
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};
