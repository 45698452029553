import { Iterable } from "../../library/iteration";
import { RemoteCareAPISync } from "../../library/core/api";
import { EventEmitter } from "../../library/core/event";
import { Attributes, Model } from "../../library/model";
import { deserialize } from "../../library/model";
import { Role } from "./role.interface";
import { ModelCollection } from "../../library/model/model.collection";

export class RoleModel extends Model<Role> {
  static readonly path = "roles";

  /**
   * Makes a new instance of PracticeModel. It requires only the attributes
   * that would be used to instatiate new Practice Model.
   *
   * @param attributes
   */
  static make(attributes: Role): RoleModel {
    return new RoleModel(
      new Attributes<Role>(attributes),
      new RemoteCareAPISync<Role>(RoleModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of roles using the provided resource url and a deserializer thunk.
   * The entire role list can be accessed in the models member of the Collection class.
   */
  static makeCollection(): ModelCollection<RoleModel, Role> {
    return new ModelCollection<RoleModel, Role>(
      `${RemoteCareAPISync.host}/${RoleModel.path}`,
      (entity: Role) => deserialize(entity, RoleModel.make)
    );
  }

  /**
   * Sync provider by Id.
   * @param id
   */
  static async sync(id: string): Promise<RoleModel> {
    const role: Role = (
      await new RemoteCareAPISync<Role>(RoleModel.path).fetch(id)
    ).data;
    return deserialize(role, RoleModel.make);
  }

  /**
   * Sync providers by Ids.
   *
   * @param id
   */
  static syncMany = async (ids: string[]): Promise<RoleModel[]> =>
    RemoteCareAPISync.concurrently(ids, RoleModel.path, RoleModel.make);
}
