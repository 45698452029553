import { NoteAuthor } from "./types";

export const renderAuthor = (author?: NoteAuthor | null) => {
  if (!author) return "-";

  const { name, credentials } = author;

  const split = name.split(" ");
  if (split.length === 1) return name;
  const last = split.splice(1);
  const creds = credentials ? `, ${credentials}` : "";

  return `${split[0].charAt(0)} ${last.join(" ")}${creds}`;
};
