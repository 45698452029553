import { extractphone } from "@/components/form";
import { PatientModel } from "@/domain/patient/model";
import { Patient, PatientForm } from "@/domain/patient/model/types";
import { PatientConditionForm } from "@/domain/patient/model/types";
import { Serializeable } from "../serializer";
import {
  getPatientDesignatedRepresentatives,
  patientDateOfBirth,
} from "./utils";

/**
 * Serializer for creating new Patient.
 */
export class CreatePatientSerializer implements Serializeable<PatientModel> {
  constructor(protected readonly formData: PatientForm) {}

  serialize(): PatientModel {
    return PatientModel.make({
      mrn: this.formData.mrn === "" ? null : this.formData.mrn,
      providerId: this.formData.providerId,
      billingType:
        this.formData.billingType === "" ? null : this.formData.billingType,
      demographics: {
        legalName: {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          middleName:
            this.formData.middleName === "" ? null : this.formData.middleName,
          prefix: this.formData.prefix === "" ? null : this.formData.prefix,
          suffix: this.formData.suffix === "" ? null : this.formData.suffix,
        },
        dateOfBirth: patientDateOfBirth(this.formData.dateOfBirth),
        birthSex:
          this.formData.birthSex === "UNKNOWN"
            ? null
            : this.formData.birthSex.toUpperCase(),
        gender: this.formData.gender === "" ? null : this.formData.gender,
        race: this.formData.race === "UNKNOWN" ? null : this.formData.race,
        language: this.formData.language,
        ethnicity:
          this.formData.ethnicity === "" ? null : this.formData.ethnicity,
        preferredName:
          this.formData.preferredName === ""
            ? null
            : this.formData.preferredName,
      },
      address: {
        street: [this.formData.address],
        locality: this.formData.city,
        region: this.formData.state,
        postalCode: this.formData.zip,
        country: this.formData.country,
      },
      email: this.formData.email === "" ? null : this.formData.email,
      phone: {
        primary: {
          number: extractphone(this.formData.primaryPhone),
          isSmsAllowed: this.formData.allowSMS?.primary,
        },
        ...(this.formData.secondaryPhone
          ? {
              secondary: {
                number: extractphone(this.formData.secondaryPhone),
                isSmsAllowed: this.formData.allowSMS?.secondary,
              },
            }
          : {}),
      },
      assignedUserId:
        this.formData.careTeam === "" ? null : this.formData.careTeam,
      priority:
        this.formData.priority === "NONE" ? null : this.formData.priority,
      anthropometry: {
        height:
          this.formData.height === ""
            ? null
            : { value: this.formData.height, unit: this.formData.heightUnit },
        weight:
          this.formData.weight === ""
            ? null
            : { value: this.formData.weight, unit: this.formData.weightUnit },
      },

      designatedRepresentatives: getPatientDesignatedRepresentatives(
        this.formData.emergencyContact
      ),
      conditions:
        this.formData.diagnosis && this.formData.diagnosis.length > 0
          ? this.formData.diagnosis
              .map((condition: PatientConditionForm) => {
                if (condition.displayName !== "") {
                  return {
                    type: condition.type,
                    icd10:
                      condition.icdCode.value === ""
                        ? null
                        : {
                            code: condition.icdCode.value,
                            description: condition.icdCode.label,
                          },
                    displayName: condition.displayName,
                  };
                }
              })
              .filter((element) => element !== undefined)
          : null,
      programId: this.formData.programId,
      encounter: this.formData.encounter,
    } as unknown as Patient);
  }
}
