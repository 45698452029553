import { Route } from "react-router-dom";
import { UserModel } from "../../domain/user/model";
import { Routable } from "./";

/**
 * Route for Organization User landing page.
 */
export class OrganizationUserRoute implements Routable {
  private _uri: string[] = ["organizations"];

  constructor(private readonly user: UserModel) {
    this._uri.push(user.parentId);
  }

  static build = (userModel: UserModel): Routable =>
    new OrganizationUserRoute(userModel);

  get to(): string {
    return this.URI;
  }

  get URI(): string {
    return this._uri.join("/");
  }
}
