import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  TimerActivityState,
  TimerColors,
  TimerState,
  TimerUserState,
} from "@/components/countdown/types";
import { dispatchSetPatientTimer } from "@/domain/patient/redux/actions";
import { UserPatientTimerState } from "@/domain/patient/redux/types";
import { TimerProps } from "./types";
import Countdown from "@/components/countdown";
import { RootState } from "@/types";
import { NoteModel } from "@/domain/notes/model";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ResourceType } from "@/library/core/config/resource";
import {
  createTimerNote,
  getUserPatientPreviousTime,
  handlePageUnload,
} from "./helper";
import {
  AppContext,
  AppWrapper,
  useGlobalAppContext,
} from "@/components/context";

const PatientTimer: React.FC<TimerProps> = ({
  userPatientId,
  patientTimer,
  patient,
  setTimer,
  ...props
}) => {
  const { setWorkTimerState, isVirtualVisitRunning, workTimerState } =
    useGlobalAppContext();
  const currentUserTimer: UserPatientTimerState | undefined =
    patientTimer[userPatientId];

  const history = useHistory();

  const [timerCurrentState, setTimerCurrentState] =
    useState<UserPatientTimerState>({
      userPatientId,
      seconds: 0,
      hours: 0,
      minutes: 0,
      timerUserState: TimerUserState.ENABLED,
      timerActivityState: TimerActivityState.INITIAL,
    });

  const handleTimerState = async (timerState: TimerState) => {
    setTimerCurrentState((prevState) => ({ ...prevState, ...timerState }));
    if (setWorkTimerState) {
      await setWorkTimerState({
        userPatientId: timerCurrentState.userPatientId,
        ...timerState,
        timerUserState: currentUserTimer.timerUserState,
        timerActivityState: currentUserTimer.timerActivityState,
      });
    }
  };

  useEffect(() => {
    if (!isVirtualVisitRunning) {
      setTimerCurrentState((prevState) => ({
        ...prevState,
        ...workTimerState!,
      }));
    }
  }, [isVirtualVisitRunning]);

  const getCurrentRoute = () => {
    const [location, currentPath] = history.location.pathname.split("/");
    return currentPath;
  };

  const handleRouteChange = async () => {
    if (getCurrentRoute() === "patient") return;
    const seconds = timerCurrentState.seconds + timerCurrentState.minutes * 60;
    if (!seconds) return;
    try {
      const timerNote = createTimerNote(timerCurrentState, patient);
      const created = await Invoker.make(
        Receiver.make(
          NoteModel.make(timerNote, true),
          ResourceType.note,
          RCAResourceActions.Create
        ),
        CleanTask.make(handleSuccess),
        FinallyTask.make(),
        successMessage()
      ).invoke();
      if (!created)
        setTimer({
          ...currentUserTimer,
          ...timerCurrentState,
        });
    } catch (error) {
      setTimer({
        ...currentUserTimer,
        ...timerCurrentState,
      });
    }
  };

  const successMessage = () =>
    ` ${
      timerCurrentState.seconds + timerCurrentState.minutes * 60
    } seconds were applied to patient ${
      patient.demographics.legalName.firstName
    } ${patient.demographics.legalName.lastName} `;

  const handleSuccess = () => {
    setTimer({
      ...currentUserTimer,
      minutes: 0,
      seconds: 0,
      hours: 0,
      timerActivityState: TimerActivityState.INITIAL,
      timerUserState: TimerUserState.ENABLED,
    });
  };

  useEffect(() => {
    if (!currentUserTimer) return;
    const unlisten = history.listen(handleRouteChange);
    return unlisten;
  }, [timerCurrentState]);

  const handleIdle = (timerState: TimerState) => {
    setTimer({
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.ENABLED,
      timerActivityState: TimerActivityState.INACTIVE,
    });
  };

  const handleInactivityPause = (timerState: TimerState) => {
    setTimer({
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.ENABLED,
      timerActivityState: TimerActivityState.PAUSED,
    });
  };

  const getTimerColor = () => {
    const defaultColor = props.currentUser?.canAccumulateBillableTime
      ? TimerColors.Blue
      : TimerColors.Gray;
    const { timerActivityState, timerUserState } = currentUserTimer;

    if (timerActivityState === TimerActivityState.PAUSED) {
      return TimerColors.Red;
    }

    if (timerActivityState === TimerActivityState.INACTIVE) {
      return TimerColors.Yellow;
    }

    if (timerUserState === TimerUserState.DISABLED) {
      return TimerColors.Red;
    }

    return defaultColor;
  };

  let prevState: TimerActivityState = TimerActivityState.INITIAL;
  const handleTimerClick = (timerState: TimerState) => {
    if (!currentUserTimer) return;
    if (prevState === TimerActivityState.PAUSED) return;

    if (currentUserTimer.timerUserState === TimerUserState.DISABLED) {
      const newTimer = {
        userPatientId,
        ...timerState,
        timerUserState: TimerUserState.ENABLED,
        timerActivityState: TimerActivityState.ACTIVE,
      };
      setTimer(newTimer);
      if (setWorkTimerState) {
        setWorkTimerState({
          userPatientId: timerCurrentState.userPatientId,
          ...timerState,
          timerUserState: newTimer.timerUserState,
          timerActivityState: newTimer.timerActivityState,
        });
      }
      return;
    }

    if (currentUserTimer.timerActivityState === TimerActivityState.INITIAL) {
      clickOnInitialTimerState(timerState);
      return;
    }

    if (currentUserTimer.timerActivityState === TimerActivityState.ACTIVE) {
      clickOnActiveTimer(timerState);
      return;
    }

    if (currentUserTimer.timerActivityState === TimerActivityState.INACTIVE) {
      clickOnInactiveTimer(timerState);
      return;
    }

    if (currentUserTimer.timerActivityState === TimerActivityState.PAUSED) {
      clickOnPausedTimer(timerState);
      return;
    }
  };

  const clickOnInitialTimerState = (timerState: TimerState) => {
    setTimer({
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.DISABLED,
      timerActivityState: TimerActivityState.ACTIVE,
    });
  };

  const clickOnActiveTimer = (timerState: TimerState) => {
    const newTimer = {
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.DISABLED,
      timerActivityState: TimerActivityState.ACTIVE,
    };
    setTimer(newTimer);

    if (setWorkTimerState) {
      setWorkTimerState({
        userPatientId: timerCurrentState.userPatientId,
        ...timerState,
        timerUserState: newTimer.timerUserState,
        timerActivityState: newTimer.timerActivityState,
      });
    }
  };

  const clickOnInactiveTimer = (timerState: TimerState) => {
    const newTimer = {
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.DISABLED,
      timerActivityState: TimerActivityState.ACTIVE,
    };
    setTimer(newTimer);

    if (setWorkTimerState) {
      setWorkTimerState({
        userPatientId: timerCurrentState.userPatientId,
        ...timerState,
        timerUserState: newTimer.timerUserState,
        timerActivityState: newTimer.timerActivityState,
      });
    }
  };

  const clickOnPausedTimer = (timerState: TimerState) => {
    const newTimer = {
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.ENABLED,
      timerActivityState: TimerActivityState.ACTIVE,
    };
    setTimer(newTimer);

    if (setWorkTimerState) {
      setWorkTimerState({
        userPatientId: timerCurrentState.userPatientId,
        ...timerState,
        timerUserState: newTimer.timerUserState,
        timerActivityState: newTimer.timerActivityState,
      });
    }
  };

  const handleUserStateToActive = (timerState: TimerState) => {
    if (currentUserTimer.timerUserState === TimerUserState.DISABLED) return;
    prevState = currentUserTimer.timerActivityState;
    setTimer({
      userPatientId,
      ...timerState,
      timerUserState: TimerUserState.ENABLED,
      timerActivityState: TimerActivityState.ACTIVE,
    });
  };

  useEffect(() => {
    if (!currentUserTimer) {
      setTimer({
        userPatientId,
        seconds: 0,
        minutes: 0,
        hours: 0,
        timerUserState: TimerUserState.ENABLED,
        timerActivityState: TimerActivityState.INITIAL,
      });
    }
  }, []);

  useEffect(() => {
    if (!currentUserTimer) return;
    const { seconds, minutes, hours } = timerCurrentState;

    let timerActivityState = currentUserTimer.timerActivityState;
    if (
      currentUserTimer.timerActivityState === TimerActivityState.PAUSED ||
      currentUserTimer.timerActivityState === TimerActivityState.INACTIVE
    ) {
      timerActivityState = TimerActivityState.ACTIVE;
    }

    window.onbeforeunload = () => {
      handlePageUnload(userPatientId, {
        ...currentUserTimer,
        timerActivityState,
        ...{ seconds, minutes, hours },
      });
    };
  }, [currentUserTimer, timerCurrentState]);

  const stopPropogation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isRunning = () => {
    return (
      currentUserTimer.timerUserState === TimerUserState.ENABLED &&
      currentUserTimer.timerActivityState !== TimerActivityState.INITIAL &&
      currentUserTimer.timerActivityState !== TimerActivityState.PAUSED
    );
  };

  return (
    <AppContext.Consumer>
      {({ isVirtualVisitRunning, workTimerState }) => {
        return (
          <div className="text-center" onClick={stopPropogation}>
            <h4>
              {currentUserTimer && !isVirtualVisitRunning && (
                <Countdown
                  running={isRunning()}
                  offsetTimestamp={getUserPatientPreviousTime(currentUserTimer)}
                  timerActivityState={currentUserTimer.timerActivityState}
                  onClick={handleTimerClick}
                  onIdle={handleIdle}
                  onInactiveStopped={handleInactivityPause}
                  connectToTimerState={handleTimerState}
                  timerColor={getTimerColor()}
                  onActiveUser={handleUserStateToActive}
                />
              )}
            </h4>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};

const mapStateToProps = (state: RootState) => ({
  patientTimer: state.patient.timerByUserPatientId,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTimer: (time: UserPatientTimerState) =>
      dispatch(dispatchSetPatientTimer(time)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientTimer);
