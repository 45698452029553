import { Message, Notification as Notify, toaster } from "rsuite";

import "./notification.styles.css";

export const notify = (
  type: "error" | "success" | "info" | "warning",
  message: string | string[],
  heading?: string,
  duration?: number
) => {
  toaster.push(
    <Notify type={type} header={heading || ""} duration={duration || 1000}>
      <>
        {typeof message === "string" ? (
          <Message showIcon type={type} title={heading}>
            {message}
          </Message>
        ) : (
          <Message showIcon type={type}>
            <ul>
              {message.map((m) => (
                <li>{m}</li>
              ))}
            </ul>
          </Message>
        )}
      </>
    </Notify>
  );
};

export const Notification = {
  notify,
};
