import { Iterator } from "./../iteration/iterator";

export class ModelIterator<M, E> extends Iterator<M, E> {
  /**
   * Get the first collection before iterating.
   */
  get getFirst(): M[] {
    return this.collection.container;
  }

  /**
   * Check if there are more resources to the right of the cursor.
   */
  get hasNext(): boolean {
    return this.collectionState.currentPage < this.collectionState.lastPage;
  }

  /**
   * Check if there are more resources to the left of the cursor.
   */
  get hasPrevious(): boolean {
    return this.collectionState.currentPage !== 1;
  }

  /**
   * Get previous resources in the api.
   */
  async next(): Promise<M[]> {
    this.collectionState = await this.collection.fetch({
      currentPage: this.collectionState.currentPage + 1,
      limit: this.collection.limit,
    });
    return this.collection.container;
  }

  /**
   * Get previous resources in the api.
   */
  async previous(): Promise<M[]> {
    this.collectionState = await this.collection.fetch({
      currentPage: this.collectionState.currentPage - 1,
    });
    return this.collection.container;
  }

  /**
   * Get all resources from page first through last in the api.
   */
  async getAll(): Promise<M[]> {
    while (this.hasNext) {
      await this.next();
    }
    return this.collection.container;
  }

  /**
   * Get current position of cursor.
   */
  get position(): number {
    return this.collectionState.currentPage;
  }
}
