import React, { useState, useEffect, FunctionComponent } from "react";
import { connect } from "react-redux";

import { Modal } from "@/components/modal";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import CreateContactForm from "./createContactForm.component";
import { ContactForm } from "./model/contact";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { extractphone, formatPhone } from "@/components/form";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
} from "@/domain/patient/redux/actions";

interface Props {
  patientId: string | undefined;

  selectedPatient: Patient[];
  setSelectedPatient: (patient: Patient) => void;
  deselectAllPatients: () => { type: string };
}

export const Component: FunctionComponent<Props> = (props: Props) => {
  const { patientId } = props;

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [modalBtn, setModalBtn] = useState("Add");
  const [modalHeading, setModalHeading] = useState("Add Contact");

  const [patientContacts, setPatientContacts]: any = useState([]);
  const [targetedContact, setTargetedContact]: any = useState(null);
  const [targetedContactId, setTargetedContactId]: any = useState(null);

  const updatePatientContactState = (updatedContacts: ContactForm[]) => {
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      props.selectedPatient.forEach((patient: Patient) => {
        if (patient.id === patientId) {
          patient.contacts = updatedContacts;
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }
      });
    }
  };

  const parseValues = (
    contact: ContactForm,
    editMode?: boolean
  ): ContactForm[] => {
    let newContacts = [];
    if (editMode) {
      newContacts = patientContacts;
    } else if (patientContacts && patientContacts.length > 0) {
      newContacts = [...patientContacts, contact];
    } else {
      newContacts = [contact];
    }

    const contacts = newContacts.map((contact: ContactForm) => {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        relationship: contact.relationship === "" ? null : contact.relationship,
        mobile:
          !contact.mobile || contact.mobile === ""
            ? null
            : extractphone(contact.mobile!),
        email: !contact.email || contact.email === "" ? null : contact.email,
        notes: !contact.notes || contact.notes === "" ? null : contact.notes,
        isActive: true,
      };
    });
    return contacts;
  };

  const handleSubmit = async (
    contact: ContactForm,
    editMode?: boolean
  ): Promise<void> => {
    const patientModel = await PatientModel.sync(patientId!);
    if (editMode) {
      patientContacts[targetedContactId] = contact;
    }
    try {
      await patientModel.modifyPatient<Patient, "contacts">({
        contacts: parseValues(contact, editMode),
      });
      contact.mobile = contact.mobile ? extractphone(contact.mobile) : null;
      if (!editMode) {
        patientContacts && patientContacts.length > 0
          ? setPatientContacts([...patientContacts, contact])
          : setPatientContacts([contact]);
      }
      setPatientContacts((updatedContacts: ContactForm[]) => {
        updatePatientContactState(updatedContacts);
        return updatedContacts;
      });
      closeModal();
      Notification.notify(SUCCESS, "Contact updated successfully.");
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    const getUserContacts = async () => {
      const patientModel = await PatientModel.sync(patientId!);
      const patientContacts = patientModel.pluck("contacts");
      setPatientContacts(patientContacts);
    };
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      const patient: Patient | undefined = props.selectedPatient.find(
        (patient: Patient) => patient.id === patientId
      );
      if (patient && !!patientContacts) {
        setPatientContacts(patient.contacts);
      }
    } else {
      getUserContacts();
    }
  }, []);

  return (
    <>
      <div id="contacts" className="tab-pane fade in active show">
        <div className="row">
          <div className="col-6">
            <div className="text-left">
              <h4>Contacts</h4>
            </div>
          </div>
          <div className="col-6">
            <div className="text-right mb-2">
              <button
                className="btn btn-glow-primary add-user btn-primary btn-sm"
                onClick={() => {
                  setModalBtn("Add");
                  setModalHeading("Add Contact");
                  setShowModal(true);
                }}
              >
                <span>Add Contact</span>
              </button>
            </div>
          </div>
        </div>

        <div className="mt-1 table-responsive">
          <table className=" table table-striped table-sm table-light table-hover user-management-table table-bordered">
            <thead>
              <tr>
                <th className="bg-primary text-white small">
                  <span>Name</span>
                </th>
                <th className="bg-primary text-white small">
                  <span>Relationship</span>
                </th>
                <th className="bg-primary text-white small">
                  <span>Mobile</span>
                </th>
                <th className="bg-primary text-white small">
                  <span>Email</span>
                </th>
                <th className="bg-primary text-white small">
                  <span>Notes</span>
                </th>
                <th className="bg-primary text-white small">
                  <span>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {patientContacts &&
                patientContacts.map((contact: ContactForm, id: number) => (
                  <tr key={id}>
                    <td>
                      <a
                        href="#"
                        onClick={() => {
                          setTargetedContact(contact);
                          setTargetedContactId(id);
                          setModalBtn("Save Changes");
                          setModalHeading("Edit Contact");
                          setShowModal(true);
                        }}
                      >
                        {contact.firstName} {contact.lastName}
                      </a>
                    </td>
                    <td>{contact.relationship}</td>
                    <td>{contact.mobile ? formatPhone(contact.mobile) : ""}</td>
                    <td>{contact.email}</td>
                    <td>{contact.notes}</td>
                    <td>{contact.isActive ? "Active" : "Inactive"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        heading={modalHeading}
        button={modalBtn}
        handleClose={closeModal}
        show={showModal}
      >
        {showModal && (
          <CreateContactForm
            handleSubmit={handleSubmit}
            handleClose={closeModal}
            modalBtn={modalBtn}
            targetedContact={targetedContact}
            targetedContactId={targetedContactId}
          />
        )}
      </Modal>
    </>
  );
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setSelectedPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
  };
};

const mapStateToProps = (state: {
  patient: { patients: Patient[]; selectedPatients: Patient[] };
}) => {
  return {
    selectedPatient: state.patient.selectedPatients,
  };
};

export const ContactTab = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
