import React, { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { useFormik, FormikHelpers, FormikState, FormikProvider } from "formik";
import axios, { AxiosResponse } from "axios";
import SelectMultiple from "react-select";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Button } from "rsuite";
import cx from "clsx";

import { Select, TextInputMask } from "@/components/form";
import { PatientModel } from "@/domain/patient/model";
import {
  PatientConditionForm,
  Patient,
  PatientForm as PatientFormType,
  PatientConditionType,
} from "@/domain/patient/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import { CountryCodes, usStates } from "@/library/types/address";
import { OrganizationType } from "@/library/types";
import {
  ProviderId,
  setProviders as dispatchSetProviders,
  dispatchSetTargetProvider,
} from "@/domain/provider/redux";
import { Provider, ProviderModel } from "@/domain/provider/model";
import { Modal } from "@/components/modal";
import { TextInput } from "@/components/_inputs/TextInput";
import SecondaryDiagnosisForm from "../SecondaryDiagnosisForm";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { ResourceType } from "@/library/core/config/resource";
import { Language, Languages } from "@/library/types/language";
import { incrementPatientCountInProvider } from "@/domain/patient/redux/middleware";
import { User, UserModel } from "@/domain/user/model";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Organization } from "@/domain/organization/model/types";
import { OrganizationModel } from "@/domain/organization/model";
import { DatePicker } from "@/components/_inputs/DatePicker";
import { DATE_FORMAT } from "@/pipes/date/constants";
import { EmergencyContactForm } from "@/domain/patient/view/form/EmergencyContactForm";
import { ServiceLevelSection } from "@/domain/patient/view/form/peripherals/ServiceLevelSection";
import { LocationSelect } from "@/components/_selects/LocationSelect";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { PracticeSelect } from "@/components/_selects/PracticeSelect";
import { ProviderSelect } from "@/components/_selects/ProviderSelect";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { Tag } from "@/domain/tags/model/types";
import { BILLING_TYPES } from "@/library/types/billing";
import { Notification } from "@/components/notification/notification";
import { ERROR } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import {
  dispatchCreatePatient,
  dispatchSetTargetPatient,
  dispatchUpdatePatient,
} from "@/domain/patient/redux/actions";
import { RootState, SelectOption } from "@/types";
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
  MAX_CONTACT_FIELD_LIMIT,
} from "./constants";
import { Props } from "./types";
import { getInitialValues, option } from "./helpers";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { Program } from "@/domain/program/model/types";
import { fetchPrograms } from "@/components/pathway/functions";
import MaskedInput from "react-text-mask";

export const PatientForm: React.FC<Props> = (props) => {
  const {
    isPatientDetail,
    handleClose,
    onSetFormik,
    button,
    targetedPatient,
    resourceAction,
    assignServiceLevel,
    onEditPatient,
  } = props;

  const patients = useSelector<RootState, Patient[]>(
    (state) => state.patient.patients
  );
  const targetProviderId = useSelector<RootState, ProviderId>(
    (state) => state.provider.targetProvider
  );
  const targetPatientId = useSelector<RootState, PatientId>(
    (state) => state.patient.targetPatient
  );
  const practices = useSelector(selectPractices);
  const providers = useSelector<RootState, Provider[]>(
    (state) => state.provider.providers
  );
  const selectedPractices = useSelector<RootState, Practice[]>(
    (state) => state.practice.selectedPractices
  );
  const selectedOrganization = useSelector<RootState, Organization | undefined>(
    (state) => state.organization.selectedOrganization
  );
  const locationTags = useSelector(selectLocationTags);
  const dispatch = useDispatch();
  const setProviders = (providers: Provider[]) =>
    dispatch(dispatchSetProviders(providers));
  const updatePatient = (patient: Patient) =>
    dispatch(dispatchUpdatePatient(patient));
  const setTargetPatient = (id: PatientId) =>
    dispatch(dispatchSetTargetPatient(id));
  const updateTargetProviderId = (id: ProviderId) =>
    dispatch(dispatchSetTargetProvider(id));

  const locationTagIds = locationTags.map((tag: Tag) => tag._meta.id);
  let targetPatient =
    targetedPatient ||
    (patients.find(
      (patient: Patient) => patient.id === targetPatientId
    ) as Patient);
  const isEditMode = !!(targetPatientId || targetPatient);

  const [ICD10Lookup, setICD10Lookup] = useState([{ value: "", label: "" }]);
  const [diagnosisField, setDiagnosisField] = useState<PatientConditionForm[]>(
    []
  );
  const [selectedLocationId, setSelectedLocationId] = useState<string>("");
  const [disableAddSecondaryButton, setDisableAddSecondaryButton] = useState(
    !isEditMode
  );
  const [birthDate, setBirthDate] = useState<string | Date | null>(null);
  const [showSecondaryFormModal, setShowSecondaryFormModal] = useState(false);
  const [careMember, setCareMember] = useState([]);
  const [practiceUsers, setPracticeUsers]: [User[], Dispatch<any>] = useState(
    []
  );
  const [organizationUsers, setOrganizationUsers]: [User[], Dispatch<any>] =
    useState([]);
  const [selectedPractice, setSelectedPractice]: [
    Practice | any,
    Dispatch<any>
  ] = useState(undefined);

  const [isPatientsPageLoad, setIsPatientsPageLoad] = useState(true);
  const [isServiceLevelOnCreate, setIsServiceLevelOnCreate] = useState(false);
  const validateDiagnosisField = () => {
    let validated = true;
    diagnosisField.forEach((field) => {
      if (field.displayName === "") {
        validated = false;
      }
    });
    setDisableAddSecondaryButton(!validated);
    return validated;
  };

  const closeModal = () => {
    setShowSecondaryFormModal(false);
  };

  const [allowSMS, setAllowSMS] = useState({
    primary: false,
    secondary: false,
  });

  const [duration, setDuration] = useState(0);
  const [newProvider, setNewProvider]: any = useState();
  const handleDate = (formik: any, date: Date, field: string) => {
    if (date) {
      formik.setFieldValue(field, `${format(date, DATE_FORMAT)}`);
    } else {
      formik.setFieldValue(field, null);
    }
  };

  const handleLocationChange = (option: any) => {
    setSelectedLocationId(option);
  };

  const fetchUsers = async () => {
    let selectedOrg = selectedOrganization;
    if (!selectedOrg) {
      const practiceOrg = (
        await PracticeModel.sync(targetPatient.practiceId)
      ).pluck("organizationIds")[0];
      selectedOrg = (await OrganizationModel.sync(practiceOrg)).pluckAll();
    }
    const orgId = selectedOrg.id;
    let allOrganizationUserModel: UserModel[] = [];

    if (selectedOrg.type !== OrganizationType.Root) {
      allOrganizationUserModel = await UserModel.fetchByOrganizationIds([
        orgId!,
      ]);
    }

    let allPracticeUserModel: UserModel[] = [];

    if (selectedPractices.length) {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        selectedPractices.map((practice: Practice) => practice._meta?.id)
      );
    } else {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        (
          await PracticeModel.fetchByOrganizationIds([orgId!])
        ).map((practiceModel) => practiceModel.pluck("_meta").id)
      );
    }
    await setPracticeUsers(allPracticeUserModel.map((user) => user.pluckAll()));
    await setOrganizationUsers(
      allOrganizationUserModel.map((user) => user.pluckAll())
    );
  };

  const populateCareTeam = () => {
    if (!selectedPractice) {
      return;
    } else {
      const pracUsers = practiceUsers.filter(
        (user) => user.parentEntities[0].id === selectedPractice.id
      );
      const users = organizationUsers.concat(pracUsers);
      const careMembers = [...(new Set(users) as any)].map((user: User) => {
        return {
          value: user.id as string,
          label: `${user.firstName} ${user.lastName}`,
          role: "Users",
        };
      });
      setCareMember(careMembers as []);
    }
  };

  const populatePrograms = () => {
    let programs: Program[] = [];
    (async () => {
      programs = await fetchPrograms();
      setPrograms(
        programs.filter(
          (program: Program) =>
            program.organizationId === selectedOrganization?.id
        )
      );
    })();
  };
  /**
   * Fetching all provider by fetched practices Ids
   * @returns
   */
  const fetchProviders = async (): Promise<Provider[]> => {
    const practiceIds = practices.map((practice: Practice) => practice.id);
    const allProviders = (
      await ProviderModel.fetchByPracticeIds(practiceIds as string[])
    ).map((practiceModel) => practiceModel.pluckAll());
    setProviders(allProviders);
    return allProviders;
  };

  const handleProviderChange = async (providerId: string) => {
    await formik.setFieldValue("providerId", providerId);
    const selectedProvider: Provider | undefined = providers.find(
      (provider: Provider) => provider.id === providerId
    );
    setNewProvider(selectedProvider);
    const practice = practices.find(
      (practice: Practice) => practice.id === selectedProvider?.practiceId
    );

    if (practice) {
      await formik.setFieldValue("Practice", (practice as Practice).id);
      setSelectedPractice(practice);
    } else {
      /**
       * If practice does not exist in our local state
       * we can sync the provider by its Id and pull the practice name
       */
      const providerData = (await ProviderModel.sync(providerId)).pluckAll();
      const fetchedPractice = (
        await PracticeModel.sync(providerData.practiceId!)
      ).pluckAll();

      await formik.setFieldValue("Practice", fetchedPractice.id);
      await setSelectedPractice(fetchedPractice);
    }
  };

  let patientEmergencyContact = [
    {
      firstName: "",
      lastName: "",
      relation: "",
      email: "",
      primaryPhone: "",
      allowSms: false,
    },
  ];

  const [enableAddContactButton, setEnableAddContactButton] = useState(false);
  const [showEmergencyContactModal, setShowEmergencyContactModal] =
    useState(false);

  const validateEmergencyContact = (): boolean => {
    let validated = true;
    emergencyContactField.forEach((field) => {
      if (
        field.firstName === "" ||
        field.lastName === "" ||
        field.email === "" ||
        field.relation === ""
      ) {
        validated = false;
      }
    });
    setEnableAddContactButton(validated);
    return validated;
  };

  if (isEditMode && targetPatient.designatedRepresentatives.length !== 0) {
    patientEmergencyContact = targetPatient.designatedRepresentatives.map(
      (contact) => {
        return {
          firstName: contact.name.split(" ")[0],
          lastName: contact.name.split(" ")[1],
          relation: contact.relation || "",
          email: contact.email || "",
          primaryPhone: contact.phone.primary.number,
          allowSms: contact.phone.primary.isSmsAllowed,
        };
      }
    );
  }

  const [emergencyContactField, setEmergencyContactField] = useState(
    patientEmergencyContact
  );

  const handleSubmit = async (
    values: PatientFormType,
    { setSubmitting, resetForm }: FormikHelpers<PatientFormType>
  ) => {
    values.diagnosis = diagnosisField;
    values.emergencyContact = emergencyContactField;
    if (values && values.programId && values.programId === "null") {
      values.programId = null;
    }
    if (isEditMode && targetPatient.encounter?.startDateTime) {
      const start = DateTime.fromISO(targetPatient.encounter?.startDateTime);
      const end =
        targetPatient.encounter?.endDateTime &&
        DateTime.fromISO(targetPatient.encounter?.endDateTime);
      const previousDuration =
        start && end && end.diff(start).as("days").toFixed(0);
      if (
        duration !== 0 &&
        (previousDuration !== duration.toString() ||
          !values.encounter?.endDateTime)
      ) {
        values.encounter = {
          endDateTime: DateTime.fromISO(targetPatient.encounter?.startDateTime)
            .plus({
              day: duration,
            })
            .toUTC()
            .toISO(),
        };
      } else {
        values.encounter = {
          endDateTime: targetPatient.encounter?.endDateTime || null,
        };
      }
    }
    if (!isEditMode && duration !== 0) {
      values.encounter = {
        endDateTime: DateTime.fromJSDate(new Date(), {
          zone: "America/Los_Angeles",
        })
          .startOf("day")
          .plus({
            day: duration,
          })
          .toUTC()
          .toISO(),
      };
    }
    if (targetPatient) {
      targetPatient = {
        ...targetPatient,
        programDuration: values.programDuration,
        otherProgramDuration: values.otherProgramDuration,
      };
    }
    setSubmitting(true);

    if (
      selectedLocationId &&
      targetPatient.id &&
      !targetPatient.tagIds?.includes(selectedLocationId)
    ) {
      await PatientModel.assignTags(targetPatient.id, {
        set: [selectedLocationId],
        delete: targetPatient.tagIds,
      });
    }

    function attachNonSerializedValues(patient: Patient) {
      patient.tagIds = selectedLocationId ? [selectedLocationId] : [];
      patient.anthropometry = {
        height:
          values.height === ""
            ? undefined
            : { value: values.height, unit: values.heightUnit },
        weight:
          values.weight === ""
            ? undefined
            : { value: values.weight, unit: values.weightUnit },
      };
    }

    if (assignServiceLevel && onEditPatient) {
      setIsServiceLevelOnCreate(true);
      const patient: Patient = PatientModel.serialize(
        values,
        resourceAction
      ).pluckAll();
      try {
        patient.id = (await PatientModel.createPatient(patient)).data._self.id;
        if (values.Practice) patient.practiceId = values.Practice;
        patient.encounter = {
          startDateTime: DateTime.fromJSDate(new Date(), {
            zone: "America/Los_Angeles",
          })
            .startOf("day")
            .toUTC()
            .toISO(),
          endDateTime: values.encounter?.endDateTime,
        };
        attachNonSerializedValues(patient);

        dispatch(dispatchCreatePatient(patient));
        incrementPatientCountInProvider(values, resourceAction);
        setTargetPatient(patient.id!);
        updateTargetProviderId(patient.providerId);
        onEditPatient();
        return;
      } catch (error: any) {
        if (error.status || error.response) {
          Notification.notify(
            ERROR,
            RCAResponseErrorParser.parse(error).message()
          );
          return;
        } else {
          throw error;
        }
      }
    }

    if (
      targetPatient &&
      targetPatient.encounter?.endDateTime === values.encounter?.endDateTime
    ) {
      values.encounter = undefined;
    } else {
      values.encounter = {
        startDateTime: undefined,
        endDateTime: values.encounter?.endDateTime,
      };
    }

    await Invoker.make<Patient>(
      Receiver.make(
        PatientModel.serialize(values, resourceAction),
        ResourceType.patient,
        resourceAction
      ),
      CleanTask.make(
        () =>
          resetForm(
            getInitialValues(
              isEditMode,
              targetPatient,
              targetProviderId,
              diagnosisField
            ) as Partial<FormikState<PatientFormType>>
          ),
        async () => {
          const patient = PatientModel.serialize(
            values,
            resourceAction
          ).pluckAll();
          // Attach values that should exist but don't belong in serializers
          attachNonSerializedValues(patient);

          if (isEditMode && targetPatient) {
            patient._meta = targetPatient._meta;
          }

          updatePatient({
            ...targetPatient,
            ...patient,
            provider: newProvider,
            encounter: {
              id: targetPatient?.encounter?.id,
              startDateTime: values?.encounter?.startDateTime,
              endDateTime: values?.encounter?.endDateTime,
            },
          });
          handleClose();
        },
        () => {
          incrementPatientCountInProvider(values, resourceAction);
          setTargetPatient("");
          updateTargetProviderId("");
        }
      ),
      FinallyTask.make(() => {
        setSubmitting(false);
        formik.setErrors({});
      })
    ).invoke();
  };

  const initialValues = getInitialValues(
    isEditMode,
    targetPatient,
    targetProviderId,
    diagnosisField
  ) as PatientFormType;
  const formik = useFormik<PatientFormType>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { values, errors } = formik;

  const [didSetFormik, setDidSetFormik] = useState(false);
  const [elementDisabled, setElementDisabled] = useState(
    values.programDuration !== "0"
  );
  const [programDurationDisabled, setProgramDurationDisabled] = useState(true);
  const [programs, setPrograms] = useState([] as Program[]);
  const handleTextInputChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(field, event.target.value);
    };

  useEffect(() => {
    let patientDateOfBirth = null;
    let patientCondition: Array<PatientConditionForm> = [
      {
        label: "Primary Dx",
        required: false,
        displayName: "",
        icdCode: { label: "", value: "" },
        type: PatientConditionType.PRIMARY,
        resolvedDate: null,
      },
    ];

    if (isEditMode && targetPatient) {
      patientDateOfBirth = targetPatient.demographics.dateOfBirth
        ? (targetPatient.demographics.dateOfBirth as string)
        : null;

      patientCondition = (targetPatient.conditions || []).map((condition) => {
        return {
          id: condition.id,
          label:
            condition.type === PatientConditionType.PRIMARY
              ? "Primary Dx"
              : "Secondary",
          required: false,
          displayName: condition.displayName,
          icdCode: condition.icd10
            ? {
                label: condition.icd10.description,
                value: condition.icd10.code,
              }
            : null,
          type: condition.type,
          resolvedDate: condition.resolved
            ? (condition.resolved as string)
            : null,
          hasChanged: false,
        };
      }) as Array<PatientConditionForm>;

      if (
        patientCondition.length &&
        patientCondition[0].type === PatientConditionType.SECONDARY
      ) {
        setDisableAddSecondaryButton(true);
        patientCondition.unshift({
          label: "Primary Dx",
          required: true,
          displayName: "",
          icdCode: { label: "", value: "" },
          type: PatientConditionType.PRIMARY,
          resolvedDate: null,
        });
      }

      if (
        (patientCondition && patientCondition.length === 0) ||
        !patientCondition
      ) {
        setDisableAddSecondaryButton(true);
        patientCondition = [
          {
            label: "Primary Dx",
            required: true,
            displayName: "",
            icdCode: { label: "", value: "" },
            type: PatientConditionType.PRIMARY,
            resolvedDate: null,
          },
        ];
      }
    }

    setBirthDate(patientDateOfBirth);
    setDiagnosisField(patientCondition);
  }, [targetPatient]);

  useEffect(() => {
    if (!selectedLocationId && targetPatient && targetPatient.tagIds?.length) {
      for (let i = 0; i < targetPatient.tagIds.length; i++) {
        const tagId = targetPatient.tagIds[i];

        if (locationTagIds.includes(tagId)) {
          setSelectedLocationId(tagId);
          return;
        }
      }
    }
  }, [selectedLocationId, locationTagIds]);

  useEffect(() => {
    if (!providers.length) fetchProviders();
    if (!organizationUsers.length) fetchUsers();
    populatePrograms();
  }, []);

  useEffect(() => {
    populateCareTeam();
    populatePrograms();
  }, [selectedPractice, organizationUsers, practiceUsers]);

  useEffect(() => {
    if (
      providers &&
      providers.length !== 0 &&
      practices &&
      practices.length !== 0
    ) {
      if (isEditMode) {
        const providerId =
          targetPatient.provider?.id! || targetPatient.providerId;

        handleProviderChange(providerId);
      } else {
        handleProviderChange(
          targetProviderId || (providers[0].id as ProviderId)
        );
      }
    }
  }, [practices, providers]);

  useEffect(() => {
    if (didSetFormik) return;
    if (onSetFormik) onSetFormik(formik);
    setDidSetFormik(true);
  }, [formik]);

  useEffect(() => {
    if (isEditMode && targetPatient.designatedRepresentatives.length !== 0) {
      setEnableAddContactButton(true);
    }
  }, []);

  useEffect(() => {
    if (
      props.preselectEntityAndProvider &&
      (values.Practice || values.providerId) &&
      isPatientsPageLoad
    ) {
      formik.setFieldValue("Practice", "");
      formik.setFieldValue("providerId", "");
    }
  }, [values.Practice, values.providerId]);

  return (
    <>
      <FormikProvider value={formik}>
        <form
          id="patient-form"
          className={styles.patientForm}
          onSubmit={formik.handleSubmit}
        >
          <div>
            {isEditMode && (
              <ServiceLevelSection
                patient={targetPatient}
                assignFromPatientCreate={isServiceLevelOnCreate}
                handlePatientCreateModal={handleClose}
              />
            )}
            {/* Basic Info Form  */}
            <div className={cx("col-md-12", "mb-3", styles.pl0)}>
              <h6 className={styles.formHeading}>Demographics</h6>
              <hr />
            </div>

            <div className="form-row d-flex justify-content-start mb-3">
              <div className="col-md-2">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  required
                  value={values?.lastName}
                  hasError={!!errors?.lastName}
                  onChange={handleTextInputChange("lastName")}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  required
                  value={values?.firstName}
                  hasError={!!errors?.firstName}
                  onChange={handleTextInputChange("firstName")}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="Middle Name"
                  name="middleName"
                  type="text"
                  placeholder="Middle Name"
                  autoComplete="new-field"
                  value={values?.middleName}
                  hasError={!!errors?.middleName}
                  onChange={handleTextInputChange("middleName")}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="Prefix"
                  name="prefix"
                  type="text"
                  placeholder="Prefix"
                  value={values?.prefix}
                  hasError={!!errors?.prefix}
                  onChange={handleTextInputChange("prefix")}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="Suffix"
                  name="suffix"
                  type="text"
                  placeholder="Suffix"
                  value={values?.suffix}
                  hasError={!!errors?.suffix}
                  onChange={handleTextInputChange("suffix")}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="Preferred Name"
                  name="preferredName"
                  type="text"
                  placeholder="Preferred Name"
                  value={values?.preferredName}
                  hasError={!!errors?.preferredName}
                  onChange={handleTextInputChange("preferredName")}
                />
              </div>
            </div>
            <div className="form-row d-flex justify-content-start mb-3">
              <div className="col-md-2">
                <DatePicker
                  label="Date Of Birth"
                  required
                  showYearDropdown
                  isClearable={false}
                  name="dateOfBirth"
                  maxDate={new Date()}
                  customInput={
                    <MaskedInput
                      mask={option.mask}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                  selected={
                    birthDate
                      ? DateTime.fromISO(birthDate.toString()).toJSDate()
                      : null
                  }
                  onChange={(date: Date) => {
                    const dateStr = date ? format(date, "yyyy-MM-dd") : null;
                    setBirthDate(dateStr);
                    handleDate(formik, date, "dateOfBirth");
                    formik.setFieldValue("dateOfBirth", dateStr);
                  }}
                  hasError={!!errors?.dateOfBirth}
                />
              </div>

              <div className="col-md-2">
                <Select
                  label="Birth Sex"
                  name="birthSex"
                  hasError={!!errors?.birthSex}
                >
                  <option value="UNKNOWN">Unknown</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </Select>
              </div>
              <div className="col-md-2">
                <TextInput
                  label="Identifies As"
                  name="gender"
                  type="text"
                  placeholder="Gender identity"
                  value={values?.gender}
                  onChange={handleTextInputChange("gender")}
                  hasError={!!errors?.gender}
                />
              </div>
              <div className="col-md-2">
                <Select label="Race" name="race" hasError={!!errors?.race}>
                  <option value="UNKNOWN">Unknown</option>
                  <option value="WHITE">White</option>
                  <option value="BLACK">Black</option>
                  <option value="ASIAN">Asian</option>
                  <option value="HISPANIC">Hispanic</option>
                  <option value="NORTH_AMERICAN_NATIVE">
                    North American Native
                  </option>
                  <option value="OTHER">Other</option>
                </Select>
              </div>
              <div className="col-md-3">
                <TextInput
                  name="mrn"
                  type="text"
                  label="Medical Record Number (MRN)"
                  placeholder="MRN"
                  value={values?.mrn || ""}
                  hasError={!!errors?.mrn}
                  onChange={handleTextInputChange("mrn")}
                />
              </div>
            </div>
            <div className="form-row d-flex justify-content-start">
              <div className={cx(styles.sevenCol3, "mb-3")}>
                <TextInput
                  label="Address"
                  name="address"
                  type="text"
                  placeholder="Full address including apartment number"
                  required
                  value={values?.address}
                  onChange={handleTextInputChange("address")}
                  hasError={!!errors?.address}
                />
              </div>
              <div className="col-md-2">
                <TextInput
                  label="City"
                  name="city"
                  type="text"
                  placeholder="City"
                  required
                  value={values?.city}
                  onChange={handleTextInputChange("city")}
                  hasError={!!errors?.city}
                />
              </div>
              <div className="form-group col-md-2">
                <Select
                  label="State"
                  name="state"
                  required
                  hasError={!!errors?.state}
                >
                  <option value="">Select State</option>
                  {usStates.map((state, index) => (
                    <option key={`state-${state}`} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="form-group col-md-2">
                <TextInput
                  label="Zip Code"
                  name="zip"
                  type="text"
                  placeholder="Zip Code"
                  required
                  value={values?.zip}
                  onChange={handleTextInputChange("zip")}
                  hasError={!!errors?.zip}
                />
              </div>
              <div className="form-group col-md-2">
                <Select
                  label="Country"
                  name="country"
                  required
                  hasError={!!errors?.country}
                >
                  <option>Select Country</option>
                  {CountryCodes.filter((country) => country.code === "US").map(
                    (country, index) => (
                      <option
                        key={`country-${country.code}`}
                        value={country.code}
                      >
                        {country.name}
                      </option>
                    )
                  )}
                </Select>
              </div>
              <div className={styles.sevenCol3}>
                <TextInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Patient Email"
                  value={values?.email}
                  onChange={handleTextInputChange("email")}
                  hasError={!!errors?.email}
                />
              </div>
              <div className="col-md-2 mb-3">
                <Select
                  label="Language"
                  name="language"
                  required
                  hasError={!!errors?.language}
                >
                  {Languages.map((language: Language) => (
                    <option
                      key={`lang-${language.abbreviation}`}
                      value={language.abbreviation}
                    >
                      {language.displayName}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-md-2 mb-3">
                <TextInputMask
                  label="Primary Phone"
                  name="primaryPhone"
                  type="tel"
                  mask="+1 (999) 999-9999"
                  required
                  value={values?.primaryPhone}
                  onChange={handleTextInputChange("primaryPhone")}
                  hasError={!!errors?.primaryPhone}
                  supporting={
                    <div
                      className={"form-inline float-right " + styles.supporting}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="allowSMSPrimary"
                        {...(isEditMode
                          ? {
                              defaultChecked:
                                targetPatient.phone.primary.isSmsAllowed,
                            }
                          : {})}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setAllowSMS((state) => ({
                            ...state,
                            primary: e.target.checked,
                          }));
                          formik.values.allowSMS.primary = e.target.checked;
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="allowSMSPrimary"
                      >
                        Allow SMS
                      </label>
                    </div>
                  }
                />
              </div>
              <div className="col-md-2 mb-3">
                <TextInputMask
                  label="Other Phone"
                  name="secondaryPhone"
                  type="tel"
                  mask="+1 (999) 999-9999"
                  value={values?.secondaryPhone}
                  onChange={handleTextInputChange("secondaryPhone")}
                  hasError={!!errors?.secondaryPhone}
                  supporting={
                    <div
                      className={"form-inline float-right " + styles.supporting}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="allowSMSSecondary"
                        {...(isEditMode
                          ? {
                              defaultChecked:
                                targetPatient.phone.secondary?.isSmsAllowed,
                            }
                          : {})}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setAllowSMS((state) => ({
                            ...state,
                            secondary: e.target.checked,
                          }));
                          formik.values.allowSMS.secondary = e.target.checked;
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="allowSMSSecondary"
                      >
                        Allow SMS
                      </label>
                    </div>
                  }
                />
              </div>
            </div>

            {/* Designated Representative Form  */}
            <div className={cx("col-md-12", "mb-3", "mt-4", styles.pl0)}>
              <h6 className={styles.formHeading}>Emergency Contacts</h6>
              <hr />
            </div>
            <div>
              {emergencyContactField.map((contact, index) => (
                <div
                  key={`emergencyContact-${index}`}
                  className="form-row d-flex justify-content-start"
                >
                  <div className="col-md-2 mb-3">
                    <TextInput
                      label="First Name"
                      name="representativesFirstName"
                      type="text"
                      placeholder="First Name"
                      autoComplete="new-field"
                      value={emergencyContactField[index].firstName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const firstName = e.target.value;
                        emergencyContactField[index].firstName = firstName;
                        formik.setFieldValue(
                          "representativesFirstName",
                          firstName
                        );
                        setEmergencyContactField([...emergencyContactField]);
                        validateEmergencyContact();
                      }}
                      hasError={!!errors?.representativesFirstName}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <TextInput
                      label="Last Name"
                      name="representativesLastName"
                      type="text"
                      placeholder="Last Name"
                      autoComplete="new-field"
                      value={emergencyContactField[index].lastName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const lastName = e.target.value;
                        emergencyContactField[index].lastName = lastName;
                        formik.setFieldValue(
                          "representativesLastName",
                          lastName
                        );
                        setEmergencyContactField([...emergencyContactField]);
                        validateEmergencyContact();
                      }}
                      hasError={!!errors?.representativesLastName}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <TextInput
                      label="Relationship"
                      name="representativesRelation"
                      type="text"
                      placeholder="Relationship"
                      value={emergencyContactField[index].relation}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const relation = e.target.value;
                        emergencyContactField[index].relation = relation;
                        formik.setFieldValue(
                          "representativesRelation",
                          relation
                        );
                        setEmergencyContactField([...emergencyContactField]);
                        validateEmergencyContact();
                      }}
                      hasError={!!errors?.representativesRelation}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <TextInput
                      label="Email"
                      name="representativesEmail"
                      type="email"
                      placeholder="Email"
                      value={emergencyContactField[index].email}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const email = e.target.value;
                        emergencyContactField[index].email = email;
                        formik.setFieldValue("representativesEmail", email);
                        setEmergencyContactField([...emergencyContactField]);
                        validateEmergencyContact();
                      }}
                      hasError={!!errors?.representativesEmail}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <TextInputMask
                      value={emergencyContactField[index].primaryPhone}
                      label="Primary Phone"
                      name="representativesPhone"
                      type="tel"
                      mask="+1 (999) 999-9999"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const primaryPhone = e.target.value;
                        emergencyContactField[index].primaryPhone =
                          primaryPhone;
                        formik.setFieldValue(
                          "representativesPhone",
                          primaryPhone
                        );
                        setEmergencyContactField([...emergencyContactField]);
                      }}
                      hasError={!!errors?.representativesPhone}
                      supporting={
                        <div
                          className={
                            "form-inline float-right " + styles.supporting
                          }
                        >
                          <input
                            tabIndex={0}
                            className="form-check-input"
                            type="checkbox"
                            id="allowSMSPrimaryRepresentatative"
                            {...(isEditMode
                              ? {
                                  defaultChecked:
                                    emergencyContactField[index].allowSms,
                                }
                              : {})}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const isChecked: boolean = e.target.checked;
                              emergencyContactField[index].allowSms = isChecked;
                              setEmergencyContactField([
                                ...emergencyContactField,
                              ]);
                              validateEmergencyContact();
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="allowSMSPrimaryRepresentatative"
                          >
                            Allow SMS
                          </label>
                        </div>
                      }
                    />
                  </div>
                  <div className="col-md-2 mt-3">
                    {index === 0 && (
                      <Button
                        type="button"
                        appearance="primary"
                        className="mb-2"
                        disabled={!enableAddContactButton}
                        onClick={() => {
                          if (isEditMode) {
                            setShowEmergencyContactModal(true);
                            return;
                          }
                          if (
                            emergencyContactField.length ===
                              MAX_CONTACT_FIELD_LIMIT ||
                            !validateEmergencyContact()
                          ) {
                            return;
                          }

                          setEmergencyContactField([
                            ...emergencyContactField,
                            {
                              firstName: "",
                              lastName: "",
                              relation: "",
                              email: "",
                              primaryPhone: "",
                              allowSms: false,
                            },
                          ]);

                          setEnableAddContactButton(false);
                        }}
                      >
                        <span>Add Additional Contact</span>
                      </Button>
                    )}
                    {index !== 0 && (
                      <button
                        type="button"
                        style={{ position: "absolute" }}
                        className={globalStyles.buttonReset}
                        onClick={() => {
                          const contactFields = emergencyContactField.filter(
                            (contact, i) => i !== index
                          );
                          setEmergencyContactField(contactFields);
                        }}
                      >
                        <i className="fa pointer fa-times-circle text-danger fa-2x" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Care Team Form */}
            <div className={cx("col-md-12", "mb-3", "mt-4", styles.pl0)}>
              <h6 className={styles.formHeading}>Care Team</h6>
              <hr />
            </div>
            <div id="careteam">
              <div className="form-row d-flex justify-content-start">
                <div className="col-md-2 mb-3">
                  <PracticeSelect
                    name="Practice"
                    label="Entity/Branch"
                    required
                    selectedId={values?.Practice}
                    hasError={!!errors?.Practice}
                    disabled={isEditMode}
                    onChange={(option: SelectOption | unknown) => {
                      setIsPatientsPageLoad(false);
                      formik.setFieldValue(
                        "Practice",
                        (option as SelectOption)?.value
                      );
                      // Reset provider on practice select
                      formik.setFieldValue("providerId", null);
                    }}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <ProviderSelect
                    name="providerId"
                    label="Provider"
                    required
                    selectedId={values?.providerId}
                    selectedPractice={values?.Practice}
                    hasError={!!errors?.providerId}
                    onChange={(option: SelectOption | unknown) => {
                      setIsPatientsPageLoad(false);
                      handleProviderChange((option as SelectOption)?.value);
                    }}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label>Location</label>
                  <LocationSelect
                    data={[]}
                    disabled={!isEditMode}
                    isSearchable={false}
                    name="location"
                    selectedId={selectedLocationId}
                    onChange={handleLocationChange}
                    hasError={!!errors?.location}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <Select
                    label="Risk Level"
                    name="priority"
                    hasError={!!errors?.priority}
                  >
                    <option value="NONE">None</option>
                    <option value="HIGH">High</option>
                    <option value="MEDIUM">Medium</option>
                    <option value="LOW">Low</option>
                  </Select>
                </div>
                <div className="col-md-2 mb-3">
                  <Select
                    label="Care Team Member"
                    name="careTeam"
                    hasError={!!errors?.careTeam}
                  >
                    <option value="">None</option>
                    {careMember &&
                      careMember.length !== 0 &&
                      careMember.map(
                        (user: { label: string; value: string }) => (
                          <option
                            key={`careMember-${user.value}`}
                            value={user.value}
                          >
                            {user.label}
                          </option>
                        )
                      )}
                  </Select>
                </div>
              </div>
            </div>

            {/* Diagnosis Form */}
            <div className={cx("col-md-12", "mb-3", "mt-4", styles.pl0)}>
              <h6
                data-target="#diagnosisColapse"
                className={styles.formHeading}
              >
                Medical Condition
              </h6>
              <hr />
            </div>
            <div className="row">
              <div className="form-group col-md-2">
                <Select
                  name="programId"
                  disabled={isServiceLevelOnCreate}
                  label="Program"
                  onChange={(e) => {
                    if (e.currentTarget.value !== null) {
                      setProgramDurationDisabled(false);
                    }
                    formik.setFieldValue(
                      "programId",
                      e.currentTarget.value === "null"
                        ? null
                        : e.currentTarget.value
                    );
                  }}
                >
                  <option value={"null"}></option>
                  {programs.map((program, key) => (
                    <option key={key} value={program._meta?.id}>
                      {program.shortName}
                    </option>
                  ))}
                </Select>
              </div>
              <div
                className="form-group col-md-2"
                style={{ paddingRight: "2px" }}
              >
                <Select
                  disabled={
                    (!isEditMode && programDurationDisabled) ||
                    isServiceLevelOnCreate
                  }
                  name="programDuration"
                  label="Program Duration"
                  onChange={(e) => {
                    const selectedValue = e.currentTarget.value;
                    if (selectedValue === "0") {
                      setElementDisabled(false);
                    } else {
                      setElementDisabled(true);
                      if (selectedValue !== "")
                        setDuration(parseInt(selectedValue));
                      formik.setFieldValue("otherProgramDuration", "");
                    }
                    formik.setFieldValue("programDuration", selectedValue);
                  }}
                >
                  <option value=""></option>
                  <option value="30">30 days</option>
                  <option value="45">45 days</option>
                  <option value="60">60 days</option>
                  <option value="90">90 days</option>
                  <option value="0">Other</option>
                </Select>
              </div>
              <div
                className="form-group col-md-1"
                style={{ marginTop: "22px", paddingRight: "1px" }}
              >
                <TextInput
                  name="otherProgramDuration"
                  type="number"
                  min="2"
                  max="365"
                  disabled={elementDisabled}
                  value={values.otherProgramDuration || ""}
                  onChange={(e) => {
                    const selectedValue = e.currentTarget.value;
                    formik.setFieldValue(
                      "otherProgramDuration",
                      e.currentTarget.value
                    );
                    if (selectedValue !== "")
                      setDuration(parseInt(e.currentTarget.value));
                  }}
                />
              </div>
              <span
                style={{
                  paddingTop: "32px",
                  paddingRight: "20px",
                }}
              >
                Days
              </span>

              <div className="form-group col-md-2">
                <Select
                  disabled={!!(targetPatient && targetPatient.programId)}
                  label="Billing Type(s)"
                  name="billingType"
                  hasError={!!errors?.billingType}
                >
                  <option value="">None selected</option>
                  {BILLING_TYPES.map((billingType, index) => (
                    <option
                      key={`billing-${billingType.value}`}
                      value={billingType.value}
                    >
                      {billingType.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              {diagnosisField.map(
                (field: PatientConditionForm, index: number) => (
                  <div
                    key={`diagnosis-${index}`}
                    className="form-row d-flex justify-content-start"
                  >
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label={field.label}
                        name={field.type}
                        type="text"
                        placeholder="Diagnosis"
                        value={field.displayName}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          diagnosisField[index].displayName =
                            event.currentTarget.value;
                          diagnosisField[index].hasChanged = true;
                          formik.setFieldValue(
                            field.type!,
                            event.currentTarget.value
                          );
                          setDiagnosisField(diagnosisField);
                          formik.values.diagnosis = diagnosisField as [];
                          validateDiagnosisField();
                        }}
                        hasError={!!(errors as any)?.[field.type || ""]}
                      />
                    </div>
                    <div className="col-md-7 mb-3">
                      <label>ICD-10 Code</label>
                      <SelectMultiple
                        defaultValue={
                          field.icdCode?.value === "" || !field.icdCode
                            ? null
                            : field.icdCode
                        }
                        isMulti={false}
                        onChange={(selectedDiagnosis) => {
                          diagnosisField[index].icdCode = selectedDiagnosis as {
                            label: string;
                            value: string;
                          };
                          diagnosisField[index].hasChanged = true;
                          setDiagnosisField(diagnosisField);
                          formik.values.diagnosis = diagnosisField as [];
                        }}
                        options={ICD10Lookup}
                        className="basic-multi-select"
                        placeholder="Type Diagnosis and choose from a list of ICD-10 codes"
                        classNamePrefix="select"
                        onInputChange={(userInput: string) => {
                          axios
                            .post(
                              `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${userInput}`
                            )
                            .then((response: AxiosResponse) => {
                              try {
                                const data = response.data[3];
                                setICD10Lookup(
                                  data.map((result: string[]) => {
                                    return {
                                      value: result[0],
                                      label: `${result[0]} - ${result[1]}`,
                                    };
                                  })
                                );
                              } catch (e) {}
                            });
                        }}
                      />
                    </div>
                    <div className="col-md-2 d-flex align-items-center mt-2 text-left ">
                      {index !== 0 && (
                        <i
                          className="fa pointer fa-times-circle text-danger fa-2x"
                          style={{
                            position: "absolute",
                          }}
                          onClick={() => {
                            const diagnosisFields = diagnosisField.filter(
                              (contact, i) => i !== index
                            );
                            setDiagnosisField([...diagnosisFields]);
                          }}
                        />
                      )}
                      {index === 0 && (
                        <Button
                          appearance="primary"
                          type="button"
                          className="add-user"
                          disabled={disableAddSecondaryButton}
                          onClick={() => {
                            if (validateDiagnosisField()) {
                              if (isEditMode) {
                                setShowSecondaryFormModal(true);
                              } else {
                                diagnosisField.push({
                                  label: "Secondary",
                                  required: false,
                                  displayName: "",
                                  icdCode: { label: "", value: "" },
                                  type: PatientConditionType.SECONDARY,
                                  resolvedDate: null,
                                  hasChanged: true,
                                });
                                setDiagnosisField([...diagnosisField]);
                                setDisableAddSecondaryButton(true);
                              }
                            }
                          }}
                        >
                          <span>Add Secondary</span>
                        </Button>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          {/* Patient form component is not opening inside of modal in patient detail
            component thus we need to show save changes button at the bottom of form */}
          {isPatientDetail && (
            <div className="d-flex justify-content-end mt-5">
              <Button
                appearance="ghost"
                onClick={async () => {
                  handleClose();
                  formik.resetForm({ ...INITIAL_VALUES, status: "" });
                }}
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                appearance="primary"
                disabled={formik.isSubmitting}
                style={{ marginLeft: 10 }}
              >
                {button}
              </Button>
            </div>
          )}
        </form>
      </FormikProvider>

      <Modal
        heading="Add an emergency contact"
        button="Add"
        handleClose={() => {
          setShowEmergencyContactModal(false);
        }}
        show={showEmergencyContactModal}
      >
        {showEmergencyContactModal && (
          <EmergencyContactForm
            closeModal={() => setShowEmergencyContactModal(false)}
            afterContactAdd={(contact: any) => {
              emergencyContactField.push(contact);
              setEmergencyContactField([...emergencyContactField]);
              setShowEmergencyContactModal(false);
            }}
            targetedPatient={targetPatient}
          />
        )}
      </Modal>
      <Modal
        heading="New Condition"
        button="save"
        handleClose={closeModal}
        show={showSecondaryFormModal}
      >
        {showSecondaryFormModal && (
          <SecondaryDiagnosisForm
            targetedPatient={targetPatient}
            handleClose={closeModal}
            handleSave={(newCondition: string) => {
              diagnosisField.push({
                label: "Secondary",
                required: false,
                displayName: newCondition,
                icdCode: { label: "", value: "" },
                type: PatientConditionType.SECONDARY,
                resolvedDate: null,
                hasChanged: true,
              });
              closeModal();
            }}
          />
        )}
      </Modal>
    </>
  );
};
