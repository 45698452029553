import { EventEmitter } from "@/library/core/event";
import { RemoteCareAPISync } from "@/library/core/api";
import { Attributes, Model, ModelCollection } from "@/library/model";
import { Http } from "@/library/core/api/http";
import { deserialize } from "@/library/model";
import { Rule } from "./types";

export class RuleModel extends Model<Rule> {
  static readonly path = "rules";

  static make(attributes: Rule): RuleModel {
    return new RuleModel(
      new Attributes<Rule>(attributes),
      new RemoteCareAPISync<Rule>(RuleModel.path),
      new EventEmitter()
    );
  }

  static makeRuleCollection(): ModelCollection<RuleModel, Rule> {
    return new ModelCollection<RuleModel, Rule>(
      `${RemoteCareAPISync.host}/${RuleModel.path}`,
      (entity: Rule) => deserialize(entity, RuleModel.make)
    );
  }

  static async fetchByParams(
    params: {
      [key: string]: string | string[] | boolean;
    } = {}
  ): Promise<RuleModel[]> {
    const iterator = await RuleModel.makeRuleCollection()
      .withParams(params)
      .getMany();

    return iterator.getAll();
  }

  static updateRule = async (rule: Rule) => {
    await Http().post(`${RemoteCareAPISync.host}/${RuleModel.path}`, { rule });
  };
}
