import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "@/components/table";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { SortedState } from "@/components/table/head/sortable";
import { OrganizationsFilter } from "@/library/types";
import { OrganizationTableHeader } from "@/domain/organization/view/table/constants";
import { Organization } from "@/domain/organization/model/types";
import {
  setTargetOrganization as dispatchSetTargetOrganization,
  dispatchSelectOrganization,
  setOrganizationsSearchFilters as dispatchSetOrganizationsSearchFilters,
} from "@/domain/organization/redux/actions";
import { ResourceType } from "@/library/core/config/resource";
import { RootState } from "@/types";
import { organizationType, kitFulfillment } from "./constants";
import { Props } from "./types";

export const OrganizationTable: FunctionComponent<Props> = (props) => {
  const {
    data,
    execSearch,
    reset,
    onPageLimitChange,
    onEditOrganization,
    onManageOverrides,
    onManagePrograms,
    onAddPractice,
  } = props;

  const [headContents, setHeaderContents]: any = useState();
  const [headers, setHeaders] = useState(OrganizationTableHeader);

  const paginationConfig = useSelector<RootState, any>(
    (state) => state.organization.paginationConfig
  );
  const pageLimit = useSelector<RootState, any>(
    (state) => state.organization.organizationPageLimit
  );
  const organizationsFilter = useSelector<RootState, any>(
    (state) => state.organization.filters
  );
  const sortedState = useSelector<RootState, any>(
    (state) => state.organization.sortedState
  );

  const dispatch = useDispatch();
  const selectOrganization = (organization?: Organization) =>
    dispatch(dispatchSelectOrganization(organization));
  const setTargetOrganization = (organization?: Organization) =>
    dispatch(dispatchSetTargetOrganization(organization));
  const setOrganizationsSearchFilters = (
    organizationsFilter: OrganizationsFilter
  ) => dispatch(dispatchSetOrganizationsSearchFilters(organizationsFilter));

  /**
   * Reset filter change the sorted state of all columns to `no sort`.
   */
  useEffect(() => {
    setHeaders(
      OrganizationTableHeader.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      })
    );
    setHeaderContents(
      <SortableHead
        headers={headers}
        execSearch={execSearch}
        resourceType={ResourceType.organization}
      />
    );
  }, [reset]);

  const addPractice = (organization: Organization) => {
    setTargetOrganization(organization);
    selectOrganization(organization);
    onAddPractice();
  };

  const editOrganization = (organization: Organization) => {
    setTargetOrganization(organization);
    selectOrganization(organization);
    onEditOrganization();
  };

  const manageOverrides = (organization: Organization) => {
    setTargetOrganization(organization);
    selectOrganization(organization);
    onManageOverrides(organization);
  };

  const managePrograms = (organization: Organization) => {
    setTargetOrganization(organization);
    selectOrganization(organization);
    onManagePrograms(organization);
  };

  const subHeader = [
    <tr key={"header"}>
      <td
        className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""}
      ></td>
      <td className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}>
        <div>
          <BasicMultiSelect
            data={organizationType}
            onChange={async (value: string) => {
              setOrganizationsSearchFilters({
                ...organizationsFilter,
                type: value,
              });
              await execSearch({});
            }}
            findValueBy={organizationsFilter.type}
            isSearchable={false}
            placeholder="All"
            onSelect={async (value: string) => {
              setOrganizationsSearchFilters({
                ...organizationsFilter,
                type: value,
              });
              await execSearch({});
            }}
          />
        </div>
      </td>
      <td
        className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
      ></td>
      <td
        className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""}
      ></td>
      <td
        className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""}
      ></td>
      <td
        className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""}
      ></td>
      <td className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""}>
        <div>
          <BasicMultiSelect
            data={kitFulfillment}
            onChange={async (value: string) => {
              await setOrganizationsSearchFilters({
                ...organizationsFilter,
                kitFulfillment: value,
              });
              execSearch({});
            }}
            findValueBy={organizationsFilter.kitFulfillment}
            isSearchable={false}
            placeholder="All"
            onSelect={async (value: string) => {
              setOrganizationsSearchFilters({
                ...organizationsFilter,
                kitFulfillment: value,
              });
              await execSearch({});
            }}
          />
        </div>
      </td>
      <td
        className={sortedState.sortedColumnIndex === 7 ? "sorted_col" : ""}
      ></td>
    </tr>,
  ];

  const dataContents = (data || [])
    .map((org) => org as Organization)
    .map((org, key) => {
      return (
        <tr key={key} className="td-text">
          <td
            className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""}
          >
            <Link key={key} to={`/organizations/${org.id}`}>
              {org.name}
            </Link>
          </td>
          <td
            className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}
          >
            {org.type}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
          >
            {org.address.street}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""}
          >
            {org.address.locality}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""}
          >
            {org.address.region}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""}
          >
            {org.contact?.phone ?? "-"}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""}
          >
            {org.kitFulfillment ?? "No Kit"}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 8 ? "sorted_col" : ""}
          >
            <div className="dropleft btn-group">
              <button
                className=""
                style={{ backgroundColor: "transparent", border: "none" }}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-boundary="window"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="feather icon-more-horizontal"></i>
              </button>
              <div
                className="dropdown-menu hide-scroll"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => editOrganization(org as Organization)}
                >
                  Edit/View
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => addPractice(org as Organization)}
                >
                  Create Entity/Branch
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => manageOverrides(org as Organization)}
                >
                  Manage Parameter Overrides
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => managePrograms(org as Organization)}
                >
                  Manage Programs
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    });

  const tableData = subHeader.concat(dataContents);

  return (
    <Table
      paginationConfig={paginationConfig}
      pageLimit={pageLimit}
      onPageLimitChange={onPageLimitChange}
      header={headContents}
      data={tableData}
      onNavigate={execSearch}
    />
  );
};
