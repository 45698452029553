import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import cx from "clsx";

import { DATE_FORMAT } from "@/pipes/date/constants";
import { Label } from "@/components/Label";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  format?: string;
  hasError?: boolean;
  className?: string;
} & ReactDatePickerProps;

export const DatePicker: React.FC<Props> = (props: Props) => {
  const {
    label,
    required,
    onChange,
    selected,
    name,
    disabled,
    hasError,
    className,
    ...rest
  } = props;

  return (
    <>
      {label ? (
        <Label
          htmlFor={String(name)}
          className={cx(globalStyles.truncate, hasError && globalStyles.danger)}
        >
          {label}
          {required ? (
            <span className={cx(globalStyles.danger, globalStyles.bold)}>
              *
            </span>
          ) : null}
        </Label>
      ) : null}
      <ReactDatePicker
        {...rest}
        className={cx("form-control", hasError && styles.hasError, className)}
        placeholderText={DATE_FORMAT.toUpperCase()}
        name={name}
        dateFormat={DATE_FORMAT}
        selected={selected}
        autoComplete="off"
        onChange={onChange}
        required={false}
        disabled={disabled ? disabled : false}
        isClearable={props.isClearable}
        maxDate={props.maxDate ? new Date(props.maxDate) : null}
        minDate={props.minDate ? new Date(props.minDate) : null}
      />
    </>
  );
};
