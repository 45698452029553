import { VirtualVisitMainMedia } from "@/components/virtualVisit/mainMedia";
import * as React from "react";

export interface RightPanelProps {
  room?: any;
  leave: () => void;
  getElapsedTime?: (seconds: number) => void;
  getElapsedTimeOnReset?: (seconds: number) => void;
  onReset: number;
  isMicMutted: boolean;
  toggleMic: () => void;
}
export const RightPanel: React.FC<RightPanelProps> = ({
  room,
  leave,
  isMicMutted,
  toggleMic,
  getElapsedTime,
  getElapsedTimeOnReset,
  onReset,
}) => {
  return (
    <VirtualVisitMainMedia
      getElapsedTimeOnReset={getElapsedTimeOnReset}
      isMicMutted={isMicMutted}
      toggleMic={toggleMic}
      room={room}
      leave={leave}
      onReset={onReset}
      getElapsedTime={getElapsedTime}
    />
  );
};
