import { Tabs, Tab } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { FC } from "react";
import { SyntheticEvent } from "react";
import { EditNote as EditNoteIcon } from "@mui/icons-material";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface VirtualVisitTabProps {
  value: number;
  handleChange: (event: SyntheticEvent, newValue: number) => void;
}
export const VirtualVisitTabs: FC<VirtualVisitTabProps> = ({
  value,
  handleChange,
}) => {
  return (
    <Tabs value={value} onChange={handleChange}>
      <Tab
        sx={(theme) => ({ fontSize: theme.spacing(1) })}
        label="Note"
        {...a11yProps(0)}
        icon={<EditNoteIcon />}
        iconPosition="start"
      />
      <Tab
        sx={(theme) => ({ fontSize: theme.spacing(1) })}
        icon={<PeopleIcon />}
        iconPosition="start"
        label="Participants"
        {...a11yProps(1)}
      />
    </Tabs>
  );
};
