import { CellContext } from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import cx from "clsx";

import { ObservationTypes } from "@/domain/patient/model/constants";
import { getSeverityAlertLevel } from "@/domain/notes/model/constants";
import { AlertLevel } from "@/domain/notes/model/types";
import { dateFormatter } from "@/pipes/date";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import React from "react";

export type Props = {
  info: CellContext<any, any>;
  date: string;
  hoveredCellId?: string;
  onHover: () => void;
  onLeave: () => void;
};

export const ObservationCell: React.FC<Props> = (props) => {
  const { info, date, hoveredCellId, onHover, onLeave } = props;
  const { row, cell } = info;
  const { original } = row;
  const { type } = original;

  const valueObj = original[date] || {};
  const { severityScore } = valueObj;
  const alertLevel = getSeverityAlertLevel(severityScore);
  let { value } = valueObj;
  const { biometricEntryIcon } = valueObj;
  const timeOfObservation = dateFormatter({
    date: valueObj.timeOfObservation,
    includeTime: true,
    toLocalTimezone: {
      enabled: true,
    },
  });

  const renderWarning = (value: string) => (
    <span className={cx(globalStyles.bold, globalStyles.warning)}>{value}</span>
  );
  const renderDanger = (value: string) => (
    <span className={cx(globalStyles.bold, globalStyles.danger)}>{value}</span>
  );
  const renderSuccess = (value: string) => (
    <span className={cx(globalStyles.bold)}>{value}</span>
  );

  if (!value) return <span className={styles.cell}>{`-`}</span>;

  switch (type) {
    case ObservationTypes.BloodPressure:
      const split = value?.split("/");
      let first = split[0];
      let second = split[1];
      if (alertLevel === AlertLevel.RED_ALERT) {
        first = renderDanger(first);
        second = renderDanger(second);
      } else if (alertLevel === AlertLevel.YELLOW_ALERT) {
        first = renderWarning(first);
        second = renderWarning(second);
      } else if (alertLevel === AlertLevel.GREEN_ALERT) {
        first = renderSuccess(first);
        second = renderSuccess(second);
      }

      value = (
        <>
          {first}/{second}
        </>
      );
      break;
    default:
      if (alertLevel === AlertLevel.RED_ALERT) value = renderDanger(value);
      else if (alertLevel === AlertLevel.YELLOW_ALERT)
        value = renderWarning(value);
      else if (alertLevel === AlertLevel.GREEN_ALERT)
        value = renderSuccess(value);
      break;
  }

  const focused = hoveredCellId === cell.id;

  return (
    <>
      <button
        onFocus={onHover}
        onBlur={onLeave}
        onMouseOver={onHover}
        onMouseOut={onLeave}
        onMouseLeave={onLeave}
        className={globalStyles.buttonReset}
      >
        <span data-tooltip-id={cell.id} className={styles.cell}>
          {value}
        </span>
      </button>

      {focused ? (
        <Tooltip isOpen id={`#${cell.id}`} className={styles.tooltip}>
          <div className={cx(styles.tooltipText, styles.value)}>{value}</div>
          <div className={styles.tooltipText}>{timeOfObservation}</div>
          <div
            dangerouslySetInnerHTML={{ __html: biometricEntryIcon || "" }}
            className={styles.tooltipText}
          />
        </Tooltip>
      ) : null}
    </>
  );
};
