import { Address } from "@/library/types/address";
import {
  AccessorySize,
  AccessoryType,
} from "@/domain/kits/models/device.interface";

export type Order = {
  id?: string;
  _meta?: { id: string };
  type: string;
  fulfillment: string;
  patientId: string;
  destination?: {
    name: string;
    address: Address | string;
    requestedDate?: string;
    requestedTimeWindow?: "AM" | "PM" | string;
  };
  serviceLevel?: "RemoteCare" | "RemoteCareLive";
  accessories?: {
    type: AccessoryType;
    size: AccessorySize;
  }[];
  specialInstructions?: string;

  origin?: {
    name: string;
    address: Address | string;
    needsShippingMaterials: boolean;
    requestedDate?: string;
    requestedTimeWindow?: "AM" | "PM" | string;
  };
  deviceId?: string;

  method?: LogisticMethod;
  fulfillmentEmailAddress?: string;
  additionalFulfillmentEmailAddresses?: string[];
};

export enum LogisticMethod {
  Courier = "COURIER",
  Manual = "MANUAL",
}
