import { Automation, Trigger, Action } from "@/domain/automation/model/types";
import dayjs from "dayjs";
import * as Yup from "yup";

export interface AutomationPayload {
  set: Record<string, Automation>; // Object with IDs as keys and Automation objects as values
  delete: string[]; // Array of IDs to delete
  push: Automation[]; // Array of Automation objects to add
}

export enum Frequency {
  Once = "Once",
  Daily = "Daily",
  Weekly = "Weekly",
}

export enum ReminderType {
  Biometrics = "Biometrics",
  Appointment = "Appointment",
  Medication = "Medication",
  VirtualVisit = "VirtualVisit",
  Other = "Other",
}

export const VALIDATION_SCHEMA = Yup.object({
  type: Yup.string().required("Type is required"),
  frequency: Yup.string().required("Frequency is required"),
  description: Yup.string().when("type", {
    is: "Other",
    then: (schema) => schema.required("Description is required."),
  }),
  date: Yup.date()
    .nullable()
    .min(new Date(), "Please enter a valid date and time.")
    .when("frequency", {
      is: Frequency.Once,
      then: (schema) => schema.required("Date is required."),
    }),
  dailyTime: Yup.string()
    .nullable()
    .when("frequency", {
      is: Frequency.Daily,
      then: (schema) => schema.required("Time is required."),
    }),
  weekDayTime: Yup.string()
    .nullable()
    .when("frequency", {
      is: Frequency.Weekly,
      then: (schema) => schema.required("Time is required."),
    }),
  weekDayOption: Yup.string().when("frequency", {
    is: Frequency.Weekly,
    then: (schema) => schema.required("Day is required."),
  }),
});
