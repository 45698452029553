import React, { useEffect, useState } from "react";
import { Button } from "rsuite";

import { Patient } from "@/domain/patient/model/types";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import {
  AccessoryType,
  DeviceState,
  PatientDevice,
  ServiceLevel,
} from "@/domain/kits/models/device.interface";
import { DeviceModel } from "@/domain/kits/models/device.model";
import { KitFulfillmentModal } from "@/domain/kits/view/KitFulfillmentModal";
import { CheckboxOption, SelectOption } from "@/types";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { FormikHelpers } from "formik";
import { Select } from "@/components/_selects/Select";
import { LogisticMethod, Order } from "@/domain/order/model/types";
import { ReturnMethods } from "@/domain/kits/view/KitShipmentForm/constants";
import { DateTime } from "luxon";
import { OrderModel } from "@/domain/order/model";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { PatientState } from "@/library/types/patientState";

type Props = {
  patient: Patient;
  assignFromPatientCreate?: boolean;
  handlePatientCreateModal?: () => void;
};

const SERVICE_LEVEL_OPTIONS = [
  { label: "RemoteCare", value: "RemoteCare" },
  { label: "RemoteCareLive", value: "RemoteCareLive" },
];

export const ServiceLevelSection: React.FC<Props> = (props) => {
  const { patient, assignFromPatientCreate, handlePatientCreateModal } = props;
  const { practiceId } = patient;

  const [showModal, setShowModal] = useState(false);
  const [device, setDevice] = useState<PatientDevice>();
  const [deviceOptions, setDeviceOptions] = useState<
    (PatientDevice & CheckboxOption)[]
  >([]);
  const [isDeviceAssigned, setIsDeviceAssigned] = useState(
    patient.lifecycle?.state === PatientState.Completed
  );
  const [serviceLevel, setServiceLevel] = useState<ServiceLevel>();
  const [modalStep, setModalStep] = useState(0);
  const [peripheralOptions, setPeripheralOptions] = useState<CheckboxOption[]>(
    Object.keys(AccessoryType).map((accType) => {
      return {
        label: accType,
        checked: false,
      };
    })
  );

  const modalDescription = modalStep === 0 ? serviceLevel : "";

  const selectedServiceLevel = serviceLevel
    ? SERVICE_LEVEL_OPTIONS.find((s) => s.value === serviceLevel)
    : null;

  const handleCloseModal = () => {
    setShowModal(false);
    if (assignFromPatientCreate && handlePatientCreateModal)
      handlePatientCreateModal();
  };

  const handleSelectDevices = async () => {
    LoadingIndicator.fire.show();
    await fetchDevicesByPracticeId();
    setModalStep(0);
    setShowModal(true);
    LoadingIndicator.fire.hide();
  };

  const handleDeviceCheckboxes = (checked: boolean, deviceId?: string) => {
    setDeviceOptions((prev) => {
      return prev.map((deviceOpt) => {
        if (deviceOpt.id === deviceId) deviceOpt.checked = checked;
        else deviceOpt.checked = false;
        return deviceOpt;
      });
    });
  };

  const handleSetDeviceOptions = (
    devices: (PatientDevice & CheckboxOption)[]
  ) => {
    setDeviceOptions(
      devices.map((device) => {
        return {
          ...device,
          checked: false,
        } as any;
      })
    );
  };

  const handleSelectServiceLevel = (serviceLevel: ServiceLevel) => {
    if (isDeviceAssigned) return;
    setServiceLevel(serviceLevel);
  };

  const fetchDevicesByPracticeId = async () => {
    const deviceModels = await DeviceModel.fetchByPracticeIds([practiceId]);
    const deviceList = (deviceModels || []).map((dm) => dm.pluckAll());

    const selectedDevices = deviceList.filter((device) => {
      if (
        device.serviceLevel === serviceLevel &&
        device.lifecycle?.state === DeviceState.Active &&
        !device.patientId
      )
        return device;
    });
    setDeviceOptions(
      selectedDevices.map((device) => {
        return {
          ...device,
          checked: false,
        } as any;
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const deviceModels = await DeviceModel.fetchByPatientId(patient.id!);
      const devices = (deviceModels || []).map((dm) => dm.pluckAll());

      if (devices.length > 0) {
        setIsDeviceAssigned(true);
        setServiceLevel(devices[0].serviceLevel);
        const peripheralOpts = peripheralOptions.map((opt) => {
          const isExist = devices[0].accessories?.find(
            (acc) => acc.accessoryType === opt.label
          );
          if (isExist) {
            return {
              ...opt,
              checked: true,
            };
          }
          return opt;
        });
        setPeripheralOptions(peripheralOpts);
      }

      setDevice(devices.length > 0 ? devices[0] : undefined);
    };

    fetchData();
  }, []);

  useEffect(() => {}, [serviceLevel, peripheralOptions]);

  const handleNextClick = () => {
    setModalStep(1);
  };

  const handleBackClick = () => {
    setModalStep(0);
  };

  const handleModalSubmit = async (
    values: KitShipmentFormValues,
    form: FormikHelpers<KitShipmentFormValues>
  ) => {
    form.setSubmitting(true);
    setTimeout(() => form.setSubmitting(false), 500);
    const additionalFulfillmentEmailAddresses = values.contactEmail
      .split(",")
      .slice(1)
      .map((email) => email.trim());
    const kitFulfillmentOrderOwned: Order = {
      type: "DISTRIBUTION",
      fulfillment: "CUSTOMER",
      patientId: patient.id!,
      method:
        values.returnMethod === ReturnMethods.pickup
          ? LogisticMethod.Manual
          : LogisticMethod.Courier,
      destination: {
        name:
          values.returnMethod === ReturnMethods.ship
            ? values.shipToName
            : values.pickupName,
        address:
          values.returnMethod === ReturnMethods.ship
            ? values.shipToAddress
            : values.pickupAddress,
        requestedDate:
          values.returnMethod === ReturnMethods.ship
            ? DateTime.fromISO(values.deliveryDate).toISODate()
            : DateTime.fromISO(values.pickupDate).toISODate(),
        requestedTimeWindow: values.pickupTime,
      },
      deviceId: values.device?.id!,
      specialInstructions: values.specialInstructions,
      fulfillmentEmailAddress: values.contactEmail.split(",")[0],
      additionalFulfillmentEmailAddresses,
    };

    try {
      // initiate owned kit (customer order) shipment
      await OrderModel.createOrder(kitFulfillmentOrderOwned);
      Notification.notify(SUCCESS, "Order created successfully.");
      handleCloseModal();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };
  const handleServiceLevelChange = (option: SelectOption | unknown) => {
    handleSelectServiceLevel((option as SelectOption).value as ServiceLevel);
  };

  return (
    <>
      <div className="col-md-12 mb-3 pl-0 mt-2">
        <h6 className="form-heading">Service Level</h6>
        <hr />
      </div>
      <div className="row mb-5">
        <div className="col-md-3">
          <Select
            options={SERVICE_LEVEL_OPTIONS}
            disabled={isDeviceAssigned}
            onChange={handleServiceLevelChange}
            value={selectedServiceLevel}
            label={`${isDeviceAssigned ? "" : "Choose"} Service Level`}
            name=""
          />
          {!isDeviceAssigned && (
            <Button
              appearance="primary"
              className="mt-3 w-50"
              onClick={handleSelectDevices}
              disabled={!serviceLevel}
            >
              Select
            </Button>
          )}
        </div>
      </div>

      <KitFulfillmentModal
        open={showModal}
        onClose={handleCloseModal}
        onNext={handleNextClick}
        onPrev={handleBackClick}
        onSubmit={handleModalSubmit}
        step={modalStep}
        patient={patient}
        description={modalDescription}
        serviceLevel={serviceLevel}
        deviceOptions={deviceOptions}
        setDeviceOptions={handleSetDeviceOptions}
        onDeviceCheck={handleDeviceCheckboxes}
      />
    </>
  );
};
