import { OrganizationModel } from "@/domain/organization/model";
import { OrganizationForm } from "@/domain/organization/model/types";
import { Serializer } from "./../serializer";
import { CreateOrganizationSerializer, ModifyBasicInfoSerializer } from "./";
import { RCAResourceActions } from "../../core/config/actions";

/**
 * Organization domain serializer. It holds information about all serializers in this domain.
 */
export class OrganizationSerializer extends Serializer<
  OrganizationForm,
  OrganizationModel
> {
  static for(
    action: RCAResourceActions,
    from: OrganizationForm
  ): OrganizationSerializer {
    return new OrganizationSerializer(action, from);
  }

  serialize(): OrganizationModel {
    const serializer = new (this.get(this.action))(this.formData);
    return serializer.serialize();
  }

  /**
   * Map of currently supported resource actions to their respective serializers.
   * @protected
   */
  protected serializers = {
    [RCAResourceActions.Create]: CreateOrganizationSerializer,
    [RCAResourceActions.ModifyBasicInfo]: ModifyBasicInfoSerializer,
  };
}
