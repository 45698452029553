import React, { useEffect, useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";
import styles from "../style.module.scss";
import LineChart from "@/components/charts/HighChart.component";
import {
  chartConstants,
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import {
  biometryEntryMethod,
  parseTooltipDate,
} from "@/domain/patient/view/tabsNew/monitoring/helpers";
import {
  getAlertLevelColor,
  getAlertLevelColorBG,
  getSeverityAlertLevel,
  getSeverityAlertLevelNew,
} from "@/domain/notes/model/constants";
import { useSelector } from "react-redux";
import {
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import { ObservationType } from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";
import { sortObservationArrayByDate } from "../helper";
import { NoDataAvailable } from "../../tabsNew";
import { ChartProps } from "../types";
import { TIMEOUT_DATE_CHANGE } from "../../tabsNew/monitoring/constants";
import { makeChartOptionsNew } from "../constants";
import cx from "clsx";

export const SpirometryChart: React.FC<ChartProps> = (props) => {
  const { observationData, patientDetail } = props;
  const spirometries = observationData;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);
  let pefAverage = 0;
  let fevAverage = 0;
  let pefAggregate = 0;
  let fevAggregate = 0;
  const [allowChartUpdate, setAllowChartUpdate] = useState(false);

  useEffect(() => {
    setAllowChartUpdate(true);
    window.setTimeout(() => {
      setAllowChartUpdate(false);
    }, TIMEOUT_DATE_CHANGE);
  }, [isoStartDate, isoEndDate]);

  spirometries.map((spirometry) => {
    pefAggregate += spirometry.pef?.value ?? 0;
    fevAggregate += spirometry.fev1?.value ?? 0;
  });

  pefAverage = pefAggregate / spirometries.length;
  fevAverage = fevAggregate / spirometries.length;
  sortObservationArrayByDate(spirometries);

  const commonChartConfig = {
    events: {
      beforePrint: async () => {
        setIsPrinting(true);
      },
      afterPrint: () => {
        setIsPrinting(false);
      },
    },
    chart: {
      type: "line",
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
      animation: false,
      spacingLeft: 10,
      spacingRight: 10,
      marginTop: 40,
    },
    xAxis: {
      min: isoStartDate,
      max: isoEndDate,
      labels: {
        overflow: "justify",
        format: "{value:%m/%d}",
      },
      gridLineWidth: 1,
    },

    colorAxis: [
      {
        showInLegend: false,
        reversed: true,
        labels: {
          format: "{value}L",
        },
      },
      {
        showInLegend: false,
        labels: {
          format: "{value}L",
        },
      },
    ],
    caption: {
      text: isPrinting ? patientDetail : "",
    },
    time: {
      useUTC: false,
    },
  };

  const fevChartConfig = useMemo(() => {
    return makeChartOptionsNew({
      ...commonChartConfig,
      tooltip: {
        useHTML: true,
        valueSuffix: " (L)",
        formatter: function (this: TooltipFormatterContextObject) {
          let point = this.points ? this.points[0] : this.point;
          return parseTooltipDate(
            ObservationType.Spirometry,
            this,
            "L",
            "",
            point.series?.userOptions
          );
        },
      },
      chart: {
        spacingLeft: 10,
        spacingRight: 10,
        marginTop: 40,
      },
      yTitle: "",
      yAxis: {
        gridLineDashStyle: "longdash",
        plotLines: [
          {
            color: "#1A91FF",
            value: fevAverage,
            width: "1",
            zIndex: 4,
          },
        ],
      },
      series: [
        {
          name: "FEV 1",
          color: chartConstants.COLOR.Line,
          colorAxis: 0,
          data: spirometries.map((sp: any) => {
            const { severityScore, timeOfObservation, _meta } = sp;
            const { created } = _meta;
            let x;
            const source = sp?.source ?? null;
            if (timeOfObservation) {
              x = Date.parse(new Date(timeOfObservation).toISOString());
            }
            if (created) {
              x = Date.parse(new Date(created).toISOString());
            }
            if (timeOfObservation || created) {
              return {
                x,
                y: sp.fev1?.value,
                marker: {
                  fillColor: getAlertLevelColorBG(
                    getSeverityAlertLevelNew(severityScore)
                  ),
                },
                source,
              };
            }
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      ],
    });
  }, [spirometries, patientDetail, isPrinting]);

  const pefChartConfig = useMemo(() => {
    return makeChartOptionsNew({
      ...commonChartConfig,
      tooltip: {
        useHTML: true,
        valueSuffix: " (L/min)",
        formatter: function (this: TooltipFormatterContextObject) {
          let point = this.points ? this.points[0] : this.point;
          return parseTooltipDate(
            ObservationType.Spirometry,
            this,
            "L/min",
            "",
            point.series?.userOptions
          );
        },
      },
      yTitle: "",
      chart: {
        spacingLeft: 10,
        spacingRight: 10,
        marginTop: 40,
      },
      yAxis: {
        gridLineDashStyle: "longdash",
        plotLines: [
          {
            color: "#1A91FF",
            value: pefAverage,
            width: "1",
            zIndex: 4,
          },
        ],
      },
      series: [
        {
          name: "PEF",
          color: chartConstants.COLOR.Line,
          colorAxis: 0,
          data: spirometries.map((sp) => {
            const { severityScore, timeOfObservation, _meta } = sp;
            const { created } = _meta;
            let x;
            const source = sp?.source ?? null;
            if (timeOfObservation) {
              x = Date.parse(new Date(timeOfObservation).toISOString());
            }
            if (created) {
              x = Date.parse(new Date(created).toISOString());
            }
            if (timeOfObservation || created) {
              return {
                x,
                y: sp.pef?.value,
                marker: {
                  fillColor: getAlertLevelColorBG(
                    getSeverityAlertLevelNew(severityScore)
                  ),
                },
                source,
              };
            }
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      ],
    });
  }, [spirometries, patientDetail, isPrinting]);

  return (
    <div className={cx(styles.spirometryPadding, "spirometryTab")}>
      <div className="">
        <label className={styles.graphHeader}>{"PEF"}</label>
        {spirometries.length ? (
          <LineChart
            containerProps={{
              id: "spirometry-chart-pef",
              className: "graphChart",
            }}
            options={pefChartConfig.options}
            allowChartUpdate={allowChartUpdate}
          />
        ) : (
          <NoDataAvailable className="noDataAvailable" />
        )}
      </div>
      <div className="">
        <label className={styles.graphHeader}>{"FEV 1"}</label>
        {spirometries.length ? (
          <LineChart
            containerProps={{
              id: "spirometry-chart-fev",
              className: "graphChart",
            }}
            options={fevChartConfig.options}
            allowChartUpdate={allowChartUpdate}
          />
        ) : (
          <NoDataAvailable className="noDataAvailable" />
        )}
      </div>
    </div>
  );
};

export default SpirometryChart;
