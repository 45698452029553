import { PatientId } from "@/domain/patient/redux/types";
import { Rule } from "@/domain/rules/model/types";
import * as C from "./constants";

export const dispatchSetPatientRules = (
  patientId: PatientId,
  rules: Rule[]
) => ({
  type: C.SET_PATIENT_RULES,
  payload: { patientId, rules },
});

export const dispatchSetOrganizationRules = (
  organizationId: PatientId,
  rules: Rule[]
) => ({
  type: C.SET_ORGANIZATION_RULES,
  payload: { organizationId, rules },
});

export const dispatchSetPracticeRules = (
  practiceId: PatientId,
  rules: Rule[]
) => ({
  type: C.SET_PRACTICE_RULES,
  payload: { practiceId, rules },
});

export const dispatchSetProviderRules = (
  providerId: PatientId,
  rules: Rule[]
) => ({
  type: C.SET_PROVIDER_RULES,
  payload: { providerId, rules },
});
