import { Dispatch } from "redux";

import {
  AssessmentReason,
  ObservationReason,
  PatientNoteType,
} from "@/library/types/note";
import { Assessment } from "@/domain/assessment/model/types";
import { Note, NoteForm } from "@/domain/notes/model/types";
import { NoteModel } from "@/domain/notes/model";
import { PatientObservation } from "@/domain/patient/model/types";
import { RootState } from "@/types";
import { createNote } from "./actions";

/**
 * Intercept note's object before saving it to the redux state.
 *
 * @param note
 */
export const createNoteThunk =
  (note: NoteForm) => async (dispatch: Dispatch, getState: () => RootState) => {
    // check if note is child note and exit
    if (NoteModel.make(note).isChild) {
      // don't save child note to redux
      return;
    }

    //Check if note type is timelog and exit
    if (note.type === PatientNoteType.Timelog) {
      //Don't save timelog note to redux store as they should not display in the note table
      return;
    }

    if (note.type === PatientNoteType.Observation) {
      note.isComplete = true;
      if (!note.reason) {
        note.reason = ObservationReason.Manual;
      }
    }

    if (note.type === PatientNoteType.Assessment) {
      restructureAssessmentNote(note);
    }

    /**
     * Attaching selected patient ID to newly created Note.
     */
    const { patient: patientState, user } = getState();

    if (!patientState) return;

    const { currentUser } = user;
    if (!note.patientId) {
      note.patientId = patientState.selectedPatients[0].id;
    }

    dispatch(
      createNote({
        ...note,
        [note.type.toLowerCase()]: {
          ...(note.location ? { location: note.location } : null),
          ...(note.reason ? { reason: note.reason } : null),
          ...(note.observations ? { observations: note.observations } : null),
        },
        author: {
          name: `${currentUser?.firstName} ${currentUser?.lastName}`,
          credentials: currentUser?.credentials || "",
          email: currentUser?.email,
        },
        _meta: getNoteMeta(note),
        ...(note.observations !== undefined
          ? { resolvedObservations: note.observations as PatientObservation[] }
          : {}),
      })
    );
  };

const getNoteMeta = (note: NoteForm) => {
  let createdDate = new Date().toISOString();
  if (note.type === PatientNoteType.Observation) {
    if (note.timeOfObservation) {
      createdDate = note.timeOfObservation;
    }
  }
  return {
    created: createdDate,
    id: note.id as string,
  };
};

/**
 * Attach required properties to assessment note object.
 * @param note
 *
 */
const restructureAssessmentNote = (note: NoteForm) => {
  note.type = PatientNoteType.Assessment as PatientNoteType;
  note.reason = AssessmentReason.Completed;

  note.resolvedAssessment = {
    ...note.resolvedAssessment,
    questions: note.questions || [],
  } as Assessment;

  note.assessment = {
    ...note.assessment,
    reason: AssessmentReason.Completed,
  } as Note["assessment"];
};
