import { FunctionComponent } from "react";

import styles from "./content.module.scss";

type ContentProps = {};

export const Content: FunctionComponent<ContentProps> = ({ children }) => {
  const retractSidebar = () => {
    const sidebars = document.getElementsByClassName("sidebar");
    if (sidebars.length) {
      const mainSidebar = sidebars[0];
      if (mainSidebar.className === "sidebar active") {
        mainSidebar.classList.remove("active");
      }
    }
  };

  return (
    <section onMouseDown={retractSidebar} className={styles.pageContent}>
      {children}
    </section>
  );
};
