import { connect } from "react-redux";
import React from "react";
import { User, UserModel, UserState } from "../model";
import { Notification } from "../../../components/notification/notification";
import {
  ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "../../../library/constants";
import { RCAResponseErrorParser } from "../../../library/error/parser/rca.error.parser";
import { PaginationConfig } from "../../../library/types";
import { selectUser, setUsers, setUsersPaginationConfig } from "../redux";
import { RootState } from "@/types";

type EnableDisableUserProps = {
  selectedUser: User | undefined;
  setUsers: (users: User[]) => { type: string; payload: User[] };
  modalContent: string;
  onModalClose: () => void;
  users: User[];
};

type EnableDisableUserState = {};

class Component extends React.Component<
  EnableDisableUserProps,
  EnableDisableUserState
> {
  constructor(props: EnableDisableUserProps) {
    super(props);
    this.state = {};
  }

  updateUserState = (newState: UserState, isEnabled: boolean): void => {
    const { users, selectedUser } = this.props;

    const userToBeUpdated = users.find(
      (user: User) => user.id === selectedUser?.id
    );
    if (userToBeUpdated) {
      const updatedUsers = users.map((user: User) => {
        if (user.id === userToBeUpdated.id) {
          user.state = newState;
          user.isEnabled = isEnabled;
        }
        return user;
      });
      this.props.setUsers(updatedUsers.map((user: User) => user));
    }
  };
  async enableUser() {
    try {
      await UserModel.enableUser(this.props.selectedUser?.id!);
      this.updateUserState(UserState.PENDING, true);
      Notification.notify(SUCCESS, "User successfully enabled.");
      this.props.onModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
    }
  }

  async disableUser() {
    try {
      await UserModel.disableUser(this.props.selectedUser?.id!);
      this.updateUserState(UserState.SUSPENDED, false);
      Notification.notify(SUCCESS, "User successfully disabled.");
      this.props.onModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
    }
  }

  render() {
    return (
      <>
        <p
          style={{
            padding: "15px",
            fontSize: "17px",
          }}
        >
          {this.props.modalContent}
        </p>
        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => this.props.onModalClose()}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={async () => {
              if (this.props.selectedUser?.isEnabled) {
                await this.disableUser();
              } else {
                await this.enableUser();
              }
            }}
            className="btn btn-primary mr-2"
          >
            Continue
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    users: state.user.users,
    loggedInUser: state.user.currentUser,
    userPageLimit: state.user.userPageLimit,
    paginationConfig: state.user.paginationConfig,

    targetUserId: state.user.targetUserId,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setUsers: (users: User[]): any => dispatch(setUsers(users)),
    setUsersPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setUsersPaginationConfig(paginationConfig)),

    selectUser: (user: User): any => dispatch(selectUser(user)),
  };
};

export const EnableDisableUserComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
