import { User, UserModel } from "../../../domain/user/model";
import { CanMake } from "../../model/model.interface";
import { Deserializer, DeserializerStack } from "../deserializer";

export class UserDeserializer extends Deserializer<User, UserModel> {
  /**
   * Makes an instance of user deserializer
   * @param user
   * @param makeModel
   * @returns
   *
   */
  static for(user: User, makeModel: CanMake<User, UserModel>) {
    return new UserDeserializer(user, makeModel);
  }
  /**
   * Stack of syncronous deserializing functions.
   */
  protected deserializers: DeserializerStack<UserModel> = {
    /**
     * @NOTE NEVER ADD ASYNC METHODS IN THE DESERIALIZER STACK.
     * USE USER MIDDLEWARE FOR ASYNCRONOUS TASKS.
     *
     */
    lastLoggedInAt: () => this.remodelLastLoggedInAt(),
  };

  /**
   * Converts user last activity date to system timezone
   */
  private remodelLastLoggedInAt(): void {
    const lastLoggedInAt = this.entityModel.pluck("lastLoggedInAt");
    if (lastLoggedInAt) {
      const dateInLocalTimezone = this.convertLocalTimezone(lastLoggedInAt);
      this.setValue("lastLoggedInAt", dateInLocalTimezone.toString());
    }
  }
}
