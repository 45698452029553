import React from "react";
import { useSelector } from "react-redux";
import { FormikHelpers, FormikProvider, useFormik } from "formik";

import { Select, TextInput, TextInputMask } from "@/components/form";
import { CountryCodes, usStates } from "@/library/types/address";
import { Location, PostTag, Tag } from "@/domain/tags/model/types";
import { PracticeId } from "@/domain/practice/redux/types";
import { buildTagType, makeAssociatedData } from "@/domain/tags/model/helpers";
import { notify } from "@/components/notification/notification";
import { Practice } from "@/domain/practice/model/types";
import { TagModel } from "@/domain/tags/model";
import { RootState } from "@/types";
import { makeValidation } from "./helpers";

// import styles from './styles.module.scss';

export type Props = {
  practiceId?: PracticeId | null;
  tag?: Tag;
  onSave: (tag: Tag) => void;
  onClose: () => void;
};

const initialValues = {
  id: undefined,
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  contactName: "",
  contactEmail: "",
  contactPosition: "",
  contactPhone: "",
};

export const LocationForm: React.FC<Props> = (props) => {
  const { practiceId, tag, onClose, onSave } = props;

  const practices = useSelector<RootState, Practice[]>(
    (state) => state.practice.practices
  );
  const practice = practices.find(
    (practice: Practice) => practice.id === practiceId
  ) as Practice;

  const location = tag ? buildTagType<Location>(tag) : null;
  const isEditMode = !!tag && !!location;

  const getInitialValues = () => {
    if (isEditMode) {
      return {
        id: location.id,
        name: location.name,
        address: location.address || ("" as string),
        city: location.city || ("" as string),
        state: location.state || ("" as string),
        zip: location.zip || ("" as string),
        country: location.country || ("" as string),
        contactName: location.contactName || ("" as string),
        contactEmail: location.contactEmail || ("" as string),
        contactPosition: location.contactPosition || ("" as string),
        contactPhone: location.contactPhone || ("" as string),
      };
    }
    return initialValues;
  };

  const handleSubmit = async (
    values: Partial<Location>,
    form: FormikHelpers<Partial<Location>>
  ) => {
    const { setSubmitting } = form;

    if (!practiceId) return;

    setSubmitting(true);

    const associatedData = makeAssociatedData<Partial<Location>>(
      initialValues,
      values
    );
    const postTag: PostTag = {
      type: isEditMode ? undefined : "location",
      practiceId: isEditMode ? undefined : practiceId,
      value: values.name || "",
      associatedData,
    };

    let id = tag?.id || tag?._meta?.id;
    if (isEditMode && id) {
      await TagModel.updateLocationTag(id, postTag);
    } else {
      const createRes = await TagModel.createLocationTag(postTag);
      if (createRes?._self) id = createRes._self.id;
    }

    const updatedTag = {
      ...postTag,
      type: "location",
      practiceId,
      associatedData,
      _meta: {
        ...tag?._meta,
        id,
      },
    } as Tag;

    notify("success", "Location saved.");
    setSubmitting(false);
    onSave(updatedTag);
    onClose();
  };

  const form = useFormik<Partial<Location>>({
    initialValues: getInitialValues(),
    validationSchema: makeValidation(),
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <div className="modal-body">
          <div className="form-row d-flex justify-content-center">
            <div className="col-md-4 mb-3">
              <TextInput
                label="Location Name"
                name="name"
                type="text"
                placeholder="Location Name"
                required
              />
            </div>
            <div className="col-md-3 mb-3">
              <Select label="Branch" name="practice" required={false} disabled>
                <option value={practiceId || ""}>{practice?.name}</option>
              </Select>
            </div>
            <div className="col-md-3 mb-3" />
            <div className="col-md-10 col-lg-10 mb-3">
              <TextInput
                label="Address"
                name="address"
                type="text"
                placeholder="Full Address Including Apt. #"
                required
              />
            </div>
            <div className="col-md-10 col-lg-10 mb-3">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <TextInput
                    label="City"
                    name="city"
                    type="text"
                    placeholder="City"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <Select label="State" name="state" required>
                    <option value="">Select State</option>
                    {usStates.map((state, key) => (
                      <option key={key} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form-group col-md-2">
                  <TextInput
                    label="Zip Code"
                    name="zip"
                    type="text"
                    placeholder="Zip Code"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <Select label="Country" name="country" required>
                    <option>Select Country</option>
                    {CountryCodes.filter(
                      (country) => country.code === "US"
                    ).map((country, key) => (
                      <option key={key} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-lg-10 mb-3">
              <div className="form-row">
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Name"
                    name="contactName"
                    type="text"
                    placeholder="Contact Name"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Email"
                    name="contactEmail"
                    type="email"
                    placeholder="Contact Email"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Position"
                    name="contactPosition"
                    type="text"
                    placeholder="Contact Position"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInputMask
                    type="tel"
                    label="Contact Phone"
                    name="contactPhone"
                    mask="+1 (999) 999-9999"
                    value={form.values.contactPhone}
                    onChange={(e: any) => {
                      form.setFieldValue("contactPhone", e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mt-5">
              {isEditMode && (
                <button
                  disabled
                  type="button"
                  className="btn btn-danger sweet-multiple"
                >
                  Disable
                </button>
              )}
            </div>

            <div className="col-6 mt-5 d-flex justify-content-end">
              <button
                onClick={onClose}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={form.isSubmitting}
                className="btn btn-primary"
              >
                {isEditMode ? "Save Changes" : "Create"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
