import { Selector } from "react-redux";
import { createSelector } from "reselect";

import { RootState } from "@/types";
import { Location, Tag } from "../model/types";
import { buildTagType } from "../model/helpers";

export const selectLocationTags: Selector<RootState, Tag[]> = (state) =>
  state.tag.locationTags;

export const selectLocations = createSelector<
  [Selector<RootState, Tag[]>],
  Location[]
>([selectLocationTags], (tags) => {
  const locations = [];
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];

    locations.push(buildTagType(tag));
  }
  return locations;
});
