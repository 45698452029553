import { AxiosPromise } from "axios";

import { unique } from "@/util/unique";
import { RemoteCareAPISync } from "../../../library/core/api";
import { EventEmitter } from "../../../library/core/event";
import { Attributes, Model } from "../../../library/model";
import {
  AssignedPatientsResponse,
  User,
  UserActions,
  UserParentTypes,
  UserState,
} from "./user";
import { Http } from "../../../library/core/api/http";
import { MODEL_SYNCED, SAVE_ERROR } from "../../../library/constants";
import { ModelCollection } from "../../../library/model/model.collection";
import { UserForm } from ".";
import { RCAResourceActions } from "../../../library/core/config/actions";
import { UserSerializer } from "../../../library/serializers/user";
import { Iterator } from "../../../library/iteration/iterator";
import { UserPolicy } from "../../../library/policy/user.policy";
import { OrganizationType } from "../../../library/types";
import { UserDeserializer } from "../../../library/deserializers/user/user.deserializer";
import { ResetPassword } from "../../profile/model/model";
import { Policy } from "@/library/policy/policy.interface";

export class UserModel extends Model<User> {
  static readonly path = "users";

  /**
   * Makes a new instance of UserModel. It requires only the attributes
   * that would be used to instantiate the new User Model.
   *
   * @param attributes
   */
  static make(attributes: User): UserModel {
    return new UserModel(
      new Attributes<User>(attributes),
      new RemoteCareAPISync<User>(UserModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of users using the provided resource url and a deserializer.
   * The entire organization list can be accessed in the models member of the Collection class.
   */
  static makeUserCollection(): ModelCollection<UserModel, User> {
    return new ModelCollection<UserModel, User>(
      `${RemoteCareAPISync.host}/${UserModel.path}`,
      (entity: User) => this.deserialize(entity)
    );
  }

  static async fetchByParams(params: {
    [key: string]: string[] | boolean;
  }): Promise<UserModel[]> {
    const userCollection: ModelCollection<UserModel, User> =
      UserModel.makeUserCollection();
    userCollection.on(MODEL_SYNCED, () => {});
    userCollection.withParams({
      ...params,
    });
    const iterator: Iterator<UserModel, User> = await userCollection.getMany();
    return iterator.getAll();
  }

  static async fetchByOrganizationIds(
    ids: string[],
    params?: { [key: string]: string | boolean | string[] }
  ): Promise<UserModel[]> {
    if (!ids.length) {
      return new Promise((resolve) => resolve([]));
    }

    return this.fetchByParams({
      organizationIds: ids,
      ...(params ? { ...params } : {}),
    });
  }

  static async fetchByPracticeIds(
    ids: string[],
    params?: { [key: string]: string | boolean | string[] }
  ): Promise<UserModel[]> {
    if (!ids.length) {
      return new Promise((resolve) => resolve([]));
    }

    return this.fetchByParams({
      practiceIds: ids,
      ...(params ? { ...params } : {}),
    });
  }

  /**
   * Sync a user by Id.
   *
   * @param id
   */
  static async sync(id: string): Promise<UserModel> {
    const apiSync = new RemoteCareAPISync<User>(UserModel.path);
    const user: User = (await apiSync.fetch(id)).data;

    return this.deserialize(user);
  }

  /**
   * Update currently logged in user profile.
   */
  async updateMe(
    onSuccess: () => void,
    onError: (withArg?: any) => void
  ): Promise<void> {
    // register success and error handlers.
    this.on(MODEL_SYNCED, () => onSuccess());
    this.on(SAVE_ERROR, (withArg?: any) => onError());

    const user: User = this.attributes.getAll();
    delete user.id;

    let entity;
    try {
      entity = (
        await Http().post(
          `${RemoteCareAPISync.host}/${UserModel.path}/me/actions/update-profile`,
          user
        )
      ).data._self;
    } catch (e) {
      this.trigger(SAVE_ERROR, e);
    }

    if (entity) {
      this.set({ ...this.attributes.getAll(), id: entity.id });
    }
  }

  /**
   * Authenticate user.
   */
  static async authenticateMe(): Promise<User | null> {
    try {
      return (await Http().get(`${RemoteCareAPISync.host}/users/me`)).data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Reset currently logged in user password
   */
  static async resetMyPassword(values: ResetPassword): Promise<void> {
    await Http().post(
      `${RemoteCareAPISync.host}/users/me/actions/change-password`,
      {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      }
    );
  }

  async organizationScopeIds(): Promise<string[]> {
    return this.scopeIds("Organization");
  }

  async practiceScopeIds(): Promise<string[]> {
    return this.scopeIds("Practice");
  }

  private async scopeIds(
    entity: "Organization" | "Practice"
  ): Promise<string[]> {
    const userPolicies = await UserPolicy.parseMany(this.pluck("policy"));
    return userPolicies
      .filter((userPolicy) => userPolicy.scope.parser.entity === entity)
      .map((userPolicy) => userPolicy.scope.parser.entityId);
  }

  /**
   * A thunk used to determine if a user can perform a given action.
   * This will be extended to handle any user action type verification.
   */
  get canPerform(): (action: string) => boolean {
    // check if user attributes are populated
    if (!this.attributes.getAll()) {
      return (action: string) => false;
    }
    return (action: string) => {
      switch (action) {
        case UserActions.CreatePracticeUser:
          return [
            UserParentTypes.Practice,
            UserParentTypes.Organization,
            UserParentTypes.Admin,
          ].includes(this.parentType);

        case UserActions.CreateOrganizationUser:
          return [UserParentTypes.Organization, UserParentTypes.Admin].includes(
            this.parentType
          );

        case UserActions.CreateAdminUser:
          return UserParentTypes.Admin === this.parentType;
        default:
          return false;
      }
    };
  }

  /**
   * Get supporting label for creating user form.
   *
   * @param action
   */
  getCreateFormLabel(action: string): string {
    // check if user attributes are populated
    if (!this.attributes.getAll()) {
      return "";
    }
    switch (action) {
      case UserActions.CreatePracticeUser:
        return "Scope of visibility limited to a single practice";

      case UserActions.CreateOrganizationUser:
        return "Scope of visibility granted to multiple practices under a single organization";

      case UserActions.CreateAdminUser:
        return "Scope of visibility granted to multiple organizations and all contained practices";
      default:
        return "";
    }
  }

  /**
   * Get the type of this parent user.
   */
  get parentType(): UserParentTypes {
    if (this.attributes.get("parentEntities")[0].classification === "Root") {
      return UserParentTypes.Admin;
    }

    return this.attributes.get("parentEntities")[0].type as UserParentTypes;
  }

  /**
   * Get parent Id.
   */
  get parentId(): string {
    return this.attributes.get("parentEntities")[0].id;
  }

  /**
   * Check if user parent is practice
   */
  get hasPracticeParentType(): boolean {
    return (
      this.attributes.get("parentEntities")[0].type === UserParentTypes.Practice
    );
  }

  /**
   * Check if user parent is organization
   */
  get hasOrganizationParentType(): boolean {
    return (
      this.attributes.get("parentEntities")[0].type ===
      UserParentTypes.Organization
    );
  }

  /**
   * Check if user parent is practice
   */
  get hasAdminParentType(): boolean {
    return (
      this.attributes.get("parentEntities")[0].type ===
        UserParentTypes.Organization &&
      this.attributes.get("parentEntities")[0].classification ===
        OrganizationType.Root
    );
  }

  /**
   * Takes a form data and serializes it based on intended RemoteCareAPI Resource action.
   *
   * @param data
   * @param action
   */
  static serialize = (
    data: UserForm,
    action: RCAResourceActions
  ): UserModel => {
    return UserSerializer.for(action, data).serialize();
  };

  static deserialize = (data: User) =>
    UserDeserializer.for(data, UserModel.make).deserialize();

  /**
   * Sync Users by Ids.
   *
   * @param id
   */
  static syncMany = async (
    ids: string[]
  ): Promise<Array<UserModel | number>> => {
    const promiseArray: AxiosPromise[] = [];
    ids
      .filter(unique)
      .forEach((id) =>
        promiseArray.push(new RemoteCareAPISync(UserModel.path).fetch(id))
      );
    const promiseResult = await Promise.allSettled(promiseArray);
    const userInfo: Array<UserModel | number> = [];
    promiseResult.forEach((promiseResultItem, index) => {
      if (promiseResultItem.status === "rejected") {
        userInfo.push(promiseResultItem.reason.response.status);
      } else {
        userInfo.push(this.deserialize(promiseResultItem?.value?.data));
      }
    });
    return userInfo;
  };

  static assignPatients = async (
    userId: string,
    patientIds: string[]
  ): Promise<AssignedPatientsResponse | undefined> => {
    try {
      const assignedPatients = await Http().post(
        `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/assign-patients`,
        { patientIds }
      );
      return assignedPatients.data;
    } catch (e) {
      return undefined;
    }
  };

  get hasPendingStatus(): boolean {
    return this.pluck("state") === UserState.PENDING;
  }

  get isSuspended(): boolean {
    return this.pluck("state") === UserState.SUSPENDED;
  }

  static resendUserInvite = async (userId: string) => {
    await Http().post(
      `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/resend-invite`
    );
  };

  static resetUserPassword = async (userId: string) => {
    await Http().post(
      `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/send-reset-password`,
      {}
    );
  };

  static async enableUser(userId: string): Promise<void> {
    await Http().post(
      `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/enable`,
      {}
    );
  }

  static async disableUser(userId: string): Promise<void> {
    await Http().post(
      `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/disable`,
      {}
    );
  }

  static async setPolicy(
    userId: string,
    autoAssignRules?: any,
    policy?: Policy
  ): Promise<void> {
    await Http().post(
      `${RemoteCareAPISync.host}/${UserModel.path}/${userId}/actions/set-policy`,
      {
        autoAssignRules,
        policy,
      }
    );
  }
}
