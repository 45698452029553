/**
 * Billing classification for a patient.
 */
export enum BillingType {
  RPM = "RPM",
  CCM = "CCM",
  TCM = "TCM",
  RPM_CCM = "RPM_CCM",
}

/**
 * Bliing options for selection
 */
export const BILLING_TYPES: { label: string; value: string }[] = [
  { label: BillingType.RPM, value: BillingType.RPM },
  { label: BillingType.CCM, value: BillingType.CCM },
  { label: BillingType.TCM, value: BillingType.TCM },
  { label: "RPM/TCM", value: BillingType.RPM_CCM },
];
