import { Formik, Form } from "formik";
import React from "react";
import { User, UserModel } from "../../user/model";
import { connect } from "react-redux";
import { Notification } from "../../../components/notification/notification";
import { ERROR, SUCCESS } from "../../../library/constants";
import { RCAResponseErrorParser } from "../../../library/error/parser/rca.error.parser";
import { ResetPassword, ResetPasswordForm } from "../model/model";
import {
  ResetPasswordValidation,
  TextInput,
  Validation,
} from "../../../components/form";
import * as Yup from "yup";
import { setCurrentUser } from "../../user/redux";

type ResetPasswordProps = {
  handleClose: () => void;
  button: string;
  currentUser: User;
  setCurrentUser: (user: User) => { type: string; payload: User };
};

class Component extends React.Component<ResetPasswordProps, {}> {
  constructor(props: ResetPasswordProps) {
    super(props);
  }

  handleSubmit = async (values: ResetPassword) => {
    try {
      await UserModel.resetMyPassword(values);
      Notification.notify(SUCCESS, "Password updated successfully.");
      this.props.handleClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  parseValues = (values: ResetPasswordForm) => {
    return {
      id: values.id,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword,
    };
  };

  render() {
    const user = this.props.currentUser;
    return (
      <Formik
        initialValues={{
          id: user?.id,
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={Yup.object({
          id: Validation.id,
          currentPassword: ResetPasswordValidation.currentPassword,
          newPassword: ResetPasswordValidation.newPassword,
          confirmNewPassword: ResetPasswordValidation.confirmPassword,
        })}
        validateOnMount
        validateOnBlur
        onSubmit={(values: ResetPasswordForm) =>
          this.handleSubmit(this.parseValues(values))
        }
      >
        {(formik) => {
          return (
            <div className="modal-body">
              <Form className="d-flex justify-content-center">
                <div className="form-row">
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="New Password"
                      name="newPassword"
                      type="password"
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      type="password"
                      required
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-5">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="btn btn-primary"
                    >
                      {this.props.button}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: { user: { currentUser: User } }) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setCurrentUser: (user: User): { type: string; payload: User } =>
      dispatch(setCurrentUser(user)),
  };
};

export const ResetPasswordFormComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
