import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import Loader from "rsuite/Loader";
import produce from "immer";
import cx from "clsx";

import { Patient } from "@/domain/patient/model/types";
import { Tooltip } from "@/components/Tooltip";
import { PatientModel } from "@/domain/patient/model";
import { dispatchUpdatePatient } from "@/domain/patient/redux/actions";
import { ObservationOverviewSnapshot } from "@/domain/observations/types";
import { MIN_FETCH_TIME_MS } from "@/library/constants";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  patient?: Patient;
};

export const PatientReviewedCheckbox: React.FC<Props> = (props) => {
  const { patient } = props;

  const [pending, setPending] = useState(false);
  const [checked, setChecked] = useState(false);
  const [currentSecond, setCurrentSecond] = useState(
    DateTime.local().get("second")
  );

  const dispatch = useDispatch();
  const updatePatient = (patient: Patient) =>
    dispatch(dispatchUpdatePatient(patient));

  const hasAlerts = (patient?.health?.alertScore || 0) > 0;
  const hasReadings = patient?.health?.hasIncompleteNotes === true;
  const lastReview = patient?.lastReviewedOn
    ? DateTime.fromISO(patient?.lastReviewedOn)
    : null;
  const now = DateTime.now();
  const diff = lastReview ? now.diff(lastReview, ["days"]) : null;
  const yellow = hasReadings || (lastReview && diff && diff.days > 1);
  const reason =
    (patient?.health?.alertScore || 0) > 0
      ? 'Please clear the alerts from the Patient "Monitoring" tab.'
      : yellow
      ? hasReadings
        ? "Please accept current readings"
        : "Patient was reviewed > 24h ago"
      : "";

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSecond(DateTime.local().get("second"));
    }, 1000);

    if (!checked) clearInterval(timer);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [checked]);

  useEffect(() => {
    if (
      !hasAlerts &&
      !hasReadings &&
      lastReview &&
      diff &&
      diff.days < 1 &&
      lastReview.hasSame(DateTime.local(), "day")
    )
      setChecked(true);
  }, [hasAlerts, hasReadings, lastReview, diff]);

  useEffect(() => {
    // Reset each new day
    if (checked && lastReview && !lastReview.hasSame(DateTime.local(), "day")) {
      setChecked(false);
    }
  }, [checked, lastReview, currentSecond]);

  const handleLabelClick = (event: MouseEvent) => event.stopPropagation();
  const handleLabelKeyPress = (event: KeyboardEvent) => event.stopPropagation();
  const handleReviewedChange = async (event: ChangeEvent) => {
    event.stopPropagation();

    if (!patient || checked) return;

    const startTime = new Date().getTime();
    setPending(true);
    const patientModel = PatientModel.make(patient);
    await patientModel.setReviewed();
    const updatedPatient = produce(patient, (draft) => {
      draft.lastReviewedOn = new Date().toISOString();
      if (draft.health) {
        draft.health.hasIncompleteNotes = false;
        const observationKeys = Object.keys(draft.health.observations || {});
        for (let i = 0; i < observationKeys.length; i++) {
          const key = observationKeys[i] as keyof ObservationOverviewSnapshot;
          if (!!draft.health?.observations?.[key]) {
            (draft.health.observations as any)[key].isComplete = true;
          }
        }
      }
    });
    const requestTime = new Date().getTime() - startTime;
    const additionalDelay =
      requestTime < MIN_FETCH_TIME_MS ? MIN_FETCH_TIME_MS - requestTime : 0;

    setTimeout(() => {
      updatePatient(updatedPatient);
      setChecked(true);
      setPending(false);
    }, additionalDelay);
  };

  if (!patient || !patient?.health) return null;

  return (
    <button
      type="button"
      onClick={handleLabelClick}
      onKeyPress={handleLabelKeyPress}
      className={cx(globalStyles.buttonReset)}
    >
      <label className={cx(styles.reviewedLabel)} htmlFor="patient-reviewed">
        <Tooltip
          tooltipContent={reason}
          trigger={!hasAlerts ? [] : ["hover", "focus", "click"]}
          delay={100}
          tooltipStyle={{ display: "flex" }}
        >
          <span className={cx(hasAlerts && globalStyles.disabled)}>
            Reviewed
          </span>
          {!pending ? (
            <input
              id="patient-reviewed"
              className="ml-2"
              type="checkbox"
              checked={checked}
              onChange={handleReviewedChange}
              disabled={hasAlerts}
            />
          ) : (
            <Loader size="xs" style={{ marginLeft: 7 }} />
          )}
        </Tooltip>
      </label>
    </button>
  );
};
