import { ProviderForm, ProviderModel } from "../../../domain/provider/model";
import { Serializeable } from "../serializer";

/**
 * Serializer for modifying Provder.
 */
export class ModifyProviderSerializer implements Serializeable<ProviderModel> {
  constructor(protected readonly formData: ProviderForm) {}

  serialize(): ProviderModel {
    return ProviderModel.make({
      id: this.formData.id,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      credentials: this.formData.credentials,
      specialty: this.formData.specialty ? this.formData.specialty : null,
      phoneNumber: this.formData.phoneNumber ? this.formData.phoneNumber : null,
    } as any);
  }
}
