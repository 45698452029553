import { AnyAction, Dispatch } from "redux";

import { createOrganization, setOrganizations } from "./actions";
import { OrganizationModel } from "../model";
import { Organization } from "../model/types";
import { SetOrganzationDispatch } from "./types";

/**
 * Outer synchronous function that receives the `organizationCollection` parameter:
 * @param organizationCollection
 */
export function setOrganizationsThunk(
  organizationCollection: OrganizationModel[]
): (dispatch: SetOrganzationDispatch) => Promise<void> {
  // And then creates and returns the async thunk function:
  return async (dispatch: SetOrganzationDispatch) => {
    if (!organizationCollection.length) {
      dispatch(setOrganizations([]));
    }
    //  Now we can use the organizationModel value and intercept it before sending it to the action or reducer.
    const organizations = organizationCollection.map((organizationModel) =>
      organizationModel.pluckAll()
    );
    dispatch(setOrganizations(organizations));
  };
}

/**
 * Intercept newly created organization object before saving it to the redux state
 * @param organization
 */
export const createOrganizationThunk =
  (organization: Organization) =>
  (dispatch: Dispatch): AnyAction => {
    return dispatch(createOrganization(organization));
  };
