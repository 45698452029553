import react from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Reference from "yup/lib/Reference";
import Lazy from "yup/lib/Lazy";
import { isBefore } from "date-fns";
import { TextInput, Select, ObservationValidation } from "@/components/form";
import { Note, NoteObservation } from "@/domain/notes/model/types";
import { DatePicker } from "rsuite";
import { RSUITE_TWELVE_HOUR_FORMAT } from "@/pipes/date/constants";
import {
  ObservationType,
  ObservationsUnitAbbrevation,
} from "@/domain/observations/types";

interface State {
  observationType: ObservationType | undefined | string;
  unit: string | undefined;
  validationType:
    | Yup.AnySchema<any, any, any>
    | Reference<unknown>
    | Lazy<any, any>;
}

interface Props {
  setObservationsFieldValue: (
    index: number,
    nextObservation: NoteObservation
  ) => void;
  index: number;
}

export class ObservationFormFields extends react.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      observationType: "",
      unit: undefined,
      validationType: undefined as unknown as Yup.AnySchema,
    };
  }

  get initialValues() {
    return {
      observations: [{}],
    } as any;
  }

  render() {
    return (
      <div className="row">
        <Formik
          initialValues={this.initialValues}
          validationSchema={Yup.object({
            value: this.state.validationType,
            fev1: ObservationValidation.SpirometryFev,
            pef: ObservationValidation.SpirometryPef,
            systolic: ObservationValidation.BloodPressureSystolic,
            diastolic: ObservationValidation.BloodPressureDiastolic,
          })}
          onSubmit={async (
            values: Partial<Note>,
            { setSubmitting, resetForm }
          ) => {}}
        >
          {(formik) => {
            return (
              <>
                <div className="form-group col-3">
                  <div className="form-group">
                    <label>Time of Observation (if not current time)</label>
                    <div>
                      <DatePicker
                        format={RSUITE_TWELVE_HOUR_FORMAT}
                        ranges={[
                          {
                            label: "Now",
                            value: new Date(),
                          },
                        ]}
                        disabledDate={(date) =>
                          !isBefore(date as Date, new Date())
                        }
                        style={{ width: 260 }}
                        name="timeOfObservation"
                        onChange={(date: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            timeOfObservation: date.toISOString(),
                          };
                          formik.setFieldValue(
                            "timeOfObservation",
                            date.toISOString()
                          );
                          formik.setFieldValue("observations", [observation]);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-3">
                  <Select
                    className="form-control"
                    label="Observation type"
                    name="observationType"
                    required
                    value={this.state.observationType}
                    onChange={async (value: any) => {
                      this.setState((previousState) => {
                        let unit: string | undefined;
                        switch (value.target.value) {
                          case ObservationType.BloodPressure:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.BloodPressure
                              ];
                            break;
                          case ObservationType.Spirometry:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.Spirometry
                              ];
                            break;
                          case ObservationType.GlucoseLevel:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.GlucoseLevel
                              ];
                            this.setState({
                              validationType:
                                ObservationValidation[
                                  ObservationType.GlucoseLevel
                                ],
                            });
                            break;
                          case ObservationType.HeartRate:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.HeartRate
                              ];
                            this.setState({
                              validationType:
                                ObservationValidation[
                                  ObservationType.HeartRate
                                ],
                            });
                            break;
                          case ObservationType.PulseOximetry:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.PulseOximetry
                              ];
                            this.setState({
                              validationType:
                                ObservationValidation[
                                  ObservationType.PulseOximetry
                                ],
                            });
                            break;
                          case ObservationType.Temperature:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.Temperature
                              ];
                            this.setState({
                              validationType:
                                ObservationValidation[
                                  ObservationType.Temperature
                                ],
                            });
                            break;
                          case ObservationType.Weight:
                            unit =
                              ObservationsUnitAbbrevation[
                                ObservationType.Weight
                              ];
                            this.setState({
                              validationType:
                                ObservationValidation[ObservationType.Weight],
                            });
                            break;
                          default:
                            unit = unit;
                        }
                        return {
                          ...previousState,
                          observationType: value.target.value,
                          unit: unit,
                        };
                      });

                      let observation = formik.values.observations[0];
                      observation = {
                        ...observation,
                        type: value.target.value,
                      };
                      formik.setFieldValue("observations", [observation]);
                      this.props.setObservationsFieldValue(
                        this.props.index,
                        observation
                      );
                      formik.setSubmitting(true);
                    }}
                  >
                    <option value={""}>Select One</option>
                    <option value={ObservationType.BloodPressure}>
                      Blood Pressure
                    </option>
                    <option value={ObservationType.GlucoseLevel}>
                      Glucose Level
                    </option>
                    <option value={ObservationType.HeartRate}>
                      Heart Rate
                    </option>
                    <option value={ObservationType.PulseOximetry}>
                      Pulse Oximetry
                    </option>
                    <option value={ObservationType.Spirometry}>
                      Spirometry
                    </option>
                    <option value={ObservationType.Temperature}>
                      Temperature
                    </option>
                    <option value={ObservationType.Weight}>Weight</option>
                  </Select>
                </div>
                {this.state.observationType !== ObservationType.BloodPressure &&
                  this.state.observationType !== ObservationType.Spirometry &&
                  this.state.observationType !== "" && (
                    <div className="form-group col-3">
                      <TextInput
                        label={"Value (" + this.state.unit + ")"}
                        name="value"
                        type="number"
                        placeholder="value"
                        required
                        onChange={(v: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            value: parseFloat(v.target.value),
                            unit: this.state.unit,
                          };
                          formik.setFieldValue("observations", [observation]);
                          formik.setFieldValue("value", v.target.value);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                  )}
                {this.state.observationType === ObservationType.Spirometry && (
                  <>
                    <div className="form-group col-3">
                      <TextInput
                        label="Fev1(L)"
                        name="fev1"
                        type="number"
                        placeholder="fev1 - (L)"
                        required
                        onChange={(v: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            fev1: {
                              value: parseFloat(v.target.value),
                              unit: "L",
                            },
                          };
                          formik.setFieldValue("observations", [observation]);
                          formik.setFieldValue("fev1", v.target.value);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                    <div className="form-group col-3">
                      <TextInput
                        label="Pef(L/min)"
                        name="pef"
                        type="number"
                        placeholder="pef - (L/min)"
                        required
                        onChange={(v: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            diastolic: undefined,
                            systolic: undefined,
                            pef: {
                              value: parseFloat(v.target.value),
                              unit: "L/min",
                            },
                          };
                          formik.setFieldValue("pef", v.target.value);
                          formik.setFieldValue("observations", [observation]);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                  </>
                )}
                {this.state.observationType ===
                  ObservationType.BloodPressure && (
                  <>
                    <div className="form-group col-3">
                      <TextInput
                        label="Systolic(mmHg)"
                        name="systolic"
                        type="number"
                        placeholder="systolic - (mmHg)"
                        required
                        onChange={(v: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            pef: undefined,
                            fev1: undefined,
                            systolic: {
                              value: parseFloat(v.target.value),
                              unit: "mmHg",
                            },
                          };
                          formik.setFieldValue("systolic", v.target.value);
                          formik.setFieldValue("observations", [observation]);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                    <div className="form-group col-3">
                      <TextInput
                        label="Diastolic(mmHg)"
                        name="diastolic"
                        type="number"
                        placeholder="diastolic - (mmHg)"
                        required
                        onChange={(v: any) => {
                          let observation = formik.values.observations[0];
                          observation = {
                            ...observation,
                            pef: undefined,
                            fev1: undefined,
                            diastolic: {
                              value: parseFloat(v.target.value),
                              unit: "mmHg",
                            },
                          };
                          formik.setFieldValue("diastolic", v.target.value);
                          formik.setFieldValue("observations", [observation]);
                          this.props.setObservationsFieldValue(
                            this.props.index,
                            observation
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    );
  }
}
