import { Modal } from "@/components/modal";
import React, { FC } from "react";
import { User } from "@/domain/user/model";
import { Patient } from "@/domain/patient/model/types";
import { NoActionWatchListItem } from "@/domain/patient/view/PatientsPage/watchlist/noActionWatchListItem";
import { UnassignWatchListItem } from "@/domain/patient/view/PatientsPage/watchlist/unassignWatchListItem";

interface Props {
  show?: boolean;
  onClose: () => void;
  title?: string;
  button: string;
  users?: User[];
  save: () => Promise<void>;
  patients: Patient[];
  addToUnassignList?: (user: User) => void;
  removeFromUnassignList?: (user: User) => void;
}
export const UnassignWatchListModal: FC<Props> = ({
  show = false,
  onClose,
  title = "modal",
  button,
  users = [],
  addToUnassignList = (user: User) => {},
  removeFromUnassignList = (user: User) => {},
  patients,
  save,
}) => {
  return (
    <Modal
      description="List of users"
      handleClose={onClose}
      show={show}
      heading={title}
      button={button}
      actionButton={{
        formik: {
          submitForm: () => (async () => await save())(),
          resetForm: () => {},
        },
        name: "Unassign",
      }}
      size="xs"
    >
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          position: "relative",
          maxHeight: "70vh",
        }}
      >
        {users.map((user: User) => {
          return patients.every(
            (patient: Patient) =>
              !patient.watchingUserIds?.includes(user._meta?.id! || user.id!)
          ) ? (
            <NoActionWatchListItem
              user={user}
              secondary="Not Assigned"
              toolTipDescription="This user is not assigned to any of the selected patients"
            />
          ) : (
            <UnassignWatchListItem
              patientCount={user.watchedPatientCount || 0}
              user={user}
              addToUnassignList={addToUnassignList}
              removeFromUnassignList={removeFromUnassignList}
            />
          );
        })}
      </div>
    </Modal>
  );
};
