import React, { Fragment, FC, SyntheticEvent, useState } from "react";
import { VirtualVisitCanvas } from "@/components/virtualVisit/canvas";
import { TwilioError } from "twilio-video/tsdef/TwilioError";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalAppContext } from "@/components/context";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface Principal {
  name: string;
  age: number;
  gender: "Male" | "Female";
}

export interface Handlers {
  onError: (error: TwilioError) => void;
  onLeave: (
    participantJoined: boolean,
    note: string | null,
    elapsedTime: number
  ) => Promise<void>;
  onMultiParticipant: (
    note: string | null,
    elapsedTime: number
  ) => Promise<void>;
}

export interface Options {
  token: string;
  room: string | null;
}
export interface VirtualVisitContainerProps {
  principal: Principal;
  options: Options;
  handlers: Handlers;
}
export const VirtualVisit: FC<VirtualVisitContainerProps> = ({
  principal,
  options,
  handlers,
}) => {
  const { setVirtualVisitNote, virtualVisitNote } = useGlobalAppContext();

  const [resetKey, setResetKey] = useState(Date.now()); // Using timestamp as a unique key to trigger timer reset.
  const [stop, setStop] = useState(false);
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [participantJoined, setParticipantJoined] = useState(false);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const getElapsedTime = async (seconds: number) => {
    setDurationInSeconds(seconds);
    await handlers.onLeave(
      participantJoined,
      virtualVisitNote || null,
      seconds
    );
    if (setVirtualVisitNote) {
      setVirtualVisitNote("");
    }
  };

  const handleReset = () => {
    if (!participantJoined) {
      setDurationInSeconds(0);
      setResetKey(Date.now()); // Update the resetKey state to trigger the timer reset.
      setParticipantJoined(true);
    }
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <VirtualVisitCanvas
            token={options.token}
            roomName={options.room as string}
            patientAge={principal.age}
            patientName={principal.name}
            patientGender={principal.gender}
            handleError={handlers.onError}
            onReset={resetKey}
            getElapsedTime={stop ? getElapsedTime : undefined}
            onLeaveCall={async (isMultiParticipants: boolean) => {
              await setStop(true);
            }}
            onMultiParticipant={() => {
              handleReset();
            }}
            getElapsedTimeOnReset={async (seconds: number) => {
              await handlers.onMultiParticipant(virtualVisitNote!, seconds);
            }}
            onNoteChanged={async (event: SyntheticEvent) => {
              const note = (event.target as any).value;
              if (setVirtualVisitNote) {
                setVirtualVisitNote(note);
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
