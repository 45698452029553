import React from "react";
import { connect } from "react-redux";

import {
  deselectAllProviders,
  selectAllProviders,
  setProviderPageLimit,
  setProvidersPaginationConfig,
  setProviderFilters,
  SetProviderDispatch,
  setProviderSortedState,
} from "@/domain/provider/redux";
import { Modal } from "@/components/modal";
import {
  Provider,
  ProviderModel,
  ProviderFilters,
} from "@/domain/provider/model";
import { FILTER_STRING, MODEL_SYNCED } from "@/library/constants";
import { PaginationConfig, ProviderQuery } from "@/library/types";
import { Organization } from "@/domain/organization/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { PracticeId } from "@/domain/practice/redux/types";
import { dispatchSetTargetPractice } from "@/domain/practice/redux/actions";
import { PatientForm } from "@/domain/patient/view/form/PatientForm";
import { Header } from "@/components/Header";
import { ModelCollection } from "@/library/model";
import { Iterator } from "@/library/iteration";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { RCAResourceActions } from "@/library/core/config/actions";
import { setProvidersThunk } from "@/domain/provider/redux/provider.middleware";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { ProviderSortedState } from "@/domain/provider/redux/types";
import { SortQuery } from "@/components/table/head/sortable";
import { ProviderTable } from "./ProviderTable";
import { ProviderFormComponent } from "./form/form.component";
import RSModal from "rsuite/Modal";
import cx from "clsx";
import styles from "@/domain/patient/view/PatientsPage/styles.module.scss";
import Button from "rsuite/Button";
import { ProviderRules } from "@/domain/rules/view/ProviderRules";

type ProviderProps = {
  selectedOrganization: Organization;
  //provider props
  providers: Provider[];
  setProviders: (
    providerModels: ProviderModel[]
  ) => (dispatch: SetProviderDispatch) => Promise<void>;
  setProvidersPaginationConfig: (paginationConfig: PaginationConfig) => {
    type: string;
    payload: PaginationConfig;
  };
  setProviderPageLimit: (providerPageLimit: number) => {
    type: string;
    payload: number;
  };
  paginationConfig: PaginationConfig; // change name to providerPaginationConfig
  providerPageLimit: number;
  selectedProviders: Provider[];
  filters: ProviderFilters;

  deselectAllProviders: () => { type: string };
  selectAllProviders: () => { type: string };
  targetProviderId: string;

  // practices props
  selectedPractices: Practice[];
  setProviderFilters: (filters: {}) => {
    type: string;
    payload: ProviderFilters;
  };
  setTargetPractice: (id: PracticeId) => { type: string; payload: PracticeId };

  displayName: string;
  hideProvider: boolean;
  toggleProvider: () => void;
  onManageAssignmentClick: (title: string, type: string, id: string) => void;

  sortedState: ProviderSortedState;

  setProviderSortedState: (sortedState: ProviderSortedState) => {
    type: string;
    payload: ProviderSortedState;
  };
};

type PracticeComponentState = {
  paginationConfig: PaginationConfig;
  search: string;

  assignServiceLevel: boolean;
  // Patient modal config
  patientModalConfig: {
    show: boolean;
    title: string;
    modalButton: string;
    assignServiceLevelButton: string;
    resourceAction: RCAResourceActions | undefined;
  };

  // Provider modal config
  providerModalConfig: {
    show: boolean;
    title: string;
    modalButton: string;
    resourceAction: RCAResourceActions | undefined;
  };
  firstTimeLoad: boolean;
  reset: boolean;
  formik: any;
  onSubmitForm: () => void;
  showOverrideModal: boolean;
};

class Component extends React.Component<ProviderProps, PracticeComponentState> {
  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      paginationConfig: {
        totalResources: 0,
        currentPage: 0,
        lastPage: 0,
      },
      search: "",
      assignServiceLevel: false,

      patientModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        assignServiceLevelButton: "",
        resourceAction: undefined,
      },

      providerModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
      },
      firstTimeLoad: true,
      reset: true,
      formik: undefined,
      onSubmitForm: () => {},
      showOverrideModal: false,
    };
  }

  async componentDidUpdate(prevProps: ProviderProps) {
    if (
      prevProps.selectedPractices.length !== this.props.selectedPractices.length
    ) {
      await this.execSearch({});
    }
    if (
      prevProps.selectedProviders.length !== this.props.selectedProviders.length
    ) {
      await this.execSearch({});
    }
  }

  attachPatientCount = async (providers: Provider[]): Promise<Provider[]> => {
    return await Promise.all(
      providers.map(async (provider: Provider) => {
        const patientCollection: ModelCollection<PatientModel, Patient> =
          PatientModel.makePatientCollection();
        patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
        patientCollection.withParams({ providerIds: [provider.id] });
        const iterator: Iterator<PatientModel, Patient> =
          await patientCollection.getMany();
        const patients: PatientModel[] = await iterator.getAll();
        provider.patients = patients.length;
        return provider;
      })
    );
  };

  execSearch = async (query: ProviderQuery) => {
    const {
      setProviders,
      setProvidersPaginationConfig,
      selectedOrganization,
      sortedState,
    } = this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });
    }

    LoadingIndicator.fire.show();

    const providerCollection: ModelCollection<ProviderModel, Provider> =
      ProviderModel.makeProviderCollection();
    providerCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    const { sortedColumnIndex, ...sortQuery } = sortedState;

    const allOrganizationPractices = (
      await PracticeModel.fetchByOrganizationIds([
        selectedOrganization._meta?.id!,
      ])
    ).map((practiceModel) => practiceModel.pluck("_meta")?.id);

    const paginationConfig: PaginationConfig = await providerCollection.fetch({
      ...query,
      limit: this.props.providerPageLimit,
      includePatientCount: true,
      ...{ name: this.state.search },
      ...(!!this.props.selectedPractices.length
        ? {
            practiceIds: this.props.selectedPractices.map(
              (practice: Practice) => practice.id
            ),
          }
        : allOrganizationPractices.length
        ? { practiceIds: allOrganizationPractices }
        : {}),
      ...this.props.filters,
      ...(!!this.props.selectedProviders.length
        ? {
            ids: this.props.selectedProviders.map(
              (provider: Provider) => provider.id
            ),
          }
        : {}),
      ...sortQuery,
    });

    await setProviders(providerCollection.container);
    await setProvidersPaginationConfig(paginationConfig);
    providerCollection.trigger(MODEL_SYNCED);
  };

  setPageLimit = async (limit: number) => {
    this.props.setProviderPageLimit(limit);
    await this.execSearch({});
  };

  onPatientModalClose = () => {
    this.setState({
      patientModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        assignServiceLevelButton: "",
        resourceAction: undefined,
      },
    });
  };

  onProviderModalClose = () => {
    this.setState({
      providerModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
      },
    });
  };

  onManageOverridesModalClose = () => {
    this.setState({
      showOverrideModal: false,
    });
  };

  onAddProvider = () => {
    this.props.setTargetPractice(this.props.selectedPractices[0].id as string);
    this.setState({
      providerModalConfig: {
        show: true,
        title: "Create Provider",
        modalButton: "Create",
        resourceAction: RCAResourceActions.Create,
      },
    });
  };

  onEditProvider = () => {
    this.setState({
      providerModalConfig: {
        show: true,
        title: "Edit Provider",
        modalButton: "Save Changes",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
      },
    });
  };

  onCreatePatient = () => {
    this.setState({
      patientModalConfig: {
        show: true,
        title: "Create Patient",
        modalButton: "Create",
        assignServiceLevelButton: "Create and Assign Service Level",
        resourceAction: RCAResourceActions.Create,
      },
    });
  };

  onEditPatient = () => {
    this.setState({
      assignServiceLevel: false,
      patientModalConfig: {
        show: true,
        title: "Edit Patient",
        modalButton: "Save Changes",
        assignServiceLevelButton: "",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
      },
    });
  };
  onAssignServiceLevel = () => {
    this.setState({
      assignServiceLevel: true,
    });
  };

  onManageOverrides = () => {
    this.setState({
      showOverrideModal: true,
    });
  };

  setFormik = (formik: any) => this.setState({ formik });

  renderPatientFormActions = () => {
    const { formik, patientModalConfig, showOverrideModal } = this.state;

    return (
      <div
        className={cx(styles.patientFormActions, "d-flex justify-content-end")}
      >
        <Button
          type="button"
          appearance="subtle"
          onClick={() => {
            this.onPatientModalClose();
            formik?.resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          appearance="primary"
          disabled={formik?.isSubmitting}
          onClick={() => {
            formik?.submitForm();
          }}
          style={{ marginLeft: 8 }}
        >
          {patientModalConfig?.modalButton}
        </Button>
        {patientModalConfig?.modalButton === "Create" ? (
          <Button
            type="submit"
            appearance="primary"
            disabled={formik?.isSubmitting}
            onClick={() => {
              this.onAssignServiceLevel();
              formik?.submitForm();
            }}
            style={{ marginLeft: 8 }}
          >
            {patientModalConfig?.assignServiceLevelButton}
          </Button>
        ) : null}
      </div>
    );
  };

  render() {
    const { providers } = this.props;

    return (
      <div className="card-body">
        <Header
          entity={{
            name: "provider",
            displayName: this.props.displayName,
            selectedEntityLength: this.props.providers.length,
            totalEntityLength: this.props.paginationConfig.totalResources,
          }}
          button={{
            create: {
              onCreate: this.onAddProvider,
            },
            toggleCollapse: {
              onToggle: this.props.toggleProvider,
              collapsed: this.props.hideProvider,
              shouldDisplay: true,
            },
          }}
          table={{ pageLimit: this.props.providerPageLimit }}
          search={{
            onSearch: this.execSearch,
            searchStr: this.state.search,
            filterStr: FILTER_STRING,
            filters: { status: undefined },
            placeholder: "Enter provider's first or last name",
            sortedState: {
              direction: undefined,
              sortBy: undefined,
            } as SortQuery,
          }}
          filter={{
            onResetState: (newState: {}) => this.setState(newState),
            onResetFilters: this.props.setProviderFilters,
            onResetSortedState: this.props.setProviderSortedState,
          }}
        />

        <div
          id="provider-list"
          className={`container-fluid collapse ${
            this.props.hideProvider ? "hide" : "show"
          }`}
        >
          <div className="row" />
          <ProviderTable
            pageLimit={this.props.providerPageLimit}
            paginationConfig={this.props.paginationConfig}
            onPageLimitChange={this.setPageLimit}
            data={providers}
            execSearch={this.execSearch}
            onCreatePatient={this.onCreatePatient}
            onEditProvider={this.onEditProvider}
            reset={this.state.reset}
            onManageAssignmentClick={this.props.onManageAssignmentClick}
            onManageOverrides={this.onManageOverrides}
          />

          <RSModal
            size="full"
            backdrop="static"
            overflow={true}
            style={{ width: "90vw", paddingLeft: "5vw" }}
            open={this.state.patientModalConfig.show}
            onClose={this.onPatientModalClose}
          >
            <RSModal.Header closeButton>
              <RSModal.Title>
                {this.state.patientModalConfig.title}
              </RSModal.Title>
            </RSModal.Header>
            <RSModal.Body>
              <PatientForm
                button={this.state.patientModalConfig.modalButton}
                handleClose={this.onPatientModalClose}
                resourceAction={
                  this.state.patientModalConfig
                    .resourceAction as RCAResourceActions
                }
                onSetFormik={(formik: any) => {
                  this.setFormik(formik);
                }}
                assignServiceLevel={this.state.assignServiceLevel}
                onEditPatient={this.onEditPatient}
              />

              {this.renderPatientFormActions()}
            </RSModal.Body>
          </RSModal>

          <Modal
            handleClose={this.onProviderModalClose}
            show={this.state.providerModalConfig.show}
            heading={this.state.providerModalConfig.title}
            button={this.state.providerModalConfig.modalButton}
          >
            {this.state.providerModalConfig.show && (
              <ProviderFormComponent
                button={this.state.providerModalConfig.modalButton}
                handleClose={this.onProviderModalClose}
                resourceAction={this.state.providerModalConfig.resourceAction}
              />
            )}
          </Modal>
          <RSModal
            size="lg"
            backdrop
            overflow={false}
            open={this.state.showOverrideModal}
            onClose={this.onManageOverridesModalClose}
          >
            <RSModal.Header closeButton>
              <RSModal.Title>Manage Parameter Overrides</RSModal.Title>
            </RSModal.Header>
            <RSModal.Body>
              <ProviderRules selectedProviderId={this.props.targetProviderId} />
            </RSModal.Body>
          </RSModal>
        </div>
      </div>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => ({
  setProviders: (
    providerModels: ProviderModel[]
  ): ((dispatch: SetProviderDispatch) => Promise<void>) =>
    dispatch(setProvidersThunk(providerModels)),
  setProviderPageLimit: (
    pageLimit: number
  ): { type: string; payload: number } =>
    dispatch(setProviderPageLimit(pageLimit)),
  setProvidersPaginationConfig: (
    paginationConfig: PaginationConfig
  ): { type: string; payload: PaginationConfig } =>
    dispatch(setProvidersPaginationConfig(paginationConfig)),
  deselectAllProviders: () => dispatch(deselectAllProviders()),
  selectAllProviders: () => dispatch(selectAllProviders()),
  setProviderFilters: (filters: any) => dispatch(setProviderFilters(filters)),
  setTargetPractice: (id: PracticeId): any =>
    dispatch(dispatchSetTargetPractice(id)),
  setProviderSortedState: (providerSortedState: ProviderSortedState) =>
    dispatch(setProviderSortedState(providerSortedState)),
});

const mapStateToProps = (state: {
  provider: {
    providers: Provider[];
    selectedProviders: Provider[];
    paginationConfig: PaginationConfig;
    sortedState: ProviderSortedState;
    providerPageLimit: number;
    filters: ProviderFilters;
    targetProvider: string;
  };
  practice: { selectedPractices: Practice[] };
  organization: { selectedOrganization: Organization };
}) => ({
  providers: state.provider.providers,
  paginationConfig: state.provider.paginationConfig,
  providerPageLimit: state.provider.providerPageLimit,
  selectedPractices: state.practice.selectedPractices,
  selectedOrganization: state.organization.selectedOrganization,
  selectedProviders: state.provider.selectedProviders,
  filters: state.provider.filters,
  sortedState: state.provider.sortedState,
  targetProviderId: state.provider.targetProvider,
});

export const ProviderComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
