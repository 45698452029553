import * as Yup from "yup";

import { Validation } from "@/components/form";

export const makeValidation = () =>
  Yup.object({
    id: Validation.id,
    name: Validation.name,
    address: Validation.addressOne,
    city: Validation.city,
    state: Validation.state,
    zip: Validation.zip,
    country: Validation.country,
    contactName: Validation.name,
    contactEmail: Validation.contactEmail,
    contactPosition: Validation.contactPosition,
    contactPhone: Validation.phone,
  });
