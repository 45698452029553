import { format } from "date-fns";
import { DateFormatterArgs } from "./types";
import {
  DATE_FORMAT,
  DATE_AND_TIME_FORMAT,
  MILLISECONDS_PER_MINUTE,
} from "./constants";
import { DateFormatter } from "./helper";

export const dateFormatter = (args: DateFormatterArgs) => {
  if (!args.date) return "";

  const dateFormat = args.includeTime ? DATE_AND_TIME_FORMAT : DATE_FORMAT;
  const formatter = new DateFormatter(dateFormat, args.date);

  if (args.toLocalTimezone) {
    return formatter.toLocalTimezone(args.toLocalTimezone.excludeTimezone);
  }
  if (args.isDateOfBirth) {
    const utcDate = new Date(args.date);
    const dob = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * MILLISECONDS_PER_MINUTE
    );
    return format(dob, args.hasCustomFormatting || dateFormat);
  }
  if (args.convertToUTC) {
    return formatter.toUTC(new Date(args.date));
  }
  if (args.convertToPST) {
    return formatter.toPST(
      new Date(args.date),
      args.convertToPST.includeTimeZone
    );
  }
  return formatter.formatDate(args.date);
};
