import { KeyValue, Tag } from "./types";

export const buildTagType = <T extends {}>(tag: Tag): T => {
  const noGood = ["associatedData", "_meta"];

  const data: Partial<T> = Object.keys(tag)
    .filter((key) => !noGood.includes(key))
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: tag[curr as keyof Tag] as unknown,
      };
    }, {});

  for (let i = 0; i < tag.associatedData?.length; i++) {
    const kv: KeyValue = tag.associatedData[i];

    // @ts-ignore
    data[kv.key] = kv.value;
  }

  // @ts-ignore
  data["id"] = tag._meta?.id;

  return data as T;
};

export const makeAssociatedData = <T extends {}>(
  initialValues: T,
  values: Partial<T>
): KeyValue[] => {
  return Object.keys(initialValues)
    .map((key) => {
      if (key === "id") return null;

      const value = values[key as keyof T];

      return {
        key,
        value,
      };
    })
    .filter((a) => !!a) as KeyValue[];
};
