import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tooltip/dist/react-tooltip.css";
import { enableES5 } from "immer";

import { Dashboard } from "@/pages/dashboard";
import { store, persistor } from "@/redux/store";
import "@/assets/css/anelto.styles.css";
import "@/assets/css/font-awesome-4.7.0/css/font-awesome.min.css";
import "@/styles/variables.scss";
import "@/styles/reset.scss";
import "@/styles/base.scss";
import { AppWrapper } from "@/components/context";

enableES5();

ReactDOM.render(
  <AppWrapper>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Dashboard />
      </PersistGate>
    </Provider>
  </AppWrapper>,
  document.getElementById("root")
);
