import React from "react";
import { connect } from "react-redux";
import { User, UserModel } from "../model";
import { Notification } from "../../../components/notification/notification";
import {
  ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "../../../library/constants";
import { RCAResponseErrorParser } from "../../../library/error/parser/rca.error.parser";
import { PaginationConfig, UsersFilter } from "../../../library/types";
import { SortQuery } from "../../../components/table/head/sortable";
import { Organization } from "../../organization/model/types";
import { setTargetUser, setUsersSearchFilters } from "../redux";
import { dispatchSelectOrganization } from "../../organization/redux/actions";

type ResendInviteProps = {
  selectedUser: User;
  modalContent: string;
  onModalClose: () => void;
};

type ResendInviteState = {
  disableContinueButton: boolean;
};

class Component extends React.Component<ResendInviteProps, ResendInviteState> {
  constructor(props: ResendInviteProps) {
    super(props);
    this.state = {
      disableContinueButton: false,
    };
  }

  resendUserInvite = async () => {
    try {
      await UserModel.resendUserInvite(this.props.selectedUser.id!);
      Notification.notify(
        SUCCESS,
        "Invite sent successfully to " + this.props.selectedUser.email + "."
      );
      this.props.onModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
    }
  };

  resetUserPassword = async () => {
    try {
      await UserModel.resetUserPassword(this.props.selectedUser.id!);
      Notification.notify(
        SUCCESS,
        "Reset password link successfully sent to " +
          this.props.selectedUser.email +
          "."
      );
      this.props.onModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
    }
  };

  render() {
    return (
      <>
        <p
          style={{
            padding: "15px",
            fontSize: "17px",
          }}
        >
          {this.props.modalContent} {this.props.selectedUser.email}.
        </p>
        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => this.props.onModalClose()}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={this.state.disableContinueButton}
            onClick={async () => {
              this.setState({
                disableContinueButton: true,
              });
              const hasPendingStatus = UserModel.make(
                this.props.selectedUser
              ).hasPendingStatus;
              if (hasPendingStatus) {
                await this.resendUserInvite();
              } else {
                await this.resetUserPassword();
              }
              this.setState({
                disableContinueButton: false,
              });
            }}
            className="btn btn-primary mr-2"
          >
            Continue
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: {
  user: {
    users: User[];
    filters: UsersFilter;
    paginationConfig: PaginationConfig;
    userPageLimit: number;
    sortedState: SortQuery;
  };
  organization: { selectedOrganization: Organization };
}) => {
  return {
    paginationConfig: state.user.paginationConfig,
    pageLimit: state.user.userPageLimit,
    usersFilter: state.user.filters,
    sortedState: state.user.sortedState,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setUsersSearchFilters: (userFilter: UsersFilter) =>
      dispatch(setUsersSearchFilters(userFilter)),
    setTargetUser: (userId: string) => dispatch(setTargetUser(userId)),
    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
  };
};

export const ResendInviteComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
