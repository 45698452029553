import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RCAResourceActions } from "@/library/core/config/actions";
import { Patient } from "@/domain/patient/model/types";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
} from "@/domain/patient/redux/actions";
import { PatientForm } from "@/domain/patient/view/form/PatientForm";
import { TagModel } from "@/domain/tags/model";
import { dispatchSetLocationTags } from "@/domain/tags/redux/actions";
import { Tag } from "@/domain/tags/model/types";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { ModelCollection } from "@/library/model";
import { Iterator } from "@/library/iteration/iterator";
import { MODEL_SYNCED } from "@/library/constants";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";

export type Props = {};

export const PatientDetailTab: React.FC<Props> = () => {
  const selectedPatient = useSelector(selectFirstPatient);

  const dispatch = useDispatch();
  const practices = useSelector(selectPractices);
  const locationTags = useSelector(selectLocationTags);
  const setSelectedPatient = (patient: Patient) =>
    dispatch(dispatchSelectPatient(patient));
  const deselectAllPatients = () => dispatch(dispatchDeselectAllPatients());
  const setLocationTags = (tags: Tag[]) =>
    dispatch(dispatchSetLocationTags(tags));

  const onFormSubmission = (patient?: Patient) => {
    if (patient && selectedPatient) {
      const updatedPatient = { ...selectedPatient, ...patient };
      deselectAllPatients();
      setSelectedPatient(updatedPatient);
    }
  };

  useEffect(() => {
    (async () => {
      if (!locationTags.length) {
        setLocationTags(
          await TagModel.fetchAllTagsWithIterator({
            type: "location",
            practiceIds: practices.map((practice) => practice.id),
          })
        );
      }
    })();
  }, [practices.length, locationTags.length]);

  if (!selectedPatient) return null;

  return (
    <PatientForm
      button="Save Changes"
      handleClose={onFormSubmission}
      isPatientDetail={true}
      resourceAction={RCAResourceActions.ModifyBasicInfo}
      targetedPatient={selectedPatient}
    />
  );
};
