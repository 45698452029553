import { Program } from "@/domain/program/model/types";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import {
  AssessmentTemplate,
  AssessmentTemplateMetadata,
} from "@/domain/assessment/model/types";
import { ProgramModel } from "@/domain/program/model";

export const fetchPrograms = async (): Promise<Program[]> => {
  const programs: Program[] = await ProgramModel.fetchAllProgramsWithIterator(
    {}
  );
  return programs.filter((program: Program, index: number, self: Program[]) => {
    if (program.organizationId) {
      return true; // Always include programs with organizationId
    }

    // Exclude program if there's another program with the same name but has an organizationId
    return !self.some(
      (otherProgram: Program) =>
        otherProgram.name === program.name && otherProgram.organizationId
    );
  });
};

export const fetchProgramsForOrganization = async (
  organizationId: string
): Promise<Program[]> => {
  const programs: Program[] = await ProgramModel.fetchAllProgramsWithIterator(
    {}
  );
  const eligiblePrograms: Program[] = [];

  programs.forEach((program: Program) => {
    if (!program.organizationId) {
      // This is a global program
      const clonedByGivenOrg = programs.find(
        (p) => p.name === program.name && p.organizationId === organizationId
      );

      if (clonedByGivenOrg) {
        eligiblePrograms.push(clonedByGivenOrg);
      } else {
        eligiblePrograms.push(program);
      }
    }
  });

  return eligiblePrograms;
};

export const attachMissingMetadataFields = (template: AssessmentTemplate) => {
  if (template.metadata && template.metadata.types === null) {
    if (
      template.metadata.frequencies &&
      template.metadata.enrollmentDays &&
      template.metadata.frequencies[0] === "ONCE" &&
      template.metadata.enrollmentDays[0] === 1
    ) {
      template.metadata.types = ["WELCOME"];
    }

    if (
      template.metadata.frequencies &&
      template.metadata.frequencies[0] === "REPEAT"
    ) {
      template.metadata.types = ["DAY OF WEEK"];
    }
  }

  if (template.metadata && template.metadata.preferredDays === null) {
    template.metadata.preferredDays = [];
  }
};
export const resolveAssessmentTemplate = async (
  id: string,
  option?: AssessmentTemplateMetadata
): Promise<AssessmentTemplate> => {
  const response = await Http().get(
    `${RemoteCareAPISync.host}/assessment-templates/${id}`
  );
  const template: AssessmentTemplate = response.data;
  attachMissingMetadataFields(template);
  return template as AssessmentTemplate;
};
