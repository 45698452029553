import React, { LegacyRef, RefObject } from "react";
import cx from "clsx";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { logo } from "@/assets/icons/anelto";
import { User, UserParentTypes } from "@/domain/user/model";
import { Guard } from "@/library/guard/Guard";
import { Organization } from "@/domain/organization/model/types";
import { IdleDetector } from "@/components/IdleDetector";
import RouteChangeDetector, {
  HasPathname,
} from "@/components/Sidebar/routeChangeDetector.component";
import {
  dashboard,
  practice,
  organization,
  users,
  reports,
  patients,
  kits,
} from "@/assets/icons/dashboard";
import { ReactComponent as BackSvg } from "@/assets/icons/dashboard/back.svg";
import { ReactComponent as MenuSvg } from "@/assets/icons/dashboard/hamburger.svg";
import { ReactComponent as LogoutSvg } from "@/assets/icons/dashboard/sign-out.svg";
import { ReactComponent as ProfileSvg } from "@/assets/icons/dashboard/profile.svg";
import { LOGOUT } from "@/library/constants";
import { RootState } from "@/types";

import styles from "./styles.module.scss";
import { AppContext } from "@/components/context";

const collapsedWidth = "88px";

type OwnProps = {};
type Props = {
  currentUser: User | null;
  selectedOrganization: Organization | undefined;
  logout: () => void;
};

type OrganizationComponentState = {
  activeLink: string;
  width: string;
  linkStyle: React.CSSProperties;
  menuOpen: boolean;
  showTooltip: boolean;
};

class Component extends React.Component<Props, OrganizationComponentState> {
  btn: LegacyRef<HTMLElement> | any = React.createRef();
  sidebar: RefObject<HTMLDivElement> | undefined = React.createRef();
  searchBtn: RefObject<HTMLDivElement> | undefined = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      width: collapsedWidth,
      activeLink: this.getActiveLink(),
      linkStyle: { opacity: 0 },
      menuOpen: false,
      showTooltip: false,
    };
  }

  getActiveLink = () => {
    const activeRoute = window.location.pathname.split("/");
    if (activeRoute[1] === "organizations" && activeRoute.length > 2) {
      return "practices";
    }
    return window.location.pathname.split("/").join("");
  };

  onbtnClick = () => {
    const { menuOpen } = this.state;

    if (!menuOpen) {
      this.setState({
        width: "240px",
        linkStyle: { opacity: 1 },
        menuOpen: true,
      });
    } else {
      this.setState({
        width: collapsedWidth,
        linkStyle: { opacity: 0 },
        menuOpen: false,
      });
    }
  };

  getLinkClass = (linkName: string) => {
    return this.state.activeLink === linkName ? "active-link" : "inactive-link";
  };

  onLinkChange = (active: string) => {
    this.setState({ activeLink: active });
  };

  handleMouseOver = () => this.setState({ showTooltip: true });
  handleMouseOut = () => this.setState({ showTooltip: false });

  handleLogout = () => {
    this.props.logout();

    setTimeout(() => window.location.assign(`${_env_.AUTH_URL}/logout`), 10);
  };

  render() {
    const { menuOpen, showTooltip } = this.state;

    const { _env_ } = window as typeof window & {
      _env_: Record<string, string>;
    };

    return (
      <AppContext.Consumer>
        {({ isIdleDetectorOn }) => {
          // console.log("isIdleDetectorOn: ", isIdleDetectorOn);
          return (
            <div
              ref={this.sidebar}
              style={{ width: this.state.width }}
              className={cx(
                styles.sidebar,
                menuOpen && styles.active,
                menuOpen && "active",
                "d-md-block"
              )}
              onMouseOver={this.handleMouseOver}
              onFocus={this.handleMouseOut}
              onMouseOut={this.handleMouseOver}
              onBlur={this.handleMouseOut}
            >
              <RouteChangeDetector
                getCurrentRoute={(currentRoute: HasPathname) => {
                  const activeRoute = currentRoute?.pathname?.split("/") || [];
                  let route = activeRoute[1];
                  if (
                    activeRoute[1] === "organizations" &&
                    activeRoute.length > 2
                  ) {
                    route = "practices";
                  }
                  const routeName = route || this.getActiveLink();
                  this.setState({ activeLink: routeName });
                }}
              />
              <img
                className="av_list img-fluid w-100"
                style={{ padding: 4, marginTop: 7 }}
                src={logo}
                alt="RemoteCare"
              />
              <button
                id="hamburgerBtn"
                onClick={this.onbtnClick}
                className={cx(
                  styles.menuButton,
                  menuOpen ? styles["close-btn"] : styles["hamburger-btn"]
                )}
              >
                {menuOpen ? <BackSvg /> : <MenuSvg />}
              </button>
              <ul className="nav_list">
                <Link
                  to={`/patients?${
                    this.props.selectedOrganization
                      ? `organizationId=${this.props.selectedOrganization?._meta?.id}`
                      : ""
                  }`}
                  className={styles.link}
                  onClick={() => this.onLinkChange("patients")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "patients"
                        )}`}
                        src={patients}
                        alt="patients"
                        style={{ padding: 13 }}
                      />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("patients")
                        )}
                        style={this.state.linkStyle}
                      >
                        Patients
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Patients
                      </span>
                    )}
                  </li>
                </Link>
                <Link
                  to="/user-dashboard"
                  className={styles.link}
                  onClick={() => this.onLinkChange("user-dashboard")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        style={{ padding: 13 }}
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "user-dashboard"
                        )}`}
                        src={dashboard}
                        alt="user dashbpard"
                      />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("user-dashboard")
                        )}
                        style={this.state.linkStyle}
                      >
                        Dashboards
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Dashboards
                      </span>
                    )}
                  </li>
                </Link>
                <Link
                  to="/reports"
                  className={styles.link}
                  onClick={() => this.onLinkChange("reports")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "reports"
                        )}`}
                        src={reports}
                        alt="reports"
                      />

                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("reports")
                        )}
                        style={this.state.linkStyle}
                      >
                        Reports
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Reports
                      </span>
                    )}
                  </li>
                </Link>
                <Link
                  to="/kits"
                  className={styles.link}
                  onClick={() => this.onLinkChange("kits")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "kits"
                        )}`}
                        src={kits}
                        alt="kits"
                        style={{ padding: 17.5 }}
                      />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("kits")
                        )}
                        style={this.state.linkStyle}
                      >
                        Kits
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Kit Management
                      </span>
                    )}
                  </li>
                </Link>
                <NavLink
                  to="/practices"
                  className={styles.link}
                  onClick={() => this.onLinkChange("practices")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "practices"
                        )}`}
                        src={practice}
                        alt="Practice"
                      />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("practices")
                        )}
                        style={this.state.linkStyle}
                      >
                        Entity/Branch
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Entity/Branch
                      </span>
                    )}
                  </li>
                </NavLink>
                <Link
                  to="/user-management"
                  className={styles.link}
                  onClick={() => this.onLinkChange("user-management")}
                >
                  <li>
                    <div className={styles.link}>
                      <img
                        className={`img-fluid wid-10 ${this.getLinkClass(
                          "user-management"
                        )}`}
                        src={users}
                        alt="user-management"
                      />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("user-management")
                        )}
                        style={this.state.linkStyle}
                      >
                        Users
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        User Management
                      </span>
                    )}
                  </li>
                </Link>
                {Guard.accessLevel(UserParentTypes.Admin).canActivate(
                  this.props.currentUser!
                ) && (
                  <Link
                    to="/organizations"
                    className={styles.link}
                    onClick={() => this.onLinkChange("organizations")}
                  >
                    <li>
                      <div className={styles.link}>
                        <img
                          className={`img-fluid wid-10 ${this.getLinkClass(
                            "organizations"
                          )}`}
                          src={organization}
                          alt="Organization"
                        />
                        <span
                          className={cx(
                            styles.links_name,
                            this.getLinkClass("organizations")
                          )}
                          style={this.state.linkStyle}
                        >
                          Organizations
                        </span>
                      </div>
                      {showTooltip && !menuOpen && (
                        <span
                          className={cx(styles.tooltip, styles["active-link"])}
                        >
                          Organizations
                        </span>
                      )}
                    </li>
                  </Link>
                )}
              </ul>
              <hr />
              <ul className="nav_list">
                <Link to="/profile" className={styles.link}>
                  <li>
                    <div className={styles.link}>
                      <ProfileSvg style={{ minWidth: 62, padding: 14 }} />
                      <span
                        className={cx(
                          styles.links_name,
                          this.getLinkClass("profile")
                        )}
                        style={this.state.linkStyle}
                      >
                        My Profile
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        My Profile
                      </span>
                    )}
                  </li>
                </Link>

                <Link
                  to="/"
                  onClick={this.handleLogout}
                  className={cx(styles.link, styles.noLink, styles.logoutLink)}
                  style={{ marginTop: 8 }}
                >
                  <li>
                    <div
                      className={cx(styles.link, styles.noLink)}
                      style={{ padding: "0 4px" }}
                    >
                      <div className={styles.logoutBtn}>
                        <LogoutSvg style={{ padding: 0, height: 28 }} />
                      </div>
                      <span
                        className={styles.links_name}
                        style={this.state.linkStyle}
                      >
                        Sign out
                      </span>
                    </div>
                    {showTooltip && !menuOpen && (
                      <span
                        className={cx(styles.tooltip, styles["active-link"])}
                      >
                        Sign out
                      </span>
                    )}
                  </li>
                </Link>
              </ul>

              {isIdleDetectorOn && (
                <IdleDetector className={styles.idleContainer} />
              )}
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.user.currentUser,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  logout: () => dispatch({ type: LOGOUT, payload: {} }),
});

export const Sidebar = connect(mapStateToProps, mapDispatchToProps)(Component);
