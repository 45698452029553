import { Dispatch } from "redux";

import { PatientModel } from "@/domain/patient/model";
import {
  AlertScoreColor,
  HealthSnapshot,
  Patient,
  PatientBilling,
  PatientForm,
} from "@/domain/patient/model/types";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Provider, ProviderModel } from "@/domain/provider/model";
import { User } from "@/domain/user/model";
import { store } from "@/redux/store";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ProviderState } from "@/domain/provider/redux/types";
import { setProviders } from "@/domain/provider/redux";
import { NoteModel } from "@/domain/notes/model";
import {
  dispatchCreatePatient,
  dispatchSetAssignmentPatients,
  dispatchSetPatients,
} from "./actions";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import {
  CURRENT_DATE,
  DAYS_LIMIT,
  ERROR,
  SOMETHING_WENT_WRONG,
} from "@/library/constants";
import { PatientNoteType } from "@/library/types/note";
import { AuditModel } from "@/domain/audit/model";
import { AuditEntryType, AuditLog } from "@/domain/audit/model/types";
import { PatientDeviceAudit } from "@/domain/kits/models/device.interface";
import { DateTime } from "luxon";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { Notification } from "@/components/notification/notification";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { uniq } from "lodash";
import { REQUEST_PARAMS_LENGTH_LIMIT } from "@/domain/patient/view/AlertsTable/constants";
import { Note } from "@/domain/notes/model/types";
import { PaginationConfig } from "@/library/types";

/**
 * Outer synchronous function that receives the `patientCollection` parameter:
 * @param patientCollection
 */
export const setPatientsThunk =
  (patientCollection: PatientModel[]) => async (dispatch: Dispatch) => {
    // And then creates and returns the async thunk function:
    if (!patientCollection.length) {
      dispatch(dispatchSetPatients([]));
    }
    const patients = patientCollection.map((patientModel) =>
      patientModel.pluckAll()
    );
    if (patients) {
      const patientsWithParentEntities = await attachParentEntities(patients);
      const patientsWithUsernames = await attachAssignedUserName(
        patientsWithParentEntities
      );
      const patientsWithReimbursableEvents = await attachReimbursableEvents(
        patientsWithUsernames
      );
      dispatch(dispatchSetPatients(patientsWithReimbursableEvents));
    }
  };

/**
 * Outer synchronous function that receives the `patientCollection` parameter:
 * @param patientCollection
 */
export const setAssignmentPatientsThunk =
  (patientCollection: PatientModel[]) => async (dispatch: Dispatch) => {
    // And then creates and returns the async thunk function:
    if (!patientCollection.length) {
      dispatch(dispatchSetAssignmentPatients([]));
    }
    const patients = patientCollection.map((patientModel) =>
      patientModel.pluckAll()
    );
    if (patients) {
      const patientsWithParentEntities = await attachParentEntities(patients);
      const patientsWithUsernames = await attachAssignedUserName(
        patientsWithParentEntities
      );
      dispatch(dispatchSetAssignmentPatients(patientsWithUsernames));
    }
  };

/**
 * Intercept patient's object before saving it to the redux state
 * @param patient
 */
export async function createPatientThunk(patient: Patient): Promise<void> {
  const providerModel = await ProviderModel.sync(patient.providerId);
  const practiceModel = await PracticeModel.sync(
    providerModel.pluck("practiceId") as string
  );
  patient.provider = providerModel.pluckAll();
  patient.practice = practiceModel.pluckAll();
  patient.billing = getPatientBilling();

  store.dispatch(dispatchCreatePatient(patient));
}

/**
 * Get patient billing initial object
 * @returns
 */
export const getPatientBilling = (): PatientBilling => {
  return {
    cpt99453: {
      training: false,
      reimbursableEvents: 0,
    },
    cpt99454: {
      currentDays: 0,
      observedDays: 0,
      reimbursableEvents: 0,
    },
    cpt99457: {
      currentMinutes: 0,
      directPatientContact: false,
      reimbursableEvents: 0,
    },
    cpt99458: {
      reimbursableEvents: 0,
      currentMinutes: 0,
      currentMinuteBuckets: {
        bucket21: 1,
        bucket41: 1,
        bucket61: 1,
      },
    },
  };
};

/**
 * Attach the assigned user's name in the patient objects
 * @param patients
 * @returns patientsWithUserNames
 */

export const attachAssignedUserName = async (
  patients: Patient[]
): Promise<Patient[]> => {
  let userIds = patients
    .filter((patient) => patient.assignedUserId)
    .map((patient) => patient.assignedUserId);
  userIds = uniq(userIds);
  const users = await Promise.all(
    userIds.map(async (id) => {
      try {
        return await Http().get(`${RemoteCareAPISync.host}/users/${id}`);
      } catch (error: any) {
        if (error.response?.status === 403) {
          Notification.notify(
            ERROR,
            RCAResponseErrorParser.parse(error).message()
          );
        } else {
          Notification.notify(ERROR, SOMETHING_WENT_WRONG);
        }
      }
    })
  );
  const resolvedUsers: User[] = users
    .filter((user) => user !== undefined)
    .map((user) => user?.data);

  const resolvedByKey: Record<string, User> = {};
  resolvedUsers.forEach((user, index) => {
    resolvedByKey[userIds[index] as string] = resolvedUsers[index];
  });

  return patients.map((patient) => {
    if (patient.assignedUserId) {
      if (patient.assignedUserId in resolvedByKey) {
        const assignedUser = resolvedByKey[patient.assignedUserId];
        if (assignedUser) {
          patient.assignedUserName = `${assignedUser.firstName} ${assignedUser.lastName}`;
        }
      }
    }
    return patient;
  });
};

/**
 * Attach the Reimbursable events to patient
 * @param patients
 * @returns patientsWithReimbursableEvents
 */

export const attachReimbursableEvents = async (
  patients: Patient[]
): Promise<Patient[]> => {
  const patientsWithReimbursableEvents = patients.map((patient) => {
    const reimbursableEvents = 16;
    const observedDays = patient.billing?.cpt99454.observedDays ?? 0;
    const currentDays = patient.billing?.cpt99454.currentDays ?? 0;
    patient.reimbursableEvents =
      observedDays >= reimbursableEvents
        ? observedDays.toString()
        : reimbursableEvents -
          observedDays +
          " (" +
          observedDays +
          " in " +
          currentDays +
          ") ";
    return patient;
  });
  return patientsWithReimbursableEvents;
};
/**
 * Attaching parent entities of patients in the patient's object
 * @param patients
 * @returns patients with parent entities
 */
export const attachParentEntities = async (
  patients: Patient[]
): Promise<Patient[]> => {
  const practiceState = store.getState().practice.practices;
  const practiceStateIds = practiceState.map(
    (practice: Practice) => practice.id
  );
  let practicesIds: string[] = patients.map(
    (patient: Patient) => patient.practiceId
  );
  if (
    practiceStateIds &&
    practiceStateIds.length &&
    practiceStateIds.length > 0
  ) {
    /**
     * Removing practices ids if they already existed in our redux state
     */
    practicesIds = practicesIds.filter(
      (practiceId) => !practiceStateIds.includes(practiceId)
    );
  }
  /**
   * Sync patient's practices from the server
   */
  const practices: Practice[] = [
    ...(await PracticeModel.syncMany(practicesIds)).map((practice) =>
      practice.pluckAll()
    ),
    ...practiceState,
  ];

  const providerState = (store.getState().provider as ProviderState).providers;
  const providerStateIds = providerState.map((provider) => provider.id);
  let providerIds: string[] = patients.map(
    (patient: Patient) => patient.providerId
  );
  if (
    providerStateIds &&
    providerStateIds.length &&
    providerStateIds.length > 0
  ) {
    /**
     * Removing providers ids if they already existed in our redux state.
     */
    providerIds = providerIds.filter(
      (providerId) => !providerStateIds.includes(providerId)
    );
  }
  /**
   * sync patient's providers from the server
   */
  const providers: Provider[] = [
    ...(await ProviderModel.syncMany(providerIds)).map((provider) =>
      provider.pluckAll()
    ),
    ...providerState,
  ];

  // Get practices by practicesId and attach them to respective patient
  const _patients = patients.map((patient: Patient) => {
    const practice: Practice | undefined = practices.find(
      (practice: Practice) => practice.id === patient.practiceId
    );
    const provider: Provider | undefined = providers.find(
      (provider: Provider) => provider.id === patient.providerId
    );
    return {
      ...patient,
      practice,
      provider,
    };
  });

  return _patients;
};

/**
 * Attaching insurances of patients in the patient's object
 * @param patients
 * @returns patients with patient insurances
 */
export const attachPatientInsurance = async (
  patients: Patient[]
): Promise<Patient[]> => {
  const patientsWithInsurances = patients.map((patient) => {
    const primaryInsurance = patient.insurance?.filter(
      (insurance: { type: string }) => insurance.type === "PRIMARY"
    );
    const secondaryInsurance = patient.insurance?.filter(
      (insurance: { type: string }) => insurance.type === "SECONDARY"
    );
    if (primaryInsurance?.length) {
      const insurance = primaryInsurance[0];
      if (insurance) {
        const company = insurance.company ?? "";
        const groupNumber = insurance.groupNumber ?? "";
        const policyNumber = insurance.policyNumber ?? "";
        const expirationDate = insurance.expirationDate ?? "";

        patient.primaryInsurance = [
          "Company: " + company,
          "Group: " + groupNumber,
          "Policy: " + policyNumber,
          "Expires: " + expirationDate,
        ];
      }
    }
    if (secondaryInsurance?.length) {
      const insurance = secondaryInsurance[0];
      if (insurance) {
        const company = insurance.company ?? "";
        const groupNumber = insurance.groupNumber ?? "";
        const policyNumber = insurance.policyNumber ?? "";
        const expirationDate = insurance.expirationDate ?? "";

        patient.secondaryInsurance = [
          "Company: " + company,
          "Group: " + groupNumber,
          "Policy: " + policyNumber,
          "Expires: " + expirationDate,
        ];
      }
    }

    return patient;
  });
  return patientsWithInsurances;
};

/**
 * Attaching diagnosis of patients in the patient's object
 * @param patients
 * @returns patients with diagnosis
 */
export const attachPatientConditions = async (
  patients: Patient[]
): Promise<Patient[]> => {
  const patientsWithConditions = patients.map((patient) => {
    const primaryCondition = patient.conditions.filter(
      (dx: { type: string }) => dx.type === "PRIMARY"
    );
    const secondaryCondition = patient.conditions.filter(
      (dx: { type: string }) => dx.type === "SECONDARY"
    );
    if (primaryCondition.length) {
      const condition = primaryCondition[0];
      if (condition) {
        const displayName = condition.displayName;
        patient.primaryCondition = displayName ?? "-";
      }
    }

    if (secondaryCondition.length) {
      const condition = secondaryCondition[0];
      if (condition) {
        const displayName = condition.displayName;
        patient.secondaryCondition = displayName ?? "-";
      }
    }
    return patient;
  });
  return patientsWithConditions;
};

/**
 * function will increment the patients count in the selected provider object and update the redux state of provider
 * @param values
 * @param resourceAction
 */
export const incrementPatientCountInProvider = (
  values: PatientForm,
  resourceAction: RCAResourceActions
): void => {
  if (resourceAction === RCAResourceActions.Create) {
    const providerState: ProviderState = store.getState().provider;
    const providerToBeInceremented = providerState.providers.find(
      (provider: Provider) => provider.id === values.providerId
    );
    if (providerToBeInceremented) {
      const changedProviders = providerState.providers.map(
        (provider: Provider) => {
          if (provider.id === providerToBeInceremented.id) {
            provider = {
              ...provider,
              patients: (provider.patients as number) + 1,
            };
          }
          return provider;
        }
      );
      store.dispatch(
        setProviders(changedProviders.map((provider: Provider) => provider))
      );
    }
  }
};

/**
 * Calculating patient CPT99454 days and remaining days
 * @param currentDays
 * @param observedDays
 * @returns
 */
export const getPatientReimbursableEvents = (
  billing: PatientBilling | undefined
): string => {
  if (billing) {
    const { observedDays, currentDays } = billing.cpt99454;
    return `${observedDays} in ${currentDays} (${29 - currentDays})`;
  }
  return "";
};

/**
 * Attach parent notes to patient
 *
 * @param patients
 * @returns
 */
export const attachPatientNotes = async (
  patients: Patient[],
  selectedMonth?: Date
) => {
  const date = selectedMonth || new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDate = new Date(y, m, 1);
  const endDate = new Date(y, m + 1, 0);

  const startDateTime = convertDateToLocalTimezone(firstDate).startOf("day");
  const endDateTime = convertDateToLocalTimezone(endDate).endOf("day");

  const patientIds = patients.map((patient) => {
    return patient._meta?.id!;
  });

  let splitPatientIds = [];
  let [patientNotes, paginationConfig] = [[] as Note[], {} as PaginationConfig];
  if (patientIds.length > REQUEST_PARAMS_LENGTH_LIMIT) {
    while (patientIds.length) {
      splitPatientIds.push(patientIds.splice(0, REQUEST_PARAMS_LENGTH_LIMIT));
    }
  }
  if (splitPatientIds.length > 0) {
    await Promise.all(
      splitPatientIds.map(async (chunkedIds) => {
        const [chunkedPatientNotes, paginationConfig] =
          await NoteModel.getNotes(
            chunkedIds,
            startDateTime.toUTC().toString(),
            endDateTime.toUTC().toString(),
            undefined,
            { limit: 500 }
          );
        chunkedPatientNotes.map((note) => patientNotes.push(note));
      })
    );
  } else {
    [patientNotes, paginationConfig] = await NoteModel.getNotes(
      patientIds,
      startDateTime.toUTC().toString(),
      endDateTime.toUTC().toString(),
      undefined,
      { limit: 500 }
    );
  }

  return patients.map((patient: Patient) => {
    return {
      ...patient,
      notes: patientNotes.filter((note) => note.patientId === patient.id),
    };
  });
};

const getStartDate = (): Date => {
  const datetime = convertDateToLocalTimezone(CURRENT_DATE);
  return datetime.minus({ days: DAYS_LIMIT }).toJSDate();
};

/**
 * Attach patient request notes
 */
export const attachPatientRequestNotes = async (patients: Patient[]) => {
  const startDateTime = convertDateToLocalTimezone(getStartDate()).startOf(
    "day"
  );
  const endDateTime = convertDateToLocalTimezone(new Date()).endOf("day");
  const patientIds = patients.map((patient) => {
    return patient._meta?.id!;
  });
  const [patientNotes, paginationConfig] = await NoteModel.getNotes(
    patientIds,
    startDateTime.toISO(),
    endDateTime.toISO(),
    [PatientNoteType.PatientRequest]
  );
  return patients.map((patient: Patient) => {
    return {
      ...patient,
      notes: patientNotes.filter(
        (note) => note.patientId === patient._meta?.id!
      ),
    };
  });
};

export const attachPatientDeviceHistory = async (
  patients: Patient[],
  selectedMonth?: Date
) => {
  let startDateTime: DateTime | undefined, endDateTime: DateTime | undefined;
  const date = selectedMonth && new Date(selectedMonth),
    reportYear = date?.getFullYear(),
    reportMonth = date?.getMonth();
  if (reportMonth && reportYear) {
    startDateTime =
      CURRENT_DATE.getMonth() === reportMonth
        ? undefined
        : convertDateToLocalTimezone(
            new Date(reportYear, reportMonth, 1)
          ).startOf("day");
    endDateTime =
      CURRENT_DATE.getMonth() === reportMonth
        ? undefined
        : convertDateToLocalTimezone(
            new Date(reportYear, reportMonth + 1, 0)
          ).endOf("day");
  }
  const patientIds = patients.map((patient: Patient) => {
    return patient._meta?.id!;
  });

  const splitPatientIds = [];
  let [equipmentHist, paginationConfig] = [
    [] as AuditLog[],
    {} as PaginationConfig,
  ];
  if (patientIds.length > REQUEST_PARAMS_LENGTH_LIMIT) {
    while (patientIds.length) {
      splitPatientIds.push(patientIds.splice(0, REQUEST_PARAMS_LENGTH_LIMIT));
    }
  }
  if (splitPatientIds.length > 0) {
    await Promise.all(
      splitPatientIds.map(async (chunkedIds) => {
        const [chunkedEquipmentHist, paginationConfig] =
          await AuditModel.getAuditEntryLog({
            type: AuditEntryType.DeviceAssignment,
            patientId: chunkedIds,
            startDate: startDateTime?.toUTC().toString(),
            endDate: endDateTime?.toUTC().toString(),
            limit: 500,
          });
        chunkedEquipmentHist.map((deviceEntry) =>
          equipmentHist.push(deviceEntry)
        );
      })
    );
  } else {
    [equipmentHist, paginationConfig] = await AuditModel.getAuditEntryLog({
      type: AuditEntryType.DeviceAssignment,
      patientId: patientIds,
      startDate: startDateTime?.toUTC().toString(),
      endDate: endDateTime?.toUTC().toString(),
      limit: 500,
    });
  }

  return patients.map((patient: Patient) => {
    return {
      ...patient,
      deviceHistory: equipmentHist.filter(
        (e) => e.patientId === patient._meta?.id!
      ) as unknown as PatientDeviceAudit[],
    };
  });
};

/**
 * Get color of patient status indicator
 *
 * @param patientHealth
 * @returns
 */
export const getPatientStatusIndicator = (
  patientHealth: HealthSnapshot | undefined
): AlertScoreColor => {
  const HOURS_LIMIT = 24;
  const SEVERITY_YELLOW = 2;
  const SEVERITY_RED = 5;
  if (!patientHealth) return AlertScoreColor.Green;

  const { alertScore, hasIncompleteNotes, hasDeviceCommunication } =
    patientHealth;

  if (!hasDeviceCommunication) {
    return AlertScoreColor.Black;
  }

  if (alertScore !== undefined && hasIncompleteNotes) {
    if (alertScore >= SEVERITY_RED) {
      return AlertScoreColor.Red;
    }
    if (alertScore >= SEVERITY_YELLOW) {
      return AlertScoreColor.Yellow;
    }
    return AlertScoreColor.White;
  }

  return AlertScoreColor.Green;
};
