import React, { FC, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import CancelFilled from "@mui/icons-material/Cancel";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconButton from "@mui/material/IconButton";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";

import { Patient } from "@/domain/patient/model/types";
import {
  Automation,
  DateTimeTrigger,
  ScheduleTrigger,
  Trigger,
  Action,
  ReminderForm,
  VirtualVisitAction,
  ReminderAction,
} from "@/domain/automation/model/types";
import {
  AutomationPayload,
  Frequency,
  ReminderType,
  VALIDATION_SCHEMA,
} from "@/domain/patient/view/BannerMenu/Reminders/constants";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { Notification } from "@/components/notification/notification";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { ERROR, SUCCESS } from "@/library/constants";
import { Modal } from "@/components/modal";
import { TextInput } from "@/components/_inputs/TextInput";

import globalStyles from "@/styles/globals.module.scss";

interface Props {
  patient: Patient;
  reminderAutomation: Automation | ReminderForm;
  deleteReminder: (index: number) => void;
  removeAutomation?: (id: string) => void;
  updateReminder: (
    index: number,
    value: ReminderForm,
    isAutomation: boolean
  ) => void;
  index: number;
  getDescription: (type: string) => string;
  updateCurrentPatient: () => Promise<void>;
  isAutomation: boolean;
}

export const ViewReminders: FC<Props> = ({
  index,
  patient,
  reminderAutomation,
  deleteReminder,
  updateReminder,
  getDescription,
  updateCurrentPatient,
  isAutomation,
  removeAutomation,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [disableAcceptBtn, setDisableAcceptBtn] = useState<boolean>(false);

  const handleSubmit = async (
    values: ReminderForm,
    { setSubmitting }: FormikHelpers<ReminderForm>
  ) => {
    if (
      values.action &&
      "message" in values.action &&
      values.action.message === "" &&
      values.description
    ) {
      values.action = {
        ...values.action,
        message: values.description,
      };
    }
    const patientAutomations = patient.automations;
    const payload: AutomationPayload = {
      set: {},
      delete: [],
      push: [],
    };
    setSubmitting(true);
    if ("id" in (reminderAutomation as Automation)) {
      const id: string = (reminderAutomation as Automation).id as string;
      payload.set[id] = {
        triggers: [values.trigger],
        actions: [values.action],
      } as Automation;
    } else {
      const newAutomation = {
        triggers: [values.trigger],
        actions: [values.action],
      } as Automation;
      payload.push = [newAutomation];
    }
    try {
      LoadingIndicator.fire.show();
      await Http().post(
        `${RemoteCareAPISync.host}/patients/${patient._meta?.id}/actions/modify`,
        {
          automations: payload,
        }
      );
      setSubmitting && setSubmitting(false);
      Notification.notify(SUCCESS, "Reminder added successfully");
      updateReminder(index, { saved: true }, isAutomation);
    } catch (e) {
      Notification.notify(
        ERROR,
        `There was an error updating this reminder, please try again.`
      );
    } finally {
      LoadingIndicator.fire.hide();
    }
  };

  const formik = useFormik<ReminderForm>({
    enableReinitialize: true,
    initialValues: {
      type: (reminderAutomation as ReminderForm)?.type
        ? (reminderAutomation as ReminderForm).type
        : "",
      frequency: (reminderAutomation as ReminderForm)?.frequency
        ? (reminderAutomation as ReminderForm).frequency
        : Frequency.Once,
      weekDayOption: (reminderAutomation as ReminderForm)?.weekDayOption
        ? (reminderAutomation as ReminderForm).weekDayOption
        : "",
      date: (reminderAutomation as ReminderForm)?.date
        ? (reminderAutomation as ReminderForm).date
        : null,
      description: (reminderAutomation as ReminderForm)?.description
        ? (reminderAutomation as ReminderForm).description
        : "",
      trigger: (reminderAutomation as ReminderForm)?.trigger
        ? (reminderAutomation as ReminderForm).trigger
        : ({} as Trigger),
      action: (reminderAutomation as ReminderForm)?.action
        ? (reminderAutomation as ReminderForm).action
        : ({} as Action),
      weekDayTime: (reminderAutomation as ReminderForm)?.weekDayTime
        ? (reminderAutomation as ReminderForm).weekDayTime
        : null,
      dailyTime: (reminderAutomation as ReminderForm)?.dailyTime
        ? (reminderAutomation as ReminderForm).dailyTime
        : null,
      isEditMode: !!reminderAutomation,
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: VALIDATION_SCHEMA,
  });

  const { values, errors } = formik;

  const handleDeleteReminder = async () => {
    setDisableAcceptBtn(true);
    const patientAutomations = patient.automations;
    let updatedPatientAutomations: Automation[] | undefined = [];
    const payload: AutomationPayload = {
      set: {},
      delete: [],
      push: [],
    };
    updatedPatientAutomations = patientAutomations?.filter(
      (automation: Automation) => {
        return automation.id !== (reminderAutomation as Automation)?.id;
      }
    );
    if ((reminderAutomation as Automation)?.id)
      payload.delete = [(reminderAutomation as Automation)?.id as string];
    try {
      LoadingIndicator.fire.show();
      await Http().post(
        `${RemoteCareAPISync.host}/patients/${patient._meta?.id}/actions/modify`,
        {
          automations: payload,
        }
      );
      Notification.notify(SUCCESS, "Reminder deleted successfully");
      removeAutomation &&
        removeAutomation((reminderAutomation as Automation)?.id as string);
    } catch (e) {
      Notification.notify(
        ERROR,
        `There was an error deleting this reminder, please try again.`
      );
    } finally {
      LoadingIndicator.fire.hide();
      setShowConfirmModal(false);
    }
  };

  const handleReminderTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    let action;
    const description = getDescription(value);
    if (value === ReminderType.VirtualVisit) {
      action = {
        type: "REQUEST_VIRTUAL_VISIT",
      } as VirtualVisitAction;
    } else if (value !== "Other") {
      action = {
        type: "REMIND",
        message: description,
      } as ReminderAction;
    }
    updateReminder(index, { type: value, action, description }, isAutomation);
  };

  const handleDescriptionChanged = (event: SelectChangeEvent) => {
    const description = event.target.value;
    formik.setFieldValue("description", description);
    formik.setFieldValue("action", {
      type: "REMIND",
      message: values.description,
    });
    updateReminder(
      index,
      {
        action: {
          type: "REMIND",
          message: description || "",
        },
        description: description,
      },
      isAutomation
    );
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    updateReminder(
      index,
      { frequency: event.target.value, date: null },
      isAutomation
    );
  };

  const handleWeekDayOptionChange = (event: SelectChangeEvent) => {
    updateReminder(index, { weekDayOption: event.target.value }, isAutomation);
  };

  const renderError = (nameKey: keyof ReminderForm) => {
    return errors.hasOwnProperty(nameKey) ? (
      <ul style={{ marginTop: 14 }}>
        {errors.hasOwnProperty(nameKey) ? (
          <li className={globalStyles.danger}>{errors[nameKey]}</li>
        ) : null}
      </ul>
    ) : null;
  };

  const renderText = () => {
    return values.frequency === Frequency.Daily
      ? "every day"
      : values.weekDayOption
      ? `on ${values.weekDayOption}`
      : `on ${dayjs(values.date).format("dddd MM/DD/YYYY")}`;
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ border: 2, borderColor: "#F4F2F2", borderRadius: 10 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ m: 3 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                }}
              >
                {(isAutomation && "id" in reminderAutomation) ||
                (!isAutomation &&
                  !(reminderAutomation as ReminderForm).saved) ? (
                  <IconButton
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      if (
                        !isAutomation ||
                        (isAutomation && !("id" in reminderAutomation))
                      ) {
                        deleteReminder(index);
                      } else {
                        setShowConfirmModal(true);
                      }
                    }}
                  >
                    <CancelFilled />
                  </IconButton>
                ) : null}
              </div>
              <FormControl variant="outlined" sx={{ m: 4, minWidth: 180 }}>
                <InputLabel id="typeLabel">Type</InputLabel>
                <Select
                  label="Type"
                  labelId="typeLabel"
                  onChange={handleReminderTypeChange}
                  sx={{ mb: 2 }}
                  name="type"
                  value={values.type}
                  required
                  error={!!errors.type}
                >
                  <MenuItem value="Medication">Medication</MenuItem>
                  <MenuItem value="Biometrics">Biometrics</MenuItem>
                  <MenuItem value="Appointment">Appointment</MenuItem>
                  <MenuItem value="VirtualVisit">Virtual Visit</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {renderError("type")}
              </FormControl>
              <FormControl variant="standard">
                <TextInput
                  label="Description"
                  style={{ width: 452 }}
                  name="description"
                  type="text"
                  disabled={values.type !== "Other"}
                  placeholder="Description"
                  value={values.description}
                  hasError={!!errors?.description}
                  onChange={handleDescriptionChanged}
                />
                {renderError("description")}
              </FormControl>
              <Typography variant="body1" gutterBottom>
                At: {values.date && dayjs(values.date).format("hh:mm A  ")}{" "}
                {values.date && renderText()}
              </Typography>
              <div>
                <FormControl
                  variant="outlined"
                  sx={{ mt: 4, mr: 0, maxWidth: 130 }}
                >
                  <Select
                    value={values.frequency}
                    onChange={handleFrequencyChange}
                    displayEmpty
                    name="frequency"
                    renderValue={(value) => {
                      return (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <CachedIcon color="primary" />
                          {value}
                        </Box>
                      );
                    }}
                    size="small"
                    required
                    error={!!errors.frequency}
                  >
                    <MenuItem value="Once">Once</MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {values.frequency === Frequency.Once && (
                    <>
                      <DateTimePicker
                        label="Date/Time"
                        disablePast
                        value={values.date}
                        onChange={(value) => {
                          if (value) {
                            updateReminder(
                              index,
                              {
                                date: value,
                                trigger: {
                                  type: "DATE_TIME",
                                  when: dayjs(value).toISOString(),
                                } as DateTimeTrigger,
                              },
                              isAutomation
                            );
                          }
                        }}
                        closeOnSelect={false}
                        slotProps={{
                          openPickerButton: {
                            color: "primary",
                          },
                          inputAdornment: {
                            position: "start",
                          },
                          textField: { size: "small", required: true },
                        }}
                        sx={{ mt: 4, ml: 1 }}
                      />
                      {renderError("date")}
                    </>
                  )}
                  {values.frequency === Frequency.Daily && (
                    <>
                      <TimePicker
                        label="Time"
                        value={values.dailyTime}
                        closeOnSelect={false}
                        onChange={(value: dayjs.Dayjs | null) => {
                          if (value) {
                            updateReminder(
                              index,
                              {
                                dailyTime: value,
                                date: value.add(1, "d"),
                                trigger: {
                                  type: "SCHEDULE",
                                  occurrences: [
                                    {
                                      day: "MONDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "TUESDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "WEDNESDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "THURSDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "FRIDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "SATURDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                    {
                                      day: "SUNDAY",
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                  ],
                                } as ScheduleTrigger,
                              },
                              isAutomation
                            );
                          }
                        }}
                        slotProps={{
                          openPickerButton: {
                            color: "primary",
                          },
                          inputAdornment: {
                            position: "start",
                          },
                          textField: { size: "small", required: true },
                        }}
                        sx={{ mt: 4, ml: 2, maxWidth: 160 }}
                      />
                      {renderError("dailyTime")}
                    </>
                  )}
                  {values.frequency === Frequency.Weekly && (
                    <>
                      <FormControl
                        sx={{ mt: 4, ml: 1, minWidth: 120 }}
                        variant="outlined"
                        error={!values.weekDayOption}
                      >
                        <InputLabel id="weekDayOptionLabel" size="small">
                          Day
                        </InputLabel>
                        <Select
                          displayEmpty
                          required
                          label="Day"
                          labelId="weekDayOptionLabel"
                          onChange={handleWeekDayOptionChange}
                          name="weekDayOption"
                          size="small"
                          value={values.weekDayOption}
                          error={!!errors.weekDayOption}
                        >
                          <MenuItem value="Monday">Monday</MenuItem>
                          <MenuItem value="Tuesday">Tuesday</MenuItem>
                          <MenuItem value="Wednesday">Wednesday</MenuItem>
                          <MenuItem value="Thursday">Thursday</MenuItem>
                          <MenuItem value="Friday">Friday</MenuItem>
                          <MenuItem value="Saturday">Saturday</MenuItem>
                          <MenuItem value="Sunday">Sunday</MenuItem>
                        </Select>
                        {renderError("weekDayOption")}
                      </FormControl>
                      <FormControl sx={{ mt: 4, ml: 1 }}>
                        <TimePicker
                          label="Time"
                          value={values.weekDayTime}
                          onChange={(value: dayjs.Dayjs | null) => {
                            if (value) {
                              updateReminder(
                                index,
                                {
                                  weekDayTime: value,
                                  date: value.add(1, "d"),
                                  trigger: {
                                    type: "SCHEDULE",
                                    occurrences: [
                                      {
                                        ...(values.weekDayOption && {
                                          day: values.weekDayOption.toUpperCase(),
                                        }),
                                        hour: dayjs(value).hour(),
                                        minute: dayjs(value).minute(),
                                      },
                                    ],
                                  } as ScheduleTrigger,
                                },
                                isAutomation
                              );
                            }
                          }}
                          slotProps={{
                            openPickerButton: {
                              color: "primary",
                            },
                            inputAdornment: {
                              position: "start",
                            },
                            textField: { size: "small", required: true },
                          }}
                          closeOnSelect={false}
                          sx={{ maxWidth: 140 }}
                        />
                        {renderError("weekDayTime")}
                      </FormControl>
                    </>
                  )}
                </LocalizationProvider>
                {values.frequency && (
                  <FormControl sx={{ mt: 4, ml: 1 }}>
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      size="medium"
                      color="primary"
                      variant="outlined"
                      sx={{ height: 39 }}
                    >
                      SAVE
                    </Button>
                  </FormControl>
                )}
              </div>
            </Stack>
          </Box>
        </form>
      </FormikProvider>
      <Modal
        size="xs"
        handleClose={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        heading={"Confirm Delete Reminder"}
        button={"Accept"}
      >
        {showConfirmModal && (
          <>
            <p
              style={{
                padding: "10px",
                fontSize: "17px",
              }}
            >
              Are you sure you want to delete this reminder ?
            </p>
            <div className="row">
              <div className="col-12 mt-4 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disableAcceptBtn}
                  onClick={handleDeleteReminder}
                  className="btn btn-primary mr-2"
                >
                  Accept
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
