import { FC, useState } from "react";
import { WatchListItem } from "./../watchListItem";
import { User } from "@/domain/user/model";
import { Box, Tooltip } from "@mui/material";

interface Props {
  user: User;
  toolTipDescription?: string;
  secondary: string;
}
export const NoActionWatchListItem: FC<Props> = ({
  secondary,
  toolTipDescription = "No action required",
  user,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const remove = () => {
    setIsDirty(true);
  };
  const resetAddOrRemove = () => {
    setIsDirty(false);
  };
  return (
    <Tooltip title={toolTipDescription}>
      <Box>
        <WatchListItem
          noAction
          secondary={secondary}
          primary={`${user?.firstName} ${user?.lastName}`}
          patientCount={user.watchedPatientCount || 0}
        />
      </Box>
    </Tooltip>
  );
};
