import NoteContent from "../../chart/components/note-content";
import styles from "./styles.module.scss";
import { dateFormatter } from "@/pipes/date/index";
import { Note } from "@/domain/notes/model/types";

interface Props {
  notes: Note[];
}

function NoteTableDoc(props: Props) {
  const { notes } = props;
  return (
    <table className="table table-borderless" id="chart_notes">
      <tbody>
        <tr>
          <td
            scope="col"
            className={styles.summaryHeadings + " text-left"}
            colSpan={3}
          >
            Chart Note:{" "}
          </td>
        </tr>
        <tr className="bg-primary text-white font-weight-bold">
          <td className={styles.summaryHeadings + " text-center border"}>
            Timestamp
          </td>
          <td className={styles.summaryHeadings + " text-center border"}>
            Comment
          </td>
          <td className={styles.summaryHeadings + " text-center border"}>
            Author
          </td>
        </tr>
        {(notes || []).map((note) => (
          <tr>
            <td className="p-2 text-center border">
              {dateFormatter({
                date: note._meta?.created!,
                includeTime: true,
                toLocalTimezone: { enabled: true },
              })}
            </td>
            <td className="p-2 text-center border">
              <NoteContent note={note} />
            </td>
            <td className="p-2 text-center border">
              {note.author?.name}
              {note.author?.credentials && ","} {note.author?.credentials}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default NoteTableDoc;
