import produce from "immer";
import { AnyAction } from "redux";

import { LOGOUT, SELECT_ORGANIZATION } from "@/library/constants";
import { TagState } from "./types";
import * as C from "./constants";

export const INITIAL_STATE: TagState = {
  locationTags: [],
};

export const tagReducer = produce((state: TagState, action: AnyAction) => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (!action.payload) break;

      const { tag: tagState = {} } = action.payload;
      const { locationTags } = tagState;

      state.locationTags = locationTags || [];

      break;
    }

    case C.SET_LOCATION_TAGS: {
      const { locationTags } = action.payload;
      state.locationTags = locationTags;
      break;
    }

    case C.SAVE_LOCATION_TAG: {
      const { tag } = action.payload;

      const index = state.locationTags.findIndex(
        (t) => t._meta?.id === tag._meta?.id
      );

      if (index > -1) state.locationTags.splice(index, 1, tag);
      else state.locationTags.push(tag);

      break;
    }

    case C.DELETE_LOCATION_TAG: {
      const { tagId } = action.payload;

      const index = state.locationTags.findIndex((t) => t.id === tagId);

      if (index > -1) state.locationTags.splice(index, 1);

      break;
    }

    case SELECT_ORGANIZATION: {
      // Reset tags when org changes
      state.locationTags = [];
      break;
    }

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}, INITIAL_STATE);
