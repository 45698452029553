import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  Row,
  Cell,
  flexRender,
  CellContext,
  useReactTable,
  getCoreRowModel,
  RowSelectionState,
  HeaderContext,
} from "@tanstack/react-table";
import cx from "clsx";

import { IndeterminateCheckbox } from "@/components/form/IndeterminateCheckbox";
import { SeverityLevel } from "@/domain/notes/model/types";
import { dateFormatter } from "@/pipes/date/index";
import { AlertTypeLabelMap } from "./constants";
import { makeColumns } from "./helpers";
import { AlertTableRow, Props, RefProps } from "./types";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { getBiometryEntryIcon } from "@/domain/patient/view/tabs/monitoring/helpers";

export const AlertsTable: React.FC<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<RefProps>) => {
    const { data, renderActions, className } = props;

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const numSelected = Object.keys(rowSelection).length;

    const handleCheckboxHeaderClick = () => {
      if (numSelected > 0) {
        table.setRowSelection({});
      } else {
        table.toggleAllRowsSelected();
      }
    };

    const renderCheckboxHeader = ({
      table,
    }: HeaderContext<AlertTableRow, unknown>) => {
      return (
        <IndeterminateCheckbox
          disabled={!data.length}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={handleCheckboxHeaderClick}
        />
      );
    };
    const renderCheckboxCell = ({
      row,
    }: CellContext<AlertTableRow, unknown>) => (
      <div className="px-1">
        <input
          type="checkbox"
          {...{
            checked: row.getIsSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    );
    const renderDateCell = (info: CellContext<AlertTableRow, any>) => {
      const dateTime = dateFormatter({
        date: info.getValue(),
        includeTime: true,
        toLocalTimezone: { enabled: true },
      });

      return (
        <>
          <div className={styles.dateCell}>{dateTime}</div>
        </>
      );
    };
    const renderLevelCell = (info: CellContext<AlertTableRow, any>) => {
      const severityScore = info.getValue();
      let label = "Low";
      let className = globalStyles.success;

      if (severityScore >= SeverityLevel.SEVERITY_RED) {
        label = "High";
        className = globalStyles.danger;
      } else if (severityScore >= SeverityLevel.SEVERITY_YELLOW) {
        label = "Medium";
        className = globalStyles.warning;
      }

      return <span className={cx(globalStyles.bold, className)}>{label}</span>;
    };
    const renderTypeCell = (info: CellContext<AlertTableRow, any>) => {
      return AlertTypeLabelMap[info.getValue()] || "";
    };
    const renderResponseCell = (info: CellContext<AlertTableRow, any>) => {
      const source = info.row.original.source;
      const icon = source ? getBiometryEntryIcon(source) : "";
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: icon || "" }} /> {""}
          <span title={info.getValue()}>{info.getValue()} </span>
        </>
      );
    };

    const columns = makeColumns({
      renderCheckboxHeader,
      renderCheckboxCell,
      renderDateCell,
      renderLevelCell,
      renderTypeCell,
      renderResponseCell,
    });
    const table = useReactTable<AlertTableRow>({
      data,
      columns,
      state: {
        rowSelection,
      },
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
    });
    const { rows } = table.getRowModel();

    useImperativeHandle(ref, () => ({
      reset: () => {
        table.setRowSelection({});
      },
    }));

    const renderHeader = () =>
      table.getHeaderGroups().map((headerGroup) => {
        return (
          <tr key={`headerGroup-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={`header-${header.id}`} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        );
      });

    const renderRow = (row: Row<AlertTableRow>) => {
      return (
        <tr
          key={`alertRow-${row.id}`}
          className={cx(styles.row, row.getIsSelected() && styles.selected)}
        >
          {row.getVisibleCells().map((cell: Cell<AlertTableRow, unknown>) => {
            return (
              <td
                key={cell.id}
                className={cx(
                  cell.column.id === "label" ? styles.labelTd : null,
                  cell.column.id === "expander" ? styles.expanderTd : null
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    };

    const renderEmpty = () => (
      <tr className={styles.emptyTr}>
        <td colSpan={columns.length} className={styles.emptyTd}>
          No Current Alerts.
        </td>
      </tr>
    );

    return (
      <table className={cx(globalStyles.table, styles.alertsTable, className)}>
        <thead>{renderHeader()}</thead>
        <tbody>
          {renderActions && renderActions(columns, rowSelection)}
          {rows.length ? rows.map(renderRow) : renderEmpty()}
        </tbody>
      </table>
    );
  }
);
