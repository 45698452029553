import { AutoAssignRule } from "@/library/types/autoAssignRule";
import { UserActions } from "@/domain/user/model";

export const autoAssignRule = (
  orgId: string,
  role: any,
  userAction: any
): AutoAssignRule => {
  return {
    parent: {
      type: parentType(userAction),
      id: orgId,
    },
    target: {
      type: "Practice",
    },
    roleIds: typeof role === "string" ? [role] : role,
  };
};

const parentType = (userAction: any): "Practice" | "Organization" => {
  return userAction === UserActions.CreatePracticeUser
    ? "Practice"
    : "Organization";
};

export const WILDCARD_SCOPE = "*";

export const INVALID_MONGO_ID = "000000000000000000000000";
