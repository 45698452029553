import { PatientDevice } from "../models/device.interface";
import * as C from "./constants";
import { KitFilters, KitSortedState } from "./types";
import { PaginationConfig } from "../../../library/types/pagination";
import { Tag } from "../../tags/model/types";

export const dispatchSetKits = (kits: PatientDevice[]) => ({
  type: C.SET_KITS,
  payload: { kits },
});

export const dispatchSetKitFilters = (filters: KitFilters) => ({
  type: C.SET_KIT_FILTER,
  payload: { filters },
});

export const dispatchSetKitSortedState = (sortedState: KitSortedState) => ({
  type: C.SET_KIT_SORTED_STATE,
  payload: { sortedState },
});

export const dispatchSetKitPaginationConfig = (
  paginationConfig: PaginationConfig
) => ({
  type: C.SET_KITS_PAGINATION_CONFIG,
  payload: { paginationConfig },
});

export const dispatchSetReturnKitModalActive = (
  returnKitModalActive: boolean
) => ({
  type: C.SET_RETURN_KIT_MODAL_ACTIVE,
  payload: { returnKitModalActive },
});

export const dispatchUnassignDevice = (deviceId: string) => ({
  type: C.UNASSIGN_DEVICE,
  payload: deviceId,
});

export const dispatchUpdateDevice = (device: PatientDevice) => ({
  type: C.UPDATE_DEVICE,
  payload: device,
});

export const dispatchSelectKit = (kitId: string) => ({
  type: C.SELECT_KIT,
  payload: kitId,
});

export const dispatchDeselectKit = (kitId: string) => ({
  type: C.DESELECT_KIT,
  payload: kitId,
});

export const dispatchAssignLocation = (kitIds: string[], tag: Tag) => ({
  type: C.ASSIGN_LOCATION,
  payload: { kitIds, tag },
});

export const dispatchUnassignLocation = (kitIds: string[]) => ({
  type: C.UNASSIGN_LOCATION,
  payload: kitIds,
});

export const dispatchSelectAllKits = () => ({
  type: C.SELECT_ALL_KITS,
});

export const dispatchDeselectAllKits = () => ({
  type: C.DESELECT_ALL_KITS,
});
