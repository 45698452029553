import { FC, ReactNode, useState } from "react";
import {
  GroupAdd as GroupAddIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import { WatchListItem } from "./../watchListItem";
import { User } from "@/domain/user/model";

interface Props {
  patientCount: number;
  user: User;
  addToAssignList: (user: User) => void;
  removeFromAssignList: (user: User) => void;
}
export const AssignWatchListItem: FC<Props> = ({
  user,
  patientCount,
  addToAssignList,
  removeFromAssignList,
}) => {
  const add = () => {
    addToAssignList(user);
  };
  const resetAddOrRemove = () => {
    removeFromAssignList(user);
  };
  return (
    <WatchListItem
      actionButtonDescription="Adds to already exisitng watchlist"
      resetButton={<RestartAltIcon onClick={resetAddOrRemove} />}
      primary={`${user?.firstName} ${user?.lastName}`}
      patientCount={patientCount}
      actionButton={<GroupAddIcon onClick={add} />}
    />
  );
};
