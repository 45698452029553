import * as React from "react";
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const COLORS = ["#f70707fa", "#f9b800", "#28a745", "#f9b800", "#f70707fa"];

interface Props {
  name: string;
  range: Array<number>;
  MIN: number;
  MAX: number;
}

interface State {
  values: Array<number>;
  showTextBox: boolean;
}

class MultiRangeSlider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      values: this.props.range,
      showTextBox: false,
    };
  }

  handleEnterTextfield = (e: any, index: number) => {
    if (e.keyCode === 13) {
      // It works when hit the enter button.
      let enteredValue = e.target.value;
      if (
        enteredValue > this.state.values[index + 1] ||
        enteredValue < this.state.values[index - 1]
      )
        return; // Avoid overlapping one range to another
      if (
        (this.state.values[index + 1] === undefined &&
          enteredValue > this.props.MAX) ||
        (this.state.values[index - 1] === undefined &&
          enteredValue < this.props.MIN)
      )
        return; //Checking if enter value is greater or less than MIN and MAX values
      this.state.values[index] = enteredValue;
    }
  };

  render() {
    return (
      <div className="range-container">
        <Range
          values={this.state.values}
          step={STEP}
          min={this.props.MIN}
          max={this.props.MAX}
          onChange={(values) => this.setState({ values })}
          allowOverlap={false}
          renderTrack={({ props, children }) => (
            <div
              onClick={() =>
                this.setState({
                  showTextBox: !this.state.showTextBox,
                })
              }
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: COLORS,
                    min: this.props.MIN,
                    max: this.props.MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, index, isDragged, value }) => (
            <>
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                  backgroundColor: COLORS[index],
                }}
                key={index}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-28px",
                    color: COLORS[index],
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  <input
                    type="number"
                    defaultValue={this.state.values[index]}
                    max={this.props.MAX}
                    min={this.props.MIN}
                    className={
                      this.state.showTextBox
                        ? "form-control"
                        : "form-control d-none"
                    }
                    onClick={(e) => e.stopPropagation()}
                    onKeyUp={(e) => this.handleEnterTextfield(e, index)}
                    style={{
                      position: "absolute",
                      top: "-38px",
                      color: COLORS[index],
                      fontWeight: "bold",
                      fontSize: "14px",
                      fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                      padding: "4px",
                      width: "100px",
                    }}
                  />
                  {this.state.values[index]}
                </div>
              </div>
            </>
          )}
        />
      </div>
    );
  }
}

export default MultiRangeSlider;
