import React from "react";
import Button from "rsuite/Button";
import Input from "rsuite/Input";
import cx from "clsx";

import { ThresholdBelowTypes } from "@/domain/rules/model/constants";
import {
  RuleVariable,
  VariableDefaultObject,
} from "@/domain/rules/model/types";
import { ParameterFormValues } from "@/domain/rules/view/ObservationParameters/types";

import ruleStyles from "@/domain/rules/view/styles.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  name: keyof ParameterFormValues;
  variable?: RuleVariable | null;
  disabled?: boolean;
  values: VariableDefaultObject;
  defaultValues: VariableDefaultObject;
  onChange: (name: keyof ParameterFormValues) => (value: string) => void;
  onReset?: () => void;
};

export const ObservationVariable: React.FC<Props> = (props) => {
  const { name, disabled, variable, values, defaultValues, onChange, onReset } =
    props;

  if (!variable || !values) return null;

  const { type, displayName } = variable;
  const highName = `${name}-high`;
  const mediumName = `${name}-medium`;

  return (
    <div className={styles.row}>
      <div className={cx(ruleStyles.col, ruleStyles.colLg)}>
        <div className={styles.paramName}>{displayName || name}</div>
      </div>
      <div className={cx(ruleStyles.col, ruleStyles.colMd)}>
        {defaultValues.medium ? (
          <div className={cx(styles.rowHeight, styles.inputContainer)}>
            <span className={styles.arrow}>
              {ThresholdBelowTypes.includes(type) ? "≤" : "≥"}
            </span>
            <Input
              type="number"
              name={mediumName}
              disabled={disabled}
              value={values.medium || ""}
              min={ThresholdBelowTypes.includes(type) ? values.high || 0 : 0}
              max={
                ThresholdBelowTypes.includes(type)
                  ? undefined
                  : values.high || undefined
              }
              className={cx(styles.input, styles.yellow)}
              onChange={onChange(mediumName as keyof ParameterFormValues)}
            />
          </div>
        ) : null}
      </div>
      <div className={cx(ruleStyles.col, ruleStyles.colMd)}>
        {defaultValues.high ? (
          <div className={cx(styles.rowHeight, styles.inputContainer)}>
            <span className={styles.arrow}>
              {ThresholdBelowTypes.includes(type) ? "≤" : "≥"}
            </span>
            <Input
              type="number"
              name={highName}
              disabled={disabled}
              value={values.high || ""}
              min={ThresholdBelowTypes.includes(type) ? 0 : values.medium || 0}
              max={
                ThresholdBelowTypes.includes(type)
                  ? values.medium || undefined
                  : undefined
              }
              className={cx(styles.input, styles.red)}
              onChange={onChange(highName as keyof ParameterFormValues)}
            />
          </div>
        ) : null}
      </div>
      <div className={cx(ruleStyles.col, ruleStyles.colMd)}>
        <div className={styles.rowHeight}>
          <span className={styles.defaults}>{`${
            defaultValues.medium ? "M: " : ""
          }${defaultValues.medium || ""}${
            defaultValues.medium && defaultValues.high ? "; " : ""
          }${defaultValues.high ? "H: " : ""}${
            defaultValues.high || ""
          }`}</span>
        </div>
      </div>
      <div className={cx(ruleStyles.col, ruleStyles.colBtn)}>
        <div className={styles.rowHeight}>
          <Button
            disabled={
              String(values.high) === String(defaultValues.high) &&
              String(values.medium) === String(defaultValues.medium)
            }
            className={styles.resetButton}
            onClick={onReset}
          >
            Reset to default
          </Button>
        </div>
      </div>
    </div>
  );
};
