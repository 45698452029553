import { Dispatch } from "redux";

import { dispatchSetPractices, dispatchCreatePractice } from "./actions";
import { SetPracticeDispatch } from "./types";
import { PracticeModel } from "../model";
import { Practice } from "../model/types";
import { ProviderModel } from "../../provider/model";

/**
 * Outer synchronous function that receives the `practiceCollection` and `providerCollection` parameter:
 * @param practiceCollection
 * @param providerCollection
 */
export function setPracticesThunk(practiceCollection: PracticeModel[]) {
  // And then creates and returns the async thunk function:
  return async (dispatch: SetPracticeDispatch) => {
    if (!practiceCollection.length) {
      dispatchSetPractices([]);
    }

    let practices: Practice[] = practiceCollection.map(
      (practiceCollectionItem) => practiceCollectionItem.pluckAll()
    );
    // Find if there are any practices without provider count attached.
    let practiceIds: string[] = [];
    practices.forEach((practice) => {
      if (!("providerCount" in practice)) {
        practiceIds.push(practice._meta.id);
      } else {
        practice.providers = practice.providerCount as number;
      }
    });
    if (practiceIds.length) {
      const providers = (
        await ProviderModel.fetchByPracticeIds(practiceIds as string[])
      ).map((provider) => provider.pluckAll());

      practices = practices.map((practice) => {
        if (!practice.providers) {
          practice.providers = providers.filter(
            (provider) => provider.practiceId === practice.id
          ).length;
        }
        return practice;
      });
    }
    dispatch(dispatchSetPractices(practices));
  };
}

/**
 * Intercept the newly created practice object before saving it to the redux state and dispatch cretePractice action
 * @param practice
 */
export const createPracticeThunk =
  (practice: Practice) => (dispatch: Dispatch) => {
    // Newly created practice will not have any provider assigned to it so it is safe to assume it has 0 providers initially.
    practice.providers = 0;
    dispatch(dispatchCreatePractice(practice));
  };
