import {
  CREATE_ORGANIZATION,
  DESELECT_ORGANIZATION,
  SELECT_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_PAGINATIONCONFIG,
  SET_ORGANIZATION_PAGE_LIMIT,
  SET_PRACTICES_PAGINATIONCONFIG,
  SET_ORGANIZATIONS_SEARCH_FILTER,
  SET_TARGET_ORGANIZATION,
  SET_ORGANIZATIONS_SORTED_STATE,
} from "../../../library/constants";
import {
  OrganizationState,
  PageLimit,
  PaginationConfig,
} from "../../../library/types";
import { Organization } from "../model/types";

export const INITIAL_STATE = {
  organizations: [],
  selectedOrganization: undefined,
  targetOrganization: undefined,
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  organizationPageLimit: 50,
  filters: {
    isEnabled: undefined,
    type: undefined,
    kitFulfillment: undefined,
  },
  sortedState: {
    direction: undefined,
    sortBy: undefined,
  },
};

export const organizationReducer = (
  state: OrganizationState = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: [action.payload, ...state.organizations],
      };

    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };

    case SELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };

    case DESELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: undefined,
      };

    case SET_ORGANIZATION_PAGE_LIMIT:
      return {
        ...state,
        organizationPageLimit: action.payload as PageLimit,
      };

    case SET_ORGANIZATIONS_PAGINATIONCONFIG:
      return {
        ...state,
        paginationConfig: action.payload as unknown as PaginationConfig,
      };

    case SET_ORGANIZATIONS_SEARCH_FILTER:
      return {
        ...state,
        filters: action.payload,
      };

    case SET_TARGET_ORGANIZATION:
      return {
        ...state,
        targetOrganization: action.payload as Organization,
      };
    case SET_ORGANIZATIONS_SORTED_STATE:
      return {
        ...state,
        sortedState: action.payload,
      };

    default:
      return state;
  }
};
