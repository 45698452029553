import { RuleType } from "./types";

export const RuleTypes = {
  Boolean: RuleType.Boolean as RuleType,
  Enabled: RuleType.Enabled as RuleType,
  Number: RuleType.Number as RuleType,
  String: RuleType.String as RuleType,
  Threshold: RuleType.Threshold as RuleType,
  ThresholdAbove: RuleType.ThresholdAbove as RuleType,
  ThresholdAboveHigh: RuleType.ThresholdAboveHigh as RuleType,
  ThresholdAboveMedium: RuleType.ThresholdAboveMedium as RuleType,
  ThresholdBelow: RuleType.ThresholdBelow as RuleType,
  ThresholdBelowHigh: RuleType.ThresholdBelowHigh as RuleType,
  ThresholdBelowMedium: RuleType.ThresholdBelowMedium as RuleType,
  ThresholdOverTime: RuleType.ThresholdOverTime as RuleType,
};

export const ThresholdTypes = [
  RuleTypes.Threshold,
  RuleTypes.ThresholdAbove,
  RuleTypes.ThresholdBelow,
];

export const ThresholdBelowTypes = [
  RuleTypes.ThresholdBelow,
  RuleTypes.ThresholdBelowHigh,
  RuleTypes.ThresholdBelowMedium,
];
export const ThresholdAboveTypes = [
  RuleTypes.ThresholdAbove,
  RuleTypes.ThresholdAboveHigh,
  RuleTypes.ThresholdAboveMedium,
];
