import React from "react";
import RSelect, { Props as SelectProps } from "react-select";
import cx from "clsx";

import { Label } from "@/components/Label";

import vars from "@/styles/vars.module.scss";
import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

const IndicatorSeparator = () => null;

export type OwnProps = {
  name: string;
  label?: string;
  required?: boolean;
  hasError?: boolean;
  errorLabel?: string;
};
export type Props = OwnProps & SelectProps;

export const Select = React.forwardRef<RSelect, Props>((props, ref) => {
  const {
    name,
    options,
    label,
    required,
    hasError,
    errorLabel,
    components = {},
    pending = false,
    disabled = false,
    containerClassName,
    className,
    ...selectProps
  } = props;

  return (
    <div className={cx(styles.select, containerClassName)}>
      {label ? (
        <Label
          className={cx(
            required && globalStyles.requiredField,
            hasError && globalStyles.danger
          )}
          htmlFor={name}
        >
          {label}
        </Label>
      ) : null}
      <RSelect
        ref={ref}
        name={name}
        options={options}
        components={{
          ...components,
          IndicatorSeparator,
        }}
        className={cx(
          globalStyles.buttonReset,
          styles.select,
          (disabled || pending) && globalStyles.disabled,
          className
        )}
        isDisabled={disabled || pending}
        {...selectProps}
        styles={{
          control: (base, state) => {
            const { menuIsOpen, isFocused } = state;

            const isOpen = menuIsOpen || isFocused;

            return {
              ...base,
              backgroundColor: isOpen ? "#ffffff" : "#fff",
              borderColor: hasError
                ? vars["color-alert-danger"]
                : isOpen
                ? vars["color-rca-blue"]
                : vars["color-rca-gray"],
              color: isOpen ? vars["color-rca-blue"] : vars["color-rca-gray"],
              borderWidth: 1,
              borderRadius: vars["border-radius-input"],
              boxShadow: isOpen ? vars["box-shadow-button"] : undefined,
              height: parseInt(vars["height-input"]),
              minHeight: parseInt(vars["height-input"]),
              width: "100%",
              paddingTop: 1,
              cursor: disabled ? "not-allowed" : undefined,
              "&:hover": disabled
                ? undefined
                : {
                    color: vars["color-rca-blue"],
                    borderColor: vars["color-rca-blue"],
                    backgroundColor: "#fff",
                  },
            };
          },
          singleValue: (base) => ({
            ...base,
            color: vars["color-readable-dark"],
          }),
          clearIndicator: (base) => ({
            ...base,
            width: 24,
            padding: 0,
            color: vars["color-rca-grayrow"],
            transitionDuration: "33ms",
            cursor: "pointer",

            "&:hover": disabled
              ? { cursor: "not-allowed" }
              : {
                  color: vars["color-alert-danger"],
                },
          }),
          dropdownIndicator: (base) => ({
            ...base,
            width: 26,
            padding: "8px 0",
            color: "inherit",
            transitionDuration: "33ms",
            cursor: "pointer",

            "&:hover": disabled
              ? { cursor: "not-allowed", color: "inherit" }
              : {
                  color: vars["color-rca-blue"],
                },
          }),

          menu: (base) => ({
            ...base,
            zIndex: vars["z-index-8"],
            minHeight: "46px",
          }),

          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
              ? vars["color-rca-blue"]
              : state.isFocused
              ? vars["color-rca-grayrow"]
              : "#fff",
            cursor: "pointer",
          }),

          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: vars["color-alert-danger"],
              color: "#fff",
            },
          }),

          valueContainer: (base, state) => {
            const { selectProps: _p } = state;
            const { menuIsOpen } = _p;

            const isOpen = menuIsOpen;

            return {
              ...base,
              paddingTop: isOpen ? 2 : 3,
              paddingLeft: 7,
              paddingRight: 7,
            };
          },
        }}
      />
      {errorLabel ? (
        <Label htmlFor={String(name)} className={cx(styles.errorLabel)}>
          {errorLabel}
        </Label>
      ) : null}
    </div>
  );
});
