import React, { SyntheticEvent, useState } from "react";
import Button from "rsuite/Button";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import cx from "clsx";
import * as Yup from "yup";
import { isEqual } from "lodash";

import {
  RuleEnabledKey,
  RuleOverrides,
  RuleType,
  RuleVariable,
} from "@/domain/rules/model/types";
import { RuleTypes, ThresholdTypes } from "@/domain/rules/model/constants";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";
import { ObservationVariable } from "@/domain/rules/view/ObservationVariable";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import {
  ObservationMeasurementUnits,
  ObservationTitles,
} from "@/domain/patient/model/constants";
import { ObservationType } from "@/domain/observations/types";
import { SavedIndicator } from "@/domain/rules/view/SavedIndicator";
import { ScalarVariable } from "@/domain/rules/view/ScalarVariable";
import { selectCurrentUser } from "@/domain/user/redux/selectors";
import { LoadingDimmer } from "@/components/LoadingDimmer";
import { ERROR, MIN_FETCH_TIME_MS } from "@/library/constants";
import { PatientModel } from "@/domain/patient/model";
import { OrganizationModel } from "@/domain/organization/model";
import {
  PostSetParameterOverrides,
  PostUnsetParameterOverrides,
} from "@/domain/organization/model/types";
import { Notification } from "@/components/notification/notification";
import { ICONS, OverrideLevels } from "./constants";
import {
  makeDefaultValues,
  makeInitialValues,
  makePostVariables,
} from "./helpers";
import { ParameterFormValues, Props } from "./types";

import ruleStyles from "@/domain/rules/view/styles.module.scss";
import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import produce from "immer";
import { selectCurrentPractice } from "@/domain/practice/redux/selectors";
import { selectCurrentProvider } from "@/domain/provider/redux/selectors";
import { PracticeModel } from "@/domain/practice/model";
import { ProviderModel } from "@/domain/provider/model";

const scalarRuleTypes = [
  RuleTypes.Enabled,
  RuleTypes.Boolean,
  RuleTypes.Number,
  RuleTypes.String,
];

export const ObservationParameters: React.FC<Props> = (props) => {
  const { rule, savedAt, onSave, overrideLevel, containerClassName } = props;

  const { id = "", trigger } = rule;
  const { observationType: type } = trigger;

  // Formik will set it's `touched` to true on all fields after validation
  // so we need to track our own `touched` manually
  const [touched, setTouched] = useState({});
  const [variablesToUnset, setVariablesToUnset] = useState<string[]>([]);
  const [triggerEnabled, setTriggerEnabled] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);
  const [savedValues, setSavedValues] = useState<ParameterFormValues | null>(
    null
  );

  const user = useSelector(selectCurrentUser);
  const patient = useSelector(selectFirstPatient);
  const organization = useSelector(selectActiveOrganization);
  const selectedPractice = useSelector(selectCurrentPractice);
  const selectedProvider = useSelector(selectCurrentProvider);

  const overrides = () => {
    switch (overrideLevel) {
      case OverrideLevels.organization:
        return organization?.ruleVariables?.hasOwnProperty(id)
          ? (organization?.ruleVariables[id as string] as RuleOverrides) || {}
          : (patient?.ruleVariables[id] as RuleOverrides) || {};
      case OverrideLevels.practice:
        return selectedPractice?.ruleVariables?.hasOwnProperty(id)
          ? (selectedPractice?.ruleVariables[id as string] as RuleOverrides) ||
              {}
          : organization?.ruleVariables?.hasOwnProperty(id)
          ? (organization?.ruleVariables[id as string] as RuleOverrides) || {}
          : {};
      case OverrideLevels.provider:
        return selectedProvider?.ruleVariables?.hasOwnProperty(id)
          ? (selectedProvider?.ruleVariables[id as string] as RuleOverrides) ||
              {}
          : selectedPractice?.ruleVariables?.hasOwnProperty(id)
          ? (selectedPractice?.ruleVariables[id as string] as RuleOverrides) ||
            {}
          : organization?.ruleVariables?.hasOwnProperty(id)
          ? (organization?.ruleVariables[id as string] as RuleOverrides) || {}
          : {};
      default:
        return (patient?.ruleVariables[id] as RuleOverrides) || {};
    }
  };

  const initialValues = makeInitialValues(rule, overrides());
  const defaultValues = makeDefaultValues(rule, overrides());

  const title = ObservationTitles[type as ObservationType];
  const unit = ObservationMeasurementUnits[type as ObservationType];
  const variableKeys = Object.keys(rule?.variables || {}) as Array<
    keyof RuleOverrides
  >;
  const variablesToShow = variableKeys.filter(
    (key) => !scalarRuleTypes.includes(rule?.variables[key]?.type as RuleType)
  );
  const scalarVariables = variableKeys.filter((key) =>
    scalarRuleTypes.includes(rule?.variables[key]?.type as RuleType)
  );
  const enabledKey =
    variableKeys.find(
      (key) => rule?.variables[key]?.type === RuleTypes.Enabled
    ) || "isEnabled";

  function getId() {
    switch (overrideLevel) {
      case OverrideLevels.organization:
        return organization?.id;
      case OverrideLevels.practice:
        return selectedPractice?.id;
      case OverrideLevels.provider:
        return selectedProvider?.id;
      default:
        return patient?.id;
    }
  }
  const handleSubmit = async (values: ParameterFormValues) => {
    const id = getId();
    if (!id || !user?.id || !rule?.id) return;

    const startTime = new Date().getTime();
    let fetchError: Error;
    setSubmitPending(true);

    const variables = makePostVariables(
      values,
      scalarVariables,
      variablesToUnset,
      touched
    );
    const setData: PostSetParameterOverrides = {
      ruleId: rule.id,
      variables,
    };
    const unsetData: PostUnsetParameterOverrides = {
      ruleId: rule.id,
      variableNames: variablesToUnset,
    };

    if (Object.keys(variables).length) {
      try {
        if (overrideLevel === OverrideLevels.organization) {
          await OrganizationModel.setParameterOverrides(id, setData);
        } else if (overrideLevel === OverrideLevels.practice) {
          await PracticeModel.setParameterOverrides(id, setData);
        } else if (overrideLevel === OverrideLevels.provider) {
          await ProviderModel.setParameterOverrides(id, setData);
        } else {
          await PatientModel.setParameterOverrides(id, setData);
        }
      } catch (err: Error | unknown) {
        fetchError = err as Error;
        Notification.notify(ERROR, (err as Error).message, "", 2000);
      }
    }

    if (variablesToUnset.length) {
      try {
        if (overrideLevel === OverrideLevels.organization) {
          await OrganizationModel.unsetParameterOverrides(id, unsetData);
        } else if (overrideLevel === OverrideLevels.practice) {
          await PracticeModel.unsetParameterOverrides(id, unsetData);
        } else if (overrideLevel === OverrideLevels.provider) {
          await ProviderModel.unsetParameterOverrides(id, unsetData);
        } else {
          await PatientModel.unsetParameterOverrides(id, unsetData);
        }
      } catch (err: Error | unknown) {
        fetchError = err as Error;

        Notification.notify(ERROR, (err as Error).message, "", 2000);
      }
    }

    form.setSubmitting(true);
    const requestTime = new Date().getTime() - startTime;
    const additionalDelay =
      requestTime < MIN_FETCH_TIME_MS ? MIN_FETCH_TIME_MS - requestTime : 0;

    setTimeout(() => {
      if (!fetchError) {
        setSavedValues(values);
        onSave?.(new Date());
      }
      form.resetForm({ values });
      form.setTouched({});
      setTouched({});
      setVariablesToUnset([]);
      setSubmitPending(false);
      form.setSubmitting(false);
    }, additionalDelay);
  };

  const form = useFormik<ParameterFormValues>({
    initialValues: savedValues || initialValues,
    validationSchema: Yup.object({
      isEnabled: Yup.boolean().required("required"),
    }),
    onSubmit: handleSubmit,
  });
  const { isValid, values, submitForm } = form;

  const isDifferent = !isEqual(defaultValues, values);
  const isEnabled = values[enabledKey as keyof RuleEnabledKey] ? true : false;

  const handleSave = () => {
    if (isValid) {
      submitForm();
    }
  };
  const handleSavedAlertEnd = () => onSave?.(null);
  const handleScalarChange =
    (key: keyof ParameterFormValues) =>
    (
      _value: string | number | boolean,
      checked: boolean | SyntheticEvent<HTMLElement, Event>
    ) => {
      const value =
        typeof checked === "boolean"
          ? checked
          : typeof _value === "string"
          ? key === "baselineWeight"
            ? Math.round(parseFloat(_value) * 10) / 10
            : Math.round(parseFloat(_value) * 100) / 100
          : _value;

      form.setFieldValue(key, value);
      form.setFieldTouched(key, true);
      setTouched({ ...touched, [key]: true });

      // Make sure this key is no longer marked for "unset"
      const unsetIndex = variablesToUnset.indexOf(key);
      if (unsetIndex > -1) {
        setVariablesToUnset(
          produce(variablesToUnset, (draft) => {
            draft.splice(unsetIndex, 1);
          })
        );
      }
    };
  const handleVariableChange =
    (key: keyof ParameterFormValues) => (value: string) => {
      const split = key.split("-");
      const variable = rule.variables[split[0]];
      const newTouched: Record<string, boolean> = { ...touched, [key]: true };

      form.setFieldValue(key, parseFloat(value));
      form.setFieldTouched(key, true);

      if (ThresholdTypes.includes(variable.type)) {
        const otherThreshold = split[1] === "medium" ? "high" : "medium";
        const otherKey = `${split[0]}-${otherThreshold}`;

        form.setFieldValue(
          otherKey,
          values[otherKey as keyof ParameterFormValues]
        );
        form.setFieldTouched(otherKey, true);
        newTouched[otherKey] = true;

        // Make sure this key is no longer marked for "unset"
        const unsetIndex = variablesToUnset.indexOf(key);
        if (unsetIndex > -1) {
          setVariablesToUnset(
            produce(variablesToUnset, (draft) => {
              draft.splice(unsetIndex, 1);
            })
          );
        }
      }

      setTouched(newTouched);
    };
  const handleResetScalar = (key: keyof ParameterFormValues) => async () => {
    const foundIndex = variablesToUnset.indexOf(key);
    const value = (
      defaultValues as Record<string, string | number | boolean | null>
    )[key];

    form.setFieldValue(key, value);
    form.setFieldTouched(key, true);
    setTouched({ ...touched, [key]: true });

    if (foundIndex === -1) {
      setVariablesToUnset([...variablesToUnset, key]);
    }
  };
  const handleResetVariable = (key: keyof ParameterFormValues) => async () => {
    const foundIndex = variablesToUnset.indexOf(key);
    const mediumValue =
      defaultValues[`${key}-medium` as keyof ParameterFormValues];
    const highValue = defaultValues[`${key}-high` as keyof ParameterFormValues];

    form.setFieldValue(
      `${key}-medium`,
      mediumValue === null || mediumValue === undefined ? "" : mediumValue
    );
    form.setFieldValue(
      `${key}-high`,
      highValue === null || highValue === undefined ? "" : highValue
    );
    form.setFieldTouched(`${key}-medium`, true);
    form.setFieldTouched(`${key}-high`, true);
    setTouched({
      ...touched,
      [`${key}-medium`]: true,
      [`${key}-high`]: true,
    });

    if (foundIndex === -1) {
      setVariablesToUnset([...variablesToUnset, key]);
    }
  };
  const handleResetAll = () => {
    form.resetForm();
    form.setValues(defaultValues);
    form.setTouched({});
    setTouched({});

    // Unset all
    setVariablesToUnset(variableKeys);
    setTriggerEnabled(false);
  };

  const renderIcon = (svg: React.ReactNode) => (
    <div className={styles.icon}>
      <img src={svg as string} alt={title} />
    </div>
  );

  const renderVariable = (name: string) => {
    const variable = rule?.variables[name] as RuleVariable;

    let valuesHigh: number = 0;
    let defaultValuesHigh: number = 0;
    let valuesMedium: number = 0;
    let defaultValuesMedium: number = 0;
    if (
      rule.trigger.observationType === ObservationType.Weight ||
      rule.trigger.observationType === ObservationType.Temperature
    ) {
      valuesHigh = +parseFloat(values[`${name}-high`]).toFixed(1);
      defaultValuesHigh = +parseFloat(defaultValues[`${name}-high`]).toFixed(1);
      valuesMedium = +parseFloat(values[`${name}-medium`]).toFixed(1);
      defaultValuesMedium = +parseFloat(
        defaultValues[`${name}-medium`]
      ).toFixed(1);
    } else {
      valuesHigh = parseInt(values[`${name}-high`], 10);
      defaultValuesHigh = parseInt(defaultValues[`${name}-high`], 10);
      valuesMedium = parseInt(values[`${name}-medium`], 10);
      defaultValuesMedium = parseInt(defaultValues[`${name}-medium`], 10);
    }

    return (
      <ObservationVariable
        key={`variable-${name}`}
        name={name as keyof ParameterFormValues}
        disabled={!isEnabled}
        variable={variable}
        values={{
          high: valuesHigh || null,
          medium: valuesMedium || null,
        }}
        defaultValues={{
          high: defaultValuesHigh || null,
          medium: defaultValuesMedium || null,
        }}
        onChange={handleVariableChange}
        onReset={handleResetVariable(name)}
      />
    );
  };

  const renderScalarVariable = (name: keyof RuleOverrides) => {
    const variable = rule?.variables[name] as RuleVariable;

    return (
      <ScalarVariable
        key={`scalar-${name}`}
        name={name}
        disabled={!isEnabled}
        variable={variable}
        value={values[name]}
        defaultValue={defaultValues[name]}
        onChange={handleScalarChange}
        onReset={handleResetScalar(name)}
        containerClassName={styles.scalarVariable}
      />
    );
  };

  return (
    <div className={cx(styles.parameters, containerClassName)}>
      <form onSubmit={form.handleSubmit}>
        <div className={styles.top}>
          <div className={styles.title}>
            {renderIcon(ICONS[type as ObservationType])} {title}
          </div>
          <div className={styles.scalarSection}>
            {scalarVariables.length
              ? scalarVariables.map(renderScalarVariable)
              : null}
          </div>
        </div>

        <div
          className={cx(
            styles.content,
            !isEnabled && globalStyles.disabled,
            !isEnabled && styles.disabled
          )}
        >
          <div className={styles.contentTitle}>Alert Level ({unit})</div>
          <div className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <span className={cx(ruleStyles.col, ruleStyles.colLg)}>
                Parameter
              </span>
              <span className={cx(ruleStyles.col, ruleStyles.colMd)}>
                Medium
              </span>
              <span className={cx(ruleStyles.col, ruleStyles.colMd)}>High</span>
              <span className={cx(ruleStyles.col, ruleStyles.colMd)}>
                Defaults
              </span>
              <span className={cx(ruleStyles.col, ruleStyles.colBtn)} />
            </div>
            {variablesToShow.map(renderVariable)}
            {!variablesToShow.length ? (
              <div style={{ marginTop: 21, textAlign: "center" }}>
                No variables available.
              </div>
            ) : null}
          </div>
        </div>

        {/* <ObservationAssessment /> */}
      </form>

      {savedAt ? (
        <SavedIndicator
          direction="up"
          savedAt={savedAt}
          onClose={handleSavedAlertEnd}
          containerClassName={styles.savedContainer}
        />
      ) : null}

      <div className={styles.actions}>
        <Button
          onClick={handleResetAll}
          disabled={!isDifferent}
          appearance="link"
        >
          Reset all
        </Button>
        <Button
          loading={submitPending}
          onClick={handleSave}
          disabled={!variablesToUnset.length && !Object.keys(touched).length}
          appearance="primary"
        >
          Save Changes
        </Button>
      </div>

      {!rule && <LoadingDimmer pending dimmerClassName={styles.dimmer} />}
    </div>
  );
};
