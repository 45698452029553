import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { DateTime } from "luxon";

import { PatientObservation } from "@/domain/patient/model/types";
import {
  selectNumDaysBetweenDates,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { RootState } from "@/types";
import { AggregateData } from "@/domain/patient/view/tables/AggregateTable/types";
import { ObservationTypes } from "@/domain/patient/model/constants";
import { selectObservationsFromPatientNotes } from "@/domain/notes/redux/selectors";
import {
  displayObsUnit,
  truncateObservationValue,
} from "@/domain/observations/helpers";
import { ObservationType } from "@/domain/observations/types";
import {
  biometryEntryMethod,
  getBiometryEntryIcon,
} from "@/domain/patient/view/tabs/monitoring/helpers";
import { getBiometryEntryIconNew } from "@/domain/patient/view/tabsNew/monitoring/helpers";

const isCANewPatientTabsEnabled = _env_.ENABLE_NEW_PATIENT_TAB === "true";

export const selectAggregateTableData = createSelector<
  [
    Selector<RootState, PatientObservation[]>,
    Selector<RootState, string>,
    Selector<RootState, number>
  ],
  AggregateData[]
>(
  [
    selectObservationsFromPatientNotes,
    selectMonitoringEndDate,
    selectNumDaysBetweenDates,
  ],
  (observations, isoEndDate, numDays) => {
    if (!observations || !observations.length) return [];

    const bloodPressure: AggregateData = {
      title: displayObsUnit(ObservationType.BloodPressure),
      type: ObservationTypes.BloodPressure,
      subRows: [],
    };
    const heartRate: AggregateData = {
      title: displayObsUnit(ObservationType.HeartRate),
      type: ObservationTypes.HeartRate,
      subRows: [],
    };
    const temperature: AggregateData = {
      title: displayObsUnit(ObservationType.Temperature),
      type: ObservationTypes.Temperature,
      subRows: [],
    };
    const weight: AggregateData = {
      title: displayObsUnit(ObservationType.Weight),
      type: ObservationTypes.Weight,
      subRows: [],
    };
    const glucometry: AggregateData = {
      title: displayObsUnit(ObservationType.GlucoseLevel),
      type: ObservationTypes.GlucoseLevel,
      subRows: [],
    };
    const pulseOximetry: AggregateData = {
      title: displayObsUnit(ObservationType.PulseOximetry),
      type: ObservationTypes.PulseOximetry,
      subRows: [],
    };

    const spirometry: { [parentKey: string]: AggregateData } = {
      fev1: {
        title: (
          displayObsUnit(ObservationType.Spirometry) as {
            pef: string;
            fev1: string;
          }
        ).fev1,
        type: ObservationTypes.Spirometry,
        subRows: [],
        subType: "fev1",
      },
      pef: {
        title: (
          displayObsUnit(ObservationType.Spirometry) as {
            pef: string;
            fev1: string;
          }
        ).pef,
        type: ObservationTypes.Spirometry,
        subRows: [],
        subType: "pef",
      },
    };
    const dataMap = {
      [ObservationTypes.BloodPressure]: bloodPressure,
      [ObservationTypes.HeartRate]: heartRate,
      [ObservationTypes.Temperature]: temperature,
      [ObservationTypes.Weight]: weight,
      [ObservationTypes.GlucoseLevel]: glucometry,
      [ObservationTypes.PulseOximetry]: pulseOximetry,
      [ObservationTypes.Spirometry]: spirometry,
    };

    for (let i = 0; i < numDays; i++) {
      const date = DateTime.fromISO(isoEndDate).minus({ days: i });
      const isoDate = date.toISODate();

      bloodPressure[isoDate] = "";
      heartRate[isoDate] = "";
      temperature[isoDate] = "";
      weight[isoDate] = "";
      glucometry[isoDate] = "";
      pulseOximetry[isoDate] = "";
      spirometry["pef"][`${isoDate}`] = "";
      spirometry["fev1"][`${isoDate}`] = "";
    }
    const setupAdd =
      (
        type: ObservationType,
        isoDate: string,
        severityScore: number,
        data: AggregateData,
        createdDate: string,
        timeOfObservation: string | undefined,
        biometricEntryIcon: string,
        subType?: string
      ) =>
      (value: string | number) => {
        if (!data[isoDate]) {
          data[isoDate] = {
            value: truncateObservationValue(type, value),
            severityScore,
            createdDate,
            timeOfObservation,
            biometricEntryIcon,
            subType,
          };
        } else {
          if (!(data.subRows as AggregateData[]).length) {
            (data.subRows as AggregateData[]).push({
              type,
              [isoDate]: {
                value: truncateObservationValue(type, value),
                severityScore,
                createdDate,
                timeOfObservation,
                biometricEntryIcon,
                subType,
              },
            });
          } else {
            let found = false;
            const dataSubRows = data.subRows as AggregateData[];
            for (let k = 0; k < (data.subRows as AggregateData[]).length; k++) {
              const row = (data.subRows as AggregateData[])[k];
              const isIsoDateMap = dataSubRows.some((row) => {
                return row[isoDate];
              });
              if (!row[isoDate] && !isIsoDateMap) {
                row[isoDate] = {
                  value: truncateObservationValue(type, value),
                  severityScore,
                  createdDate,
                  timeOfObservation,
                  biometricEntryIcon,
                  subType,
                };
                found = true;
              }
            }
            if (!found) {
              (data.subRows as AggregateData[]).push({
                type,
                [isoDate]: {
                  value: truncateObservationValue(type, value),
                  severityScore,
                  createdDate,
                  timeOfObservation,
                  biometricEntryIcon,
                  subType,
                },
              });
            }
          }
        }
      };

    for (let j = 0; j < observations.length; j++) {
      const observation = observations[j];
      if (
        observation.source === biometryEntryMethod.ClinicianEntry &&
        !observation.manualNoteId
      ) {
        continue;
      }
      const {
        type,
        value,
        systolic,
        diastolic,
        severityScore = 0,
        _meta,
        timeOfObservation,
        source,
        fev1,
        pef,
        manualNoteId,
      } = observation;
      const { created } = _meta;

      const date = DateTime.fromISO(
        timeOfObservation ? timeOfObservation : created
      );
      const isoDate = date.toISODate();
      const biometricEntryIcon =
        source || manualNoteId
          ? isCANewPatientTabsEnabled
            ? getBiometryEntryIconNew(
                source || biometryEntryMethod.ClinicianEntry
              )
            : getBiometryEntryIcon(source || biometryEntryMethod.ClinicianEntry)
          : "";
      let addData, addDataPEF, addDataFEV1;
      if (type === ObservationTypes.Spirometry) {
        addDataPEF = setupAdd(
          type,
          isoDate,
          severityScore || 0,
          dataMap[ObservationTypes.Spirometry].pef as AggregateData,
          created,
          date.toString(),
          biometricEntryIcon,
          "pef"
        );
        addDataFEV1 = setupAdd(
          type,
          isoDate,
          severityScore || 0,
          dataMap[ObservationTypes.Spirometry].fev1 as AggregateData,
          created,
          date.toString(),
          biometricEntryIcon,
          "fev1"
        );
      } else {
        addData = setupAdd(
          type,
          isoDate,
          severityScore || 0,
          dataMap[type] as AggregateData,
          created,
          date.toString(),
          biometricEntryIcon
        );
      }

      switch (type) {
        case ObservationTypes.BloodPressure:
          const bp =
            systolic && diastolic
              ? `${truncateObservationValue(
                  ObservationTypes.BloodPressure,
                  systolic.value
                )}/${truncateObservationValue(
                  ObservationTypes.BloodPressure,
                  diastolic.value
                )}`
              : "";
          addData && addData(bp);
          break;
        case ObservationTypes.Spirometry:
          let sp = fev1
            ? `${truncateObservationValue(
                ObservationTypes.Spirometry,
                fev1.value,
                true
              )}`
            : "";
          addDataFEV1 && addDataFEV1(sp);
          sp = pef
            ? `${truncateObservationValue(
                ObservationTypes.Spirometry,
                pef.value
              )}`
            : "";
          addDataPEF && addDataPEF(sp);
          break;
        default:
          addData && addData(value || "");
          break;
      }
    }

    const data: AggregateData[] = [
      heartRate,
      bloodPressure,
      pulseOximetry,
      weight,
      glucometry,
      temperature,
      spirometry.pef,
      spirometry.fev1,
    ];

    return data;
  }
);
