import React from "react";
import { connect } from "react-redux";

import { Provider, ProviderTableHeader } from "@/domain/provider/model";
import { Table } from "@/components/table";
import {
  deselectProvider,
  ProviderId,
  selectProvider,
  dispatchSetTargetProvider,
  selectAllProviders,
  deselectAllProviders,
  setProviderFilters,
  dispatchSetActiveProvider,
} from "@/domain/provider/redux";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { ResourceType } from "@/library/core/config/resource";
import { TextButton } from "@/components/_buttons/TextButton";
import { RootState } from "@/types";
import { Props, ConnectedProps, DispatchProps } from "./types";
import { PracticeId } from "@/domain/practice/redux/types";
import {
  dispatchSetTargetPractice,
  dispatchSetActivePractice,
} from "@/domain/practice/redux/actions";
import { Practice } from "@/domain/practice/model/types";

const Component: React.FC<Props> = (props) => {
  const {
    data,
    execSearch,
    onPageLimitChange,
    pageLimit,
    paginationConfig,
    sortedState,
    onCreatePatient,
    onEditProvider,
    setTargetProvider,
    setTargetPractice,
    onManageOverrides,
    setActiveProvider,
    setActivePractice,
  } = props;

  const addPatient = (targetProvider: ProviderId) => {
    setTargetProvider(targetProvider);
    onCreatePatient();
  };

  const editProvider = (
    targetProvider: ProviderId,
    targetPractice: PracticeId
  ) => {
    setTargetProvider(targetProvider);
    setTargetPractice(targetPractice);
    onEditProvider();
  };

  const onResetSelectedProviders = () => {
    deselectAllProviders();
  };

  const manageOverrides = (provider: Provider) => {
    setActiveProvider(provider);
    setTargetProvider(provider.id!);
    setActivePractice(provider.practice);
    onManageOverrides(provider);
  };

  const subHeader = [
    <tr key="providerTableHeader">
      <td className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""} />
      <td className={sortedState.sortedColumnIndex === 7 ? "sorted_col" : ""} />
    </tr>,
  ];

  const dataContents = (data || [])
    .map((provider) => provider as Provider)
    .map((provider) => {
      return (
        <tr key={provider.id} className="td-text">
          <td
            className={sortedState.sortedColumnIndex === 0 ? "sorted_col" : ""}
          >
            {provider.firstName}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}
          >
            {provider.lastName}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
          >
            {provider.credentials}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 3 ? "sorted_col" : ""}
          >
            <TextButton
              onClick={() =>
                editProvider(
                  provider.id as ProviderId,
                  provider.practiceId as PracticeId
                )
              }
            >
              {provider.npi ?? "None"}
            </TextButton>
          </td>
          <td
            className={sortedState.sortedColumnIndex === 4 ? "sorted_col" : ""}
          >
            {provider.practice?.name}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 5 ? "sorted_col" : ""}
          >
            {"calcd-threshold"}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 6 ? "sorted_col" : ""}
          >
            {provider.patients ?? "-"}
          </td>
          <td
            className={sortedState.sortedColumnIndex === 7 ? "sorted_col" : ""}
          >
            <div className="dropleft btn-group">
              <button
                className=""
                style={{ backgroundColor: "transparent", border: "none" }}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-boundary="window"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="feather icon-more-horizontal" />
              </button>
              <div
                className="dropdown-menu dropdown-content hide-scroll"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() =>
                    editProvider(
                      provider.id as ProviderId,
                      provider.practiceId as PracticeId
                    )
                  }
                >
                  Edit/View
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => addPatient(provider.id as ProviderId)}
                >
                  Create Patient
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => manageOverrides(provider as Provider)}
                >
                  Manage Parameter Overrides
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    });

  const tableData = subHeader.concat(dataContents);

  return (
    <Table
      paginationConfig={paginationConfig}
      pageLimit={pageLimit}
      onPageLimitChange={onPageLimitChange}
      header={
        <SortableHead
          headers={ProviderTableHeader}
          execSearch={execSearch}
          resourceType={ResourceType.provider}
        />
      }
      data={tableData}
      onNavigate={execSearch}
      resetSelections={onResetSelectedProviders}
      isNonScrollable={true}
    />
  );
};

const mapStateToProps = (state: RootState): ConnectedProps => {
  return {
    selectedPractices: state.practice.selectedPractices,
    selectedProviders: state.provider.selectedProviders,
    providers: state.provider.providers,
    filters: state.provider.filters,
    sortedState: state.provider.sortedState,
  };
};

const mapDispatcherToProps = (dispatch: any): DispatchProps => {
  return {
    setTargetProvider: (id: ProviderId) =>
      dispatch(dispatchSetTargetProvider(id)),
    selectProvider: (provider: Provider) => dispatch(selectProvider(provider)),
    deselectProvider: (provider: Provider) =>
      dispatch(deselectProvider(provider)),
    selectAllProviders: () => dispatch(selectAllProviders()),
    deselectAllProviders: () => dispatch(deselectAllProviders()),
    setProviderFilters: (filters: any) => dispatch(setProviderFilters(filters)),
    setTargetPractice: (id: PracticeId): any =>
      dispatch(dispatchSetTargetPractice(id)),
    setActiveProvider: (provider: Provider): any =>
      dispatch(dispatchSetActiveProvider(provider)),
    setActivePractice: (practice: Practice): any =>
      dispatch(dispatchSetActivePractice(practice)),
  };
};

export const ProviderTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
