import {
  CREATE_USER,
  DESELECT_USER,
  SELECT_USER,
  SET_CURRENT_USER,
  SET_USERS,
  SET_USERS_PAGINATIONCONFIG,
  SET_USER_PAGE_LIMIT,
  SET_USERS_SEARCH_FILTER,
  SET_TARGET_USER,
  RESET_TARGET_USER,
  SET_USERS_SORTED_STATE,
} from "../../../library/constants";
import {
  PaginationConfig,
  UsersFilter,
  UsersSortedState,
} from "../../../library/types";
import { User } from "../model";

export const createUser = (user: User) => ({
  type: CREATE_USER,
  payload: user,
});

export const setUsers = (users: User[]) => {
  return {
    type: SET_USERS,
    payload: users,
  };
};

export const setUsersPaginationConfig = (
  paginationConfig: PaginationConfig
): { type: string; payload: PaginationConfig } => ({
  type: SET_USERS_PAGINATIONCONFIG,
  payload: paginationConfig,
});

export const setUsersSortedState = (payload: UsersSortedState) => {
  return {
    type: SET_USERS_SORTED_STATE,
    payload: payload,
  };
};

export const setUserPageLimit = (
  userPageLimit: number
): { type: string; payload: number } => ({
  type: SET_USER_PAGE_LIMIT,
  payload: userPageLimit,
});

export const selectUser = (user: User) => {
  return {
    type: SELECT_USER,
    payload: user,
  };
};

export const deselectUser = () => {
  return {
    type: DESELECT_USER,
  };
};

export const setUsersSearchFilters = (payload: UsersFilter) => {
  return {
    type: SET_USERS_SEARCH_FILTER,
    payload: payload,
  };
};

export const setTargetUser = (id: string) => {
  return {
    type: SET_TARGET_USER,
    payload: id,
  };
};

export const setCurrentUser = (user: User) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const resetTargetUser = () => {
  return {
    type: RESET_TARGET_USER,
    payload: null,
  };
};
