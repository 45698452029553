import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select, Props as SelectProps } from "@/components/_selects/Select";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";
import { PracticeModel } from "@/domain/practice/model";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { dispatchSetPractices } from "@/domain/practice/redux/actions";
import { Practice } from "@/domain/practice/model/types";

export type Props = {
  name: string;
  selectedId?: string | null;
} & SelectProps;

export const PracticeSelect: React.FC<Props> = (props) => {
  const {
    selectedId,
    name = "practice-select",
    label = "Entity/Branch",
    ...rest
  } = props;

  const [pending, setPending] = useState(false);

  const practices = useSelector(selectPractices);
  const selectedOrganization = useSelector(selectActiveOrganization);
  const dispatch = useDispatch();
  const setPractices = (practices: Practice[]) =>
    dispatch(dispatchSetPractices(practices));

  useEffect(() => {
    const fetch = async () => {
      try {
        setPending(true);
        const practices = (
          await PracticeModel.fetchByOrganizationIds([
            selectedOrganization!.id!,
          ])
        ).map((model) => model.pluckAll());
        setPractices(practices);
      } catch (error) {
        console.error(error);
      } finally {
        setPending(false);
      }
    };

    if (!pending && selectedOrganization?.id && !practices.length) fetch();
  }, [practices, selectedOrganization?.id]);

  const options = practices.map((practice: Practice) => ({
    label: practice.name,
    value: practice.id,
  }));

  const selectedOption = selectedId
    ? options.find((o) => o.value === selectedId)
    : null;

  return (
    <Select
      name={name}
      label={label}
      options={options}
      isMulti={false}
      pending={pending}
      value={selectedOption}
      {...rest}
    />
  );
};
