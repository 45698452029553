import { Func } from "../../types/callback";
import { Receiver } from "../receiver/receiver";
import { Task } from "./task";

/**
 * Responsible for running finally tasks after a Command was executed whether successfully or not.
 * Think of this task runner as your `finally` in a try-catch-finally block.
 */
export class FinallyTask<T> extends Task<T> {
  private finallies!: Func[];

  setFinallies(finallies: Func[]) {
    this.finallies = finallies;
    return this;
  }

  async run(receiver?: Partial<Receiver<T>>): Promise<void> {
    await Promise.all(this.finallies.map(async (final: Func) => await final()));
  }

  static make<T>(...finallies: Func[]): Task<T> {
    const finallyTask: FinallyTask<T> = new FinallyTask<T>();
    return finallyTask.setFinallies(finallies);
  }
}
