import { MODEL_SYNCED, SAVE_ERROR } from "../../constants";
import { Handler, Success } from "../handler";
import { Receiver } from "../receiver/receiver";
import { Task } from "./task";

/**
 * Responsible for Scheduling event handling functions that run on success or failure.
 */
export class ScheduleEventHandlersTask<T> extends Task<T> {
  protected onSuccess!: Handler<T>;
  protected onFailure!: Handler<T>;

  async run(receiver?: Partial<Receiver<T>>): Promise<void> {
    if (receiver && receiver.model && this.onSuccess && this.onFailure) {
      receiver.model.on(
        SAVE_ERROR,
        async (withArg?: any) => await this.onFailure.handle(withArg, receiver)
      );
      receiver.model.on(
        MODEL_SYNCED,
        async () =>
          await this.onSuccess.handle(new Success("Successful Save"), receiver)
      );
    }
  }

  setHandlers(onSuccess: Handler<T>, onFailure: Handler<T>) {
    this.onSuccess = onSuccess;
    this.onFailure = onFailure;
    return this;
  }
}
