export const Languages: Language[] = [
  {
    abbreviation: "en",
    displayName: "English",
  },
  {
    abbreviation: "es",
    displayName: "Spanish",
  },
  {
    abbreviation: "zh",
    displayName: "Chinese",
  },
  {
    abbreviation: "ja",
    displayName: "Japanese",
  },
  {
    abbreviation: "fr",
    displayName: "French",
  },
  {
    abbreviation: "ru",
    displayName: "Russian",
  },
  {
    abbreviation: "ht",
    displayName: "Haitian Creole",
  },
];

export interface Language {
  abbreviation: string | undefined;
  displayName: string;
}
