import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { connect } from "react-redux";

import ReportTable from "./table/reportTable.component";
import { ModelCollection } from "@/library/model";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import {
  attachAssignedUserName,
  attachParentEntities,
  attachPatientConditions,
  attachPatientDeviceHistory,
  attachPatientInsurance,
  attachPatientNotes,
} from "@/domain/patient/redux/middleware";
import { Practice } from "@/domain/practice/model/types";
import { Provider } from "@/domain/provider/model";
import { Organization } from "@/domain/organization/model/types";
import { User } from "@/domain/user/model";
import { ReportTypes, RPMComprehensiveCSVHeader } from "../model/reports";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { CURRENT_DATE } from "@/library/constants";
import { ReportHeader } from "./header/report-header";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { selectedReportType } from "./table/reports.utils";
import "../reports.styles.css";
import { dateFormatter } from "@/pipes/date";
import { Program } from "@/domain/program/model/types";
import { Tag } from "@/domain/tags/model/types";
import { rpmComprehensive } from "@/pages/reports/view/table/rpm-comprehensive-utils";
import { ReactElement } from "react";
import { DateTime } from "luxon";

interface Props {
  selectOrganization: (organization: Organization) => {
    type: string;
    payload: Organization;
  };
  selectedOrganization: Organization | undefined;

  setPatients: (patientModel: PatientModel[]) => void;
  setPatientsState: (
    patientCollection: ModelCollection<PatientModel, Patient>
  ) => void;
  patients: Patient[];
  currentUser: User;
}

interface State {
  reportType: ReportTypes;
  globalFilters: {
    practiceIds: string[] | undefined;
    providerIds: string[] | undefined;
    userIds: string[] | undefined;
  };
  locations: Tag[];
  practices: Practice[];
  programs: Program[];
  providers: Provider[];
  patients: Patient[];
  filteredPatients: Patient[];
  practicePlaceholder: string;
  providerPlaceholder: string;
  selectedPractice: Practice | undefined;
  selectedDateRange: string;
  reportMonthYear: DateTime;
}

type TableData = { [key: string]: string | number };

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reportType: ReportTypes.rpm,
      patients: [],
      filteredPatients: [],
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
        userIds: undefined,
      },
      locations: [],
      practices: [],
      programs: [],
      providers: [],
      practicePlaceholder: "All Entity/Branch",
      providerPlaceholder: "All Providers",
      selectedPractice: undefined,
      selectedDateRange: "",
      reportMonthYear: convertDateToLocalTimezone(CURRENT_DATE),
    };
  }
  setPrograms = (programs: Program[]) => {
    this.setState({ programs });
  };
  handleReportDateRangeChange = (reportMonthYear: Date) => {
    this.setState({
      reportMonthYear: convertDateToLocalTimezone(reportMonthYear),
    });
  };

  handleSelectedPractice = (selectedPractice: Practice) => {
    this.setState(() => ({
      selectedPractice,
    }));
  };

  setLocation = async (locations: Tag[]) => {
    this.setState({ locations });
  };
  setPatientsState = async (
    patientCollection: ModelCollection<PatientModel, Patient>
  ) => {
    const patientsWithAssignedUser = await attachAssignedUserName(
      patientCollection.container.map((patient) => patient.pluckAll())
    );
    const patientWithParentEntities = await attachParentEntities(
      patientsWithAssignedUser
    );
    const patientWithInsurances = await attachPatientInsurance(
      patientWithParentEntities
    );
    const patientWithConditions = await attachPatientConditions(
      patientWithInsurances
    );
    const patientWithNotes = await attachPatientNotes(
      patientWithConditions,
      this.state.reportMonthYear.toJSDate()
    );
    const patientsWithProgram = patientWithNotes.map((patient: Patient) => {
      const program = this.state.programs.find(
        (program) => program._meta?.id === patient.programId
      );
      return {
        ...patient,
        program: program?.shortName,
      };
    });

    let patientsWithDeviceHistory: Patient[];
    if (this.state.reportType === ReportTypes.rpmComprehensive) {
      patientsWithDeviceHistory = await attachPatientDeviceHistory(
        patientsWithProgram,
        this.state.reportMonthYear.toJSDate()
      );
    }

    LoadingIndicator.fire.hide();
    this.setState(() => ({
      patients: patientsWithDeviceHistory
        ? patientsWithDeviceHistory
        : patientsWithProgram,
    }));
  };

  setPracticesState = async (practices: Practice[]) => {
    this.setState({ practices });
  };
  setProvidersState = async (providers: Provider[]) => {
    this.setState({ providers });
  };
  handleChangeReportType = (reportType: ReportTypes): void => {
    this.setState({ reportType });
  };
  setFilteredPatients = async (filteredPatients: Patient[]) => {
    this.setState({ filteredPatients });
  };

  getReportFileName = (extension: string) => {
    const datetime = convertDateToLocalTimezone(CURRENT_DATE);
    return this.state.reportType === ReportTypes.rpmComprehensive
      ? rpmComprehensive.FileName || ""
      : selectedReportType[this.state.reportType]?.FileName ||
          "" +
            datetime.year +
            "_" +
            datetime.month.toString().padStart(2, "0") +
            extension;
  };

  getUserDetails = (): string[] => {
    const userDetail =
      " \nFor " +
      this.state.reportMonthYear +
      "\nReport generated: " +
      dateFormatter({ date: CURRENT_DATE.toISOString() }) +
      "By: **" +
      this.props.currentUser.firstName +
      " " +
      this.props.currentUser.lastName +
      "** ";
    return userDetail.split("**");
  };

  getUserDetailsForCSV = (): TableData => {
    const selectedReportMetaData =
      this.state.reportType === ReportTypes.rpmComprehensive
        ? rpmComprehensive
        : selectedReportType[this.state.reportType];
    const rowData: TableData = {};
    rowData["Report Type"] = selectedReportMetaData?.Heading || "";
    rowData["Month"] =
      this.state.reportMonthYear.toLocaleString({
        month: "long",
        year: "numeric",
      }) || "";
    rowData["Generated"] = dateFormatter({ date: CURRENT_DATE.toISOString() });
    return rowData;
  };

  /**
   * Generating PDF File
   */
  handleExport = (): void => {
    const selectedReportMetaData =
      this.state.reportType === ReportTypes.rpmComprehensive
        ? rpmComprehensive
        : selectedReportType[this.state.reportType];
    let patientData = selectedReportMetaData?.data(
      this.state.filteredPatients,
      this.state.reportMonthYear
    );
    const header = selectedReportMetaData?.header;

    // Remote ID from patient Data
    patientData = patientData?.map((patient: Array<any>) => {
      const arr = [...patient];
      arr.splice(0, 1);
      return arr;
    });
    /**
     * PDF Configuration
     * */
    let startX = 0.6;
    let startY = 1;
    const fontSize = 8;
    const fontStyle = "Helvetica";
    const pdfHeading = selectedReportMetaData?.Heading || "";

    /**
     * Init JSPDF
     */
    const doc: jsPDF = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: "a3",
    });
    doc.setFontSize(10).setFont(fontStyle, "bold");
    /** Changing the name of downloaded PDF file. */
    const reportFileName: string = this.getReportFileName(".pdf");
    doc.text(pdfHeading, startX, startY).setFont(fontStyle, "light");
    this.getUserDetails().forEach((text: string, index: number) => {
      doc.setFont(fontStyle, "bold");
      if (index % 2 === 0) {
        doc.setFont(fontStyle, "light");
      }

      doc.text(text, startX, startY);
      startX = 2.55;
      startY = 1.32;
    });
    autoTable(doc, {
      head:
        this.state.reportType === ReportTypes.rpmComprehensive
          ? [
              [
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                {
                  content: "CPT99454",
                  colSpan: 5,
                  styles: { halign: "center" },
                },
                {
                  content: "CPT99457",
                  colSpan: 3,
                  styles: { halign: "center" },
                },
                {
                  content: "CPT99458",
                  colSpan: 4,
                  styles: { halign: "center" },
                },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
                { content: "", colSpan: 0, styles: { fillColor: "white" } },
              ],
              header,
            ]
          : [header],
      body: patientData,
      startY: 2,
      horizontalPageBreak: true,
      horizontalPageBreakBehaviour: "immediately",
      styles: { fontSize },
      didParseCell: (data) => {
        if (typeof data.cell.raw === "object") {
          const o = data.cell.raw as ReactElement;
          if (o) {
            if ("props" in o && "children" in o.props) {
              data.cell.text = [`${o.props.children}`];
              data.cell.styles.textColor = o.props.className.includes("success")
                ? "green"
                : "red";
            }
            if (o.type === "i") {
              if (o.props.className.includes("check")) {
                data.cell.styles.textColor = "green";
                data.cell.text = ["true"];
              } else {
                data.cell.text = [" "];
              }
            }
          }
        }
      },
    });
    doc.save(reportFileName);
  };

  handleExportToCSV = (): void => {
    const selectedReportMetaData =
      this.state.reportType === ReportTypes.rpmComprehensive
        ? rpmComprehensive
        : selectedReportType[this.state.reportType];
    let patientData = selectedReportMetaData?.data(
      this.state.filteredPatients,
      this.state.reportMonthYear
    );
    const headers =
      this.state.reportType === ReportTypes.rpmComprehensive
        ? RPMComprehensiveCSVHeader
        : selectedReportMetaData?.header;

    // Remote ID from patient Data
    patientData = patientData?.map((patient: Array<any>) => {
      const arr = [...patient];
      arr.splice(0, 1);
      return arr;
    });

    // Extracting the rows
    const headerData: TableData = this.getUserDetailsForCSV();
    const data: TableData[] = patientData?.map((row: string[]) => {
      const rowData: TableData = {};
      row.forEach((value, index) => {
        if (value && typeof value === "object") {
          const newValue = value as ReactElement;
          value = newValue.props?.children && newValue.props.children;
          if (!value) {
            if (newValue.type === "i") {
              if (newValue.props.className.includes("check")) {
                value = "true";
              } else {
                value = " ";
              }
            }
          }
        }
        const header = headers[index];
        rowData[header] = value ?? "";
      });
      return rowData;
    });

    // Convert the extracted table data to CSV
    const csvString: string = data
      ? this.convertToCSV([headerData, ...data])
      : this.convertToCSV([headerData]);
    this.downloadCSV(csvString, this.getReportFileName(".csv"));
  };

  convertToCSV = (arr: TableData[]): string => {
    if (arr.length <= 1) {
      return "";
    }

    const parseArray = (arr: TableData[]) => {
      let result = "";
      arr.forEach((item) => {
        result += keys
          .map((k) => {
            let val = item[k];
            // Check if the value is a large number and format it as text by prepending a tab character
            if (typeof val === "string") {
              // Large number, prepend with a tab character
              val = "\t" + val;
            }
            return `"${val}"`;
          })
          .join(columnDelimiter);
        result += lineDelimiter;
      });
      return result;
    };
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let result = "";

    // Create a table for description first
    let keys = Object.keys(arr[0]);
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    result += parseArray([arr[0]]);
    result += lineDelimiter;

    // Create a table for actual data
    keys = Object.keys(arr[1]);
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    result += parseArray(arr.slice(1));
    return result;
  };

  downloadCSV = (csvString: string, filename: string): void => {
    const blob: Blob = new Blob([csvString], {
      type: "text/csv;charset=utf-8;",
    });
    const url: string = URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <>
        <ReportHeader
          handleChangeReportType={this.handleChangeReportType}
          selectedReportDateRange={this.handleReportDateRangeChange}
          selectedPractice={this.handleSelectedPractice}
          setPatientsState={this.setPatientsState}
          handlePDFExport={this.handleExport}
          handleCSVExport={this.handleExportToCSV}
          setLocation={this.setLocation}
          setPractices={this.setPracticesState}
          setProviders={this.setProvidersState}
          setProgramFunction={this.setPrograms}
        />
        <div>
          <div className="user-dashboard">
            <ReportTable
              reportType={
                !this.state.reportType ? ReportTypes.rpm : this.state.reportType
              }
              patients={this.state.patients}
              programs={this.state.programs}
              reportMonthYear={this.state.reportMonthYear.toLocaleString({
                month: "long",
                year: "numeric",
              })}
              selectedPractice={this.state.selectedPractice}
              locations={this.state.locations}
              practices={this.state.practices}
              providers={this.state.providers}
              setFilteredPatients={this.setFilteredPatients}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: {
  organization: { selectedOrganization: Organization };
  user: { currentUser: User };
}) => {
  return {
    currentUser: state.user.currentUser,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

export const ReportsComponent = connect(mapStateToProps)(Component);
