import { ResponseErrorParser } from "./error.parser";

/**
 * Parses RCA server response errors.
 */
export class RCAResponseErrorParser extends ResponseErrorParser {
  /**
   * Creates a new instance of RCAResponseErrorParser
   *
   * @param error
   */
  static parse(error: any): RCAResponseErrorParser {
    return new RCAResponseErrorParser(error);
  }

  /**
   * Form the message to be displayed to user.
   */
  public message(): string[] {
    const status = this.statusCode();

    if (!!this.error.response.data.detail) {
      return this.error.response.data["detail"];
    }

    if (status === 400) {
      return this.inValidFields().map((field: string) => {
        return `${field} field is invalid`;
      });
    }

    if (status === 401) {
      // TODO: Redirect user to login page. Confirm from product first.
      // But make sure to parse specific 401 message to make sure
      // it is not a cookie issue before redirecting to login.
      return ["Unauthorized Access. Are you sure you are logged in?"];
    }

    if (status === 403) {
      return [this.error.response.data["detail"]];
    }

    return ["There was a problem with processing this action"];
  }

  /**
   * Get all invalid fields.
   *
   * @private
   */
  private inValidFields(): string[] {
    const invalidParams: any[] = this.error.response.data["invalid-params"];
    return invalidParams.map((invalidParam) => {
      // grab the invalid parameter/field from path
      const path: string[] = (invalidParam.path as string).split(".");
      return path[path.length - 1];
    });
  }
}
