export const NpiTableHeaderContent: string[] = [
  "NPI",
  "First Name",
  "Last Name",
  "Credentials",
  "Address",
  "City",
  "State",
  "Zip",
];

export interface NpiProvider {
  npi: string;
  firstName: string;
  lastName: string;
  credential: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
}
