import { TextInput, Select, TextInputMask } from "../../../../components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./form.styles.css";
import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { Validation } from "../../../../components/form";
import { Provider, ProviderForm, ProviderModel } from "../../model";
import {
  ProviderId,
  setProviders,
  setProvidersPaginationConfig,
} from "../../redux";
import { connect } from "react-redux";
import { PaginationConfig } from "../../../../library/types";
import { dispatchSetPractices } from "../../../practice/redux/actions";
import { PracticeId, SetPracticeDispatch } from "../../../practice/redux/types";
import { PracticeModel } from "../../../practice/model";
import { Practice } from "../../../practice/model/types";
import { Credentials, Credential } from "../../../../library/types/credential";
import { Invoker } from "../../../../library/common/invoker/invoker";
import { Receiver } from "../../../../library/common/receiver/receiver";
import { CleanTask, FinallyTask } from "../../../../library/common/task";
import { ResourceType } from "../../../../library/core/config/resource";
import { RCAResourceActions } from "../../../../library/core/config/actions";
import { specialties } from "../../../../library/types/specialties";
import { setPracticesThunk } from "../../../practice/redux/middleware";
import { LoadingIndicator } from "../../../../components/loadingIndicator/loadingIndicator";
import { npiLookup } from "../../../../library/npi/npiLookup";
import { Modal } from "../../../../components/modal";
import NpiLookup from "../NpiLookup/NpiLookup.component";
import { NpiProvider } from "../NpiLookup/npiLookup";
import { ERROR, SOMETHING_WENT_WRONG } from "../../../../library/constants";
import { RCAResponseErrorParser } from "../../../../library/error/parser/rca.error.parser";
import { Notification } from "../../../../components/notification/notification";

interface Props {
  handleClose: () => void;
  button: string;
  setProviders: (providers: Provider[]) => {
    type: string;
    payload: Provider[];
  };
  setPractices: (practices: Practice[]) => {
    type: string;
    payload: Practice[];
  };
  setPracticesThunk: (
    practices: PracticeModel[]
  ) => (dispatch: SetPracticeDispatch) => Promise<void>;
  targetProvider: ProviderId;
  setProvidersPaginationConfig: (paginationConfig: PaginationConfig) => {
    type: string;
    payload: PaginationConfig;
  };
  providers: Provider[];
  providerPageLimit: number;

  paginationConfig: PaginationConfig;

  targetPractice: PracticeId;
  practices: Practice[];
  selectedPractices: Practice[];

  resourceAction: RCAResourceActions | undefined;
}

export const ProviderFormCmp: FunctionComponent<Props> = ({
  handleClose,
  practices,
  ...props
}) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    credentials: "",
    npi: null,
    practiceId: props.targetPractice,
    specialty: "",
    npiFirstName: "",
    npiLastName: "",
    npiState: "",
    phoneNumber: "",
  };

  const getTargetProvider = props.providers.find(
    (provider: Provider) => provider.id === props.targetProvider
  ) as Provider;
  const isEditMode = props.button === "Save Changes";
  const getInitialValues = () => {
    const provider = getTargetProvider;
    if (isEditMode && provider) {
      return {
        id: provider.id,
        firstName: provider.firstName,
        lastName: provider.lastName,
        credentials: provider.credentials,
        npi: provider.npi,
        practiceId: props.targetPractice,
        specialty: provider.specialty || "",
        phoneNumber: provider.phoneNumber || "",
      };
    }

    return initialValues;
  };

  const [photo, setPhoto] = useState(undefined);
  const [imageURL, setImageURL] = useState("");
  const [showNpiLookup, setShowNpiLookup] = useState(false);
  const [npiResults, setNpiResults]: [
    NpiProvider[],
    Dispatch<SetStateAction<any>>
  ] = useState([]);

  const getNpiResults = async (
    firstName: string,
    lastName: string,
    state: string
  ) => {
    if (!firstName && !lastName && !state) {
      setNpiResults([]);
      return;
    }
    const results = await npiLookup(firstName, lastName, state);
    setNpiResults(results);
  };

  const incrementProviderCount = (values: ProviderForm): void => {
    if (props.resourceAction === RCAResourceActions.Create) {
      const practiceToBeIncremented = practices.find(
        (practice: Practice) => practice.id === values.practiceId
      );
      if (practiceToBeIncremented) {
        const changedPractices = practices.map((practice: Practice) => {
          if (practice.id === practiceToBeIncremented.id) {
            practice.providers = (practice.providers as number) + 1;
          }
          return practice;
        });
        props.setPractices(
          changedPractices.map((practice: Practice) => practice)
        );
      }
    }
  };

  let setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  return (
    <>
      <div className={showNpiLookup ? "d-none" : "d-block"}>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={Yup.object({
            firstName: Validation.name,
            lastName: Validation.name,
            credentials: Validation.credentials,
            npi: Validation.npi,
            specialty: Validation.specialty,
            phoneNumber: Validation.phoneNotRequired,
          })}
          validateOnMount
          onSubmit={async (
            values: ProviderForm,
            { setSubmitting, resetForm }
          ) => {
            await Invoker.make<Provider>(
              Receiver.make(
                ProviderModel.serialize(values, props.resourceAction!),
                ResourceType.provider,
                props.resourceAction!
              ),
              CleanTask.make(
                () => resetForm(getInitialValues() as any),
                () => handleClose(),
                () => incrementProviderCount(values)
              ),
              FinallyTask.make(() => setSubmitting(false))
            ).invoke();
          }}
        >
          {(formik) => {
            setFieldValue = formik.setFieldValue;
            return (
              <Form>
                <div className="modal-body">
                  <div className="subject-scroll">
                    <div
                      className="form-row d-flex justify-content-center"
                      id="provider_form"
                    >
                      <div className="col-md-3 mt-3">
                        <Select
                          label="Entity/Branch"
                          name="practiceField"
                          required
                          disabled
                        >
                          <option>No Practice Selected</option>
                          {practices.map((practice: Practice) => {
                            return (
                              <option
                                value={practice.id}
                                selected={
                                  props.targetPractice === practice.id
                                    ? true
                                    : false
                                }
                              >
                                {practice.name}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="col-md-3 mt-3"></div>
                      <div className="col-md-3 mt-3"></div>
                      <div className="col-md-3 mt-3"></div>

                      <div className="col-md-3 mt-3">
                        <TextInput
                          label="First Name"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          required
                          onChange={(e: any) => {
                            formik.setFieldValue("firstName", e.target.value);
                            formik.setFieldValue(
                              "npiFirstName",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextInput
                          label="Last Name"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          required
                          onChange={(e: any) => {
                            formik.setFieldValue("lastName", e.target.value);
                            formik.setFieldValue("npiLastName", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextInput
                          label="NPI"
                          name="npi"
                          type="text"
                          placeholder="NPI"
                          required={false}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <label className="-field" style={{ opacity: "0" }}>
                          d
                        </label>
                        <button
                          className="btn btn-light mt-4 w-50 py-auto text-center"
                          type="button"
                          onClick={async () => {
                            const { firstName, lastName } = formik.values;
                            const state = "";
                            LoadingIndicator.fire.show();
                            try {
                              await getNpiResults(firstName, lastName, state);
                            } catch (error: any) {
                              LoadingIndicator.fire.show();
                              if (error.status || error.response) {
                                Notification.notify(
                                  ERROR,
                                  RCAResponseErrorParser.parse(error).message()
                                );
                              } else {
                                Notification.notify(
                                  ERROR,
                                  SOMETHING_WENT_WRONG
                                );
                                throw error;
                              }
                            }
                            await setShowNpiLookup(
                              showNpiLookup ? false : true
                            );
                            LoadingIndicator.fire.hide();
                          }}
                        >
                          Lookup
                        </button>
                      </div>

                      <div className="col-md-3 mt-3">
                        <Select label="Credentials" name="credentials" required>
                          <option value="">Select Credentials</option>
                          {Credentials.length > 0 &&
                            Credentials.filter((credential: Credential) => {
                              return credential.prescriptive;
                            }).map((credential: Credential, key: number) => {
                              return (
                                <option
                                  key={`credential${key}`}
                                  value={credential.abbreviation}
                                >
                                  {credential.displayName +
                                    " (" +
                                    credential.abbreviation +
                                    ")"}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-3 mt-3">
                        <Select
                          label="Specialty"
                          name="specialty"
                          required={false}
                        >
                          <option value="">Unknown</option>
                          {specialties.map((specialty: string) => {
                            return (
                              <option key={`key${specialty}`} value={specialty}>
                                {specialty}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextInputMask
                          type="tel"
                          value={formik.values.phoneNumber}
                          label="Phone Number"
                          name="phoneNumber"
                          mask="+1 (999) 999-9999"
                          onChange={(e: any) => {
                            formik.setFieldValue("phoneNumber", e.target.value);
                          }}
                          required={false}
                        />
                      </div>
                      <div className="col-md-3 mt-3"></div>
                    </div>

                    <div className="row">
                      <div className="col-6 mt-5">
                        {isEditMode && (
                          <button
                            disabled
                            type="button"
                            className="btn btn-danger sweet-multiple"
                          >
                            Disable
                          </button>
                        )}
                      </div>

                      <div className="col-6 mt-5 d-flex justify-content-end">
                        <button
                          onClick={() => {
                            handleClose();
                            formik.resetForm();
                          }}
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="btn btn-primary"
                        >
                          {props.button}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {showNpiLookup && (
        <NpiLookup
          onSelectProvider={(provider: NpiProvider) => {
            setFieldValue("npi", provider.npi);
            setFieldValue("firstName", provider.firstName);
            setFieldValue("lastName", provider.lastName);
            setFieldValue("credentials", provider.credential);
            setShowNpiLookup(false);
          }}
          handleClose={() => setShowNpiLookup(false)}
          npiResults={npiResults}
        ></NpiLookup>
      )}
    </>
  );
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setProviders: (providers: Provider[]): any =>
      dispatch(setProviders(providers)),
    setPracticesThunk: (
      practices: PracticeModel[]
    ): ((dispatch: SetPracticeDispatch) => Promise<void>) =>
      dispatch(setPracticesThunk(practices)),
    setPractices: (practices: Practice[]): any =>
      dispatch(dispatchSetPractices(practices)),
    setProvidersPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setProvidersPaginationConfig(paginationConfig)),
  };
};

const mapStateToProps = (state: {
  practice: {
    targetPractice: PracticeId;
    practices: Practice[];
    selectedPractices: Practice[];
  };
  provider: {
    providers: Provider[];
    targetProvider: ProviderId;
    providerPageLimit: number;
    paginationConfig: PaginationConfig;
  };
}) => ({
  targetPractice: state.practice.targetPractice,
  selectedPractices: state.practice.selectedPractices,

  providers: state.provider.providers,
  targetProvider: state.provider.targetProvider,
  providerPageLimit: state.provider.providerPageLimit,
  paginationConfig: state.provider.paginationConfig,
  practices: state.practice.practices,
});

export const ProviderFormComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ProviderFormCmp);
