export type CountdownProps = {
  /**
   * Should timer run
   */
  running: boolean;
  /**
   * Initial countdown time
   */
  offsetTimestamp: Date;
  /**
   * State of the timer
   */
  timerActivityState: TimerActivityState;
  /**
   * Default background color of timer
   */
  timerColor: string;
  /**
   * Event fire on timer click
   */
  onClick: (timerState: TimerState) => void;
  /**
   * Fire when timer goes inactive yellow
   */
  onIdle: (timerState: TimerState) => void;
  /**
   * Fire when timer stopped due to inactivity red
   */
  onInactiveStopped: (timerState: TimerState) => void;
  /**
   * Keep emitting the latest state of timer
   */
  connectToTimerState: (timerState: TimerState) => void;
  /**
   * Fires when user State change to active
   */
  onActiveUser: (timerState: TimerState) => void;
};

export interface TimerState {
  seconds: number;
  minutes: number;
  hours: number;
  isRunning: boolean;
}

const isPatientBannerRevamp = _env_.ENABLE_PATIENT_BANNER_REVAMP === "true";

export const TimerColors = {
  Blue: isPatientBannerRevamp ? "#030575" : "#4472c4",
  Red: isPatientBannerRevamp ? "#DB0B0B" : "#ed3833",
  Yellow: isPatientBannerRevamp ? "#FFCE1F" : "#fdf851",
  Gray: isPatientBannerRevamp ? "#BBBBBB" : "#8c8989",
};

export const TimerTextColor = {
  Black: isPatientBannerRevamp ? "#464646" : "#000000",
  White: "#FFFFFF",
};

export enum TimerActivityState {
  INITIAL = "INITIAL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PAUSED = "PAUSED",
}

export enum TimerUserState {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
