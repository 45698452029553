import { useEffect, useState } from "react";
import { video } from "@/assets/icons/observations";
import { HealthSnapshot } from "@/domain/patient/model/types";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";
import { ServiceLevel } from "@/domain/kits/models/device.interface";
import { DateTime } from "luxon";
import { DATE_TIME_HOUR_MIN_FORMAT } from "@/pipes/date/constants";

export type OwnProps = {
  patient: any;
  patientHealth: HealthSnapshot;
  onVirtualVisit: () => void;
};

export type Props = OwnProps & Partial<IconContainerProps>;
export const VideoVisitRequest: React.FC<Props> = (props) => {
  const [color, setColor] = useState("");
  const { patient, patientHealth, ...rest } = props;
  const patientNotes =
    patient.notes?.filter((note: any) => {
      if ("requestType" in note) {
        if (
          !note.isComplete &&
          note.type === "PATIENT_REQUEST" &&
          note.requestType === "VIDEO"
        ) {
          return note;
        }
      }
    }) || [];
  const dateTimeFormat = `${DateTime.fromJSDate(
    new Date(patientNotes[patientNotes?.length - 1]?.time)
  )
    .toUTC()
    .toFormat(DATE_TIME_HOUR_MIN_FORMAT)}
     (${DateTime.local().toFormat("ZZZZ")})`;

  useEffect(() => {
    if (patientNotes.length >= 1) {
      setColor("red");
    } else {
      setColor("white");
    }
  }, [patientNotes]);

  return (
    <IconContainer
      {...rest}
      color={color}
      id={`obs-video-visit-${patient?.id}`}
      size="sm"
      src={video}
      onClick={props.onVirtualVisit}
      tooltipContent={
        !patient.deviceInfo ||
        (patient.deviceInfo &&
          patient.deviceInfo[0]?.serviceLevel !== ServiceLevel.RemoteCareLive)
          ? "Patient does not have a video-capable device"
          : null
      }
      disabled={
        !patient.deviceInfo ||
        patient?.deviceInfo[0]?.serviceLevel !== ServiceLevel.RemoteCareLive
      }
    >
      <div className="text-center">
        <p style={{ fontSize: 12 }}>
          {patientNotes.length >= 1 && dateTimeFormat}
        </p>
      </div>
    </IconContainer>
  );
};
