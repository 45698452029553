import produce from "immer";

import { Note } from "@/domain/notes/model/types";
import { Patient } from "@/domain/patient/model/types";
import { UserPatientTimerState } from "@/domain/patient/redux/types";
import { PatientNoteType } from "@/library/types/note";
import { PERSIST_KEY } from "@/redux/store";
import { RootState } from "@/types";
import { TimerUserState } from "@/components/countdown/types";

const persistKey = "persist:" + PERSIST_KEY;
const timeUserState = TimerUserState.ENABLED;

export const handlePageUnload = (
  userPatientId: string,
  timer: UserPatientTimerState
) => {
  const persistedValues = localStorage.getItem(persistKey);
  if (persistedValues) {
    const parsed = JSON.parse(persistedValues);
    const updatedValues = produce<RootState>(parsed, (draft) => {
      draft.patient.timerByUserPatientId[userPatientId] = timer;
    });

    localStorage.setItem(persistKey, JSON.stringify(updatedValues));
  }
};

export const calculateMilliSeconds = (user: UserPatientTimerState) => {
  return (
    ((user?.hours || 0) * 60 * 60 +
      (user?.minutes || 0) * 60 +
      (user?.seconds + 0.5 || 0)) *
    1000
  );
};

export const getUserPatientPreviousTime = (
  user: UserPatientTimerState | undefined
): Date => {
  if (!user) return new Date();
  const milliseconds = calculateMilliSeconds(user);
  const d = new Date();
  d.setMilliseconds(milliseconds);
  return d;
};

export const createTimerNote = (
  timerState: UserPatientTimerState,
  patient: Patient
) => {
  return {
    patientId: patient.id,
    type: PatientNoteType.Timelog,
    comment: "Time Log Recorded",
    directPatientContact: false,
    seconds: timerState.seconds + timerState.minutes * 60,
    intervention: ["NO_ACTION"],
    replyToIds: [],
  } as unknown as Note;
};
