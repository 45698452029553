import { useEffect, useRef, useState } from "react";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
// import { TabList, TabPanel, TabContext } from "@mui/lab";
import { initialArrayType, Props } from "./types";
import cx from "clsx";
import styles from "./styles.module.scss";
import "./styles.module.scss";
import { AlertsTable } from "../../AlertsTableNew";
import { selectAlertsTableData } from "../../AlertsTableNew/selectors";
import {
  dispatchSetMonitoringStartDate,
  dispatchSetMonitoringEndDate,
  dispatchToggleMonitoringTabVisibility,
  dispatchSetAcceptReject,
  dispatchSetSelectedPatientRules,
} from "@/domain/patient/redux/actions";
import { ObservationTypes } from "@/domain/patient/model/constants";
import {
  selectFirstPatient,
  selectMonitoringEndDate,
  selectMonitoringStartDate,
  selectMonitoringTabVisibility,
  selectNoteCreatedOn,
} from "@/domain/patient/redux/selectors";
import { selectAggregateTableData } from "./selectors";

import { NoteDisposition } from "@/domain/notes/model/types";
import { AlertTableRowNew } from "@/domain/patient/view/AlertsTableNew/types";
import {
  biometryEntryMethod,
  exportPatientDetail,
  getAllNotes,
  getUnresolvedNotes,
} from "./helpers";
import {
  dispatchAddRejectedNoteId,
  dispatchSaveCreateNoteAlert,
  dispatchSelectedNoteIds,
  setPatientNotes,
  setUnresolvedPatientNotes,
} from "@/domain/notes/redux/actions";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { PracticeModel } from "@/domain/practice/model";
import { ProviderModel } from "@/domain/provider/model";
import {
  BloodPressureTab,
  BloodPressureTabSelected,
  ChartIcon,
  ChartSelectedIcon,
  ChartTableIcon,
  ChartTableIconSelected,
  EmptyAlert,
  ExpandChartIcon,
  ExportChartIcon,
  GlucoseTab,
  GlucoseTabSelected,
  HeartRateTab,
  HeartRateTabSelected,
  NextPage,
  PreviousPage,
  PrintChartIcon,
  PulseOximetryTab,
  PulseOximetryTabSelected,
  SpirometryTab,
  SpirometryTabSelected,
  TemperatureTab,
  TemperatureTabSelected,
  WeightTab,
  WeightTabSelected,
} from "@/assets/icons/dashboard";
import { useHistory, useParams } from "react-router-dom";
import { PatientTab, RouteParams } from "../../PatientDetails/types";
import { RuleModel } from "@/domain/rules/model";
import { selectRulesForPatient } from "@/domain/rules/redux/selectors";
import { keyBy } from "lodash";
import { dispatchSetPatientRules } from "@/domain/rules/redux/actions";
import { RootState } from "@/types";
import { DateTime } from "luxon";
import { DAYS_LIMIT } from "../../tabs/summary/constants";
import { PatientObservation } from "@/domain/patient/model/types";
import {
  getCreateNoteAlertText,
  selectObservationsFromPatientNotes,
} from "@/domain/notes/redux/selectors";
import { Figure } from "../../Figure";
import { ObservationType } from "@/domain/observations/types";
import HeartRateChart from "../../ChartsNew/HeartRateChart";
import { BloodPressureChart } from "../../ChartsNew/BloodPressureChart";
import PulseOximetryChart from "../../ChartsNew/PulseOximetryChart";
import { WeightChart } from "../../ChartsNew/WeightChart";
import { GlucometryChart } from "../../ChartsNew/GlucometryChart";
import { TemperatureChart } from "../../ChartsNew/TemperatureChart";
import SpirometryChart from "../../ChartsNew/SpirometryChart";
import DateRangePickerReact from "@/components/DateRangePicker";
import { ObservationTable } from "../../tablesNew";
import { TAB_CONSTANTS, TAB_MODES } from "./constants";
import { Message, useToaster } from "rsuite";

import { makeInitialValues } from "@/domain/rules/view/ObservationParameters/helpers";
import { Rule, RuleOverrides } from "@/domain/rules/model/types";

export const NoDataAvailable = (props: { className: string }) => {
  return (
    <div className={styles[props.className]}>
      <div>
        <div className={cx(styles.alertEmptyImg)}>
          <img src={EmptyAlert} alt="empty" />
        </div>
        <div>
          <label className={cx(styles.emptyAlertText)}>
            {"No data to display"}
          </label>
        </div>
      </div>
    </div>
  );
};

export const MonitoringTab: React.FC<Props> = (props) => {
  const [providerName, setProviderName] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [showChart, setShowChart] = useState(true);
  const [overrideArray, setOverrideArray] = useState<initialArrayType>([]);
  const [selectedRows, setSelectedRows] = useState<AlertTableRowNew[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [graphMode, setGraphMode] = useState<number>(TAB_MODES.Chart);
  const [divHeight, setDivHeight] = useState<string>("");

  const alertsTableRef = useRef<{ reset: () => void }>(null);
  const noteFormRef = useRef<{ submit: () => void }>(null);

  const patient = useSelector(selectFirstPatient);
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const alertsData = useSelector(selectAlertsTableData);
  const noteCreatedOn = useSelector(selectNoteCreatedOn);
  const [alertsCount, setAlertsCount] = useState(alertsData?.length);
  const dispatch = useDispatch();
  const { id: patientId = "", practiceId, providerId } = patient || {};
  const history = useHistory();
  const params = useParams<RouteParams>();
  const rules = useSelector(selectRulesForPatient(patient?.id));
  const rulesbyType = rules ? keyBy(rules, "trigger.observationType") : {};
  const rulesbyTypeNew = overrideArray ? keyBy(overrideArray, "name") : {};

  const aggregateData = useSelector(selectAggregateTableData);
  const observations =
    useSelector<RootState, PatientObservation[]>(
      selectObservationsFromPatientNotes
    ) || [];

  const alertText = useSelector(getCreateNoteAlertText);
  const toaster = useToaster();
  const message = <Message>{alertText}</Message>;

  useEffect(() => {
    if (alertText) {
      toaster.push(message, {
        placement: "bottomCenter",
        duration: 5000,
        className: "toastClassMonitor",
      });
      setTimeout(() => {
        dispatch(dispatchSaveCreateNoteAlert(""));
      }, 2000);
    }
  }, [alertText]);

  useEffect(() => {
    const fetchRules = async () => {
      if (!patientId) return;
      const ruleModels =
        (await RuleModel.fetchByParams({
          limit: "100",
        })) || [];
      const rules = ruleModels.map((m) => m.pluckAll());
      let initialArray: initialArrayType[] = [];
      rules.map((rule) => {
        let initialValues = makeInitialValues(
          rule,
          (patient?.ruleVariables[rule?.id ?? 0] as RuleOverrides) || {}
        );
        initialArray.push({
          name: rule.trigger?.observationType,
          values: initialValues,
        });
      });
      setOverrideArray(initialArray);
      dispatch(dispatchSetPatientRules(patientId, rules));
    };
    fetchRules();
  }, [patientId]);

  useEffect(() => {
    setAlertsCount(alertsData?.length);
  }, [alertsData]);

  useEffect(() => {
    const init = async () => {
      if (!patient || !practiceId || !providerId) return;

      LoadingIndicator.fire.show();
      const practiceModel: PracticeModel = await PracticeModel.sync(practiceId);
      const providerModel: ProviderModel = await ProviderModel.sync(providerId);
      setProviderName(
        `${providerModel.pluckAll().firstName} ${
          providerModel.pluckAll().lastName
        }`
      );
      setPracticeName(practiceModel.pluckAll().name);
      LoadingIndicator.fire.hide();
    };

    init();
  }, [patientId]);

  const fetchNotes = async () => {
    let allNotes;
    let unresolvedNotes;

    try {
      allNotes = await getAllNotes(patientId);
      unresolvedNotes = await getUnresolvedNotes(patientId);
    } catch (error: Error | unknown) {
      console.error(error);
    }

    if (allNotes) dispatch(setPatientNotes(patientId, allNotes));
    if (unresolvedNotes)
      dispatch(setUnresolvedPatientNotes(patientId, unresolvedNotes));
  };

  useEffect(() => {
    if (patientId) fetchNotes();
  }, [patientId]);

  useEffect(() => {
    if (noteCreatedOn) setTimeout(() => fetchNotes(), 500);
  }, [noteCreatedOn]);

  useEffect(() => {
    // Reset table selection
    if (alertsTableRef.current) alertsTableRef.current.reset();
    setSelectedRows([]);
  }, [isoStartDate, isoEndDate]);

  useEffect(() => {
    dispatch(dispatchSelectedNoteIds([]));
    let div = document.getElementById("patientBannerView");
    if (div) {
      setDivHeight(`calc( 100vh - ${div.offsetHeight + 40}px )`);
    }
  }, []);

  if (!patient) return null;

  const saveSelectedAlerts = (noteIds: AlertTableRowNew[]) => {
    dispatch(dispatchSelectedNoteIds(noteIds));
  };
  const rejectedAction = (noteIds: AlertTableRowNew[]) => {
    saveSelectedAlerts(noteIds);
    dispatch(dispatchSetSelectedPatientRules(rulesbyTypeNew));
    dispatch(dispatchSetAcceptReject(false));
    history.push(`/patient/${patientId}/${PatientTab.Monitoring}/createNote`);
  };

  const acceptedAction = (noteIds: AlertTableRowNew[]) => {
    saveSelectedAlerts(noteIds);
    dispatch(dispatchSetSelectedPatientRules(rulesbyTypeNew));
    dispatch(dispatchSetAcceptReject(true));
    history.push(`/patient/${patientId}/${PatientTab.Monitoring}/createNote`);
  };

  /*****************************Charts section************************************* */

  const handleStartDateChange = async (startDate: Date) => {
    const start = DateTime.fromJSDate(startDate).toJSDate();
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };
  const handleEndDateChange = async (endDate: Date) => {
    const end = DateTime.fromJSDate(endDate).toJSDate();
    dispatch(dispatchSetMonitoringEndDate(end.toISOString()));
  };
  const handlePrevClick = () => {
    const start = DateTime.fromISO(isoStartDate)
      .minus({ days: DAYS_LIMIT })
      .toJSDate();
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };
  const handleNextClick = () => {
    const start = DateTime.fromISO(isoStartDate)
      .plus({ days: DAYS_LIMIT })
      .toJSDate();
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };
  const startDate = DateTime.fromISO(isoStartDate).startOf("day").toJSDate();
  const endDate = DateTime.fromISO(isoEndDate).endOf("day").toJSDate();
  const startMax = DateTime.now()
    .minus({ days: DAYS_LIMIT - 1 })
    .toJSDate();
  const endMax = DateTime.now().toJSDate();
  const now = DateTime.now();
  const patientDetail = exportPatientDetail({
    patient,
    provider: providerName,
    practice: practiceName,
    startDate,
    endDate,
  });

  const handleToggleFigure = (tab: ObservationType) => () => {
    dispatch(dispatchToggleMonitoringTabVisibility(tab));
  };
  const nextDisabled = now.toISODate() === isoEndDate;

  const renderCharts = () => {
    if (!showChart) return null;

    if (!observations.length) {
      return <NoDataAvailable className="noDataAvailableFullScreen" />;
    }

    return (
      <div className={styles.chartsDiv}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs value={activeTab} variant="scrollable">
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.BloodPressure
                        ? BloodPressureTabSelected
                        : BloodPressureTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.BloodPressure
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    mmHg
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.BloodPressure}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.BloodPressure);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.HeartRate
                        ? HeartRateTabSelected
                        : HeartRateTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.HeartRate
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    bpm
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.HeartRate}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.HeartRate);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.PulseOximetry
                        ? PulseOximetryTabSelected
                        : PulseOximetryTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.PulseOximetry
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    %
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.PulseOximetry}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.PulseOximetry);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.Weight
                        ? WeightTabSelected
                        : WeightTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.Weight
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    lbs
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.Weight}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.Weight);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.GlucoseLevel
                        ? GlucoseTabSelected
                        : GlucoseTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.GlucoseLevel
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    mg/dL
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.GlucoseLevel}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.GlucoseLevel);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.Temperature
                        ? TemperatureTabSelected
                        : TemperatureTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.Temperature
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    °F
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.Temperature}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.Temperature);
              }}
            />
            <Tab
              label={
                <div className={styles.tabsTitleDiv}>
                  <img
                    src={
                      activeTab == TAB_CONSTANTS.Spirometry
                        ? SpirometryTabSelected
                        : SpirometryTab
                    }
                    alt=""
                    className={styles.tabIcon}
                  />
                  <label
                    className={
                      activeTab == TAB_CONSTANTS.Spirometry
                        ? styles.tabsTitleSelected
                        : styles.tabsTitle
                    }
                  >
                    PEF FEV1
                  </label>
                </div>
              }
              value={TAB_CONSTANTS.Spirometry}
              onClick={() => {
                setActiveTab(TAB_CONSTANTS.Spirometry);
              }}
            />
          </Tabs>
          <div className={styles.graphContainer} id="graphContainerDiv">
            {activeTab == TAB_CONSTANTS.BloodPressure && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <BloodPressureChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.BloodPressure &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.HeartRate]]}
                    observationType={ObservationType.BloodPressure}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.HeartRate && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <HeartRateChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.HeartRate &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.BloodPressure]]}
                    observationType={ObservationType.HeartRate}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.PulseOximetry && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <PulseOximetryChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.PulseOximetry &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.PulseOximetry]]}
                    observationType={ObservationType.PulseOximetry}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.Weight && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <WeightChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.Weight &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.Weight]]}
                    observationType={ObservationType.Weight}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.GlucoseLevel && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <GlucometryChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.GlucoseLevel &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.GlucoseLevel]]}
                    observationType={ObservationType.GlucoseLevel}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.Temperature && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <TemperatureChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.Temperature &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[aggregateData[TAB_CONSTANTS.Temperature]]}
                    observationType={ObservationType.Temperature}
                  />
                )}
              </div>
            )}
            {activeTab == TAB_CONSTANTS.Spirometry && (
              <div className={cx(styles.graphRenderDiv, "overflow-auto h-100")}>
                {graphMode === TAB_MODES.Chart ? (
                  <SpirometryChart
                    observationData={observations.filter(
                      (observation: PatientObservation) =>
                        observation.type === ObservationTypes.Spirometry &&
                        observation.source !==
                          biometryEntryMethod.ClinicianEntry
                    )}
                    patientDetail={patientDetail}
                  />
                ) : (
                  <ObservationTable
                    data={[
                      aggregateData[TAB_CONSTANTS.Spirometry],
                      aggregateData[7],
                    ]}
                    observationType={ObservationType.Spirometry}
                  />
                )}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  };

  return (
    <>
      <div
        className={"monitoringParentContainer d-flex justify-content-between"}
      >
        {alertsData.length >= 1 ? (
          <div
            className={cx(styles.alertDiv)}
            style={{ height: divHeight ?? null }}
          >
            <label
              className={cx(styles.alertsTitle)}
            >{`Alerts (${alertsData?.length})`}</label>
            <div className={cx(styles.alertTable)}>
              <AlertsTable
                data={alertsData}
                acceptedAction={(data: AlertTableRowNew[]) =>
                  acceptedAction(data)
                }
                rejectedAction={(data: AlertTableRowNew[]) =>
                  rejectedAction(data)
                }
                rulesbyType={rulesbyTypeNew}
              />
            </div>
          </div>
        ) : (
          <div
            className={cx(styles.alertEmpty)}
            style={{ height: divHeight ?? null }}
          >
            <div className="">
              <div className={cx(styles.alertEmptyImg)}>
                <img src={EmptyAlert} alt="empty" />
              </div>
              <div>
                <label
                  className={cx(styles.emptyAlertText)}
                >{`No new alerts`}</label>
              </div>
            </div>
          </div>
        )}
        <div
          className={cx(styles.graphsDiv)}
          style={{ height: divHeight ?? null }}
        >
          <div className="monitoringTab">
            <div className="d-flex justify-content-between">
              <DateRangePickerReact
                startDate={startDate}
                endDate={endDate}
                startMax={startMax}
                endMax={endMax}
                onStartChange={handleStartDateChange}
                onEndChange={handleEndDateChange}
                containerClassName={styles.dateRangeContainer}
              />
              <div className="d-flex">
                <div onClick={() => setGraphMode(TAB_MODES.Chart)}>
                  <img
                    src={
                      graphMode === TAB_MODES.Chart
                        ? ChartSelectedIcon
                        : ChartIcon
                    }
                    alt="chartIcon"
                    className="pointer"
                  />
                </div>
                <div onClick={() => setGraphMode(TAB_MODES.Table)}>
                  <img
                    src={
                      graphMode === TAB_MODES.Table
                        ? ChartTableIconSelected
                        : ChartTableIcon
                    }
                    alt="tableIcon"
                    className="pointer"
                  />
                </div>
              </div>
            </div>

            {renderCharts()}
            <div className={cx("d-flex justify-content-end")}>
              <div>
                <div className="d-flex">
                  <div onClick={handlePrevClick}>
                    <img
                      src={PreviousPage}
                      alt="previous"
                      className="pointer"
                    />
                  </div>
                  <div
                    className={
                      nextDisabled
                        ? styles.nextDisabled
                        : styles.nextPageActionDiv
                    }
                    onClick={() => (nextDisabled ? {} : handleNextClick())}
                  >
                    <img src={NextPage} alt="next" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
