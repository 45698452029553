import { Row } from "@tanstack/react-table";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import cx from "clsx";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  row: Row<any>;
  showExpand?: boolean;
};

export const ExpandIcon: React.FC<Props> = (props) => {
  const { row, showExpand } = props;

  const canExpand = row.getCanExpand();

  return canExpand || showExpand ? (
    <div
      className={cx(
        styles.expandContainer,
        !canExpand && globalStyles.disabled
      )}
    >
      <button
        className={cx(globalStyles.buttonReset, styles.arrowBtn)}
        onClick={row.getToggleExpandedHandler()}
        disabled={!canExpand}
      >
        <ArrowDownIcon
          style={{
            transform: row.getIsExpanded() ? undefined : "rotate(-90deg)",
          }}
        />
      </button>
    </div>
  ) : null;
};
