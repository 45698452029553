import { createStore, applyMiddleware, Reducer, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { organizationReducer } from "@/domain/organization/redux/reducer";
import { practiceReducer } from "@/domain/practice/redux/reducer";
import { providerReducer } from "@/domain/provider/redux";
import { patientReducer } from "@/domain/patient/redux/reducer";
import { userReducer } from "@/domain/user/redux";
import { tagReducer } from "@/domain/tags/redux/reducer";
import { noteReducer } from "@/domain/notes/redux/reducer";
import { rulesReducer } from "@/domain/rules/redux/reducer";
import { assessmentReducer } from "@/domain/assessment/redux/reducer";
import { kitsReducer } from "@/domain/kits/redux/reducer";
import { INITIAL_STATE as organizationState } from "@/domain/organization/redux/reducer";
import { INITIAL_STATE as practiceState } from "@/domain/practice/redux/reducer";
import { INITIAL_STATE as providerState } from "@/domain/provider/redux/provider.reducer";
import { INITIAL_STATE as patientState } from "@/domain/patient/redux/reducer";
import { INITIAL_STATE as userState } from "@/domain/user/redux/user.reducer";
import { INITIAL_STATE as tagState } from "@/domain/tags/redux/reducer";
import { INITIAL_STATE as notesState } from "@/domain/notes/redux/reducer";
import { INITIAL_STATE as rulesState } from "@/domain/rules/redux/reducer";
import { INITIAL_STATE as assessmentState } from "@/domain/assessment/redux/reducer";
import { INITIAL_STATE as kitsState } from "@/domain/kits/redux/reducer";
import { RootState } from "@/types";

export const PERSIST_KEY = "maverick-web";
const persistConfig = {
  key: PERSIST_KEY,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["kits", "rules"],
  blacklist: ["patient", "notes"], // Nested configs within each reducer
};

const initialState = {
  organization: organizationState,
  practice: practiceState,
  provider: providerState,
  patient: patientState,
  user: userState,
  tag: tagState,
  notes: notesState,
  rules: rulesState,
  assessment: assessmentState,
  kits: kitsState,
};

export const rootReducer = combineReducers({
  organization: organizationReducer,
  practice: practiceReducer,
  provider: providerReducer,
  patient: patientReducer,
  user: userReducer,
  tag: tagReducer,
  notes: noteReducer,
  rules: rulesReducer,
  assessment: assessmentReducer,
  kits: kitsReducer,
});

const storeFactory = (initalState: Partial<RootState>) => {
  const persistedReducer = persistReducer(
    persistConfig,
    rootReducer as Reducer
  );
  const logger = createLogger({ collapsed: true });
  const middleware = [logger, thunk];
  const store = createStore(
    persistedReducer,
    initalState,
    applyMiddleware(...middleware)
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

export const { store, persistor } = storeFactory(initialState);
