import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FC, SyntheticEvent, useState } from "react";
import { PatientInfo } from "@/components/virtualVisit/patientInfo";
import { VirtualVisitTabs } from "@/components/virtualVisit/tabs";
import { TabContainer } from "@/components/virtualVisit/tabContainer";
import { VirtualVisitNote } from "@/components/virtualVisit/note";
import { Participant } from "@/components/virtualVisit/participant";

export interface LeftPanelProps {
  patientName: string;
  patientGender: "Male" | "Female";
  patientAge: number;
  onNoteChanged: (event: SyntheticEvent) => void;
  room: any;
  remoteParticipants: any[];
  isMultiParticipants: any;
  isMicMutted: boolean;
  toggleMic: () => void;
}

export const LeftPanel: FC<LeftPanelProps> = ({
  patientName,
  patientGender,
  patientAge,
  onNoteChanged,
  room,
  remoteParticipants,
  isMicMutted,
  toggleMic,
  isMultiParticipants,
}) => {
  const [value, setValue] = useState(1);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        padding: theme.spacing(2),
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "16px 0px 0px 16px",
      })}
    >
      <Stack spacing={2}>
        <PatientInfo
          name={patientName}
          gender={patientGender}
          age={patientAge}
        />
        <VirtualVisitTabs value={value} handleChange={handleChange} />
        <TabContainer index={0} value={value}>
          <VirtualVisitNote onChange={onNoteChanged} />
        </TabContainer>
        <TabContainer index={1} value={value}>
          {room && (
            <Box
              sx={(theme) => ({
                position: "absolute",
                width: "262px",
                height: "250px",
                borderRadius: "16px 16px 16px 16px",
                overflowX: "hidden",
                overflowY: "auto",
              })}
            >
              <Participant
                width="100%"
                height="auto"
                isMicMutted={isMicMutted}
                key={room.localParticipant.identity}
                room={room}
                name={patientName}
                participant={room.localParticipant}
              />
            </Box>
          )}
          {/* {participants.map((participant: any) => (
            <Participant
              key={participant.identity}
              room={room}
              name={patientName}
              participant={participant}
            />
          ))} */}
        </TabContainer>
      </Stack>
    </Box>
  );
};
