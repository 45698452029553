import { Tooltip as RsuiteTooltip, Whisper } from "rsuite";
import { FunctionComponent } from "react";
import cx from "clsx";

import { Props } from "./types";
import styles from "./styles.module.scss";

const DEFAULT_DELAY = 500;

export const Tooltip: FunctionComponent<Props> = ({
  tooltipContent,
  children,
  tooltipClassName,
  tooltipStyle = {},
  ...props
}) => {
  if (!children) return null;

  return (
    <Whisper
      placement={props.placement || "top"}
      controlId="control-id-hover"
      delay={props.delay ? props.delay : DEFAULT_DELAY}
      trigger={props.trigger || ["hover", "click"]}
      className={props.className}
      style={props.style}
      {...props}
      speaker={
        <RsuiteTooltip style={{ opacity: 1, zIndex: 9999, lineHeight: 1.4 }}>
          {children || tooltipContent}
        </RsuiteTooltip>
      }
    >
      <span
        className={cx(
          styles.tooltip,
          styles.noBorder,
          props.disableTextTruncate && "text-truncate",
          tooltipClassName
        )}
        style={tooltipStyle}
      >
        {children || tooltipContent}
      </span>
    </Whisper>
  );
};
