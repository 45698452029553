import { RoleModel } from "../../domain/role/role.model";
import { PolicyRole } from "./policy.interface";

export class Scope {
  constructor(
    private readonly roles: PolicyRole[],
    public readonly parser: ScopeParser = ScopeParser.parse("")
  ) {}

  /**
   * Returns a role common to all scopes.
   */
  async role(): Promise<RoleModel | null> {
    const roleId = this.roles[0].roleId;
    try {
      const role: RoleModel = await RoleModel.sync(roleId);
      return role;
    } catch (e) {
      // TODO: create a NullModel and return it
      return null;
    }
  }

  getRoleId(): string {
    return this.roles[0]?.roleId;
  }

  getRoleIds(): string[] {
    return this.roles.map((role) => role.roleId);
  }
}

export class ScopeParser {
  static parse(name: string) {
    const scope = name.split(":");

    return new ScopeParser(scope[0], scope[1]);
  }

  private constructor(
    public readonly entity: string,
    public readonly entityId: string
  ) {}
}
