import { EventEmitter } from "@/library/core/event";
import { RemoteCareAPISync } from "@/library/core/api";
import { Attributes, deserialize, Model } from "@/library/model";
import { Http } from "@/library/core/api/http";
import { Assessment } from "./types";

export class AssessmentModel extends Model<Assessment> {
  static readonly path = "assessments";
  /**
   * Makes a new instance of AssessmentModel. It requires only the attributes
   * that would be used to instantiate new Assessment Model.
   *
   * @param attributes
   */
  static make(attributes: Assessment): AssessmentModel {
    const assessmentModel = new AssessmentModel(
      new Attributes<Assessment>({ ...attributes }),
      new RemoteCareAPISync<Assessment>(AssessmentModel.path),
      new EventEmitter()
    );

    return assessmentModel;
  }

  /**
   * Sync Assessment by assessment Id.
   * @param assessmentId
   */
  static sync = async (id: string): Promise<AssessmentModel> =>
    deserialize(
      (await new RemoteCareAPISync<Assessment>(AssessmentModel.path).fetch(id))
        .data,
      AssessmentModel.make
    );

  static async getAssessmentTemplates(): Promise<Assessment[]> {
    const response = await Http().get(
      `${RemoteCareAPISync.host}/assessment-templates?type=PATHWAY_ASSESSMENT`
    );
    return response.data;
  }
}
