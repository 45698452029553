import React from "react";

import { ChartColors } from "@/components/charts/chartsColor";
import ScatterChart from "@/components/charts/HighChart.component";
import { Patient } from "@/domain/patient/model/types";
import { getPatientReimbursableEvents } from "@/domain/patient/redux/middleware";

interface Props {
  patients: Patient[];
}
interface State {
  chartConfig: any;
}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chartConfig: this.drawChart(),
    };
  }

  drawChart() {
    return {
      options: {
        chart: {
          type: "scatter",
          height: 550,
        },
        title: {
          text: "CPT 99454",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          min: 1,
          max: 30,
          title: {
            text: "Day of patient’s current billing period",
          },
        },
        yAxis: {
          title: {
            text: "Readings completed in patient’s current billing period",
          },
          min: 0,
          max: 30,
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {},
        },
        tooltip: {},
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        series: [
          // next four series are for color regions
          {
            type: "area",
            name: "On Track to Bill",
            fillOpacity: 0.1,
            opacity: 0.5,
            color: ChartColors.GREEN,
            marker: {
              enabled: false,
            },
            enableMouseTracking: false,
            data: [
              [1, 0],
              [16, 16],
              [30, 30],
            ],
          },

          {
            type: "area",
            name: "At Risk to Bill",
            fillOpacity: 0.8,
            opacity: 0.8,
            color: ChartColors.YELLOW,
            marker: {
              enabled: false,
            },
            enableMouseTracking: false,
            data: [
              [5, 0],
              [30, 16],
            ],
          },
          {
            type: "area",
            name: "Disqualified to Bill",
            fillOpacity: 1,
            opacity: 0.9,
            color: ChartColors.RED,
            marker: {
              enabled: false,
            },
            enableMouseTracking: false,
            data: [
              [14, 0],
              [30, 14],
            ],
          },

          {
            name: "Patient Detail",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 7,
              fill: "black",
              fillColor: "black",
            },
            data: this.getMarkers(),
          },

          {
            type: "line",
            name: "Finish Line",
            dashStyle: "dash",
            enableMouseTracking: false,
            marker: {
              enabled: false,
            },
            color: "#059e47",
            data: [
              [0, 16],
              [30, 16],
            ],
          },
        ],
      },
    };
  }

  getMarkers = () => {
    const patients = this.props.patients.map((patient) => {
      return this.props.patients.filter((pat) => {
        if (
          pat.billing?.cpt99454.observedDays ===
            patient.billing?.cpt99454.observedDays &&
          pat.billing?.cpt99454.currentDays ===
            patient.billing?.cpt99454.currentDays
        ) {
          return pat;
        }
      });
    });
    const markers = patients.map((patient) => {
      return {
        y: patient[0].billing?.cpt99454.observedDays,
        x: patient[0].billing?.cpt99454.currentDays,
        marker: {
          fillColor: patient.length === 1 ? "black" : "white",
          radius: patient.length === 1 ? 7 : 18,
          lineColor: "black",
          lineWidth: 1.5,
        },
        events: {
          mouseOver: async () => {
            await this.setState((prevState) => ({
              ...prevState,
              chartConfig: {
                options: {
                  tooltip: {
                    stickOnContact: true,
                    useHTML: true,
                    pointFormat: patient
                      .map((p) => {
                        return (
                          '<span style="color:{point.color}"></span>' +
                          '<b>Name: </b><a target="_blank" href="/patient/' +
                          p.id +
                          '/">' +
                          p.demographics.legalName.firstName +
                          " " +
                          p.demographics.legalName.lastName +
                          "</a><br/>" +
                          "<b>CareTeam: </b>" +
                          (p.assignedUserName ?? "Unassigned") +
                          "<br/>" +
                          '<b>Observations: </b><span style="font-size: 11px ;color:' +
                          (p.billing?.cpt99454.reimbursableEvents! > 0
                            ? "green"
                            : "red") +
                          '">' +
                          getPatientReimbursableEvents(p.billing) +
                          " days</span><br><br>"
                        );
                      })
                      .join(""),
                  },
                },
              },
            }));
          },
          click: () => {
            if (patient.length === 1) {
              window.open("/patient/" + patient[0].id + "/", "_blank");
            }
          },
        },
      };
    });
    return markers;
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.patients !== this.props.patients) {
      this.setState({
        chartConfig: this.drawChart(),
      });
    }
  }

  render() {
    return (
      <>
        <ScatterChart options={this.state.chartConfig.options} />
      </>
    );
  }
}

export const RemainingDaysChart = Component;
