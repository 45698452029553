import { Model } from "../../model";
import { Command } from "../command/command";
import { Constructor } from "../../types/constructor";
import { CommandConfigMap } from "../../core/config/commands";
import { RCAResourceActions } from "../../core/config/actions";
import { Receiver } from "../receiver/receiver";
import { SuccessHandler, FailureHandler } from "../handler";
import { Task, ScheduleEventHandlersTask } from "../task";

/**
 * Invoker is an API for executing Maverick Commands / Actions like Creating or Modifying RCA API Resources.
 * It can be used for exisitng actions and future actions like patient assignments and all clinical operations in Maverick
 *
 */
export class Invoker<T> {
  onBefore!: Task<T>[];
  onAfter!: Task<T>[];
  onFinally!: Task<T>[];

  constructor(
    protected readonly model: Model<T>,
    protected readonly receiver: Receiver<T>
  ) {}

  registerOnBefore(...tasks: Task<T>[]) {
    this.onBefore = tasks;
  }

  registerOnAfter(...tasks: Task<T>[]) {
    this.onAfter = tasks;
  }

  registerOnFinally(...tasks: Task<T>[]) {
    this.onFinally = tasks;
  }

  /**
   * Task Runner!
   *
   * @param tasks
   * @private
   */
  private async run(...tasks: Task<T>[]): Promise<void> {
    await Promise.all(
      tasks.map(async (task: Task<T>) => await task.run(this.receiver.clone()))
    );
  }

  /**
   * Finds appropriate Command per userAction and invokes it.
   */
  async invoke(): Promise<void | boolean> {
    // Prepare command but do not execute yet. Task runners need receiver context to be set.
    const command: Command<T> = this.command(this.receiver.action);
    const receiver: Receiver<T> = this.receiver.setContext(command);

    // Register event handlers - success and failures.
    if (this.onBefore) {
      await this.run(...this.onBefore);
    }

    // Now, Execute API command based on UI action.
    await command.execute(receiver);

    // Run clean up tasks like closing modal, turning off spinners etc.
    if (this.onAfter && command.isCompleted && command.isSuccessful) {
      await this.run(...this.onAfter);
    }

    // Run tasks that does not depend on whether command succeeds or not.
    // These tasks act like finally in your good old try-catch-finally blocks.
    if (this.onFinally && command.isCompleted) {
      await this.run(...this.onFinally);
    }

    return command.isSuccessful;
  }

  /**
   * Find the appropriate Command based on UI action and return a new instance of it.
   *
   * @param action
   */
  command(action: RCAResourceActions): Command<T> {
    // Map of invokable commands based on actions being triggered in Maverick.
    const invokableCommands: { [key: string]: Constructor<Command<T>> } =
      CommandConfigMap;
    const command: Constructor<Command<T>> = invokableCommands[action];
    return new command();
  }

  /**
   * Makes a new instance of Invoker.
   *
   * @param model
   * @param action
   * @param resourceType
   * @param cleanUps
   * @param finallies
   */
  static make<T>(
    receiver: Receiver<T>,
    cleanUpTask: Task<T>,
    finallyTask: Task<T>,
    successMessage?: string
  ): Invoker<T> {
    const invoker: Invoker<T> = new Invoker(receiver.model, receiver);

    // Register Success | Failure Event Handlers. This tasks is for setting up success and failure event handlers
    // that comes from APISync
    const eventHandlerRegistery: ScheduleEventHandlersTask<T> =
      new ScheduleEventHandlersTask<T>();
    eventHandlerRegistery.setHandlers(
      new SuccessHandler(successMessage),
      new FailureHandler()
    );
    invoker.registerOnBefore(eventHandlerRegistery);

    // Register Callbacks. These are tasks that run only when the command succeeds and there are no failures.
    invoker.registerOnAfter(cleanUpTask);

    // Register Finallu Tasks. These are tasks that run whether or not the command succeeded or failed.
    invoker.registerOnFinally(finallyTask);

    return invoker;
  }
}
