import { UserActions } from "../../domain/user/model";
import { Constructor, ConstructorWithArgs } from "../types/constructor";
import { PolicyBuilder } from "./policyBuilders/policyBuilder.interface";
import { OrganizationUserPolicyBuilder } from "./policyBuilders/organizationUser.policy";
import { PracticeUserPolicyBuilder } from "./policyBuilders/practiceUser.policy";
import { RootUserPolicyBuilder } from "./policyBuilders/rootUser.policy";
import { OrganizationScopeBuilder } from "./scopeBuilders/organization.scopeBuilder";
import { PracticeScopeBuilder } from "./scopeBuilders/practice.scopeBuilder";
import { ScopeBuilder } from "./scopeBuilders/scopeBuilder.interface";
import { GlobalScopeBuilder } from "@/library/policy/scopeBuilders/global.scopeBuilder";

export class UserPolicyBuilder {
  constructor(userType: UserActions) {}

  static make(userType: UserActions): PolicyBuilder {
    const builder = new UserPolicyBuilder(userType);
    return builder.get(userType);
  }

  builders: Builders = {
    [UserActions.CreateAdminUser]: {
      policy: RootUserPolicyBuilder,
      scope: {
        organization: OrganizationScopeBuilder,
        practice: PracticeScopeBuilder,
        global: GlobalScopeBuilder,
      },
    },
    [UserActions.CreateOrganizationUser]: {
      policy: OrganizationUserPolicyBuilder,
      scope: {
        organization: OrganizationScopeBuilder,
        practice: PracticeScopeBuilder,
        global: GlobalScopeBuilder,
      },
    },
    [UserActions.CreatePracticeUser]: {
      policy: PracticeUserPolicyBuilder,
      scope: {
        organization: OrganizationScopeBuilder,
        practice: PracticeScopeBuilder,
        global: GlobalScopeBuilder,
      },
    },
  };

  get(userType: UserActions): PolicyBuilder {
    const builder = this.builders[userType];
    return new builder.policy(builder.scope);
  }
}

interface Builders {
  [key: string]: {
    policy: ConstructorWithArgs<PolicyBuilder>;
    scope: {
      [key: string]: Constructor<ScopeBuilder>;
    };
  };
}
