import { format } from "date-fns";
import cx from "clsx";

import { assessment } from "@/assets/icons/observations";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import { dateFormatter } from "@/pipes/date";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";

import styles from "./styles.module.scss";
import { AlertLevel } from "@/domain/notes/model/types";
import { DateTime, FixedOffsetZone } from "luxon";
import { PatientState } from "@/library/types/patientState";
import { useState } from "react";
import { arrClone } from "@/util/clone";
import { AssessmentReason } from "@/library/types/note";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "MM/dd/yy";
const MINUTES_PER_HOUR = 60 as const;
const HOURS_PATHWAY_COMPLETION_DUE = 36 as const;

export type OwnProps = {
  patient: Patient;
  patientHealth: HealthSnapshot;
};
export type Props = OwnProps & Partial<IconContainerProps>;

export const Assessment: React.FC<Props> = (props) => {
  const { patient, patientHealth, ...rest } = props;
  const [disposition] = useState(patient.health?.assessment?.disposition);
  const getTileBackgroundColor = (): string => {
    const assessment = patientHealth?.assessment;
    let alertLevel: string = AlertLevel.NO_ALERT;
    if (patient.lifecycle.state === PatientState.Inactive) {
      return AlertLevel.DISABLED_ALERT;
    }
    if (assessment) {
      // evaluate color for when pathway has not been completed after 36 hours of being sent
      const deviceLastCommunicated = arrClone(
        patient?.deviceInfo || []
      ).reverse()[0];
      // get patient's device fixed timezone offset in minutes
      const zone = FixedOffsetZone.instance(
        (deviceLastCommunicated?.timezoneOffset ?? 0) * MINUTES_PER_HOUR
      );
      const timePathwaySent = DateTime.fromISO(assessment?.time, {
        zone,
      });
      const now = DateTime.fromISO(DateTime.now().toISO(), {
        zone,
      });
      // evaluate color for when pathway is completed
      if (
        assessment.reason === AssessmentReason.Completed &&
        assessment.severityScore === 0
      ) {
        alertLevel = AlertLevel.GREEN_ALERT;
      } else if (
        assessment.reason === AssessmentReason.Completed &&
        assessment.severityScore === 2
      ) {
        alertLevel = AlertLevel.YELLOW_ALERT;
      } else if (
        assessment.reason === AssessmentReason.Completed &&
        assessment.severityScore === 5
      ) {
        alertLevel = AlertLevel.RED_ALERT;
      }
      if (
        now.diff(timePathwaySent, "hours").as("hours") >
        HOURS_PATHWAY_COMPLETION_DUE
      ) {
        alertLevel = AlertLevel.BLACK_ALERT;
      }
    }
    return alertLevel;
  };
  const [tileColor] = useState(getTileBackgroundColor());
  return (
    <IconContainer
      {...rest}
      id={`obs-assessment-${patient?.id}`}
      size="xs"
      src={assessment}
      isObservationCompleted={
        patientHealth?.assessment?.isComplete === true &&
        patientHealth?.assessment?.reason === AssessmentReason.Completed
      }
      color={tileColor}
      tooltipContent={
        patientHealth?.assessment ? (
          <>
            <div>{patientHealth?.assessment?.name}</div>
            {dateFormatter({
              date: patientHealth?.assessment?.time,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })}
          </>
        ) : null
      }
    >
      <div className="text-center">
        <p
          className={cx(
            styles.lead,
            styles.sm,
            tileColor === AlertLevel.BLACK_ALERT && styles.whiteText
          )}
        >
          {patientHealth?.assessment && patientHealth?.assessment?.time
            ? format(new Date(patientHealth?.assessment?.time), TIME_FORMAT)
            : "-"}
        </p>

        <p
          className={cx(
            "mt-0",
            styles.lead,
            styles.sm,
            tileColor === AlertLevel.BLACK_ALERT && styles.whiteText
          )}
        >
          {patientHealth?.assessment && patientHealth?.assessment?.time
            ? format(new Date(patientHealth?.assessment?.time), DATE_FORMAT)
            : "-"}
        </p>
      </div>
    </IconContainer>
  );
};
