import React from "react";
import { SummaryNoteOptions } from "./constants";
import styles from "./styles.module.scss";

type Props = {
  selectedNoteOption: SummaryNoteOptions;
  setSelectedNoteOption: (option: SummaryNoteOptions) => void;
};

const ChartNoteOptions: React.FC<Props> = ({
  selectedNoteOption,
  setSelectedNoteOption,
}) => {
  return (
    <div className="mt-3">
      <div className="text-center font-weight-bold h6">
        Include Chart Note Options
      </div>
      <div className="border p-3 mt-2">
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            onClick={() => setSelectedNoteOption(SummaryNoteOptions.All)}
            checked={selectedNoteOption === SummaryNoteOptions.All}
          />{" "}
          All Chart Notes in Date Range
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            onClick={() => setSelectedNoteOption(SummaryNoteOptions.Last2Days)}
            checked={selectedNoteOption === SummaryNoteOptions.Last2Days}
          />{" "}
          Last 2 Days of Date Range
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            onClick={() => setSelectedNoteOption(SummaryNoteOptions.NoCharts)}
            checked={selectedNoteOption === SummaryNoteOptions.NoCharts}
          />{" "}
          No Chart Notes
        </div>
      </div>
    </div>
  );
};

export default ChartNoteOptions;
