import { Modal } from "@/components/modal";
import React, { FC } from "react";
import { User } from "@/domain/user/model";
import { AssignWatchListItem } from "@/domain/patient/view/PatientsPage/watchlist/assignWatchListItem";
import { Patient } from "@/domain/patient/model/types";
import { NoActionWatchListItem } from "@/domain/patient/view/PatientsPage/watchlist/noActionWatchListItem";
import { Box } from "@mui/material";

interface Props {
  show?: boolean;
  onClose: () => void;
  title?: string;
  button: string;
  users?: User[];
  save: () => Promise<void>;
  patients: Patient[];
  addToAssignList?: (user: User) => void;
  removeFromAssignList?: (user: User) => void;
}
export const AssignWatchListModal: FC<Props> = ({
  show = false,
  onClose,
  title = "modal",
  button,
  users = [],
  addToAssignList = (user: User) => {},
  removeFromAssignList = (user: User) => {},
  patients,
  save,
}) => {
  return (
    <Box
      sx={(theme) => ({
        paddingLeft: theme.spacing(50),
        paddingRight: theme.spacing(50),
      })}
    >
      <Modal
        description="List of users"
        handleClose={onClose}
        show={show}
        heading={title}
        button={button}
        actionButton={{
          formik: {
            submitForm: () => (async () => await save())(),
            resetForm: () => {},
          },
          name: "Assign",
        }}
        size="xs"
      >
        <div
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
            maxHeight: "70vh",
          }}
        >
          {users.map((user: User) => {
            return patients.every((patient: Patient) =>
              patient.watchingUserIds?.includes(user._meta?.id! || user.id!)
            ) ? (
              <NoActionWatchListItem
                user={user}
                secondary="Assigned"
                toolTipDescription="This user has already been assigned to selected patients"
              />
            ) : (
              <AssignWatchListItem
                patientCount={user.watchedPatientCount || 0}
                user={user}
                addToAssignList={addToAssignList}
                removeFromAssignList={removeFromAssignList}
              />
            );
          })}
        </div>
      </Modal>
    </Box>
  );
};
