import { ObservationModel } from "@/domain/observations/model";
import { Note } from "@/domain/notes/model/types";
import { NoteModel } from "@/domain/notes/model";
import { CanMake } from "@/library/model/model.interface";
import {
  Deserializer,
  DeserializerStack,
} from "@/library/deserializers/deserializer";

export class NoteDeserializer extends Deserializer<Note, NoteModel> {
  /**
   * Makes an instance of note deserializer
   * @param note
   * @param makeModel
   * @returns
   *
   */
  static for(note: Note, makeModel: CanMake<Note, NoteModel>) {
    return new NoteDeserializer(note, makeModel);
  }
  /**
   * Stack of syncronous deserializing functions.
   */
  protected deserializers: DeserializerStack<ObservationModel> = {
    /**
     * @NOTE NEVER ADD ASYNC METHODS IN THE DESERIALIZER STACK.
     *
     */
    _meta: () => this.remodelMetaCreated(),
  };

  private remodelMetaCreated(): void {
    const createdDate = this.entityModel.pluck("_meta")?.created;
    if (createdDate) {
      const dateInLocalTimezone = this.convertLocalTimezone(createdDate);
      const _meta = {
        created: dateInLocalTimezone.toString(),
        id: this.entityModel.pluck("_meta")!.id,
      };
      this.setValue("_meta", _meta);
    }
  }
}
