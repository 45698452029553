import { extractphone } from "@/components/form";
import { OrganizationModel } from "@/domain/organization/model";
import { OrganizationForm } from "@/domain/organization/model/types";
import { OrganizationType } from "../../types";
import { KitFulfillmentOption } from "../../types/kitFulfillmentOption";
import { Serializeable } from "../serializer";

/**
 * Serializer for creating new Organization.
 */
export class CreateOrganizationSerializer
  implements Serializeable<OrganizationModel>
{
  constructor(protected readonly formData: OrganizationForm) {}

  serialize(): OrganizationModel {
    return OrganizationModel.make({
      id: this.formData.id,
      type: this.formData.type as OrganizationType,
      name: this.formData.name,
      address: {
        street: [this.formData.address],
        locality: this.formData.city,
        region: this.formData.state,
        postalCode: this.formData.zip,
        country: this.formData.country,
      },
      contact: {
        name: this.formData.contactName,
        position: this.formData.contactPosition || null,
        email: this.formData.contactEmail || null,
        phone: extractphone(this.formData.contactPhone),
      },
      domain: this.formData.domain,
      kitFulfillment:
        this.formData.kitFulfillment === "No Kit"
          ? null
          : (this.formData.kitFulfillment as KitFulfillmentOption),
      ruleVariables: undefined,
    });
  }
}
