import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { DateTime } from "luxon";
import { format } from "date-fns";

export class DateFormatter {
  private localTimzone = DateTime.local().zoneName;

  constructor(private format: string, private date: string | number) {}

  public toLocalTimezone(excludeTimezone?: boolean) {
    const localDate = convertDateToLocalTimezone(new Date(this.date))
      .toJSDate()
      .toISOString();
    return `${this.formatDate(localDate)} ${
      !excludeTimezone
        ? this.parseTimezone(this.getTimezoneAbbrevation(this.localTimzone))
        : ""
    }`;
  }

  public formatDate(date: string | number) {
    return `${format(new Date(date), this.format)} `;
  }

  private parseTimezone(timezone: string) {
    if (timezone) {
      const zone = timezone.split("+")[0];
      return `(${zone})`;
    }
    return "";
  }

  public toUTC(date: Date) {
    const utcDate = DateTime.fromJSDate(date, { zone: "UTC" })
      .toJSDate()
      .toString();
    return this.formatDate(utcDate);
  }

  public toPST(date: Date, includeZoneAbbreviation?: boolean) {
    const pstDate = DateTime.fromJSDate(date, {
      zone: "America/Los_Angeles",
    }).toFormat("LL-dd-yy hh:mm:ss");
    return `${pstDate} ${
      includeZoneAbbreviation
        ? this.parseTimezone(this.getTimezoneAbbrevation("America/Los_Angeles"))
        : ""
    }`;
  }

  private getTimezoneAbbrevation(zone: string): string {
    const longTimezone = DateTime.local()
      .setLocale("en-US")
      .setZone(zone)
      .offsetNameLong.split(" ");
    let timezoneAbrrevation = "";
    longTimezone.forEach((tz: string) => (timezoneAbrrevation += tz[0]));
    return timezoneAbrrevation;
  }
}
