import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Toggle } from "rsuite";

import { TextInput, TextArea, Validation, Select } from "@/components/form";
import { Patient } from "@/domain/patient/model/types";
import { Note } from "@/domain/notes/model/types";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { NoteModel } from "@/domain/notes/model";
import { ResourceType } from "@/library/core/config/resource";
import { RCAResourceActions } from "@/library/core/config/actions";
import { CleanTask, FinallyTask } from "@/library/common/task";
import {
  PatientNoteType,
  Outcome,
  Interventions,
  Intervention,
} from "@/library/types/note";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { snake2Camel } from "@/pipes/text";

interface Props {
  onSetFormik: (formik: any) => void;
  replyToIds: string[];
  handleClose: () => void;
  patient: Patient;
  updateParentNoteInReduxIfChild: (note: Partial<Note>) => void;
}

export const CallNoteForm: React.FC<Props> = (props) => {
  const initialValues = {
    patientId: props.patient.id as string,
    comment: "",
    directPatientContact: false,
    minutes: 0,
    outcome: [],
    direction: "",
    location: "",
    replyToIds: props.replyToIds,
    type: PatientNoteType.Call,
  };

  const [checked, setChecked] = useState(false);
  const [minutesRequired, setMinutesRequired] = useState(true);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          comment: Validation.notRequired,
          directPatientContact: Validation.notRequired,
          minutes: minutesRequired
            ? Validation.naturalNumberRequired
            : Validation.wholeNumberNotRequired,
          outcome: Validation.notRequiredArray,
          direction: Validation.required,
          location: Validation.required,
          replyToIds: Validation.notRequiredArray,
        })}
        onSubmit={async (
          values: Partial<Note>,
          { setSubmitting, resetForm }
        ) => {
          if (values.minutes?.toString() === "") {
            values.minutes = null;
          }
          await Invoker.make<Note>(
            Receiver.make(
              NoteModel.make(values as Note, true),
              ResourceType.note,
              RCAResourceActions.Create
            ),
            CleanTask.make(
              () => resetForm(initialValues as any),
              () => props.handleClose(),
              () => props.updateParentNoteInReduxIfChild(values as Note)
            ),
            FinallyTask.make(() => setSubmitting(false))
          ).invoke();
        }}
      >
        {(formik) => {
          props.onSetFormik(formik);
          return (
            <Form>
              <div className="modal-body">
                <div className="subject-scroll">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextArea
                          label="Comment"
                          name="comment"
                          type="checkbox"
                          required={false}
                          rows="7"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Outcome</label>
                            <div>
                              <BasicMultiSelect
                                data={Object.keys(Interventions)
                                  .filter(
                                    (intervention) =>
                                      intervention !==
                                      Intervention.LeftPhoneMessage
                                  )
                                  .map((key) => {
                                    return {
                                      value: key,
                                      label: Interventions[key as Intervention],
                                    };
                                  })}
                                onChange={(intervention: any) => {
                                  if (
                                    !formik.values.directPatientContact &&
                                    (intervention.includes(
                                      "VIDEO_VISIT_COMPLETED"
                                    ) ||
                                      intervention.includes(
                                        "DIRECT_PATIENT_CONTACT"
                                      ))
                                  ) {
                                    formik.setFieldValue(
                                      "directPatientContact",
                                      true
                                    );
                                  }
                                  formik.setFieldValue(
                                    "intervention",
                                    intervention
                                  );
                                }}
                                isSearchable={false}
                                placeholder="Select Intervention"
                                sortable={false}
                                findValueBy={(option: any) => option.value}
                                picker="check"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group ">
                            <TextInput
                              label="Minutes"
                              name="minutes"
                              type="number"
                              placeholder="Minutes"
                              required={minutesRequired}
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Direct Patient Contact</label>
                            <div>
                              <Toggle
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                onChange={(value) => {
                                  formik.setFieldValue(
                                    "directPatientContact",
                                    value
                                  );
                                }}
                                checked={formik.values.directPatientContact}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Select
                              className="form-control"
                              name="location"
                              required
                              label="Location"
                              onChange={(event: any) => {
                                const location = event.target.value;
                                formik.setFieldValue("location", location);
                                const isPatient = location === "PATIENT";
                                const isOther = location === "OTHER";
                                formik.setFieldValue(
                                  "directPatientContact",
                                  isPatient
                                );
                                setChecked(isPatient);
                                setMinutesRequired(!isOther);
                              }}
                            >
                              <option>Select One</option>
                              <option value={"CAREGIVER"}>Caregiver</option>
                              <option value={"HOME_CARE"}>Home Care</option>
                              <option value={"PATIENT"}>Patient</option>
                              <option value={"PROVIDER"}>Provider</option>
                              <option value={"OTHER"}>Other</option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Select
                              className="form-control"
                              name="direction"
                              label="Direction"
                              required
                            >
                              <option>Select One</option>
                              <option value={"INBOUND"}>Inbound</option>
                              <option value={"OUTBOUND"}>Outbound</option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
