import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import cx from "clsx";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  savedAt?: Date;
  onClose?: () => void;
  direction?: "down" | "up";
  duration?: number;
  containerClassName?: string;
};

export const SavedIndicator: React.FC<Props> = (props) => {
  const {
    savedAt,
    onClose,
    direction = "down",
    duration = 2100,
    containerClassName,
  } = props;

  const [show, setShow] = useState(false);

  const startCloseAnimation = debounce(() => {
    setShow(false);
  }, duration);
  const close = debounce(() => {
    onClose?.();
  }, duration + 330);

  useEffect(() => {
    setShow(true);
    startCloseAnimation();
    close();

    return () => {
      startCloseAnimation.cancel();
      close.cancel();
    };
  }, [savedAt]);

  return (
    <div
      className={cx(
        styles.savedIndicator,
        styles.animation,
        direction === "down" ? styles.fromTop : styles.fromBottom,
        show && styles.show,
        containerClassName
      )}
    >
      <div className={styles.label}>
        <i
          className={cx("fas", "fa-check", globalStyles.success, styles.check)}
        />
        <span>{`Saved ${
          savedAt
            ? DateTime.fromJSDate(savedAt).toLocaleString(
                DateTime.TIME_WITH_SECONDS
              )
            : ""
        }`}</span>
      </div>
    </div>
  );
};
