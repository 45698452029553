import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import Button from "rsuite/Button";
import RSModal from "rsuite/Modal";
import cx from "clsx";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";

import {
  Patient,
  PatientFilters,
  PatientTableType,
} from "@/domain/patient/model/types";
import { PaginationConfig, PatientQuery } from "@/library/types";
import { Provider } from "@/domain/provider/model";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { PatientModel } from "@/domain/patient/model";
import {
  CURRENT_DATE,
  DAYS_LIMIT,
  FILTER_STRING,
  MODEL_SYNCED,
} from "@/library/constants";
import { PatientForm } from "@/domain/patient/view/form/PatientForm";
import { Header } from "@/components/Header";
import { ModelCollection } from "@/library/model";
import { RCAResourceActions } from "@/library/core/config/actions";
import { OrganizationModel } from "@/domain/organization/model";
import { Iterator } from "@/library/iteration/iterator";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { SeverityLevel } from "@/domain/notes/model/types";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { PatientBanners } from "@/domain/patient/view/PatientBanners";
import {
  getEndDate,
  getStartDate,
} from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  LocationFilterRole,
  mapDispatcherToProps,
  mapStateToProps,
  MY_PATIENTS,
  MY_WATCHLIST,
  NO_TAGS_FILTER,
  FILTER_TYPES,
} from "./constants";
import { Props, State } from "./types";
import { generateClinicalFilter, getPracticeIds } from "./helpers";

import styles from "./styles.module.scss";
import { INVALID_MONGO_ID } from "@/library/serializers/user/helper";
import { CreateEntityButton } from "@/domain/organization/view";
import { PatientTab } from "@/domain/patient/view/PatientDetails/types";
import {
  attachParentEntities,
  attachPatientRequestNotes,
} from "../../redux/middleware";
import { PatientState } from "@/library/types/patientState";
import {
  PatientProgramState,
  StateDisplayName,
} from "../form/manageProgram/types";

const isPatientSlidingPaneEnabled =
  _env_.ENABLE_PATIENT_SLIDING_PANE === "true";

class PatientsPageComponent extends React.PureComponent<
  Props & RouteComponentProps,
  State
> {
  state: Readonly<State> = {
    userName: "",
    userModels: [],
    paginationConfig: {
      totalResources: 0,
      currentPage: 0,
      lastPage: 0,
    },
    search: "",

    patientAssignmentModalConfig: {
      show: false,
      title: "",
      modalButton: "",
    },
    patientToAssignServiceLevel: undefined,
    patientModel: undefined,
    assignServiceLevel: false,
    patientModalConfig: {
      show: false,
      title: "",
      modalButton: "",
      resourceAction: undefined,
    },
    providerIds: [],
    providerModels: [],
    isCollapsed: false,

    typingTimeout: 0,
    users: [],
    formik: undefined,
    onSubmitForm: () => {},
    multiSelectConfig: {
      showLoadingIndicator: false,
    },
    showPlaceholder: false,
    selectedLocationId: "",
    allPracticeIds: this.props.practices.map((practice) => practice.id!),
    locationTags: this.props.locationTags,
    currentUserTags:
      JSON.parse(sessionStorage.getItem("currentUserTags") as string) ||
      this.props.locationTags.filter((t) => {
        return this.props.currentUser?.tagIds.includes(t._meta.id);
      }),
    preselectEntityAndProvider: false,
  };

  async componentDidMount() {
    const { setMonitoringDates } = this.props;
    // Reset monitoring dates when exiting a patient
    setMonitoringDates(getStartDate(), getEndDate());
    const allPracticeIds = await this.fetchPracticeIdsByOrg(
      this.props.selectedOrganization?.id!
    );
    this.setState((prevState) => ({
      ...prevState,
      allPracticeIds: !allPracticeIds.length
        ? [INVALID_MONGO_ID]
        : allPracticeIds,
    }));
    if (
      !this.props.filters.status?.length &&
      localStorage.getItem("initialLoadPatientList") === null
    ) {
      localStorage.setItem("initialLoadPatientList", "yes");
      this.props.setPatientFilters({
        ...this.props.filters,
        status: [PatientState.Active],
      });
      this.props.setFilterItems([
        ...this.props.filterItems.filter(
          (filterItem) => filterItem.type !== FILTER_TYPES.FILTER_TYPE_STATUS
        ),
        {
          type: FILTER_TYPES.FILTER_TYPE_STATUS,
          value: PatientProgramState.ACTIVE,
          description: StateDisplayName[PatientProgramState.ACTIVE],
        },
      ]);
    }

    if (this.props.tableType === PatientTableType.Clinical) {
      await this.execClinicalPatientSearch({});
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedOrganization &&
      prevProps.selectedOrganization?._meta?.id !==
        this.props.selectedOrganization._meta?.id
    ) {
      this.props.deselectAllPatients();
      // Different organization has been selected, patients that belongs to the newly selected organization needs to be fetched

      if (this.props.tableType === PatientTableType.Clinical) {
        this.props.setPatients([]);
        await this.execClinicalPatientSearch({});
      }

      const allPracticeIds = await this.fetchPracticeIdsByOrg(
        this.props.selectedOrganization?.id!
      );
      this.setState((prevState) => ({
        ...prevState,
        allPracticeIds: !allPracticeIds.length
          ? [INVALID_MONGO_ID]
          : allPracticeIds,
      }));
    }

    if (
      prevProps.selectedPractices.length !== this.props.selectedPractices.length
    ) {
      await this.execSearch({});
    }

    // Always compare props before processing events
    if (
      prevProps.selectedProviders.length !== this.props.selectedProviders.length
    ) {
      await this.execSearch({});
    }

    if (prevProps.reload !== this.props.reload) {
      await this.execAssignmentPatientSearch({});
    }

    if (!this.props.selectedPatients?.length && this.state.selectedLocationId) {
      this.setState({ selectedLocationId: "" });
    }
  }

  async componentWillUnmount() {
    if (this.props.tableType === PatientTableType.Assignment) {
      return;
    }
    this.props.setPractices([]);
    this.props.setProviders([]);
    this.props.setPatients([]);
    this.props.setAssignmentPatients([]);
    this.props.deselectAllPractices();
    this.props.deselectAllProviders();
    this.props.deselectAllPatients();
    localStorage.removeItem("initialLoadPatientList");
  }

  getHealthSinceDate(): DateTime {
    const datetime = convertDateToLocalTimezone(CURRENT_DATE);
    return datetime.minus({ days: DAYS_LIMIT });
  }

  execClinicalPatientSearch = async (query: PatientQuery) => {
    const { setPatients, setPatientsPaginationConfig, selectedOrganization } =
      this.props;

    if (query.name !== undefined) {
      this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }

    if (!selectedOrganization) {
      return;
    }

    const allOrganizationPractices = (
      await PracticeModel.fetchByOrganizationIds([
        selectedOrganization?._meta?.id as string,
      ])
    ).map((practiceModel) => practiceModel.pluck("_meta")?.id);

    if (!allOrganizationPractices.length) {
      return;
    }
    this.setState({ showPlaceholder: true });
    LoadingIndicator.fire.show();

    const typingTimeout = setTimeout(async () => {
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
      const params = {
        ...query,
        includeHealth: 1,
        includeDeviceSnapshot: true,
        healthSince: this.getHealthSinceDate().toUTC().toString(),
        lifecycleState: this.props.filters.status,
        sortBy: "smart",
        ...{ name: this.state.search },
        ...((!this.props.filters.practiceIds ||
          this.props.filters.practiceIds.length === 0) &&
          (!this.props.filters.providerIds ||
            this.props.filters.providerIds.length === 0) && {
            practiceIds: allOrganizationPractices,
          }),
        ...(generateClinicalFilter(this.props.filters) as PatientFilters),
      };
      // Not supported via api
      delete params.status;
      patientCollection.withParams(params);
      const paginationConfig: PaginationConfig =
        await patientCollection.fetch();
      let patients = patientCollection.container.map((container) =>
        container.pluckAll()
      );
      patients = this.filterPatientsBySelectedAlertType(patients);
      const patientsWithRequestNotes: Patient[] =
        await attachPatientRequestNotes(patients);
      setPatients(await attachParentEntities(patientsWithRequestNotes));
      setPatientsPaginationConfig(paginationConfig);
      this.setState({ showPlaceholder: false });
      patientCollection.trigger(MODEL_SYNCED);
    }, 500) as unknown as number;

    this.setState({ typingTimeout });
  };

  filterPatientsBySelectedAlertType(patients: Patient[]) {
    switch (this.props.filters.alert) {
      case "alerts":
        return patients.filter(
          (patient) =>
            (patient.health?.alertScore || 0) > SeverityLevel.SEVERITY_GREEN
        );

      case "noAlerts":
        return patients.filter(
          (patient) =>
            (patient.health?.alertScore || 0) === SeverityLevel.SEVERITY_GREEN
        );
      default:
        return patients;
    }
  }

  execAssignmentPatientSearch = async (query: PatientQuery) => {
    const practiceIds: string[] = await getPracticeIds();
    // there are no practices belonging to the selected organization, don't exec search
    if (!practiceIds.length) {
      return;
    }

    const { setAssignmentPatients, setAssignmentPatientsPaginationConfig } =
      this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }
    LoadingIndicator.fire.show();
    const typingTimeout = setTimeout(async () => {
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
      const paginationConfig: PaginationConfig = await patientCollection.fetch({
        ...query,
        practiceIds: practiceIds,
        limit: this.props.assignmentPatientPageLimit,
        sortBy: "smart",
        ...{ name: this.state.search },
        ...(this.props.assignmentPatientFilters as PatientFilters),
        ...(!this.props.assignmentPatientFilters.practiceIds?.length
          ? { practiceIds: practiceIds }
          : {}),
      });

      await setAssignmentPatients(patientCollection.container);

      setAssignmentPatientsPaginationConfig(paginationConfig);
      patientCollection.trigger(MODEL_SYNCED);
    }, 500) as unknown as number;

    this.setState({ typingTimeout });
  };

  execSearch = async (query: PatientQuery) => {
    const { setPatients, setPatientsPaginationConfig, selectedOrganization } =
      this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }

    const allOrganizationPractices = (
      await PracticeModel.fetchByOrganizationIds([
        selectedOrganization?._meta?.id as string,
      ])
    ).map((practiceModel) => practiceModel.pluck("_meta")?.id);

    if (!allOrganizationPractices.length) {
      return;
    }
    LoadingIndicator.fire.show();
    this.setState({
      typingTimeout: setTimeout(async () => {
        const patientCollection: ModelCollection<PatientModel, Patient> =
          PatientModel.makePatientCollection();
        patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
        const paginationConfig: PaginationConfig =
          await patientCollection.fetch({
            ...query,
            limit: this.props.patientPageLimit,
            sortBy: "smart",
            ...{ name: this.state.search },
            ...(!!this.props.selectedProviders.length
              ? {
                  providerIds: this.props.selectedProviders.map(
                    (provider: Provider) => provider.id
                  ),
                }
              : !!this.props.selectedPractices.length
              ? {
                  practiceIds: this.props.selectedPractices.map(
                    (practice: Practice) => practice.id
                  ),
                }
              : { practiceIds: allOrganizationPractices }),
            ...(this.props.filters as PatientFilters),
          });

        const patientsWithRequestNotes: Patient[] =
          await attachPatientRequestNotes(
            patientCollection.container.map((container) => container.pluckAll())
          );
        await setPatients(patientsWithRequestNotes);

        setPatientsPaginationConfig(paginationConfig);

        patientCollection.trigger(MODEL_SYNCED);
      }, 500) as unknown as number,
    });
  };

  onPatientModalClose = () => {
    this.props.setTargetPatient("");
    this.props.setTargetProvider("");
    this.setState({
      patientModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        assignServiceLevelButton: "",
        resourceAction: undefined,
      },
      preselectEntityAndProvider: false,
    });
  };

  onEditPatient = () => {
    this.setState({
      assignServiceLevel: false,
      patientModalConfig: {
        show: true,
        title: "Edit Patient",
        modalButton: "Save Changes",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
      },
    });
  };

  onAssignServiceLevel = () => {
    this.setState({
      assignServiceLevel: true,
    });
  };

  onAddPatient = () => {
    if (this.props.selectedProviders && this.props.selectedProviders[0]) {
      this.props.setTargetProvider(
        this.props.selectedProviders[0].id as string
      );
    }
    this.setState({
      patientModalConfig: {
        show: true,
        title: "Create Patient",
        modalButton: "Create",
        assignServiceLevelButton: "Create and Assign Service Level",
        resourceAction: RCAResourceActions.Create,
      },
      preselectEntityAndProvider: true,
    });
  };

  setFormik = (formik: any) => this.setState({ formik });

  execPatientSearch = async () => {
    switch (this.props.tableType) {
      case PatientTableType.Admin:
        await this.execSearch({});
        break;
      case PatientTableType.Assignment:
        await this.execAssignmentPatientSearch({});
        break;
      case PatientTableType.Clinical:
        await this.execClinicalPatientSearch({});
        break;
      default:
        await this.execSearch({});
    }
  };

  renderPatientFormActions = () => {
    const { formik, patientModalConfig } = this.state;

    return (
      <div
        className={cx(styles.patientFormActions, "d-flex justify-content-end")}
      >
        <Button
          type="button"
          appearance="subtle"
          onClick={() => {
            this.onPatientModalClose();
            formik?.resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          appearance="primary"
          disabled={formik?.isSubmitting}
          onClick={() => {
            formik?.submitForm();
          }}
          style={{ marginLeft: 8 }}
        >
          {patientModalConfig?.modalButton}
        </Button>
        {patientModalConfig?.modalButton === "Create" ? (
          <Button
            type="submit"
            appearance="primary"
            disabled={formik?.isSubmitting}
            onClick={() => {
              this.onAssignServiceLevel();
              formik?.submitForm();
            }}
            style={{ marginLeft: 8 }}
          >
            {patientModalConfig?.assignServiceLevelButton}
          </Button>
        ) : null}
      </div>
    );
  };

  fetchPracticeIdsByOrg = async (organizationId: string) => {
    let allPracticeIds: string[] = [];
    const practiceModels = await PracticeModel.fetchByOrganizationIds([
      organizationId,
    ]);
    allPracticeIds = (practiceModels || []).map((pm) => pm.pluck("id")!);

    return allPracticeIds;
  };

  handleSelectLocation = async (tagIds: string[]) => {
    this.props.setPatientFilters({
      ...this.props.filters,
      tagIds,
    });

    const locations = this.getData();
    const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_LOCATION;
    this.props.setFilterItems([
      ...this.props.filterItems.filter(
        (filterItem) => filterItem.type !== FILTER_TYPE
      ),
      ...(locations
        ? locations
            .filter((location) => tagIds.includes(location.value))
            .map((location) => {
              return {
                type: FILTER_TYPE,
                description: location.label,
                value: location.value,
              };
            })
        : []),
    ]);

    await this.execPatientSearch();
  };

  handleFetchLocations = () => {
    const currentUser = this.props.currentUser;
    if (currentUser && currentUser.tagIds.length) {
      const locationTags = this.props.locationTags.filter((t) => {
        return currentUser.tagIds.includes(t._meta.id);
      });
      if (locationTags.length) {
        // setting storage item to persist location tags on page reload
        sessionStorage.setItem("currentUserTags", JSON.stringify(locationTags));
        return locationTags;
      }
    }
    return this.props.locationTags;
  };

  getData = () => {
    const tags = this.handleFetchLocations();

    const noLocation = [
      {
        label: "Unassigned",
        value: NO_TAGS_FILTER as string,
        role: LocationFilterRole.Filter,
      },
    ];
    const locations = (tags || []).map((tag) => {
      return {
        label: tag.value,
        value: tag._meta.id,
        role: LocationFilterRole.Locations,
      };
    });
    return [...noLocation, ...locations];
  };

  handleVirtualVisit = (selectedPatient?: Patient) => {
    if (selectedPatient) {
      this.props.history.push(
        `/patient/${selectedPatient.id}/${PatientTab.Monitoring}?virtual-visits=true`
      );
    }
  };

  render() {
    const { patients, selectedPatients } = this.props;
    const { selectedUserId } = this.state;

    return (
      <div
        className="container-fluid"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Header
          isNav={this.props.tableType === PatientTableType.Clinical}
          entity={{
            name: "patient",
            displayName: this.props.displayName,
            selectedEntityLength:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.selectedAssignmentPatients.length
                : this.props.patients.length,
            totalEntityLength:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.assignmentPatientPaginationConfig.totalResources
                : this.props.paginationConfig.totalResources,
          }}
          button={{
            create: {},
            toggleCollapse: {
              onToggle: () => {},
              collapsed: false,
            },
          }}
          table={{ pageLimit: this.props.patientPageLimit }}
          search={{
            onSearch:
              this.props.tableType === PatientTableType.Assignment
                ? this.execAssignmentPatientSearch
                : this.props.tableType === PatientTableType.Clinical
                ? this.execClinicalPatientSearch
                : this.execSearch,
            searchStr: this.state.search,
            filterStr: FILTER_STRING,
            filters:
              this.props.tableType === PatientTableType.Assignment
                ? {
                    status: undefined,
                    assignedUserIds: undefined,
                    practiceIds: undefined,
                    providerIds: undefined,
                  }
                : {
                    status: undefined,
                    assignedUserIds: undefined,
                  },
            placeholder: isPatientSlidingPaneEnabled
              ? "Search by ID/MRN"
              : "name, ID or MRN",
          }}
          filter={{
            onResetState: (newState) => this.setState(newState),
            onResetFilters:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.setAssignmentPatientFilters
                : this.props.setPatientFilters,
          }}
          filterItems={{
            enabled: true,
            onResetFilterItems: this.props.setFilterItems,
          }}
          userFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectUser: (users: string[], descriptions: string[]) => {
              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_USER;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(users
                  ? users.map((user, index) => {
                      return {
                        type: FILTER_TYPE,
                        description:
                          (descriptions && descriptions[index]) || user,
                        value: user,
                      };
                    })
                  : []),
              ]);

              if (users.toString() === MY_WATCHLIST) {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  watchingUserIds: users,
                });
                this.execPatientSearch();
              } else if (users.toString() === MY_PATIENTS) {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  assignedUserIds: users,
                });
                this.execPatientSearch();
              } else {
                const userFilter = Array.from(new Set(users));
                this.props.setPatientFilters({
                  ...this.props.filters,
                  assignedUserIds: userFilter,
                  watchingUserIds: userFilter,
                });
                this.execPatientSearch();
              }
            },
          }}
          statusFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectStatus: async (
              status: string | undefined,
              description: string | undefined
            ) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                status: status ? [status] : [],
              });

              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_STATUS;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(status
                  ? [
                      {
                        type: FILTER_TYPE,
                        description: description || status,
                        value: status,
                      },
                    ]
                  : []),
              ]);
              if (this.props.tableType === PatientTableType.Clinical) {
                await this.execClinicalPatientSearch({});
              }
            },
            selectedStatus: this.props.filters.status?.length
              ? this.props.filters.status
              : [],
          }}
          practiceFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectPractices: async (
              practiceIds: string[] | undefined,
              descriptions: string[] | undefined
            ) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                practiceIds,
              });

              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_PRACTICE;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(practiceIds
                  ? practiceIds.map((practiceIds, index) => {
                      return {
                        type: FILTER_TYPE,
                        description:
                          (descriptions && descriptions[index]) || practiceIds,
                        value: practiceIds,
                      };
                    })
                  : []),
              ]);

              await this.execPatientSearch();
            },
          }}
          providerFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectProviders: (
              providerIds: string[] | undefined,
              descriptions: string[] | undefined
            ) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                providerIds,
              });

              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_PROVIDER;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(providerIds
                  ? providerIds.map((providerId, index) => {
                      return {
                        type: FILTER_TYPE,
                        description:
                          (descriptions && descriptions[index]) || providerId,
                        value: providerId,
                      };
                    })
                  : []),
              ]);

              this.execPatientSearch();
            },
          }}
          alertFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectAlert: (alert: string, description: string) => {
              if (alert === "") {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  alert: undefined,
                });
              } else {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  alert,
                });
              }

              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_ALERT;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(alert
                  ? [
                      {
                        type: FILTER_TYPE,
                        description,
                        value: alert,
                      },
                    ]
                  : []),
              ]);

              this.execPatientSearch();
            },
          }}
          organizationFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            selectedOrganization: this.props.selectedOrganization,
            onSelectOrganization: async (organizationId: string) => {
              const allPracticeIds = await this.fetchPracticeIdsByOrg(
                organizationId
              );
              if (!this.state.currentUserTags?.length) {
                this.setState((prevState) => ({
                  ...prevState,
                  allPracticeIds: !allPracticeIds.length
                    ? [INVALID_MONGO_ID]
                    : allPracticeIds,
                }));
              }
              await this.props.setPatientFilters({
                status: [PatientState.Active],
                practiceIds: [],
                providerIds: [],
                assignedUserIds: undefined,
                tagIds: [],
              });
              await this.props.setFilterItems([
                {
                  type: FILTER_TYPES.FILTER_TYPE_STATUS,
                  value: PatientProgramState.ACTIVE,
                  description: StateDisplayName[PatientProgramState.ACTIVE],
                },
              ]);
              const path = "patients";
              window.history.replaceState(
                null,
                ``,
                `/${path}?organizationId=${organizationId}`
              );

              // Sync organization with organizationId
              const orgModel: OrganizationModel = await OrganizationModel.sync(
                organizationId
              );

              // make selected organization the default
              await this.props.selectOrganization(orgModel.pluckAll());
              // this.selectOrganization(`${orgModel.pluck('name')},${orgModel.pluck('id')!}`)
            },
          }}
          programFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            selectedProgram: this.props.selectedProgram,
            onSelectProgram: async (programId: string, description: string) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                programIds: programId === null ? [] : [programId],
              });

              const FILTER_TYPE = FILTER_TYPES.FILTER_TYPE_PROGRAM;
              this.props.setFilterItems([
                ...this.props.filterItems.filter(
                  (filterItem) => filterItem.type !== FILTER_TYPE
                ),
                ...(programId
                  ? [
                      {
                        type: FILTER_TYPE,
                        description,
                        value: programId,
                      },
                    ]
                  : []),
              ]);

              this.execPatientSearch();
            },
          }}
          locationFilter={{
            enabled: true,
            data: this.getData(),
            selectedId: this.props.filters.tagIds ?? [],
            selectedPractices: this.props.filters.practiceIds?.length
              ? this.props.filters.practiceIds
              : this.state.allPracticeIds ?? [],
            onChange: async (selectedPracticeIds: string[]) => {
              await this.handleSelectLocation(selectedPracticeIds);
            },
            onSelect: this.handleSelectLocation,
            onClear: async () => {
              await this.handleSelectLocation([]);
            },
          }}
        >
          <div
            className={cx(
              isPatientSlidingPaneEnabled && styles["last-element"]
            )}
          >
            <div
              className={cx(
                isPatientSlidingPaneEnabled && styles["last-element-child"]
              )}
            >
              <CreateEntityButton
                className={cx(styles.col)}
                handleClick={this.onAddPatient}
                text={
                  isPatientSlidingPaneEnabled
                    ? "+ Create New"
                    : "Create Patient"
                }
              />
            </div>
          </div>
        </Header>

        <div
          id="patient-list"
          className={cx(
            "container-fluid",
            styles.list,
            this.props.hidePatient ? styles.hide : styles.show
          )}
        >
          {this.props.tableType === PatientTableType.Clinical &&
            this.props.bannerView && (
              <>
                <PatientBanners
                  onVirtualVisit={this.handleVirtualVisit}
                  patients={[...new Set(patients)]}
                  onEditPatient={this.onEditPatient}
                  showPlaceholder={this.state.showPlaceholder}
                  execSearch={this.execClinicalPatientSearch}
                />
              </>
            )}
        </div>

        <RSModal
          size="full"
          backdrop="static"
          overflow={true}
          style={{ width: "90vw", paddingLeft: "5vw" }}
          open={this.state.patientModalConfig.show}
          onClose={this.onPatientModalClose}
        >
          <RSModal.Header closeButton>
            <RSModal.Title>{this.state.patientModalConfig.title}</RSModal.Title>
          </RSModal.Header>
          <RSModal.Body>
            <PatientForm
              button={this.state.patientModalConfig.modalButton}
              handleClose={this.onPatientModalClose}
              resourceAction={
                this.state.patientModalConfig
                  .resourceAction as RCAResourceActions
              }
              onSetFormik={(formik: any) => {
                this.setFormik(formik);
              }}
              assignServiceLevel={this.state.assignServiceLevel}
              onEditPatient={this.onEditPatient}
              preselectEntityAndProvider={this.state.preselectEntityAndProvider}
            />

            {this.renderPatientFormActions()}
          </RSModal.Body>
        </RSModal>
      </div>
    );
  }
}

export const PatientsPage = withRouter(
  connect(mapStateToProps, mapDispatcherToProps)(PatientsPageComponent)
);
