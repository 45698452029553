import { ReportTypes, ReportTypesLabel } from "../../model/reports";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import React from "react";

interface Props {
  handleReportTypeChange: (type: ReportTypes) => any;
  reportTypePlaceHolder: string;
}

function ReportType(props: Props) {
  const { handleReportTypeChange, reportTypePlaceHolder } = props;

  const reportTypes = [
    { label: ReportTypesLabel.rpm, value: ReportTypes.rpm },
    {
      label: ReportTypesLabel.rpmComprehensive,
      value: ReportTypes.rpmComprehensive,
    },
    { label: ReportTypesLabel.alert, value: ReportTypes.alert },
    { label: ReportTypesLabel.outcomes, value: ReportTypes.outcomes },
    { label: ReportTypesLabel.patientStatus, value: ReportTypes.patientStatus },
  ];

  return (
    <>
      <BasicMultiSelect
        data={reportTypes}
        onChange={async (value: ReportTypes) => {
          handleReportTypeChange(value);
        }}
        findValueBy={""}
        isSearchable={false}
        placeholder={
          reportTypes.find((report) => report.value === reportTypePlaceHolder)
            ?.label || ReportTypesLabel.rpm
        }
        onSelect={() => {}}
        onClear={() => {
          handleReportTypeChange(ReportTypes.rpm);
        }}
      />
    </>
  );
}

export default ReportType;
