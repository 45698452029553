export abstract class ResponseErrorParser {
  constructor(protected readonly error: any) {
    this.error = error;
  }

  /**
   * Get status code.
   *
   * @private
   */
  protected statusCode(): number {
    return this.error.response.status;
  }

  /**
   * Get the parsed error message.
   */
  public abstract message(): string[];
}
