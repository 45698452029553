import CommunityResource from "./form/communityResource.component";
import Food from "./form/food.component";
import Housing from "./form/housing.component";
import ImportantInfo from "./form/importantInfo.component";
import MedicalProviderPlan from "./form/medicalProviderPlan.component";
import OtherProviderPlan from "./form/otherProviderPlan.component";
import PersonalSafety from "./form/personalSafety.component";
import Transportation from "./form/transportation.component";
import TreatmentConcerns from "./form/treatmentConcerns.component";
import TreatmentPlanRevision from "./form/treatmentPlanRevision.component";
import Utilities from "./form/utilities.component";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import React from "react";
interface Props {}

export class TreatmentPlanTab extends React.Component {
  // *************** Global Variables *************** //
  medicalProviderPlanCollapse: HTMLInputElement | any;
  otherProviderPlanCollapse: HTMLInputElement | any;
  importantPatientInfoCollapse: HTMLInputElement | any;
  treatmentConcernCollapse: HTMLInputElement | any;
  housingCollapse: HTMLInputElement | any;
  transportationCollapse: HTMLInputElement | any;
  personalSafetyCollapse: HTMLInputElement | any;
  foodCollapse: HTMLInputElement | any;
  utilityCollapse: HTMLInputElement | any;
  communityResourceCollapse: HTMLInputElement | any;
  treatmentPlanRevisionCollapse: HTMLInputElement | any;
  allCollapsibleElements: HTMLInputElement | any;

  constructor(props: any) {
    super(props);
    this.state = {};

    // HTML Elements references
    this.medicalProviderPlanCollapse = React.createRef();
    this.otherProviderPlanCollapse = React.createRef();
    this.importantPatientInfoCollapse = React.createRef();
    this.treatmentConcernCollapse = React.createRef();
    this.housingCollapse = React.createRef();
    this.transportationCollapse = React.createRef();
    this.personalSafetyCollapse = React.createRef();
    this.foodCollapse = React.createRef();
    this.utilityCollapse = React.createRef();
    this.communityResourceCollapse = React.createRef();
    this.treatmentPlanRevisionCollapse = React.createRef();
    this.allCollapsibleElements = [
      this.medicalProviderPlanCollapse,
      this.otherProviderPlanCollapse,
      this.importantPatientInfoCollapse,
      this.treatmentConcernCollapse,
      this.housingCollapse,
      this.transportationCollapse,
      this.personalSafetyCollapse,
      this.foodCollapse,
      this.utilityCollapse,
      this.communityResourceCollapse,
      this.treatmentPlanRevisionCollapse,
    ];
  }

  render() {
    return (
      <>
        <div id="treatment-plan" className="tab-pane fade in active show">
          <div className="mt">
            <div>
              <div className="text-right mb-2">
                <button
                  className="btn btn-glow-primary btn-primary btn-sm add-user expand-all mr-2"
                  onClick={() =>
                    this.allCollapsibleElements.forEach((el: any) =>
                      el.current.click()
                    )
                  }
                >
                  <span />
                </button>
                <button
                  className="btn btn-glow-secondary add-user btn-secondary btn-sm"
                  data-toggle="modal"
                  data-target="#create-allergies"
                >
                  <span>
                    <PersonAddAltRoundedIcon
                      className="mr-1"
                      fontSize="small"
                    />
                    Report
                  </span>
                </button>
                <button className="btn btn-glow-primary add-user btn-primary btn-sm">
                  <span>
                    <i className="fa fa-user-plus mr-1" aria-hidden="true" />
                    Edit
                  </span>
                </button>
              </div>
            </div>
            <div className="accordion detail_accordation">
              <div>
                <a
                  href="#"
                  className="btn-header-link collapsed"
                  ref={this.medicalProviderPlanCollapse}
                  data-toggle="collapse"
                  data-target="#treatmentbasic1"
                  aria-expanded="true"
                  aria-controls="treatmentbasic1"
                >
                  <div className="form-group">
                    <h6>Medical Provider Treatment Plan for RPM</h6>
                    <hr />
                  </div>
                </a>
              </div>
              <div id="treatmentbasic1" className="collapse panel-collapse">
                <MedicalProviderPlan />
              </div>
              <div>
                <a
                  href="#"
                  ref={this.otherProviderPlanCollapse}
                  className="btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#treatmentbasic2"
                  aria-expanded="true"
                  aria-controls="treatmentbasic2"
                >
                  <div className="form-group">
                    <h6>Other Provider Plan Management</h6>
                    <hr />
                  </div>
                </a>
              </div>
              <div id="treatmentbasic2" className="collapse panel-collapse">
                <OtherProviderPlan />
              </div>
              <div>
                <a
                  href="#"
                  ref={this.importantPatientInfoCollapse}
                  className="btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#treatmentbasic3"
                  aria-expanded="true"
                  aria-controls="treatmentbasic3"
                >
                  <div className="form-group">
                    <h6>Important Patient Information</h6>
                    <hr />
                  </div>
                </a>
              </div>
              <div id="treatmentbasic3" className="collapse panel-collapse">
                <ImportantInfo />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.treatmentConcernCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic4"
                      aria-expanded="true"
                      aria-controls="treatmentbasic4"
                    >
                      <div className="form-group">
                        <h6>Treatment Concerns</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic4" className="collapse panel-collapse">
                    <TreatmentConcerns />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.housingCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic5"
                      aria-expanded="true"
                      aria-controls="treatmentbasic5"
                    >
                      <div className="form-group">
                        <h6>Housing</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic5" className="collapse panel-collapse">
                    <Housing />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.transportationCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic6"
                      aria-expanded="true"
                      aria-controls="treatmentbasic6"
                    >
                      <div className="form-group">
                        <h6>Transportation</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic6" className="collapse panel-collapse">
                    <Transportation />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.personalSafetyCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic7"
                      aria-expanded="true"
                      aria-controls="treatmentbasic7"
                    >
                      <div className="form-group">
                        <h6>Personal Safety</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic7" className="collapse panel-collapse">
                    <PersonalSafety />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.foodCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic8"
                      aria-expanded="true"
                      aria-controls="treatmentbasic8"
                    >
                      <div className="form-group">
                        <h6>Food</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic8" className="collapse panel-collapse">
                    <Food />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mt-1">
                    <a
                      href="#"
                      ref={this.utilityCollapse}
                      className="btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#treatmentbasic9"
                      aria-expanded="true"
                      aria-controls="treatmentbasic9"
                    >
                      <div className="form-group">
                        <h6>Utilities</h6>
                        <hr />
                      </div>
                    </a>
                  </div>
                  <div id="treatmentbasic9" className="collapse panel-collapse">
                    <Utilities />
                  </div>
                </div>
              </div>
              <div className="mt-2" id="treatmenthead10">
                <a
                  href="#"
                  ref={this.communityResourceCollapse}
                  className="btn-header-link"
                  data-toggle="collapse"
                  data-target="#treatmentbasic10"
                  aria-expanded="true"
                  aria-controls="treatmentbasic10"
                >
                  <div className="form-group">
                    <h6>Community Resources and Assistance Available</h6>
                    <hr />
                  </div>
                </a>
              </div>
              <div id="treatmentbasic10" className="collapse panel-collapse">
                <CommunityResource />
              </div>
              <div id="treatmenthead11">
                <a
                  href="#"
                  ref={this.treatmentPlanRevisionCollapse}
                  className="btn-header-link"
                  data-toggle="collapse"
                  data-target="#treatmentbasic11"
                  aria-expanded="true"
                  aria-controls="treatmentbasic11"
                >
                  <div className="form-group">
                    <h6>Treatment Plan Revision History</h6>
                    <hr />
                  </div>
                </a>
              </div>
              <div id="treatmentbasic11" className="collapse panel-collapse">
                <TreatmentPlanRevision />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
