import {
  CancelAlert,
  ClearSelect,
  EmptyAlert,
  NavigateBack,
  SwitchOff,
  SwitchOn,
} from "@/assets/icons/dashboard";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cx from "clsx";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAcceptedRejectAlerts,
  selectAcceptedRejectedNoteIds,
  selectRulesForSelectedPatient,
} from "@/domain/notes/redux/selectors";
import { AlertSeverity } from "../AlertsTableNew/alertSeverity";
import { generateAlertData } from "../AlertsTableNew/helpers";
import { MultiSelect } from "@/components/multiSelect";
import {
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  ObservationType,
  ObservationTypeNew,
} from "@/domain/observations/types";
import { AlertLevelNew, Note } from "@/domain/notes/model/types";
import {
  AlertTypeLabelMapNew,
  ArrayValidLength,
} from "../AlertsTableNew/constants";
import {
  getSeverityAlertLevelNew,
  NoteDispositions,
  PatientNoteTypes,
} from "@/domain/notes/model/constants";
import { MultiSelectDataType, MultiSelectNew } from "./multiSelect";
import { Intervention, Interventions } from "@/library/types/note";
import { InterventionComponent } from "@/components/Intervention";
import { selectRulesForPatient } from "@/domain/rules/redux/selectors";
import { RouteParams } from "../PatientDetails/types";
import { keyBy } from "lodash";
import { RuleModel } from "@/domain/rules/model";
import { dispatchSetPatientRules } from "@/domain/rules/redux/actions";
import { AlertTableRowNew } from "../AlertsTableNew/types";
import {
  dispatchSaveCreateNoteAlert,
  dispatchSelectedNoteIds,
} from "@/domain/notes/redux/actions";
import { selectFirstPatient, selectPatientTimer } from "../../redux/selectors";
import { RootState } from "@/types";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { NoteModel } from "@/domain/notes/model";
import { ResourceType } from "@/library/core/config/resource";
import { RCAResourceActions } from "@/library/core/config/actions";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { RuleEnabledKey, RuleOverrides } from "@/domain/rules/model/types";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";

export interface AcceptedAlerts {
  date: string;
  comment: string;
  noteId: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
}

export type observationsDataType = {
  date: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
  comment?: string;
  typeDate?: string;
  typeDateDesc?: string;
  typeValue1?: string;
  typeValue2?: string;
  typeValue1P?: string;
  typeValue2P?: string;
  typeOperator?: string;
  typeValue?: string;
  typePriority?: string;
  typeValue3?: string;
  typeValue3P?: string;
  typeValue3Operator?: string;
};

interface ObservationData {
  date: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
  comment?: string;
  typeDate?: string;
  typeDateDesc?: string;
  typeValue1?: string;
  typeValue2?: string;
  typeValue1P?: string;
  typeValue2P?: string;
  typeOperator?: string;
  typeValue?: string;
  typePriority?: string;
  typeValue3?: string;
  typeValue3P?: string;
  typeValue3Operator?: string;
}

export type TextAreaProps = {
  style: {
    height: string;
    minHeight: string;
    maxHeight: string;
  };
  scrollHeight: number;
};

export type AlertType = {
  date: string;
  dateText: string;
  typeTitle: string;
  types: ObservationData[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: ObservationData[] | null | undefined;
  comment?: string;
  noteId?: string;
};

export const ChartPriority = {
  High: "High",
  Medium: "Medium",
  Normal: "Normal",
  None: "None",
};

export const DATE_FORMAT = "MM/dd/yy | hh:mm:ss";

const getPriorityView = (priority: string = ChartPriority.Normal) => {
  return (
    <div
      className={
        priority === ChartPriority.High
          ? styles.highText
          : priority === ChartPriority.Medium
          ? styles.mediumText
          : styles.normalText
      }
    >
      {priority}
    </div>
  );
};

const getPriorityValue = (
  priority: string = ChartPriority.None,
  value: string | number = 0
) => {
  return (
    <div
      className={
        priority === ChartPriority.High
          ? styles.highValue
          : priority === ChartPriority.Medium
          ? styles.mediumValue
          : priority === ChartPriority.Normal
          ? styles.normalValue
          : styles.lowValue
      }
    >
      {value}
    </div>
  );
};

function resizeTextArea(textarea: TextAreaProps, userDefinedHeight = 0) {
  const { style } = textarea;
  style.height = style.minHeight = "auto";
  style.minHeight = `${Math.min(
    textarea.scrollHeight + 4,
    parseInt(textarea.style.maxHeight)
  )}px`;
  style.height = `${Math.max(textarea.scrollHeight + 4, userDefinedHeight)}px`;
}

export const CreateNote: React.FC = (props) => {
  const [alertsCount, setAlertsCount] = useState(0);
  const [alerts, setAlerts] = useState<AlertTableRowNew[]>([]);
  const [selectedInterventions, setSelectedInterventions] = useState<string[]>(
    []
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [content, setContent] = useState("");
  const [interKey, setInterKey] = useState(1);
  const [rowLength, setRowLength] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [isSwitchDirect, setSwitchDirect] = useState(false);
  const [valid, setValid] = useState(false);
  const [patientId, setPatientId] = useState("");
  const params = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedNoteIds = useSelector(selectAcceptedRejectedNoteIds);
  const rulesbyTypeNew = useSelector(selectRulesForSelectedPatient);
  const isAcceptAlert = useSelector(selectAcceptedRejectAlerts);
  const [key, setKey] = useState(ArrayValidLength);
  const [alertArray, setalertArray] = useState<ObservationData[]>([]);
  const [disposition, setDisposition] = useState(isAcceptAlert);
  const [overrideArray, setOverrideArray] = useState([]);
  const patient = useSelector(selectFirstPatient);
  useEffect(() => {
    setDisposition(isAcceptAlert);
  }, [isAcceptAlert]);

  useEffect(() => {
    if (params?.patientId) setPatientId(params.patientId);
  }, [params?.patientId]);

  useEffect(() => {
    setOverrideArray(rulesbyTypeNew ?? []);
  }, [patientId]);

  useEffect(() => {
    let notesArray: AlertTableRowNew[] = [];
    if (selectedNoteIds?.length) {
      selectedNoteIds?.map((note: AlertTableRowNew) => {
        notesArray.push(note);
      });
    }
    const alertArray = generateAlertData(selectedNoteIds, rulesbyTypeNew);
    setKey(key + ArrayValidLength);
    setalertArray(alertArray);
    setAlerts(notesArray);
    setAlertsCount(notesArray.length);
  }, [selectedNoteIds]);

  useEffect(() => {
    const alertArray = generateAlertData(selectedNoteIds, rulesbyTypeNew);
    setalertArray(alertArray);
    setKey(key + ArrayValidLength);
  }, [rulesbyTypeNew]);

  useEffect(() => {
    if (params?.patientId) setPatientId(params.patientId);
  }, [params?.patientId]);

  useEffect(() => {
    validate();
  }, [selectedInterventions]);

  const validate = () => {
    let validData = true;
    if (!selectedInterventions.length || !selectedNoteIds.length) {
      validData = false;
    }
    setValid(validData);
  };

  const onOptionClick = (option: MultiSelectDataType) => {
    let selectedOptions = selectedInterventions;
    if (selectedOptions.includes(option.value)) {
      selectedOptions = selectedOptions.filter((data) => data != option.value);
    } else {
      selectedOptions.push(option.value);
    }
    validate();
    setSelectedInterventions(selectedOptions);
    if (
      selectedOptions.includes(Intervention.VideoVisitConducted) ||
      selectedOptions.includes(Intervention.ContactedPatientPerPhone)
    ) {
      setSwitchDirect(true);
    }
    setInterKey(interKey + 1);
  };

  const onOptionClearClick = (option: MultiSelectDataType) => {
    let selectedOptions = selectedInterventions;
    if (selectedOptions.includes(option.value)) {
      selectedOptions = selectedOptions.filter((data) => data != option.value);
    } else {
      selectedOptions.push(option.value);
    }
    validate();
    setSelected(selectedOptions);
    setSelectedInterventions(selectedOptions);
    if (
      selectedOptions.includes(Intervention.VideoVisitConducted) ||
      selectedOptions.includes(Intervention.ContactedPatientPerPhone)
    ) {
      setSwitchDirect(true);
    }
    setInterKey(interKey + 1);
  };

  const onSelectAll = () => {
    if (selectAll) {
      setSelectedInterventions([]);
      setSelectAll(false);
    } else {
      let selected = Object.keys(Interventions)
        .filter(
          (intervention) => intervention !== Intervention.LeftPhoneMessage
        )
        .map((key) => {
          return key;
        });
      setSelectedInterventions(selected);
      setSelectAll(true);
    }
    validate();
  };

  const textarea = document.getElementById("narrativeText");
  let userDefinedHeight = 0;
  if (textarea) {
    textarea.addEventListener("input", () => {
      textarea.style.height =
        textarea.scrollHeight > textarea.clientHeight
          ? textarea.scrollHeight + "px"
          : textarea.clientHeight + "px";
    });
  }

  const saveSelectedAlerts = (noteIds: AlertTableRowNew[]) => {
    dispatch(dispatchSelectedNoteIds(noteIds));
  };

  const onClearClick = (noteId?: string) => {
    let arrayOfAlerts = selectedNoteIds;
    let arrayOfAlertsNew = [];
    arrayOfAlertsNew = arrayOfAlerts.filter(
      (alertElement: AlertTableRowNew) => alertElement.noteId !== noteId
    );
    saveSelectedAlerts(arrayOfAlertsNew);
  };

  const onSubmitClick = () => {
    LoadingIndicator.fire.show();
    let replyToIds = alerts.map((alert) => {
      return alert.noteId;
    });
    let values = {
      directPatientContact: isSwitchDirect,
      seconds: 0,
      disposition: disposition
        ? NoteDispositions.Accepted
        : NoteDispositions.Rejected,
      comment: content,
      type: PatientNoteTypes.Clinical,
      patientId: patientId,
      intervention: selectedInterventions,
      replyToIds: replyToIds,
    };
    onCreateNote(values as Partial<Note>, {});
  };

  const onCreateNote = async (values: Partial<Note>, {}) => {
    values.comment = values.comment === "" ? null : values.comment;
    await Invoker.make<Note>(
      Receiver.make(
        NoteModel.make(values as Note, true),
        ResourceType.note,
        RCAResourceActions.Create
      ),
      CleanTask.make(() => {}),
      FinallyTask.make(() => {
        let text = `${selectedNoteIds.length} Alert${
          selectedNoteIds.length > 1 ? "s" : ""
        } ${disposition ? "accepted" : "rejected"} !`;
        dispatch(dispatchSaveCreateNoteAlert(text));
        dispatch(dispatchSelectedNoteIds([]));
        LoadingIndicator.fire.hide();
        history.go(-1);
      })
    ).invoke();
  };

  const onDoneClick = () => {
    setSelected(selectedInterventions);
  };

  return (
    <>
      <div className={cx(styles.createNoteMainDiv, "createNoteMainDiv")}>
        <div
          className={cx(styles.navigateBackContainer, styles.pointer)}
          onClick={() => history.go(-1)}
        >
          <img src={NavigateBack} alt="navigateBack" />
          <div>
            <label className={cx(styles.headerPage)}>{`Create Note`}</label>
          </div>
        </div>
        <div className={cx(styles.detailsDiv)}>
          <div className={cx(styles.acceptAlertDiv)}>
            <label
              className={cx(styles.subTitle, styles.acceptAlertLabelPadding)}
            >
              {`${
                disposition ? "Accepting" : "Rejecting"
              } Alerts (${alertsCount})`}
            </label>
            <div className={cx(styles.acceptAlertContentDiv)}>
              {alertArray?.length > 0 ? (
                <div className={styles.alertList}>
                  {alertArray?.map((alert: AlertType) => {
                    if (alert?.disposition && alert?.disposition?.length > 0) {
                      return alert?.disposition?.map(
                        (typeObj: ObservationData, index: number) => (
                          <div className={styles.mainTypeDiv}>
                            <div className={styles.typeParentDiv}>
                              <div className={styles.typeDiv} key={index}>
                                <div className="d-flex align-items-center mr-3">
                                  <div
                                    className={cx(
                                      styles.typeDateText,
                                      styles.margintp
                                    )}
                                  >
                                    {typeObj.typeDate}
                                  </div>
                                  <div className={styles.typeDatelbl}>
                                    {typeObj.typeDateDesc}
                                  </div>
                                </div>
                                <div className={cx(styles.typeDataDiv)}>
                                  <div className={styles.typeDateText}>
                                    {typeObj.typeValue}
                                  </div>
                                  {getPriorityView(typeObj?.typePriority)}
                                </div>
                              </div>
                              <div className={styles.typeDiv} key={index}>
                                <div className="d-flex align-items-center">
                                  <div
                                    className={cx(
                                      styles.typeDateText,
                                      styles.margintp
                                    )}
                                  >
                                    {typeObj.typeDate}
                                  </div>
                                  <div className={styles.typeDatelbl}>
                                    {alert.narrative}
                                  </div>
                                </div>
                                <div className={cx(styles.typeDataDiv)}>
                                  {getPriorityView(typeObj?.typePriority)}
                                </div>
                              </div>
                            </div>

                            {alertArray.length > 0 && (
                              <div
                                className={cx(
                                  styles.typeParentDivCheckBox,
                                  alertArray.length <= 1 && styles.clearDisabled
                                )}
                                onClick={() =>
                                  alertArray.length > 1
                                    ? onClearClick(alert?.noteId)
                                    : () => {}
                                }
                              >
                                <img
                                  src={CancelAlert}
                                  alt="cancelalert"
                                  className={cx(
                                    styles.imgCancelAlert,
                                    alertArray.length <= 1 &&
                                      styles.clearDisabled
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        )
                      );
                    } else if (alert?.types) {
                      return alert?.types?.map((typeObj: ObservationData[]) => {
                        {
                          return (
                            <div className={styles.mainTypeDiv}>
                              <div className={styles.typeParentDiv}>
                                {typeObj.map(
                                  (type: ObservationData, index: number) => (
                                    <div className={styles.typeDiv} key={index}>
                                      <div className="d-flex align-items-center mr-3">
                                        <div
                                          className={cx(
                                            styles.typeDateText,
                                            styles.margintp
                                          )}
                                        >
                                          {type.typeDate}
                                        </div>
                                        <div className={styles.typeDatelbl}>
                                          {type.typeDateDesc}
                                        </div>
                                      </div>
                                      <div className={cx(styles.typeDataDiv)}>
                                        {type?.typeValue1 &&
                                          getPriorityValue(
                                            type?.typeValue1P,
                                            type?.typeValue1
                                          )}
                                        {type?.typeValue3 && (
                                          <span className={styles.operatorTxt}>
                                            {type.typeValue3Operator}
                                          </span>
                                        )}
                                        {type?.typeValue3 &&
                                          getPriorityValue(
                                            type?.typeValue3P,
                                            type?.typeValue3
                                          )}
                                        {type?.typeValue2 && (
                                          <span className={styles.operatorTxt}>
                                            {type.typeOperator}
                                          </span>
                                        )}
                                        {type?.typeValue2 &&
                                          getPriorityValue(
                                            type?.typeValue2P,
                                            type?.typeValue2
                                          )}
                                        <div className={styles.typeDateText}>
                                          {type.typeValue}
                                        </div>
                                        {getPriorityView(type?.typePriority)}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              {alertArray.length > 0 && (
                                <div
                                  className={cx(
                                    styles.typeParentDivCheckBox,
                                    alertArray.length <= 1 &&
                                      styles.clearDisabled
                                  )}
                                  onClick={() =>
                                    alertArray.length > 1
                                      ? onClearClick(alert?.noteId)
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={CancelAlert}
                                    alt="cancelalert"
                                    className={cx(
                                      styles.imgCancelAlert,
                                      alertArray.length <= 1 &&
                                        styles.clearDisabled
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }
                      });
                    }
                  })}
                </div>
              ) : (
                <div className={cx(styles.alertEmpty)}>
                  <div className="">
                    <div className={cx(styles.alertEmptyImg)}>
                      <img src={EmptyAlert} alt="empty" />
                    </div>
                    <div>
                      <label
                        className={cx(styles.emptyAlertText)}
                      >{`No alerts selected`}</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={cx(styles.interventionMainDiv)}>
              <div className={cx(styles.interventionDiv)}>
                <div className={cx(styles.directContactDiv)}>
                  <div className={cx(styles.dispoDiv)}>
                    <label
                      className={cx(styles.optionHeader)}
                    >{`Disposition`}</label>
                    <FormControl className={cx(styles.radioDiv)}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={isAcceptAlert ? "accept" : "reject"}
                        name="radio-buttons-group"
                        className={cx(styles.radioSummary)}
                        onChange={(e) => {
                          setDisposition(
                            e.target?.value === "accept" ? true : false
                          );
                        }}
                      >
                        <FormControlLabel
                          value={"accept"}
                          control={<Radio className={cx(styles.radioButton)} />}
                          label="Accept"
                          className={cx(styles.radioButtonText)}
                        />
                        <FormControlLabel
                          value={"reject"}
                          control={<Radio className={cx(styles.radioButton)} />}
                          label="Reject"
                          className={cx(styles.radioButtonText)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className={styles.directPatientContactDiv}>
                    <label
                      className={cx(styles.optionHeader)}
                    >{`Direct Patient Contact`}</label>
                    <div
                      onClick={() => setSwitchDirect(!isSwitchDirect)}
                      className={cx(styles.switchDiv)}
                    >
                      <img
                        className={styles.switchClass}
                        src={isSwitchDirect ? SwitchOn : SwitchOff}
                        alt="switch"
                      />
                    </div>
                  </div>
                </div>

                <div className={cx(styles.dividerDiv)}></div>
                <div className={styles.interventDiv}>
                  <div className={cx(styles.interventionsDropDiv, "p-o col-6")}>
                    <label
                      className={cx(styles.optionHeader)}
                    >{`Interventions`}</label>
                    <MultiSelectNew
                      options={Object.keys(Interventions)
                        .filter(
                          (intervention) =>
                            intervention !== Intervention.LeftPhoneMessage
                        )
                        .map((key) => {
                          return {
                            value: key,
                            label: Interventions[key as Intervention],
                          };
                        })}
                      onClick={(option: MultiSelectDataType) =>
                        onOptionClick(option)
                      }
                      selectedOptions={selectedInterventions}
                      onSelectAll={() => onSelectAll()}
                      placeHolder={"Select"}
                      onDoneClick={() => onDoneClick()}
                    />
                    <div key={interKey} className={cx(styles.interDiv)}>
                      {selected.map((data, index) => (
                        <InterventionComponent
                          data={data}
                          onClick={(option: MultiSelectDataType) =>
                            onOptionClearClick(option)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className={styles.narrativeTextAreaDiv}>
                    <label
                      className={cx(styles.optionHeader)}
                    >{`Narrative`}</label>
                    <div className={cx(styles.narrativeTextArea)}>
                      <textarea
                        value={content}
                        maxLength={1000}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder={"Enter"}
                        rows={rowLength}
                        id="narrativeText"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cx(
                styles.actionAlert,
                !valid && styles.notAllowedClick
              )}
            >
              <div
                className={cx(styles.cancelActionButton, styles.cursorPointer)}
                onClick={() => history.go(-1)}
              >{`Cancel`}</div>
              <div
                className={cx(
                  styles.createActionButton,
                  valid ? styles.cursorPointer : styles.actionNotAllowed
                )}
                onClick={() => (valid ? onSubmitClick() : () => {})}
              >{`Create`}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
