import { Constructor } from "../../types/constructor";
import { Policy, PolicyRole } from "../policy.interface";
import { ScopeBuilder } from "../scopeBuilders/scopeBuilder.interface";

export abstract class PolicyBuilder {
  protected practices!: string[];
  protected practiceId!: string;
  protected organizations!: string[];
  protected organizationId!: string;
  protected roleIds!: string[];
  protected hasGlobal!: boolean;

  protected readonly scope: {
    organization: ScopeBuilder;
    practice: ScopeBuilder;
    global: ScopeBuilder;
  };

  constructor(scopeBuilder: {
    organization: Constructor<ScopeBuilder>;
    practice: Constructor<ScopeBuilder>;
    global: Constructor<ScopeBuilder>;
  }) {
    this.scope = {
      organization: new scopeBuilder.organization(),
      practice: new scopeBuilder.practice(),
      global: new scopeBuilder.global(),
    };
  }

  abstract build(): Promise<Policy>;

  withPractices(practices: string[]): PolicyBuilder {
    this.practices = practices;
    return this;
  }

  withPractice(practiceId: string): PolicyBuilder {
    this.practiceId = practiceId;
    return this;
  }

  withOrganization(organizationId: string): PolicyBuilder {
    this.organizationId = organizationId;
    return this;
  }

  withOrganizations(organizations: string[]): PolicyBuilder {
    this.organizations = organizations;
    return this;
  }

  withRole(roleIds: string[]): PolicyBuilder {
    this.roleIds = roleIds;
    return this;
  }

  // builds policy with a wildcard scope
  withAll(wildCard: string): PolicyBuilder {
    if (wildCard) {
      this.organizations.push(wildCard);
    }
    return this;
  }

  // builds policy with a global scope
  withGlobal(hasGlobal: boolean): PolicyBuilder {
    this.hasGlobal = hasGlobal;
    return this;
  }

  protected get scopeValue(): { roles: PolicyRole[] } {
    return {
      roles: this.roleIds.map((id) => ({
        roleId: id,
        notBefore: null,
        notAfter: null,
      })),
    };
  }
}
