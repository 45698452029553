import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Attributes, Model } from "@/library/model";
import { Provider } from "./provider";
import { deserialize } from "@/library/model";
import { ModelCollection } from "@/library/model";
import { ProviderForm } from ".";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ProviderSerializer } from "@/library/serializers/provider";
import { isEmptyArray } from "@/util/isEmptyArray";
import { MODEL_SYNCED } from "@/library/constants";
import { Iterator } from "@/library/iteration";
import {
  PostSetParameterOverrides,
  PostUnsetParameterOverrides,
} from "@/domain/organization/model/types";
import { Http } from "@/library/core/api/http";

export class ProviderModel extends Model<Provider> {
  static readonly path = "providers";

  /**
   * Makes a new instance of PracticeModel. It requires only the attributes
   * that would be used to instatiate new Practice Model.
   *
   * @param attributes
   */
  static make(attributes: Provider): ProviderModel {
    return new ProviderModel(
      new Attributes<Provider>(attributes),
      new RemoteCareAPISync<Provider>(ProviderModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of providers using the provided resource url and a deserializer thunk.
   * The entire practice list can be accessed in the models member of the Collection class.
   */
  static makeProviderCollection(): ModelCollection<ProviderModel, Provider> {
    return new ModelCollection<ProviderModel, Provider>(
      `${RemoteCareAPISync.host}/${ProviderModel.path}`,
      (entity: Provider) => deserialize(entity, ProviderModel.make)
    );
  }

  /**
   * Sync provider by Id.
   * @param id
   */
  static async sync(id: string): Promise<ProviderModel> {
    const provider: Provider = (
      await new RemoteCareAPISync<Provider>(ProviderModel.path).fetch(id)
    ).data;
    return deserialize(provider, ProviderModel.make);
  }

  /**
   * Sync providers by Ids.
   *
   * @param id
   */
  static syncMany = async (ids: string[]): Promise<ProviderModel[]> =>
    RemoteCareAPISync.concurrently(ids, ProviderModel.path, ProviderModel.make);

  /**
   * Takes a form data and serializes it based on intended RemoteCareAPI Resource action.
   *
   * @param data
   * @param action
   */
  static serialize = (
    data: ProviderForm,
    action: RCAResourceActions
  ): ProviderModel => {
    return ProviderSerializer.for(action, data).serialize();
  };

  /**
   * Fetch providers by practice ids
   * @param ids
   * @returns
   */
  static async fetchByPracticeIds(ids: string[]): Promise<ProviderModel[]> {
    if (isEmptyArray(ids)) {
      return new Promise((resolve) => resolve([]));
    }

    const collection = this.makeProviderCollection();
    collection.on(MODEL_SYNCED, () => {});
    collection.withParams({
      practiceIds: ids,
    });

    const iterator: Iterator<ProviderModel, Provider> =
      await collection.getMany();
    return iterator.getAll();
  }

  static async setParameterOverrides(
    orgId: string,
    payload: PostSetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${ProviderModel.path}/${orgId}/actions/set-parameter-override`,
      payload
    );
  }

  static async unsetParameterOverrides(
    orgId: string,
    payload: PostUnsetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${ProviderModel.path}/${orgId}/actions/unset-parameter-override`,
      payload
    );
  }
}
