import React, { ReactNode } from "react";
import { format } from "date-fns";

import { extractphone, formatPhone } from "@/components/form";
import { Modal } from "@/components/modal";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import InsuranceFormComponent from "./insuranceForm.component";
import { InsuranceForm } from "./model/insurance";

interface Props {
  patientId: string | undefined;
}

interface State {
  insuranceFormModalConfig: {
    show: boolean;
    title: string;
    modalButton: string;
  };
  patientInsurance: InsuranceForm[];
  selectedInsurance: {
    insurance: InsuranceForm | null | undefined;
    id: number | null | undefined;
  };
  patientModel: PatientModel | null;
  disableSaveBtn: boolean;
}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      insuranceFormModalConfig: {
        show: false,
        title: "Add Insurance Information",
        modalButton: "Add",
      },
      patientInsurance: [],
      selectedInsurance: { insurance: null, id: null }, // Saves reference of selected insurance to edit
      patientModel: null,
      disableSaveBtn: false,
    };
  }

  handleCloseInsuranceFormModal = (): void => {
    this.setState({
      ...this.state,
      insuranceFormModalConfig: {
        show: false,
        title: "",
        modalButton: "",
      },
    });
  };

  handleOpenInsuranceFormModal = (modalType: {
    isEditModal: boolean;
    selectedInsurance?: InsuranceForm;
    id?: number;
  }): void => {
    this.setState({
      ...this.state,
      insuranceFormModalConfig: {
        show: true,
        title: modalType.isEditModal
          ? "Edit Insurance Information"
          : "Add Insurance Information",
        modalButton: modalType.isEditModal ? "Save Changes" : "Add",
      },
      selectedInsurance: {
        insurance: modalType.selectedInsurance,
        id: modalType.id,
      },
    });
  };
  /**
   * Adding patient insurance in `patientInsurance` array and render it on the insurance table
   * @param insuranceInfo
   */
  onAddPatientInsurance = (insuranceInfo: InsuranceForm): void => {
    insuranceInfo.phone = extractphone(insuranceInfo.phone!);
    insuranceInfo.effectiveDate = insuranceInfo.effectiveDate
      ? format(new Date(insuranceInfo.effectiveDate) as Date, "yyyy-MM-dd")
      : null;
    insuranceInfo.expirationDate = insuranceInfo.expirationDate
      ? format(new Date(insuranceInfo.expirationDate) as Date, "yyyy-MM-dd")
      : null;
    this.setState({
      ...this.state,
      //Adding newly created insurance in the patientInsurance Array
      patientInsurance: [...this.state.patientInsurance, insuranceInfo],
      insuranceFormModalConfig: {
        ...this.state.insuranceFormModalConfig,
        show: false,
      },
    });
  };

  /**
   * Updating the patient insurance existing record
   * @param insuranceInfo
   * @param index
   */
  onEditInsurance = (insuranceInfo: InsuranceForm, index: number): void => {
    insuranceInfo.phone = extractphone(insuranceInfo.phone!);
    insuranceInfo.effectiveDate = insuranceInfo.effectiveDate
      ? format(new Date(insuranceInfo.effectiveDate) as Date, "yyyy-MM-dd")
      : null;
    insuranceInfo.expirationDate = insuranceInfo.expirationDate
      ? format(new Date(insuranceInfo.expirationDate) as Date, "yyyy-MM-dd")
      : null;
    this.state.patientInsurance[index] = insuranceInfo;
    this.setState({
      ...this.state,
      insuranceFormModalConfig: {
        ...this.state.insuranceFormModalConfig,
        show: false,
      },
    });
  };

  parseInsuranceFieldValues = (): InsuranceForm[] => {
    return this.state.patientInsurance.map((insurance: InsuranceForm) => {
      return {
        type: insurance.type,
        company: insurance.company,
        groupNumber:
          insurance.groupNumber === "" ? null : insurance.groupNumber,
        policyNumber: insurance.policyNumber,
        phone: insurance.phone === "" ? null : insurance.phone!,
        effectiveDate: insurance.effectiveDate,
        expirationDate: insurance.expirationDate,
      };
    });
  };

  handleAddingPatientInsurance = async (): Promise<void> => {
    if (this.state.patientInsurance.length === 0) {
      return Notification.notify(ERROR, "There is nothing to save");
    }
    this.setState({
      ...this.state,
      disableSaveBtn: true,
    });
    try {
      await this.state.patientModel!.modifyPatient<Patient, "insurance">({
        insurance: this.parseInsuranceFieldValues(),
      });
      Notification.notify(SUCCESS, "Insurance updated successfully.");
    } catch (error) {
      Notification.notify(ERROR, RCAResponseErrorParser.parse(error).message());
    }
    this.setState({
      ...this.state,
      disableSaveBtn: false,
    });
  };

  async componentDidMount(): Promise<void> {
    if (this.props.patientId) {
      const patientModel = await PatientModel.sync(this.props.patientId);
      const patientInsurance = patientModel.pluck("insurance")
        ? patientModel.pluck("insurance")!.map((insurance: InsuranceForm) => {
            return {
              type: insurance.type,
              company: insurance.company,
              groupNumber:
                insurance.groupNumber === null ? "" : insurance.groupNumber,
              policyNumber: insurance.policyNumber,
              phone: insurance.phone === null ? "" : insurance.phone!,
              effectiveDate: insurance.effectiveDate,
              expirationDate: insurance.expirationDate,
            };
          })
        : [];
      this.setState({
        ...this.state,
        patientModel,
        patientInsurance: patientInsurance as InsuranceForm[],
      });
    }
  }

  render(): ReactNode {
    return (
      <>
        <div id="contacts" className="tab-pane fade in active show">
          <div className="text-right mb-2">
            <button
              className="btn btn-glow-primary add-user btn-primary btn-sm"
              onClick={() =>
                this.handleOpenInsuranceFormModal({ isEditModal: false })
              }
            >
              <span>Add Insurance</span>
            </button>
          </div>
          <div className="mt-1 table-responsive">
            <table className=" table table-striped table-sm table-light table-hover user-management-table table-bordered">
              <thead>
                <tr>
                  <th className="bg-primary text-white small">
                    <span>Type</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Company</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Group Number</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Policy Number</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Phone</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>EFFECTIVE DATE</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>EXPIRATION DATE</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.patientInsurance.map(
                  (insurance: InsuranceForm, id: number) => (
                    <tr key={id}>
                      <td>
                        <a
                          href="#"
                          onClick={() =>
                            this.handleOpenInsuranceFormModal({
                              isEditModal: true,
                              id,
                              selectedInsurance: insurance,
                            })
                          }
                        >
                          {insurance.type}
                        </a>
                      </td>
                      <td>{insurance.company}</td>
                      <td>{insurance.groupNumber}</td>
                      <td>{insurance.policyNumber}</td>
                      <td>
                        {insurance.phone ? formatPhone(insurance.phone) : ""}
                      </td>
                      <td>{insurance.effectiveDate}</td>
                      <td>{insurance.expirationDate}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <button
              onClick={() => {}}
              type="button"
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.state.disableSaveBtn}
              onClick={this.handleAddingPatientInsurance}
            >
              Save
            </button>
          </div>
        </div>
        <Modal
          heading={this.state.insuranceFormModalConfig.title}
          button={this.state.insuranceFormModalConfig.modalButton}
          handleClose={this.handleCloseInsuranceFormModal}
          show={this.state.insuranceFormModalConfig.show}
        >
          {this.state.insuranceFormModalConfig.show && (
            <InsuranceFormComponent
              handleClose={this.handleCloseInsuranceFormModal}
              button={this.state.insuranceFormModalConfig.modalButton}
              onAddPatientInsurance={this.onAddPatientInsurance}
              selectedInsurance={{
                insurance: this.state.selectedInsurance.insurance,
                id: this.state.selectedInsurance.id,
              }}
              onEditInsurance={this.onEditInsurance}
            />
          )}
        </Modal>
      </>
    );
  }
}

export const ManageInsurance = Component;
