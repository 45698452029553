import { FC } from "react";
import cx from "clsx";
import { startCase, camelCase } from "lodash";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  tabs: Array<string>;
  handleTabClick: (tab: string) => void;
  activeTab: string;
};

export const Tabs: FC<Props> = (props: Props) => {
  const { tabs, handleTabClick, activeTab } = props;

  return (
    <>
      <ul className={cx(styles.nav, "font-14", "font-arial")}>
        {tabs &&
          tabs.length &&
          tabs.length > 0 &&
          tabs.map((tab, index) => (
            <li key={index + "tab"} className="flex-fill">
              <button
                data-toggle="tab"
                className={cx(
                  globalStyles.buttonReset,
                  styles.tab,
                  activeTab === tab && styles.active,
                  "w-100 text-center"
                )}
                onClick={() => handleTabClick(tab)}
              >
                <b>{startCase(camelCase(tab))}</b>
              </button>
            </li>
          ))}
      </ul>
    </>
  );
};
