import { Select, TextInput } from "@/components/form";
import { Button } from "rsuite";
import { SupportiveInformation, Practice } from "../../model/types";
import React, { useState } from "react";
import { PracticeModel } from "../../model/index";
import { Notification } from "@/components/notification/notification";
import { SUCCESS, ERROR } from "../../../../library/constants/index";
import { useDispatch, useSelector } from "react-redux";
import { dispatchUpdatePractcie } from "../../redux/actions";
import { echo } from "@/library/echo";
import { selectCurrentUser } from "@/domain/user/redux/selectors";
import { Guard } from "@/library/guard/Guard";
import { UserParentTypes } from "@/domain/user/model";

type Props = {
  supportInformation: SupportiveInformation | undefined;
  practice: Practice;
};

export const PracticeSupportInformation: React.FC<Props> = ({
  supportInformation,
  practice,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [customerNumber, setCustomerNumber] = useState(
    supportInformation?.customerNumber || ""
  );
  const [fulfillment, setFulfillment] = useState(
    supportInformation?.defaultFulfillmentModel || ""
  );
  const [enableUpdateBtn, setEnableUpdateBtn] = useState<boolean>(false);

  const handleUpdateSupportInformation = async () => {
    setEnableUpdateBtn(false);
    try {
      const supportiveInformation = {
        customerNumber: customerNumber === "" ? null : customerNumber,
        defaultFulfillmentModel: (fulfillment === ""
          ? null
          : fulfillment) as any,
      } as SupportiveInformation;
      await PracticeModel.updateSupportInformation(
        practice!.id!,
        supportiveInformation
      );
      //update redux state
      const targetedPractice = { ...practice };
      targetedPractice.supportInformation = supportiveInformation;
      dispatch(dispatchUpdatePractcie(targetedPractice));
      Notification.notify(
        SUCCESS,
        "Supportive Information updated successfully."
      );
    } catch (e) {
      Notification.notify(ERROR, "Something went wrong.");
    }
  };

  const isRootUser = Guard.accessLevel(UserParentTypes.Admin).canActivate(
    currentUser!
  );
  if (!isRootUser) {
    return <></>;
  }

  return (
    <>
      <div className="col-md-4 mb-3">
        <TextInput
          label="Customer Number"
          name="customerNumber"
          type="text"
          placeholder="Customer Number"
          required={false}
          defaultValue={customerNumber}
          onChange={(e: any) => {
            const val = e.target.value || "";
            setCustomerNumber(val);
            setEnableUpdateBtn(true);
          }}
        />
      </div>

      <div className="col-md-4 mb-3">
        <div className="d-flex">
          <label>Fulfillment Model</label>
        </div>
        <select
          className="form-control"
          defaultValue={fulfillment}
          onChange={(e: any) => {
            const val = e.target.value || "";
            setFulfillment(val);
            setEnableUpdateBtn(true);
          }}
        >
          <option value="">None</option>
          <option value="OWNED">Owned</option>
          <option value="LEASED">Leased</option>
        </select>
      </div>

      <div className="col-md-4 mb-3">
        <Button
          appearance="primary"
          disabled={!enableUpdateBtn}
          onClick={handleUpdateSupportInformation}
          size="lg"
          className="mt-4"
        >
          Update Support Information
        </Button>
      </div>
    </>
  );
};
