import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikHelpers, FormikProvider } from "formik";

import { TextInput, Select, TextInputMask } from "@/components/form";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { PracticeForm as PracticeFormValues } from "@/domain/practice/model/types";
import { PracticeId } from "@/domain/practice/redux/types";
import { CountryCodes, usStates } from "@/library/types/address";
import { Organization } from "@/domain/organization/model/types";
import { Invoker } from "@/library/common/invoker/invoker";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ResourceType } from "@/library/core/config/resource";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { Receiver } from "@/library/common/receiver/receiver";
import { RootState } from "@/types";
import { makeValidation } from "./helpers";
import { PracticeSupportInformation } from "./supportInformation";

interface Props {
  handleClose: () => void;
  button: string;
  resourceAction: RCAResourceActions | undefined;
}

export const PracticeForm: React.FC<Props> = (props) => {
  const { handleClose, button, resourceAction } = props;

  const selectedOrganization = useSelector<RootState, Organization | undefined>(
    (state: RootState) => state.organization.selectedOrganization
  );

  const targetPractice = useSelector<RootState, PracticeId>(
    (state) => state.practice.targetPractice
  );
  const practices = useSelector<RootState, Practice[]>(
    (state) => state.practice.practices
  );
  const practice = practices.find(
    (practice: Practice) => practice.id === targetPractice
  ) as Practice;

  const isEditMode = button === "Save Changes";

  const initialValues = useMemo<PracticeFormValues>(() => {
    if (isEditMode && practice) {
      return {
        id: practice.id,
        name: practice.name,
        type: practice.type,
        organization: practice.organizationIds
          ? practice.organizationIds[0]
          : "",
        address: practice.address.street[0] || ("" as string),
        city: practice.address.locality || ("" as string),
        state: practice.address.region || ("" as string),
        zip: practice.address.postalCode || ("" as string),
        imageUrl: "",
        country: practice.address.country || ("" as string),
        contactName: practice.contact?.name || ("" as string),
        contactEmail: practice.contact?.email || ("" as string),
        contactPosition: practice.contact?.position || ("" as string),
        contactPhone: practice.contact?.phone || ("" as string),
      };
    }

    return {
      id: undefined,
      name: "",
      type: "",
      organization: selectedOrganization?.id || "",
      address: "",
      city: "",
      state: "",
      zip: "",
      imageUrl: "",
      country: "",
      contactName: "",
      contactEmail: "",
      contactPosition: "",
      contactPhone: "",
    };
  }, [isEditMode, practice]);

  const handleSubmit = async (
    values: PracticeFormValues,
    form: FormikHelpers<PracticeFormValues>
  ) => {
    const { setSubmitting, resetForm } = form;

    await Invoker.make<Practice>(
      Receiver.make(
        PracticeModel.serialize(values, resourceAction!),
        ResourceType.practice,
        resourceAction!
      ),
      CleanTask.make(
        () => resetForm(initialValues as any),
        () => handleClose()
      ),
      FinallyTask.make(() => setSubmitting(false))
    ).invoke();
  };

  const validationSchema = useMemo(
    () => makeValidation(isEditMode),
    [isEditMode]
  );

  const form = useFormik<PracticeFormValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const handleCancelClick = () => {
    handleClose();
    form.resetForm({ ...initialValues, status: "" });
  };

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <div className="modal-body">
          <div className="form-row d-flex justify-content-center">
            <div className="col-md-4 mb-3">
              <TextInput
                label="Entity/Branch Name"
                name="name"
                type="text"
                placeholder="Entity/Branch Name"
                required
              />
            </div>
            <div className="col-md-3 mb-3">
              <Select label="Type" name="type" required>
                <option value="">Select Entity/Branch Type</option>
                <option value="Health System">Health System</option>
                <option value="Home Health">Home Health</option>
                <option value="Hospice">Hospice</option>
                <option value="Physician Practice">Physician Practice</option>
                <option value="Population Health">Population Health</option>
                <option value="3rd Party Monitoring">
                  3rd Party Monitoring
                </option>
                <option value="Other">Other</option>
              </Select>
            </div>
            <div className="col-md-3 mb-3">
              <Select
                label="Organization"
                name="organization"
                required={false}
                disabled
              >
                <option value={selectedOrganization?.id}>
                  {selectedOrganization?.name}
                </option>
              </Select>
            </div>
            <div className="col-md-10 col-lg-10 mb-3">
              <TextInput
                label="Address"
                name="address"
                type="text"
                placeholder="Full Address Including Apt. #"
                required
              />
            </div>
            <div className="col-md-10 col-lg-10 mb-3">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <TextInput
                    label="City"
                    name="city"
                    type="text"
                    placeholder="City"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <Select label="State" name="state" required>
                    <option value="">Select State</option>
                    {usStates.map((state, key) => (
                      <option key={key} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form-group col-md-2">
                  <TextInput
                    label="Zip Code"
                    name="zip"
                    type="text"
                    placeholder="Zip Code"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <Select label="Country" name="country" required>
                    <option>Select Country</option>
                    {CountryCodes.filter(
                      (country) => country.code === "US"
                    ).map((country, key) => (
                      <option key={key} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-lg-10 mb-3">
              <div className="form-row">
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Name"
                    name="contactName"
                    type="text"
                    placeholder="Contact Name"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Email"
                    name="contactEmail"
                    type="email"
                    placeholder="Contact Email"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInput
                    label="Contact Position"
                    name="contactPosition"
                    type="text"
                    placeholder="Contact Position"
                    required
                  />
                </div>
                <div className="form-group col-md-3">
                  <TextInputMask
                    type="tel"
                    label="Contact Phone"
                    name="contactPhone"
                    mask="+1 (999) 999-9999"
                    value={form.values.contactPhone}
                    onChange={(e: any) => {
                      form.setFieldValue("contactPhone", e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-10">
              <div className="row">
                {isEditMode && (
                  <PracticeSupportInformation
                    practice={practice}
                    supportInformation={practice.supportInformation}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mt-5">
              {isEditMode && (
                <button
                  disabled
                  type="button"
                  className="btn btn-danger sweet-multiple"
                >
                  Disable
                </button>
              )}
            </div>

            <div className="col-6 mt-5 d-flex justify-content-end">
              <button
                onClick={handleCancelClick}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={form.isSubmitting}
                className="btn btn-primary"
              >
                {button}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
