import { JSXElementConstructor, ReactElement } from "react";
import { Loader as Spinner } from "rsuite";
import { renderToString } from "react-dom/server";
import { AxiosResponse } from "axios";

import { defaultConfig, FullScreenLoaderBg, LoadingConfig } from "./loader";

import styles from "./styles.module.scss";

export class LoadingIndicator {
  /**
   * Text visible on loading screen
   */
  private loaderText: string | undefined;
  /**
   * Config to style the loading spinner
   */
  private config: React.CSSProperties = defaultConfig;

  /**
   * Making a new instance of loading indicator
   */
  public static get fire(): LoadingIndicator {
    return new LoadingIndicator();
  }

  constructor(config?: Partial<LoadingConfig>) {
    if (config) {
      this.config = { ...this.config, ...config } as React.CSSProperties;
    }
  }

  /**
   * Making a new instance of loading indicator with custom loader configuration
   * @param config
   * @returns
   */
  static forRoot(config?: Partial<LoadingConfig>): LoadingIndicator {
    return new LoadingIndicator(config);
  }

  /**
   * Rendering loading indicator component to the screen
   */
  show(): void {
    const isLoaderOn = document.getElementById("loadingIndicatorWrapper");
    if (!isLoaderOn) {
      document.getElementById("loadingIndicator")!.innerHTML += renderToString(
        this.render()
      );
    }
  }

  showFullScreen(text?: string): void {
    if (text) this.loaderText = text;
    this.config = {
      ...this.config,
      ...{ background: FullScreenLoaderBg, zIndex: 9999999 },
    };
    const isLoaderOn = document.getElementById("fullScreenWrapper");
    if (!isLoaderOn) {
      document.getElementById("fullScreenLoadingIndicator")!.innerHTML +=
        renderToString(this.renderFullScreen());
    }
  }

  /**
   * Removing the loading indicator elements from the DOM
   * Destroy or hide the loader
   */
  hide(): void {
    document.getElementById("loadingIndicatorWrapper")?.remove();
  }

  hideFullScreen(): void {
    document.getElementById("fullScreenWrapper")?.remove();
  }

  /**
   * Asynchronously calling loading indicator component on the screen.
   * Destroying it when the promise get resolved
   * @param task
   */
  async taskRunner(
    task: () => Promise<AxiosResponse>,
    params: {
      limit?: number;
    }
  ): Promise<AxiosResponse> {
    //Avoiding loading indicator for multiselects
    const multiSelectsLimit = 100;
    if (params && params.limit && params.limit < multiSelectsLimit) {
      this.show();
    }

    const executedTask = await task();
    this.hide();
    return executedTask;
  }

  /**
   * Render method uses react DOM properties to create loading indicator component
   */
  private render(): ReactElement<any, string | JSXElementConstructor<any>> {
    return (
      <div
        id="loadingIndicatorWrapper"
        style={this.config}
        className={styles.loader}
      >
        <Spinner center />
      </div>
    );
  }

  private renderFullScreen(): ReactElement<
    any,
    string | JSXElementConstructor<any>
  > {
    return (
      <div id="fullScreenWrapper" style={this.config} className={styles.loader}>
        <Spinner center />
        <h3 className="text-centered text-white h1">{this.loaderText || ""}</h3>
      </div>
    );
  }
}
