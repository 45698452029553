import React, {
  BaseSyntheticEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from "react";
import cx from "clsx";
import styles from "./styles.module.scss";
import {
  ArrowUp,
  CheckBox,
  CheckBoxNotSelected,
  SearchIcon,
} from "@/assets/icons/dashboard";
import { ArrowDown } from "@/assets/icons/dashboard";

export type MultiSelectDataType = {
  label: string;
  value: string;
  role?: string;
};

interface data {
  options: MultiSelectDataType[];
  selectedOptions: string[];
  onClick: (item: MultiSelectDataType) => void;
  onSelectAll: () => void;
  placeHolder: string;
  onDoneClick: () => void;
}

export const MultiSelectNew = (props: data) => {
  const [showList, setShowList] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [optionKey, setOptionKey] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [optionsFiltered, setOptionsFiltered] = useState<MultiSelectDataType[]>(
    props.options
  );

  useEffect(() => {
    if (props.options.length === props.selectedOptions?.length) {
      setSelectAll(true);
    }
    window.addEventListener("click", (e: any) => {
      if (
        !document.getElementById("multiSelectDropdown")?.contains(e.target) &&
        e.target.parentElement.id !== "multiDrop"
      ) {
        if (!props.selectedOptions?.length) {
          setShowList(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (props.options.length === props.selectedOptions?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [props.selectedOptions]);

  const onOptionClick = (option: MultiSelectDataType) => {
    props.onClick(option);
    setOptionKey(optionKey + 1);
  };

  const onSearchChange = (e: BaseSyntheticEvent) => {
    let searchText = e.target.value;
    let filteredList = props.options;
    if (searchText) {
      filteredList = props.options.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setSearchValue(searchText);
    setOptionsFiltered(filteredList);
  };

  return (
    <div id="multiSelectDropdown" className={cx(styles.mainDropDiv)}>
      <div className={cx(styles.dropDownDiv)}>
        <div
          className={cx(
            props.selectedOptions.length
              ? styles.dropDownLbl
              : styles.dropDownLblOpac
          )}
          onClick={() => {
            setShowList(!showList);
          }}
        >
          {(props.selectedOptions.length &&
            `${props.selectedOptions.length} selected`) ||
            props.placeHolder}
        </div>
        <div
          onClick={() => {
            setShowList(!showList);
          }}
        >
          <img
            className={styles.arrowImg}
            src={showList ? ArrowUp : ArrowDown}
            alt={"drop"}
          />
        </div>
      </div>
      {showList && (
        <div className={cx(styles.multiDropS)}>
          {showSearch && (
            <div className={cx(styles.searchDiv)}>
              <input
                value={searchValue}
                onChange={(e) => onSearchChange(e)}
                placeholder="Search"
              />
            </div>
          )}
          <ul
            className={cx(
              showSearch ? styles.optionsList : styles.optionsListSearchDisabled
            )}
          >
            <div key={optionKey}>
              {optionsFiltered.map(
                (item: MultiSelectDataType, index: number) => (
                  <div
                    key={index}
                    className={cx(styles.optionMenu)}
                    onClick={() => onOptionClick(item)}
                    id="multiDrop"
                  >
                    {props.selectedOptions.includes(item.value) ? (
                      <img
                        className={cx(styles.checkIcon)}
                        src={CheckBox}
                        alt={"check"}
                      />
                    ) : (
                      <img
                        className={cx(styles.unCheckIcon)}
                        src={CheckBoxNotSelected}
                        alt={"unCheck"}
                      />
                    )}
                    <div className={cx(styles.dropDownLabels)}>
                      {item.label}
                    </div>
                  </div>
                )
              )}
            </div>
          </ul>
          <div
            className={cx(
              props.selectedOptions.length
                ? styles.actionDiv
                : styles.actionDivDisabled
            )}
            onClick={
              props.selectedOptions.length
                ? () => {
                    setShowList(false);
                    props.onDoneClick();
                  }
                : () => {}
            }
          >
            <div
              className={cx(
                props.selectedOptions.length
                  ? styles.doneActionButton
                  : styles.doneActionButtonDisabled
              )}
            >{`Done`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
