import { EventEmitter } from "@/library/core/event";
import { RemoteCareAPISync } from "@/library/core/api";
import { Attributes, Model, ModelCollection } from "@/library/model";
import { Note } from "./types";
import { PatientModel } from "../../patient/model";
import { MODEL_SYNCED } from "@/library/constants";
import { NoteDeserializer } from "@/library/deserializers/note/note.deserializer";
import { PaginationConfig } from "@/library/types";
import { Http } from "@/library/core/api/http";

export class NoteModel extends Model<Note> {
  static readonly path = "notes";
  /**
   * Makes a new instance of NoteModel. It requires only the attributes
   * that would be used to instantiate new Note Model.
   *
   * @param attributes
   */
  static make(attributes: Note, withoutPatientId?: boolean): NoteModel {
    const noteModel = new NoteModel(
      new Attributes<Note>({ ...attributes }),
      new RemoteCareAPISync<Note>(
        NoteModel.path,
        NoteModel.buildUrl(attributes.patientId!)
      ),
      new EventEmitter()
    );
    if (!!withoutPatientId) {
      noteModel.on(MODEL_SYNCED, () => {});
      noteModel.set({ ...noteModel.pluckAll(), patientId: undefined });
    }

    return noteModel;
  }

  /**
   * Makes a list of notes using the provided resource url and a deserializer.
   * The entire note list can be accessed in the models member of the Collection class.
   */
  static makeNoteCollection(
    patientId: string
  ): ModelCollection<NoteModel, Note> {
    return new ModelCollection<NoteModel, Note>(
      NoteModel.buildUrl(patientId),
      (entity: Note) => this.deserialize(entity)
    );
  }

  static buildUrl = (patientId: string): string =>
    `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/${NoteModel.path}`;

  /**
   * Sync Notes by patient Id.
   * @param patientId
   */
  static syncByPatientId = (
    patientId: string
  ): ModelCollection<NoteModel, Note> =>
    NoteModel.makeNoteCollection(patientId);

  /**
   * Gets all notes belonging to a Parent Note.
   *
   * @param noteId
   * @param patientId
   */
  static syncByReplyTo = async (
    noteId: string,
    patientId: string
  ): Promise<Note[]> => {
    const noteModelCollection: ModelCollection<NoteModel, Note> =
      NoteModel.makeNoteCollection(patientId);
    noteModelCollection.on(MODEL_SYNCED, () => {});

    noteModelCollection.withParams({ replyToIds: [noteId] });

    const iterator = await noteModelCollection.getMany();
    const childrenNoteModels = await iterator.getAll();

    return childrenNoteModels.map((noteModel) => noteModel.pluckAll());
  };

  get isParent() {
    return !this.isChild;
  }

  get isChild() {
    return !!this.pluck("replyToIds")?.length;
  }

  clone = (): Note => {
    return { ...this.pluckAll() };
  };

  static deserialize = (data: Note) =>
    NoteDeserializer.for(data, NoteModel.make).deserialize();

  static async getNotes(
    patientIds: string[],
    startDate: string,
    endDate: string,
    types?: string[],
    params = {}
  ): Promise<[Note[], PaginationConfig]> {
    const response = await Http().get(
      `${RemoteCareAPISync.host}/${NoteModel.path}`,
      {
        params: {
          patientIds,
          startDate,
          endDate,
          types,
          ...params,
        },
      }
    );
    const totalResources = parseInt(response.headers["total-resources"]);
    const currentPage = parseInt(response.headers["current-page"]);
    const lastPage = parseInt(response.headers["last-page"]);

    const paginationConfig = {
      totalResources,
      currentPage,
      lastPage,
    };

    return [response.data, paginationConfig];
  }
}
