import * as React from "react";
import styles from "./styles.module.scss";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/material";
import {
  Dashboard,
  Kits,
  Patients,
  Reports,
  Users,
  DashboardSelected,
  KitsSelected,
  PatientsSelected,
  ReportsSelected,
  UsersSelected,
  NavigationLogo,
  Organization,
  OrganizationSelected,
  Logout,
} from "@/assets/icons/dashboard";
import cx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "@/library/constants";
import { RootState } from "@/types";
import { UserParentTypes } from "@/domain/user/model";
import { Guard } from "@/library/guard/Guard";
import { Link } from "react-router-dom";
import RouteChangeDetector, {
  HasPathname,
} from "./routeChangeDetector.component";
import Avatar from "@mui/material/Avatar";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 109,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideMenu() {
  const [activeLink, setActiveLink] = React.useState("");
  const [isProfileHover, setProfileHover] = React.useState(false);

  const dispatch = useDispatch();

  let selectedOrganization = useSelector((state: RootState) => {
    return state.organization?.selectedOrganization;
  });
  let currentUser = useSelector((state: RootState) => {
    return state.user?.currentUser;
  });

  const SideMenuOptions = [
    {
      label: "Patient",
      url: `patients`,
      path: `patients?${
        selectedOrganization
          ? `organizationId=${selectedOrganization?._meta?.id}`
          : ""
      }`,
      ic: Patients,
      icSelected: PatientsSelected,
    },
    {
      label: "Dashboard",
      path: "user-dashboard",
      ic: Dashboard,
      icSelected: DashboardSelected,
    },
    {
      label: "Reports",
      path: "reports",
      ic: Reports,
      icSelected: ReportsSelected,
    },
    {
      label: "Kits",
      path: "kits",
      ic: Kits,
      icSelected: KitsSelected,
    },
    // {
    //   label: "Practices",
    //   path: "/practices",
    //   ic: Practices,
    //   icSelected: Practices,
    // },
    {
      label: "Users",
      path: "user-management",
      ic: Users,
      icSelected: UsersSelected,
    },
    Guard.accessLevel(UserParentTypes.Admin).canActivate(currentUser!) && {
      label: "Organizations",
      path: "organizations",
      ic: Organization,
      icSelected: OrganizationSelected,
    },
  ];

  let selectedIndex = 0;

  const handleLogout = () => {
    dispatch({ type: LOGOUT, payload: {} });
    setTimeout(() => window.location.assign(`${_env_.AUTH_URL}/logout`), 10);
  };

  React.useEffect(() => {
    getActiveLink();
  }, []);

  const getActiveLink = () => {
    const activeRoute = window.location.pathname.split("/");
    if (activeRoute[1] === "organizations" && activeRoute.length > 2) {
      return "practices";
    }

    /* Check if the pathname is having sub-routes and patient in it to show patient tab active */
    if (activeRoute[1] === "patient" && activeRoute.length > 2) {
      return "patient";
    }

    setActiveLink(window.location.pathname.split("/").join(""));
    return window.location.pathname.split("/").join("");
  };

  const stringAvatar = (name: string) => {
    let selected = activeLink === "profile";
    return {
      sx: {
        bgcolor: selected || isProfileHover ? `#030575` : `#383A9B`,
        width: `2.375rem`,
        height: `2.375rem`,
        marginLeft: `0.4375rem`,
        fontFamily: `Montserrat`,
        fontSize: `0.75rem`,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  let userName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  return (
    <Box>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={false}
        sx={{
          "& .MuiPaper-root": {
            zIndex: 0,
          },
        }}
        PaperProps={{
          sx: {
            background: "#030575",
          },
        }}
      >
        <DrawerHeader className={cx(styles.sideMenuHeader)}>
          <img src={NavigationLogo} alt="Logo" />
        </DrawerHeader>
        <RouteChangeDetector
          getCurrentRoute={(currentRoute: HasPathname) => {
            const activeRoute = currentRoute?.pathname?.split("/") || [];
            let route =
              activeRoute[1] === `patient` ? `patients` : activeRoute[1];
            const routeName = route || getActiveLink();
            setActiveLink(routeName);
          }}
        />
        <List className={cx(styles.sideMenu)}>
          {SideMenuOptions.map((menu: any, index: any) => (
            <Link to={`/${menu.path}`} key={menu.label}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "center",
                  px: 2.5,
                }}
                className={styles.listItemIcon}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={
                      (activeLink === "" && menu.url === "patients") ||
                      activeLink === menu.path ||
                      activeLink === menu.url
                        ? cx(styles.sideMenuItemSelected)
                        : cx(styles.sideMenuItem)
                    }
                  >
                    <img
                      className={cx(styles.sideMenuImg)}
                      src={
                        (activeLink === "" && menu.url === "patients") ||
                        activeLink === menu.path ||
                        activeLink === menu.url
                          ? menu.icSelected
                          : menu.ic
                      }
                      alt={menu.label}
                    />
                    <div className={cx(styles.sideMenuTxt)}>{menu.label}</div>
                  </div>
                </ListItemIcon>
              </ListItemButton>
            </Link>
          ))}
          <div className={cx(styles.divider)}>
            <div className={cx(styles.dividerRoute)}></div>
          </div>
          <Link to="/profile">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              className={styles.listItemIcon}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                  marginBottom: `0.625rem`,
                }}
              >
                <div
                  className={cx(
                    activeLink === "profile"
                      ? styles.sideMenuItemSelectedProfile
                      : styles.sideMenuItemProfile
                  )}
                  onMouseEnter={() => setProfileHover(true)}
                  onMouseLeave={() => setProfileHover(false)}
                >
                  <Avatar {...stringAvatar(userName)} />
                  <div
                    className={cx(styles.sideMenuTxtProfile)}
                  >{`My Profile`}</div>
                </div>
              </ListItemIcon>
            </ListItemButton>
          </Link>
          <Link to="/" onClick={() => handleLogout()}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              className={styles.listItemIcon}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <div className={cx(styles.sideMenuItem)}>
                  <img
                    className={cx(styles.sideMenuImg)}
                    src={Logout}
                    alt={"logout"}
                  />
                  <div
                    className={cx(styles.sideMenuTxtSignOut)}
                  >{`Sign Out`}</div>
                </div>
              </ListItemIcon>
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
    </Box>
  );
}
