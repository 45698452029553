import { Tag } from "@/domain/tags/model/types";
import * as C from "./constants";

export const dispatchSetLocationTags = (locationTags: Tag[]) => ({
  type: C.SET_LOCATION_TAGS,
  payload: { locationTags },
});

export const dispatchSaveLocationTag = (tag: Tag) => ({
  type: C.SAVE_LOCATION_TAG,
  payload: { tag },
});

export const dispatchDeleteLocationTag = (tagId: string) => ({
  type: C.DELETE_LOCATION_TAG,
  payload: { tagId },
});
