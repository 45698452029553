import { PracticeState, PracticeId, PracticeSortedState } from "./types";
import {
  CREATE_PRACTICE,
  DESELECT_ALL_PRACTICES,
  DESELECT_PRACTICE,
  SELECT_ALL_PRACTICES,
  SELECT_PRACTICE,
  SET_PRACTICES,
  SET_PRACTICES_PAGINATIONCONFIG,
  SET_PRACTICE_PAGE_LIMIT,
  SET_TARGET_PRACTICE,
  SET_PRACTICE_FILTERS,
  SET_PRACTICE_SORTED_STATE,
  SELECT_ORGANIZATION,
  SET_ACTIVE_PRACTICE,
  UNSET_ACTIVE_PRACTICE,
} from "@/library/constants";
import { PageLimit, PaginationConfig } from "@/library/types";
import { Practice, PracticeFilters } from "../model/types";
import { UPDATE_PRACTICE } from "@/library/constants";
import { AnyAction } from "redux";

export const INITIAL_STATE = {
  practices: [],
  selectedPractices: [],
  targetPractice: "",
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  practicePageLimit: 10,
  practiceFilters: {
    type: undefined,
    isEnabled: undefined,
  },
  selectedPractice: undefined,
  sortedState: {
    direction: undefined,
    sortBy: undefined,
  },
};

export const practiceReducer = (
  state: PracticeState = INITIAL_STATE,
  action: AnyAction
): PracticeState => {
  switch (action.type) {
    case CREATE_PRACTICE:
      return {
        ...state,
        practices: [action.payload, ...state.practices] as Practice[],
      };
    case SET_PRACTICES:
      return {
        ...state,
        practices: action.payload as Practice[],
      };
    case SET_TARGET_PRACTICE:
      return {
        ...state,
        targetPractice: action.payload as unknown as PracticeId,
      };
    case SET_ACTIVE_PRACTICE:
      return {
        ...state,
        selectedPractice: action.payload,
      };
    case UNSET_ACTIVE_PRACTICE:
      return {
        ...state,
        selectedPractice: undefined,
      };
    case SET_PRACTICES_PAGINATIONCONFIG:
      return {
        ...state,
        paginationConfig: action.payload as unknown as PaginationConfig,
      };

    case SET_PRACTICE_PAGE_LIMIT:
      return {
        ...state,
        practicePageLimit: action.payload as PageLimit,
      };
    case SELECT_PRACTICE:
      return {
        ...state,
        selectedPractices: [
          ...state.selectedPractices,
          action.payload,
        ] as Practice[],
      };
    case DESELECT_PRACTICE:
      return {
        ...state,
        selectedPractices: state.selectedPractices.filter(
          (practice: Practice) =>
            practice.id !== (action.payload as unknown as Practice).id
        ),
      };

    case SELECT_ALL_PRACTICES:
      return {
        ...state,
        selectedPractices: state.practices,
      };
    case DESELECT_ALL_PRACTICES:
      return {
        ...state,
        selectedPractices: [],
      };

    case SET_PRACTICE_FILTERS:
      return {
        ...state,
        practiceFilters: action.payload as PracticeFilters,
      };
    case SET_PRACTICE_SORTED_STATE:
      return {
        ...state,
        sortedState: action.payload as PracticeSortedState,
      };
    case UPDATE_PRACTICE:
      state.practices[
        state.practices.findIndex(
          (practice) => practice.id === action.payload.id
        )
      ] = action.payload;
      return state;

    // Side effects
    case SELECT_ORGANIZATION:
      return {
        ...state,
        practices: [],
        selectedPractices: [],
      };

    default:
      return state;
  }
};
