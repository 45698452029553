import {
  HeaderContent,
  SortBy,
  SortedState,
} from "@/components/table/head/sortable";

export const organizationType = [
  { label: "All", value: "" },
  { label: "Health System", value: "Health System" },
  { label: "Home Health", value: "Home Health" },
  { label: "Hospice", value: "Hospice" },
  { label: "Physician Practice", value: "Physician Practice" },
  { label: "Population Health", value: "Population Health" },
  { label: "3rd Party Monitoring", value: "3rd Party Monitoring" },
  { label: "Other", value: "Other" },
];

export const kitFulfillment = [
  { label: "All", value: "" },
  { label: "Anelto", value: "Anelto" },
  { label: "Self", value: "Self" },
  { label: "No Kit", value: "null" },
];

export const status = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const OrganizationTableHeader: HeaderContent[] = [
  {
    name: "name",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "type",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "address",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Street,
  },
  {
    name: "city",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Locality,
  },
  {
    name: "state",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Region,
  },
  {
    name: "phone",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.Phone,
  },
  {
    name: "fulfillment",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.KitFulfillment,
  },
  {
    name: "actions",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
];
