import React, { MouseEvent } from "react";
import { useParams } from "react-router-dom";
import cx from "clsx";

import { Tooltip } from "@/components/Tooltip";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { dateFormatter } from "@/pipes/date";
import { AgePipe } from "@/pipes/age";
import { Notification } from "@/components/notification/notification";
import { PatientReviewedCheckbox } from "@/domain/patient/view/PatientReviewedCheckbox";
import { SUCCESS } from "@/library/constants";
import {
  getPrimaryConditionName,
  getPatientServiceLevel,
  getRecentStatus,
  getRecentStatusDate,
  getEncounterDays,
} from "./helpers";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  patient: Patient;
  includeTimer?: boolean;
  commentFieldSize?: number;
  timer: React.ReactNode;
};

const DEFAULT_COMMENT_FIELD_SIZE = 5;

export const PatientBannerDetails: React.FC<Props> = (props) => {
  const {
    includeTimer,
    timer,
    patient,
    commentFieldSize = DEFAULT_COMMENT_FIELD_SIZE,
  } = props;

  const { provider, demographics } = patient || {};
  const { legalName, dateOfBirth, preferredName } = demographics || {};
  const { firstName, lastName } = legalName || {};

  const queryParams: { patientId: string } = useParams();
  const tooltipPlacement = queryParams.patientId ? "bottom" : "top";

  const patientModel = PatientModel.make(patient);
  const devices = patientModel.pluck("deviceInfo");

  const handlePreventClick = (event: MouseEvent) => {
    event.stopPropagation();
  };
  const handleCommentKeyUp = (event: KeyboardEvent) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
  };

  const handleCommentChange = async (commentInput: string) => {
    const comment = commentInput === "" ? null : commentInput;
    try {
      if (props.patient.comments !== comment) {
        await PatientModel.make(props.patient).modifyPatient<
          Patient,
          "comments"
        >({
          comments: comment,
        });
      }
      Notification.notify(SUCCESS, "Comment saved successfully");
    } catch (e) {}
  };

  return (
    <div
      className={cx(
        styles.patientBannerDetails,
        includeTimer && styles.hasTimer
      )}
    >
      <div className={styles.left}>
        <div className={cx(styles.detailRow)} style={{ marginTop: 0 }}>
          <div className={cx(globalStyles.truncate, styles.patientNameCol)}>
            <Tooltip
              placement={tooltipPlacement}
              style={{ maxWidth: "100%" }}
              tooltipContent={
                <>
                  {lastName}, {firstName}{" "}
                  {preferredName && <>({preferredName})</>}
                </>
              }
            >
              <h6 className={cx(styles.patientName, globalStyles.truncate)}>
                {lastName}, {firstName}{" "}
                {preferredName && <>({preferredName})</>}
              </h6>
            </Tooltip>
          </div>
        </div>
        <div className={cx(styles.detailRow)}>
          <div className={styles.column1}>
            <p className={styles.bold}>
              {" "}
              {dateOfBirth
                ? dateFormatter({ date: dateOfBirth, isDateOfBirth: true })
                : "-"}
              {dateOfBirth && (
                <>
                  {" "}
                  (Age <AgePipe dob={dateOfBirth} />)
                </>
              )}
            </p>
          </div>
          <div className={cx(globalStyles.truncate, styles.column2)}>
            <Tooltip
              placement={tooltipPlacement}
              tooltipContent={
                <>{getPrimaryConditionName(patient?.conditions)}</>
              }
            >
              <p className={cx(globalStyles.truncate, styles.bold)}>
                {getPrimaryConditionName(patient?.conditions)}
              </p>
            </Tooltip>
          </div>
        </div>
        <div className={cx(styles.detailRow)}>
          <div className={styles.column1}>
            <p>
              ID:{" "}
              <span className={styles.bold}>
                {patient?.encounter?.id || "-"}
              </span>
            </p>
          </div>
          <div className={cx(styles.column2)}>
            <p className={globalStyles.truncate}>
              <Tooltip
                placement={tooltipPlacement}
                tooltipContent={
                  <>
                    {provider?.firstName} {provider?.lastName}
                    {provider && ","} {provider?.credentials}
                  </>
                }
              >
                <span className={styles.bold}>
                  {provider?.firstName} {provider?.lastName}
                  {provider && ","} {provider?.credentials}
                </span>
              </Tooltip>
            </p>
          </div>
        </div>
        <div className={cx(styles.detailRow)}>
          <div className={styles.column1}>
            <p>{getPatientServiceLevel(devices)}</p>
          </div>
          <div className={cx(styles.column2)}>
            {" "}
            <p>
              {provider?.phoneNumber ? (
                <span className={styles.bold}>{provider.phoneNumber}</span>
              ) : (
                "No phone listed"
              )}
            </p>
          </div>
        </div>
        <div className={cx(styles.detailRow)}>
          <div className={cx(styles.column2)}>
            <PatientReviewedCheckbox patient={patient} />
          </div>
          <div className={styles.column1}>
            <span className={styles.bannerStatus}>
              {getRecentStatus(patient?.lifecycle)}:{" "}
            </span>
            <span className={styles.bannerEncounter}>
              {" "}
              {getRecentStatusDate(patient?.lifecycle)} /
              {getEncounterDays(patient?.lifecycle)}{" "}
            </span>
          </div>
        </div>
      </div>

      <div className={cx("px-0", styles.commentWrapper)}>
        <div className="px-2">
          <textarea
            onBlur={(e) => handleCommentChange(e.target.value || "")}
            onKeyUp={handleCommentKeyUp as any}
            className="form-control"
            rows={commentFieldSize}
            style={{
              borderRadius: "20px",
            }}
            defaultValue={patientModel.pluck("comments") || ""}
            onClick={handlePreventClick}
            placeholder={"Comment..."}
          />
        </div>
        {includeTimer && <div>{timer}</div>}
      </div>
    </div>
  );
};
