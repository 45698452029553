import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Note } from "@/domain/notes/model/types";
import { dispatchSetMonitoringDates } from "@/domain/patient/redux/actions";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { MonitoringTab } from "../monitoring";
import { BiometricType, graphHeadings, SummaryNoteOptions } from "./constants";
import NoteTableDoc from "./docs/NoteTableDoc";
import PatientDetailDoc from "./docs/PatientDetailDoc";
import { fetchPatientNotes, generateGraphImages } from "./helper";
import { SummaryTable } from "@/domain/patient/view/tables/SummaryTable";
import { selectAggregateTableData } from "@/domain/patient/view/tables/AggregateTable/selectors";
import { AggregateData } from "@/domain/patient/view/tables/AggregateTable/types";
import { ObservationType } from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";
import { PatientNoteType } from "@/library/types/note";

let chartTableVerticalPos = 0;
let graphsCoveredNextPage = false;

type Props = {
  startDate: Date;
  endDate: Date;
  includeCharts: boolean;
  includeTables: boolean;
  selectedNoteOption: SummaryNoteOptions;
  providerNote: string;
  biometricOptionsToInclude: BiometricType[];
};

const CreateSummary: React.FC<Props> = (props) => {
  const {
    selectedNoteOption,
    startDate,
    endDate,
    includeCharts,
    includeTables,
    biometricOptionsToInclude,
    ...restProps
  } = props;
  const includeNotes = selectedNoteOption !== SummaryNoteOptions.NoCharts;
  const [showGraphs, setShowGraphs] = useState<boolean>(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const patient = useSelector(selectFirstPatient);
  const aggregateData = useSelector(selectAggregateTableData);
  const [filteredAggregateData, setFilteredAggregateData] = useState<
    AggregateData[]
  >([]);
  const dispatch = useDispatch();

  const showLoader = () =>
    LoadingIndicator.fire.showFullScreen("Generating PDF please wait...");

  const hideLoader = () => {
    changeDisplayOfHighchartExportBtn("block");
    setShowGraphs(false);
    LoadingIndicator.fire.hideFullScreen();
  };

  const changeDisplayOfHighchartExportBtn = (display: "none" | "block") => {
    const highChartExportBtn = document.getElementsByClassName(
      "highcharts-exporting-group"
    );
    if (highChartExportBtn) {
      for (let i = 0, len = highChartExportBtn.length; i < len; i++) {
        const element: any = highChartExportBtn[i];
        if (element) {
          element.style.display = display;
        }
      }
    }
  };

  const handleExport = async () => {
    showLoader();
    setShowGraphs(true);
    // set observation graphs data
    const start = DateTime.fromJSDate(startDate).toISODate();
    const end = DateTime.fromJSDate(endDate).toISODate();
    dispatch(dispatchSetMonitoringDates(start, end));
    setTimeout(async () => {
      changeDisplayOfHighchartExportBtn("none");
      await processPdf();
    }, 2000);
  };

  const processPdf = async () => {
    const doc = new jsPDF({
      filters: ["ASCIIHexEncode"],
      orientation: "p",
      // orientation: "landscape",
      unit: "pt",
      format: "a4",
    });

    let chartsImageUrls: string[] = [];
    let chartHeadings: string[] = [];
    if (includeCharts) {
      [chartHeadings, chartsImageUrls] = (await generateGraphImages(
        biometricOptionsToInclude
      )) as [string[], string[]];
    }
    if (includeNotes) {
      await fetchNotes();
    }
    let lastYPos = 0;
    autoTable(doc, {
      html: "#patientDoc",
      useCss: true,
      theme: "plain",
      includeHiddenHtml: false,
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: 150 },
        2: { cellWidth: "auto" },
      },
      didDrawPage: (data) => {
        if (includeCharts) {
          lastYPos = attachGraphImages(
            doc,
            chartsImageUrls,
            chartHeadings,
            data.cursor!.y
          );
        }
        if (includeTables) {
          doc.addPage();
          attachBiometricTable(doc);
        }
        if (includeNotes) {
          doc.addPage();
          attachNoteTable(doc);
        }
        generatePdf(doc);
      },
    });
  };

  const attachGraphImages = (
    doc: jsPDF,
    chartImages: string[],
    chartHeadings: string[],
    verticalPos: number
  ) => {
    let currentHorizontalPos = 0;
    let currentVerticalPos = verticalPos + 10;
    const pageHeight = doc.internal.pageSize.getHeight();
    const imageWidth = doc.internal.pageSize.getWidth() / 2 - 10;
    const imageHeight = pageHeight / 7;
    const verticalMarginBottom = 150;
    let nextTableStartY = 0;
    doc.setFontSize(10);
    for (let x = 0; x < chartImages.length; x++) {
      nextTableStartY = verticalMarginBottom + currentVerticalPos;
      doc.text(
        biometricOptionsToInclude.length ? chartHeadings[x] : graphHeadings[x],
        currentHorizontalPos + 10,
        currentVerticalPos - 10
      );
      doc.addImage(
        chartImages[x],
        "PNG",
        currentHorizontalPos,
        currentVerticalPos,
        imageWidth,
        imageHeight
      );

      if (currentHorizontalPos === 0) {
        currentHorizontalPos = doc.internal.pageSize.getWidth() / 2;
      } else {
        currentHorizontalPos = 0;
        currentVerticalPos += verticalMarginBottom;
        if (graphsCoveredNextPage) {
          chartTableVerticalPos = currentVerticalPos + imageHeight;
        }
      }
    }
    return nextTableStartY;
  };

  const attachNoteTable = (doc: jsPDF) => {
    autoTable(doc, {
      html: "#chart_notes",
      useCss: true,
      theme: "plain",
      includeHiddenHtml: false,
      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: "auto" },
        2: { cellWidth: 150 },
      },
    });
  };

  const attachBiometricTable = (doc: jsPDF) => {
    doc.setFont("Helvetica", "", "bold").setFontSize(10);
    doc.text("Biometric Table:", 50, 30, {});

    autoTable(doc, {
      html: "#aggregateBiometricTable",
      useCss: true,
      theme: "plain",
      includeHiddenHtml: false,
      // split overflowing columns into pages
      horizontalPageBreak: true,
      // repeat this column in split pages
      horizontalPageBreakRepeat: 1,
    });
  };

  const fetchNotes = async () => {
    let startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);
    if (selectedNoteOption === SummaryNoteOptions.Last2Days) {
      startDateTime = DateTime.fromJSDate(endDate).minus({ day: 2 });
    }

    const notes = await fetchPatientNotes(
      patient!.id!,
      startDateTime.toUTC().toString(),
      endDateTime.toUTC().toString()
    );
    setNotes(notes);
  };

  const generatePageNumbers = (doc: jsPDF) => {
    const pages = doc.internal.pages.length - 1;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    doc.setFontSize(10);

    for (let pageNumber = 1; pageNumber < pages + 1; pageNumber++) {
      const horizontalPos = pageWidth - 80;
      const verticalPos = pageHeight - 10;
      doc.setPage(pageNumber);
      doc.text(
        `Page ${pageNumber} of ${pages}`,
        horizontalPos,
        verticalPos,
        {}
      );
    }
  };

  const generatePdf = (doc: jsPDF) => {
    generatePageNumbers(doc);
    doc.setPage(doc.internal.pages.length + 1);
    window.open(doc.output("bloburl"), "_blank");
    hideLoader();
  };

  useEffect(() => {
    let tableData: AggregateData[] = [];
    if (biometricOptionsToInclude.includes(BiometricType.HeartRate)) {
      aggregateData.filter((d) => {
        if (d.hasOwnProperty("type") && d.type === ObservationType.HeartRate) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.BloodPressure)) {
      aggregateData.filter((d) => {
        if (
          d.hasOwnProperty("type") &&
          d.type === ObservationType.BloodPressure
        ) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.PulseOximetry)) {
      aggregateData.filter((d) => {
        if (
          d.hasOwnProperty("type") &&
          d.type === ObservationType.PulseOximetry
        ) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Weight)) {
      aggregateData.filter((d) => {
        if (d.hasOwnProperty("type") && d.type === ObservationType.Weight) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.GlucoseLevel)) {
      aggregateData.filter((d) => {
        if (
          d.hasOwnProperty("type") &&
          d.type === ObservationType.GlucoseLevel
        ) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Temperature)) {
      aggregateData.filter((d) => {
        if (
          d.hasOwnProperty("type") &&
          d.type === ObservationType.Temperature
        ) {
          tableData.push(d);
        }
        return tableData;
      });
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Spirometry)) {
      aggregateData.filter((d) => {
        if (d.hasOwnProperty("type") && d.type === ObservationType.Spirometry) {
          tableData.push(d);
        }
        return tableData;
      });
    }

    let newTableData: { [parentKey: string]: { [childKey: string]: any } } = {};
    const newSubRowObject: any = {};
    tableData.forEach((readingsObject) => {
      Object.keys(readingsObject).forEach((key: string) => {
        if (key !== "subRows" && key !== "title" && key !== "type") {
          const title = readingsObject["title"] as string;
          if (!(key in newTableData)) {
            newTableData[key] = {};
          }
          newTableData[key]["title"] = `${key}`;
          if (newTableData[key][title]) {
            const subRowsData = newTableData[key][title]["subRows"];
            newTableData[key][title] = readingsObject[key];
            newTableData[key][title]["subRows"] = subRowsData;
          } else {
            newTableData[key][title] = readingsObject[key];
          }
        }

        // Prepare subrows
        if (key === "subRows") {
          const subRows: any = readingsObject["subRows"];
          subRows.forEach((subRowObject: any) => {
            Object.keys(subRowObject).forEach((dateString: string) => {
              if (dateString !== "type") {
                const title = readingsObject["title"] as string;
                if (!(dateString in newTableData)) {
                  newTableData[dateString] = {};
                }
                if (!(title in newTableData[dateString])) {
                  newTableData[dateString][title] = {};
                }
                if (!(dateString in newSubRowObject)) {
                  newSubRowObject[dateString] = {};
                }
                if (!(title in newSubRowObject[dateString])) {
                  newSubRowObject[dateString][title] = [];
                }
                newSubRowObject[dateString][title].push(
                  subRowObject[dateString]
                );
              }
            });
          });
        }
      });
    });

    // Add subrows in new table data in required structure
    Object.keys(newTableData).forEach((dateString) => {
      if (dateString in newSubRowObject) {
        let maxLength = 0;
        const values: any = Object.values(newSubRowObject[dateString]);
        const keys = Object.keys(newSubRowObject[dateString]);
        values.forEach((valueArray: any) => {
          if (valueArray && valueArray.length > maxLength) {
            maxLength = valueArray.length;
          }
        });
        for (let i = 0; i < maxLength; i++) {
          const newObject: any = {};
          for (let j = 0; j < values.length; j++) {
            newObject[keys[j]] = values[j][i];
          }
          if (
            dateString in newTableData &&
            !("subRows" in newTableData[dateString])
          ) {
            newTableData[dateString].subRows = [];
          }
          newTableData[dateString].subRows.push(newObject);
        }
      }
    });

    const sortedObject = Object.keys(newTableData)
      .sort()
      .reduce(function (result: any, key) {
        result[key] = newTableData[key];
        return result;
      }, {});

    setFilteredAggregateData(Object.values(sortedObject));
  }, [aggregateData, biometricOptionsToInclude]);

  return (
    <>
      <div className={showGraphs ? "" : "d-none"}>
        <MonitoringTab onlyCharts />
      </div>
      <div className="d-none">
        <PatientDetailDoc
          dateRanges={{ start: startDate, end: endDate }}
          patient={patient!}
          pageWidth={1000}
          providerNote={restProps.providerNote}
        />
        <SummaryTable
          data={filteredAggregateData as any}
          biometricOptionsToInclude={[
            displayObsUnit(ObservationType.HeartRate),
            displayObsUnit(ObservationType.BloodPressure),
            displayObsUnit(ObservationType.PulseOximetry),
            displayObsUnit(ObservationType.Weight),
            displayObsUnit(ObservationType.GlucoseLevel),
            displayObsUnit(ObservationType.Temperature),
            (
              displayObsUnit(ObservationType.Spirometry) as {
                pef: string;
                fev1: string;
              }
            ).pef,
            (
              displayObsUnit(ObservationType.Spirometry) as {
                pef: string;
                fev1: string;
              }
            ).fev1,
          ]}
          autoExpand={true}
        />
        <NoteTableDoc notes={notes} />
      </div>
      <button className="btn btn-primary" onClick={handleExport}>
        Create
      </button>
    </>
  );
};

export default CreateSummary;
