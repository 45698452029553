import React, { useEffect, useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";
import LineChart from "@/components/charts/HighChart.component";
import {
  biometryEntryMethod,
  parseTooltipDate,
} from "@/domain/patient/view/tabsNew/monitoring/helpers";
import {
  ObservationUnits,
  PressureUnits,
} from "@/domain/patient/model/constants";
import { chartConstants, COLORS } from "@/domain/patient/view/charts/constants";
import {
  getAlertLevelColorBG,
  getSeverityAlertLevelNew,
} from "@/domain/notes/model/constants";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { sortObservationArrayByDate } from "../helper";
import { NoDataAvailable } from "../../tabsNew";
import { ChartProps } from "../types";
import { TIMEOUT_DATE_CHANGE } from "../../tabsNew/monitoring/constants";
import { makeChartOptionsNew } from "../constants";

export const BloodPressureChart: React.FC<ChartProps> = (props) => {
  const { observationData, patientDetail } = props;
  const bloodPressures = observationData;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);
  const [allowChartUpdate, setAllowChartUpdate] = useState(false);

  let systolicAverage = 0;
  let diastolicAverage = 0;
  let systolicAggregate = 0;
  let diastolicAggregate = 0;

  bloodPressures.map((bloodPressure) => {
    systolicAggregate += bloodPressure.systolic?.value ?? 0;
    diastolicAggregate += bloodPressure.diastolic?.value ?? 0;
  });
  systolicAverage = systolicAggregate / bloodPressures.length;
  diastolicAverage = diastolicAggregate / bloodPressures.length;
  sortObservationArrayByDate(bloodPressures);

  useEffect(() => {
    setAllowChartUpdate(true);
    window.setTimeout(() => {
      setAllowChartUpdate(false);
    }, TIMEOUT_DATE_CHANGE);
  }, [isoStartDate, isoEndDate]);

  const chartConfig = useMemo(() => {
    return makeChartOptionsNew({
      chart: {
        spacingLeft: 10,
        spacingRight: 10,
        marginTop: 40,
      },
      yTitle: "",
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        valueSuffix: PressureUnits.mmhg,
        formatter: function (this: TooltipFormatterContextObject) {
          let point = this.points ? this.points[0] : this.point;
          return parseTooltipDate(
            ObservationType.BloodPressure,
            this,
            `${ObservationsUnitAbbrevation.BLOOD_PRESSURE}`,
            "",
            point.series?.userOptions
          );
        },
        shared: true,
      },
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
        labels: {
          format: "{value:%m/%d}",
        },
        gridLineWidth: 1,
      },
      yAxis: {
        gridLineDashStyle: "longdash",
        plotLines: [
          {
            color: "#1A91FF",
            value: systolicAverage,
            width: "1",
            zIndex: 4,
          },
          {
            color: "#1A91FF",
            value: diastolicAverage,
            width: "1",
            zIndex: 4,
          },
        ],
      },
      series: [
        // Systolic BP
        {
          align: "right",
          name: "Systolic",
          color: chartConstants.COLOR.Line,
          colorAxis: 0,
          data: bloodPressures.map((bp) => {
            const { severityScore = 0 } = bp;
            const mmHgsInOneKpa = 7.501;
            const value =
              bp.systolic && bp.systolic.unit === ObservationUnits.KiloPascal
                ? bp.systolic.value * mmHgsInOneKpa
                : bp.systolic?.value || 0;
            const source = bp?.source ?? null;
            return {
              x: Date.parse(
                new Date(
                  bp.timeOfObservation ? bp.timeOfObservation : bp._meta.created
                ).toISOString()
              ),
              y: value,
              marker: {
                fillColor: getAlertLevelColorBG(
                  getSeverityAlertLevelNew(severityScore)
                ),
              },
              source,
            };
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
        // Diastolic BP
        {
          name: "Diastolic",
          color: chartConstants.COLOR.Line,
          colorAxis: 1,
          data: bloodPressures.map((bp) => {
            const { severityScore = 0 } = bp;
            const mmHgsInOneKpa = 7.501;
            const value =
              bp.diastolic && bp.diastolic.unit === ObservationUnits.KiloPascal
                ? bp.diastolic.value * mmHgsInOneKpa
                : bp.diastolic?.value || 0;
            const source = bp?.source ?? null;
            return {
              x: Date.parse(
                new Date(
                  bp.timeOfObservation ? bp.timeOfObservation : bp._meta.created
                ).toISOString()
              ),
              y: value,
              marker: {
                fillColor: getAlertLevelColorBG(
                  getSeverityAlertLevelNew(severityScore)
                ),
              },
              source,
            };
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      ],
      colorAxis: [
        {
          showInLegend: false,
          reversed: true,
          labels: {
            format: "{value}%",
          },
        },
        {
          showInLegend: false,
          labels: {
            format: "{value}%",
          },
        },
      ],
      time: {
        useUTC: false,
      },
    });
  }, [bloodPressures, patientDetail, isPrinting]);

  return (
    <div>
      {bloodPressures.length ? (
        <LineChart
          containerProps={{
            id: "blood-pressure-chart",
            className: "graphChart",
          }}
          options={chartConfig.options}
          allowChartUpdate={allowChartUpdate}
        />
      ) : (
        <NoDataAvailable className="noDataAvailable" />
      )}
    </div>
  );
};
