import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "clsx";

import { RuleModel } from "@/domain/rules/model";
import { dispatchSetProviderRules } from "@/domain/rules/redux/actions";
import { ObservationParameters } from "@/domain/rules/view/ObservationParameters";
import { selectRulesForProvider } from "@/domain/rules/redux/selectors";
import { Rule } from "@/domain/rules/model/types";
import { OverrideLevels } from "../ObservationParameters/constants";

import styles from "../OrganizationRules/styles.module.scss";

export type Props = {
  containerClassName?: string;
  selectedProviderId: string | null;
};
export const ProviderRules: React.FC<Props> = (props) => {
  const { containerClassName, selectedProviderId } = props;
  const rules = useSelector(selectRulesForProvider(selectedProviderId));

  const dispatch = useDispatch();

  const [savedAt, setSavedAt] = useState<Record<string, Date | null>>({});

  const handleSavedAt = (rule: Rule) => (savedAt: Date | null) =>
    setSavedAt({
      ...savedAt,
      [rule.id as string]: savedAt,
    } as any);

  useEffect(() => {
    const fetchRules = async () => {
      if (!selectedProviderId) return;

      const ruleModels =
        (await RuleModel.fetchByParams({
          limit: "100",
          providerId: selectedProviderId,
        })) || [];
      const rules = ruleModels.map((m) => m.pluckAll());

      dispatch(dispatchSetProviderRules(selectedProviderId, rules));
    };

    fetchRules();
  }, [selectedProviderId, savedAt]);

  return (
    <div className={cx(styles.organizationRules, containerClassName)}>
      {rules.map((rule) => {
        return (
          <ObservationParameters
            key={`providerRule-${rule.name}`}
            rule={rule}
            onSave={handleSavedAt(rule)}
            savedAt={savedAt[rule.id as string]}
            overrideLevel={OverrideLevels.provider}
            containerClassName={styles.box}
          />
        );
      })}
    </div>
  );
};
