import React, { Dispatch, SetStateAction, useState } from "react";
import { HeaderContent, SortedState, SortedStateType } from "../head/sortable";
import { TableWithPaginationConfig } from "./datatableConfig";
import DatatablePagination from "../../pagination";
import Table from "./table";
import { Stack } from "@mui/material";
import ArrowUpIcon from "@rsuite/icons/ArrowUp";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";

interface Props {
  records: (string | JSX.Element | undefined)[][];
  header: HeaderContent[];
  subHeaders?: JSX.Element[];
  mergedHeaders?: (JSX.Element | undefined)[];
  config?: TableWithPaginationConfig;
  id?: string;
}

export default function TableWithPagination(props: Props) {
  let recordsOnPage = 50;
  if (props.config && props.config.recordsPerPage) {
    recordsOnPage = props.config.recordsPerPage;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsOnPage);
  const [sortedColumn, setSortedColumn]: [
    number | undefined,
    Dispatch<SetStateAction<any>>
  ] = useState(undefined);

  // Get current records
  const indexOfLastRecord: number = currentPage * recordsPerPage;
  const indexOfFirstRecord: number = indexOfLastRecord - recordsPerPage;
  let currentRecords = props.records.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  /**
   * Paginating the table records
   * @param pageNumber
   * @returns
   */

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  /**
   * Set the value of records per page
   */
  const handleRecordPerPage = (perPage: number) => {
    setRecordsPerPage(perPage);
    resetDataTableConfiguration();
  };

  /**
   * Reset table config
   */
  const resetDataTableConfiguration = () => {
    currentRecords = props.records.slice(indexOfFirstRecord, indexOfLastRecord);
    paginate(1);
  };

  /**
   * Sort records
   */
  const sortingStates: SortedStateType[] = [
    SortedState.Ascending,
    SortedState.Descending,
    SortedState.NoSort,
  ];

  const nextSortState = (currentState: SortedStateType): SortedStateType => {
    if (currentState === SortedState.NoSort) {
      return sortingStates[0];
    } else {
      const currentIndex = sortingStates.indexOf(currentState);
      return sortingStates[currentIndex + 1];
    }
  };

  const onSort = (currentSortedState: SortedStateType, index: number) => {
    if (props.config?.onSort) {
      const nextSortedState = nextSortState(currentSortedState);
      props.config.onSort(index, nextSortedState);
      props.header[index].sortedState = nextSortedState;
      setSortedColumn(
        nextSortedState === SortedState.NoSort ? undefined : index
      );
    }
  };

  /**
   * Generating table header
   */
  const headers = props.header.map((content, key) => {
    let head = <span className=" th-text">{content.name}</span>;
    const splitContent = content.name.split(" ");
    if (splitContent.includes("/n")) {
      head = (
        <span className=" th-text">
          {" "}
          {splitContent[0]} <br /> {splitContent[splitContent.length - 1]}
        </span>
      );
    }
    return (
      <th key={key} className="bg-primary  text-white th-text">
        {head}
        {content.isSortable && (
          <span
            className={props.config?.isSortable ? "ml-1 pointer" : "d-none"}
            style={{ fontSize: "14px", verticalAlign: "top" }}
            onClick={async () => {
              onSort(content.sortedState, key);
            }}
          >
            {content.sortedState === SortedState.Ascending && (
              <ArrowUpIcon style={{ margin: "-0.3em", fontSize: "18px" }} />
            )}
            {content.sortedState === SortedState.Descending && (
              <ArrowDownIcon style={{ margin: "-0.3em", fontSize: "18px" }} />
            )}
            {content.sortedState === SortedState.NoSort && (
              <Stack
                spacing={-1.5}
                sx={{
                  display: "inline-flex",
                  fontSize: "18px",
                  margin: "-0.3em",
                }}
              >
                <ArrowUpIcon />
                <ArrowDownIcon />
              </Stack>
            )}
          </span>
        )}
      </th>
    );
  });

  return (
    <>
      <Table
        records={currentRecords}
        recordsPerPage={recordsPerPage}
        currentPage={currentPage}
        recordsOnPage={recordsOnPage}
        totalRecords={props.records.length}
        id={props.id}
        headers={headers}
        subHeaders={props.subHeaders}
        mergedHeaders={props.mergedHeaders}
        sortedColumnIndex={sortedColumn}
      />
      {currentRecords.length !== 0 && (
        <DatatablePagination
          recordsPerPage={recordsPerPage}
          totalRecords={props.records.length}
          paginate={paginate}
          currentPage={currentPage}
          setRecordPerPage={handleRecordPerPage}
        />
      )}
    </>
  );
}
