import { Receiver } from "../receiver/receiver";

/**
 * With the help of Receiver turns a user action into an RCA API Command.
 *
 */
export class Command<T> {
  public isCompleted = false;
  public isSuccessful = false;

  /**
   * Used for marking command execution as completed.
   */
  markAsCompleted() {
    this.isCompleted = true;
  }

  /**
   * Used for marking command execution as successful. If a receiver never calls it, the command is unsuccessful by default.
   */
  markAsSuccessful() {
    this.isSuccessful = true;
  }

  /**
   * Executes a user command by delegating it to a receiver that receives all the parameters essential for a specific command.
   *
   * @param receiver
   */
  async execute(receiver?: Receiver<T>) {
    if (receiver) {
      await receiver.execute();
    }
  }
}
