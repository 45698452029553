import { FC } from "react";
import {
  GroupRemove as GroupRemoveIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import { WatchListItem } from "./../watchListItem";
import { User } from "@/domain/user/model";

interface Props {
  patientCount: number;
  user: User;
  addToUnassignList: (user: User) => void;
  removeFromUnassignList: (user: User) => void;
}
export const UnassignWatchListItem: FC<Props> = ({
  user,
  patientCount,
  addToUnassignList,
  removeFromUnassignList,
}) => {
  const unassign = () => {
    addToUnassignList(user);
  };
  const resetAddOrRemove = () => {
    removeFromUnassignList(user);
  };

  return (
    <WatchListItem
      actionButtonDescription="Removes from already existing watchlist"
      resetButton={<RestartAltIcon onClick={resetAddOrRemove} />}
      primary={`${user?.firstName} ${user?.lastName}`}
      patientCount={patientCount}
      actionButton={<GroupRemoveIcon onClick={unassign} />}
    />
  );
};
