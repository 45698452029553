import { useSelector } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { selectPatientFilter } from "../../redux/selectors";

import styles from "./styles.module.scss";

interface Props {
  onChange: (alert: string, description: string) => void;
}

function AlertFilter(props: Props) {
  const { onChange } = props;
  const filters = useSelector(selectPatientFilter);

  const data = [
    { label: "Alerts", value: "alerts" },
    { label: "No Alerts", value: "noAlerts" },
  ];
  const selectedAlert = data.find(
    (alertOptions) => alertOptions.value === filters.alert
  )?.value;
  return (
    <BasicMultiSelect
      data={data}
      onChange={async (selectedAlert: string) => {}}
      value={filters.alert}
      isSearchable={false}
      placeholder={selectedAlert || "All Alert Status"}
      findValueBy={filters.alert}
      onSelect={async (selectedAlert: string) => {
        const selectedValue = data.filter(
          (alertOptions) => alertOptions.value === selectedAlert
        );
        onChange(selectedAlert, selectedValue[0].label);
      }}
      onClear={() => {
        onChange("", "");
      }}
      className={styles.select}
    />
  );
}

export default AlertFilter;
