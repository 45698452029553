import React, { useState } from "react";
import { NoteTimerProps } from "./types";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/domain/user/redux/selectors";
import Countdown from "@/components/countdown";
import {
  TimerActivityState,
  TimerState,
  TimerColors,
} from "@/components/countdown/types";

const NoteTimer: React.FC<NoteTimerProps> = ({ onChange, isStopped }) => {
  const currentUser = useSelector(selectCurrentUser);

  const [timerActivityState, setTimerActivityState] =
    useState<TimerActivityState>(TimerActivityState.INITIAL);
  const defaultColor = currentUser?.canAccumulateBillableTime
    ? TimerColors.Blue
    : TimerColors.Gray;

  const getTimerColor = () => {
    if (timerActivityState === TimerActivityState.PAUSED) {
      return TimerColors.Red;
    }

    if (timerActivityState === TimerActivityState.INACTIVE) {
      return TimerColors.Yellow;
    }

    return defaultColor;
  };

  const isRunning = () => {
    if (isStopped) {
      return !isStopped;
    }
    return (
      timerActivityState !== TimerActivityState.INITIAL &&
      timerActivityState !== TimerActivityState.PAUSED
    );
  };

  const handleTimerClick = () => {
    if (timerActivityState === TimerActivityState.PAUSED) {
      setTimerActivityState(TimerActivityState.ACTIVE);
    } else {
      setTimerActivityState(TimerActivityState.PAUSED);
    }
  };

  return (
    <>
      <span>
        <Countdown
          running={isRunning()}
          offsetTimestamp={new Date()}
          timerActivityState={timerActivityState}
          timerColor={getTimerColor()}
          onIdle={() => {
            setTimerActivityState(TimerActivityState.INACTIVE);
          }}
          onInactiveStopped={() => {
            setTimerActivityState(TimerActivityState.PAUSED);
          }}
          connectToTimerState={(timerState) => {
            onChange(timerState);
          }}
          onActiveUser={(timerState: TimerState) => {
            setTimerActivityState(TimerActivityState.ACTIVE);
          }}
          onClick={handleTimerClick}
        />
      </span>
    </>
  );
};

export default NoteTimer;
