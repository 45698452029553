import { FunctionComponent } from "react";
import { IconButton } from "rsuite";
import cx from "clsx";
import filterIcon from "../../assets/icons/anelto/filter.svg";

import styles from "./styles.module.scss";

type FilterProps = {
  filterText: string;
  containerClassName?: string;
  openPane: () => void;
};
export const FilterButton: FunctionComponent<FilterProps> = (props) => {
  const { filterText, containerClassName, openPane } = props;

  return (
    <div className={cx(styles["filter-icon-parent"], containerClassName)}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          openPane();
        }}
        placement="left"
        className={cx(styles["icon-button"])}
      >
        <img
          className={cx(styles["filter-icon"])}
          src={filterIcon}
          alt={"call"}
        />
        <span className={cx(styles["filter-button-text"])}>{filterText}</span>
      </IconButton>
    </div>
  );
};
