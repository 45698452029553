import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "rsuite";
import { DateTime } from "luxon";

import { RootState } from "@/types";
import {
  getPatientNextState,
  ManageStateProps,
  PatientState,
  PatientStateMachine,
  PatientStateTransition,
  PatientTransition,
} from "./types";

export const ManageState: React.FC<ManageStateProps> = (props) => {
  const {
    targetedPatient,
    onShowConfirmModal,
    onDisableAcceptBtn,
    onSelectStatus,
    setReturnKitModal,
  } = props;
  const { lifecycle } = targetedPatient || {};

  const dispatch = useDispatch();

  const handleChooseStatus = (selectedState: PatientState): void => {
    onSelectStatus(selectedState);
    selectedState === PatientState.Completed &&
      targetedPatient.deviceInfo &&
      setReturnKitModal();
    selectedState === PatientState.Completed &&
      !targetedPatient.deviceInfo &&
      onShowConfirmModal(true);
    selectedState !== PatientState.Completed && onShowConfirmModal(true);
    onDisableAcceptBtn(false);
  };

  const transformState = (state: PatientState) => {
    if (state === PatientState.Preactive) return "PRE-ACTIVE";
    return state;
  };

  return (
    <>
      {props.type === "dropdown" && (
        <>
          {lifecycle.state !== PatientState.Completed &&
            getPatientNextState(lifecycle.state as PatientState).map(
              (state: PatientState) => (
                <Dropdown.Item
                  key={`lifecycle-${state}`}
                  onSelect={() => handleChooseStatus(state)}
                >
                  {transformState(state)}
                </Dropdown.Item>
              )
            )}
        </>
      )}
    </>
  );
};
