import React, { useState } from "react";
import cx from "clsx";
import { Tooltip } from "../../Tooltip";
import useCopyToClipboard from "@/hooks/useCopy";
import copyIcon from "@/assets/icons/anelto/copy.svg";

interface CopyButtonProps {
  className?: string;
  tooltip?: string;
  content: string;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  className,
  tooltip,
  content,
}) => {
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(
    tooltip || "Copy"
  );
  const [_value, copy] = useCopyToClipboard();

  const onClick = async () => {
    await copy(content);

    setTooltipContent(
      <>
        <i className="fa text-success fa-check-circle"></i> Copied
      </>
    );

    setTimeout(() => {
      setTooltipContent(tooltip || "Copy");
    }, 2000);
  };

  return (
    <>
      <Tooltip tooltipContent={<>{tooltipContent}</>}>
        <button
          className={cx(className || "", "btn btn-primary text-center")}
          type="button"
          onClick={onClick}
        >
          <img src={copyIcon} alt="Copy" className="fa mr-0 fa-1x" />
        </button>
      </Tooltip>
    </>
  );
};
