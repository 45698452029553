import { PatientObservation } from "@/domain/patient/model/types";
import { Attributes, Model, ModelCollection } from "@/library/model";
import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { PatientModel } from "@/domain/patient/model";
import { ObservationReason } from "@/library/types/note";
import { MODEL_SYNCED } from "@/library/constants";
import { ObservationDeserializer } from "@/library/deserializers/patient/observation.deserializer";

export class ObservationModel extends Model<PatientObservation> {
  static readonly path = "observations";
  /**
   * Makes a new instance of ObservationModel. It requires only the attributes
   * that would be used to instantiate new Observation Model.
   *
   * @param attributes
   */
  static make(attributes: PatientObservation): ObservationModel {
    return new ObservationModel(
      new Attributes<PatientObservation>(attributes),
      new RemoteCareAPISync<PatientObservation>(ObservationModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of observations using the provided resource url and a deserializer.
   * The entire observations list can be accessed in the models member of the Collection class.
   */
  static makeObservationCollection(
    patientId: string
  ): ModelCollection<ObservationModel, PatientObservation> {
    return new ModelCollection<ObservationModel, PatientObservation>(
      ObservationModel.buildUrl(patientId),
      (entity: PatientObservation) => this.deserialize(entity)
    );
  }

  static buildUrl = (patientId: string): string =>
    `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/${ObservationModel.path}`;

  /**
   * Sync observation by patient Id.
   * @param patientId
   */
  static sync = (
    patientId: string
  ): ModelCollection<ObservationModel, PatientObservation> =>
    ObservationModel.makeObservationCollection(patientId);

  static syncByRefId = async (
    patientId: string,
    NoteOrLegacyId: string,
    observationReason: ObservationReason
  ): Promise<PatientObservation[]> => {
    const observationModelCollection: ModelCollection<
      ObservationModel,
      PatientObservation
    > = ObservationModel.makeObservationCollection(patientId);
    observationModelCollection.on(MODEL_SYNCED, () => {});

    if (observationReason === ObservationReason.Manual) {
      observationModelCollection.withParams({ manualNoteId: NoteOrLegacyId });
    } else {
      observationModelCollection.withParams({
        legacyDocumentId: NoteOrLegacyId,
      });
    }
    const iterator = await observationModelCollection.getMany();
    const patientObservationModels = await iterator.getAll();

    return patientObservationModels.map((patientObservationModel) =>
      patientObservationModel.pluckAll()
    );
  };

  static deserialize = (data: PatientObservation) =>
    ObservationDeserializer.for(data, ObservationModel.make).deserialize();
}
