import { useRef, useState } from "react";

import { Notification } from "@/components/notification/notification";
import { ERROR, SOMETHING_WENT_WRONG } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";

interface Props {
  handleClose: () => void;
  handleSave: (condition: string) => void;
  targetedPatient: Patient;
}

function SecondaryDiagnosisForm(props: Props) {
  const { handleClose } = props;
  const [condition, setCondition] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const onAddNewCondition = async () => {
    if (condition.trim() !== "") {
      setDisableSaveBtn(true);
      try {
        const addedCondition = {
          displayName: condition,
          type: "SECONDARY",
        };
        await PatientModel.make(props.targetedPatient).modifyPatient(
          addedCondition as any,
          "add-condition"
        );
        props.handleSave(condition);
        setDisableSaveBtn(false);
      } catch (error: any) {
        if (error.status || error.response) {
          Notification.notify(
            ERROR,
            RCAResponseErrorParser.parse(error).message()
          );
        } else {
          Notification.notify(ERROR, SOMETHING_WENT_WRONG);
        }
        setDisableSaveBtn(false);
      }
    } else {
      setShowErrorMsg(true);
    }
  };
  const secondaryField = useRef(null);

  return (
    <>
      <div className="modal-body">
        <div className="subject-scroll">
          <div className="form-row d-flex justify-content-center">
            <div className="col-12 mb-3 px-5">
              <label className="required-field" htmlFor="secondary">
                Secondary
              </label>
              <input
                className="form-control false"
                name="secondary"
                ref={secondaryField}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  setCondition(event.currentTarget.value);
                }}
                type="text"
                placeholder="New Condition"
              />
              {showErrorMsg && (
                <div
                  style={{
                    color: "tomato",
                    fontStyle: "italic",
                    fontSize: "11px",
                  }}
                >
                  This field is required.
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={onAddNewCondition}
                disabled={disableSaveBtn}
                className="btn btn-primary mr-2"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondaryDiagnosisForm;
