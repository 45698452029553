export enum ResourceType {
  organization,
  practice,
  patient,
  user,
  provider,
  note,
  tag,
  kits,
}
