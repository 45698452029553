import { ERROR } from "@/library/constants";
import { Note } from "@/domain/notes/model/types";
import { Notification } from "@/components/notification/notification";
import { Str } from "@/util/Str";
import { ObservationFields } from "./types";
import {
  OBSERVATIONS_BOUNDS,
  ObservationType,
} from "@/domain/observations/types";

export const validateObservationsFields = (values: Partial<Note>): boolean => {
  let validated = true;
  const invalidFields: string[] = [];
  values.observations?.forEach((observation: Partial<ObservationFields>) => {
    if (Object.keys(observation).length === 1) {
      // If lenght of observation object is 1 that means it does not have any value
      validated = false;
    }

    switch (observation.type) {
      case ObservationType.BloodPressure:
        if (
          !observation.diastolic ||
          !observation.systolic ||
          !observation.systolic.value ||
          !observation.diastolic.value ||
          observation.diastolic.value <
            OBSERVATIONS_BOUNDS.BloodPressureDiastolic.lowerBound ||
          observation.diastolic.value >
            OBSERVATIONS_BOUNDS.BloodPressureDiastolic.upperBound ||
          observation.systolic.value <
            OBSERVATIONS_BOUNDS.BloodPressureSystolic.lowerBound ||
          observation.systolic.value >
            OBSERVATIONS_BOUNDS.BloodPressureSystolic.upperBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.Spirometry:
        if (
          !observation.fev1 ||
          !observation.pef ||
          !observation.fev1.value ||
          !observation.pef.value ||
          observation.fev1.value <
            OBSERVATIONS_BOUNDS.SpirometryFev.lowerBound ||
          observation.fev1.value >
            OBSERVATIONS_BOUNDS.SpirometryFev.upperBound ||
          observation.pef.value <
            OBSERVATIONS_BOUNDS.SpirometryPef.lowerBound ||
          observation.pef.value > OBSERVATIONS_BOUNDS.SpirometryPef.upperBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.GlucoseLevel:
        if (
          !observation.value ||
          !observation.value ||
          observation.value > OBSERVATIONS_BOUNDS.GLUCOSE_LEVEL.upperBound ||
          observation.value < OBSERVATIONS_BOUNDS.GLUCOSE_LEVEL.lowerBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.HeartRate:
        if (
          !observation.value ||
          !observation.value ||
          observation.value > OBSERVATIONS_BOUNDS.HEART_RATE.upperBound ||
          observation.value < OBSERVATIONS_BOUNDS.HEART_RATE.lowerBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.PulseOximetry:
        if (
          !observation.value ||
          !observation.value ||
          observation.value > OBSERVATIONS_BOUNDS.PULSE_OXIMETRY.upperBound ||
          observation.value < OBSERVATIONS_BOUNDS.PULSE_OXIMETRY.lowerBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.Temperature:
        if (
          !observation.value ||
          !observation.value ||
          observation.value > OBSERVATIONS_BOUNDS.TEMPERATURE.upperBound ||
          observation.value < OBSERVATIONS_BOUNDS.TEMPERATURE.lowerBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      case ObservationType.Weight:
        if (
          !observation.value ||
          !observation.value ||
          observation.value > OBSERVATIONS_BOUNDS.WEIGHT.upperBound ||
          observation.value < OBSERVATIONS_BOUNDS.WEIGHT.lowerBound
        ) {
          invalidFields.push(generateErrorMessage(observation.type));
          validated = false;
        }
        break;
      default:
    }
  });
  if (!validated && invalidFields.length > 0) {
    Notification.notify(
      ERROR,
      invalidFields.map((message) => message)
    );
  }
  return validated;
};

const generateErrorMessage = (observationType: ObservationType): string => {
  const observation = observationType.split("_").join(" ").toLowerCase();
  return `Invalid field ${Str.CapitalizeFirstLetter(observation)}`;
};
