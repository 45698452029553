import { KeyboardEvent, MouseEvent, useState } from "react";
import { Tooltip } from "react-tooltip";
import cx from "clsx";

import { AlertLevel } from "@/domain/notes/model/types";
import { getBannerIconColor } from "@/domain/patient/view/PatientBanners/constants";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export interface Props {
  id: string;
  src: string;
  color?: string;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
  onClick?: (event: MouseEvent | KeyboardEvent) => void;
  isObservationCompleted?: boolean;
  timeObservationCompleted?: string;
  size?: "sm" | "xs";
}

const getBackgroundColor = (props: Props) => {
  return props.isObservationCompleted && props.color === AlertLevel.BLACK_ALERT
    ? AlertLevel.BLACK_ALERT
    : props.isObservationCompleted
    ? AlertLevel.NO_ALERT
    : getBannerIconColor(props.color as AlertLevel);
};

const iconStyle = (props: Props) => ({
  backgroundColor: `${getBackgroundColor(props)}`,
  border: `5px solid ${getBannerIconColor(props.color as AlertLevel)}`,
});

export const IconContainer: React.FC<Props> = (props) => {
  const { id, size, tooltipContent, disabled, onClick } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  return (
    <>
      <button
        className={cx(
          globalStyles.buttonReset,
          "p-1",
          "bd-highlight",
          styles.obsItem,
          disabled && globalStyles.disabled
        )}
        disabled={disabled}
        onClick={onClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseOver={handleFocus}
        onMouseOut={handleBlur}
      >
        <span
          id={id}
          className={cx("text-center", "box-border", styles.iconContainer)}
          style={iconStyle(props)}
          data-tip-disable={false}
          data-tooltip-delay-show={2000}
        >
          <img
            src={props.src}
            className={cx(
              props.color === AlertLevel.BLACK_ALERT && styles["whiteIcon"],
              !props.isObservationCompleted &&
                props.color === AlertLevel.DISABLED_ALERT &&
                styles["grayIcon"],
              "d-block mx-auto svgfix",
              size && styles[size]
            )}
            alt=""
          />
          {props.children}
        </span>
      </button>

      {tooltipContent ? (
        <Tooltip
          isOpen={focused || false}
          anchorSelect={`#${id}`}
          className={styles.tooltip}
          // @ts-ignore
          delay={{ show: "2500", hide: "0" }}
          delayShow={2000}
          enterNextDelay={2000}
        >
          {tooltipContent}
        </Tooltip>
      ) : null}
    </>
  );
};
