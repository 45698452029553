import { Box, Divider, Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { PathwayProgram } from "@/components/pathway/program";
import { PathwayAutomations } from "@/components/pathway/automations";
import { PathwayOrganizationReadOnly } from "@/components/pathway/organization";
import { Program } from "@/domain/program/model/types";
import { Organization } from "@/domain/organization/model/types";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { AssessmentAction, Automation } from "@/domain/automation/model/types";
import {
  fetchPrograms,
  fetchProgramsForOrganization,
} from "@/components/pathway/functions";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";

export interface PathwayContainerProps {
  organization: Organization;
  onSave: () => void;
}

export const PathwayContainer: FC<PathwayContainerProps> = ({
  organization,
  onSave,
}) => {
  const [program, setProgram] = useState<Program | null>(null);
  const [programs, setPrograms] = useState<Program[]>([]);

  useEffect(() => {
    (async () => {
      const p: Program[] = await fetchProgramsForOrganization(
        organization._meta?.id as string
      );
      setPrograms(p);
    })();
  }, [program]);

  return (
    <Box>
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        <Box>
          <Stack
            sx={(theme) => ({ width: "40%" })}
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <PathwayOrganizationReadOnly organization={organization} />
            <PathwayProgram setProgram={setProgram} programs={programs} />
          </Stack>
          {program && program.automations && (
            <PathwayAutomations
              save={async (automations: Automation[]) => {
                const allPrograms: Program[] = await fetchPrograms();
                const originalProgram = allPrograms.find(
                  (p: Program) => p._meta?.id === program._meta?.id
                );
                if (program) {
                  if (program.organizationId !== null && originalProgram) {
                    const originalAutomations: Automation[] =
                      originalProgram.automations ?? [];
                    const updatedAutomations: Automation[] = automations;

                    interface AutomationPayload {
                      set: Record<string, Automation>; // Object with IDs as keys and Automation objects as values
                      delete: string[]; // Array of IDs to delete
                      push: Automation[]; // Array of Automation objects to add
                    }

                    const payload: AutomationPayload = {
                      set: {},
                      delete: [],
                      push: [],
                    };

                    // Create a map for quick lookup by ID
                    const originalMap = new Map(
                      originalAutomations.map((auto) => [auto.id, auto])
                    );

                    updatedAutomations.forEach((updatedAutomation) => {
                      const originalAutomation = originalMap.get(
                        updatedAutomation.id
                      );

                      if (originalAutomation) {
                        const id = updatedAutomation.id;
                        delete updatedAutomation.id;
                        const action = updatedAutomation
                          .actions[0] as AssessmentAction;
                        delete action.assessmentTemplate;
                        payload.set[id as string] = updatedAutomation;
                        originalMap.delete(id); // Remove from the map as it's been matched
                      } else {
                        // Add to 'push' if it's a new automation
                        const id = updatedAutomation.id;
                        delete updatedAutomation.id;
                        const action = updatedAutomation
                          .actions[0] as AssessmentAction;
                        delete action.assessmentTemplate;
                        payload.push.push(updatedAutomation);
                      }
                    });

                    // Any remaining items in the originalMap are to be deleted
                    originalMap.forEach((_, id) => {
                      if (id) {
                        payload.delete.push(id);
                      }
                    });
                    try {
                      // update program
                      await Http().post(
                        `${RemoteCareAPISync.host}/programs/${program._meta?.id}/actions/modify`,
                        {
                          ...program,
                          id: undefined,
                          _meta: undefined,
                          automations: payload,
                        }
                      );
                      onSave();
                      Notification.notify(
                        SUCCESS,
                        `Program updated successfully`
                      );
                    } catch (e) {
                      Notification.notify(
                        ERROR,
                        `There was an error updating this program, please try again.`
                      );
                    }
                  } else {
                    // create program
                    delete program._meta;
                    delete program.id;
                    program.automations = automations.map(
                      (automation: Automation) => {
                        const action = (
                          automation.actions as AssessmentAction[]
                        )[0];
                        delete action.assessmentTemplate;
                        delete automation.id;
                        return automation;
                      }
                    );
                    program.organizationId = organization._meta?.id as string;

                    try {
                      await Http().post(
                        `${RemoteCareAPISync.host}/programs`,
                        program
                      );
                      onSave();
                      Notification.notify(
                        SUCCESS,
                        `Program updated successfully`
                      );
                    } catch (e) {
                      Notification.notify(
                        ERROR,
                        `There was an error updating this program, please try again.`
                      );
                    }
                  }
                }
              }}
              program={program}
              organization={organization}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
