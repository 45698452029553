import { extractphone } from "@/components/form";
import { User, UserActions, UserForm, UserModel } from "@/domain/user/model";
import { Serializeable } from "@/library/serializers/serializer";
import { autoAssignRule } from "@/library/serializers/user/helper";

/**
 * Serializer for creating new User.
 */
export class CreateUserSerializer implements Serializeable<UserModel> {
  constructor(protected readonly formData: UserForm) {}

  serialize(): UserModel {
    return UserModel.make({
      id: this.formData.id,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      email: this.formData.email,
      credentials:
        this.formData.credentials === "none" ? null : this.formData.credentials,
      phone: extractphone(this.formData.phone),
      notifications: this.formData.notifications,
      canAccumulateBillableTime: this.formData.canAccumulateBillableTime,
      parentEntities: [
        {
          type:
            this.formData.userAction === UserActions.CreatePracticeUser
              ? "Practice"
              : "Organization",
          id: (() => {
            const { id } = this.formData.currentUser;
            if (this.formData.userAction === UserActions.CreatePracticeUser) {
              return this.formData.practiceId;
            }
            if (
              this.formData.userAction === UserActions.CreateOrganizationUser
            ) {
              return this.formData.organizationId;
            }
            return id;
          })(),
        },
      ],
      autoAssignRules: this.formData.hasAutoAssign
        ? [
            ...this.formData.organization
              .split(",")
              .map((organizationId) =>
                autoAssignRule(
                  organizationId,
                  this.formData.role,
                  this.formData.userAction
                )
              ),
          ]
        : null,
      policy: this.formData.policy,
      tagIds: this.formData.locationIds ? this.formData.locationIds : undefined,
    } as unknown as User);
  }
}
