import { PatientObservation } from "@/domain/patient/model/types";
import { ObservationModel } from "@/domain/observations/model";
import { CanMake } from "@/library/model/model.interface";
import {
  Deserializer,
  DeserializerStack,
} from "@/library/deserializers/deserializer";

export class ObservationDeserializer extends Deserializer<
  PatientObservation,
  ObservationModel
> {
  /**
   * Makes an instance of observation deserializer
   * @param observation
   * @param makeModel
   * @returns
   *
   */
  static for(
    observation: PatientObservation,
    makeModel: CanMake<PatientObservation, ObservationModel>
  ) {
    return new ObservationDeserializer(observation, makeModel);
  }
  /**
   * Stack of syncronous deserializing functions.
   */
  protected deserializers: DeserializerStack<ObservationModel> = {
    /**
     * @NOTE NEVER ADD ASYNC METHODS IN THE DESERIALIZER STACK.
     *
     */
    _meta: () => this.remodelMetaCreated(),
  };

  private remodelMetaCreated(): void {
    const createdDate = this.entityModel.pluck("_meta").created;
    if (createdDate) {
      const dateInLocalTimezone = this.convertLocalTimezone(createdDate);
      const _meta = {
        created: dateInLocalTimezone.toString(),
        id: this.entityModel.pluck("_meta").id,
      };
      this.setValue("_meta", _meta);
    }
  }
}
