import React, { FC, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { PathwayHistoryRow } from "@/components/pathway/history/row";
import { Http } from "@/library/core/api/http";
import {
  Assessment,
  AssessmentTemplate,
} from "@/domain/assessment/model/types";
import { RemoteCareAPISync } from "@/library/core/api/APISync";
import { Box, Typography } from "@mui/material";

interface Props {
  patientId: string;
}
export const PathwayHistory: FC<Props> = ({ patientId }) => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  useEffect(() => {
    (async () => {
      try {
        if (!assessments.length) {
          const response = await Http().get(
            `${RemoteCareAPISync.host}/patients/${patientId}/assessments`
          );
          const resolvedAssessments: Assessment[] = await Promise.all(
            (response.data as Assessment[]).map(async (a: Assessment) => {
              if (a.templateId) {
                const template: AssessmentTemplate = (
                  await Http().get(
                    `${RemoteCareAPISync.host}/assessment-templates/${a.templateId}`
                  )
                ).data;
                a.template = template;
                return a;
              }
              return a;
            })
          );
          setAssessments(resolvedAssessments);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [assessments]);
  return (
    <Box>
      <Typography variant="h6">Pathway Results</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Pathway Name</TableCell>
              <TableCell align="left">Date/Time</TableCell>
              <TableCell align="left">Pathway Response</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.map((assessment) => (
              <PathwayHistoryRow
                key={assessment._meta?.id!}
                assessment={assessment}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
