import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";
import {
  TextInput,
  Select,
  TextInputMask,
  Validation,
} from "@/components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AllergyForm } from "../model/allergy";
import { format } from "date-fns";
import { DatePicker } from "@/components/_inputs/DatePicker";
import { DateTime } from "luxon";

interface Props {
  modalBtn: string;
  handleClose: () => void;
  handleSubmit: (values: AllergyForm, editMode: boolean) => Promise<void>;
  targetedAllergy: AllergyForm;
  targetedAllergyId: number;
}

function AllergyFormComponent(props: Props) {
  const { modalBtn, handleClose, handleSubmit } = props;
  const editMode = modalBtn === "Save Changes";

  let occuredDate = null;
  if (editMode && props.targetedAllergy) {
    occuredDate = props.targetedAllergy.dateOccurred
      ? (props.targetedAllergy.dateOccurred as string)
      : null;
  }
  const [dateOccured, setDateOccured]: [
    string | null,
    Dispatch<SetStateAction<any>>
  ] = useState(occuredDate);

  const initialValues: AllergyForm = {
    allergy: "",
    reaction: "",
    dateOccurred: "",
    severe: false,
  };

  const getInitialValues = (): AllergyForm => {
    if (editMode && props.targetedAllergy) {
      return {
        allergy: props.targetedAllergy.allergy,
        reaction: props.targetedAllergy.reaction,
        dateOccurred: props.targetedAllergy.dateOccurred || "",
        severe: props.targetedAllergy.severe,
      };
    }
    return initialValues;
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          allergy: Validation.required,
          reaction: Validation.required,
          dateOccurred: Validation.notRequired,
        })}
        onSubmit={async (values: AllergyForm, { setSubmitting, resetForm }) => {
          await handleSubmit(values, editMode);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="modal-body">
                <div>
                  <div className="form-row d-flex justify-content-start">
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Allergy"
                        name="allergy"
                        type="text"
                        placeholder="Enter Allergy"
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Reaction"
                        name="reaction"
                        type="text"
                        placeholder="Enter Reaction"
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <div>
                        <label>Effective Date</label>
                      </div>
                      <DatePicker
                        isClearable={true}
                        name="dateOccurred"
                        selected={
                          dateOccured
                            ? DateTime.fromISO(dateOccured).toJSDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          setDateOccured(date);
                          if (date) {
                            formik.setFieldValue(
                              "dateOccurred",
                              format(new Date(date) as Date, "yyyy-MM-dd")
                            );
                          } else {
                            formik.setFieldValue("dateOccurred", "");
                          }
                        }}
                      />
                    </div>
                    <div className="col mb-3">
                      <div className="mb-2">
                        <label style={{ opacity: 0 }}> Severe </label>
                      </div>
                      <label className="mr-4">
                        <input
                          type="checkbox"
                          defaultChecked={
                            editMode && props.targetedAllergy.severe
                              ? props.targetedAllergy.severe
                              : false
                          }
                          name="severe"
                          onChange={(e: any) => {
                            formik.setFieldValue("severe", e.target.checked);
                          }}
                          className="status-dropdown mr-2"
                        />
                        Severe
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4 d-flex justify-content-start"></div>
                    <div className="col-6 mt-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        {modalBtn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default AllergyFormComponent;
