export const camelize = (str: string): string => {
  return str
    .split(" ")
    .map(
      (word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
    )
    .reduce((prev, curr) => `${prev} ${curr}`);
};

export const snake2Camel = (snake: string): string =>
  camelize(snake.replaceAll("_", " "));
