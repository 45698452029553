import React from "react";
import cx from "clsx";

import styles from "./styles.module.scss";
import { connect } from "react-redux";
import {
  dispatchSetFilterItems,
  dispatchSetPatientFilters,
} from "@/domain/patient/redux/actions";
import { FilterItemDetail, PatientFilters } from "@/domain/patient/model/types";
import { Dispatch } from "redux";
import { ReactComponent as FilterClearIcon } from "@/assets/icons/anelto/filterclear.svg";

type FilterClearProps = {
  onSearch: (query: object) => void;
  filters: PatientFilters;
  filterItems: FilterItemDetail[];
  setPatientFilters: (filters: PatientFilters) => void;
  setFilterItems: (filterItems: FilterItemDetail[]) => void;
};

type FilterClearState = {
  filters: PatientFilters;
  filterItems: FilterItemDetail[];
};

class FilterClear extends React.Component<FilterClearProps, FilterClearState> {
  clear() {
    this.props.setFilterItems([]);
    this.props.setPatientFilters({});
    this.props.onSearch({});
  }

  render() {
    return (
      <div className={cx(styles["filter-clear-parent"])}>
        <div className={cx(styles["filter-clear"])}>
          <button
            type="button"
            onClick={() => {
              this.clear();
            }}
          >
            <FilterClearIcon />
          </button>
          Clear All
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setPatientFilters: (filters: PatientFilters) =>
      dispatch(dispatchSetPatientFilters(filters)),
    setFilterItems: (filterItems: FilterItemDetail[]) =>
      dispatch(dispatchSetFilterItems(filterItems)),
  };
};

const mapStateToProps = (state: {
  patient: { filters: PatientFilters; filterItems: FilterItemDetail[] };
}) => {
  return {
    filters: state.patient.filters,
    filterItems: state.patient.filterItems,
  };
};

export const FilterClearComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterClear);
