import { extractphone } from "@/components/form";
import { PatientForm } from "@/domain/patient/model/types";
import { dateFormatter } from "../../../pipes/date/index";

export const getPatientDesignatedRepresentatives = (
  contacts: PatientForm["emergencyContact"]
) => {
  const emergencyContacts = contacts
    ?.filter((contact) => contact.firstName !== "" && contact.lastName !== "")
    .map((contact) => {
      return {
        name: contact.firstName + " " + contact.lastName,
        relation: contact.relation === "" ? null : contact.relation,
        email: contact.email === "" ? null : contact.email,
        phone:
          contact.primaryPhone === ""
            ? null
            : {
                primary: {
                  number: extractphone(contact.primaryPhone),
                  isSmsAllowed: contact.allowSms,
                },
              },
      };
    });
  return emergencyContacts;
};

export const patientDateOfBirth = (dob: string | undefined) => {
  if (!dob || dob === "") return null;
  const dateOfBirth = dateFormatter({
    date: dob,
    isDateOfBirth: true,
    hasCustomFormatting: "yyyy-MM-dd",
  });
  return dateOfBirth;
};
