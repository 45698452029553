/**
 * StateMachine maps the relation between currentState, transitions
 * allowed from that state, and the resulting state.
 *
 * @example
 * const PatientStateMachine: StateMachine<PatientTransition, PatientState> = {
 *   [PatientTransition.AddPatient]: {
 *     allowedFrom: [PatientState.NoState, PatientState.Completed],
 *     resultingState: PatientState.Added,
 *   },
 * }
 */
export type StateMachine<T extends string, S extends string> = {
  [K in T]: {
    allowedFrom: Array<S>;
    resultingState: S;
  };
};

/**
 * StateTransition maps the relation between currentState,
 * resulting state and transitions
 *
 * @example
 * export const PatientStateTransition: StateTransition<
 * PatientState,
 * PatientTransition
 * > = {
 * [PatientState.Added]: {
 *   resultingState: PatientState.Preactive,
 *   transition: PatientTransition.ActivatePatient
 * },
 */

export type StateTransitionMachine<S extends string, T extends string> = {
  [K in S]:
    | {
        stateTransition: possibleTransition<S, T>[];
      }
    | undefined;
};

type possibleTransition<S extends string, T extends string> = {
  resultingState: S;
  transition: T;
};
/**
 * transitionState takes the current state and the resulting state
 * and returns the transition.
 * @param {string} currentState
 * @param {string} resultingState
 * @param {StateTransitionMachine} stateTransitionMachine
 * @returns {string} the resulting transition.
 */
export function transitionState<S extends string, T extends string>(
  currentState: S,
  resultingState: S,
  stateTransitionMachine: StateTransitionMachine<S, T>
): T | undefined {
  return stateTransitionMachine[currentState]!.stateTransition.filter(
    () => resultingState === resultingState
  ).find((s) => s.resultingState === resultingState)?.transition;
}
