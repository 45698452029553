import { Scope } from "./scope";

export interface Policy {
  [key: string]: {
    roles: PolicyRole[];
  };
}

export interface PolicyRole {
  roleId: string;
  notBefore: string | null;
  notAfter: string | null;
}

export abstract class PolicyParser {
  constructor(public readonly scope: Scope) {}
}
