import { useField } from "formik";
import cx from "clsx";

import "../form.styles.css";
import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  hasError?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export const Select: React.FC<Props> = ({
  label,
  required = false,
  hasError = false,
  ...props
}) => {
  const [field, meta] = useField(props as any);
  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <label
        className={cx(
          hasError && globalStyles.danger,
          required ? "required-field" : ""
        )}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      {/* @ts-ignore */}
      <select
        className={cx(
          (hasError || !isValid) && styles.hasError,
          `form-control`
        )}
        {...field}
        {...props}
      />
      {!isValid ? (
        <div className="invalid-input-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};
