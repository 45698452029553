import React from "react";
import {
  TextInput,
  Select,
  TextInputMask,
  Validation,
  TextArea,
} from "@/components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { InterdisciplinaryForm } from "../model/interdisciplinary";

interface Props {
  handleSubmit: (
    values: InterdisciplinaryForm,
    editMode: boolean
  ) => Promise<void>;
  handleClose: () => void;
  modalBtn: string;
  targetedTeamMember?: InterdisciplinaryForm;
}

function InterdisciplinaryFormComponent(props: Props) {
  const { handleClose, handleSubmit } = props;
  const isEditMode = props.modalBtn === "Save Changes";

  const initialValues: InterdisciplinaryForm = {
    firstName: "",
    lastName: "",
    practiceName: "",
    specialty: "",
    contactFirstName: "",
    contactLastName: "",
    phoneNumber: "",
    faxNumber: "",
    notes: "",
    isActive: true,
  };

  const getInitialValues = (): InterdisciplinaryForm => {
    if (isEditMode && props.targetedTeamMember) {
      const member = props.targetedTeamMember;
      return {
        firstName: member.firstName,
        lastName: member.lastName,
        practiceName: member.practiceName,
        specialty: member.specialty === null ? "" : member.specialty,
        contactFirstName:
          member.contactFirstName === null ? "" : member.contactFirstName,
        contactLastName:
          member.contactLastName === null ? "" : member.contactLastName,
        phoneNumber: member.phoneNumber === null ? "" : member.phoneNumber,
        faxNumber: member.faxNumber === null ? "" : member.faxNumber,
        notes: member.notes === null ? "" : member.notes,
        isActive: true,
      };
    }
    return initialValues;
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          firstName: Validation.required,
          lastName: Validation.required,
          practiceName: Validation.required,
        })}
        onSubmit={async (
          values: InterdisciplinaryForm,
          { setSubmitting, resetForm }
        ) => {
          await handleSubmit(values, isEditMode);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="modal-body">
                <div className="subject-scroll">
                  <div className="form-row d-flex justify-content-start">
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="First Name"
                        name="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Last Name"
                        name="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Practice"
                        name="practiceName"
                        type="text"
                        placeholder="Enter Entity/Branch Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Speciality"
                        name="specialty"
                        type="text"
                        placeholder=""
                        required={false}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Contact First Name"
                        name="contactFirstName"
                        type="text"
                        placeholder="Contact First Name"
                        required={false}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Contact Last Name"
                        name="contactLastName"
                        type="text"
                        placeholder="Contact Last Name"
                        required={false}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInputMask
                        value={formik.values.phoneNumber}
                        label="Contact Phone"
                        name="phone"
                        type="tel"
                        mask="+1 (999) 999-9999"
                        onChange={(e: any) => {
                          formik.setFieldValue("phoneNumber", e.target.value);
                        }}
                        required={false}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInputMask
                        value={formik.values.faxNumber}
                        label="Fax"
                        name="phone"
                        type="tel"
                        mask="+1 (999) 999-9999"
                        onChange={(e: any) => {
                          formik.setFieldValue("faxNumber", e.target.value);
                        }}
                        required={false}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <TextArea
                        label="Notes"
                        name="notes"
                        placeholder=""
                        required={false}
                        rows={1}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4 d-flex justify-content-start"></div>
                    <div className="col-6 mt-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        {props.modalBtn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default InterdisciplinaryFormComponent;
