import { RCAResourceActions } from "../core/config/actions";

/**
 * Describes concrete Serializers for domain specific actions.
 */
export abstract class Serializer<F, M> {
  abstract serialize(): M;
  protected abstract serializers: { [key: string]: any };

  constructor(
    protected readonly action: RCAResourceActions,
    protected readonly formData: F
  ) {
    this.trimmer(formData);
  }

  protected get(action: RCAResourceActions) {
    return this.serializers[action];
  }

  private trimmer(formObject: any) {
    if (formObject && typeof formObject === "object") {
      Object.keys(formObject).forEach((key) => {
        if (formObject[key] !== null && typeof formObject[key] === "object") {
          // Make function recursive if the object has nested objects
          this.trimmer(formObject[key]);
          return;
        }
        if (typeof formObject[key] === "string") {
          formObject = {
            ...formObject,
            [key]: formObject[key].trim(),
          };
        }
      });
    }
  }
}

/**
 * Describes serialiazer behaviour.
 */
export interface Serializeable<M> {
  serialize(): M;
}
