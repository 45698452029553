import React from "react";
import {
  flexRender,
  getCoreRowModel,
  Cell,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import cx from "clsx";

import { DATA } from "./constants";
import { makeColumns } from "./helpers";
import { AlertLegendRow, Props } from "./types";

import styles from "./styles.module.scss";

export const AlertLegend: React.FC<Props> = (props: Props) => {
  const { className } = props;

  const table = useReactTable<AlertLegendRow>({
    data: DATA,
    columns: makeColumns(),
    getCoreRowModel: getCoreRowModel(),
  });
  const { rows } = table.getRowModel();

  const renderHeader = () =>
    table.getHeaderGroups().map((headerGroup) => {
      return (
        <tr key={`legendHeadGroup-${headerGroup.id}`}>
          {headerGroup.headers.map((header) => {
            return (
              <th key={`header-${header.id}`} colSpan={header.colSpan}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            );
          })}
        </tr>
      );
    });

  const renderRow = (row: Row<AlertLegendRow>) => {
    return (
      <tr key={`alertLegend-${row.id}`}>
        {row.getAllCells().map((cell: Cell<AlertLegendRow, unknown>) => {
          return (
            <td key={cell.id} className={styles[cell.column.id]}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <table className={cx(styles.alertLegend, className)}>
      <thead>{renderHeader()}</thead>
      <tbody>{rows.length ? rows.map(renderRow) : null}</tbody>
    </table>
  );
};
