import React from "react";
import { useSelector } from "react-redux";

import { Table } from "@/components/table";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { TextButton } from "@/components/_buttons/TextButton";
import { ResourceType } from "@/library/core/config/resource";
import { buildTagType } from "@/domain/tags/model/helpers";
import { Location } from "@/domain/tags/model/types";
import { Practice } from "@/domain/practice/model/types";
import { RootState } from "@/types";
import { HEADERS } from "./constants";
import { Props } from "./types";

export const LocationTable: React.FC<Props> = (props) => {
  const {
    data,
    execSearch,
    pageLimit = 0,
    onPageLimitChange,
    paginationConfig,
    onEditTag,
    sortedState,
    setSortedState,
  } = props;

  const practices = useSelector<RootState, Practice[]>(
    (state) => state.practice.practices
  );

  const handleEditTag = (tagId: string) => () => {
    onEditTag(tagId);
  };

  const renderSubHeader = () => [
    <tr key={"locationTableHeader"}>
      {/* <td
        className={sortedState?.sortedColumnIndex === 0 ? "sorted_col" : ""}
      ></td> */}
    </tr>,
  ];

  const renderData = () =>
    (data || []).map((tag) => {
      const location = buildTagType<Location>(tag);
      const practice = practices.find(
        (practice: Practice) => practice.id === location.practiceId
      ) as Practice;

      return (
        <tr key={location?.id} className="td-text">
          <td
            className={sortedState?.sortedColumnIndex === 0 ? "sorted_col" : ""}
          >
            <TextButton onClick={handleEditTag(tag._meta.id as string)}>
              {location.name || "-"}
            </TextButton>
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 1 ? "sorted_col" : ""}
          >
            {location.address || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 2 ? "sorted_col" : ""}
          >
            {location.city || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 3 ? "sorted_col" : ""}
          >
            {location.state || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 4 ? "sorted_col" : ""}
          >
            {practice?.name || location.practiceId || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 5 ? "sorted_col" : ""}
          >
            {location.contactName || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 6 ? "sorted_col" : ""}
          >
            {location.contactEmail || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 7 ? "sorted_col" : ""}
          >
            {location.contactPhone || "-"}
          </td>
          <td
            className={sortedState?.sortedColumnIndex === 8 ? "sorted_col" : ""}
          >
            <div className="dropleft btn-group">
              <button
                className=""
                style={{ backgroundColor: "transparent", border: "none" }}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-boundary="window"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="feather icon-more-horizontal"></i>
              </button>
              <div
                className="dropdown-menu hide-scroll"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={handleEditTag(tag._meta.id as string)}
                >
                  Edit/View
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    });

  const tableData = [renderSubHeader(), renderData()];

  return (
    <Table
      paginationConfig={paginationConfig}
      pageLimit={pageLimit}
      onPageLimitChange={onPageLimitChange}
      header={
        <SortableHead
          headers={HEADERS}
          execSearch={execSearch}
          resourceType={ResourceType.tag}
          setSortedState={setSortedState}
        />
      }
      data={tableData}
      onNavigate={execSearch}
      isNonScrollable={true}
    />
  );
};
