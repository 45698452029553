import React from "react";
import bg from "../../assets/images/bg-images/wood-1.png";
import { Modal } from "../../components/modal";
import { EditProfileFormComponent } from "./form/edit.component";
import { ResetPasswordFormComponent } from "./form/reset-password.component";
import { User } from "../user/model";
import { setCurrentUser } from "../user/redux";
import { connect } from "react-redux";

type ProfileProps = {
  currentUser: User;
  setCurrentUser: (user: User) => { type: string; payload: User };
};

type ProfileState = {
  showModal: boolean;
  modalTitle: string;
  modalButton: string;
};

class Component extends React.Component<ProfileProps, ProfileState> {
  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      showModal: false,
      modalTitle: "",
      modalButton: "",
    };
  }

  onShowModal = (modalHeader: string, modalButton: string) => {
    this.setState({
      showModal: true,
      modalTitle: modalHeader,
      modalButton: modalButton,
    });
  };

  onModalClose = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
    });
  };

  render() {
    const user = this.props.currentUser;
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <h6 className="mt-1">My Profile</h6>
          </div>
          <div className="col-md-6 col-sm-6 d-flex flex-row-reverse">
            <form className="form-inline">
              <button
                onClick={() => this.onShowModal("Edit Profile", "Save Changes")}
                type="button"
                className="btn btn-sm btn-glow-primary btn-primary"
              >
                <i className="feather icon-edit"></i>
                <span style={{ fontSize: "10px" }}>Edit Profile</span>
              </button>
            </form>
            <form className="form-inline">
              <button
                onClick={() =>
                  this.onShowModal("Reset Password", "Save Changes")
                }
                type="button"
                className="btn btn-sm btn-glow-primary btn-danger"
              >
                <i className="feather icon-lock"></i>
                <span style={{ fontSize: "10px" }}>Reset Password</span>
              </button>
            </form>
          </div>
        </div>
        <div className="card user-card user-card-2 shape-right">
          <div className="card-header border-0 p-2 pb-0">
            <div className="cover-img-block">
              <img src={bg} className="img-fluid" />
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="user-about-block">
              <div className="row align-items-center">
                <div className="col">
                  <div className="row align-items-center">
                    <div className="col-auto col pr-0">
                      <div className="change-profile">
                        <div className="dropdown">
                          <a className="dropdown-toggle"></a>
                        </div>
                      </div>
                    </div>
                    <div className="col mt-5">
                      <h6 className="mb-1">
                        {user?.firstName} {user?.lastName}
                        {user?.credentials && `, ${user?.credentials}`}
                      </h6>
                      <p className="mb-0">{user?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col col-auto"></div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          handleClose={this.onModalClose}
          show={this.state.showModal}
          heading={this.state.modalTitle}
          button={this.state.modalButton}
        >
          {this.state.modalTitle === "Reset Password" ? (
            <ResetPasswordFormComponent
              button={this.state.modalButton}
              handleClose={this.onModalClose}
            />
          ) : (
            <EditProfileFormComponent
              button={this.state.modalButton}
              handleClose={this.onModalClose}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: { user: { currentUser: User } }) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setCurrentUser: (user: User): { type: string; payload: User } =>
      dispatch(setCurrentUser(user)),
  };
};

export const ProfileComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
