import { FC, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import {
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
  Mic as MicOnIcon,
  MicOff as MicOffIcon,
  CallEnd as CallEndIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

export interface ControlsProps {
  leave: any;
  room?: any;
  toggleMicControl: () => void;
}
export const Controls: FC<ControlsProps> = ({
  room,
  toggleMicControl,
  leave,
}) => {
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  useEffect(() => {
    room.localParticipant.audioTracks.forEach((publication: any) => {
      publication.track.disable();
      setIsMicOn(false);
    });
  }, [setIsMicOn, room]);

  const toggleCamera = () => {
    room.localParticipant.videoTracks.forEach((publication: any) => {
      if (isCameraOn) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
      setIsCameraOn(!isCameraOn);
    });
  };

  const toggleMic = () => {
    room.localParticipant.audioTracks.forEach((publication: any) => {
      if (isMicOn) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
      setIsMicOn(!isMicOn);
      toggleMicControl();
    });
  };
  return (
    <Stack spacing={1} direction="row">
      <div style={{ background: "rgb(255 255 255 / 100%)", borderRadius: 10 }}>
        <IconButton onClick={toggleCamera}>
          {isCameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
        </IconButton>
        <IconButton onClick={toggleMic}>
          {isMicOn ? <MicOnIcon /> : <MicOffIcon />}
        </IconButton>
        <IconButton onClick={leave}>
          <CallEndIcon />
        </IconButton>
      </div>
    </Stack>
  );
};
