import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "rsuite/Button";
import produce from "immer";
import cx from "clsx";

import { Address } from "@/library/types/address";
import { AddressInputs } from "@/components/_inputs/AddressInputs";
import { TextButton } from "@/components/_buttons/TextButton";
import { TextInput } from "@/components/_inputs/TextInput";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { ModeEdit } from "@mui/icons-material";

export type Props = {
  title?: string;
  to?: string;
  address?: Address;
  onChange: (address: Address) => void;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const defaultAddress = {
  street: ["", ""],
  locality: "",
  region: "",
  postalCode: "",
  country: "US",
};

export const AddressPicker: React.FC<Props> = (props) => {
  const { title, to, address = defaultAddress, onChange, onNameChange } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [addressInputs, setAddressInputs] = useState<Address>(address);

  const handleEditPress = () => setIsEditing(true);
  const handleEditCancel = () => setIsEditing(false);

  useEffect(() => {
    setAddressInputs(address);
  }, [address]);

  const handleInputChange = (
    key: keyof Address,
    value: string,
    index?: number
  ) => {
    const nextAddress = produce<Address>(addressInputs, (draft) => {
      if (key === "street" && index !== undefined) {
        draft.street[index] = value;
      } else {
        // @ts-ignore
        draft[key] = value;
      }
    });

    setAddressInputs(nextAddress);
  };

  const handleSubmit = () => {
    onChange(addressInputs);
    setIsEditing(false);
  };

  return (
    <div className={styles.addressPicker}>
      {title ? (
        <h3 className={cx(globalStyles.pageHeading, styles.title)}>{title}</h3>
      ) : null}

      <div className={styles.buttonContainer}>
        {!isEditing ? (
          <button
            type="button"
            onClick={handleEditPress}
            className={cx(globalStyles.buttonReset, styles.editButton)}
          >
            <ModeEdit />
          </button>
        ) : (
          <TextButton onClick={handleEditCancel}>Cancel</TextButton>
        )}
      </div>

      {isEditing ? (
        <>
          <TextInput
            label="Name"
            value={to}
            onChange={onNameChange}
            containerStyle={{ marginBottom: 7 }}
          />

          <AddressInputs
            street={addressInputs?.street || []}
            locality={addressInputs?.locality}
            region={addressInputs?.region}
            postalCode={addressInputs?.postalCode}
            country={addressInputs?.country}
            onChange={handleInputChange}
          />

          <div className={cx(globalStyles.col, styles.editActions)}>
            <Button
              type="button"
              onClick={handleSubmit}
              appearance="primary"
              style={{ paddingLeft: 18, paddingRight: 18 }}
            >
              Update
            </Button>
            <TextButton style={{ marginTop: 10 }} onClick={handleEditCancel}>
              Cancel
            </TextButton>
          </div>
        </>
      ) : (
        <div className={styles.address}>
          {to ? <div className={styles.row}>{to}</div> : null}
          {address.street?.length && address.street[0] ? (
            <div className={styles.row}>{address.street[0]}</div>
          ) : null}
          {address.street?.length && address.street[1] ? (
            <div className={styles.row}>{address.street[1]}</div>
          ) : null}
          {address.locality ? (
            <div
              className={styles.row}
            >{`${address.locality}, ${address.region} ${address.postalCode}`}</div>
          ) : null}
          {address.country !== "US" ? (
            <div className={styles.row}>{address.country}</div>
          ) : null}
        </div>
      )}
    </div>
  );
};
