import React from "react";
import { connect } from "react-redux";

import { Table } from "@/components/table";
import { PaginationConfig } from "@/library/types";
import { Note, NoteAction } from "@/domain/notes/model/types";
import { PatientNoteType } from "@/library/types/note";
import {
  deselectNote,
  selectNote,
  setTargetNoteId,
} from "@/domain/notes/redux/actions";
import {
  getAlertLevelTextColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { SeverityLevel } from "@/domain/notes/model/types";
import { isEmptyArray } from "@/util/isEmptyArray";
import "./table.styles.css";
import { dateFormatter } from "@/pipes/date";
import NoteContent from "../components/note-content";
import { RootState } from "@/types";
import { renderAuthor } from "@/domain/notes/model/helpers";

type TableProps = {
  onOpenReplies: (note: Note) => void;
  headers: string[];
  data: any[];
  execSearch: (query: {}) => any;
  onPageLimitChange: (limit: number) => any;
  pageLimit: number;
  paginationConfig: PaginationConfig;
  selectNote: (note: Note) => { type: string; payload: Note };
  deselectNote: (note: Note) => { type: string; payload: Note };
  selectedNotes: Note[];
  notes: Note[];
  setTargetNoteId: (id: string) => { type: string; payload: string };
  onAddChildNote: (
    replyTo: string,
    noteType: NoteAction,
    replyToIds: string[]
  ) => void;
  noteType: PatientNoteType[];
};

type State = {};

class Component extends React.Component<TableProps, State> {
  constructor(props: TableProps) {
    super(props);
    this.state = {};
  }

  addNote = (note: Note, noteType: NoteAction) => {
    this.props.setTargetNoteId(note.id!);
    this.props.onAddChildNote(note.id!, noteType, [note.id!]);
  };

  getNoteReasonClassName = (note: Note): string => {
    let textColor = "text-link";
    const severityScore = note.severityScore;
    if (!severityScore) {
      return textColor;
    }

    const alertLevel = getSeverityAlertLevel(severityScore);
    textColor = getAlertLevelTextColor(alertLevel);

    return textColor;
  };

  /**
   * Filters out all the `Assessment` and `Observation` notes where severityScore === 0
   * @param notes
   * @returns
   *
   */
  removeObservationInRange = (notes: Note[]) =>
    (notes = notes.filter(
      (note) =>
        (note.type !== PatientNoteType.Observation &&
          note.severityScore !== SeverityLevel.SEVERITY_GREEN) ||
        (note.type !== PatientNoteType.Assessment &&
          note.severityScore !== SeverityLevel.SEVERITY_GREEN)
    ));

  render() {
    const {
      headers,
      data,
      execSearch,
      onPageLimitChange,
      pageLimit,
      paginationConfig,
    } = this.props;

    const headerContents = [
      ...(headers || []).map((content, key) => (
        <th key={key} className="bg-primary text-white small">
          <span className="th-text">{content}</span>
        </th>
      )),
    ];

    const dataContents = (data || [])
      .filter((note) => {
        if (!isEmptyArray(this.props.noteType)) {
          const isSelectedNoteType = this.props.noteType.includes(note.type);
          if (isSelectedNoteType) {
            return note;
          }
        } else {
          return note;
        }
      })
      .map((note) => {
        return (
          <tr key={note.id} className="td-text">
            <td>
              {dateFormatter({
                date: note._meta?.created,
                includeTime: true,
                toLocalTimezone: { enabled: true },
              })}
            </td>
            <td style={{ width: "100px", whiteSpace: "unset" }}>
              <span>
                <NoteContent note={note} />
              </span>
            </td>
            <td>
              {note.author && note.type !== PatientNoteType.PatientRequest
                ? renderAuthor(note.author)
                : "-"}
            </td>
          </tr>
        );
      });

    return (
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headerContents}
        data={dataContents}
        onNavigate={execSearch}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedNotes: state.notes.selectedNotes,
    notes: state.notes.notes,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    selectNote: (note: Note) => dispatch(selectNote(note)),
    deselectNote: (note: Note) => dispatch(deselectNote(note)),
    setTargetNoteId: (id: string): any => dispatch(setTargetNoteId(id)),
  };
};

export const NoteTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
