import React from "react";
import { Formik, Form } from "formik";
import { Toggle } from "rsuite";
import * as Yup from "yup";

import { Select, TextArea, Validation } from "@/components/form";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ResourceType } from "@/library/core/config/resource";
import { NoteModel } from "@/domain/notes/model";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { Note } from "@/domain/notes/model/types";
import { Patient } from "@/domain/patient/model/types";
import { PatientNoteType } from "@/library/types/note";

interface Props {
  onSetFormik: (formik: any) => void;
  replyToIds: string[];
  handleClose: () => void;
  patient: Patient;
  updateParentNoteInReduxIfChild: (note: Partial<Note>) => void;
}

export const AppointmentNoteForm: React.FC<Props> = (props) => {
  const initialValues = {
    patientId: props.patient.id,
    comment: undefined,
    directPatientContact: false,
    location: "",
    replyToIds: props.replyToIds,
    type: PatientNoteType.Appointment,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          patientId: Validation.notRequired,
          comment: Validation.notRequired,
          directPatientContact: Validation.notRequired,
          location: Validation.appointmentLocation,
          replyToIds: Validation.notRequiredArray,
          type: Validation.notRequired,
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await Invoker.make<Note>(
            Receiver.make(
              NoteModel.make(values as Partial<Note> as any, true),
              ResourceType.note,
              RCAResourceActions.Create
            ),
            CleanTask.make(
              () => resetForm(initialValues as Record<string, any>),
              () => props.handleClose(),
              () =>
                props.updateParentNoteInReduxIfChild(values as Partial<Note>)
            ),
            FinallyTask.make(() => setSubmitting(false))
          ).invoke();
        }}
      >
        {(formik) => {
          props.onSetFormik(formik);
          return (
            <Form>
              <div className="modal-body">
                <div className="subject-scroll">
                  <div className="row">
                    <div className="form-group col">
                      <TextArea
                        name="comment"
                        required={false}
                        label="Comment"
                        className="form-control"
                        rows={1}
                        defaultValue={""}
                      />
                    </div>

                    <div className="form-group col">
                      <label>Direct Patient Contact</label>
                      <div>
                        <Toggle
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          onChange={(v) => {
                            formik.setFieldValue("directPatientContact", v);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col">
                      <Select
                        className="form-control"
                        name="location"
                        required
                        label="Location"
                      >
                        <option>Select One</option>
                        <option value={"HOME"}>Home</option>
                        <option value={"OFFICE"}>Office</option>
                        <option value={"TELEMEDICINE"}>Telemedicine</option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
