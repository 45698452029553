import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ModelCollection } from "@/library/model";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Notification } from "@/components/notification/notification";
import { Table } from "@/components/table";
import { Patient } from "@/domain/patient/model/types";
import { PaginationConfig } from "@/library/types";
import {
  DeviceState,
  DeviceTransition,
  KitTableHeader,
  PatientDevice,
  ServiceLevel,
} from "@/domain/kits/models/device.interface";
import { DeviceModel } from "@/domain/kits/models/device.model";
import {
  ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "@/library/constants/index";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { Modal } from "@/components/modal";
import { SortedState } from "@/components/table/head/sortable";
import { SortableHead } from "@/components/table/head/sortableHead.component";
import { ResourceType } from "@/library/core/config/resource";
import {
  dispatchDeselectAllKits,
  dispatchDeselectKit,
  dispatchSelectAllKits,
  dispatchSelectKit,
  dispatchSetKitFilters,
  dispatchSetKitSortedState,
  dispatchUnassignDevice,
  dispatchUnassignLocation,
  dispatchUpdateDevice,
} from "@/domain/kits/redux/actions";
import {
  selectedKitSortedState,
  selectKitFilters,
  selectSelectedKitIds,
} from "@/domain/kits/redux/selectors";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { Str } from "@/util/Str";
import {
  Avatar,
  MenuItem,
  MenuList,
  Tooltip,
  Button,
  Divider,
  Menu,
} from "@mui/material";
import { PracticeModel } from "@/domain/practice/model";
import { SelectOption } from "@/types";
import { EntityType } from "@/domain/kits/view/helper";

type KitManagementTableProps = {
  execSearch: (query?: Record<string, any>) => Promise<void>;
  pageLimit: number;
  onPageLimitChange: (limit: number) => void;
  paginationConfig: PaginationConfig;
  devices: PatientDevice[];
  isRootUser: boolean;
};

const StatusOptions: { label: string; value: string }[] = [
  { label: "All", value: "" },
  { label: "Assigned", value: "assigned" },
  { label: "Available", value: "available" },
  { label: "Dirty", value: "dirty" },
  { label: "Reprocessing", value: "reprocessing" },
];

const ServiceLevelOptions: { label: string; value: string }[] = [
  { label: "All", value: "" },
  { label: ServiceLevel.RemoteCare, value: ServiceLevel.RemoteCare },
  { label: ServiceLevel.RemoteCareLive, value: ServiceLevel.RemoteCareLive },
];

const KitManagementTable: React.FC<KitManagementTableProps> = (props) => {
  const {
    execSearch,
    pageLimit,
    onPageLimitChange,
    paginationConfig,
    isRootUser,
  } = props;
  const kitSortedState = useSelector(selectedKitSortedState);
  const kitFilters = useSelector(selectKitFilters);
  const selectedOrganization = useSelector(selectActiveOrganization);
  const selectedKitIds = useSelector(selectSelectedKitIds);
  const dispatch = useDispatch();

  const [headContents, setHeaderContents]: any = useState();
  const [headers, setHeaders] = useState(KitTableHeader);
  const [statusFilter, setStatusFilter] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    heading: "",
    onContinueClick: () => {},
    text: "",
  });
  const [selectedKitCount, setSelectedKitCount] = useState(0);
  const [practiceOptions, setPracticeOptions] = useState<SelectOption[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<
    SelectOption[]
  >([]);
  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      dispatch(dispatchSelectAllKits());
      setSelectedKitCount(props.devices.length);
    } else {
      dispatch(dispatchDeselectAllKits());
      setSelectedKitCount(0);
    }
  };

  const resetHeaders = () => {
    setHeaders(
      KitTableHeader.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      })
    );
    setHeaderContents(
      <SortableHead
        headers={headers}
        setSortedState={(sortedState) => {
          dispatch(dispatchSetKitSortedState(sortedState));
        }}
        execSearch={execSearch}
        resourceType={ResourceType.kits}
        hasCheckbox={{
          isChecked: selectedKitIds.length === props.devices.length,
          onChange: toggleSelectAll,
        }}
      />
    );
  };

  const fetchPractices = async () => {
    if (selectedOrganization?.id) {
      const practices = await PracticeModel.fetchByOrganizationIds([
        selectedOrganization.id,
      ]);
      const options = practices.map((model: PracticeModel) => {
        return {
          value: model.pluck("id") as string,
          label: model.pluck("name"),
        };
      });
      setPracticeOptions(options);
    }
  };

  const fetchOrganizations = async () => {
    const organizationCollection: ModelCollection<
      OrganizationModel,
      Organization
    > = OrganizationModel.makeOrganizationCollection();
    const limit = 100;
    organizationCollection.withParams({ limit });

    const iterator = await organizationCollection.getMany();
    const allOrganizationModels = await iterator.getAll();
    const options = allOrganizationModels.map((orgModel: OrganizationModel) => {
      return {
        value: `${orgModel.attributes.get("id")}`,
        label: orgModel.attributes.get("name"),
      };
    });
    setOrganizationOptions(options);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    setHeaders(KitTableHeader);
    setHeaderContents(
      <SortableHead
        headers={headers}
        setSortedState={(sortedState) => {
          dispatch(dispatchSetKitSortedState(sortedState));
        }}
        execSearch={execSearch}
        resourceType={ResourceType.kits}
        hasCheckbox={{
          isChecked: selectedKitIds.length === props.devices.length,
          onChange: toggleSelectAll,
        }}
      />
    );
  }, [selectedKitIds]);

  useEffect(() => {
    fetchPractices();
    resetHeaders();
  }, [selectedOrganization]);

  useEffect(() => {
    if (kitSortedState.sortedColumnIndex === undefined) resetHeaders();
  }, [kitSortedState]);

  const handleConfirmationModalClose = () => {
    setConfirmationModal({
      show: false,
      heading: "",
      onContinueClick: () => {},
      text: "",
    });
  };

  const renderKitStatus = (
    assignedUserId: null | string | undefined,
    state: string | null | undefined
  ) => {
    /**
     * state - active && patientId = assigned
     * state - active && !patientId = available
     * !patientId && state - dirty = dirty
     * !patientId && state - reprocessinng = reprocessing
     */
    let status = "";
    status = assignedUserId ? "Assigned" : "Available";

    if (!assignedUserId) {
      status =
        state === DeviceState.Active
          ? "Available"
          : state === DeviceState.Dirty
          ? "Dirty"
          : state === DeviceState.Reprocessing
          ? "Reprocessing"
          : " ";
    }
    return status;
  };

  const renderPatientName = (patient: Patient | undefined) => {
    if (!patient) return "";
    return (
      patient.demographics.legalName.lastName +
      ", " +
      patient.demographics.legalName.firstName +
      " " +
      (patient.demographics.preferredName
        ? `(${patient.demographics.preferredName})`
        : "")
    );
  };

  const onUnassignDevice = (device: PatientDevice) => {
    setConfirmationModal({
      show: true,
      heading: "Unassign Device",
      onContinueClick: () => handleUnAssignDevice(device),
      text: "Are you sure you want to unassign device from the patient?",
    });
  };

  const renderMenuTitle = (device: PatientDevice) => {
    switch (device.lifecycle?.state) {
      case DeviceState.Dirty:
        return "Change to reprocessing";
      case DeviceState.Reprocessing:
        return "Change to available";
      default:
        return null;
    }
  };

  const onChangeDeviceStatus = (device: PatientDevice) => {
    const toState =
      device.lifecycle?.state === DeviceState.Dirty
        ? "reprocessing"
        : "available";
    setConfirmationModal({
      show: true,
      heading: "Change Status",
      onContinueClick: () => handleChangeDeviceStatus(device),
      text: `Are you sure you want to change the device status to ${toState} ?`,
    });
  };

  const handleChangeDeviceStatus = async (device: PatientDevice) => {
    const transition =
      device.lifecycle?.state === DeviceState.Dirty
        ? DeviceTransition.StartReprocess
        : DeviceTransition.FinishReprocess;
    try {
      const response = await Http().post(
        `${RemoteCareAPISync.host}/${DeviceModel.path}/${device.id}/actions/transition`,
        { transition }
      );
      if (response.data) {
        Notification.notify(SUCCESS, "Device Status successfully updated.");
        const updatedDevice = {
          ...device,
          lifecycle: {
            state:
              transition === DeviceTransition.StartReprocess
                ? DeviceState.Reprocessing
                : DeviceState.Active,
          },
        };
        dispatch(dispatchUpdateDevice(updatedDevice));
        handleConfirmationModalClose();
      }
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
      handleConfirmationModalClose();
    } finally {
      execSearch();
    }
  };

  const handleUnAssignDevice = async (device: PatientDevice) => {
    try {
      await DeviceModel.make(device).unassignedDevice();
      Notification.notify(SUCCESS, "Device unassigned successfully.");
      dispatch(dispatchUnassignDevice(device.id!));
      handleConfirmationModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
    } finally {
      execSearch();
    }
  };

  const onMoveDevices = (type: EntityType, id: string, devices: string[]) => {
    setConfirmationModal({
      show: true,
      heading: "Move Device(s)",
      onContinueClick: () => handleMoveDevices(type, id, devices),
      text: `Are you sure you want to move the device(s) to ${
        type === EntityType.Organization ? "Organization" : "Branch"
      } ?`,
    });
  };

  const handleMoveDevices = async (
    type: EntityType,
    id: string,
    deviceIds: string[]
  ) => {
    const deviceArray = [];
    try {
      for (let i = 0; i < deviceIds.length; i++) {
        const deviceId = deviceIds[i];
        const deviceModel = await DeviceModel.sync(deviceId!);
        const device = deviceModel.pluckAll();
        // First check if any device being moved has a location assigned. If yes, abort.
        if (device.tagIds?.length != 0) {
          throw {
            message:
              "One or more of the selected devices have location assigned",
          };
        }
        deviceArray.push(device);
      }
      for (let i = 0; i < deviceArray.length; i++) {
        const device = deviceArray[i];
        const deviceId = `${device.id}`;
        const tagIds = device.tagIds;
        const response = await Http().post(
          `${RemoteCareAPISync.host}/${DeviceModel.path}/${deviceId}/actions/move-device`,
          { type, id }
        );
        //unassign old location from device
        if (response && tagIds.length) {
          await DeviceModel.assignTags(deviceId, {
            delete: tagIds,
          });
          dispatch(dispatchUnassignLocation([deviceId]));
        }
        const updatedDevice = {
          ...device,
          inventory: {
            type: device.inventory ? device.inventory.type : "",
            entity: {
              type: type,
              id: id,
            },
          },
          tags: [],
        };
        dispatch(dispatchUpdateDevice(updatedDevice));
      }
      handleConfirmationModalClose();
      Notification.notify(SUCCESS, "Device(s) successfully moved.");
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else if (error.message) {
        Notification.notify(ERROR, error.message);
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
      handleConfirmationModalClose();
    } finally {
      execSearch();
    }
  };

  const kitActionMap = new Map();
  kitActionMap.set(false, dispatchDeselectKit);
  kitActionMap.set(true, dispatchSelectKit);
  const handleSelectKit = (kitId: string, checked: boolean) => {
    if (checked) setSelectedKitCount(selectedKitCount + 1);
    if (!checked && selectedKitCount > 0)
      setSelectedKitCount(selectedKitCount - 1);
    dispatch(kitActionMap.get(checked)(kitId));
  };
  const subHeader = useMemo(() => {
    return [
      <tr key={"header"}>
        <td></td>
        <td
          className={kitSortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}
        ></td>
        <td
          className={kitSortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
        ></td>
        <td>
          <BasicMultiSelect
            data={ServiceLevelOptions}
            onChange={() => {}}
            findValueBy={kitFilters.serviceLevel || ""}
            isSearchable={false}
            placeholder="All"
            onSelect={async (value: string) => {
              dispatch(
                dispatchSetKitFilters({ ...kitFilters, serviceLevel: value })
              );
            }}
            onClear={() => {
              dispatch(
                dispatchSetKitFilters({ ...kitFilters, serviceLevel: "" })
              );
            }}
          />
        </td>
        <td>
          <BasicMultiSelect
            data={StatusOptions}
            onChange={() => {}}
            findValueBy={kitFilters.status || ""}
            isSearchable={false}
            placeholder={statusFilter || "All"}
            onSelect={async (value: string) => {
              if (value) {
                const status = Str.CapitalizeFirstLetter(value);
                setStatusFilter(status);
              } else {
                setStatusFilter("All");
              }
              dispatch(dispatchSetKitFilters({ ...kitFilters, status: value }));
            }}
            onClear={() => {
              dispatch(dispatchSetKitFilters({ ...kitFilters, status: "" }));
            }}
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>,
    ];
  }, [props.devices, kitSortedState]);
  const data = props.devices.map((device) => (
    <tr key={device.id}>
      <td>
        <input
          type="checkbox"
          checked={selectedKitIds.includes(device.id!)}
          onChange={(e) => handleSelectKit(device.id!, e.target.checked)}
        />
      </td>
      <td
        className={kitSortedState.sortedColumnIndex === 1 ? "sorted_col" : ""}
      >
        {device.msn}
      </td>
      <td
        className={kitSortedState.sortedColumnIndex === 2 ? "sorted_col" : ""}
      >
        {device.iccid}
      </td>
      <td>{device.serviceLevel}</td>
      <td>{renderKitStatus(device.patientId, device.lifecycle?.state)}</td>
      <td>
        {device.inventory?.entity.type === EntityType.Practice
          ? practiceOptions.map((p) => {
              if (p.value === device.inventory?.entity.id) return p.label;
            })
          : ""}
      </td>
      <td>
        {(device.tags || [])
          .filter((tag) => tag.type === "location")
          .map((tag) => tag.value)
          .join(", ")}
      </td>
      <td>{renderPatientName(device.patient)}</td>
      <td>
        <div className="dropleft btn-group">
          <button
            className=""
            style={{ backgroundColor: "transparent", border: "none" }}
            type="button"
            id={`menu-${device.id}`}
            data-toggle="dropdown"
            data-boundary="window"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="feather icon-more-horizontal"></i>
          </button>
          <div
            className="dropdown-menu dropdown-content hide-scroll"
            aria-labelledby={`menu-${device.id}`}
          >
            {selectedKitCount > 1 && (
              <span>
                <MenuItem sx={{ fontSize: 11, padding: "0.25rem 1.5rem" }}>
                  <Avatar
                    sx={{
                      backgroundColor: "#013fa5",
                      marginRight: 0.5,
                      width: 24,
                      height: 24,
                    }}
                  >
                    <span style={{ fontSize: 12 }}>{selectedKitCount}</span>
                  </Avatar>
                  SELECTED
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
              </span>
            )}
            {device.patientId && (
              <span
                data-toggle="tooltip"
                title={
                  selectedKitCount > 1
                    ? "Action not supported for multiple devices"
                    : ""
                }
              >
                <a
                  className={`dropdown-item ${
                    selectedKitCount > 1 ? "disabled" : ""
                  }`}
                  href="#"
                  style={{ fontSize: "11px" }}
                  onClick={() => onUnassignDevice(device)}
                >
                  Unassign Device
                </a>
              </span>
            )}
            {device.lifecycle &&
              device.lifecycle?.state !== DeviceState.Active && (
                <span
                  data-toggle="tooltip"
                  title={
                    selectedKitCount > 1
                      ? "Action not supported for multiple devices"
                      : ""
                  }
                >
                  <a
                    className={`dropdown-item ${
                      selectedKitCount > 1 ? "disabled" : ""
                    }`}
                    href="#"
                    style={{ fontSize: "11px" }}
                    onClick={() => onChangeDeviceStatus(device)}
                  >
                    {renderMenuTitle(device)}
                  </a>
                </span>
              )}
            {!device.patientId &&
              device.lifecycle &&
              device.lifecycle.state === DeviceState.Active && (
                <div className="dropdown-submenu">
                  <a
                    className="dropdown-item"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-boundary="window"
                    style={{ fontSize: "11px" }}
                  >
                    Move Device
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {isRootUser && (
                      <div className="dropdown-submenu">
                        <a
                          className="dropdown-item"
                          href="#"
                          role="button"
                          id="dropdownSubMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-boundary="window"
                          style={{ fontSize: "11px" }}
                        >
                          Move to Organization
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownSubMenuLink"
                        >
                          <ul>
                            {organizationOptions.map((option) => (
                              <li key={option.value}>
                                <a
                                  style={{ fontSize: "11px" }}
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    const deviceIds: string[] = [];
                                    deviceIds.push(device.id!);
                                    if (selectedKitIds.length) {
                                      selectedKitIds.map((id) => {
                                        if (id !== device.id) {
                                          deviceIds.push(id);
                                        }
                                      });
                                    }
                                    onMoveDevices(
                                      EntityType.Organization,
                                      option.value,
                                      deviceIds
                                    );
                                  }}
                                >
                                  {option.label}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className="dropdown-submenu">
                      <a
                        className="dropdown-item"
                        href="#"
                        role="button"
                        id="dropdownSubMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-boundary="window"
                        style={{ fontSize: "11px" }}
                      >
                        Move to Branch
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownSubMenuLink"
                      >
                        <ul>
                          {practiceOptions.map((option) => (
                            <li key={option.value}>
                              <a
                                style={{ fontSize: "11px" }}
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  const deviceIds: string[] = [];
                                  deviceIds.push(device.id!);
                                  if (selectedKitIds.length) {
                                    selectedKitIds.map((id) => {
                                      if (id !== device.id) {
                                        deviceIds.push(id);
                                      }
                                    });
                                  }
                                  onMoveDevices(
                                    EntityType.Practice,
                                    option.value,
                                    deviceIds
                                  );
                                }}
                              >
                                {option.label}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </td>
    </tr>
  ));

  const tableData = subHeader.concat(data);

  return (
    <>
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headContents}
        data={tableData}
        onNavigate={execSearch}
      />
      <Modal
        handleClose={handleConfirmationModalClose}
        show={confirmationModal.show}
        heading={confirmationModal.heading}
        button={""}
        size="xs"
      >
        <div className="p-3">
          <p className="lead">{confirmationModal.text}</p>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={handleConfirmationModalClose}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={confirmationModal.onContinueClick}
            className="btn btn-primary mr-2"
          >
            Continue
          </button>
        </div>
      </Modal>
    </>
  );
};

export default KitManagementTable;
