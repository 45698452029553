import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "rsuite";
import { DateTime } from "luxon";
import { RowSelectionState } from "@tanstack/react-table";
import { keyBy } from "lodash";

import { Figure } from "@/domain/patient/view/Figure";
import { BloodPressureChart } from "@/domain/patient/view/charts/BloodPressureChart";
import { HeartRateChart } from "@/domain/patient/view/charts/HeartRateChart";
import { TemperatureChart } from "@/domain/patient/view/charts/TemperatureChart";
import { WeightChart } from "@/domain/patient/view/charts/WeightChart";
import { GlucometryChart } from "@/domain/patient/view/charts/GlucometryChart";
import { PulseOximetryChart } from "@/domain/patient/view/charts/PulseOximetryChart";
import { SpirometryChart } from "@/domain/patient/view/charts/SpirometryChart";
import { AggregateTable } from "@/domain/patient/view/tables/AggregateTable";
import { PatientObservation } from "@/domain/patient/model/types";
import { ObservationType } from "@/domain/observations/types";
import { DateRangePicker } from "@/components/_inputs/DateRangePicker";
import { PracticeModel } from "@/domain/practice/model";
import { ProviderModel } from "@/domain/provider/model";
import {
  dispatchSetMonitoringStartDate,
  dispatchSetMonitoringEndDate,
  dispatchToggleMonitoringTabVisibility,
  dispatchUpdatePatient,
} from "@/domain/patient/redux/actions";
import { ObservationTypes } from "@/domain/patient/model/constants";
import {
  selectFirstPatient,
  selectMonitoringEndDate,
  selectMonitoringStartDate,
  selectMonitoringTabVisibility,
  selectNoteCreatedOn,
} from "@/domain/patient/redux/selectors";
import { selectRulesForPatient } from "@/domain/rules/redux/selectors";
import {
  selectNotesForPatient,
  selectObservationsFromPatientNotes,
} from "@/domain/notes/redux/selectors";
import { selectAggregateTableData } from "@/domain/patient/view/tables/AggregateTable/selectors";
import {
  dispatchAddRejectedNoteId,
  resolveNotes,
  setPatientNotes,
  setUnresolvedPatientNotes,
} from "@/domain/notes/redux/actions";
import { ClinicalNoteForm } from "@/domain/patient/view/forms/ClinicalNoteForm";
import { NoteDisposition } from "@/domain/notes/model/types";
import { NoteDispositions } from "@/domain/notes/model/constants";
import { AlertsTable } from "@/domain/patient/view/AlertsTable";
import { selectAlertsTableData } from "@/domain/patient/view/AlertsTable/selectors";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import { PostSetParameterOverrides } from "@/domain/organization/model/types";
import { syncPatient } from "@/domain/patient/view/PatientDetails/helpers";
import { PatientModel } from "@/domain/patient/model";
import { RuleModel } from "@/domain/rules/model";
import { dispatchSetPatientRules } from "@/domain/rules/redux/actions";

import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { TextButton } from "@/components/_buttons/TextButton";
import { Notification } from "@/components/notification/notification";

import { RootState } from "@/types";
import { DAYS_LIMIT, ERROR } from "@/library/constants";

import {
  biometryEntryMethod,
  exportPatientDetail,
  getAllNotes,
  getUnresolvedNotes,
  renderActions,
} from "./helpers";
import { Props } from "./types";

import styles from "./styles.module.scss";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { ArrowForwardSharp } from "@mui/icons-material";

export const MonitoringTab: React.FC<Props> = (props) => {
  const [providerName, setProviderName] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [showChart, setShowChart] = useState(true);
  const [selectedRows, setSelectedRows] = useState<AlertTableRow[]>([]);
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [replyToIds, setReplyToIds] = useState<string[]>([]);
  const [disposition, setDisposition] = useState<NoteDisposition>();

  const alertsTableRef = useRef<{ reset: () => void }>(null);
  const noteFormRef = useRef<{ submit: () => void }>(null);

  const patient = useSelector(selectFirstPatient);
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const aggregateData = useSelector(selectAggregateTableData);
  const alertsData = useSelector(selectAlertsTableData);
  const tabVisibility = useSelector(selectMonitoringTabVisibility);
  const noteCreatedOn = useSelector(selectNoteCreatedOn);

  // Required to accept alert to set baseline weight
  const rules = useSelector(selectRulesForPatient(patient?.id));
  const rulesbyType = rules ? keyBy(rules, "trigger.observationType") : null;
  const weightRuleId = rulesbyType ? rulesbyType?.WEIGHT?.id : null;

  let baselineWeightAlertExists = false;
  let baselineWeightAlert: any = null;
  alertsData.forEach((data) => {
    if (
      !baselineWeightAlertExists &&
      data.type === "WEIGHT" &&
      data.comment.includes("Accept as baseline weight?")
    ) {
      baselineWeightAlertExists = true;
      baselineWeightAlert = data;
    }
  });

  const { onlyCharts } = props;

  const dispatch = useDispatch();
  const addRejectedNoteId = (noteId: string) =>
    dispatch(dispatchAddRejectedNoteId(noteId));

  const startDate = DateTime.fromISO(isoStartDate).startOf("day").toJSDate();
  const endDate = DateTime.fromISO(isoEndDate).endOf("day").toJSDate();
  const startMax = DateTime.now()
    .minus({ days: DAYS_LIMIT - 1 })
    .toJSDate();
  const endMax = DateTime.now().toJSDate();
  const now = DateTime.now();

  const { id: patientId = "", practiceId, providerId } = patient || {};
  const observations =
    useSelector<RootState, PatientObservation[]>(
      selectObservationsFromPatientNotes
    ) || [];

  useEffect(() => {
    const init = async () => {
      if (!patient || !practiceId || !providerId) return;

      LoadingIndicator.fire.show();
      const practiceModel: PracticeModel = await PracticeModel.sync(practiceId);
      const providerModel: ProviderModel = await ProviderModel.sync(providerId);
      setProviderName(
        `${providerModel.pluckAll().firstName} ${
          providerModel.pluckAll().lastName
        }`
      );
      setPracticeName(practiceModel.pluckAll().name);
      LoadingIndicator.fire.hide();
    };

    init();
  }, [patientId]);

  const fetchNotes = async (startDate: Date, endDate: Date) => {
    let allNotes;
    let unresolvedNotes;

    try {
      allNotes = await getAllNotes(patientId, startDate, endDate);
      unresolvedNotes = await getUnresolvedNotes(patientId, startDate, endDate);
    } catch (error: Error | unknown) {
      console.error(error);
    }

    if (allNotes) dispatch(setPatientNotes(patientId, allNotes));
    if (unresolvedNotes)
      dispatch(setUnresolvedPatientNotes(patientId, unresolvedNotes));
  };

  useEffect(() => {
    if (patientId) fetchNotes(startDate, endDate);
  }, [patientId]);

  useEffect(() => {
    if (noteCreatedOn) setTimeout(() => fetchNotes(startDate, endDate), 500);
  }, [noteCreatedOn]);

  useEffect(() => {
    // Reset table selection
    if (alertsTableRef.current) alertsTableRef.current.reset();
    setSelectedRows([]);
  }, [isoStartDate, isoEndDate]);

  useEffect(() => {
    const fetchRules = async () => {
      if (!patientId) return;
      const ruleModels =
        (await RuleModel.fetchByParams({
          limit: "100",
        })) || [];
      const rules = ruleModels.map((m) => m.pluckAll());
      dispatch(dispatchSetPatientRules(patientId, rules));
    };
    fetchRules();
  }, [patientId]);

  if (!patient) return null;

  const patientDetail = exportPatientDetail({
    patient,
    provider: providerName,
    practice: practiceName,
    startDate,
    endDate,
  });

  const handleToggleChart = () => {
    setShowChart(!showChart);
  };
  const handleToggleFigure = (tab: ObservationType) => () => {
    dispatch(dispatchToggleMonitoringTabVisibility(tab));
  };
  const handleStartDateChange = async (startDate: Date) => {
    const start = DateTime.fromJSDate(startDate).toJSDate();
    const end = DateTime.fromJSDate(start)
      .plus({ days: DAYS_LIMIT - 1 })
      .endOf("day")
      .toJSDate();
    fetchNotes(start, end);
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };
  const handleEndDateChange = async (endDate: Date) => {
    const end = DateTime.fromJSDate(endDate).toJSDate();
    const start = DateTime.fromJSDate(end)
      .minus({ days: DAYS_LIMIT - 1 })
      .startOf("day")
      .toJSDate();
    fetchNotes(start, end);
    dispatch(dispatchSetMonitoringEndDate(end.toISOString()));
  };
  const handlePrevClick = () => {
    const start = DateTime.fromISO(isoStartDate)
      .minus({ days: DAYS_LIMIT })
      .toJSDate();
    const end = DateTime.fromJSDate(start)
      .plus({ days: DAYS_LIMIT - 1 })
      .endOf("day")
      .toJSDate();
    fetchNotes(start, end);
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };
  const handleNextClick = () => {
    const start = DateTime.fromISO(isoStartDate)
      .plus({ days: DAYS_LIMIT })
      .toJSDate();
    const end = DateTime.fromJSDate(start)
      .plus({ days: DAYS_LIMIT - 1 })
      .endOf("day")
      .toJSDate();
    fetchNotes(start, end);
    dispatch(dispatchSetMonitoringStartDate(start.toISOString()));
  };

  const acceptBaselineWeightAlert = async (ruleId: string, value: any) => {
    const setData: PostSetParameterOverrides = {
      ruleId: ruleId,
      variables: {
        baselineWeight: value,
        isEnabled: true,
      },
    };
    let fetchError: Error;
    try {
      await PatientModel.setParameterOverrides(patientId, setData);
    } catch (err: Error | unknown) {
      fetchError = err as Error;
      Notification.notify(ERROR, (err as Error).message, "", 2000);
    }
  };

  const handleAction =
    (disposition: NoteDisposition) =>
    (rowSelection: RowSelectionState) =>
    () => {
      const selectedRows = alertsData.filter(
        (_row: AlertTableRow, index: number) => {
          return rowSelection[index] === true;
        }
      );
      setDisposition(disposition);
      setSelectedRows(selectedRows);
      setReplyToIds(selectedRows.map((r) => r.noteId));
      setShowCreateNoteModal(true);
    };

  const handleCloseModal = () => {
    setShowCreateNoteModal(false);
  };
  const handleSubmitModal = () => {
    if (noteFormRef.current) {
      noteFormRef.current.submit();
      // check if baseline weight alert is being accepted
      if (
        baselineWeightAlertExists &&
        weightRuleId &&
        baselineWeightAlert &&
        disposition === NoteDisposition.Accepted
      ) {
        acceptBaselineWeightAlert(
          weightRuleId,
          baselineWeightAlert.readingValue
        );
      }
    }
  };
  const handleModalComplete = async () => {
    // Reset selections
    if (alertsTableRef.current) alertsTableRef.current.reset();
    const noteIds = selectedRows.map((row) => row.noteId);
    dispatch(resolveNotes(patient!.id!, noteIds, selectedRows));

    if (disposition === NoteDispositions.Rejected) {
      for (let i = 0; i < selectedRows.length; i++) {
        if (selectedRows[i].noteId) addRejectedNoteId(selectedRows[i].noteId);
      }
    }
    const updatedPatient = await syncPatient(patientId);
    dispatch(dispatchUpdatePatient(updatedPatient));
    setSelectedRows([]);
  };

  const handleChangeDisposition = (val: NoteDisposition) => {
    setDisposition(val);
  };

  const renderCharts = () => {
    if (!showChart) return null;

    if (!observations.length) {
      return <div className="mt-4 mx-2">No data to display</div>;
    }

    return (
      <div>
        <Figure
          title={ObservationType.HeartRate}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.HeartRate]
          }
          onToggle={handleToggleFigure(ObservationTypes.HeartRate)}
        >
          <HeartRateChart
            heartRates={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.HeartRate &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          title={ObservationType.BloodPressure}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.BloodPressure]
          }
          onToggle={handleToggleFigure(ObservationTypes.BloodPressure)}
        >
          <BloodPressureChart
            bloodPressures={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.BloodPressure &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          title={ObservationType.PulseOximetry}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.PulseOximetry]
          }
          onToggle={handleToggleFigure(ObservationTypes.PulseOximetry)}
        >
          <PulseOximetryChart
            pulseOximetries={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.PulseOximetry &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          title={ObservationType.Weight}
          visible={onlyCharts ? true : tabVisibility[ObservationTypes.Weight]}
          onToggle={handleToggleFigure(ObservationTypes.Weight)}
        >
          <WeightChart
            weights={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.Weight &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          title={ObservationType.GlucoseLevel}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.GlucoseLevel]
          }
          onToggle={handleToggleFigure(ObservationTypes.GlucoseLevel)}
        >
          <GlucometryChart
            glucoseLevels={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.GlucoseLevel &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          title={ObservationType.Temperature}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.Temperature]
          }
          onToggle={handleToggleFigure(ObservationTypes.Temperature)}
        >
          <TemperatureChart
            temperatures={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.Temperature &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>

        <Figure
          isLast
          title={ObservationType.Spirometry}
          visible={
            onlyCharts ? true : tabVisibility[ObservationTypes.Spirometry]
          }
          onToggle={handleToggleFigure(ObservationTypes.Spirometry)}
        >
          <SpirometryChart
            spirometries={observations.filter(
              (observation: PatientObservation) =>
                observation.type === ObservationTypes.Spirometry &&
                (observation.source !== biometryEntryMethod.ClinicianEntry ||
                  (observation.manualNoteId &&
                    observation.source === biometryEntryMethod.ClinicianEntry))
            )}
            patientDetail={patientDetail}
          />
        </Figure>
      </div>
    );
  };

  const renderTables = () => {
    if (showChart) return null;

    return (
      <div className={styles.aggregateContainer}>
        <AggregateTable data={aggregateData} />
      </div>
    );
  };

  const renderCreateNoteModal = () => {
    return (
      <Modal
        open={showCreateNoteModal}
        onClose={handleCloseModal}
        size="lg"
        enforceFocus
        backdrop="static"
        className={styles.modal}
      >
        <Modal.Header>
          <Modal.Title>Create Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClinicalNoteForm
            ref={noteFormRef}
            patient={patient}
            disposition={disposition}
            replyToIds={replyToIds}
            replyToRows={selectedRows}
            handleClose={handleCloseModal}
            onDispositionChange={handleChangeDisposition}
            updateParentNoteInReduxIfChild={handleModalComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} appearance="subtle">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmitModal}
            appearance="primary"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const nextDisabled = now.toISODate() === isoEndDate;

  return (
    <>
      <div className={styles.header}>
        <Button
          onClick={handleToggleChart}
          className={styles.toggleButton}
          appearance="primary"
        >
          {showChart ? "Show Table" : "Show Graphs"}
        </Button>
        <div className={styles.headerRight}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            startMax={startMax}
            endMax={endMax}
            onStartChange={handleStartDateChange}
            onEndChange={handleEndDateChange}
            containerClassName={styles.dateRangeContainer}
          />
          <div className={styles.prevNextControls}>
            <TextButton
              onClick={handlePrevClick}
              iconLeft={<ArrowBackSharpIcon />}
            >
              Prev
            </TextButton>
            <TextButton
              iconRight={<ArrowForwardSharp />}
              onClick={handleNextClick}
              disabled={nextDisabled}
            >
              Next
            </TextButton>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <AlertsTable
            ref={alertsTableRef}
            data={alertsData}
            renderActions={renderActions(
              handleAction(NoteDispositions.Accepted),
              handleAction(NoteDispositions.Rejected)
            )}
          />
        </div>
        <div className={styles.right}>
          <div id="monitoring-charts">{renderCharts()}</div>
          {renderTables()}
        </div>
      </div>

      {renderCreateNoteModal()}
    </>
  );
};
