import { PracticeId } from "./types";
import {
  CREATE_PRACTICE,
  DESELECT_ALL_PRACTICES,
  DESELECT_PRACTICE,
  SELECT_ALL_PRACTICES,
  SELECT_PRACTICE,
  SET_PRACTICES,
  SET_PRACTICES_PAGINATIONCONFIG,
  SET_PRACTICE_PAGE_LIMIT,
  SET_TARGET_PRACTICE,
  SET_PRACTICE_FILTERS,
  SET_PRACTICE_SORTED_STATE,
  SET_ACTIVE_PRACTICE,
  UNSET_ACTIVE_PRACTICE,
} from "@/library/constants";
import { PaginationConfig } from "@/library/types";
import { Practice, PracticeFilters } from "../model/types";
import { PracticeSortedState } from "./types";
import { UPDATE_PRACTICE } from "@/library/constants";

export const dispatchCreatePractice = (practice: Practice) => ({
  type: CREATE_PRACTICE,
  payload: practice,
});

export const dispatchSetPracticeSortedState = (
  payload: PracticeSortedState
) => {
  return {
    type: SET_PRACTICE_SORTED_STATE,
    payload: payload,
  };
};

export const dispatchSetPractices = (practices: Practice[]) => ({
  type: SET_PRACTICES,
  payload: practices,
});

export const dispatchSelectPractice = (practice: Practice) => ({
  type: SELECT_PRACTICE,
  payload: practice,
});

export const dispatchDeselectPractice = (practice: Practice) => ({
  type: DESELECT_PRACTICE,
  payload: practice,
});

export const dispatchSetActivePractice = (practice: Practice) => ({
  type: SET_ACTIVE_PRACTICE,
  payload: practice,
});

export const dispatchUnsetActivePractice = (practice: Practice) => ({
  type: UNSET_ACTIVE_PRACTICE,
  payload: practice,
});

export const dispatchSelectAllPractices = () => ({
  type: SELECT_ALL_PRACTICES,
});

export const dispatchDeselectAllPractices = () => ({
  type: DESELECT_ALL_PRACTICES,
});

export const dispatchSetTargetPractice = (id: PracticeId) => ({
  type: SET_TARGET_PRACTICE,
  payload: id,
});

export const dispatchSetPracticesPaginationConfig = (
  paginationConfig: PaginationConfig
): { type: string; payload: PaginationConfig } => ({
  type: SET_PRACTICES_PAGINATIONCONFIG,
  payload: paginationConfig,
});

export const dispatchSetPracticePageLimit = (
  practicePageLimit: number
): { type: string; payload: number } => ({
  type: SET_PRACTICE_PAGE_LIMIT,
  payload: practicePageLimit,
});

export const dispatchSetPracticeFilters = (
  practiceFilters: PracticeFilters
): { type: string; payload: PracticeFilters } => ({
  type: SET_PRACTICE_FILTERS,
  payload: practiceFilters,
});

export const dispatchUpdatePractcie = (practice: Practice) => ({
  type: UPDATE_PRACTICE,
  payload: practice,
});
