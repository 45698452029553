import { TextInput } from "@/components/form";
import { Button } from "rsuite";
import { SupportiveInformation, Organization } from "../../model/types";
import React, { useState } from "react";
import { OrganizationModel } from "../../model/index";
import { Notification } from "@/components/notification/notification";
import { SUCCESS, ERROR } from "@/library/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@/domain/user/redux/selectors";
import { Guard } from "@/library/guard/Guard";
import { UserParentTypes } from "@/domain/user/model";
import { setOrganizations } from "@/domain/organization/redux/actions";
import { RootState } from "@/types";

type Props = {
  supportInformation: SupportiveInformation | undefined;
  organization: Organization;
};

export const OrganizationSupportInformation: React.FC<Props> = ({
  supportInformation,
  organization,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const organizations = useSelector<RootState, Organization[]>(
    (state) => state.organization.organizations
  );
  const [customerNumber, setCustomerNumber] = useState(
    supportInformation?.customerNumber || ""
  );
  const [fulfillment, setFulfillment] = useState(
    supportInformation?.defaultFulfillmentModel || ""
  );
  const [enableUpdateBtn, setEnableUpdateBtn] = useState<boolean>(false);

  const handleUpdateSupportInformation = async () => {
    setEnableUpdateBtn(false);
    try {
      const supportiveInformation = {
        customerNumber: customerNumber === "" ? null : customerNumber,
        defaultFulfillmentModel: (fulfillment === ""
          ? null
          : fulfillment) as any,
      } as SupportiveInformation;

      await OrganizationModel.updateSupportInformation(
        organization.id!,
        supportiveInformation
      );
      //update redux state
      const targetedOrganization = { ...organization };
      const updatedOrganizations = organizations.map((org) => {
        if (org.id === targetedOrganization.id) {
          org.supportInformation = supportiveInformation;
        }
        return org;
      });
      dispatch(setOrganizations(updatedOrganizations));
      Notification.notify(
        SUCCESS,
        "Supportive Information updated successfully."
      );
    } catch (e) {
      Notification.notify(ERROR, "Something went wrong.");
    }
  };

  const isRootUser = Guard.accessLevel(UserParentTypes.Admin).canActivate(
    currentUser!
  );
  if (!isRootUser) {
    return <></>;
  }

  return (
    <>
      <div className="form-group col-md-3">
        <TextInput
          label="Customer Number"
          name="customerNumber"
          type="text"
          placeholder="Customer Number"
          required={false}
          defaultValue={customerNumber}
          onChange={(e: any) => {
            const val = e.target.value || "";
            setCustomerNumber(val);
            setEnableUpdateBtn(true);
          }}
        />
      </div>
      <div className="form-group col-md-3">
        <div className="d-flex">
          <label>Fulfillment Model</label>
        </div>
        <select
          className="form-control"
          defaultValue={fulfillment}
          onChange={(e: any) => {
            const val = e.target.value || "";
            setFulfillment(val);
            setEnableUpdateBtn(true);
          }}
        >
          <option value="">None</option>
          <option value="OWNED">Owned</option>
          <option value="LEASED">Leased</option>
        </select>
      </div>
      <div className="form-group col-md-3">
        <Button
          appearance="primary"
          disabled={!enableUpdateBtn}
          onClick={handleUpdateSupportInformation}
          size="lg"
          className="mt-4"
        >
          Update Support Information
        </Button>
      </div>
    </>
  );
};
