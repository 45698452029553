import { Constructor } from "../../types/constructor";
import { flattenToObject } from "../helper";
import { Policy } from "../policy.interface";
import { ScopeBuilder } from "../scopeBuilders/scopeBuilder.interface";
import { PolicyBuilder } from "./policyBuilder.interface";

export class PracticeUserPolicyBuilder extends PolicyBuilder {
  async build(): Promise<Policy> {
    return {
      [this.scope.practice.build(this.practiceId)]: this.scopeValue,
      [this.scope.organization.build(this.organizationId)]: this.scopeValue,
    };
  }
}
