import InputMask from "react-input-mask";
import { useField } from "formik";
import cx from "clsx";

import globalStyles from "@/styles/globals.module.scss";
import "./../form.styles.css";

export const TextInput = ({ label, required, hasError = false, ...props }) => {
  const [field, meta] = useField(props);
  const isValid = !hasError && !(meta.touched && meta.error);

  return (
    <>
      <div style={{ display: "flex" }}>
        <label
          className={cx(
            required ? "required-field" : "",
            !isValid && globalStyles.danger
          )}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        {props.labelsupport && (
          <span
            style={{
              flex: 1,
              textAlign: "right",
              whiteSpace: "nowrap",
              fontStyle: "italic",
              fontWeight: "600",
            }}
          >
            {props.labelsupport}
          </span>
        )}
      </div>
      <input
        {...field}
        {...props}
        className={`form-control ${!isValid && "invalid-input"} ${
          props.className
        }`}
      />
      {!isValid ? (
        <div className="invalid-input-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const TextInputMask = ({
  label,
  hasError = false,
  required = false,
  onChange,
  mask,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isValid = !hasError && !(meta.touched && meta.error);

  return (
    <>
      <label
        className={cx(
          required ? "required-field" : "",
          !isValid && globalStyles.danger
        )}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <InputMask
        value={props.value}
        mask={mask}
        onChange={onChange}
        placeholder={props?.placeholder ? props.placeholder : ""}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            className={cx("form-control", !isValid && "invalid-input")}
          />
        )}
      </InputMask>
      {props.supporting && props.supporting}
      {!isValid ? (
        <div className="invalid-input-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const TextArea = ({ label, required, hasError = false, ...props }) => {
  const [field, meta] = useField(props);
  const isValid = !hasError && !(meta.touched && meta.error);

  return (
    <>
      <div style={{ display: "flex" }}>
        <label
          className={cx(
            required ? "required-field" : "",
            !isValid && globalStyles.danger
          )}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        {props.labelsupport && (
          <span
            style={{
              flex: 1,
              textAlign: "right",
              whiteSpace: "nowrap",
              fontStyle: "italic",
              fontWeight: "600",
            }}
          >
            {props.labelsupport}
          </span>
        )}
      </div>
      <textarea
        className={`form-control ${!isValid && "invalid-input"} `}
        {...field}
        {...props}
      />
      {!isValid ? (
        <div className="invalid-input-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};
