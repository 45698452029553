import React, { useEffect, useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  chartConstants,
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import {
  biometryEntryMethod,
  parseTooltipDate,
} from "@/domain/patient/view/tabsNew/monitoring/helpers";
import {
  getAlertLevelColor,
  getAlertLevelColorBG,
  getSeverityAlertLevel,
  getSeverityAlertLevelNew,
} from "@/domain/notes/model/constants";
import { useSelector } from "react-redux";
import {
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";
import { sortObservationArrayByDate } from "../helper";
import { NoDataAvailable } from "../../tabsNew";
import { ChartProps } from "../types";
import { TIMEOUT_DATE_CHANGE } from "../../tabsNew/monitoring/constants";
import { makeChartOptionsNew } from "../constants";

export const HeartRateChart: React.FC<ChartProps> = (props) => {
  const { observationData, patientDetail } = props;
  const heartRates = observationData;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [allowChartUpdate, setAllowChartUpdate] = useState(false);

  useEffect(() => {
    setAllowChartUpdate(true);
    window.setTimeout(() => {
      setAllowChartUpdate(false);
    }, TIMEOUT_DATE_CHANGE);
  }, [isoStartDate, isoEndDate]);

  const [isPrinting, setIsPrinting] = useState(false);
  let heartRateAverage = 0;
  let heartRateAggregate = 0;
  heartRates.map((heartRate) => {
    heartRateAggregate += heartRate.value ?? 0;
  });
  heartRateAverage = heartRateAggregate / heartRates.length;
  sortObservationArrayByDate(heartRates);
  const chartConfig = useMemo(() => {
    return makeChartOptionsNew({
      chart: {
        spacingLeft: 10,
        spacingRight: 10,
        marginTop: 40,
      },
      yTitle: "",
      events: {
        beforePrint: () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        valueSuffix: ObservationsUnitAbbrevation.HEART_RATE,
        formatter: function (this: TooltipFormatterContextObject) {
          let point = this.points ? this.points[0] : this.point;
          return parseTooltipDate(
            ObservationType.HeartRate,
            this,
            ObservationsUnitAbbrevation.HEART_RATE,
            "",
            point.series?.userOptions
          );
        },
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
        labels: {
          format: "{value:%m/%d}",
        },
        gridLineWidth: 1,
      },
      yAxis: {
        gridLineDashStyle: "longdash",
        plotLines: [
          {
            color: "#1A91FF",
            value: heartRateAverage,
            width: "1",
            zIndex: 4,
          },
        ],
      },
      series: [
        {
          name: "Heart Rate",
          color: chartConstants.COLOR.Line,
          data: heartRates.map((hr) => {
            const { severityScore } = hr;
            const source = hr?.source ?? null;
            return {
              x: Date.parse(
                new Date(
                  hr.timeOfObservation ? hr.timeOfObservation : hr._meta.created
                ).toISOString()
              ),
              y: hr.value,
              marker: {
                fillColor: getAlertLevelColorBG(
                  getSeverityAlertLevelNew(severityScore)
                ),
              },
              source,
            };
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
    });
  }, [heartRates, patientDetail, isPrinting]);

  return (
    <div>
      {heartRates.length ? (
        <LineChart
          containerProps={{ id: "heart-rate-chart", className: "graphChart" }}
          options={chartConfig.options}
          allowChartUpdate={allowChartUpdate}
        />
      ) : (
        <NoDataAvailable className="noDataAvailable" />
      )}
    </div>
  );
};

export default HeartRateChart;
