import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { extractphone, formatPhone } from "@/components/form";
import { Modal } from "@/components/modal";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { PatientId } from "@/domain/patient/redux/types";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
} from "@/domain/patient/redux/actions";
import InterdisciplinaryFormComponent from "./form/interdisciplinaryForm.component";
import { InterdisciplinaryForm } from "./model/interdisciplinary";

interface Props {
  patientId: PatientId;

  selectedPatient: Patient[];
  setSelectedPatient: (patient: Patient) => void;
  deselectAllPatients: () => { type: string };
}

export const Interdisciplinary = (props: Props) => {
  const { patientId } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState(
    "Add Interdisciplinary Team Member"
  );
  const [modalBtn, setModalBtn] = useState("Add");
  const [interdisciplinaryTeamMember, setInterdisciplinaryTeamMember]: any =
    useState([]);
  const [targetedTeamMember, setTargetedTeamMember]: any = useState(null);
  const [targetedTeamMemberId, setTargetedTeamMemberId]: any = useState(null);

  const handleSubmit = async (
    member: InterdisciplinaryForm,
    editMode: boolean
  ): Promise<void> => {
    const patientModel = await PatientModel.sync(patientId!);
    if (editMode) {
      interdisciplinaryTeamMember[targetedTeamMemberId] = member;
    }
    try {
      await patientModel.modifyPatient<Patient, "interdisciplinaryTeam">({
        interdisciplinaryTeam: parseValues(member, editMode),
      });
      member.phoneNumber = member.phoneNumber
        ? extractphone(member.phoneNumber)
        : null;
      member.faxNumber = member.faxNumber
        ? extractphone(member.faxNumber)
        : null;
      if (!editMode) {
        interdisciplinaryTeamMember && interdisciplinaryTeamMember.length > 0
          ? setInterdisciplinaryTeamMember([
              ...interdisciplinaryTeamMember,
              member,
            ])
          : setInterdisciplinaryTeamMember([member]);
      }
      setInterdisciplinaryTeamMember(
        (updatedTeamMember: InterdisciplinaryForm[]) => {
          updatePatientInterdisciplinaryState(updatedTeamMember);
          return updatedTeamMember;
        }
      );
      setShowModal(false);
      Notification.notify(
        SUCCESS,
        "Interdisciplinary Team Member updated successfully."
      );
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  const updatePatientInterdisciplinaryState = (
    updatedTeam: InterdisciplinaryForm[]
  ): void => {
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      props.selectedPatient.forEach((patient: Patient) => {
        if (patient.id === patientId) {
          patient.interdisciplinaryTeam = updatedTeam;
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }
      });
    }
  };

  const parseValues = (
    teamMember: InterdisciplinaryForm,
    editMode: boolean
  ): InterdisciplinaryForm[] => {
    let newInterdisciplinaryTeam = [];
    if (editMode) {
      newInterdisciplinaryTeam = interdisciplinaryTeamMember;
    } else if (
      interdisciplinaryTeamMember &&
      interdisciplinaryTeamMember.length > 0
    ) {
      newInterdisciplinaryTeam = [...interdisciplinaryTeamMember, teamMember];
    } else {
      newInterdisciplinaryTeam = [teamMember];
    }

    const contacts = newInterdisciplinaryTeam.map(
      (member: InterdisciplinaryForm) => {
        return {
          firstName: member.firstName === "" ? null : member.firstName,
          lastName: member.lastName === "" ? null : member.lastName,
          practiceName: member.practiceName === "" ? null : member.practiceName,
          specialty: member.specialty === "" ? null : member.specialty,
          contactFirstName:
            member.contactFirstName === "" ? null : member.contactFirstName,
          contactLastName:
            member.contactLastName === "" ? null : member.contactLastName,
          phoneNumber:
            member.phoneNumber === "" || !member.phoneNumber
              ? null
              : extractphone(member.phoneNumber!),
          faxNumber:
            member.faxNumber === "" || !member.faxNumber
              ? null
              : extractphone(member.faxNumber!),
          notes: member.notes === "" ? null : member.notes,
          isActive: true,
        };
      }
    );
    return contacts;
  };

  useEffect(() => {
    const getPatientInterdisciplinaryTeam = async () => {
      const patientModel = await PatientModel.sync(patientId!);
      const interdisciplinaryTeam = patientModel.pluck("interdisciplinaryTeam");
      setInterdisciplinaryTeamMember(interdisciplinaryTeam);
    };
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      const patient: Patient | undefined = props.selectedPatient.find(
        (patient: Patient) => patient.id === patientId
      );
      if (patient) {
        setInterdisciplinaryTeamMember(patient.interdisciplinaryTeam);
      }
    } else {
      getPatientInterdisciplinaryTeam();
    }
  }, []);

  return (
    <>
      <div id="interdisciplinary" className="tab-pane fade in active show">
        <div className="text-right">
          <button
            className="btn btn-glow-primary add-user btn-primary btn-sm"
            onClick={() => {
              setModalBtn("Add");
              setModalHeading("Add Interdisciplinary Team Member");
              setShowModal(true);
            }}
          >
            Add Member
          </button>
        </div>
        <div>
          <div className="mt-1 table-responsive">
            <table className=" table table-striped table-sm table-light table-hover user-management-table table-bordered">
              <thead>
                <tr>
                  <th className="bg-primary text-white small">
                    <span>Name</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Practice</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Specialty/Discipline</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Contact Name</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Main Phone</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Fax</span>
                  </th>
                  <th className="bg-primary text-white small">
                    <span>Notes</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {interdisciplinaryTeamMember &&
                  interdisciplinaryTeamMember.map(
                    (member: InterdisciplinaryForm, id: number) => (
                      <tr>
                        <td>
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#edit-interdisciplinary"
                            onClick={() => {
                              setModalBtn("Save Changes");
                              setModalHeading(
                                "Edit Interdisciplinary Team Member"
                              );
                              setTargetedTeamMember(member);
                              setTargetedTeamMemberId(id);
                              setShowModal(true);
                            }}
                          >
                            {member.firstName} {member.lastName}
                          </a>
                        </td>
                        <td>{member.practiceName}</td>
                        <td>{member.specialty}</td>
                        <td>
                          {member.contactFirstName} {member.contactLastName}
                        </td>
                        <td>
                          {member.phoneNumber
                            ? formatPhone(member.phoneNumber)
                            : ""}
                        </td>
                        <td>
                          {member.faxNumber
                            ? formatPhone(member.faxNumber)
                            : ""}
                        </td>
                        <td>{member.notes}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        heading={modalHeading}
        button={modalBtn}
        handleClose={() => {
          setShowModal(false);
        }}
        show={showModal}
      >
        {showModal && (
          <InterdisciplinaryFormComponent
            handleSubmit={handleSubmit}
            handleClose={() => setShowModal(false)}
            modalBtn={modalBtn}
            targetedTeamMember={targetedTeamMember}
          />
        )}
      </Modal>
    </>
  );
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setSelectedPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
  };
};

const mapStateToProps = (state: {
  patient: { patients: Patient[]; selectedPatients: Patient[] };
}) => {
  return {
    selectedPatient: state.patient.selectedPatients,
  };
};

export const InterdisciplinaryTab = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Interdisciplinary);
