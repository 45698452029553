import { SUCCESS } from "@/library/constants";
import { Receiver } from "@/library/common/receiver/receiver";
import { RCAResourceActions } from "@/library/core/config/actions";
import { Notification } from "@/components/notification/notification";
import { AppStore } from "@/redux/config";
import { HasId } from "@/library/types";
import { Handler } from "./handler";
import { Success } from "./success";

/**
 * Handles all successful commands. If a command succeeds, this will handle that success.
 * Its responsibility is mostly to bring the UI state to reflect the new successful resource change/addition.
 */
export class SuccessHandler<T extends HasId> extends Handler<T> {
  async handle(event: Success, receiver?: Partial<Receiver<T>>): Promise<void> {
    if (
      receiver &&
      receiver.resourceType !== undefined &&
      receiver.model !== undefined
    ) {
      // get the state and dispatchers from AppStore for that resource type
      const store = AppStore.get(receiver.resourceType);

      if (receiver.action === RCAResourceActions.ModifyBasicInfo) {
        // once we edit an entity, we want to sync it up with redux
        const entityToBeEdited = (store.state.entities as any[]).find(
          (entity: any) => entity.id === receiver?.model?.pluck("id")
        );
        const editedEntity = {
          ...entityToBeEdited,
          ...receiver.model.pluckAll(),
        };
        await store.dispatcher.setEntitiesCollection(
          store.state.entities.map((entity: any) => {
            if (entity.id === editedEntity.id) {
              entity = editedEntity;
            }
            return entity as any;
          })
        );
      } else {
        // On Create Entity, we want to make sure that pagination are set to reflect
        // newly created Entity and to also make sure that we don't have more entities than the page limit
        if (!store.state.entities.length) {
          store.dispatcher.setEntitiesPaginationConfig({
            totalResources: 1,
            currentPage: 1,
            lastPage: 1,
          });
        } else {
          if (store.state.entityPageLimit === store.state.entities.length) {
            // remove the last item in props.organizations and increment total resources so that user can next for more
            store.dispatcher.setEntitiesCollection(
              (store.state.entities as any[]).slice(0, -1)
            );
            store.dispatcher.setEntitiesPaginationConfig({
              totalResources: store.state.paginationConfig.totalResources + 1,
              currentPage: store.state.paginationConfig.currentPage,
              lastPage: store.state.paginationConfig.lastPage + 1, // last page becomes the next page that has the moved entity
            });
          } else {
            store.dispatcher.setEntitiesPaginationConfig({
              totalResources: store.state.paginationConfig.totalResources + 1,
              currentPage: store.state.paginationConfig.currentPage,
              lastPage: store.state.paginationConfig.lastPage,
            });
          }
        }
        const entity = receiver.model.pluckAll();
        store.dispatcher.createEntity(entity as any);
      }
      Notification.notify(SUCCESS, this.successMessage || event.Message());
      if (receiver.context) {
        receiver.context.markAsSuccessful();
      }
    }
  }
}
