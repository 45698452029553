import React, {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import { Drawer, Box, Grid } from "@mui/material";
import {
  fetchPrograms,
  resolveAssessmentTemplate,
} from "@/components/pathway/functions";
import {
  AssessmentAction,
  Automation,
  EnrollmentDayTrigger,
  ScheduleTrigger,
} from "@/domain/automation/model/types";
import { Program } from "@/domain/program/model/types";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import {
  AssessmentAnswerType,
  AssessmentQuestion,
  AssessmentTemplate,
} from "@/domain/assessment/model/types";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  automationSortFunc,
  padNumber,
} from "@/components/pathway/automations";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { PathwayHistory } from "@/components/pathway/history/container";

const drawerWidth = 240;

const getSchedule = (automation: Automation): string => {
  const trigger = automation.triggers[0];
  if (trigger.type === "ENROLLMENT_DAY") {
    return `Day ${
      (trigger as EnrollmentDayTrigger).day
    } of the program until completed one time by the patient`;
  } else if (trigger.type === "SCHEDULE") {
    const occurrence = (trigger as ScheduleTrigger).occurrences[0];
    return `Every ${occurrence.day} @ ${padNumber(occurrence.hour)}:${padNumber(
      occurrence.minute
    )}`;
  }
  return "";
};
const getName = (automation: Automation, program: Program) => {
  return (automation.actions[0] as AssessmentAction).assessmentTemplate
    ?.name as string;
};

interface AutomationsProps {
  program: Program;
  selectedAutomation: Automation | null | undefined;
  onAutomationSelect: Dispatch<SetStateAction<Automation | null | undefined>>;
}
const Automations: FC<AutomationsProps> = ({
  program,
  selectedAutomation,
  onAutomationSelect,
}) => {
  return (
    <List>
      {program.automations?.map((automation: Automation, index: number) => (
        <ListItem
          button
          onClick={() => onAutomationSelect(automation)}
          key={index}
          style={{
            backgroundColor:
              selectedAutomation === automation ? "lightgrey" : "transparent",
          }}
        >
          <ListItemText primary={getName(automation, program)} />
        </ListItem>
      ))}
    </List>
  );
};

interface AssessmentDisplayProps {
  automation: Automation;
}
interface AssessmentDisplayProps {
  automation: Automation;
}

const transformQuestionsToMap = (
  questions: AssessmentQuestion[]
): Record<string, AssessmentQuestion> => {
  const questionMap: Record<string, AssessmentQuestion> = {};
  questions.forEach((question) => {
    if (question.id) {
      questionMap[question.id] = question;
    }
  });
  return questionMap;
};

const renderQuestion = (
  question: AssessmentQuestion,
  questionMap: Record<string, AssessmentQuestion>,
  visited: Set<string> = new Set()
): React.ReactNode => {
  if (question && question.id && !visited.has(question.id)) {
    visited.add(question.id);
    return (
      <Accordion expanded>
        <AccordionSummary>
          <Typography>{question.prompt}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {question.answer.type === AssessmentAnswerType.Boolean && (
            <div>
              <Typography>Yes:</Typography>
              {question.answer.affirmative &&
                question.answer.affirmative.nextId &&
                renderQuestion(
                  questionMap[question.answer.affirmative.nextId],
                  questionMap,
                  visited
                )}

              <Typography>No:</Typography>
              {question.answer.negative &&
                question.answer.negative.nextId &&
                renderQuestion(
                  questionMap[question.answer.negative.nextId],
                  questionMap,
                  visited
                )}
            </div>
          )}
          {question.answer.nextId &&
            renderQuestion(
              questionMap[question.answer.nextId],
              questionMap,
              visited
            )}
        </AccordionDetails>
      </Accordion>
    );
  }
  return null;
};

const AssessmentDisplay: FC<AssessmentDisplayProps> = ({ automation }) => {
  const filteredActions = automation.actions.filter(
    (action) => action.type === "START_ASSESSMENT"
  ) as AssessmentAction[];
  const action = filteredActions[0];
  const template = action.assessmentTemplate as AssessmentTemplate;

  // Transform the array of questions into a hashmap
  const questionMap = transformQuestionsToMap(template.questions);

  return <div>{renderQuestion(template.questions[0], questionMap)}</div>;
};

export interface PathwayAutomationsReadOnlyProps {
  patientId: string;
}
export const PathwayAutomationsReadOnly: FC<
  PathwayAutomationsReadOnlyProps
> = ({ patientId }) => {
  const [selectedProgram, setSelectedProgram] = useState<
    Program | undefined | null
  >();
  const [selectedPatientId, setSelectedPatientId] = useState(patientId);
  const [selectedAutomation, setSelectedAutomation] = useState<
    Automation | null | undefined
  >(null);

  useEffect(() => {
    (async () => {
      const patient: Patient = (
        await PatientModel.sync(selectedPatientId)
      ).pluckAll();
      const allPrograms: Program[] = await fetchPrograms();
      const program: Program | undefined = allPrograms.find(
        (p: Program) => p._meta?.id === patient.programId
      );
      if (program) {
        if (program.automations) {
          const assessmentAutomations = program.automations
            .filter((a) => a.actions[0].type === "START_ASSESSMENT")
            .filter((a) =>
              ["SCHEDULE", "ENROLLMENT_DAY"].includes(a.triggers[0].type)
            );
          for (const assessmentAutomationsKey in assessmentAutomations) {
            const action = assessmentAutomations[assessmentAutomationsKey]
              .actions[0] as AssessmentAction;
            if (action.assessmentId !== null) {
              action.assessmentTemplate = await resolveAssessmentTemplate(
                action.assessmentId
              );
            }
          }
        }
        program.automations =
          program.automations?.sort(automationSortFunc) ?? null;
        setSelectedProgram(program);
      }
    })();
  }, [selectedPatientId]);

  return selectedProgram ? (
    <Grid container style={{ position: "absolute" }}>
      <Grid item xs={2} style={{ height: "100vh" }}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-root": {
              position: "absolute",
              zIndex: 1,
            },
            "& .MuiPaper-root": {
              position: "absolute",
              zIndex: 1,
            },
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              position: "absolute",
              zIndex: 1,
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            {selectedProgram && (
              <div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem onClick={() => setSelectedAutomation(null)} button>
                    <ListItemText primary="Pathway History" secondary="" />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText
                      primary="Program"
                      secondary={selectedProgram.name}
                    />
                  </ListItem>
                  <Divider component="li" variant="inset" />
                  <Box sx={{ p: 2 }}>
                    <Automations
                      selectedAutomation={selectedAutomation}
                      program={selectedProgram}
                      onAutomationSelect={setSelectedAutomation}
                    />
                  </Box>
                </List>
              </div>
            )}
          </Box>
        </Drawer>
      </Grid>
      <Grid item xs={10}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {selectedAutomation ? (
            <div>
              <ListItemText
                primary={
                  <Typography variant="h6">
                    {
                      (selectedAutomation?.actions[0] as AssessmentAction)
                        .assessmentTemplate?.name as string
                    }
                  </Typography>
                }
                secondary={getSchedule(selectedAutomation)}
              />
              <AssessmentDisplay automation={selectedAutomation} />
            </div>
          ) : (
            <PathwayHistory patientId={patientId} />
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <div>This patient has not been enrolled into any program.</div>
  );
};

export default PathwayAutomationsReadOnly;
