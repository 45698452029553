import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Modal } from "@/components/modal";
import { PatientId } from "@/domain/patient/redux/types";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
} from "@/domain/patient/redux/actions";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import AllergyFormComponent from "./form/allergyForm.component";
import MedicationFormComponent from "./form/medicationForm.component";
import { AllergyForm } from "./model/allergy";
import { MedicationForm } from "./model/medication";

interface Props {
  patientId: PatientId;

  selectedPatient: Patient[];
  setSelectedPatient: (patient: Patient) => void;
  deselectAllPatients: () => { type: string };
}

export const MedicationComponent: FunctionComponent<Props> = (props: Props) => {
  const { patientId, selectedPatient } = props;

  // ********************** Patient Medication handling ********************** //
  const [patientMedication, setPatientMedication]: any = useState([]);
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [medicationModalHeading, setMedicationModalHeading] =
    useState("Add Medication");
  const [medicationModalBtn, setMedicationModalBtn] = useState("Add");
  const [targetedMedication, setTargetedMedication]: any = useState({});
  const [targetedMedicationId, setTargetedMedicationId]: any = useState(null);

  const handleSubmitMedicationForm = async (
    values: MedicationForm,
    editMode: boolean
  ): Promise<void> => {
    const patientModel = await PatientModel.sync(patientId!);
    if (editMode) {
      patientMedication[targetedMedicationId] = values;
    }
    try {
      await patientModel.modifyPatient<Patient, "medications">({
        medications: parseMedicationValues(values, editMode),
      });
      if (!editMode) {
        patientMedication && patientMedication.length > 0
          ? setPatientMedication([...patientMedication, values])
          : setPatientMedication([values]);
      }
      setPatientMedication((medication: MedicationForm[]) => {
        updatePatienMedicationState(medication);
        return medication;
      });
      setShowMedicationModal(false);
      Notification.notify(SUCCESS, "Medications updated successfully.");
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  const parseMedicationValues = (
    values: MedicationForm,
    editMode: boolean
  ): MedicationForm[] => {
    let newMedications: MedicationForm[] = [];
    if (editMode) {
      newMedications = patientMedication;
    } else if (patientMedication && patientMedication.length > 0) {
      newMedications = [...patientMedication, values];
    } else {
      newMedications = [values];
    }

    const medications: MedicationForm[] = newMedications.map(
      (medication: MedicationForm) => {
        return {
          name: medication.name,
          dosage: medication.dosage,
          directions: medication.directions,
          dateStarted:
            medication.dateStarted === "" ? null : medication.dateStarted,
          dateEnded: medication.dateEnded === "" ? null : medication.dateEnded,
          lastRefill:
            medication.lastRefill === "" ? null : medication.lastRefill,
          prescribedBy:
            medication.prescribedBy === "" ? null : medication.prescribedBy,
          notes: medication.notes === "" ? null : medication.notes,
        };
      }
    );
    return medications;
  };

  const updatePatienMedicationState = (updatedMedication: MedicationForm[]) => {
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      props.selectedPatient.forEach((patient: Patient) => {
        if (patient.id === patientId) {
          patient.medications = updatedMedication;
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }
      });
    }
  };

  // ********************** Patient Allergies handling ********************** //
  const [patientAllergies, setPatientAllergies]: any = useState([]);
  const [showAllergyModal, setShowAllergyModal] = useState(false);
  const [allergyModalHeading, setAllergyModalHeading] = useState("Add Allergy");
  const [allergyModalBtn, setAllergyModalBtn] = useState("Add");
  const [targetedAllergy, setTargetedAllergy]: any = useState({});
  const [targetedAllergyId, setTargetedAllergyId]: any = useState(null);

  const handleSubmitAllergies = async (
    values: AllergyForm,
    editMode: boolean
  ): Promise<void> => {
    const patientModel = await PatientModel.sync(patientId!);
    if (editMode) {
      patientAllergies[targetedAllergyId] = values;
    }
    try {
      await patientModel.modifyPatient<Patient, "allergies">({
        allergies: parseAllergyValues(values, editMode),
      });
      if (!editMode) {
        patientAllergies && patientAllergies.length > 0
          ? setPatientAllergies([...patientAllergies, values])
          : setPatientAllergies([values]);
      }
      setPatientAllergies((allergies: AllergyForm[]) => {
        updatePatientAllergyState(allergies);
        return allergies;
      });
      setShowAllergyModal(false);
      Notification.notify(SUCCESS, "Allergies updated successfully.");
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  const parseAllergyValues = (
    values: AllergyForm,
    editMode: boolean
  ): AllergyForm[] => {
    let newAllergies = [];
    if (editMode) {
      newAllergies = patientAllergies;
    } else if (patientAllergies && patientAllergies.length > 0) {
      newAllergies = [...patientAllergies, values];
    } else {
      newAllergies = [values];
    }

    const allergies = newAllergies.map((allergy: AllergyForm) => {
      return {
        allergy: allergy.allergy,
        reaction: allergy.reaction,
        dateOccurred: allergy.dateOccurred === "" ? null : allergy.dateOccurred,
        severe: allergy.severe,
      };
    });
    return allergies;
  };

  const updatePatientAllergyState = (updatedAllergies: AllergyForm[]) => {
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      props.selectedPatient.forEach((patient: Patient) => {
        if (patient.id === patientId) {
          patient.allergies = updatedAllergies;
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }
      });
    }
  };

  // ************************ Lifecycle methods ************************ //
  useEffect(() => {
    const getPatientAllergiesAndMedication = async () => {
      const patientModel = await PatientModel.sync(patientId!);
      const patientAllergies = patientModel.pluck("allergies");
      const patientMedications = patientModel.pluck("medications");
      setPatientAllergies(patientAllergies);
      setPatientMedication(patientMedications);
    };
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      const patient: Patient | undefined = props.selectedPatient.find(
        (patient: Patient) => patient.id === patientId
      );
      if (patient && !!patientAllergies) {
        setPatientAllergies(patient.allergies);
        setPatientMedication(patient.medications);
      }
    } else {
      getPatientAllergiesAndMedication();
    }
  }, []);

  return (
    <>
      <div id="medications" className="tab-pane fade in active show">
        <div className="accordion detail_accordation">
          <div id="medicationbasic1" className="collapse panel-collapse show">
            <div className="text-right mb-2">
              <button
                className="btn btn-glow-primary add-user btn-primary btn-sm"
                onClick={() => {
                  setMedicationModalBtn("Add");
                  setMedicationModalHeading("Add Medication");
                  setShowMedicationModal(true);
                }}
              >
                <span> Add Medication</span>
              </button>
            </div>
            <div className="mt-1 table-responsive">
              <table className=" table table-striped table-sm table-light table-hover user-management-table table-bordered">
                <thead>
                  <tr>
                    <th className="bg-primary text-white small">
                      <span>Name</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Dose</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Directions</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Date Started</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Date Ended</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Last Refill</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Provider</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Notes</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patientMedication &&
                    patientMedication.map(
                      (medication: MedicationForm, id: number) => (
                        <tr>
                          <td>
                            <a
                              href="#"
                              onClick={() => {
                                setTargetedMedication(medication);
                                setTargetedMedicationId(id);
                                setMedicationModalBtn("Save Changes");
                                setMedicationModalHeading("Edit Medication");
                                setShowMedicationModal(true);
                              }}
                            >
                              {medication.name}
                            </a>
                          </td>
                          <td>{medication.dosage}</td>
                          <td>{medication.directions}</td>
                          <td>{medication.dateStarted}</td>
                          <td>{medication.dateEnded}</td>
                          <td>{medication.lastRefill}</td>
                          <td>{medication.prescribedBy}</td>
                          <td>{medication.notes}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <div id="medicationbasic2" className="collapse panel-collapse show">
            <div className="text-right mb-2">
              <button
                className="btn btn-glow-primary add-user btn-primary btn-sm"
                onClick={() => {
                  setAllergyModalBtn("Add");
                  setAllergyModalHeading("Add Allergy");
                  setShowAllergyModal(true);
                }}
              >
                <span> Add Allergies</span>
              </button>
            </div>
            <div className="mt-1 table-responsive">
              <table className=" table table-striped table-sm table-light table-hover user-management-table table-bordered">
                <thead>
                  <tr>
                    <th className="bg-primary text-white small">
                      <span>Medication/Other</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Reaction</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Date Occurred</span>
                    </th>
                    <th className="bg-primary text-white small">
                      <span>Mark as Severe</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patientAllergies &&
                    patientAllergies.map((allergy: AllergyForm, id: number) => (
                      <tr key={id}>
                        <td>
                          <a
                            href="#"
                            onClick={() => {
                              setTargetedAllergy(allergy);
                              setTargetedAllergyId(id);
                              setAllergyModalBtn("Save Changes");
                              setAllergyModalHeading("Edit Allergy");
                              setShowAllergyModal(true);
                            }}
                          >
                            {allergy.allergy}
                          </a>
                        </td>
                        <td>{allergy.reaction}</td>
                        <td>{allergy.dateOccurred || ""}</td>
                        <td>
                          <input
                            type="checkbox"
                            onClick={(e: any) => {
                              patientAllergies[id].severe = e.target.checked;
                              setTargetedAllergyId((id: number) => {
                                handleSubmitAllergies(
                                  patientAllergies[id],
                                  true
                                );
                                return id;
                              });
                            }}
                            checked={allergy.severe ? allergy.severe : false}
                            onChange={() => {}}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Medication Modal */}
      <Modal
        heading={medicationModalHeading}
        button={medicationModalBtn}
        handleClose={() => setShowMedicationModal(false)}
        show={showMedicationModal}
      >
        {showMedicationModal && (
          <MedicationFormComponent
            modalBtn={medicationModalBtn}
            handleClose={() => setShowMedicationModal(false)}
            handleSubmit={handleSubmitMedicationForm}
            targetedMedication={targetedMedication}
            targetedMedicationId={targetedMedicationId}
          />
        )}
      </Modal>

      {/* Medication Modal */}
      <Modal
        heading={allergyModalHeading}
        button={allergyModalBtn}
        handleClose={() => setShowAllergyModal(false)}
        show={showAllergyModal}
      >
        {showAllergyModal && (
          <AllergyFormComponent
            modalBtn={allergyModalBtn}
            handleClose={() => setShowAllergyModal(false)}
            handleSubmit={handleSubmitAllergies}
            targetedAllergy={targetedAllergy}
            targetedAllergyId={targetedAllergyId}
          />
        )}
      </Modal>
    </>
  );
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setSelectedPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
  };
};
const mapStateToProps = (state: {
  patient: { patients: Patient[]; selectedPatients: Patient[] };
}) => {
  return {
    selectedPatient: state.patient.selectedPatients,
  };
};

export const MedicationTab = connect(
  mapStateToProps,
  mapDispatcherToProps
)(MedicationComponent);
