import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";

import React, { useState, Fragment } from "react";

import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import { AlertTypeLabelMap } from "@/domain/patient/view/AlertsTable/constants";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { getLabelBySeverityScore } from "@/domain/patient/view/PatientBanners/constants";
import styles from "./styles.module.scss";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AssessmentQuestion } from "@/domain/assessment/model/types";

export const makeColumns = (): ColumnDef<AlertTableRow>[] => [
  {
    id: "type",
    header: "Type",
    accessorKey: "type",
    cell: ({ row }) => {
      const { type } = row.original;

      return <span className={styles.typeCell}>{AlertTypeLabelMap[type]}</span>;
    },
  },
  {
    id: "value",
    header: "Value",
    accessorKey: "value",
    cell: ({ row, column }) => (
      <span className={styles.valueCell}>{row.getValue(column.id) || "-"}</span>
    ),
  },
  {
    id: "severityScore",
    header: "Severity",
    accessorKey: "severityScore",
    minSize: 150,
    cell: ({ row }) => {
      const { severityScore } = row.original;
      const color = getAlertLevelColor(getSeverityAlertLevel(severityScore));

      return (
        <span className={styles.severityCell} style={{ color }}>
          {getLabelBySeverityScore(severityScore, true)}
        </span>
      );
    },
  },
  {
    id: "created",
    header: "Created",
    accessorKey: "created",
    cell: ({ row }) => {
      const { created } = row.original;

      return (
        <span className={styles.createdCell}>
          {DateTime.fromISO(created).toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      );
    },
  },
  {
    id: "comment",
    header: "Comment",
    accessorKey: "comment",
    cell: ({ row, column }) => {
      return (
        <span className={styles.commentCell}>{row.getValue(column.id)}</span>
      );
    },
  },
];

const renderSeverityScore = (severityScore: number | undefined) => {
  const color = getAlertLevelColor(getSeverityAlertLevel(severityScore));

  return (
    <span className={styles.severityCell} style={{ color }}>
      {getLabelBySeverityScore(severityScore, true)}
    </span>
  );
};

export const renderComment = (comment: string) => {
  return <span className={styles.commentCell}>{comment}</span>;
};

export const renderCreated = (created: string) => {
  return (
    <span className={styles.createdCell}>
      {DateTime.fromISO(created).toLocaleString(DateTime.DATETIME_SHORT)}
    </span>
  );
};
export function Row(props: { row: AlertTableRow }) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {row.assessmentQuestions ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell component="th" scope="row">
          {row.type}
        </TableCell>
        <TableCell align="right">{row.value}</TableCell>
        <TableCell align="right">
          {renderSeverityScore(row.severityScore)}
        </TableCell>
        <TableCell align="right">{renderCreated(row.created)}</TableCell>
        <TableCell align="right">{row.comment}</TableCell>
      </TableRow>
      {row.assessmentQuestions && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Assessment Response
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Question</TableCell>
                      <TableCell align="right">Answer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(row.assessmentQuestions || []).map(
                      (question: AssessmentQuestion) => (
                        <TableRow key={question.id}>
                          <TableCell align="left">{question.prompt}</TableCell>
                          <TableCell align="right">
                            {question.answer.value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}
