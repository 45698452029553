import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select, Props as SelectProps } from "@/components/_selects/Select";

import { setProviders as dispatchSetProviders } from "@/domain/provider/redux";
import { Provider, ProviderOptions } from "@/domain/provider/model/provider";
import { selectProviders } from "@/domain/provider/redux/selectors";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { Practice } from "@/domain/practice/model/types";
import { ProviderModel } from "@/domain/provider/model";

export type Props = {
  name: string;
  selectedId?: string | null;
} & SelectProps;

export const ProviderSelect: React.FC<Props> = (props) => {
  const {
    selectedId,
    selectedPractice,
    name = "provider-select",
    label = "Provider",
    ...rest
  } = props;

  const [pending, setPending] = useState(false);

  const practices = useSelector(selectPractices);
  const providers = useSelector(selectProviders);
  const dispatch = useDispatch();
  const setProviders = (providers: Provider[]) =>
    dispatch(dispatchSetProviders(providers));

  useEffect(() => {
    const fetch = async () => {
      try {
        setPending(true);
        const practiceIds = practices.map((practice: Practice) => practice.id);
        const allProviders = (
          await ProviderModel.fetchByPracticeIds(practiceIds as string[])
        ).map((practiceModel) => practiceModel.pluckAll());
        setProviders(allProviders);
      } catch (error) {
        console.error(error);
      } finally {
        setPending(false);
      }
    };

    if (!pending && practices.length && !providers.length) fetch();
  }, [practices]);

  const options: Array<ProviderOptions> = [];
  providers.forEach((provider: Provider) => {
    if (
      !selectedPractice ||
      (selectedPractice && provider.practiceId === selectedPractice)
    ) {
      options.push({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
      });
    }
  });
  const selectedOption = selectedId
    ? options.find((o) => o.value === selectedId)
    : null;

  return (
    <Select
      name={name}
      label={label}
      options={options}
      isMulti={false}
      pending={pending}
      value={selectedOption}
      {...rest}
    />
  );
};
