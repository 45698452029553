import React from "react";
import DatePicker from "react-datepicker";

interface Props {
  handleReportMonthYearChange: (value: Date) => void;
  reportMonthYear: Date;
}

function MonthSelect(props: Props) {
  const { handleReportMonthYearChange, reportMonthYear } = props;

  return (
    <>
      <DatePicker
        className="form-control"
        name="monthYear"
        selected={reportMonthYear}
        onChange={(date: Date) => handleReportMonthYearChange(date)}
        placeholderText="MM YYYY"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        maxDate={new Date()}
      />
    </>
  );
}

export default MonthSelect;
