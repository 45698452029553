import React, { useState } from "react";
import cx from "clsx";
import { Button } from "rsuite";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { Search } from "@/components/search";
import { OrganizationDropdownComponent } from "@/components/dropdown/organizationDropdown.component";
import { FilterButton } from "@/components/FilterButton";
import headerStyles from "@/components/Header/styles.module.scss";
import { LocationSelect } from "@/components/_selects/LocationSelect";
import AlertFilter from "@/domain/patient/view/filters/alert.filter";
import { PatientStatusFilter } from "@/domain/patient/view/filters/status.filter";
import PracticeFilter from "@/domain/patient/view/filters/practice.filter";
import ProviderFilter from "@/domain/patient/view/filters/provider.filter";
import UsersFilter from "@/domain/patient/view/filters/users.filter";
import { Str } from "@/util/Str";
import { Props } from "./types";
import closeIcon from "../../assets/icons/anelto/close-panel.svg";
import { FILTER_TYPES } from "@/domain/patient/view/PatientsPage/constants";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import ProgramFilter from "@/domain/patient/view/filters/program.filter";
import { FilterItemsComponent } from "../FilterItems";

export const Header: React.FC<Props> = (props) => {
  const {
    entity,
    organizationFilter,
    userFilter,
    statusFilter,
    practiceFilter,
    providerFilter,
    programFilter,
    searchRight,
    children,
    button,
    isNav,
    table,
    search,
    filter,
    alertFilter,
    className,
    locationFilter,
    filterItems,
  } = props;

  const { onCreate, shouldDisplay: createShouldDisplay } = button.create || {};
  const { selectedEntityLength, totalEntityLength } = entity;
  const {
    collapsed,
    onToggle,
    shouldDisplay: toggleShouldDisplay,
  } = button.toggleCollapse;
  const { name, subTitle } = entity;
  const entityName = Str.CapitalizeFirstLetter(name);

  const handleResetClick = async () => {
    if (props.onResetClick) {
      props.onResetClick();
      return;
    }
    if (!filter) return;
    await filter.onResetFilters?.(search.filters);
    if (search.sortedState) {
      await filter.onResetSortedState?.(search.sortedState);
    }
    await filter.onResetState({ search: "" });
    await filterItems?.onResetFilterItems?.([]);
    await search.onSearch({});
    // filter.selectAfterReset && await filter.selectAfterReset()
  };

  const [state, setState] = useState({
    isPaneOpen: false,
  });

  const isPatientSlidingPaneEnabled =
    _env_.ENABLE_PATIENT_SLIDING_PANE === "true";

  const displayFilterEnabled =
    locationFilter?.enabled ||
    statusFilter?.enabled ||
    userFilter?.enabled ||
    programFilter?.enabled ||
    practiceFilter?.enabled ||
    providerFilter?.enabled ||
    alertFilter?.enabled;

  return (
    <>
      {!isPatientSlidingPaneEnabled && (
        <div className={cx(isNav && styles.sticky, className)}>
          <div className={cx(styles.header, styles.row)}>
            <div className={styles.col}>
              <h6 className={cx(globalStyles.pageHeading)}>
                {toggleShouldDisplay && (
                  <button
                    onClick={onToggle}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <i
                      className={`feather icon-chevron-${
                        collapsed ? "right" : "down"
                      }`}
                    />
                  </button>
                )}
                {totalEntityLength &&
                  `${selectedEntityLength}/${totalEntityLength}`}{" "}
                {subTitle ?? `${entityName}s`}
              </h6>
            </div>
            {/* ORGANIZATION FILTERS */}
            {organizationFilter?.enabled && (
              <div className={styles.col}>
                <OrganizationDropdownComponent
                  setDefaultOrganization={true}
                  currentOrganization={
                    organizationFilter.selectedOrganization?.name ||
                    "All Organizations"
                  }
                  onChange={async (value: string) => {
                    organizationFilter?.onSelectOrganization(value);
                  }}
                />
              </div>
            )}
            {programFilter?.enabled && (
              <div className={styles.col}>
                <ProgramFilter
                  currentProgram={
                    programFilter.selectedProgram?.name || "All Programs"
                  }
                  onChange={async (value: string) => {
                    programFilter?.onSelectProgram(value, "");
                  }}
                />
              </div>
            )}

            {/* USER FILTER */}
            {userFilter?.enabled && (
              <div className={styles.col}>
                <UsersFilter
                  onChange={(user) => userFilter?.onSelectUser(user, [])}
                />
              </div>
            )}

            {/* PATIENT STATUS FILTER */}
            {statusFilter?.enabled && (
              <div className={styles.col}>
                <PatientStatusFilter
                  selectedStatus={statusFilter?.selectedStatus}
                  onChange={(status) => {
                    if (status?.includes("None")) {
                      status = undefined;
                    }
                    statusFilter?.onSelectStatus(status, undefined);
                  }}
                />
              </div>
            )}

            {/* PRACTICE FILTER */}
            {practiceFilter?.enabled && (
              <div className={styles.col}>
                <PracticeFilter
                  onChange={(practiceIds) =>
                    practiceFilter?.onSelectPractices(practiceIds, undefined)
                  }
                  selectedPracticeIds={props.practiceFilter?.selectedPractices}
                />
              </div>
            )}

            {/* PROVIDER FILTER */}
            {providerFilter?.enabled && (
              <div className={styles.col}>
                <ProviderFilter
                  onChange={(providersIds) =>
                    providerFilter?.onSelectProviders(providersIds, undefined)
                  }
                />
              </div>
            )}
            {/* LOCATION FILTER */}
            {locationFilter?.enabled && (
              <div className={headerStyles.col}>
                <LocationSelect
                  data={locationFilter.data}
                  selectedId={locationFilter.selectedId}
                  selectedPractices={locationFilter.selectedPractices}
                  onChange={async (selectedPracticeIds) => {
                    locationFilter.onChange(selectedPracticeIds);
                  }}
                  isSearchable={true}
                  placeholder="All Locations"
                  onSelect={locationFilter.onSelect}
                  groupBy="role"
                  picker="check"
                  onClear={locationFilter.onClear}
                />
              </div>
            )}

            {/* ALERT FILTER */}
            {alertFilter?.enabled && (
              <div className={styles.col}>
                <AlertFilter onChange={alertFilter.onSelectAlert} />
              </div>
            )}

            {/* CREATE ENTITY BUTTON */}
            {children ? (
              children
            ) : onCreate && createShouldDisplay ? (
              <div className={styles.col}>
                <Button
                  appearance="ghost"
                  onClick={onCreate}
                  style={{ minWidth: 130 }}
                >
                  {`Create ${entityName}`}
                </Button>
              </div>
            ) : null}
          </div>

          {!isNav && <hr style={{ marginTop: 7, marginBottom: 7 }} />}

          {!collapsed && (
            <div className={styles.row}>
              <Search
                pageLimit={table.pageLimit}
                onSearch={search.onSearch}
                value={search.searchStr}
                placeholder={search.placeholder}
                containerClassName={styles.searchMargin}
              >
                <Button
                  appearance="subtle"
                  onClick={handleResetClick}
                  style={{ marginLeft: 7 }}
                >
                  <span className="text-rca">Reset Search & Filter</span>
                </Button>
              </Search>

              {searchRight ? (
                <div className={styles.searchMargin}>{searchRight}</div>
              ) : null}
            </div>
          )}
        </div>
      )}
      {isPatientSlidingPaneEnabled && (
        <div className={cx(isNav && styles.sticky, className)}>
          <div className={cx(styles.header, styles.row)}>
            <div className={cx(styles.col)}>
              <div className={cx(styles.row)}>
                <h6 className={cx(globalStyles.pageHeading)}>
                  {toggleShouldDisplay && (
                    <button
                      onClick={onToggle}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <i
                        className={`feather icon-chevron-${
                          collapsed ? "right" : "down"
                        }`}
                      />
                    </button>
                  )}
                  {subTitle ?? `${entityName}s`}
                  {totalEntityLength &&
                  selectedEntityLength === totalEntityLength
                    ? ` (${totalEntityLength})`
                    : ` (${selectedEntityLength}/${totalEntityLength})`}
                </h6>
                {organizationFilter?.enabled && (
                  <div className={styles.col}>
                    <OrganizationDropdownComponent
                      setDefaultOrganization={true}
                      currentOrganization={
                        organizationFilter.selectedOrganization?.name ||
                        "All Organizations"
                      }
                      onChange={async (selectedOrganization: string) => {
                        organizationFilter?.onSelectOrganization(
                          selectedOrganization
                        );
                      }}
                    />
                  </div>
                )}
              </div>

              <div className={cx(styles.row)}>
                <Search
                  pageLimit={table.pageLimit}
                  onSearch={search.onSearch}
                  value={search.searchStr}
                  placeholder={search.placeholder}
                >
                  {!displayFilterEnabled && (
                    <Button
                      appearance="subtle"
                      onClick={handleResetClick}
                      style={{ marginLeft: 7 }}
                    >
                      <span className="text-rca">Reset Search & Filter</span>
                    </Button>
                  )}
                </Search>

                {displayFilterEnabled && (
                  <>
                    <FilterButton
                      filterText="Filter"
                      openPane={() => setState({ isPaneOpen: true })}
                    />

                    {filterItems?.enabled && (
                      <FilterItemsComponent onSearch={search.onSearch} />
                    )}

                    <SlidingPane
                      isOpen={state.isPaneOpen}
                      width="420px"
                      onRequestClose={() => setState({ isPaneOpen: false })}
                      closeIcon={<img src={closeIcon} alt="Close" />}
                      from="right"
                      title={
                        <div className={styles["sliding-pane-filter-title"]}>
                          Filters
                        </div>
                      }
                      className={styles["sliding-pane-filter-content"]}
                      overlayClassName={styles["sliding-pane-filter-overlay"]}
                    >
                      {statusFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Patient Status</label>
                          <PatientStatusFilter
                            selectedStatus={statusFilter?.selectedStatus}
                            onChange={(status, description) => {
                              if (status?.includes("None")) {
                                status = undefined;
                              }
                              statusFilter?.onSelectStatus(status, description);
                            }}
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_STATUS}
                            />
                          )}
                        </div>
                      )}

                      {userFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Patients</label>
                          <UsersFilter
                            onChange={(user, descriptions) =>
                              userFilter?.onSelectUser(user, descriptions)
                            }
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_USER}
                            />
                          )}
                        </div>
                      )}

                      {programFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Programs</label>
                          <ProgramFilter
                            currentProgram={
                              programFilter.selectedProgram?.name ||
                              "All Programs"
                            }
                            onChange={async (
                              selectedProgram: string,
                              description: string
                            ) => {
                              programFilter?.onSelectProgram(
                                selectedProgram,
                                description
                              );
                            }}
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_PROGRAM}
                            />
                          )}
                        </div>
                      )}

                      {practiceFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Entity/Branch</label>
                          <PracticeFilter
                            onChange={(practiceIds, descriptions) =>
                              practiceFilter?.onSelectPractices(
                                practiceIds,
                                descriptions
                              )
                            }
                            selectedPracticeIds={
                              props.practiceFilter?.selectedPractices
                            }
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_PRACTICE}
                            />
                          )}
                        </div>
                      )}

                      {providerFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Provider</label>
                          <ProviderFilter
                            onChange={(providerIds, descriptions) => {
                              providerFilter?.onSelectProviders(
                                providerIds,
                                descriptions
                              );
                            }}
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_PROVIDER}
                            />
                          )}
                        </div>
                      )}

                      {locationFilter?.enabled && (
                        <div className={headerStyles.col}>
                          <label>Location</label>
                          <LocationSelect
                            data={locationFilter.data}
                            selectedId={locationFilter.selectedId}
                            selectedPractices={locationFilter.selectedPractices}
                            onChange={async (selectedPracticeIds) => {
                              locationFilter.onChange(selectedPracticeIds);
                            }}
                            isSearchable={true}
                            placeholder="All Locations"
                            onSelect={locationFilter.onSelect}
                            groupBy="role"
                            picker="check"
                            onClear={locationFilter.onClear}
                          />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_LOCATION}
                            />
                          )}
                        </div>
                      )}

                      {alertFilter?.enabled && (
                        <div className={styles.col}>
                          <label>Alert</label>
                          <AlertFilter onChange={alertFilter.onSelectAlert} />
                          {filterItems?.enabled && (
                            <FilterItemsComponent
                              onSearch={search.onSearch}
                              byType={FILTER_TYPES.FILTER_TYPE_ALERT}
                            />
                          )}
                        </div>
                      )}

                      <div className={cx(styles.col, styles.right)}>
                        <Button
                          type="button"
                          appearance="ghost"
                          onClick={handleResetClick}
                          className={styles.reset}
                        >
                          Clear Filter
                        </Button>
                      </div>
                    </SlidingPane>
                  </>
                )}
              </div>
            </div>

            {/* CREATE ENTITY BUTTON */}
            {children ? (
              children
            ) : onCreate && createShouldDisplay ? (
              <div className={cx(styles.col)}>
                <Button
                  appearance="ghost"
                  onClick={onCreate}
                  style={{ minWidth: 130 }}
                >
                  {`Create ${entityName}`}
                </Button>
              </div>
            ) : null}

            {searchRight ? (
              <div className={styles.searchMargin}>{searchRight}</div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
