import { Attributes, Model } from "@/library/model";

import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Http } from "@/library/core/api/http";
import { AuditLog } from "@/domain/audit/model/types";
import { PaginationConfig } from "@/library/types";

export class AuditModel extends Model<AuditLog> {
  static readonly path = "audit";

  /**
   * Makes a new instance of AuditLogModel.
   *
   * @param attributes
   */
  static make(attributes: AuditLog): AuditModel {
    return new AuditModel(
      new Attributes<AuditLog>(attributes),
      new RemoteCareAPISync<AuditLog>(AuditModel.path),
      new EventEmitter()
    );
  }

  static async getAuditEntryLog(
    params = {}
  ): Promise<[AuditLog[], PaginationConfig]> {
    const response = await Http().get(
      `${RemoteCareAPISync.host}/${AuditModel.path}`,
      {
        params: {
          ...params,
        },
      }
    );
    const totalResources = parseInt(response.headers["total-resources"]);
    const currentPage = parseInt(response.headers["current-page"]);
    const lastPage = parseInt(response.headers["last-page"]);

    const paginationConfig = {
      totalResources,
      currentPage,
      lastPage,
    };

    return [response.data, paginationConfig];
  }
}
