export enum AssessmentType {
  Pathway = "PATHWAY_ASSESSMENT",
  HealthTip = "HEALTH_TIP",
  HealthScreening = "HEALTH_SCREENING",
}

export type VoiceObject = {
  name?: string;
  ssml?: string;
};
export interface AutoCompleteType {
  id?: string;
  label: string;
}

export type AssessmentTemplate = Assessment;
export interface Assessment {
  id?: string;
  /**
   * ID of the document in the legacy templates collection this template was migrated from.
   */
  legacyDocumentId: string;
  /**
   * The type of assessment template.
   */
  type: AssessmentType;
  /**
   * What the assessment template is called.
   */
  name: string;
  /**
   * The call out number for a transfer assessment answer type.
   */
  outboundPhoneNumber?: string;
  /**
   * The text-to-speech voice service used for the assessment template.
   */
  voice?: VoiceObject;
  /**
   * Ordered array of questions asked. Each question has an associated answer.
   */
  questions: AssessmentQuestion[];
  /**
   * Resouce metadata
   */
  _meta?: {
    id: string;
    created: string;
    modified?: string;
  };
  /**
   * Comment on assessment
   */
  comment?: string | null;

  /**
   * Data to support pathway management
   */
  metadata: AssessmentTemplateMetadata;

  /**
   * The Id of the template the generated this assessment
   */
  templateId?: string;

  /**
   * The resolved template object that generated this assessment
   */
  template?: AssessmentTemplate;
}

export type AssessmentTemplateMetadata = {
  /**
   * The unique IDs of the program the pathway is associated with.
   */
  programIds: string[];
  /**
   * The type of assessment.
   */
  types?: ("DAY OF WEEK" | "WELCOME" | "SURVEY")[];
  /**
   * How often the pathway should run.
   */
  frequencies?: ("ONCE" | "REPEAT")[];
  /**
   * Day(s) the pathway should run.
   */
  preferredDays?:
    | (
        | "MONDAY"
        | "TUESDAY"
        | "WEDNESDAY"
        | "THURSDAY"
        | "FRIDAY"
        | "SATURDAY"
        | "SUNDAY"
      )[];
  /**
   * Number of days the patient has been enrolled in a program.
   */
  enrollmentDays?: number[];
  /**
   * When the pathway should run.
   */
  preferredTimes?: string[];

  /**
   * Used for displaying pathway template UI
   */
  pathwayTemplate?: AutoCompleteType | null;

  /**
   * Used for displaying list of Pathway templates to select from in UI
   */
  pathwayTemplateOptions?: AssessmentTemplate[] | null;
};

export type AssessmentQuestion = {
  id?: string;
  label?: string;
  /**
   * Question prompt asked of the patient.
   */
  prompt: string;
  /**
   * Answer to the question asked.
   */
  answer: AssessmentAnswer;
};

export type AssessmentAnswer = {
  /**
   * Answer data type.
   */
  type: AssessmentAnswerType;
  /**
   * The Identifier of the next question to execute.
   */
  nextId?: string;
  /**
   * The assessment answer type "boolean" with a response of 'yes'.
   */
  affirmative?: {
    nextId: string;
  };
  /**
   * The assessment answer type "boolean" with a response of 'no'.
   */
  negative?: {
    nextId: string;
  };
  /**
   * Unknown
   */
  value?: string;
};

export enum AssessmentAnswerType {
  Age = "age",
  Alarm = "alarm",
  Boolean = "boolean",
  Done = "done",
  Gender = "gender",
  None = "none",
  Number = "number",
  Number10Scale = "number_10_scale",
  Number11Scale = "number_11_scale",
  Number125Scale = "number_125_scale",
  Number20Scale = "number_20_scale",
  Number2Scale = "number_2_scale",
  Number3Scale = "number_3_scale",
  Number5Scale = "number_5_scale",
  Percent = "percent",
  Range = "range",
  Scale15 = "scale15",
  Scale1of10 = "scale1_10",
  Scale1of10_2 = "scale_1_10",
  Scale1of100 = "scale_1_100",
  Scale1of5 = "scale_1_5",
  Scale1to10 = "scale_1to10",
  Scale1to100 = "scale_1to_100",
  Scale1to5 = "scale_1to_5",
  Start = "start",
  Text = "text",
  Text2Scale = "text_2_scale",
  Text3Scale = "text_3_scale",
  Text4Scale = "text_4_scale",
  Text5Scale = "text_5_scale",
  Transfer = "transfer",
  TransferFlow = "transfer_flow",
}
