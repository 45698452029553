import { Constructor } from "../../types/constructor";
import { flattenToObject } from "../helper";
import { Policy } from "../policy.interface";
import { ScopeBuilder } from "../scopeBuilders/scopeBuilder.interface";
import { PolicyBuilder } from "./policyBuilder.interface";

export class OrganizationUserPolicyBuilder extends PolicyBuilder {
  async build(): Promise<Policy> {
    return {
      [this.scope.organization.build(this.organizationId)]: this.scopeValue,
      ...flattenToObject(
        this.practices.map((id: string) => ({
          [this.scope.practice.build(id)]: this.scopeValue,
        }))
      ),
    };
  }
}
