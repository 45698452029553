import React from "react";
import Loader from "rsuite/Loader";

import { Dimmer } from "@/components/Dimmer";

export type Props = {
  pending?: boolean;
  dimmerClassName?: string;
  dimmerStyle?: React.CSSProperties;
};

export const LoadingDimmer: React.FC<Props> = (props) => {
  const { pending, dimmerClassName, dimmerStyle } = props;

  return pending ? (
    <Dimmer visible className={dimmerClassName} style={dimmerStyle}>
      <Loader style={{ margin: "0px auto" }} />
    </Dimmer>
  ) : null;
};
