import { DateTime } from "luxon";

import { CURRENT_DATE, DAYS_LIMIT } from "@/library/constants";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";

export const getHealthSinceDate = (): DateTime => {
  const datetime = convertDateToLocalTimezone(CURRENT_DATE);
  return datetime.minus({ days: DAYS_LIMIT });
};

export const syncPatient = async (patientId: string): Promise<Patient> => {
  const selectedPatient = (
    await PatientModel.sync(patientId, {
      healthSince: getHealthSinceDate().toUTC().toString(),
      includeDeviceSnapshot: true,
    })
  ).pluckAll();
  return selectedPatient;
};

export const setSelectedOrganization = async (
  patient: Patient,
  selectOrganization: (organization: Organization) => void
): Promise<void> => {
  const orgId = (await PracticeModel.sync(patient?.practiceId)).pluck(
    "organizationIds"
  )[0];
  selectOrganization((await OrganizationModel.sync(orgId)).pluckAll());
};
