import { Patient } from "@/domain/patient/model/types";
import {
  StateMachine,
  StateTransitionMachine,
} from "@/domain/patient/view/form/manageState/state";

type OwnProps = {
  type: "button" | "text" | "dropdown";
  targetedPatient: Patient;
  buttonText?: string;
  onShowConfirmModal: (val: boolean) => void;
  setReturnKitModal: () => void;
  onDisableAcceptBtn: (val: boolean) => void;
  onSelectStatus: (status: PatientState | undefined) => void;
};
type ConnectedProps = {};
type DispatchProps = {};
export type ManageStateProps = OwnProps & DispatchProps & ConnectedProps;

export type Writable<T> = { -readonly [P in keyof T]: T[P] };

export enum PatientState {
  Added = "ADDED",
  Preactive = "PREACTIVE",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Completed = "COMPLETED",
}

/**
 * The possible transitions from one state to another.
 */
export enum PatientTransition {
  /**
   * Triggered by 'create patient' endpoint.
   * May also be manually activated if a 'COMPLETED' patient re-enrolls.
   * Will generate a new encounter ID.
   */
  AddPatient = "ADD_PATIENT",
  /**
   * Confirms a patient for a program.
   */
  ActivatePatient = "ACTIVATE_PATIENT",
  /**
   * Patient declines to participate in a program.
   */
  PatientDeclined = "PATIENT_DECLINED",
  /**
   * When the patient completes their first vital measurement or assessment,
   * the patient is automatically activated.
   */
  FirstBiometric = "FIRST_BIOMETRIC",
  /**
   * Temporarily deactivates a patient.
   */
  DeactivatePatient = "DEACTIVATE_PATIENT",
  /**
   * Reactivate an inactive patient.
   */
  ReactivatePatient = "REACTIVATE_PATIENT",
  /**
   * Complete a patient program.
   */
  CompletePatient = "COMPLETE_PATIENT",
}

export const patientNextState = {
  [PatientState.Added]: {
    allowedState: [PatientState.Preactive, PatientState.Completed],
  },
  [PatientState.Preactive]: {
    allowedState: [PatientState.Active, PatientState.Completed],
  },
  [PatientState.Active]: {
    allowedState: [PatientState.Inactive, PatientState.Completed],
  },
  [PatientState.Inactive]: {
    allowedState: [PatientState.Active, PatientState.Completed],
  },
  [PatientState.Completed]: {
    allowedState: [],
  },
};

export const PatientStateTransition: StateTransitionMachine<
  PatientState,
  PatientTransition
> = {
  [PatientState.Added]: {
    stateTransition: [
      {
        resultingState: PatientState.Preactive,
        transition: PatientTransition.ActivatePatient,
      },
      {
        resultingState: PatientState.Completed,
        transition: PatientTransition.PatientDeclined,
      },
    ],
  },
  [PatientState.Preactive]: {
    stateTransition: [
      {
        resultingState: PatientState.Active,
        transition: PatientTransition.FirstBiometric,
      },
      {
        resultingState: PatientState.Completed,
        transition: PatientTransition.PatientDeclined,
      },
    ],
  },
  [PatientState.Active]: {
    stateTransition: [
      {
        resultingState: PatientState.Completed,
        transition: PatientTransition.CompletePatient,
      },
      {
        resultingState: PatientState.Inactive,
        transition: PatientTransition.DeactivatePatient,
      },
    ],
  },
  [PatientState.Inactive]: {
    stateTransition: [
      {
        resultingState: PatientState.Active,
        transition: PatientTransition.ReactivatePatient,
      },
      {
        resultingState: PatientState.Completed,
        transition: PatientTransition.CompletePatient,
      },
    ],
  },
  [PatientState.Completed]: undefined,
};

/**
 * PatientStateMachine is used by {@link transitionState()} to validate a
 * transition in a patient's state and returns the resulting state if the
 * transition is valid.
 */
export const PatientStateMachine: StateMachine<
  PatientTransition,
  PatientState
> = {
  [PatientTransition.AddPatient]: {
    allowedFrom: [PatientState.Completed],
    resultingState: PatientState.Added,
  },
  [PatientTransition.ActivatePatient]: {
    allowedFrom: [PatientState.Added],
    resultingState: PatientState.Preactive,
  },
  [PatientTransition.PatientDeclined]: {
    allowedFrom: [PatientState.Added, PatientState.Preactive],
    resultingState: PatientState.Completed,
  },
  [PatientTransition.FirstBiometric]: {
    allowedFrom: [PatientState.Preactive],
    resultingState: PatientState.Active,
  },
  [PatientTransition.DeactivatePatient]: {
    allowedFrom: [PatientState.Active],
    resultingState: PatientState.Inactive,
  },
  [PatientTransition.ReactivatePatient]: {
    allowedFrom: [PatientState.Inactive],
    resultingState: PatientState.Active,
  },
  [PatientTransition.CompletePatient]: {
    allowedFrom: [PatientState.Active, PatientState.Inactive],
    resultingState: PatientState.Completed,
  },
};

export const getPatientNextState = (
  currentState: PatientState
): PatientState[] => patientNextState[currentState]?.allowedState || [];

export type patientTextType = {
  title: string;
  img: any;
};
