import React, { useState } from "react";
import DatePicker from "react-datepicker";
import cx from "clsx";
import styles from "./style.module.scss";
import { DateFormat, DatePickerPlaceholder } from "./Constants";
export interface Props {
  startDate: Date;
  endDate: Date;
  startMax?: Date;
  endMax?: Date;
  onStartChange?: (startDate: Date) => void;
  onEndChange?: (startDate: Date) => void;
  onDatesChange?: (startDate: Date, endDate: Date) => void;
  containerClassName?: string;
}

export const DateRangePickerReact: React.FC<Props> = (props) => {
  const {
    startDate,
    endDate,
    startMax,
    endMax,
    onStartChange,
    onEndChange,
    onDatesChange,
    containerClassName,
  } = props;

  const [tempStart, setTempStart] = useState(startDate);
  const [tempEnd, setTempEnd] = useState(endDate);

  const handleCalendarClose = () => {
    if (tempStart && tempEnd && onDatesChange) {
      onDatesChange(tempStart, tempEnd);
    }
  };
  const handleStartChange = (date: Date) => {
    setTempStart(date);
    if (onStartChange) onStartChange(date);
  };
  const handleEndChange = (date: Date) => {
    setTempEnd(date);
    if (onEndChange) onEndChange(date);
  };

  return (
    <div>
      <div className={cx(containerClassName, styles.dateRangeParentDiv)}>
        <div className={styles.fromDateDiv}>
          <div className="d-flex">
            <label htmlFor="fromDate" className={styles.dateLabel}>
              From
            </label>
          </div>
          <DatePicker
            className="datePicker"
            name="fromDate"
            dateFormat={DateFormat}
            placeholderText={DatePickerPlaceholder}
            selected={startDate}
            onCalendarClose={handleCalendarClose}
            onChange={handleStartChange}
            maxDate={startMax || new Date(endDate)}
            required={false}
            disabledKeyboardNavigation
          />
        </div>
        <div className={styles.fromDateDiv}>
          <div className="d-flex">
            <label htmlFor="toDate" className={styles.dateLabel}>
              To
            </label>
          </div>
          <DatePicker
            className="datePicker"
            name="toDate"
            dateFormat={DateFormat}
            placeholderText={DatePickerPlaceholder}
            selected={endDate}
            onCalendarClose={handleCalendarClose}
            onChange={handleEndChange}
            minDate={endMax ? undefined : new Date(startDate)}
            maxDate={endMax}
            required={false}
            disabledKeyboardNavigation
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePickerReact;
