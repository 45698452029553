import { Route } from "react-router-dom";
import { UserModel } from "../../domain/user/model";
import { Routable } from "./";

/**
 * Route for Practice User landing page.
 */
export class PracticeUserRoute implements Routable {
  private params: { practiceId: string };
  private uri: string[] = ["patients"];

  constructor(private readonly user: UserModel) {
    this.params = { practiceId: user.parentId };
  }

  static build = (userModel: UserModel): Routable =>
    new PracticeUserRoute(userModel);

  get to(): string {
    return this.URI + "?" + this.query;
  }

  get URI(): string {
    return this.uri.join("/");
  }

  get query(): string {
    return Object.entries(this.params)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
  }
}
