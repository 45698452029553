import { PatientNoteType } from "@/library/types/note";
import {
  AlertLevel,
  AlertLevelNew,
  NoteAction,
  NoteDisposition,
  SeverityLevel,
} from "./types";
import vars from "@/styles/vars.module.scss";

export const PatientNoteTypes = {
  Appointment: PatientNoteType.Appointment as PatientNoteType,
  Call: PatientNoteType.Call as PatientNoteType,
  Message: PatientNoteType.Message as PatientNoteType,
  Clinical: PatientNoteType.Clinical as PatientNoteType,
  Observation: PatientNoteType.Observation as PatientNoteType,
  Assessment: PatientNoteType.Assessment as PatientNoteType,
  Reviewed: PatientNoteType.Reviewed as PatientNoteType,
};

export const alertLevelTextColor = {
  [AlertLevel.NO_ALERT]: "text-dark",
  [AlertLevel.GREEN_ALERT]: "text-success",
  [AlertLevel.YELLOW_ALERT]: "text-warning",
  [AlertLevel.RED_ALERT]: "text-danger",
  [AlertLevel.BLACK_ALERT]: "text-black",
  [AlertLevel.DISABLED_ALERT]: "text-gray",
};

export const alertLevelBgColor = {
  [AlertLevel.NO_ALERT]: "bg-light",
  [AlertLevel.GREEN_ALERT]: "bg-success",
  [AlertLevel.YELLOW_ALERT]: "bg-warning",
  [AlertLevel.RED_ALERT]: "bg-danger",
  [AlertLevel.BLACK_ALERT]: "bg-black",
  [AlertLevel.DISABLED_ALERT]: "bg-gray",
};

export const alertLevelColor = {
  [AlertLevel.NO_ALERT]: vars["color-alert-success"],
  [AlertLevel.GREEN_ALERT]: vars["color-alert-success"],
  [AlertLevel.YELLOW_ALERT]: vars["color-alert-warning"],
  [AlertLevel.RED_ALERT]: vars["color-alert-danger"],
  [AlertLevel.BLACK_ALERT]: vars["color-alert-black"],
  [AlertLevel.DISABLED_ALERT]: vars["color-alert-gray"],
};

export const AlertLevelLabels = {
  [AlertLevel.NO_ALERT]: "None",
  [AlertLevel.GREEN_ALERT]: "None",
  [AlertLevel.YELLOW_ALERT]: "Medium",
  [AlertLevel.RED_ALERT]: "High",
};

export const getSeverityAlertLevel = (severityScore: number | undefined) => {
  let alertLevel = AlertLevel.NO_ALERT;

  if (severityScore !== undefined) {
    if (severityScore >= SeverityLevel.SEVERITY_RED) {
      alertLevel = AlertLevel.RED_ALERT;
    } else if (severityScore >= SeverityLevel.SEVERITY_YELLOW) {
      alertLevel = AlertLevel.YELLOW_ALERT;
    } else alertLevel = AlertLevel.GREEN_ALERT;
  }

  return alertLevel;
};

export const getAlertLevelBgColor = (alertLevel: AlertLevel) =>
  alertLevelBgColor[alertLevel];

export const getAlertLevelTextColor = (alertLevel: AlertLevel) =>
  alertLevelTextColor[alertLevel];

export const getAlertLevelColor = (alertLevel: AlertLevel) =>
  alertLevelColor[alertLevel];

export const NoteActions = {
  CreateAppointmentNote: NoteAction.CreateAppointmentNote,
  CreateCallNote: NoteAction.CreateCallNote,
  CreateClinicalNote: NoteAction.CreateClinicalNote,
  CreateMessageNote: NoteAction.CreateMessageNote,
  CreateObservationNote: NoteAction.CreateObservationNote,
  CreateAssessmentNote: NoteAction.CreateAssessmentNote,
};

export const NoteDispositions = {
  Accepted: NoteDisposition.Accepted,
  Rejected: NoteDisposition.Rejected,
  Historical: NoteDisposition.Historical,
};

export const AlertLevels = {
  Default: AlertLevel.NO_ALERT,
  Green: AlertLevel.GREEN_ALERT,
  Yellow: AlertLevel.YELLOW_ALERT,
  Red: AlertLevel.RED_ALERT,
};

export const getSeverityAlertLevelNew = (severityScore: number | undefined) => {
  let alertLevel = AlertLevelNew.YELLOW_ALERT;

  if (severityScore !== undefined) {
    if (severityScore >= SeverityLevel.SEVERITY_RED) {
      alertLevel = AlertLevelNew.RED_ALERT;
    } else if (severityScore >= SeverityLevel.SEVERITY_YELLOW) {
      alertLevel = AlertLevelNew.YELLOW_ALERT;
    } else alertLevel = AlertLevelNew.GREEN_ALERT;
  }

  return alertLevel;
};

export const getAlertLevelBgColorNew = (alertLevel: AlertLevelNew) =>
  alertLevelBgColorNew[alertLevel];

export const alertLevelBgColorNew = {
  [AlertLevelNew.GREEN_ALERT]: "greenAlert",
  [AlertLevelNew.YELLOW_ALERT]: "yellowAlert",
  [AlertLevelNew.RED_ALERT]: "redAlert",
};

export const getAlertLevelColorBG = (alertLevel: AlertLevelNew) =>
  alertLevelColorBG[alertLevel];

export const alertLevelColorBG = {
  [AlertLevelNew.GREEN_ALERT]: "#66c793",
  [AlertLevelNew.YELLOW_ALERT]: "#ffce1f",
  [AlertLevelNew.RED_ALERT]: "#db0b0b",
};

export const getAlertValueLevelBgColorNew = (alertLevel: AlertLevelNew) =>
  alertValueLevelColorBG[alertLevel];

export const alertValueLevelColorBG = {
  [AlertLevelNew.GREEN_ALERT]: "normalValue",
  [AlertLevelNew.YELLOW_ALERT]: "mediumValue",
  [AlertLevelNew.RED_ALERT]: "highValue",
};
