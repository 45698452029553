import { ObservationTypes } from "../../model/constants";

export const AssessmentType = "PATHWAY";

export const DateTimeDisplayFormat = {
  DateNumeric: 1,
  Time24Hour: 2,
  Time12Hour: 3,
};

export const ArrayValidLength = 1;

export const NoDateDifference = 0;

export const AlertTypeLabelMapNew = {
  [ObservationTypes.BloodPressure]: "BP",
  [ObservationTypes.GlucoseLevel]: "BG",
  [ObservationTypes.HeartRate]: "HR",
  [ObservationTypes.PulseOximetry]: "O2",
  [ObservationTypes.Spirometry]: "Resp.",
  [ObservationTypes.Temperature]: "Temp.",
  [ObservationTypes.Weight]: "Wt.",
  [AssessmentType]: "Pthwy",
};

export const BloodPressureTypes = {
  Systolic: 0,
  Diastolic: 1,
  Both: 2,
};

export type ChartNoteType = {
  created: string;
  severityScore: number;
};
