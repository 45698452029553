import { specialties } from "./../../../library/types/specialties";
import * as Yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { CountryCodes, usStates } from "../../../library/types/address";
import { Credentials, Credential } from "../../../library/types/credential";
import { Language, Languages } from "../../../library/types/language";
import { MAX_HEIGHT, MAX_WEIGHT } from "../../../library/constants";
import {
  OBSERVATIONS_BOUNDS,
  ObservationType,
} from "@/domain/observations/types";

const phoneUtil = PhoneNumberUtil.getInstance();

export const extractphone = (phone: string) => {
  return phone
    .replace(/\)/g, "")
    .replace(/\(/g, "")
    .replace(/-/g, "")
    .replace(/_/g, "")
    .replace(/ /g, "");
};

export const formatPhone = (phone: string) =>
  phone.replace(/(\d{1})\-?(\d{3})\-?(\d{3})\-?(\d{4})/, "$1-$2-$3-$4");
export const phoneMask = (phone: string) =>
  phone.replace(/(\d{1})\-?(\d{3})\-?(\d{3})\-?(\d{4})/, "$1($2) $3-$4");

export const Validation = {
  id: Yup.string().nullable(),
  name: Yup.string()
    .max(2, "too short")
    .max(50, "too long")
    .required("required"),
  nameNotRequired: Yup.string()
    .max(2, "too short")
    .max(50, "too long")
    .nullable(),
  prefix: Yup.string().max(2, "too short").max(50, "too long").nullable(),
  suffix: Yup.string().max(2, "too short").max(50, "too long").nullable(),
  organizationType: Yup.string()
    .oneOf(
      [
        "Health System",
        "Home Health",
        "Hospice",
        "Physician Practice",
        "Population Health",
        "3rd Party Monitoring",
        "Other",
      ],
      "Invalid Organization Type"
    )
    .required("required"),
  practiceType: Yup.string()
    .oneOf(
      [
        "Health System",
        "Home Health",
        "Hospice",
        "Physician Practice",
        "Population Health",
        "3rd Party Monitoring",
        "Other",
      ],
      "Invalid Practice Type"
    )
    .required("required"),
  fulfillment: Yup.string()
    .oneOf(["Anelto", "Self", "No Kit"])
    .required("required"),
  status: Yup.string().oneOf(["Active", "Inactive"]).required("required"),
  addressOne: Yup.string()
    .min(2, "please enter a full address")
    .required("required"),
  addressTwo: Yup.string(),
  city: Yup.string().min(2, "please enter a valid city").required("required"),
  state: Yup.string().oneOf(usStates).required("required"),
  country: Yup.string()
    .oneOf(
      CountryCodes.map(
        (country: { name: string; code: string }) => country.code
      )
    )
    .required("required"),
  zip: Yup.string()
    .matches(/^[0-9]+$/gi, "zip code must be a number")
    .min(5, "please enter a valid zip code")
    .max(5, "please enter a valid zip code")
    .required("required"),
  phone: Yup.string()
    .required("required")
    .test(`phone-validity`, "", function (value) {
      const { path, createError } = this;

      let isValidPhone: boolean;
      try {
        const parsedValue = extractphone(value as string);
        isValidPhone =
          !!parsedValue &&
          phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(parsedValue));
      } catch (e) {
        isValidPhone = false;
      }

      return (
        isValidPhone ||
        createError({ path, message: "please enter a valid phone number" })
      );
    }),
  phoneNotRequired: Yup.string()
    .nullable()
    .test(`phone-validity`, "", function (value) {
      const { path, createError } = this;

      let isValidPhone: boolean;
      let parsedValue: string | undefined | null = value;

      if (parsedValue) {
        parsedValue = (value as string)
          .replace(/\)/g, "")
          .replace(/\(/g, "")
          .replace(/-/g, "")
          .replace(/_/g, "")
          .replace(/ /g, "");
      }
      try {
        isValidPhone = phoneUtil.isValidNumber(
          phoneUtil.parseAndKeepRawInput(parsedValue ?? "")
        );
      } catch (e) {
        if (parsedValue === "+1" || parsedValue === undefined) {
          isValidPhone = true;
        } else {
          isValidPhone = false;
        }
      }

      return (
        isValidPhone ||
        createError({ path, message: "please enter a valid phone number" })
      );
    }),
  required: Yup.string().required("required"),
  notRequired: Yup.string().notRequired(),
  notRequiredArray: Yup.array().notRequired(),
  requiredArray: Yup.array().min(1, "required"),
  nullRequiredArray: Yup.array().min(1, "required").nullable(),
  location: Yup.string()
    .oneOf(["CAREGIVER", "HOME_CARE", "PATIENT", "PROVIDER", "OTHER"])
    .required("required"),
  appointmentLocation: Yup.string()
    .oneOf(["HOME", "OFFICE", "TELEMEDICINE"])
    .required("required"),
  observationType: Yup.string()
    .oneOf([
      "BLOOD_PRESSURE",
      "GLUCOSE_LEVEL",
      "HEART_RATE",
      "PULSE_OXIMETRY",
      "SPIROMETRY",
      "TEMPERATURE",
      "WEIGHT",
    ])
    .required("required"),
  notRequiredBoolean: Yup.boolean().notRequired(),
  imageUrl: Yup.string().nullable(),
  contactEmail: Yup.string()
    .nullable()
    .email("Invalid email")
    .required("required"),
  email: Yup.string().required("required").email("Invalid email"),
  emailNotRequired: Yup.string().nullable().email("Invalid email"),
  domain: Yup.string()
    .matches(/^[-\w]{3,}\.[a-zA-Z]{2,}$/, "i.e. example.com")
    .required("required"),
  contactPosition: Yup.string()
    .nullable()
    .min(1, "too short")
    .max(50, "too long")
    .required("required"),
  contactName: Yup.string()
    .min(2, "too short")
    .max(50, "too long")
    .required("required"),
  npi: Yup.string()
    .matches(/^[1]?[0-9]+$/gi, "must be numbers")
    .min(10, "too short")
    .max(10, "too long")
    .nullable(),
  mrn: Yup.string()
    .matches(/.*/gm, "must be a character")
    .min(2, "too short")
    .max(32, "too long"),
  credentials: Yup.string()
    .oneOf(Credentials.map((credential: Credential) => credential.abbreviation))
    .required("required"),
  sex: Yup.string().oneOf(["MALE", "FEMALE"]).required("required"),
  race: Yup.string()
    .required("required")
    .oneOf([
      "WHITE",
      "BLACK",
      "OTHER",
      "ASIAN",
      "HISPANIC",
      "NORTH_AMERICAN_NATIVE",
      "UNKNOWN",
    ]),
  gender: Yup.string().nullable().oneOf(["Male", "Female"]),
  specialty: Yup.string().nullable().oneOf(specialties),
  language: Yup.string()
    .required("required")
    .oneOf(Languages.map((language: Language) => language.abbreviation)),
  date: Yup.string()
    .required("required")
    .test(`date-validity`, "", function (value) {
      const { path, createError } = this;
      const parsedValue = (value as string)
        ?.replace(/\-/g, "")
        .replace(/\_/g, "");
      return (
        (parsedValue && parsedValue.length === 8) ||
        createError({ path, message: "please provide a valid date" })
      );
    }),
  dateNotRequired: Yup.string()
    .nullable()
    .test(`date-validity`, "", function (value) {
      const { path, createError } = this;
      const parsedValue = (value as string)
        ?.replace(/\-/g, "")
        .replace(/\_/g, "");
      return (
        parsedValue === undefined ||
        parsedValue.length === 8 ||
        createError({ path, message: "please provide a valid date" })
      );
    }),
  priority: Yup.string().oneOf(["HIGH", "MEDIUM", "LOW", "NONE"]).nullable(),
  height: Yup.number()
    .nullable()
    .min(0, `Value must be between 0 and ${MAX_HEIGHT} inches.`)
    .max(90, `Value must be between 0 and ${MAX_HEIGHT} inches.`),
  heightUnit: Yup.string().oneOf(["cm", "in"]).nullable(),
  weight: Yup.number()
    .nullable()
    .min(0, `Value must be between 0 and ${MAX_WEIGHT}.`)
    .max(800, `Value must be between 0 and ${MAX_WEIGHT}.`),
  weightUnit: Yup.string().oneOf(["lbs", "kg"]).nullable(),
  phoneType: Yup.string().oneOf(["HOME", "CELL", "WORK"]).nullable(),
  requiredString: Yup.string().required("required"),
  nullableString: Yup.string().nullable(),
  firstName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("required"),
  lastName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("required"),

  role: Yup.string()
    .required("required")
    .oneOf([
      "Anelto Admin",
      "Care Management Admin",
      "Care Management Medical Professional",
      "Care Management Associate",
      "Practice Admin",
      "Practice Medical Professional",
      "Practice Associate",
    ]),

  facility: Yup.string()
    .required("required")
    .oneOf(["Select One", "All facilities/practices"]),
  wholeNumberNotRequired: Yup.number()
    .nullable()
    .min(0, "Value must be a whole number."),
  naturalNumberRequired: Yup.number()
    .required("required")
    .min(1, "Value must be greater than 0."),
};

/**
 * Observation validation object
 */
export const ObservationValidation = {
  /**
   * Blood Pressure Systolic
   */
  BloodPressureSystolic: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS.BloodPressureSystolic.lowerBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.BloodPressureSystolic.lowerBound} - ${OBSERVATIONS_BOUNDS.BloodPressureSystolic.upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS.BloodPressureSystolic.upperBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.BloodPressureSystolic.lowerBound} - ${OBSERVATIONS_BOUNDS.BloodPressureSystolic.upperBound}`
    ),
  /**
   * Blood Pressure Diastolic
   */
  BloodPressureDiastolic: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS.BloodPressureDiastolic.lowerBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.BloodPressureDiastolic.lowerBound} - ${OBSERVATIONS_BOUNDS.BloodPressureDiastolic.upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS.BloodPressureDiastolic.upperBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.BloodPressureDiastolic.lowerBound} - ${OBSERVATIONS_BOUNDS.BloodPressureDiastolic.upperBound}`
    ),
  /**
   * Spirometry FEV
   */
  SpirometryFev: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS.SpirometryFev.lowerBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.SpirometryFev.lowerBound} - ${OBSERVATIONS_BOUNDS.SpirometryFev.upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS.SpirometryFev.upperBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.SpirometryFev.lowerBound} - ${OBSERVATIONS_BOUNDS.SpirometryFev.upperBound}`
    ),
  /**
   * Spirometry PEF
   */
  SpirometryPef: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS.SpirometryPef.lowerBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.SpirometryPef.lowerBound} - ${OBSERVATIONS_BOUNDS.SpirometryPef.upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS.SpirometryPef.upperBound,
      `Value must be between ${OBSERVATIONS_BOUNDS.SpirometryPef.lowerBound} - ${OBSERVATIONS_BOUNDS.SpirometryPef.upperBound}`
    ),
  /**
   * Glucose Level
   */
  [ObservationType.GlucoseLevel]: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].lowerBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].upperBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.GlucoseLevel].upperBound}`
    ),
  /**
   * Heart Rate
   */
  [ObservationType.HeartRate]: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS[ObservationType.HeartRate].lowerBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.HeartRate].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.HeartRate].upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS[ObservationType.HeartRate].upperBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.HeartRate].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.HeartRate].upperBound}`
    ),
  /**
   * Pulse Oximetry
   */
  [ObservationType.PulseOximetry]: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].lowerBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].upperBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.PulseOximetry].upperBound}`
    ),
  /**
   * Temperature
   */
  [ObservationType.Temperature]: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS[ObservationType.Temperature].lowerBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.Temperature].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.Temperature].upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS[ObservationType.Temperature].upperBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.Temperature].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.Temperature].upperBound}`
    ),

  /**
   * Weight
   */
  [ObservationType.Weight]: Yup.number()
    .required("required")
    .min(
      OBSERVATIONS_BOUNDS[ObservationType.Weight].lowerBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.Weight].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.Weight].upperBound}`
    )
    .max(
      OBSERVATIONS_BOUNDS[ObservationType.Weight].upperBound,
      `Value must be between ${
        OBSERVATIONS_BOUNDS[ObservationType.Weight].lowerBound
      } - ${OBSERVATIONS_BOUNDS[ObservationType.Weight].upperBound}`
    ),
};

export const ResetPasswordValidation = {
  currentPassword: Yup.string().required("required"),
  newPassword: Yup.string()
    .required("required")
    .min(8, "Password length must be a minimum of 8.")
    .notOneOf(
      [Yup.ref("currentPassword"), null],
      "Cannot use the current password."
    ),
  confirmPassword: Yup.string()
    .required("required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
};
