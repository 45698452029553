import { HeaderContent, SortedState } from "@/components/table/head/sortable";

export const ORGANIZATION_TYPES = [
  { label: "All", value: "" },
  { label: "Health System", value: "Health System" },
  { label: "Home Health", value: "Home Health" },
  { label: "Hospice", value: "Hospice" },
  { label: "Physician Practice", value: "Physician Practice" },
  { label: "Population Health", value: "Population Health" },
  { label: "3rd Party Monitoring", value: "3rd Party Monitoring" },
  { label: "Other", value: "Other" },
];

export const PracticeTableHeader: HeaderContent[] = [
  {
    name: "name",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "type",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "city",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "address.locality",
  },
  {
    name: "state",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "address.region",
  },
  {
    name: "contact",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "contact.name",
  },
  {
    name: "email",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: "contact.email",
  },
  {
    name: "providers",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "actions",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
];
