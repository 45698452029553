import {
  Patient,
  PatientConditionForm,
  PatientForm,
} from "@/domain/patient/model/types";
import { ProviderId } from "@/domain/provider/redux";
import { INITIAL_VALUES } from "./constants";
import { DateTime } from "luxon";

export const defaultProgramDuration = ["", "30", "45", "60", "90"];
export const getInitialValues = (
  isEditMode?: boolean,
  patient?: Patient,
  targetProviderId?: ProviderId,
  diagnosisField?: PatientConditionForm[]
): PatientForm => {
  if (isEditMode && patient) {
    const encounterStartDate =
      patient.encounter &&
      patient.encounter.startDateTime &&
      DateTime.fromISO(patient.encounter.startDateTime);
    const encounterEndDate =
      patient.encounter &&
      patient.encounter.endDateTime &&
      DateTime.fromISO(patient.encounter.endDateTime);
    const programDuration = patient.programDuration
      ? patient.programDuration
      : encounterStartDate && encounterEndDate
      ? encounterEndDate.diff(encounterStartDate).as("days").toFixed(0)
      : "";

    return {
      id: patient.id || "",
      mrn: patient.mrn || "",
      firstName: patient.demographics.legalName.firstName || "",
      lastName: patient.demographics.legalName.lastName || "",
      middleName: patient.demographics.legalName.middleName || "",
      preferredName: patient.demographics.preferredName || "",
      prefix: patient.demographics.legalName.prefix || "",
      suffix: patient.demographics.legalName.suffix || "",
      birthSex:
        patient.demographics.birthSex === null
          ? "UNKNOWN"
          : patient.demographics.birthSex || "",
      gender: patient.demographics.gender || "",
      race: patient.demographics.race || "UNKNOWN",
      dateOfBirth: patient.demographics.dateOfBirth || "",
      address: patient.address.street[0] || "",
      city: patient.address.locality || "",
      state: patient.address.region || "",
      zip: patient.address.postalCode || "",
      country: patient.address.country || "",
      careTeam: patient.assignedUserId || "",
      primaryPhone: patient.phone.primary.number || "",
      secondaryPhone: patient.phone.secondary?.number || "",
      priority: patient.priority === null ? "NONE" : patient.priority || "",
      height: patient.anthropometry.height?.value || "",
      heightUnit: "in",
      weight: patient.anthropometry.weight?.value || "",
      weightUnit: "lbs",
      email: patient.email || "",
      language: patient.demographics?.language || "",
      representativesFirstName:
        patient.designatedRepresentatives[0]?.name.split(" ")[0] || "",
      representativesLastName:
        patient.designatedRepresentatives[0]?.name.split(" ")[1] || "",
      representativesEmail: patient.designatedRepresentatives[0]?.email || "",
      representativesPhone:
        patient.designatedRepresentatives[0]?.phone.primary.number || "",
      representativesPhoneType: "",
      representativesRelation:
        patient.designatedRepresentatives[0]?.relation || "",
      representativesAllowSms:
        patient.designatedRepresentatives[0]?.phone.primary.isSmsAllowed ||
        false,
      ethnicity: patient.demographics.ethnicity || "",
      allowSMS: {
        primary: patient.phone.primary?.isSmsAllowed,
        secondary: patient.phone.secondary?.isSmsAllowed,
      },
      diagnosis: [],
      providerId:
        patient?.providerId || targetProviderId || patient?.provider?.id || "",
      PRIMARY: diagnosisField?.length
        ? diagnosisField[0].displayName || ""
        : "",
      Practice: patient?.practiceId || "",
      billingType: patient.billingType || "",
      encounter: patient?.encounter,
      programId: patient?.programId === null ? "null" : patient?.programId,
      programDuration: !defaultProgramDuration.includes(programDuration)
        ? "0"
        : programDuration,
      otherProgramDuration: patient?.otherProgramDuration
        ? patient?.otherProgramDuration
        : !defaultProgramDuration.includes(programDuration)
        ? programDuration
        : "",
    };
  }

  return {
    ...INITIAL_VALUES,
    providerId: targetProviderId || "",
  };
};

export const option = {
  mask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  placeholder: "MMMM-DD-YYYY",
};
