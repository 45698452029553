import React from "react";
import cx from "clsx";

import styles from "./styles.module.scss";

type Props = {
  visible?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const Dimmer: React.FC<Props> = (props) => {
  const { visible = true, children, className, style, onClick } = props;

  return (
    /* eslint-disable */
    <div
      role="article"
      onClick={onClick}
      className={cx(
        styles.dimmer,
        styles.dimmerAnimation,
        visible && styles.show,
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};
