import { Route } from "react-router-dom";
import { UserModel, UserParentTypes } from "../../domain/user/model";
import { Routable } from "./";

/**
 * Route for Root User landing page.
 */
export class RootUserRoute implements Routable {
  private _uri: string[] = ["organizations"];

  constructor(private readonly user: UserModel) {}

  static build = (userModel: UserModel): Routable =>
    new RootUserRoute(userModel);

  get to(): string {
    return this.URI;
  }

  get URI(): string {
    return this._uri.join("/");
  }
}
