import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PresenceType, useIdleTimer } from "react-idle-timer";
import { Button, Modal } from "rsuite";
import cx from "clsx";

import { selectCurrentUser } from "@/domain/user/redux/selectors";
import { LOGOUT } from "@/library/constants";
import { msToMinutes } from "./helpers";

import styles from "./styles.module.scss";

export type Props = {
  className?: string;
};

const MINUTE = 1000 * 60;
const DEFAULT_MS = MINUTE * 5; // Default if user does not have a value set
const DISPLAY_MS = MINUTE * 2; // When to display the widget
const PROMPT_MS = MINUTE * 1; // When to display the modal

export const IdleDetector: React.FC<Props> = (props) => {
  const { className } = props;

  const [state, setState] = useState<"active" | "idle">("active");
  const [remainingMs, setRemainingMs] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const logout = () => dispatch({ type: LOGOUT, payload: {} });

  const { preferences } = user || {};
  // For testing: inactivityTimeout: MINUTE * 1.1
  const { inactivityTimeout = DEFAULT_MS } = preferences || {};

  const onPrompt = () => setModalOpen(true);
  const onCloseModal = () => {
    reset();
    setState("active");
    setModalOpen(false);
  };
  const onPresenceChange = (presence: PresenceType) => setState(presence.type);

  const { getRemainingTime, reset } = useIdleTimer({
    onPrompt,
    onPresenceChange,
    timeout: inactivityTimeout,
    promptBeforeIdle: PROMPT_MS,
    throttle: 500,
    events: [
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
    ],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingMs(getRemainingTime());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    const handleLogout = async () => {
      if (!user?.id) return;

      await logout();

      setTimeout(() => window.location.assign(`${_env_.AUTH_URL}/logout`), 10);
    };

    if (state === "idle") handleLogout();
  }, [state]);

  if (!user?.id) return null;

  return (
    <>
      {remainingMs <= DISPLAY_MS ? (
        <div className={cx(styles.idleTime, className)}>
          <div className={styles.circle} />
          <span>{msToMinutes(remainingMs)}</span>
        </div>
      ) : null}

      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        size="xs"
        enforceFocus
        backdrop="static"
        className={styles.modal}
      >
        <Modal.Header>
          <Modal.Title>Are you still there?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Logout in {msToMinutes(remainingMs)}.</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCloseModal} appearance="primary">
            I'm here
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
