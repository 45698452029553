import { Organization } from "@/domain/organization/model/types";
import {
  dispatchSelectOrganization,
  setOrganizations,
  setOrganizationsPaginationConfig,
  setOrganizationsSortedState,
} from "@/domain/organization/redux/actions";
import { createOrganizationThunk } from "@/domain/organization/redux/middleware";
import { Patient } from "@/domain/patient/model/types";
import {
  dispatchSelectPatient,
  dispatchSetPatients,
  dispatchSetPatientsPaginationConfig,
} from "@/domain/patient/redux/actions";
import { createPatientThunk } from "@/domain/patient/redux/middleware";
import { Note } from "@/domain/notes/model/types";
import { setNotes } from "@/domain/notes/redux/actions";
import { createNoteThunk } from "@/domain/notes/redux/middleware";
import { Practice } from "@/domain/practice/model/types";
import {
  dispatchSelectPractice,
  dispatchSetPractices,
  dispatchSetPracticesPaginationConfig,
} from "@/domain/practice/redux/actions";
import { createPracticeThunk } from "@/domain/practice/redux/middleware";
import { Provider } from "@/domain/provider/model";
import {
  selectProvider,
  setProviders,
  setProvidersPaginationConfig,
} from "@/domain/provider/redux";
import { createProviderThunk } from "@/domain/provider/redux/provider.middleware";
import { User } from "@/domain/user/model";
import {
  selectUser,
  setUsers,
  setUsersPaginationConfig,
  setUsersSortedState,
} from "@/domain/user/redux";
import { createUserThunk } from "@/domain/user/redux/user.middleware";
import { ResourceType } from "@/library/core/config/resource";
import {
  OrganizationsSortedState,
  PaginationConfig,
  UsersSortedState,
} from "@/library/types";
import { PracticeSortedState } from "@/domain/practice/redux/types";
import { dispatchSetPracticeSortedState } from "@/domain/practice/redux/actions";
import { ProviderSortedState } from "@/domain/provider/redux/types";
import { setProviderSortedState } from "@/domain/provider/redux/provider.actions";
import { RootState } from "@/types";
import { store } from "./store";

const ReduxConfigMap = (previousState: RootState) => {
  return {
    [ResourceType.organization]: {
      state: {
        selectedEntity: previousState.organization.selectedOrganization,
        entities: previousState.organization.organizations,
        entityPageLimit: previousState.organization.organizationPageLimit,
        paginationConfig: previousState.organization.paginationConfig,
        sortedState: previousState.organization.sortedState,
      },
      dispatcher: {
        setEntitiesCollection: (organizations: Organization[]): any =>
          store.dispatch(setOrganizations(organizations)),
        createEntity: (organization: Organization): any =>
          store.dispatch(createOrganizationThunk(organization) as any),
        setEntitiesPaginationConfig: (
          paginationConfig: PaginationConfig
        ): { type: string; payload: PaginationConfig } =>
          store.dispatch(setOrganizationsPaginationConfig(paginationConfig)),

        selectEntity: (organization: Organization): any =>
          store.dispatch(dispatchSelectOrganization(organization)),
        setSortedState: (organizationSortedState: OrganizationsSortedState) =>
          store.dispatch(setOrganizationsSortedState(organizationSortedState)),
      },
    },

    [ResourceType.practice]: {
      state: {
        selectedEntity: previousState.practice.selectedPractices,
        entities: previousState.practice.practices,
        entityPageLimit: previousState.practice.practicePageLimit,
        paginationConfig: previousState.practice.paginationConfig,
        sortedState: previousState.practice.sortedState,
      },
      dispatcher: {
        setEntitiesCollection: (practices: Practice[]): any =>
          store.dispatch(dispatchSetPractices(practices)),
        createEntity: (practice: Practice): void =>
          store.dispatch(createPracticeThunk(practice) as any),
        setEntitiesPaginationConfig: (
          paginationConfig: PaginationConfig
        ): { type: string; payload: PaginationConfig } =>
          store.dispatch(
            dispatchSetPracticesPaginationConfig(paginationConfig)
          ),
        selectEntity: (practice: Practice): any =>
          store.dispatch(dispatchSelectPractice(practice)),
        setSortedState: (practiceSortedState: PracticeSortedState) =>
          store.dispatch(dispatchSetPracticeSortedState(practiceSortedState)),
      },
    },

    [ResourceType.patient]: {
      state: {
        selectedEntity: previousState.patient.selectedPatients,
        entities: previousState.patient.patients,
        entityPageLimit: previousState.patient.patientPageLimit,
        paginationConfig: previousState.patient.paginationConfig,
      },
      dispatcher: {
        setEntitiesCollection: (patients: Patient[]): any =>
          store.dispatch(dispatchSetPatients(patients)),
        createEntity: (patient: Patient): Promise<void> =>
          createPatientThunk(patient),
        setEntitiesPaginationConfig: (paginationConfig: PaginationConfig) =>
          store.dispatch(dispatchSetPatientsPaginationConfig(paginationConfig)),
        selectEntity: (patient: Patient): any =>
          store.dispatch(dispatchSelectPatient(patient)),
      },
    },

    [ResourceType.user]: {
      state: {
        selectedEntity: previousState.user.selectedUser,
        entities: previousState.user.users,
        entityPageLimit: previousState.user.userPageLimit,
        paginationConfig: previousState.user.paginationConfig,
        sortedState: previousState.user.sortedState,
      },
      dispatcher: {
        setEntitiesCollection: (users: User[]): any =>
          store.dispatch(setUsers(users)),
        createEntity: (user: User): Promise<void> =>
          store.dispatch(createUserThunk(user) as any),
        setEntitiesPaginationConfig: (
          paginationConfig: PaginationConfig
        ): { type: string; payload: PaginationConfig } =>
          store.dispatch(setUsersPaginationConfig(paginationConfig)),
        selectEntity: (user: User): any => store.dispatch(selectUser(user)),
        setSortedState: (usersSortedState: UsersSortedState) =>
          store.dispatch(setUsersSortedState(usersSortedState)),
      },
    },

    [ResourceType.provider]: {
      state: {
        selectedEntity: previousState.provider.selectedProviders,
        entities: previousState.provider.providers,
        entityPageLimit: previousState.provider.providerPageLimit,
        paginationConfig: previousState.provider.paginationConfig,
        sortedState: previousState.provider.sortedState,
      },
      dispatcher: {
        setEntitiesCollection: (providers: Provider[]): any =>
          store.dispatch(setProviders(providers)),
        createEntity: (provider: Provider): void =>
          store.dispatch(
            createProviderThunk(provider, previousState.practice) as any
          ),
        setEntitiesPaginationConfig: (
          paginationConfig: PaginationConfig
        ): { type: string; payload: PaginationConfig } =>
          store.dispatch(setProvidersPaginationConfig(paginationConfig)),
        selectEntity: (provider: Provider): any =>
          store.dispatch(selectProvider(provider)),
        setSortedState: (providerSortedState: ProviderSortedState) =>
          store.dispatch(setProviderSortedState(providerSortedState)),
      },
    },

    [ResourceType.note]: {
      state: {
        selectedEntity: previousState.notes.selectedNotes,
        entities: previousState.notes.notes,
        entityPageLimit: previousState.notes.notePageLimit,
        paginationConfig: previousState.notes.paginationConfig,
      },
      dispatcher: {
        setEntitiesCollection: (notes: Note[]): any =>
          store.dispatch(setNotes(notes)),
        createEntity: (note: Note): any =>
          store.dispatch(createNoteThunk(note) as any),
        setEntitiesPaginationConfig: (
          paginationConfig: PaginationConfig
        ): { type: string; payload: PaginationConfig } =>
          store.dispatch(setProvidersPaginationConfig(paginationConfig)),
        selectEntity: (provider: Provider): any =>
          store.dispatch(selectProvider(provider)),
      },
    },

    [ResourceType.tag]: {
      state: {},
      dispatcher: {},
    } as any,

    [ResourceType.kits]: {
      state: {},
      dispatcher: {},
    } as any,
  };
};

export const AppStore = {
  get: (resourceType: ResourceType) => {
    return ReduxConfigMap(store.getState())[resourceType];
  },
};
