import { PatientId } from "@/domain/patient/redux/types";
import { RootState } from "@/types";

export const selectRulesForPatient =
  (id?: PatientId | null) => (state: RootState) =>
    id ? state.rules.rulesByPatientId[id] || [] : [];

export const selectRulesForOrganization =
  (id?: PatientId | null) => (state: RootState) =>
    id ? state.rules.rulesByOrganizationId[id] || [] : [];
export const selectRulesForPractice =
  (id?: PatientId | null) => (state: RootState) =>
    id ? state.rules.rulesByPracticeId[id] || [] : [];
export const selectRulesForProvider =
  (id?: PatientId | null) => (state: RootState) =>
    id ? state.rules.rulesByProviderId[id] || [] : [];
