import { CellContext, ColumnDef } from "@tanstack/react-table";
import { startCase } from "lodash";
import cx from "clsx";

import { AlertLegendRow } from "./types";

import styles from "./styles.module.scss";

export const renderLevelCell = ({ row }: CellContext<AlertLegendRow, any>) => {
  const { level } = row.original;

  return (
    <div className={cx(styles.levelCell, styles[level])}>
      <div className={cx(styles.swatch, styles[level])} />
      {startCase(level)}
    </div>
  );
};

export const makeColumns = (): ColumnDef<AlertLegendRow>[] => [
  {
    id: "title",
    header: "Alert Level",
    accessorKey: "title",
  },
  {
    id: "description",
    header: "Data Range",
    accessorKey: "description",
  },
  {
    id: "level",
    header: "Color",
    accessorKey: "level",
    cell: renderLevelCell,
  },
];
