import React, { Dispatch, FC, SetStateAction } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Program } from "@/domain/program/model/types";

export interface PathwayProgramProps {
  programs: Program[];
  setProgram: Dispatch<SetStateAction<Program | null>>;
}
export const PathwayProgram: FC<PathwayProgramProps> = ({
  programs,
  setProgram,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={programs.map((program: Program) => ({
        label: program.shortName,
        id: program.name,
      }))}
      onChange={(e, newValue) => {
        const selectedProgram = programs.find((p) => p.name === newValue?.id);
        setProgram(selectedProgram as Program | null);
      }}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Program" />}
    />
  );
};
