import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { MODEL_SYNCED } from "../../constants";
import { Iterator } from "../../iteration/iterator";
import { Model } from "../../model";
import { ModelCollection } from "../../model/model.collection";
import { flattenToObject } from "../helper";
import { PolicyBuilder } from "./policyBuilder.interface";

export class RootUserPolicyBuilder extends PolicyBuilder {
  async build() {
    return {
      ...flattenToObject(
        this.organizations.map((id: string) => ({
          [this.scope.organization.build(id)]: this.scopeValue,
        }))
      ),
      ...flattenToObject(
        (await this.getPracticeIds()).map((id: string) => ({
          [this.scope.practice.build(id)]: this.scopeValue,
        }))
      ),
      ...flattenToObject(
        this.hasGlobal ? [{ [this.scope.global.build()]: this.scopeValue }] : []
      ),
    };
  }

  /**
   * Fetch all practices for selected organizations.
   *
   * @private
   */
  private async getPracticeIds(): Promise<string[]> {
    const practices: string[] = [];
    if (this.organizations.includes("*")) {
      practices.push("*");
      return practices;
    }
    await Promise.all(
      this.organizations.map(async (id: string) => {
        const practiceCollection: ModelCollection<PracticeModel, Practice> =
          PracticeModel.makePracticeCollection();
        practiceCollection.on(MODEL_SYNCED, () => {});
        practiceCollection.withParams({
          organizationIds: [id],
        });

        const iterator: Iterator<PracticeModel, Practice> =
          await practiceCollection.getMany();
        const allPracticeModels = await iterator.getAll();

        practices.push(
          ...allPracticeModels.map(
            (model: Model<Practice>) => model.attributes.get("id") as string
          )
        );
      })
    );

    return practices;
  }
}
