import { AccessoryType } from "@/domain/kits/models/device.interface";

export const mapAccessoryTypeToDisplay: Record<AccessoryType, string> = {
  [AccessoryType.BLOOD_PRESSURE_MONITOR]: "Blood Pressure Meter",
  [AccessoryType.GLUCOMETER]: "Glucose Meter",
  [AccessoryType.SCALE]: "Scale",
  [AccessoryType.PULSE_OXIMETER]: "PulseOx",
  [AccessoryType.SPIROMETER]: "Spirometer",
  [AccessoryType.THERMOMETER]: "Thermometer",
};
