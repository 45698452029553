import { PaginationConfig } from "@/library/types";
import {
  Patient,
  PatientFilters,
  PatientObservation,
  FilterItemDetail,
} from "@/domain/patient/model/types";
import { ObservationType } from "@/domain/observations/types";
import { PatientId, UserPatientTimerState } from "./types";
import * as C from "./constants";

export const dispatchCreatePatient = (patient: Patient) => ({
  type: C.CREATE_PATIENT,
  payload: { patient },
});

export const dispatchUpdatePatient = (patient: Patient) => ({
  type: C.UPDATE_PATIENT,
  payload: { patient },
});

export const dispatchUpdatePatientProperty = (
  patientId: PatientId,
  key: keyof Patient,
  value: any
) => ({
  type: C.UPDATE_PATIENT_PROPERTY,
  payload: { patientId, key, value },
});

export const dispatchSetPatients = (patients: Patient[]) => ({
  type: C.SET_PATIENTS,
  payload: { patients },
});

export const dispatchSetAssignmentPatients = (patients: Patient[]) => ({
  type: C.SET_ASSIGNMENT_PATIENTS,
  payload: { patients },
});

export const dispatchSelectPatient = (patient: Patient) => ({
  type: C.SELECT_PATIENT,
  payload: { patient },
});

export const dispatchSelectAssignmentPatient = (patient: Patient) => ({
  type: C.SELECT_ASSIGNMENT_PATIENT,
  payload: { patient },
});

export const dispatchDeselectAssignmentPatient = (patient: Patient) => ({
  type: C.DESELECT_ASSIGNMENT_PATIENT,
  payload: { patient },
});

export const dispatchDeselectPatient = (patient: Patient) => ({
  type: C.DESELECT_PATIENT,
  payload: { patient },
});

export const dispatchSelectAllPatients = () => ({
  type: C.SELECT_ALL_PATIENTS,
});

export const dispatchSelectAllAssignmentPatients = () => ({
  type: C.SELECT_ALL_ASSIGNMENT_PATIENTS,
});

export const dispatchDeselectAllPatients = () => ({
  type: C.DESELECT_ALL_PATIENTS,
});

export const dispatchDeselectAllAssignmentPatients = () => ({
  type: C.DESELECT_ALL_ASSIGNMENT_PATIENTS,
});

export const dispatchSetPatientsPaginationConfig = (
  paginationConfig: PaginationConfig
) => ({
  type: C.SET_PATIENTS_PAGINATIONCONFIG,
  payload: { paginationConfig },
});

export const dispatchSetAssignmentPatientsPaginationConfig = (
  paginationConfig: PaginationConfig
) => ({
  type: C.SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG,
  payload: { paginationConfig },
});

export const dispatchSetPatientPageLimit = (pageLimit: number) => ({
  type: C.SET_PATIENTS_PAGE_LIMIT,
  payload: { pageLimit },
});

export const dispatchSetAssignmentPatientPageLimit = (pageLimit: number) => ({
  type: C.SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT,
  payload: { pageLimit },
});

export const dispatchSetTargetPatient = (id: PatientId) => ({
  type: C.SET_TARGET_PATIENT,
  payload: { id },
});

export const dispatchSetPatientFilters = (filters: PatientFilters) => ({
  type: C.SET_PATIENT_FILTERS,
  payload: { filters },
});

export const dispatchSetFilterItems = (filterItems: FilterItemDetail[]) => ({
  type: C.SET_FILTER_ITEM_DETAILS,
  payload: { filterItems },
});

export const dispatchSetAssignmentPatientFilters = (
  filters: PatientFilters
) => ({
  type: C.SET_ASSIGNMENT_PATIENT_FILTERS,
  payload: { filters },
});

export const dispatchAddPatientObservations = (
  patientId: PatientId,
  observations: PatientObservation[]
) => ({
  type: C.ADD_PATIENT_OBSERVATIONS,
  payload: { patientId, observations },
});

export const dispatchSetPatientObservations = (
  patientId: PatientId,
  observations: PatientObservation[]
) => ({
  type: C.SET_PATIENT_OBSERVATIONS,
  payload: { patientId, observations },
});

export const dispatchSetMonitoringStartDate = (startDate: string) => ({
  type: C.SET_MONITORING_START_DATE,
  payload: { startDate },
});
export const dispatchSetMonitoringEndDate = (endDate: string) => ({
  type: C.SET_MONITORING_END_DATE,
  payload: { endDate },
});
export const dispatchSetMonitoringDates = (
  startDate: string,
  endDate: string
) => ({
  type: C.SET_MONITORING_DATES,
  payload: { startDate, endDate },
});

export const dispatchToggleMonitoringTabVisibility = (
  tab: ObservationType
) => ({
  type: C.TOGGLE_MONITORING_TAB_VISIBILITY,
  payload: { tab },
});

export const dispatchSetPatientTimer = (timer: UserPatientTimerState) => {
  return {
    type: C.SET_PATIENT_TIMER,
    payload: { timer },
  };
};

export const dispatchSetAcceptReject = (acceptAlerts: boolean) => ({
  type: C.SET_ACCEPT_REJECT_NOTES_TOGGLE,
  payload: acceptAlerts,
});

export const dispatchSetSelectedPatientRules = (rules: {}) => ({
  type: C.SET_RULES_SELECTED_PATIENT,
  payload: rules,
});
