import {
  AlertTypeLabelMapNew,
  AssessmentType,
  BloodPressureTypes,
  ChartNoteType,
  DateTimeDisplayFormat,
} from "./constants";
import { VariableDefaultObject } from "@/domain/rules/model/types";
import {
  AcceptedAlerts,
  ChartPriority,
  DATE_FORMAT,
  observationsDataType,
} from "./index";
import { PatientNoteType, SeverityScore } from "@/library/types/note";
import {
  getSeverityAlertLevelNew,
  PatientNoteTypes,
} from "@/domain/notes/model/constants";
import { format } from "date-fns";
import { ObservationTypes } from "../../model/constants";
import { renderAuthor } from "@/domain/notes/model/helpers";
import { DateTime } from "luxon";
import { BloodPressureDisplayData, RepliedToNotes } from "./types";
import {
  ObservationsUnitAbbrevationNew,
  ObservationTypeNew,
} from "@/domain/observations/types";

export function formatDateAlerts(dateString: string, displayFormat: number) {
  if (dateString) {
    switch (displayFormat) {
      case DateTimeDisplayFormat.DateNumeric:
        const dateNumeric: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
        return new Date(dateString).toLocaleDateString([], dateNumeric);
      case DateTimeDisplayFormat.Time24Hour:
        const time24Hours: Intl.DateTimeFormatOptions = {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h24",
        };
        return `${new Date(dateString).toLocaleTimeString(
          [],
          time24Hours
        )}(${new Date(dateString).getTimezoneOffset()})`;
    }
  }
}

export const OperatorSign = {
  GreaterThanEqualTo: "\u2265",
  DevidedBy: "\u2044",
  LessThanEqualTo: "\u2264",
  Or: "|",
  PlusOrMinus: "\u{207A}\u{2215}\u{208B}",
};
export const getpriorityAboveBelowValue = (
  value: number,
  rule: { above: VariableDefaultObject; below: VariableDefaultObject }
) => {
  let priority = ChartPriority.Normal;
  let operator = OperatorSign.GreaterThanEqualTo;
  let valueText = rule?.below?.medium?.toString() ?? "0";

  if (value >= (rule?.above?.high ?? 0)) {
    priority = ChartPriority.High;
    valueText = rule?.above?.high?.toString() ?? "0";
  } else if (value >= (rule?.above?.medium ?? 0)) {
    priority = ChartPriority.Medium;
    valueText = rule?.above?.medium?.toString() ?? "0";
  } else if (value <= (rule?.below?.high ?? 0)) {
    priority = ChartPriority.High;
    operator = OperatorSign.LessThanEqualTo;
    valueText = rule?.below?.high?.toString() ?? "0";
  } else if (value <= (rule?.below?.medium ?? 0)) {
    priority = ChartPriority.Medium;
    operator = OperatorSign.LessThanEqualTo;
    valueText = rule?.below?.medium?.toString() ?? "0";
  }
  return { priority, operator, valueText };
};

export const getprioritySpirPulseOxValue = (
  value: number,
  below: VariableDefaultObject
) => {
  let priority = ChartPriority.Normal;
  let operator = OperatorSign.GreaterThanEqualTo;
  let valueText = below?.medium?.toString() ?? "0";

  if (value <= (below?.high ?? 0)) {
    priority = ChartPriority.High;
    operator = OperatorSign.LessThanEqualTo;
    valueText = below?.high?.toString() ?? "0";
  } else if (value <= (below?.medium ?? 0)) {
    priority = ChartPriority.Medium;
    operator = OperatorSign.LessThanEqualTo;
    valueText = below?.medium?.toString() ?? "0";
  }
  return { priority, operator, valueText };
};

export const getpriorityWeightValue = (
  value: number,
  below: VariableDefaultObject
) => {
  let priority = ChartPriority.Normal;
  let operator = OperatorSign.GreaterThanEqualTo;
  let valueText = below?.medium?.toString() ?? "0";

  if (value >= (below?.high ?? 0)) {
    priority = ChartPriority.High;
    operator = OperatorSign.GreaterThanEqualTo;
    valueText = below?.high?.toString() ?? "0";
  } else if (value >= (below?.medium ?? 0)) {
    priority = ChartPriority.Medium;
    operator = OperatorSign.GreaterThanEqualTo;
    valueText = below?.medium?.toString() ?? "0";
  }
  return { priority, operator, valueText };
};

export const getpriorityHeartRateValue = (
  value: number,
  rule: { above: VariableDefaultObject; below: VariableDefaultObject }
) => {
  let priority = ChartPriority.Normal;
  let operator = OperatorSign.GreaterThanEqualTo;
  let valueText = rule?.below?.medium?.toString() ?? "0";

  if (value >= (rule?.above?.high ?? 0)) {
    priority = ChartPriority.High;
    valueText = rule?.above?.high?.toString() ?? "0";
  } else if (value <= (rule?.below?.medium ?? 0)) {
    priority = ChartPriority.High;
    valueText = rule?.below?.medium?.toString() ?? "0";
    operator = OperatorSign.LessThanEqualTo;
  }
  return { priority, operator, valueText };
};

export const getTimezoneAbbrevation = (zone: string): string => {
  const longTimezone = DateTime.local()
    .setLocale("en-US")
    .setZone(zone)
    .offsetNameLong.split(" ");
  let timezoneAbrrevation = "";
  longTimezone.forEach((tz: string) => (timezoneAbrrevation += tz[0]));
  return timezoneAbrrevation;
};

export const getPathwayAlertData = (chartNote: ChartNoteType) => {
  let alertData: {}[] = [];
  let typeDate = chartNote?.created
    ? format(new Date(chartNote?.created), DATE_FORMAT)
    : "-";
  const localTimzone = DateTime.local().zoneName;
  const zoneNameAbbreviation = getTimezoneAbbrevation(localTimzone);
  typeDate = `${typeDate} ${zoneNameAbbreviation}`;
  const typeDateDesc = AlertTypeLabelMapNew["PATHWAY"];
  let priority =
    chartNote.severityScore === SeverityScore.SEVERITY_RED
      ? ChartPriority.High
      : chartNote.severityScore === SeverityScore.SEVERITY_YELLOW
      ? ChartPriority.Medium
      : ChartPriority.Normal;
  alertData.push({
    typeDate,
    typeDateDesc,
    typePriority: priority,
  });
  return alertData;
};

export const generateAlertData = (chartNotes: any, rules: any) => {
  return chartNotes?.length > 0
    ? chartNotes.map((chartNote: any) => {
        const { comment, repliedToNotes } = chartNote;
        const alertTypes =
          chartNote.noteType === PatientNoteTypes.Observation
            ? chartNote.observation?.observations?.length
              ? chartNote.observation?.observations.map((observation: any) => {
                  const { type, systolic, diastolic, pef, fev1, value, unit } =
                    observation;
                  let alertData: {}[] = [];
                  let typeDate = chartNote?.created
                    ? format(new Date(chartNote?.created), DATE_FORMAT)
                    : "-";
                  const localTimzone = DateTime.local().zoneName;
                  const zoneNameAbbreviation =
                    getTimezoneAbbrevation(localTimzone);
                  typeDate = `${typeDate} ${zoneNameAbbreviation}`;
                  const typeDateDesc = AlertTypeLabelMapNew[observation.type];
                  let data = chartNote?.data;
                  let typeValue1P;
                  let typeValue2P;
                  let typeValue1;
                  let typeValue2;
                  let typeValue;
                  let typePriority;
                  let typeOperator;
                  let noteId;
                  let typeBPChanged;
                  const ruleType = rules[type];
                  switch (type) {
                    case ObservationTypes.BloodPressure:
                      if (systolic && diastolic) {
                        let bpData = data?.bpData;
                        let isBPChanged = false;
                        const systolicData = getpriorityAboveBelowValue(
                          systolic.value,
                          {
                            above: {
                              high:
                                ruleType?.values[
                                  "systolicHighBloodPressureDiscrete-high"
                                ] ?? 0,
                              medium:
                                ruleType?.values[
                                  "systolicHighBloodPressureDiscrete-medium"
                                ] ?? 0,
                            } as VariableDefaultObject,
                            below: {
                              high:
                                ruleType?.values[
                                  "systolicLowBloodPressureDiscrete-high"
                                ] ?? 0,
                              medium:
                                ruleType?.values[
                                  "systolicLowBloodPressureDiscrete-medium"
                                ] ?? 0,
                            } as VariableDefaultObject,
                          }
                        );
                        const diastolicData = getpriorityAboveBelowValue(
                          diastolic.value,
                          {
                            above: {
                              high:
                                ruleType?.values[
                                  "diastolicHighBloodPressureDiscrete-high"
                                ] ?? 0,
                              medium:
                                ruleType?.values[
                                  "diastolicHighBloodPressureDiscrete-medium"
                                ] ?? 0,
                            } as VariableDefaultObject,
                            below: {
                              high:
                                ruleType?.values[
                                  "diastolicLowBloodPressureDiscrete-high"
                                ] ?? 0,
                              medium:
                                ruleType?.values[
                                  "diastolicLowBloodPressureDiscrete-medium"
                                ] ?? 0,
                            } as VariableDefaultObject,
                          }
                        );
                        let systolicDisplayData: BloodPressureDisplayData = {
                          priority: "",
                          valueText: "",
                          operator: "",
                        };
                        let diastolicDisplayData: BloodPressureDisplayData = {
                          priority: "",
                          valueText: "",
                          operator: "",
                        };
                        let changePriority = ChartPriority.Medium;
                        Object.assign(systolicDisplayData, systolicData);
                        Object.assign(diastolicDisplayData, diastolicData);
                        if (bpData) {
                          isBPChanged =
                            bpData?.systolic?.change ||
                            bpData?.diastolic?.change;
                          if (isBPChanged) {
                            let systolicSeverityScore =
                              bpData.systolic.severityValue;
                            let diastolicSeverityScore =
                              bpData?.diastolic.severityValue;
                            typeBPChanged =
                              bpData?.systolic?.change &&
                              bpData?.diastolic?.change
                                ? BloodPressureTypes.Both
                                : bpData?.systolic?.change
                                ? BloodPressureTypes.Systolic
                                : BloodPressureTypes.Diastolic;
                            if (
                              (typeBPChanged === BloodPressureTypes.Both ||
                                typeBPChanged ===
                                  BloodPressureTypes.Systolic) &&
                              systolicData.priority == ChartPriority.Normal
                            ) {
                              systolicDisplayData.priority =
                                getSeverityAlertLevelNew(systolicSeverityScore);
                            }
                            if (
                              (typeBPChanged === BloodPressureTypes.Both ||
                                typeBPChanged ===
                                  BloodPressureTypes.Diastolic) &&
                              diastolicData.priority == ChartPriority.Normal
                            ) {
                              diastolicDisplayData.priority =
                                getSeverityAlertLevelNew(
                                  diastolicSeverityScore
                                );
                            }

                            // Set priority value for change subline
                            if (typeBPChanged === BloodPressureTypes.Both) {
                              changePriority =
                                systolicSeverityScore > diastolicSeverityScore
                                  ? getSeverityAlertLevelNew(
                                      systolicSeverityScore
                                    )
                                  : getSeverityAlertLevelNew(
                                      diastolicSeverityScore
                                    );
                            } else if (
                              typeBPChanged === BloodPressureTypes.Systolic
                            ) {
                              changePriority = getSeverityAlertLevelNew(
                                systolicSeverityScore
                              );
                            } else {
                              changePriority = getSeverityAlertLevelNew(
                                diastolicSeverityScore
                              );
                            }
                          }
                        }
                        typeValue1 = systolic.value.toString();
                        typeValue2 = diastolic.value.toString();
                        typeValue =
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.BloodPressure
                          ];
                        typeOperator = OperatorSign.DevidedBy;
                        typeValue1P = systolicDisplayData.priority;
                        typeValue2P = diastolicDisplayData.priority;
                        typePriority = getSeverityAlertLevelNew(
                          chartNote.severityScore
                        );

                        //Creating array for storing the values as per changed variables
                        let bloodPressureArray = [];

                        let alertLine = {
                          typeDate,
                          typeDateDesc,
                          typeValue1P,
                          typeValue2P,
                          typeValue1,
                          typeValue2,
                          typeValue,
                          typeOperator,
                          typePriority,
                        };

                        // Pushing main alert line object to array
                        bloodPressureArray.push(alertLine);

                        if (typePriority !== ChartPriority.Normal) {
                          if (systolicData.priority !== ChartPriority.Normal) {
                            let alertSubLine1 = {
                              typeDate: "",
                              typeDateDesc: "Systolic",
                              typeValue1P: systolicData.priority,
                              typeValue2P: ChartPriority.None,
                              typeValue1: systolic.value,
                              typeValue2: systolicData.valueText,
                              typeValue:
                                ObservationsUnitAbbrevationNew[
                                  ObservationTypeNew.BloodPressure
                                ],
                              typeOperator: systolicData.operator,
                              typePriority: systolicData.priority,
                            };

                            // Pushing systolic alert subline object to array
                            bloodPressureArray.push(alertSubLine1);
                          }
                          if (diastolicData.priority !== ChartPriority.Normal) {
                            let alertSubLine2 = {
                              typeDate: "",
                              typeDateDesc: "Diastolic",
                              typeValue1P: diastolicData.priority,
                              typeValue2P: ChartPriority.None,
                              typeValue1: diastolic.value,
                              typeValue2: diastolicData.valueText,
                              typeValue: diastolic.unit,
                              typeOperator: diastolicData.operator,
                              typePriority: diastolicData.priority,
                            };

                            // Pushing diastolic alert subline object to array
                            bloodPressureArray.push(alertSubLine2);
                          }

                          if (isBPChanged) {
                            let changeLine = {
                              typeDate: "",
                              typeDateDesc: "Change",
                              typeValue1P:
                                (typeBPChanged ===
                                  BloodPressureTypes.Systolic ||
                                  typeBPChanged === BloodPressureTypes.Both) &&
                                systolicDisplayData.priority,
                              typeValue2P: ChartPriority.None,
                              typeValue3P:
                                typeBPChanged ===
                                  BloodPressureTypes.Diastolic ||
                                typeBPChanged === BloodPressureTypes.Both
                                  ? diastolicDisplayData.priority
                                  : diastolicDisplayData.priority,
                              typeValue1:
                                typeBPChanged === BloodPressureTypes.Systolic ||
                                typeBPChanged === BloodPressureTypes.Both
                                  ? systolic.value
                                  : null,
                              typeValue2: 20,
                              typeValue3:
                                typeBPChanged ===
                                  BloodPressureTypes.Diastolic ||
                                typeBPChanged === BloodPressureTypes.Both
                                  ? diastolic.value
                                  : null,
                              typeValue:
                                ObservationsUnitAbbrevationNew[
                                  ObservationTypeNew.BloodPressure
                                ],
                              typeOperator: OperatorSign.PlusOrMinus,
                              typeValue3Operator:
                                typeBPChanged === BloodPressureTypes.Both &&
                                typeOperator,
                              typePriority: changePriority,
                            };

                            // Pushing change alert subline object to array
                            bloodPressureArray.push(changeLine);
                          }
                        }

                        // Adding blood pressure array to alert data array
                        alertData = alertData.concat(bloodPressureArray);
                      }
                      break;
                    case ObservationTypes.Spirometry:
                      if (pef && fev1) {
                        const pefData = getprioritySpirPulseOxValue(pef.value, {
                          high:
                            ruleType?.values["lowPEFPercentageDiscrete-high"] ??
                            0,
                          medium:
                            ruleType?.values[
                              "lowPEFPercentageDiscrete-medium"
                            ] ?? 0,
                        } as VariableDefaultObject);
                        const fev1Data = getprioritySpirPulseOxValue(
                          fev1.value,
                          {
                            high:
                              ruleType?.values[
                                "lowFEV1PercentageDiscrete-high"
                              ] ?? 0,
                            medium:
                              ruleType?.values[
                                "lowFEV1PercentageDiscrete-medium"
                              ] ?? 0,
                          } as VariableDefaultObject
                        );
                        typePriority =
                          pefData.priority === ChartPriority.High ||
                          fev1Data.priority === ChartPriority.High
                            ? ChartPriority.High
                            : pefData.priority === ChartPriority.Medium ||
                              fev1Data.priority === ChartPriority.Medium
                            ? ChartPriority.Medium
                            : ChartPriority.Normal;
                        alertData.push({
                          typeDate,
                          typeDateDesc,
                          typeValue1P: pefData.priority,
                          typeValue2P: fev1Data.priority,
                          typeValue1: pef.value.toString(),
                          typeValue2: fev1.value.toString(),
                          typeValue:
                            ObservationsUnitAbbrevationNew[
                              ObservationTypeNew.SpirometryPEF
                            ] +
                            "|" +
                            ObservationsUnitAbbrevationNew[
                              ObservationTypeNew.Spirometry
                            ],
                          typeOperator: OperatorSign.Or,
                          typePriority: getSeverityAlertLevelNew(
                            chartNote.severityScore
                          ),
                        });
                        if (typePriority !== ChartPriority.Normal) {
                          alertData.push({
                            typeDate: "",
                            typeDateDesc: "PEF",
                            typeValue1P: pefData.priority,
                            typeValue2P: ChartPriority.None,
                            typeValue1: pef.value,
                            typeValue2: pefData.valueText,
                            typeValue:
                              ObservationsUnitAbbrevationNew[
                                ObservationTypeNew.SpirometryPEF
                              ],
                            typeOperator: pefData.operator,
                            typePriority: pefData.priority,
                          });
                          alertData.push({
                            typeDate: "",
                            typeDateDesc: "FEV1",
                            typeValue1P: fev1Data.priority,
                            typeValue2P: ChartPriority.None,
                            typeValue1: fev1.value,
                            typeValue2: fev1Data.valueText,
                            typeValue:
                              ObservationsUnitAbbrevationNew[
                                ObservationTypeNew.Spirometry
                              ],
                            typeOperator: fev1Data.operator,
                            typePriority: fev1Data.priority,
                          });
                        }
                      }
                      break;
                    case ObservationTypes.HeartRate:
                      const hrData = getpriorityHeartRateValue(value, {
                        above: {
                          high:
                            ruleType?.values["highHeartRateDiscrete-high"] ?? 0,
                        } as VariableDefaultObject,
                        below: {
                          medium:
                            ruleType?.values["lowHeartRateDiscrete-medium"] ??
                            0,
                        } as VariableDefaultObject,
                      });
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: hrData.priority,
                        typeValue1: value.toString(),
                        typeValue2: hrData.valueText,
                        typeValue:
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.HeartRate
                          ],
                        typeOperator: hrData.operator,
                        typePriority: hrData.priority,
                      });
                      break;
                    case ObservationTypes.Temperature:
                      const tempData = getpriorityAboveBelowValue(value, {
                        above: {
                          high:
                            ruleType?.values["highTemperatureDiscrete-high"] ??
                            0,
                          medium:
                            ruleType?.values[
                              "highTemperatureDiscrete-medium"
                            ] ?? 0,
                        } as VariableDefaultObject,
                        below: {
                          high:
                            ruleType?.values["lowTemperatureDiscrete-high"] ??
                            0,
                          medium:
                            ruleType?.values["lowTemperatureDiscrete-medium"] ??
                            0,
                        } as VariableDefaultObject,
                      });
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: tempData.priority,
                        typeValue1: value.toString(),
                        typeValue2: tempData.valueText,
                        typeValue:
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.Temperature
                          ],
                        typeOperator: tempData.operator,
                        typePriority: tempData.priority,
                      });
                      break;
                    case ObservationTypes.PulseOximetry:
                      const pulseOxData = getprioritySpirPulseOxValue(value, {
                        high:
                          ruleType?.values["oxygenSaturationDiscrete-high"] ??
                          0,
                        medium:
                          ruleType?.values["oxygenSaturationDiscrete-medium"] ??
                          0,
                      } as VariableDefaultObject);
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: pulseOxData.priority,
                        typeValue1: value.toString(),
                        typeValue2: pulseOxData.valueText,
                        typeValue:
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.PulseOximetry
                          ],
                        typeOperator: pulseOxData.operator,
                        typePriority: pulseOxData.priority,
                      });
                      break;
                    case ObservationTypes.Weight:
                      let weightData = data?.weightData;
                      let dailyChangeData = weightData?.dailyChange;
                      let weeklyChangeData = weightData?.weeklyChange;
                      let priority =
                        chartNote.severityScore === SeverityScore.SEVERITY_RED
                          ? ChartPriority.High
                          : chartNote.severityScore ===
                            SeverityScore.SEVERITY_YELLOW
                          ? ChartPriority.Medium
                          : ChartPriority.Normal;
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: priority,
                        typeValue1: value,
                        typeValue:
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.Weight
                          ],
                        typePriority: priority,
                      });
                      if (typePriority !== ChartPriority.Normal) {
                        const dailyChangeObject = getpriorityWeightValue(
                          dailyChangeData?.biometricReading?.value,
                          {
                            high:
                              ruleType?.values["dailyChangeDiscrete-high"] ?? 0,
                            medium:
                              ruleType?.values["dailyChangeDiscrete-medium"] ??
                              0,
                          } as VariableDefaultObject
                        );
                        const weeklyChangeObject = getpriorityWeightValue(
                          weeklyChangeData?.biometricReading?.value,
                          {
                            high:
                              ruleType?.values["weeklyChangeDiscrete-high"] ??
                              0,
                            medium:
                              ruleType?.values["weeklyChangeDiscrete-medium"] ??
                              0,
                          } as VariableDefaultObject
                        );
                        if (typePriority !== ChartPriority.Normal) {
                          if (dailyChangeData) {
                            alertData.push({
                              typeDate: "",
                              typeDateDesc: "Daily Change",
                              typeValue1P:
                                dailyChangeData.severity === "HIGH"
                                  ? ChartPriority.High
                                  : dailyChangeData.severity === "MEDIUM"
                                  ? ChartPriority.Medium
                                  : ChartPriority.Normal,
                              typeValue2P: ChartPriority.None,
                              typeValue1:
                                dailyChangeData?.biometricReading?.value,
                              typeValue2: dailyChangeObject.valueText,
                              typeValue:
                                ObservationsUnitAbbrevationNew[
                                  ObservationTypeNew.Weight
                                ],
                              typeOperator: dailyChangeObject.operator,
                              typePriority:
                                dailyChangeData.severity === "HIGH"
                                  ? ChartPriority.High
                                  : dailyChangeData.severity === "MEDIUM"
                                  ? ChartPriority.Medium
                                  : ChartPriority.Normal,
                            });
                          }
                          if (weeklyChangeData) {
                            alertData.push({
                              typeDate: "",
                              typeDateDesc: "Weekly Change",
                              typeValue1P:
                                weeklyChangeData.severity === "HIGH"
                                  ? ChartPriority.High
                                  : dailyChangeData.severity === "MEDIUM"
                                  ? ChartPriority.Medium
                                  : ChartPriority.Normal,
                              typeValue2P: ChartPriority.None,
                              typeValue1:
                                weeklyChangeData.biometricReading?.value,
                              typeValue2: weeklyChangeObject.valueText,
                              typeValue:
                                ObservationsUnitAbbrevationNew[
                                  ObservationTypeNew.Weight
                                ],
                              typeOperator: weeklyChangeObject.operator,
                              typePriority:
                                weeklyChangeData.severity === "HIGH"
                                  ? ChartPriority.High
                                  : dailyChangeData.severity === "MEDIUM"
                                  ? ChartPriority.Medium
                                  : ChartPriority.Normal,
                            });
                          }
                        }
                      }
                      break;

                    case ObservationTypes.GlucoseLevel:
                      const glcData = getpriorityAboveBelowValue(value, {
                        above: {
                          high:
                            ruleType?.values["highGlucoseLevelDiscrete-high"] ??
                            0,
                          medium:
                            ruleType?.values[
                              "highGlucoseLevelDiscrete-medium"
                            ] ?? 0,
                        } as VariableDefaultObject,
                        below: {
                          high:
                            ruleType?.values["lowGlucoseLevelDiscrete-high"] ??
                            0,
                          medium:
                            ruleType?.values[
                              "lowGlucoseLevelDiscrete-medium"
                            ] ?? 0,
                        } as VariableDefaultObject,
                      });
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: glcData.priority,
                        typeValue1: value.toString(),
                        typeValue2: glcData.valueText,
                        typeValue:
                          ObservationsUnitAbbrevationNew[
                            ObservationTypeNew.GlucoseLevel
                          ],
                        typeOperator: glcData.operator,
                        typePriority: glcData.priority,
                      });
                      break;
                    default:
                      alertData.push({
                        typeDate,
                        typeDateDesc,
                        typeValue1P: ChartPriority.Normal,
                        typeValue1: value,
                        typeValue: unit,
                        typePriority: ChartPriority.Normal,
                      });
                      break;
                  }
                  return alertData;
                })
              : chartNote.type !== PatientNoteType.Reviewed &&
                chartNote.type !== PatientNoteType.PatientRequest
              ? (repliedToNotes || [])
                  .filter((reply: any) => reply.comment)
                  .map((reply: any) => {
                    return {
                      date: reply._meta?.created
                        ? format(new Date(reply._meta?.created), DATE_FORMAT)
                        : "-",
                      comment: reply.comment,
                      noteId: reply.noteId,
                    };
                  })
              : []
            : chartNote.noteType === PatientNoteTypes.Assessment
            ? getPathwayAlertData(chartNote)
            : [];

        return {
          date: chartNote?._meta?.created
            ? format(new Date(chartNote?._meta?.created), DATE_FORMAT)
            : "-",
          dateText:
            chartNote?.author &&
            chartNote?.noteType !== PatientNoteType.PatientRequest
              ? renderAuthor(chartNote?.author)
              : "-",
          types: alertTypes ? (alertTypes as observationsDataType[][]) : null,
          narrative: comment ?? "-",
          disposition:
            chartNote.noteType !== PatientNoteTypes.Observation &&
            chartNote.noteType !== PatientNoteType.Reviewed &&
            chartNote.noteType !== PatientNoteType.PatientRequest
              ? (alertTypes as AcceptedAlerts[])
              : null,
          noteId: chartNote.noteId,
        };
      })
    : null;
};
