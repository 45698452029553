import * as Yup from "yup";
import { Validation } from "@/components/form";

export const MAX_CONTACT_FIELD_LIMIT = 15;

export const VALIDATION_SCHEMA = Yup.object({
  mrn: Validation.mrn,
  firstName: Validation.name,
  lastName: Validation.name,
  preferredName: Validation.nameNotRequired,
  prefix: Validation.prefix,
  suffix: Validation.suffix,
  gender: Validation.notRequired,
  race: Validation.race,
  dateOfBirth: Validation.date,
  enrollmentDate: Validation.dateNotRequired,
  dateLastSeen: Validation.dateNotRequired,
  email: Validation.emailNotRequired,
  address: Validation.addressOne,
  city: Validation.city,
  state: Validation.state,
  zip: Validation.zip,
  country: Validation.country,
  primaryPhone: Validation.phone,
  secondaryPhone: Validation.notRequired,
  priority: Validation.priority,
  height: Validation.height,
  heightUnit: Validation.heightUnit,
  weight: Validation.weight,
  weightUnit: Validation.weightUnit,
  language: Validation.language,
  PRIMARY: Validation.notRequired,
  Practice: Validation.notRequired,
  billingType: Validation.notRequired,
  location: Validation.nullableString,
  providerId: Yup.string().required(),
});

export const INITIAL_VALUES = {
  id: undefined,
  mrn: "",
  firstName: "",
  lastName: "",
  middleName: "",
  preferredName: "",
  prefix: "",
  suffix: "",
  birthSex: "UNKNOWN",
  gender: "",
  race: "UNKNOWN",
  dateOfBirth: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: "US",
  email: "",
  primaryPhone: "",
  secondaryPhone: "",
  priority: "NONE",
  height: "",
  heightUnit: "in",
  weight: "",
  weightUnit: "lbs",
  language: "en",
  representativesFirstName: "",
  representativesLastName: "",
  representativesEmail: "",
  representativesPhone: "",
  representativesPhoneType: "",
  representativesRelation: "",
  representativesAllowSms: false,
  ethnicity: "",
  allowSMS: {
    primary: false,
    secondary: false,
  },
  providerId: "",
  diagnosis: [],
  PRIMARY: "",
  careTeam: "",
  programStatus: "",
  Practice: "",
  billingType: "",
  location: "",
};
