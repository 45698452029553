import { ChangeEvent, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import {
  extractphone,
  TextInput,
  TextInputMask,
  Validation,
} from "@/components/form";
import { PatientModel } from "@/domain/patient/model";
import { Patient, PatientForm } from "@/domain/patient/model/types";

interface Props {
  closeModal: () => void;
  afterContactAdd: (contact: PatientForm["emergencyContact"]) => void;
  targetedPatient: Patient;
}

interface ContactFormValues {
  firstName: string;
  lastName: string;
  relation: string;
  email: string;
  primaryPhone: string;
  allowSms?: boolean;
}

export const EmergencyContactForm: React.FC<Props> = (props) => {
  const { closeModal, afterContactAdd, targetedPatient } = props;

  const [smsAllowed, setSmsAllowed] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    relation: "",
    email: "",
    primaryPhone: "",
  } as ContactFormValues;

  const parseValues = (values: ContactFormValues) => ({
    name: values.firstName + " " + values.lastName,
    relation: values.relation === "" ? null : values.relation,
    email: values.email === "" ? null : values.email,
    phone:
      values.primaryPhone === ""
        ? null
        : {
            primary: {
              number: extractphone(values.primaryPhone),
              isSmsAllowed: smsAllowed,
            },
          },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      firstName: Validation.requiredString,
      lastName: Validation.requiredString,
      relation: Validation.requiredString,
      email: Validation.email,
      primaryPhone: Validation.phone,
    }),
    onSubmit: async (
      values: ContactFormValues,
      { setSubmitting, resetForm }
    ) => {
      setDisableAddButton(true);
      try {
        await PatientModel.make(targetedPatient).modifyPatient(
          parseValues(values) as any,
          "add-designated-representative"
        );
        values.allowSms = smsAllowed;
        afterContactAdd(values as any);
        setDisableAddButton(false);
      } catch (e) {
        setDisableAddButton(false);
      }
    },
  });

  const { errors } = formik;

  const handleTextInputChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(field, event.target.value);
    };

  return (
    <FormikProvider value={formik}>
      <form>
        <div className="modal-body">
          <div className="subject-scroll">
            <div className="form-row d-flex justify-content-center m-l-50 m-r-50">
              <div className="col-12">
                <div className="row">
                  <div className="col mb-3">
                    <TextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      required
                      hasError={!!errors?.firstName}
                      onChange={handleTextInputChange("firstName")}
                    />
                  </div>

                  <div className="col mb-3">
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      required
                      hasError={!!errors?.lastName}
                      onChange={handleTextInputChange("lastName")}
                    />
                  </div>

                  <div className="col mb-3">
                    <TextInput
                      label="Relation"
                      name="relation"
                      type="text"
                      placeholder="Relation"
                      required
                      hasError={!!errors?.relation}
                      onChange={handleTextInputChange("relation")}
                    />
                  </div>

                  <div className="col mb-3">
                    <TextInput
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      required
                      hasError={!!errors?.email}
                      onChange={handleTextInputChange("email")}
                    />
                  </div>

                  <div className="col-md-2 mb-3">
                    <TextInputMask
                      label="Primary Phone"
                      name="primaryPhone"
                      type="tel"
                      mask="+1 (999) 999-9999"
                      onChange={handleTextInputChange("primaryPhone")}
                      hasError={!!errors?.primaryPhone}
                      required={false}
                      supporting={
                        <div className="form-inline float-right">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="allowSMSPrimaryRepresentatative"
                            onChange={(e: any) => {
                              const isChecked: boolean = e.target.checked;
                              setSmsAllowed(isChecked);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="allowSMSPrimaryRepresentatative"
                          >
                            Allow SMS
                          </label>
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mt-4 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={disableAddButton}
                      type="submit"
                      className="btn btn-primary mr-2 px-4"
                    >
                      {" "}
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};
