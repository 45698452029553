import React, { Dispatch, SetStateAction, useState, useEffect } from "react";

import { ResourceType } from "@/library/core/config/resource";
import { AppStore } from "@/redux/config";
import {
  HeaderContent,
  SortedState,
  SortedStateType,
  SortQuery,
} from "./sortable";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import ArrowUpIcon from "@rsuite/icons/ArrowUp";
import { Stack } from "@mui/material";

interface Props {
  headers: HeaderContent[];
  execSearch: (query: { [key: string]: string }) => Promise<void>;
  resourceType: ResourceType;
  setSortedState?: (sortedState: SortQuery) => void;
  hasCheckbox?: {
    isChecked: boolean;
    onChange: (checked: boolean) => void;
  };
}

export const SortableHead: React.FC<Props> = (props) => {
  const { headers, execSearch, resourceType, setSortedState, hasCheckbox } =
    props;

  const [headerContent, setHeaderContent] = useState(headers);

  const [activeHeader, setActiveHeader]: [
    number | undefined,
    Dispatch<SetStateAction<any>>
  ] = useState(undefined);

  const sortingStates: SortedStateType[] = [
    SortedState.Ascending,
    SortedState.Descending,
    SortedState.NoSort,
  ];

  /**
   * Get next sortable state according to current sorted state.
   * @param currentState
   * @returns
   */
  const nextSortState = (currentState: SortedStateType): SortedStateType => {
    if (currentState === SortedState.NoSort) {
      return sortingStates[0];
    } else {
      const currentIndex = sortingStates.indexOf(currentState);
      return sortingStates[currentIndex + 1];
    }
  };

  const setEntitySortedState = async (
    sortedState: SortQuery,
    index: number
  ) => {
    if (setSortedState) {
      setSortedState(sortedState);
    } else {
      const store = AppStore.get(resourceType);
      /**
       * As dispatcher for all entities does not contain setSortedState function so lets take it as any for now
       * TODO: remove any type
       */
      const dispatcher: any = store.dispatcher;
      await dispatcher.setSortedState(sortedState);
      await execSearch({});
    }
    setActiveHeader(index);
  };

  useEffect(() => {
    /**
     * Set active sorted column on routes change.
     */
    const store = AppStore.get(resourceType);
    const state: any = store.state;
    const currentSortedState = state.sortedState;
    if (currentSortedState?.direction && currentSortedState?.sortBy) {
      setHeaderContent(
        headerContent.map((head) => {
          if (head.sortBy && head.sortBy === currentSortedState.sortBy) {
            head.sortedState = currentSortedState.direction;
          } else if (head.name === currentSortedState.sortBy) {
            head.sortedState = currentSortedState.direction;
          } else {
            head.sortedState = SortedState.NoSort;
          }
          return head;
        })
      );
    }
  }, []);

  return (
    <>
      {headerContent.map((head: HeaderContent, key: number) => (
        <th
          key={key}
          className={`bg-primary  text-white th-text ${
            activeHeader === key ? "sorted_head" : ""
          }`}
        >
          {head.isCheckbox && hasCheckbox ? (
            <>
              <input
                type="checkbox"
                onChange={(e) => hasCheckbox.onChange(e.target.checked)}
                checked={hasCheckbox.isChecked}
              />
            </>
          ) : (
            head.label || head.name
          )}
          {head.isSortable && (
            <span
              className="ml-1 pointer"
              style={{ fontSize: "14px", verticalAlign: "top" }}
              onClick={async () => {
                const direction: SortedStateType = nextSortState(
                  head.sortedState
                );
                let sortBy: string = head.name;
                if (head.sortBy) {
                  sortBy = head.sortBy;
                }
                let query: SortQuery = {
                  direction,
                  sortBy,
                  sortedColumnIndex: key,
                };
                if (direction && direction === SortedState.NoSort) {
                  query = { direction: undefined, sortBy: undefined };
                }
                await setEntitySortedState(query as SortQuery, key);
                setHeaderContent(
                  headerContent.map((head, index) => {
                    if (key === index) {
                      head.sortedState = direction;
                    } else {
                      head.sortedState = SortedState.NoSort;
                    }
                    return head;
                  })
                );
              }}
            >
              {head.sortedState === SortedState.Ascending && (
                <ArrowUpIcon style={{ margin: "-0.3em", fontSize: "18px" }} />
              )}
              {head.sortedState === SortedState.Descending && (
                <ArrowDownIcon style={{ margin: "-0.3em", fontSize: "18px" }} />
              )}
              {head.sortedState === SortedState.NoSort && (
                <Stack
                  spacing={-1.5}
                  sx={{
                    display: "inline-flex",
                    fontSize: "18px",
                    margin: "-0.3em",
                  }}
                >
                  <ArrowUpIcon />
                  <ArrowDownIcon />
                </Stack>
              )}
            </span>
          )}
        </th>
      ))}
    </>
  );
};

export default SortableHead;
