import React, { SyntheticEvent } from "react";
import { Button, Checkbox, Input } from "rsuite";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import cx from "clsx";

import { RuleType, RuleVariable } from "@/domain/rules/model/types";
import { ParameterFormValues } from "@/domain/rules/view/ObservationParameters/types";

import styles from "./styles.module.scss";

export type Props = {
  name: string;
  value: string | number | boolean;
  defaultValue: string | number | boolean;
  disabled?: boolean;
  variable: RuleVariable;
  onChange: (
    name: keyof ParameterFormValues
  ) => (
    value: ValueType | undefined,
    checked: boolean | SyntheticEvent<HTMLElement, Event>
  ) => void;
  onReset: () => void;
  containerClassName?: string;
};

export const ScalarVariable: React.FC<Props> = (props) => {
  const {
    name,
    value,
    defaultValue,
    disabled,
    variable,
    onChange,
    onReset,
    containerClassName,
  } = props;

  const { type, displayName } = variable;

  const isBoolean = type === RuleType.Enabled || type === RuleType.Boolean;

  return (
    <div className={cx(styles.scalarVariable, containerClassName)}>
      {!isBoolean ? <div className={styles.label}>{displayName}</div> : null}
      {isBoolean ? (
        <Checkbox
          name="enabled"
          checked={value === true}
          onChange={onChange(name as keyof ParameterFormValues)}
        >
          {displayName ||
            (type === RuleType.Enabled
              ? "Enable Biometric Vital Measurement"
              : "")}
        </Checkbox>
      ) : (
        <Input
          name={name}
          disabled={disabled}
          type={type === RuleType.Number ? "number" : "text"}
          value={(value as string) || ""}
          onChange={onChange(name as keyof ParameterFormValues)}
          className={styles.input}
          min={type === RuleType.Number ? 0 : undefined}
        />
      )}
      {!isBoolean ? (
        <Button
          disabled={value === defaultValue}
          onClick={onReset}
          className={styles.resetButton}
        >
          Reset to default
        </Button>
      ) : null}
    </div>
  );
};
