import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RSModal from "rsuite/Modal";
import { Button, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { cloneDeep } from "lodash";
import { Patient } from "@/domain/patient/model/types";
import { ViewReminders } from "@/domain/patient/view/BannerMenu/Reminders/viewRemindersModal";
import {
  Automation,
  AutomationActionType,
  ReminderAction,
  Trigger,
  VirtualVisitAction,
  ReminderForm,
} from "@/domain/automation/model/types";
import {
  Frequency,
  ReminderType,
} from "@/domain/patient/view/BannerMenu/Reminders/constants";
import { Writable } from "@/domain/patient/view/form/manageProgram/types";
import { arrClone } from "@/util/clone";
import { dispatchSetPatients } from "@/domain/patient/redux/actions";
import { PatientModel } from "@/domain/patient/model";
import { RootState } from "@/types";
import { Str } from "@/util/Str";

interface Props {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  patient: Patient;
  selectedPatient: Patient[];
  setSelectedPatient: (patient: Patient) => void;
  deletedAutomationIds: Array<string>;
  removeAutomation?: (id: string) => void;
}

export const ManageReminders: React.FC<Props> = ({
  open,
  onClose,
  patient,
  setSelectedPatient,
  deletedAutomationIds,
  removeAutomation,
}) => {
  const [automations, setAutomations] = useState<Array<Automation>>([]);
  const [reminders, setReminders] = useState<Array<ReminderForm>>([]);
  const patients = useSelector((state: RootState) => state.patient.patients);
  const dispatch = useDispatch();
  const showSmallCards = useMediaQuery("(min-width:2500px)");

  const updateReminder = (
    index: number,
    value: ReminderForm,
    isAutomation: boolean
  ) => {
    if (isAutomation) {
      const automationsCopy: Array<Automation> = cloneDeep(automations);
      automationsCopy[index] = { ...automationsCopy[index], ...value };
      if (!("trigger" in value)) {
        automationsCopy[index]["trigger"] =
          automationsCopy[index]["triggers"][0];
      }
      if (!("action" in value)) {
        automationsCopy[index]["action"] = automationsCopy[index]["actions"][0];
      }
      setAutomations(automationsCopy);
    } else {
      const remindersCopy: Array<ReminderForm> = cloneDeep(reminders);
      remindersCopy[index] = { ...remindersCopy[index], ...value };
      setReminders(remindersCopy);
    }
  };

  const addReminder = () => {
    const remindersCopy: Array<ReminderForm> = cloneDeep(reminders);
    remindersCopy.push({});
    setReminders(remindersCopy);
  };

  const deleteReminder = (index: number) => {
    const remindersCopy: Array<ReminderForm> = cloneDeep(reminders);
    remindersCopy.splice(index, 1);
    setReminders(remindersCopy);
  };

  const updateCurrentPatient = async () => {
    if (patient.id) {
      const updatedPatient: Patient = (
        await PatientModel.sync(patient.id)
      ).pluckAll();
      const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
      const updatedPatients = _patients.map((selectedPatient) => {
        if (selectedPatient.id === patient.id) {
          selectedPatient = updatedPatient;
        }
        return selectedPatient;
      });
      dispatch(dispatchSetPatients(updatedPatients));
      setSelectedPatient(updatedPatient);
    }
  };

  const getDescription = (type: string) => {
    let description = null;
    switch (type) {
      case "Biometrics":
        description = "Please remember to take your biometric (vital) reading";
        break;
      case "Medication":
        description = "Please remember to take your medication";
        break;
      case "Appointment":
        description =
          "Please remember that you have the upcoming appointment scheduled";
        break;
      case "VirtualVisit":
        description = "";
        break;
      default:
        description = "";
    }
    return description;
  };

  const getAutomationDetails = (reminderAutomation: Automation) => {
    const action: ReminderAction | VirtualVisitAction = reminderAutomation
      .actions[0] as ReminderAction | VirtualVisitAction;
    const trigger: Trigger = reminderAutomation.triggers[0];
    let type = "";
    let description = "";
    let frequency = null;
    let date = null;
    let weekDayOption = null;
    let dailyTime = null;
    let weekDayTime = null;

    if ("message" in action) {
      if (action.message.includes("biometric")) {
        type = ReminderType.Biometrics;
      } else if (action.message.includes("medication")) {
        type = ReminderType.Medication;
      } else if (action.message.includes("appointment")) {
        type = ReminderType.Appointment;
      } else {
        type = ReminderType.Other;
        description = (action as ReminderAction).message;
      }
    } else if (action.type === AutomationActionType.RequestVirtualVisit) {
      type = ReminderType.VirtualVisit;
    }
    if (type !== ReminderType.Other) {
      description = getDescription(type);
    }
    if (trigger) {
      if (trigger.type === "DATE_TIME") {
        frequency = Frequency.Once;
        date = dayjs(trigger.when);
      } else if (trigger.type === "SCHEDULE") {
        const occurrence = trigger.occurrences[0];
        weekDayOption = Str.CapitalizeFirstLetter(occurrence.day.toLowerCase());
        const now = new Date();
        date = dayjs(
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            occurrence.hour,
            occurrence.minute
          )
        );
        if (trigger.occurrences.length > 1) {
          frequency = Frequency.Daily;
          dailyTime = date;
        } else {
          frequency = Frequency.Weekly;
          weekDayTime = date;
        }
      }
    }
    return {
      type,
      description,
      frequency,
      date,
      weekDayOption,
      dailyTime,
      weekDayTime,
      action,
      trigger,
    };
  };

  useEffect(() => {
    if (patient) {
      let reminders: Automation[] | undefined = patient.automations?.filter(
        (a) => {
          return (
            (a.actions[0].type === AutomationActionType.Remind ||
              a.actions[0].type === AutomationActionType.RequestVirtualVisit) &&
            (a.triggers[0].type === "SCHEDULE" ||
              (a.triggers[0].type === "DATE_TIME" &&
                !(DateTime.now() > DateTime.fromISO(a.triggers[0].when))))
          );
        }
      );
      const automations = reminders?.map((reminder) => {
        const automationDetails = getAutomationDetails(reminder);
        return {
          ...reminder,
          ...automationDetails,
        };
      });
      if (automations?.length) {
        setAutomations(automations);
      } else {
        setAutomations([]);
      }
    }
  }, [patient]);

  return (
    <RSModal size="full" backdrop overflow={true} open={open} onClose={onClose}>
      <RSModal.Header closeButton>
        <Typography variant="h6">Manage Reminders</Typography>
        <Typography variant="body1">
          {patient.demographics.legalName.firstName}{" "}
          {patient.demographics.legalName.lastName}
        </Typography>
      </RSModal.Header>
      <RSModal.Body>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={addReminder}
            startIcon={<AddIcon />}
            sx={{ mt: 2, mb: 3 }}
          >
            ADD REMINDER
          </Button>
        </div>

        <Grid container rowSpacing={2} columnSpacing={1}>
          {automations.length
            ? automations.map((automation: Automation, index) => {
                if (
                  automation.id &&
                  deletedAutomationIds.indexOf(automation.id) < 0
                ) {
                  return (
                    <Grid item xs={showSmallCards ? 3 : 6} key={automation.id}>
                      <ViewReminders
                        index={index}
                        patient={patient}
                        deleteReminder={deleteReminder}
                        updateReminder={updateReminder}
                        reminderAutomation={automation}
                        getDescription={getDescription}
                        updateCurrentPatient={updateCurrentPatient}
                        isAutomation={true}
                        removeAutomation={removeAutomation}
                      />
                    </Grid>
                  );
                }
              })
            : null}
          {reminders &&
            reminders.map((reminder, index) => {
              return (
                <Grid item xs={showSmallCards ? 3 : 6}>
                  <ViewReminders
                    index={index}
                    patient={patient}
                    deleteReminder={deleteReminder}
                    updateReminder={updateReminder}
                    reminderAutomation={reminder}
                    getDescription={getDescription}
                    updateCurrentPatient={updateCurrentPatient}
                    isAutomation={false}
                  />
                </Grid>
              );
            })}
        </Grid>
      </RSModal.Body>
    </RSModal>
  );
};
export default ManageReminders;
