import { convertDateToLocalTimezone } from "../../util/dateToLocalTimezone";
import { CanMake, Has_Meta, Has_SetValues } from "../model/model.interface";

export type DeserializerStack<M> = { [key: string]: () => void };

export abstract class Deserializer<
  E extends Has_Meta,
  M extends Has_SetValues<E>
> {
  /**
   * Holds instance of entity model
   */
  protected entityModel: M;

  /**
   * Stack of syncronous deserializing functions.
   *
   * @RULES
   * There is one rule applied on every function added in deserializer stack:
   * - Every function must be syncronous. Use `middlewares` for asyncronous tasks.
   *
   */
  protected abstract deserializers: DeserializerStack<M>;

  constructor(private readonly entity: E, private makeModel: CanMake<E, M>) {
    this.entityModel = this.makeModel({
      ...this.entity,
      id: this.entity._meta?.id,
    });
  }

  protected setValue<K extends keyof E>(of: K, value: E[K]) {
    this.entityModel.setValues(of)(value);
  }

  protected convertLocalTimezone(date: string) {
    return convertDateToLocalTimezone(new Date(date)).toJSDate().toISOString();
  }

  /**
   * Executes all syncronous functions from the `deserializers` stack
   * And return entity model after deserialization
   * @returns
   *
   */
  deserialize(): M {
    const deserializerTasks = Object.keys(this.deserializers);

    if (deserializerTasks.length === 0) {
      return this.entityModel;
    }

    deserializerTasks.forEach((task) => {
      this.deserializers[task]();
    });

    return this.entityModel;
  }
}
