import { ERROR } from "../../constants";
import { RCAResponseErrorParser } from "../../error/parser/rca.error.parser";
import { Handler } from "./handler";
import { Notification } from "../../../components/notification/notification";
import { Receiver } from "../receiver/receiver";

/**
 * Handles all failed Commands. If a command fails, this will handle that failure.
 */
export class FailureHandler<T> extends Handler<T> {
  async handle(event: Error, receiver?: Partial<Receiver<T>>): Promise<void> {
    Notification.notify(ERROR, RCAResponseErrorParser.parse(event).message());
  }
}
