import DatePicker from "react-datepicker";
import React from "react";
import styles from "./styles.module.scss";
import { getCurrentDate, SummaryDateRange } from "./constants";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import {
  setPatientNotes,
  setUnresolvedPatientNotes,
} from "@/domain/notes/redux/actions";
import {
  getAllNotes,
  getUnresolvedNotes,
} from "@/domain/patient/view/tabs/monitoring/helpers";

type Props = {
  selectedDateRange: SummaryDateRange;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setSelectedDateRange: (range: SummaryDateRange) => void;
};

export const SummaryDateRangePicker: React.FC<Props> = ({
  selectedDateRange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setSelectedDateRange,
}) => {
  const patient = useSelector(selectFirstPatient);
  const dispatch = useDispatch();
  const handleStartDateChange = (start: Date) => {
    setSelectedDateRange(SummaryDateRange.Custom);
    const _start = DateTime.fromJSDate(start).startOf("day");
    setStartDate(_start.toJSDate());
  };

  const handleEndDateChange = (end: Date) => {
    setSelectedDateRange(SummaryDateRange.Custom);
    const _endDate = DateTime.fromJSDate(end).endOf("day");
    setEndDate(_endDate.toJSDate());
  };

  const handleSelectDateRange = async (range: any) => {
    let lookBackDays = 0;
    let startDate: Date | undefined;
    let endDate: Date | undefined;
    if (
      ![SummaryDateRange.PreviousMonth, SummaryDateRange.CurrentMonth].includes(
        range
      )
    ) {
      lookBackDays = range;
    }
    setSelectedDateRange(range);
    if (range === SummaryDateRange.CurrentMonth) {
      startDate = DateTime.fromJSDate(getCurrentDate)
        .startOf("month")
        .toJSDate();
      setStartDate(startDate);
      endDate = getCurrentDate;
      setEndDate(endDate);
    } else if (range === SummaryDateRange.PreviousMonth) {
      startDate = DateTime.fromJSDate(getCurrentDate)
        .minus({ month: 1 })
        .startOf("month")
        .toJSDate();
      endDate = DateTime.fromJSDate(getCurrentDate)
        .minus({ month: 1 })
        .endOf("month")
        .toJSDate();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (range !== SummaryDateRange.Custom) {
      startDate = DateTime.fromJSDate(getCurrentDate)
        .minus({ day: lookBackDays })
        .plus({ minute: 1 })
        .startOf("day")
        .toJSDate();
      setStartDate(startDate);
      endDate = getCurrentDate;
      setEndDate(endDate);
    }
    if (startDate && endDate) {
      const observationNotes = await getAllNotes(
        patient?.id!,
        startDate,
        endDate
      );
      const unresolvedNotes = await getUnresolvedNotes(
        patient?.id!,
        startDate,
        endDate
      );
      observationNotes &&
        dispatch(setPatientNotes(patient?.id!, observationNotes));
      unresolvedNotes &&
        dispatch(setUnresolvedPatientNotes(patient?.id!, unresolvedNotes));
    }
  };

  return (
    <div>
      <div className="text-center font-weight-bold h6">Select Date Range</div>
      <div className="border p-3 mt-2">
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.Last14Days}
            onClick={() => handleSelectDateRange(SummaryDateRange.Last14Days)}
          />
          Last 14 Days
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.Last7Days}
            onClick={() => handleSelectDateRange(SummaryDateRange.Last7Days)}
          />
          Last 7 Days
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.Last2Days}
            onClick={() => handleSelectDateRange(SummaryDateRange.Last2Days)}
          />
          Last 2 Days
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.PreviousMonth}
            onClick={() =>
              handleSelectDateRange(SummaryDateRange.PreviousMonth)
            }
          />
          Previous Month
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.CurrentMonth}
            onClick={() => handleSelectDateRange(SummaryDateRange.CurrentMonth)}
          />
          Current Month
        </div>
        <div className={styles.summaryCheckbox}>
          <input
            type="radio"
            className="mr-2"
            name="dateRangeOpt"
            checked={selectedDateRange === SummaryDateRange.Custom}
            onClick={() => handleSelectDateRange(SummaryDateRange.Custom)}
          />
          Custom
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <div className="text-center font-14">
              From
              <DatePicker
                className="form-control"
                placeholderText="MM-DD-YYYY"
                dateFormat="MM-dd-yyyy"
                selected={startDate}
                autoComplete="off"
                onChange={handleStartDateChange}
                required={false}
                isClearable={false}
                maxDate={getCurrentDate}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="text-center font-14">
              To
              <DatePicker
                className="form-control"
                placeholderText="MM-DD-YYYY"
                dateFormat="MM-dd-yyyy"
                selected={endDate}
                autoComplete="off"
                onChange={handleEndDateChange}
                required={false}
                isClearable={false}
                minDate={startDate}
                maxDate={getCurrentDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
