import { Dispatch } from "redux";

import { Organization } from "@/domain/organization/model/types";
import { dispatchSelectOrganization } from "@/domain/organization/redux/actions";
import { Practice } from "@/domain/practice/model/types";
import {
  dispatchSetPractices,
  dispatchSelectAllPractices,
  dispatchDeselectAllPractices,
} from "@/domain/practice/redux/actions";
import { Provider } from "@/domain/provider/model";
import {
  deselectAllProviders,
  ProviderId,
  selectAllProviders,
  setProviders,
  dispatchSetTargetProvider,
} from "@/domain/provider/redux";
import { PaginationConfig } from "@/library/types";
import { RootState } from "@/types";
import { PatientModel } from "@/domain/patient/model";
import { FilterItemDetail, Patient } from "@/domain/patient/model/types";
import {
  dispatchSetPatientPageLimit,
  dispatchSetAssignmentPatientPageLimit,
  dispatchSetAssignmentPatientFilters,
  dispatchSetAssignmentPatientsPaginationConfig,
  dispatchSetPatientsPaginationConfig,
  dispatchSelectPatient,
  dispatchDeselectAllPatients,
  dispatchSelectAllPatients,
  dispatchSetPatientFilters,
  dispatchUpdatePatientProperty,
  dispatchSetTargetPatient,
  dispatchSetMonitoringDates,
  dispatchUpdatePatient,
  dispatchSetPatients,
  dispatchSetFilterItems,
} from "@/domain/patient/redux/actions";
import { setAssignmentPatientsThunk } from "@/domain/patient/redux/middleware";
import { PatientId } from "@/domain/patient/redux/types";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { selectPatientList } from "@/domain/patient/redux/selectors";
import { Tag } from "@/domain/tags/model/types";
import { dispatchSetLocationTags } from "@/domain/tags/redux/actions";

export const PatientAdminTableHeader: string[] = [
  "last name",
  "first name",
  "mrn",
  "primary dx",
  "entity / branch",
  "location",
  "provider",
  "care team member",
  "action",
];
export const PatientClinicalTableHeader: (string | string[])[] = [
  "status",
  "last name",
  "first name",
  "dob (age)",
  "gender",
  "patient id",
  "program",
  "risk level",
  ["wgt", "lbs", "observation"],
  ["bp", "sbp/dbp", "observation"],
  ["hr", "bpm", "observation"],
  ["o2 sat", "spo2", "observation"],
  ["gluc", "mg/dl", "observation"],
  ["temp", "f", "observation"],
];

export const PatientAssignmentTableHeader: string[] = [
  "last name",
  "first name",
  "primary dx",
  "entity / branch",
  "provider",
  "care team member",
];

export const NO_TAGS_FILTER = "unassigned";
export const MY_WATCHLIST = "mywatchlist";
export const MY_PATIENTS = "mypatients";

export enum FILTER_TYPES {
  FILTER_TYPE_ALERT = "Alert",
  FILTER_TYPE_LOCATION = "Location",
  FILTER_TYPE_PRACTICE = "Practice",
  FILTER_TYPE_PROGRAM = "Program",
  FILTER_TYPE_PROVIDER = "Provider",
  FILTER_TYPE_STATUS = "Status",
  FILTER_TYPE_USER = "User",
}

export enum LocationFilterRole {
  Filter = "Filter",
  Locations = "Locations",
}

export const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    setPatients: (patients: Patient[]) =>
      dispatch(dispatchSetPatients(patients)),
    setTargetPatient: (id: PatientId): any =>
      dispatch(dispatchSetTargetPatient(id)),
    setAssignmentPatients: (patients: PatientModel[]) =>
      dispatch(setAssignmentPatientsThunk(patients) as any),
    setPatientPageLimit: (pageLimit: number) =>
      dispatch(dispatchSetPatientPageLimit(pageLimit)),
    setAssignmentPatientPageLimit: (pageLimit: number) =>
      dispatch(dispatchSetAssignmentPatientPageLimit(pageLimit)),
    setAssignmentPatientFilters: (filters: any) =>
      dispatch(dispatchSetAssignmentPatientFilters(filters)),
    setAssignmentFilterItems: (filterItemDetails: FilterItemDetail[]) =>
      dispatch(dispatchSetFilterItems(filterItemDetails)),
    setAssignmentPatientsPaginationConfig: (
      paginationConfig: PaginationConfig
    ) =>
      dispatch(dispatchSetAssignmentPatientsPaginationConfig(paginationConfig)),

    setPatientsPaginationConfig: (paginationConfig: PaginationConfig) =>
      dispatch(dispatchSetPatientsPaginationConfig(paginationConfig)),

    setLocationTags: (tags: Tag[]) => dispatch(dispatchSetLocationTags(tags)),

    selectPatient: (patient: Patient): any =>
      dispatch(dispatchSelectPatient(patient)),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
    selectAllPatients: () => dispatch(dispatchSelectAllPatients()),
    setPatientFilters: (filters: any) =>
      dispatch(dispatchSetPatientFilters(filters)),
    setFilterItems: (filterItems: FilterItemDetail[]) =>
      dispatch(dispatchSetFilterItems(filterItems)),
    setTargetProvider: (id: ProviderId) =>
      dispatch(dispatchSetTargetProvider(id)),
    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
    setPractices: (practices: Practice[]): any =>
      dispatch(dispatchSetPractices(practices)),
    selectAllPractices: () => dispatch(dispatchSelectAllPractices()),
    setProviders: (providers: Provider[]): any =>
      dispatch(setProviders(providers)),
    selectAllProviders: () => dispatch(selectAllProviders()),
    deselectAllProviders: () => dispatch(deselectAllProviders()),
    deselectAllPractices: () => dispatch(dispatchDeselectAllPractices()),
    updatePatientProperty: (
      patientId: PatientId,
      key: keyof Patient,
      value: any
    ) => dispatch(dispatchUpdatePatientProperty(patientId, key, value)),
    setMonitoringDates: (startDate: string, endDate: string) =>
      dispatch(dispatchSetMonitoringDates(startDate, endDate)),
    updatePatient: (patient: Patient) =>
      dispatch(dispatchUpdatePatient(patient)),
  };
};

export const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.user.currentUser,
    practices: state.practice.practices,
    providers: state.provider.providers,
    selectedProviders: state.provider.selectedProviders,
    selectedPractices: state.practice.selectedPractices,
    patients: selectPatientList(state),
    assignmentPatients: state.patient.assignmentPatients,
    paginationConfig: state.patient.paginationConfig,
    assignmentPatientPaginationConfig:
      state.patient.assignmentPatientPaginationConfig,
    patientPageLimit: state.patient.patientPageLimit,
    assignmentPatientPageLimit: state.patient.assignmentPatientPageLimit,
    selectedPatients: state.patient.selectedPatients,
    filters: state.patient.filters,
    filterItems: state.patient.filterItems,
    assignmentPatientFilters: state.patient.assignmentPatientFilters,
    selectedAssignmentPatients: state.patient.selectedAssignmentPatients,
    selectedOrganization: state.organization.selectedOrganization,
    locationTags: selectLocationTags(state),
  };
};
