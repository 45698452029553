import {
  CREATE_PROVIDER,
  DESELECT_ALL_PROVIDERS,
  DESELECT_PROVIDER,
  SELECT_ALL_PROVIDERS,
  SELECT_PROVIDER,
  SET_PROVIDERS,
  SET_PROVIDERS_PAGE_LIMIT,
  SET_PROVIDERS_PAGINATIONCONFIG,
  SET_TARGET_PROVIDER,
  SET_PROVIDER_FILTERS,
  SET_PROVIDER_SORTED_STATE,
  SET_ACTIVE_PROVIDER,
  UNSET_ACTIVE_PROVIDER,
} from "@/library/constants";
import { Provider, ProviderFilters } from "@/domain/provider/model";
import { ProviderAction, ProviderId, ProviderState } from "./types";

export const INITIAL_STATE: ProviderState = {
  providers: [],
  selectedProviders: [],
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  providerPageLimit: 25,
  targetProvider: "",
  filters: {
    isEnabled: undefined,
  },
  selectedProvider: undefined,
  sortedState: {
    direction: undefined,
    sortBy: undefined,
  },
};

export const providerReducer = (
  state: ProviderState = INITIAL_STATE,
  action: ProviderAction
) => {
  switch (action.type) {
    case CREATE_PROVIDER:
      return {
        ...state,
        providers: [action.payload, ...state.providers] as Provider[],
      };
    case SET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      };

    case SET_PROVIDERS_PAGINATIONCONFIG:
      return {
        ...state,
        paginationConfig: action.payload,
      };
    case SET_TARGET_PROVIDER:
      return {
        ...state,
        targetProvider: action.payload as unknown as ProviderId,
      };
    case SET_ACTIVE_PROVIDER:
      return {
        ...state,
        selectedProvider: action.payload,
      };
    case UNSET_ACTIVE_PROVIDER:
      return {
        ...state,
        selectedProvider: undefined,
      };

    case SET_PROVIDERS_PAGE_LIMIT:
      return {
        ...state,
        providerPageLimit: action.payload,
      };

    case SELECT_PROVIDER:
      return {
        ...state,
        selectedProviders: [
          ...state.selectedProviders,
          action.payload,
        ] as Provider[],
      };

    case DESELECT_PROVIDER:
      return {
        ...state,
        selectedProviders: state.selectedProviders.filter(
          (provider: Provider) =>
            provider.id !== (action.payload as unknown as Provider).id
        ),
      };

    case SELECT_ALL_PROVIDERS:
      return {
        ...state,
        selectedProviders: state.providers,
      };
    case DESELECT_ALL_PROVIDERS:
      return {
        ...state,
        selectedProviders: [],
      };

    case SET_PROVIDER_FILTERS:
      return {
        ...state,
        filters: action.payload as ProviderFilters,
      };
    case SET_PROVIDER_SORTED_STATE:
      return {
        ...state,
        sortedState: action.payload,
      };

    default:
      return state;
  }
};
