import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { Dispatch } from "redux";
import Swal from "sweetalert2";
import { Form as RForm } from "rsuite";
import { connect, useSelector } from "react-redux";
import { isEqual } from "lodash";
import cx from "clsx";

import { Select, TextInput, TextInputMask } from "@/components/form";
import {
  mapUserActionToUserType,
  User,
  UserActions,
  UserForm as UserFormType,
  UserModel,
  UserParentTypes,
} from "@/domain/user/model";
import { ERROR, MODEL_SYNCED } from "@/library/constants";
import {
  selectUser,
  setUsers,
  setUsersPaginationConfig,
} from "@/domain/user/redux";
import { PaginationConfig } from "@/library/types";
import { Credential, Credentials } from "@/library/types/credential";
import { MultiSelect } from "@/components/form/select/multiSelect";
import { LocationSelect } from "@/components/_selects/LocationSelect";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { RoleModel } from "@/domain/role/role.model";
import { Role } from "@/domain/role/role.interface";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { UserPolicyBuilder } from "@/library/policy/policy.builder";
import { FilterRule } from "@/library/types/filterRule";
import { Model, ModelCollection } from "@/library/model";
import { Iterator } from "@/library/iteration/iterator";
import { Invoker } from "@/library/common/invoker/invoker";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { Receiver } from "@/library/common/receiver/receiver";
import { RCAResourceActions } from "@/library/core/config/actions";
import { ResourceType } from "@/library/core/config/resource";
import { UserPolicy } from "@/library/policy/user.policy";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Notification } from "@/components/notification/notification";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import {
  autoAssignRule,
  INVALID_MONGO_ID,
  WILDCARD_SCOPE,
} from "@/library/serializers/user/helper";
import { AutoAssignRule } from "@/library/types/autoAssignRule";
import { RootState, SelectOption } from "@/types";
import { makeValidation } from "./helpers";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { Tag } from "@/domain/tags/model/types";

export type OwnProps = {
  handleClose: () => void;
  execSearch: (query: {}) => any;
  button: string;
  userAction?: UserActions | undefined;
  onSetFormik: (formik: any) => void;
  resourceAction?: RCAResourceActions;
  defaultOrganizationIds: string[];
  defaultPracticeIds: string[];
  targetUser: UserModel | null;
};
export type ConnectedProps = {
  users: User[];
  loggedInUser: User | null;
  selectedUser: User | null;
  targetUserId: string | null;
  userPageLimit: number;
  paginationConfig: PaginationConfig;
};
export type DispatchProps = {
  setUsers: (users: User[]) => void;
  setUsersPaginationConfig: (paginationConfig: PaginationConfig) => void;
  selectUser: (user: User) => void;
};
export type Props = OwnProps & ConnectedProps & DispatchProps;

const Component: FunctionComponent<Props> = ({
  handleClose,
  targetUserId,
  execSearch,
  ...props
}) => {
  const currentUser = props.loggedInUser
    ? UserModel.make(props.loggedInUser)
    : null;
  const targetUser = props.users.find(
    (user: User) => user.id === targetUserId
  ) as User;
  if (targetUser)
    targetUser.tagIds = targetUser.tags?.set?.length
      ? targetUser.tags?.set
      : targetUser.tags?.delete?.length
      ? targetUser?.tagIds.filter((t) => !targetUser.tags?.delete?.includes(t))
      : targetUser?.tagIds;
  const isEditMode = targetUser && props.button === "Save Changes";
  const [organizations, setOrganizations] = useState([
    { value: "", label: "" },
  ]);
  const [organizationId, setOrganizationId] = useState("");
  const [notifications, setNotifications] = useState(
    targetUser
      ? targetUser.notifications
      : {
          isEmailEnabled: false,
          isSmsEnabled: false,
          notifyOnCompliance: false,
        }
  );

  const [practices, setPractices] = useState([{ value: "", label: "" }]);
  const [allPracticesInUserScope, setAllPracticesInUserScope] = useState(
    isEditMode ? props.defaultPracticeIds : [INVALID_MONGO_ID]
  );
  const [practiceId, setPracticeId] = useState(
    props.defaultPracticeIds.toString() || ""
  );
  const [practiceCheckboxChecked, setPracticeCheckboxChecked] = useState(false);
  const [roles, setRoles] = useState([{ value: "", label: "" }]);
  const [roleId, setRoleId] = useState([]);

  let canUserAccumulateTime = false;
  let canUserAccessAllPractices = false;
  if (targetUser) {
    canUserAccumulateTime = isEditMode
      ? targetUser.canAccumulateBillableTime ?? false
      : false;
    canUserAccessAllPractices = isEditMode
      ? !!targetUser.autoAssignRules?.length
      : false;
  }
  const [allPractices, setAllPractices] = useState(canUserAccessAllPractices);

  const canUserHaveGlobalScope = isEditMode
    ? !(Object.keys(targetUser?.policy || {}).indexOf("Global") < 0)
    : false;
  const canUserAccessAllResources = isEditMode
    ? !(Object.keys(targetUser?.policy || {}).indexOf("Organization:*") < 0)
    : false;
  const parentEntity = currentUser?.attributes.get("parentEntities")[0];

  const [canAccumulateBillableTime, setCanAccumulateBillableTime] = useState(
    canUserAccumulateTime
  );
  const [canCreateOrgAndGlobalResources, setCanCreateOrgAndGlobalResources] =
    useState(canUserHaveGlobalScope);
  const [canAccessAllResources, setAccessAllResources] = useState(
    canUserAccessAllResources
  );

  const targetUserRoleIds = isEditMode
    ? UserPolicy.parseSync(targetUser?.policy).scope.getRoleIds()
    : [];
  const fetchOrganizations = async () => {
    if (currentUser?.parentType === UserParentTypes.Organization) {
      // user can only select Organization they belong to
      const parentId = currentUser.attributes.get("parentEntities")[0].id;
      if (!parentId) return;

      const organizationModel: OrganizationModel = await OrganizationModel.sync(
        parentId
      );
      await setOrganizations([
        {
          value: organizationModel.attributes.get("id") as string,
          label: organizationModel.attributes.get("name"),
        },
      ]);

      return;
    }

    const orgCollection: ModelCollection<OrganizationModel, Organization> =
      OrganizationModel.makeOrganizationCollection();
    orgCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    await orgCollection.withParams({
      limit: 100,
    });
    const iterator: Iterator<OrganizationModel, Organization> =
      await orgCollection.getMany();
    const allOrganizationModel = await iterator.getAll();

    await setOrganizations(
      allOrganizationModel.map((model: Model<Organization>) => {
        return {
          value: model.attributes.get("id") as string,
          label: model.attributes.get("name").trim(),
        };
      })
    );
  };

  // calculate what type of user is to be created
  const isCreateAdminUser = props.userAction === UserActions.CreateAdminUser;
  const isCreatePracticeUser =
    props.userAction === UserActions.CreatePracticeUser;
  const isCreateOrganizationUser =
    props.userAction === UserActions.CreateOrganizationUser;

  const defaultOrgValue = !isCreateAdminUser
    ? props.defaultOrganizationIds.toString()
    : props.defaultOrganizationIds;

  const defaultPracticeValue = isCreatePracticeUser
    ? props.defaultPracticeIds.toString()
    : allPractices
    ? []
    : props.defaultPracticeIds;

  const [selectedOrganizations, setSelectedOrganizations] =
    useState(defaultOrgValue);
  const [selectedPractices, setSelectedPractices] =
    useState(defaultPracticeValue);
  const [selectedRoles, setSelectedRoles] = useState(targetUserRoleIds);

  const [initialLocationTags, setInitialLocationTags] = useState([] as Tag[]);
  const [filteredLocationTags, setFilteredLocationTags] = useState([] as Tag[]);

  const [selectedTags, setSelectedTags] = useState([] as string[]);

  const setLocationTagsByPractices = (tags: Tag[]) => {
    setFilteredLocationTags(tags);
  };
  const fetchPractices = async (orgId?: string) => {
    if (currentUser?.parentType === UserParentTypes.Practice) {
      // user can only select Practice they belong to, so we set that organizationId
      // because the organization field will not even be shown to the user
      // Retrieve practiceModel first to get the organizationId
      const parentId = currentUser.attributes.get("parentEntities")[0].id;
      if (!parentId) return;

      const practiceModel: PracticeModel = await PracticeModel.sync(parentId);
      await setOrganizationId(
        practiceModel.attributes.get("organizationIds")[0]
      );
      formik.values.organization =
        practiceModel.attributes.get("organizationIds")[0];

      await setPractices([
        {
          value: practiceModel.attributes.get("id") as string,
          label: practiceModel.attributes.get("name"),
        },
      ]);
    } else {
      // User has permission to select multiple practices
      const practiceCollection: ModelCollection<PracticeModel, Practice> =
        PracticeModel.makePracticeCollection();
      practiceCollection.on(MODEL_SYNCED, () => {
        LoadingIndicator.fire.hide();
      });
      practiceCollection.withParams({
        limit: 100,
        organizationIds: [organizationId ? organizationId : orgId],
      });
      const iterator: Iterator<PracticeModel, Practice> =
        await practiceCollection.getMany();
      const allPracticeModels = await iterator.getAll();

      await setPractices(
        allPracticeModels.map((model: Model<Practice>) => {
          return {
            value: model.attributes.get("id") as string,
            label: model.attributes.get("name"),
          };
        })
      );
    }
  };

  const fetchRoles = async (): Promise<void> => {
    const roleCollection: ModelCollection<RoleModel, Role> =
      RoleModel.makeCollection();
    roleCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    await roleCollection.withParams({
      limit: 100,
    });
    const iterator: Iterator<RoleModel, Role> = await roleCollection.getMany();
    await iterator.getAll();

    await setRoles(
      roleCollection.container
        .filter((role: RoleModel) => role.pluck("name") !== "Anelto")
        .filter((role: RoleModel) => {
          const filterRules = role.pluck("filterRules");
          if (filterRules && filterRules.length) {
            return filterRules.every((rule: FilterRule) => {
              return (
                rule.userType ===
                  mapUserActionToUserType[props.userAction as UserActions] &&
                (rule.parentId
                  ? rule.parentId === currentUser?.parentType
                  : true)
              );
            });
          }
          return true;
        })
        .map((model: any) => {
          return {
            value: model.attributes.get("id") as string,
            label: model.attributes.get("name"),
          };
        })
    );
  };

  const getInitialValues = (copyUser: User | null) => {
    return {
      id: copyUser?.id || undefined,
      firstName: copyUser?.firstName || "",
      lastName: copyUser?.lastName || "",
      role: copyUser
        ? UserPolicy.parseSync(copyUser.policy).scope.getRoleId()
        : [],
      email: copyUser?.email || "",
      organization: props.defaultOrganizationIds?.toString?.() || "",
      practice: props.defaultPracticeIds?.toString?.() || "",
      credentials: isEditMode
        ? copyUser?.credentials === null
          ? "none"
          : copyUser?.credentials
        : "none",
      notifications: {
        isEmailEnabled: isEditMode
          ? copyUser?.notifications.isEmailEnabled
          : false,
        isSmsEnabled: isEditMode ? copyUser?.notifications.isSmsEnabled : false,
        notifyOnCompliance: isEditMode
          ? copyUser?.notifications.notifyOnCompliance
          : false,
      },
      phone: copyUser?.phone ?? "",
      userAction: props.userAction,
      practiceId: "",
      organizationId: "",
      locationIds: selectedTags,
      deleteLocationIds: [],
      roleId: "",
      hasAutoAssign: isEditMode ? !!copyUser?.autoAssignRules : false,
      currentUser: currentUser?.attributes.get("parentEntities")[0],
      canAccumulateBillableTime: isEditMode
        ? copyUser?.canAccumulateBillableTime
        : false,
    } as UserFormType;
  };

  const disableUser = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, disable it!",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Disabled!",
        text: "User has been disabled.",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const createUserPolicies = async () => {
    formik.values.policy = await UserPolicyBuilder.make(props.userAction!)
      .withOrganizations(
        formik.values.organization &&
          formik.values.organization !== WILDCARD_SCOPE
          ? formik.values.organization.split(",")
          : []
      )
      .withOrganization(organizationId)
      .withPractices(
        formik.values.practice ? formik.values.practice.split(",") : []
      )
      .withPractice(practiceId)
      .withAll(
        formik.values.organization === WILDCARD_SCOPE
          ? formik.values.organization
          : ""
      )
      .withGlobal(canCreateOrgAndGlobalResources)
      .withRole(roleId.length ? roleId : targetUserRoleIds)
      .build();
    formik.values.practiceId = practiceId;
  };

  let form: any = null;
  useEffect(() => {
    if (practiceCheckboxChecked && form && practices) {
      formik.setFieldValue(
        "practice",
        practices.map((practice: any) => practice.value).toString()
      );
    }
    fetchPractices();
  }, [practices.length]);

  useEffect(() => {
    if (practiceCheckboxChecked && form && practices) {
      fetchPractices();
    }
  }, [organizationId]);

  useEffect(() => {
    fetchOrganizations();
    // Setting the organization ID here allows the practices dropdown to pre-populate on edit
    if (props.defaultOrganizationIds?.length) {
      setOrganizationId(props.defaultOrganizationIds[0]);
    }
  }, [organizations.length]);

  useEffect(() => {
    if (canAccessAllResources) {
      handleSelectAllOrganization();
    }
  }, [canAccessAllResources]);

  useEffect(() => {
    fetchRoles();

    return () => {
      if (formik) formik.resetForm();
    };
  }, []);

  useEffect(() => {
    if (
      isEditMode &&
      targetUser?.tagIds?.length &&
      filteredLocationTags.length
    ) {
      const initialLocationTags = filteredLocationTags.filter((t) => {
        return targetUser.tagIds.includes(t._meta.id);
      });
      setInitialLocationTags(initialLocationTags);
      setSelectedTags(
        initialLocationTags.map((t) => {
          return t._meta.id;
        })
      );
    }
  }, [filteredLocationTags]);

  useEffect(() => {
    if (isEditMode && !isEqual(targetUser?.tagIds, selectedTags)) {
      const deletedLocationIds = targetUser?.tagIds.filter(
        (l) => !selectedTags.includes(l)
      );
      formik.setFieldValue("deleteLocationIds", deletedLocationIds);
    }
  }, [selectedTags]);
  /*
   * Set WildCard scope `*` to be added to user's policy when
   * `All` organizations is checked.
   */
  const handleSelectAllOrganization = async () => {
    await formik.setFieldValue("organization", WILDCARD_SCOPE);
    await formik.setFieldValue("hasAutoAssign", false);
  };

  const handleSubmit = async (
    values: UserFormType,
    helpers: FormikHelpers<UserFormType>
  ) => {
    const { setSubmitting, resetForm } = helpers;
    // add user's parent entity to ensure that selected roles are
    // assigned to their parent entity’s scope
    if (isCreateAdminUser && values.organization !== WILDCARD_SCOPE) {
      const parentEntityId = parentEntity?.id;
      const selectedOrganizations = values.organization.split(",");
      if (
        parentEntityId !== undefined &&
        selectedOrganizations.indexOf(parentEntityId) < 0
      ) {
        selectedOrganizations.push(parentEntityId);
        values.organization = selectedOrganizations.toString();
      }
    }
    await createUserPolicies();
    values.canAccumulateBillableTime = canAccumulateBillableTime;
    values.notifications = notifications;
    const shouldPushAutoAssign: boolean =
      values.hasAutoAssign && !values.organization.includes(WILDCARD_SCOPE);
    const rulesToReplace = new Map();
    const rulesToDelete: any[] = [];
    // calculate auto assign rules based on current policy changes.
    const autoAssignRules = (): AutoAssignRule[] | [] => {
      if (shouldPushAutoAssign) {
        const organizationsToAutoAssign = values.organization.split(",");
        const userRules = props.targetUser?.pluck?.("autoAssignRules");
        if (userRules?.length) {
          // remove already existing organizations from the list of autoAssignRules to push
          for (const rule of userRules) {
            if (organizationsToAutoAssign.includes(rule.parent.id)) {
              organizationsToAutoAssign.splice(
                organizationsToAutoAssign.indexOf(rule.parent.id),
                1
              );
            } else {
              rulesToDelete.push(rule.id);
            }
            // update roles in auto-assign rules
            if (roleId.length && roleId !== targetUserRoleIds) {
              rule.roleIds = roleId;
              const ruleId = rule.id;
              delete rule.id;
              rulesToReplace.set(ruleId, rule);
            }
          }
        }
        return [
          ...organizationsToAutoAssign.map((organizationId) =>
            autoAssignRule(organizationId, values.role, values.userAction)
          ),
        ];
      }
      return [];
    };

    if (isEditMode && !isEqual(targetUser.policy, values.policy)) {
      const targetUserAutoAssignRules =
        props.targetUser?.pluck?.("autoAssignRules");

      let setAutoAssignRules;
      const rules = autoAssignRules();
      if (!isCreatePracticeUser) {
        setAutoAssignRules = shouldPushAutoAssign
          ? {
              push: rules,
              set: rulesToReplace
                ? Object.fromEntries(rulesToReplace)
                : undefined,
              delete: rulesToDelete,
            }
          : {
              delete:
                targetUserAutoAssignRules?.map((rule) => rule.id) ?? undefined,
            };
      }
      try {
        await UserModel.setPolicy(
          targetUser.id!,
          setAutoAssignRules?.push?.length ||
            setAutoAssignRules?.delete?.length ||
            setAutoAssignRules?.set
            ? setAutoAssignRules
            : undefined,
          values.policy
        );
        // update current user form with new changes from edit
        targetUser.policy = values.policy;
        targetUser.autoAssignRules = rules;
        const selectedRoleId = Array.isArray(values.role)
          ? values.role[0]
          : values.role;
        // populate user table with update role(s)
        targetUser.roleName = roles.find(
          (role) => role.value === selectedRoleId
        )?.label;
      } catch (error: any) {
        if (error.status || error.response) {
          Notification.notify(
            ERROR,
            RCAResponseErrorParser.parse(error).message()
          );
          return;
        } else {
          throw error;
        }
      }
    }

    // update basic user information
    await Invoker.make<User>(
      Receiver.make(
        UserModel.serialize(values, props.resourceAction!),
        ResourceType.user,
        props.resourceAction!
      ),
      CleanTask.make(
        () => resetForm(getInitialValues(null) as any),
        () => handleClose()
      ),
      FinallyTask.make(() => setSubmitting(false))
    ).invoke();
  };

  const formik = useFormik<UserFormType>({
    initialValues: getInitialValues(isEditMode ? targetUser : null),
    validationSchema: makeValidation(
      props.userAction,
      isCreateAdminUser,
      isEditMode,
      canAccessAllResources
    ),
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  props.onSetFormik(formik);

  useEffect(() => {
    if (formik.values.practice)
      setAllPracticesInUserScope(formik.values.practice.split(","));
  }, [formik.values.practice.length]);

  const handleTextInputChange =
    (key: keyof UserFormType) => (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, event.target.value);
    };

  const handleCheckboxChange =
    (key: keyof UserFormType) => (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, event.target.checked);
      setNotifications({
        ...notifications,
        [event.target.name]: event.target.checked,
      });
    };

  const colSize = isEditMode ? "col-6" : "col-4";

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col mb-3">
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  required
                  hasError={!!formik.errors.firstName}
                  onChange={handleTextInputChange("firstName")}
                />
              </div>

              <div className="col mb-3">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  required
                  hasError={!!formik.errors.lastName}
                  onChange={handleTextInputChange("lastName")}
                />
              </div>
              {!isCreateAdminUser && (
                <div className="col mb-3">
                  <Select
                    label={
                      isCreateAdminUser ? "Medical Credential" : "Credentials"
                    }
                    name="credentials"
                    required
                    hasError={!!formik.errors.credentials}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "credentials",
                        value.currentTarget.value
                      );
                    }}
                  >
                    {Credentials.length > 0 &&
                      Credentials.map((credential: Credential, key) => (
                        <option
                          key={`credential${key}`}
                          value={credential.abbreviation}
                        >
                          {credential.displayName}{" "}
                          {credential.abbreviation !== "none" &&
                            " (" + credential.abbreviation + ")"}
                        </option>
                      ))}
                  </Select>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col mb-3">
                <TextInput
                  label="Email"
                  name="email"
                  type="text"
                  placeholder="Email address"
                  required
                  disabled={isEditMode}
                  hasError={!!formik.errors.email}
                  onChange={handleTextInputChange("email")}
                />
              </div>

              <div className="col mb-3">
                <TextInputMask
                  type="Mobile"
                  label="Mobile"
                  name="phone"
                  mask="+1 (999) 999-9999"
                  placeholder="Phone number"
                  value={formik.values.phone}
                  required
                  hasError={!!formik.errors.phone}
                  onChange={handleTextInputChange("phone")}
                />
              </div>
              {props.userAction !== UserActions.CreateAdminUser && (
                <div className="col mb-3">
                  <div className="mb-2">
                    <label>Notifications</label>
                  </div>

                  <label className="mr-4">
                    <input
                      type="checkbox"
                      name="isEmailEnabled"
                      checked={notifications.isEmailEnabled}
                      onChange={handleCheckboxChange("isEmailEnabled")}
                      className={cx(styles.statusDropdown, "mr-2")}
                    />
                    Email
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="isSmsEnabled"
                      checked={notifications.isSmsEnabled}
                      onChange={handleCheckboxChange("isSmsEnabled")}
                      className={cx(styles.statusDropdown, "mr-2")}
                    />
                    SMS
                  </label>
                </div>
              )}
            </div>

            <div className="row">
              {currentUser?.parentType !== UserParentTypes.Practice && (
                <div className={cx(colSize, "mb-3")}>
                  <RForm.Group>
                    <div className="form-group m-b-0">
                      <div className="form-inline justify-content-between mb-1">
                        <label className={globalStyles.requiredField}>
                          Organization
                        </label>
                        {isCreateAdminUser && (
                          <label className="">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={canAccessAllResources}
                              onClick={(event: any) => {
                                if (event.target.checked) {
                                  formik.setFieldValue("hasAutoAssign", false);
                                  setOrganizationId("");
                                  setOrganizations([]);
                                  setSelectedOrganizations([]);
                                  formik.setFieldValue("organization", "");
                                  const organizationResetBtn =
                                    document.getElementsByClassName(
                                      "rs-picker-toggle-clean"
                                    )[0] as unknown as HTMLElement;
                                  organizationResetBtn?.click();
                                  fetchOrganizations();
                                } else {
                                  setOrganizationId("");
                                  setOrganizations([]);
                                  formik.setFieldValue("organization", "");
                                  formik.setFieldValue("hasAutoAssign", true);
                                }

                                if (isCreateAdminUser) {
                                  // Then we need to set practice field to some string value
                                  // Set credentials too
                                  formik.setFieldValue(
                                    "practice",
                                    INVALID_MONGO_ID
                                  );
                                } else {
                                  // In case organization get changed after selecting practice
                                  // practice field should reset
                                  setPracticeId("");
                                  formik.setFieldValue("practice", "");
                                }
                                setAccessAllResources(!canAccessAllResources);
                              }}
                              onChange={() => {}}
                            />
                            {""}
                            All
                          </label>
                        )}
                      </div>
                    </div>
                    <MultiSelect
                      defaultValue={defaultOrgValue}
                      disabled={
                        canAccessAllResources ||
                        (isEditMode && !isCreateAdminUser)
                      }
                      name="organization"
                      sortable={true}
                      picker={isCreateAdminUser ? "check" : "select"}
                      data={organizations}
                      onChange={async (value) => {
                        await setOrganizationId(value);
                        await fetchPractices(value);
                        if (isCreateAdminUser) {
                          // Then we need to set practice field to some string value
                          // Set credentials too
                          await formik.setFieldValue("practice", "1238475647");
                          await formik.setFieldValue("credentials", "none");
                          await formik.setFieldValue("hasAutoAssign", true);
                        } else {
                          // In case organization get changed after selecting practice,
                          // practice field should reset
                          setPractices([]);
                          setPracticeId("");
                          await formik.setFieldValue("practice", "");
                        }
                        if (!canAccessAllResources) {
                          const index = value?.indexOf(WILDCARD_SCOPE, 0);
                          if (index > -1) value?.splice(index, 1);
                        }
                        if (Array.isArray(value)) {
                          await formik.setFieldValue(
                            "organization",
                            value.toString()
                          );
                          return;
                        }
                        await formik.setFieldValue("organization", value || "");
                        setSelectedOrganizations(value);
                        await formik.setFieldValue(
                          "organizationId",
                          value || ""
                        );
                        //clear location dropdown by fetching tags with invalid practice Id
                        setAllPracticesInUserScope([INVALID_MONGO_ID]);
                      }}
                      value={selectedOrganizations}
                      isSearchable={
                        currentUser?.parentType === UserParentTypes.Admin
                      }
                      onOpen={() => {
                        if (!organizations.length) fetchOrganizations();
                      }}
                      findValueBy={
                        isCreateAdminUser ? [organizationId] : organizationId
                      }
                      placeholder="..."
                      onSelect={async (value) => {
                        setSelectedOrganizations(value);
                      }}
                      hasError={!!formik.errors.organization}
                    />
                  </RForm.Group>
                </div>
              )}

              {!isCreateAdminUser && (
                <div className={cx(colSize, "mb-3")}>
                  <RForm.Group>
                    <div className="form-group m-b-0">
                      <div className="form-inline justify-content-between mb-1">
                        <label className={globalStyles.requiredField}>
                          Entity/Branch
                        </label>

                        {isCreateOrganizationUser && (
                          <>
                            <label className="">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={allPractices}
                                onClick={(event: any) => {
                                  if (targetUser?.id) {
                                    UserModel.sync(targetUser.id).then(
                                      (user) => {
                                        targetUser.autoAssignRules =
                                          user.pluck("autoAssignRules");
                                      }
                                    );
                                  }
                                  if (event.target.checked) {
                                    setPractices([]);
                                    setSelectedPractices([]);
                                    setPracticeId("");
                                    formik.setFieldValue(
                                      "hasAutoAssign",
                                      event.target.checked
                                    );
                                    formik.setFieldValue("practice", "");
                                    setPracticeCheckboxChecked(true);
                                    fetchPractices().then(() => {
                                      formik.setFieldValue(
                                        "practice",
                                        practices
                                          .map(
                                            (practice: any) => practice.value
                                          )
                                          .toString()
                                      );
                                    });
                                  } else {
                                    setPractices([]);
                                    setPracticeId("");
                                    setAllPracticesInUserScope([
                                      INVALID_MONGO_ID,
                                    ]);
                                    formik.setFieldValue(
                                      "hasAutoAssign",
                                      false
                                    );
                                    formik.setFieldValue("practice", "");

                                    setPracticeCheckboxChecked(false);
                                  }
                                  setAllPractices(!allPractices);
                                }}
                                onChange={() => {}}
                              />{" "}
                              All
                            </label>
                          </>
                        )}
                      </div>
                    </div>

                    <MultiSelect
                      defaultValue={defaultPracticeValue}
                      disabled={
                        allPractices || (isEditMode && isCreatePracticeUser)
                      }
                      name="practice"
                      sortable={true}
                      data={practices}
                      optional={
                        props.userAction === UserActions.CreateOrganizationUser
                      }
                      onChange={async (value) => {
                        if (value) {
                          formik.setFieldValue("hasAutoAssign", false);
                        }
                        if (Array.isArray(value)) {
                          formik.setFieldValue("practice", value.toString());
                          return;
                        }
                        formik.setFieldValue("practice", value || "");
                        setPracticeId(value);
                        formik.values.practiceId = value;
                        setSelectedPractices(value);
                      }}
                      value={selectedPractices}
                      isSearchable={true}
                      onOpen={() => {
                        if (!practices.length) {
                          fetchPractices();
                        }
                      }}
                      findValueBy={
                        isCreatePracticeUser ? practiceId : [practiceId]
                      }
                      placeholder="..."
                      onSelect={async (value: string) => {
                        setSelectedPractices(value);
                        if (!value.length) {
                          setAllPracticesInUserScope([INVALID_MONGO_ID]);
                          if (isEditMode) {
                            formik.values.deleteLocationIds = targetUser.tagIds;
                          }
                        } else {
                          setAllPracticesInUserScope(
                            Array.isArray(value) ? value : [value]
                          );
                        }
                        formik.values.locationIds = [];
                      }}
                      picker={isCreatePracticeUser ? "select" : "check"}
                      hasError={!!formik.errors.practice}
                    />
                  </RForm.Group>
                </div>
              )}

              <div className={cx(colSize, "mb-3")}>
                <RForm.Group>
                  <label className={globalStyles.requiredField}>Role</label>
                  <MultiSelect
                    defaultValue={isEditMode && targetUserRoleIds}
                    name="role"
                    data={roles}
                    sortable={true}
                    onChange={async (value) => {
                      formik.setFieldValue("role", value || []);
                      setRoleId(value);
                      setSelectedRoles(value);
                      formik.values.roleId = value;
                    }}
                    value={selectedRoles}
                    isSearchable={false}
                    onOpen={() => {
                      if (!roles.length) fetchRoles();
                    }}
                    findValueBy={organizationId}
                    placeholder="..."
                    onSelect={async (value: string) => {}}
                    picker="check"
                    hasError={!!formik.errors.role}
                  />
                </RForm.Group>
              </div>
            </div>

            <div className="row">
              <div className={cx(colSize)}>
                <label>Location</label>
                <LocationSelect
                  name="locationIds"
                  data={[]}
                  selectedId={selectedTags}
                  selectedPractices={
                    allPracticesInUserScope.length
                      ? allPracticesInUserScope
                      : selectedPractices
                  }
                  onSetLocationTags={setLocationTagsByPractices}
                  onChange={async (value) => {
                    formik.setFieldValue("locationIds", value || []);
                    setSelectedTags(value);
                  }}
                  picker="check"
                  placeholder={"..."}
                  isSearchable={false}
                  hasError={!!formik.errors.locationIds}
                  onOpen={() => {
                    if (allPractices) {
                      const allPractices = practices.map((p) => {
                        return p.value;
                      });
                      setAllPracticesInUserScope(allPractices);
                    }
                  }}
                />
              </div>

              <div className="col-4 mt-4">
                {!isCreateAdminUser ? (
                  <div>
                    <label className="mr-4">
                      <input
                        type="checkbox"
                        checked={canAccumulateBillableTime}
                        name="canAccumulateTime"
                        onClick={() =>
                          setCanAccumulateBillableTime(
                            !canAccumulateBillableTime
                          )
                        }
                        className={cx(styles.statusDropdown, "mr-2")}
                        onChange={() => {}}
                      />
                      Track time for billing
                    </label>
                  </div>
                ) : (
                  <div>
                    <label className="mr-4">
                      <input
                        type="checkbox"
                        checked={canCreateOrgAndGlobalResources}
                        name="canCreateOrgAndGlobalResources"
                        onClick={() =>
                          setCanCreateOrgAndGlobalResources(
                            !canCreateOrgAndGlobalResources
                          )
                        }
                        className={cx(styles.statusDropdown, "mr-2")}
                        onChange={() => {}}
                      />
                      Allow this user to create organizations and global
                      resources
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className={cx(colSize, "mt-5")}>
                {isEditMode && (
                  <button
                    disabled
                    type="button"
                    className="btn btn-danger sweet-multiple"
                    onClick={disableUser}
                  >
                    Disable
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

const mapStateToProps = (state: RootState): ConnectedProps => {
  return {
    users: state.user.users,
    loggedInUser: state.user.currentUser,
    userPageLimit: state.user.userPageLimit,
    paginationConfig: state.user.paginationConfig,
    selectedUser: state.user.selectedUser,
    targetUserId: state.user.targetUserId,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setUsers: (users: User[]): any => dispatch(setUsers(users)),
    setUsersPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setUsersPaginationConfig(paginationConfig)),

    selectUser: (user: User): any => dispatch(selectUser(user)),
  };
};

export const UserForm = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
