import { format } from "date-fns";

import { DATE_FORMAT } from "@/pipes/date/constants";
import {
  AlertTableHeader,
  ReportTypes,
  RPMTableHeader,
} from "../../model/reports";
import { dateFormatter } from "@/pipes/date";
import { Note, SeverityLevel } from "@/domain/notes/model/types";
import { Patient } from "@/domain/patient/model/types";
import { Str } from "@/util/Str";
import { arrClone } from "@/util/clone";
import { DateTime, FixedOffsetZone } from "luxon";

export const MINUTES_PER_HOUR = 60 as const;
export interface ReportsTypeOptions {
  header: any;
  Heading: string;
  FileName: string;
  data: any;
}

export const selectedReportType: {
  [value in ReportTypes]?: ReportsTypeOptions;
} = {
  /**
   * RPM Report type Summary
   */
  [ReportTypes.rpm]: {
    header: RPMTableHeader,
    Heading: "RPM Billing Report Summary",
    FileName: "RPM_Billing_Report_Summary",
    data: (patients: Patient[]) => {
      return patients.map((patient) => {
        let eligibilityDate;
        if (patient.billing?.cpt99454.eligibilityDate) {
          //get patient's device fixed timezone offset in minutes
          // and return eligibility date in patient's timezone
          const deviceInfo = arrClone(patient?.deviceInfo || []).reverse()[0];
          const zone = FixedOffsetZone.instance(
            (deviceInfo?.timezoneOffset ?? 0) * MINUTES_PER_HOUR
          );
          eligibilityDate = zone
            ? format(
                new Date(
                  DateTime.fromISO(patient.billing?.cpt99454.eligibilityDate, {
                    zone,
                  }).toString()
                ) as Date,
                DATE_FORMAT
              )
            : format(
                new Date(patient.billing?.cpt99454.eligibilityDate) as Date,
                DATE_FORMAT
              );
        }
        return [
          patient._meta?.id,
          patient.demographics.legalName.lastName,
          patient.demographics.legalName.firstName,
          patient.demographics.dateOfBirth
            ? dateFormatter({
                date: patient.demographics.dateOfBirth,
                isDateOfBirth: true,
              })
            : "",
          patient.mrn || "",
          Str.CapitalizeFirstLetter(patient.lifecycle.state.toLowerCase()),
          patient?.practice?.name || "",
          patient.provider
            ? `${patient.provider.firstName} ${patient.provider.lastName}, ${patient.provider.credentials} `
            : "",
          patient.program || "",
          patient.tags?.find((t) => t.type === "location")?.value || "",
          eligibilityDate,
          patient.billing?.cpt99453.reimbursableEvents,
          patient.billing?.cpt99454.reimbursableEvents,
          patient.billing?.cpt99457.reimbursableEvents,
          patient.billing?.cpt99458.reimbursableEvents,
        ];
      });
    },
  },

  /**
   * Alert Report Type
   */
  [ReportTypes.alert]: {
    header: AlertTableHeader,
    Heading: "PATIENT RPM ALERT REPORT",
    FileName: "RPM_Alert_Report_",
    data: (patients: Patient[]) => {
      return patients.map((patient) => {
        return [
          patient._meta?.id,
          patient.demographics.legalName.lastName,
          patient.demographics.legalName.firstName,
          patient.demographics.dateOfBirth
            ? dateFormatter({
                date: patient.demographics.dateOfBirth,
                isDateOfBirth: true,
              })
            : "",
          patient.mrn || "",
          patient.primaryCondition,
          patient.secondaryCondition,
          patient?.practice?.name || "",
          patient.provider
            ? `${patient.provider.firstName} ${patient.provider.lastName}, ${patient.provider.credentials} `
            : "",
          patient.assignedUserName || "",
          getRedAlertsCount(patient.notes || []),
          getYellowAttentionsCount(patient.notes || []),
        ];
      });
    },
  },
};

/**
 * Counting Red Alerts
 */

const getRedAlertsCount = (notes: Note[]): number =>
  notes.filter((note) => {
    if (
      note.replyToIds?.length === 0 &&
      note.severityScore >= SeverityLevel.SEVERITY_RED
    ) {
      return note;
    }
  }).length;

/**
 * Counting Yellow Attentions Count
 */

const getYellowAttentionsCount = (notes: Note[]): number =>
  notes.filter((note) => {
    if (
      note.replyToIds?.length === 0 &&
      note.severityScore >= SeverityLevel.SEVERITY_YELLOW &&
      note.severityScore < SeverityLevel.SEVERITY_RED
    ) {
      return note;
    }
  }).length;
