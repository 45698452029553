export const TAB_CONSTANTS = {
  BloodPressure: 0,
  HeartRate: 1,
  PulseOximetry: 2,
  Weight: 3,
  GlucoseLevel: 4,
  Temperature: 5,
  Spirometry: 6,
};

export const BASE_DECIMAL_NUMBER = 10;

export const TAB_MODES = {
  Chart: 0,
  Table: 1,
};

export const TIMEOUT_DATE_CHANGE = 500;
