import { Update } from "history";
import { useHistory } from "react-router-dom";

export interface HasPathname {
  pathname: string;
}

interface Props {
  getCurrentRoute: (routeName: HasPathname) => void;
}

function RouteChangeDetector(props: Props) {
  /**
   * Fetching current route on every route change
   */
  useHistory().listen((location: Update | HasPathname) =>
    props.getCurrentRoute(location as HasPathname)
  );
  return <></>;
}

export default RouteChangeDetector;
