import {
  ColumnDef,
  ColumnDefTemplate,
  ColumnHelper,
  Row,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { AggregateData } from "./types";
import { ExpandIcon } from "../tables/ExpandIcon";
import { ObservationTypeNew } from "@/domain/observations/types";
import styles from "./styles.module.scss";

export const makeLabelColumn = (
  columnHelper: ColumnHelper<AggregateData>,
  observationType: string
) =>
  columnHelper.display({
    id: "label",
    header: () => "",
    minSize: 0,
    cell: (cell) => {
      const { row } = cell;
      const { original } = row;
      const { title } = original;

      if (!title) return "";

      const split = String(title).split(" ");

      return observationType == ObservationTypeNew.Spirometry ? (
        <span>
          <label className={styles.categoryName}>
            {split[0] === "FEV1" ? "FEV 1" : split[0]}
          </label>
        </span>
      ) : null;
    },
  });

export const makeExpanderColumn = (): ColumnDef<AggregateData> => ({
  id: "expander",
  header: () => null,
  cell: (props) => {
    const { row } = props;

    return <ExpandIcon showExpand={row.depth === 0} row={row} />;
  },
});

export const makeDateColumns = (
  columnHelper: ColumnHelper<AggregateData>,
  fromDate: DateTime,
  numDays: number,
  renderCell: (str: string) => ColumnDefTemplate<any>
): ColumnDef<AggregateData>[] => {
  const columns = [];
  for (let i = numDays - 1; i > -1; i--) {
    let date = fromDate.minus({ days: i });
    date = date.hour > 0 ? date.plus({ hours: 1 }) : date;
    // @ts-ignore
    const col = columnHelper.accessor<string, any>(date.toFormat("M/d/yyyy"), {
      cell: renderCell(date.toFormat("yyyy-MM-dd")),
      header: () => (
        <span title={date.toLocaleString()}>{date.toFormat("M/d")}</span>
      ),
    });

    columns.push(col);
  }

  return columns;
};
