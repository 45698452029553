import { PatientId } from "@/domain/patient/redux/types";
import { Assessment } from "@/domain/assessment/model/types";
import * as C from "./constants";

export const setPatientAssessments = (
  patientId: PatientId,
  assessments: Assessment[]
) => ({
  type: C.SET_PATIENT_ASSESSMENTS,
  payload: { patientId, assessments },
});
