import { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { format } from "date-fns";

import { TextInput, Validation, TextArea } from "@/components/form";
import { DatePicker } from "@/components/_inputs/DatePicker";
import { MedicationForm } from "../model/medication";
import { DateTime } from "luxon";

interface Props {
  modalBtn: string;
  handleClose: () => void;
  handleSubmit: (values: MedicationForm, editMode: boolean) => Promise<void>;
  targetedMedication: MedicationForm;
  targetedMedicationId: number;
}

function MedicationFormComponent(props: Props) {
  const { modalBtn, handleClose, handleSubmit } = props;
  const editMode = modalBtn === "Save Changes";

  let dateStartedInitialValue = null;
  let dateEndedInitialValue = null;
  let refillDateInitialValue = null;
  if (editMode && props.targetedMedication) {
    dateStartedInitialValue = props.targetedMedication.dateStarted
      ? (props.targetedMedication.dateStarted as string)
      : null;
    dateEndedInitialValue = props.targetedMedication.dateEnded
      ? (props.targetedMedication.dateEnded as string)
      : null;
    refillDateInitialValue = props.targetedMedication.lastRefill
      ? (props.targetedMedication.lastRefill as string)
      : null;
  }

  const [dateStarted, setDateStarted]: [
    string | null,
    Dispatch<SetStateAction<any>>
  ] = useState(dateStartedInitialValue);
  const [dateEnded, setDateEnded]: [
    string | null,
    Dispatch<SetStateAction<any>>
  ] = useState(dateEndedInitialValue);
  const [refillDate, setRefillDate]: [
    string | null,
    Dispatch<SetStateAction<any>>
  ] = useState(refillDateInitialValue);

  const initialValues: MedicationForm = {
    name: "",
    dosage: "",
    directions: "",
    dateStarted: "",
    dateEnded: "",
    lastRefill: "",
    prescribedBy: "",
    notes: "",
  };

  const getInitialValues = (): MedicationForm => {
    if (editMode && props.targetedMedication) {
      const selectedMedication = props.targetedMedication;
      return {
        name: selectedMedication.name,
        dosage: selectedMedication.dosage,
        directions: selectedMedication.directions,
        dateStarted: selectedMedication.dateStarted || "",
        dateEnded: selectedMedication.dateEnded || "",
        lastRefill: selectedMedication.lastRefill || "",
        prescribedBy: selectedMedication.prescribedBy || "",
        notes: selectedMedication.notes || "",
      };
    }
    return initialValues;
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          name: Validation.required,
          dosage: Validation.required,
          directions: Validation.required,
          dateStarted: Validation.notRequired,
          dateEnded: Validation.notRequired,
          lastRefill: Validation.notRequired,
          prescribedBy: Validation.notRequired,
          notes: Validation.notRequired,
        })}
        onSubmit={async (
          values: MedicationForm,
          { setSubmitting, resetForm }
        ) => {
          await handleSubmit(values, editMode);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="modal-body">
                <div>
                  <div className="form-row d-flex justify-content-start">
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Dose"
                        name="dosage"
                        type="text"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Direction"
                        name="directions"
                        type="text"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Provider"
                        name="prescribedBy"
                        type="text"
                        placeholder=""
                        required={false}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <div>
                        <label>Date Started</label>
                      </div>
                      <DatePicker
                        isClearable={true}
                        name="dateStarted"
                        selected={
                          dateStarted
                            ? DateTime.fromISO(dateStarted).toJSDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          setDateStarted(date);
                          if (date) {
                            formik.setFieldValue(
                              "dateStarted",
                              format(new Date(date) as Date, "yyyy-MM-dd")
                            );
                          } else {
                            formik.setFieldValue("dateStarted", "");
                          }
                        }}
                        maxDate={
                          dateEnded
                            ? DateTime.fromISO(dateEnded).toJSDate()
                            : null
                        }
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <div>
                        <label>Date Ended</label>
                      </div>
                      <DatePicker
                        isClearable={true}
                        name="dateEnded"
                        selected={
                          dateEnded
                            ? DateTime.fromISO(dateEnded).toJSDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          setDateEnded(date);
                          if (date) {
                            formik.setFieldValue(
                              "dateEnded",
                              format(new Date(date) as Date, "yyyy-MM-dd")
                            );
                          } else {
                            formik.setFieldValue("dateEnded", "");
                          }
                        }}
                        minDate={
                          dateStarted
                            ? DateTime.fromISO(dateStarted).toJSDate()
                            : null
                        }
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <div>
                        <label>Last Refill</label>
                      </div>
                      <DatePicker
                        isClearable={true}
                        name="lastRefill"
                        selected={
                          refillDate
                            ? DateTime.fromISO(refillDate).toJSDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          setRefillDate(date);
                          if (date) {
                            formik.setFieldValue(
                              "lastRefill",
                              format(new Date(date) as Date, "yyyy-MM-dd")
                            );
                          } else {
                            formik.setFieldValue("lastRefill", "");
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <TextArea
                        label="Notes"
                        name="notes"
                        placeholder=""
                        required={false}
                        rows={1}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4 d-flex justify-content-start"></div>
                    <div className="col-6 mt-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        {modalBtn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default MedicationFormComponent;
