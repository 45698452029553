import { RCAResourceActions } from "../../core/config/actions";
import { ResourceType } from "../../core/config/resource";
import { Model } from "../../model";
import { Command } from "../command/command";

/**
 * Brain behind every Command execution. It holds information about the action that is triggered by the user and
 * knows how to execute a Command for that user action. It also controls the execution process and marks Commands as
 * Completed. Then determines if the Command was successful or failed. Depending on the outcome, helps Task runners
 * to handle the resulting command events (Success/Failure).
 *
 * It can switch its Context from one command to the other. But it has the ability to Partially clone itself for task runners
 * that uses it and prevents the task runners from executing or calling prohibited functions like execute or setContext
 * to wrongly switch its context.
 */
export class Receiver<T> {
  public context!: Command<T>;

  constructor(
    public readonly model: Model<T>,
    public readonly resourceType: ResourceType,
    public readonly action: RCAResourceActions
  ) {}

  async execute(): Promise<void> {
    await this.model.syncUp();
    this.context.markAsCompleted();
  }

  setContext(command: Command<T>) {
    this.context = command;
    return this;
  }

  clone(): Partial<Receiver<T>> {
    return { ...this };
  }

  static make<T>(
    model: Model<T>,
    resourceType: ResourceType,
    action: RCAResourceActions
  ): Receiver<T> {
    return new Receiver<T>(model, resourceType, action);
  }
}
