import { RootState } from "../../../types/index";

export const selectKits = (rootState: RootState) => rootState.kits.kits;
export const selectKitFilters = (rootState: RootState) =>
  rootState.kits.filters;
export const selectedKitSortedState = (rootState: RootState) =>
  rootState.kits.sortedState;
export const selectKitPagePaginationConfig = (rootState: RootState) =>
  rootState.kits.paginationConfig;
export const selectSelectedKitIds = (rootState: RootState) =>
  rootState.kits.selectedKitIds;
export const selectReturnKitModalActive = (rootState: RootState) =>
  rootState.kits.returnKitModalActive;
