import React, { SyntheticEvent } from "react";
import Dropdown from "rsuite/Dropdown";
import Button from "rsuite/Button";

import { PlacementFix, Trigger } from "@/components/Tooltip/types";

type Size = "lg" | "md" | "sm" | "xs";

export type Props = {
  items: string[];
  title: string;
  trigger: Trigger;
  placement: PlacementFix;
  onSelect: (eventKey: any, event: SyntheticEvent<any>) => void;
  btnSize?: Size | undefined;
  style?: React.CSSProperties;
};

export const DropdownButton: React.FC<Props> = (props) => {
  const { onSelect, btnSize, style, items } = props;

  return (
    <Dropdown
      {...props}
      className="ml-3 font-14 font-arial font-bold"
      onSelect={onSelect}
      renderTitle={(children: any) => {
        return (
          <Button
            size={btnSize}
            style={style ? style : {}}
            appearance="ghost"
            className="rounded"
          >
            {children}{" "}
          </Button>
        );
      }}
    >
      {items.map((item: string, index) => (
        <Dropdown.Item key={`dropdown${index}`} eventKey={item}>
          {item}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
