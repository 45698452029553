import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import TableWithPagination from "@/components/table/datatable/tableWithPagination.component";
import { SortedState, SortedStateType } from "@/components/table/head/sortable";
import { Patient } from "@/domain/patient/model/types";
import {
  TableWithSort,
  sortInAscending,
  sortInDescending,
} from "@/components/table/datatable/datatableConfig";
import { getPatientReimbursableEvents } from "@/domain/patient/redux/middleware";
import { userDashboardTableHead } from "./constants";

interface Props {
  patients: Patient[];
}

export default function UserDashboardTable(props: Props) {
  const [header, setHeader] = useState(userDashboardTableHead);
  const [patientRecords, setPatientRecords] = useState([]);
  const [patients, setpatients] = useState([]);

  useEffect(() => {
    setpatients(props.patients as []);
    getPatientRecordsInJSX(getPatientsData());
    setHeader(
      userDashboardTableHead.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      })
    );
  }, [props.patients]);

  const getPatientRecordsInJSX = (records: TableWithSort[][]) => {
    const dataWithJSX = records.map((patient) => {
      return patient.map((info) => {
        return info.jsx;
      });
    });
    setPatientRecords(dataWithJSX as []);
  };

  const getPatientsData = () => {
    return props.patients.map((patient) => {
      const cpt99458_1 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket21 ?? 0;
      const cpt99458_2 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket41 ?? 0;
      const cpt99458_3 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket61 ?? 0;
      return [
        {
          value: patient.demographics.legalName.lastName,
          jsx: patient.demographics.legalName.lastName,
        },
        {
          value: patient.demographics.legalName.firstName,
          jsx: patient.demographics.legalName.firstName,
        },
        {
          value: patient?.encounter?.id ?? "",
          jsx: (
            <Link to={"/patient/" + patient.id} className="pointer">
              {patient?.encounter?.id ?? ""}
            </Link>
          ),
        },
        {
          value: patient.practice?.name,
          jsx: patient.practice?.name,
        },
        {
          value: patient.provider?.firstName + " " + patient.provider?.lastName,
          jsx:
            patient.provider?.firstName +
            " " +
            patient.provider?.lastName +
            ", " +
            patient.provider?.credentials,
        },
        {
          value: patient.assignedUserName,
          jsx: patient.assignedUserName,
        },
        {
          value: getPatientReimbursableEvents(patient.billing),
          jsx: (
            <span
              className={
                patient.billing!.cpt99454.reimbursableEvents > 0
                  ? "text-success"
                  : "text-danger"
              }
            >
              {getPatientReimbursableEvents(patient.billing)}
            </span>
          ),
        },
        {
          value: patient.billing!.cpt99457.currentMinutes,
          jsx: (
            <>
              <span
                className={
                  patient.billing!.cpt99457.currentMinutes >= 20
                    ? "text-success mr-1"
                    : "text-danger mr-1"
                }
              >
                {patient.billing?.cpt99457.currentMinutes}
              </span>
              {patient.billing?.cpt99457.directPatientContact ? (
                <i className="fa fa-check text-success" aria-hidden="true" />
              ) : (
                <i className="fa fa-times text-danger" aria-hidden="true" />
              )}
            </>
          ),
        },
        {
          value: cpt99458_1,
          jsx: (
            <>
              {cpt99458_1 > 0 && (
                <span
                  className={cpt99458_1 > 39 ? "text-success" : "text-danger"}
                >
                  {cpt99458_1}
                </span>
              )}
            </>
          ),
        },
        {
          value: cpt99458_2,
          jsx: (
            <>
              {cpt99458_2 > 0 && (
                <span
                  className={cpt99458_2 > 59 ? "text-success" : "text-danger"}
                >
                  {cpt99458_2}
                </span>
              )}
            </>
          ),
        },
        {
          value: cpt99458_3,
          jsx: (
            <>
              {cpt99458_3 > 0 && (
                <span
                  className={cpt99458_3 > 79 ? "text-success" : "text-danger"}
                >
                  {cpt99458_3}
                </span>
              )}
            </>
          ),
        },
      ];
    });
  };

  const onSortData = async (index: number, state: SortedStateType) => {
    if (state === SortedState.Ascending) {
      const sortedData = sortInAscending(getPatientsData(), index);
      getPatientRecordsInJSX(sortedData);
    } else if (state === SortedState.Descending) {
      const sortedData = sortInDescending(getPatientsData(), index);
      getPatientRecordsInJSX(sortedData);
    } else {
      getPatientRecordsInJSX(getPatientsData());
    }
    await changeHeaderSortedState(index, state);
  };

  const changeHeaderSortedState = async (
    index: number,
    state: SortedStateType
  ) => {
    await setHeader(
      userDashboardTableHead.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      }) as []
    );
  };

  const mergedHeaders = userDashboardTableHead.map((header, key) => {
    if (header.name === "(1)") {
      return (
        <th
          key={key}
          className="bg-primary text-white th-text text-center"
          colSpan={3}
        >
          CPT99458
        </th>
      );
    }
    return <th></th>;
  });

  return (
    <div id="userDashboardTable">
      <TableWithPagination
        config={{
          recordsPerPage: 50,
          isSortable: true,
          onSort: onSortData,
        }}
        header={header}
        mergedHeaders={mergedHeaders}
        records={patientRecords}
      />
    </div>
  );
}
