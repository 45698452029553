import React from "react";
import { connect } from "react-redux";
import { Button } from "rsuite";

import { Modal } from "@/components/modal";
import { NoteQuery, PaginationConfig } from "@/library/types";
import { Note } from "@/domain/notes/model/types";
import {
  dispatchAddPatientNote,
  setNotePageLimit,
  setNotes,
  setNotesPaginationConfig,
} from "@/domain/notes/redux/actions";
import { PatientEventTableHeader } from "./table/header.table";
import { NoteTable } from "./table/note.table.component";
import { Patient } from "@/domain/patient/model/types";
import { NoteModel } from "@/domain/notes/model";
import { ModelCollection } from "@/library/model";
import { MODEL_SYNCED } from "@/library/constants";
import { NoteAction } from "@/domain/notes/model/types";
import { NoteActions } from "@/domain/notes/model/constants";
import { RCAResourceActions } from "@/library/core/config/actions";
import { NoteThread } from "./thread/thread";
import {
  Intervention,
  ObservationReason,
  PatientNoteType,
} from "@/library/types/note";
import { ObservationModel } from "@/domain/observations/model";
import { AssessmentModel } from "@/domain/assessment/model";
import RangeSelectorComponent from "@/components/_inputs/DateRangePicker";
import { CURRENT_DATE, DAYS_LIMIT } from "@/library/constants";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { removeDuplicates } from "@/util/removeDuplicates";
import { AppointmentNoteForm } from "@/domain/patient/view/forms/AppointmentNoteForm";
import { CallNoteForm } from "@/domain/patient/view/forms/CallNoteForm";
import { ClinicalNoteForm } from "@/domain/patient/view/forms/ClinicalNoteForm";
import { MessageNoteForm } from "@/domain/patient/view/forms/MessageNoteForm";
import ObservationNoteForm from "@/domain/patient/view/forms/ObservationNoteForm";
import AssessmentNoteForm from "@/domain/patient/view/forms/AssessmentNoteForm";
import { RootState } from "@/types";
import { PatientId } from "@/domain/patient/redux/types";
import { selectFirstPatientNotes } from "@/domain/notes/redux/selectors";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";

type ConnectedProps = {
  chartNotes: Note[];
  selectedNotes: Note[];
  patientNotes: Note[];
  pageLimit: number;
  paginationConfig: PaginationConfig;
  selectedPatient: Patient | null;
};
type DispatchProps = {
  setNotePageLimit: (limit: number) => void;
  setNotesPaginationConfig: (paginationConfig: PaginationConfig) => void;
  setNotes: (notes: Note[]) => void;
  addPatientNote: (patientId: PatientId, note: Note) => void;
};
type OwnProps = {};
type Props = ConnectedProps & DispatchProps & OwnProps;

type State = {
  noteModalConfig: {
    show: boolean;
    title: string;
    modalButton: string;
    userAction?: NoteAction | undefined;
    resourceAction: RCAResourceActions | undefined;
    replyToIds: string[];
  };
  noteRepliesConfig: {
    note: Note | undefined;
    show: boolean;
  };
  formik: any;
  startDate: Date;
  endDate: Date;
  noteStatus: string;
  noteType: string[];
};

const DEFAULT_NOTE_TYPES = [PatientNoteType.Clinical];

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      noteModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        userAction: undefined,
        resourceAction: undefined,
        replyToIds: [],
      },
      noteRepliesConfig: {
        note: undefined,
        show: false,
      },

      formik: undefined,
      startDate: this.getStartDate(),
      endDate: new Date(),
      noteStatus: "",
      noteType: [
        PatientNoteType.Clinical,
        PatientNoteType.Observation,
        PatientNoteType.PatientRequest,
        PatientNoteType.Reviewed,
      ],
    };
  }

  onAddNote = async (title: NoteAction) => {
    await this.setState((previousState: State) => ({
      ...previousState,
      noteModalConfig: {
        show: true,
        title: `Create ${title}`,
        modalButton: "Create",
        userAction: title,
        resourceAction: RCAResourceActions.Create,
        replyToIds: this.props.selectedNotes.map((n) => n.id!),
      },
    }));
  };

  onModalClose = () =>
    this.setState((previousState: State) => ({
      ...previousState,
      noteModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
        replyToIds: [],
      },
    }));

  onRepliesModalClose = () => {
    this.setState((previousState: State) => ({
      ...previousState,
      noteRepliesConfig: {
        note: undefined,
        show: false,
      },
    }));
  };

  onOpenReplies = (note: Note) => {
    this.setState((previousState: State) => ({
      ...previousState,
      noteRepliesConfig: {
        note: note,
        show: true,
      },
    }));
  };

  execSearch = async (query: NoteQuery) => {
    const selectedPatientId: string | undefined =
      this.props.selectedPatient?.id;

    if (query && selectedPatientId) {
      LoadingIndicator.fire.show();
      const startDateTime = convertDateToLocalTimezone(
        this.state.startDate
      ).startOf("day");
      const endDateTime = convertDateToLocalTimezone(this.state.endDate).endOf(
        "day"
      );
      const noteCollection: ModelCollection<NoteModel, Note> =
        NoteModel.syncByPatientId(selectedPatientId);
      noteCollection.on(MODEL_SYNCED, () => {
        LoadingIndicator.fire.hide();
      });
      // only parent notes should be fetched
      const paginationConfig: PaginationConfig = await noteCollection
        .withParams({
          ...query,
          includeRepliedTo: true,
          limit: this.props.pageLimit,
          replyToIds: [],
          startDate: startDateTime.toUTC().toString(),
          endDate: endDateTime.toUTC().toString(),
          isComplete: this.state.noteStatus ?? undefined,
          types:
            this.state.noteType?.length > 0
              ? this.state.noteType
              : DEFAULT_NOTE_TYPES,
          isManual: true,
        })
        .fetch();

      this.props.setNotesPaginationConfig(paginationConfig);
      LoadingIndicator.fire.show();
      // map over all notes and get the children notes and add their minutes to the parent minute
      const parentNotes: Note[] = noteCollection.container.map((noteModel) => {
        const note: Note = noteModel.pluckAll();
        const intervention = [...(note.intervention || [])];
        note.original = noteModel.clone();
        note.minutes = note.minutes ?? 0;
        note.intervention =
          (removeDuplicates(intervention) as Intervention[]) || [];
        return note;
      });

      this.props.setNotes(parentNotes);

      noteCollection.trigger(MODEL_SYNCED);
    }
  };

  resolveAssessment = async (note: Note): Promise<void> => {
    const assessementId = note.assessment?.assessment?.id;
    if (!!assessementId) {
      try {
        note.resolvedAssessment = (
          await AssessmentModel.sync(assessementId)
        ).pluckAll();
      } catch (e) {}
    }
  };

  resolveObservations = async (note: Note): Promise<void> => {
    // manualNoteId
    if (note.observation && note.observation.observations.length) {
      // grab the first observation
      const observation = note.observation.observations[0];
      const refId =
        note.observation.reason === ObservationReason.Manual
          ? note._meta?.id!
          : observation.legacyDocumentId!;

      note.resolvedObservations = await ObservationModel.syncByRefId(
        note.patientId!,
        refId,
        note.observation.reason
      );
    }
  };

  componentDidMount() {
    if (this.props.selectedPatient) this.execSearch({});
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot?: any): void {
    const { patientNotes } = this.props;

    if (patientNotes?.length > (prevProps.patientNotes?.length || 0)) {
      setTimeout(() => this.execSearch({}), 500);
    }
  }

  setPageLimit = async (limit: number) => {
    await this.props.setNotePageLimit(limit);
    await this.execSearch({});
  };

  setFormik = (formik: any) => this.setState({ formik });

  updateParentNoteInReduxIfChild = (lastNoteAdded: Partial<Note>) => {
    const { chartNotes } = this.props;
    // If chartNotes is empty or the last note added is not a child note, return
    if (chartNotes.length === 0) {
      return;
    }
    if (!NoteModel.make(lastNoteAdded as Note).isChild) {
      return;
    }
    // Find parent note with matching id, and update fields
    const updatedNotes = chartNotes.map((note: Note) => {
      if (lastNoteAdded.replyToIds?.includes(note.id!)) {
        if (
          lastNoteAdded.minutes !== undefined ||
          lastNoteAdded.seconds !== undefined
        ) {
          if (note.minutes !== undefined) {
            note.minutes! +=
              (lastNoteAdded.minutes ?? 0) + (lastNoteAdded.seconds ?? 0) / 60;
          } else {
            note.minutes =
              (lastNoteAdded.minutes ?? 0) + (lastNoteAdded.seconds ?? 0) / 60;
          }
        }

        if (note.replies !== undefined) {
          note.replies! += 1;
        } else {
          note.replies = 1;
        }

        if (!note.directPatientContact) {
          note.directPatientContact = lastNoteAdded.directPatientContact;
        }

        if (lastNoteAdded.intervention) {
          note.intervention = removeDuplicates(
            lastNoteAdded.intervention.concat(note.intervention ?? [])
          ) as Intervention[];
        }
      }
      return note;
    });
    this.props.setNotes(updatedNotes);
    if (lastNoteAdded?.patientId)
      this.props.addPatientNote(lastNoteAdded.patientId, lastNoteAdded as Note);
  };

  renderNoteForm = (
    noteAction: NoteAction | undefined,
    replyToIds: string[]
  ) => {
    if (!noteAction || !this.props.selectedPatient) {
      return <></>;
    }

    switch (noteAction) {
      case NoteActions.CreateAppointmentNote:
        return (
          <AppointmentNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      case NoteActions.CreateCallNote:
        return (
          <CallNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      case NoteActions.CreateClinicalNote:
        return (
          <ClinicalNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      case NoteActions.CreateMessageNote:
        return (
          <MessageNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      case NoteActions.CreateObservationNote:
        return (
          <ObservationNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      case NoteActions.CreateAssessmentNote:
        return (
          <AssessmentNoteForm
            patient={this.props.selectedPatient}
            handleClose={this.onModalClose}
            replyToIds={replyToIds}
            onSetFormik={this.setFormik}
            updateParentNoteInReduxIfChild={this.updateParentNoteInReduxIfChild}
          />
        );
      default:
        return <></>;
    }
  };

  onAddChildNote = (noteType: NoteAction, replyToIds: string[]): void => {
    this.setState({
      noteModalConfig: {
        show: true,
        title: `Create ${noteType}`,
        modalButton: "Create",
        userAction: noteType,
        resourceAction: RCAResourceActions.Create,
        replyToIds: replyToIds,
      },
    });
  };

  getStartDate(): Date {
    const datetime = convertDateToLocalTimezone(CURRENT_DATE);
    return datetime.minus({ days: DAYS_LIMIT }).toJSDate();
  }

  onDateChange = async (startDate: Date, endDate: Date) => {
    await this.setState(() => ({
      startDate,
      endDate,
    }));
    await this.execSearch({});
  };

  render() {
    const { noteModalConfig, formik } = this.state;

    const headerContents = PatientEventTableHeader.map((content, key) => (
      <th key={key} className="bg-primary text-white small">
        {content}
      </th>
    ));

    return (
      <>
        <div id="events" className="tab-pane fade in active show">
          <div className="row">
            <div className="col"></div>
            <div className="col-4 pl-5">
              <div className="pl-5">
                <RangeSelectorComponent
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onDatesChange={this.onDateChange}
                />
              </div>
            </div>
          </div>
          <hr className="chart-hr" />
          <div className="text-right mr-0 mb-3">
            <Button
              type="submit"
              appearance="primary"
              className="px-3"
              onClick={() => {
                this.onAddNote(NoteActions.CreateClinicalNote);
              }}
            >
              Create Note
            </Button>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <NoteTable
              onOpenReplies={(note: Note) => this.onOpenReplies(note)}
              headers={PatientEventTableHeader}
              data={this.props.chartNotes}
              execSearch={this.execSearch}
              onPageLimitChange={this.setPageLimit}
              pageLimit={this.props.pageLimit}
              paginationConfig={this.props.paginationConfig}
              onAddChildNote={(
                _replyTo: string,
                noteType: NoteAction,
                replyToIds: string[]
              ) => this.onAddChildNote(noteType, replyToIds)}
              noteType={this.state.noteType as PatientNoteType[]}
            />
          </div>
        </div>
        {noteModalConfig.show && (
          <Modal
            heading="Create a Note"
            button="save"
            handleClose={this.onModalClose}
            show={noteModalConfig.show}
            description={noteModalConfig.userAction}
            actionButton={{
              formik,
              name: noteModalConfig.modalButton,
            }}
          >
            {this.renderNoteForm(
              noteModalConfig.userAction,
              noteModalConfig.replyToIds
            )}
          </Modal>
        )}
        {this.state.noteRepliesConfig.show && (
          <Modal
            heading="Note Thread"
            button=""
            handleClose={this.onRepliesModalClose}
            show={this.state.noteRepliesConfig.show}
            description={"Thread of notes attached to parent note"}
          >
            <NoteThread note={this.state.noteRepliesConfig.note!} />
          </Modal>
        )}
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setNotePageLimit: (pageLimit: number): { type: string; payload: number } =>
      dispatch(setNotePageLimit(pageLimit)),
    setNotes: (notes: Note[]): { type: string; payload: Note[] } =>
      dispatch(setNotes(notes)),
    addPatientNote: (patientId: PatientId, note: Note) =>
      dispatch(dispatchAddPatientNote(patientId, note)),
    setNotesPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setNotesPaginationConfig(paginationConfig)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    chartNotes: state.notes.notes,
    paginationConfig: state.notes.paginationConfig,
    pageLimit: state.notes.notePageLimit,
    selectedPatient: selectFirstPatient(state),
    selectedNotes: state.notes.selectedNotes,
    patientNotes: selectFirstPatientNotes(state),
  };
};

export const ChartTab = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
