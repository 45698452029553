import {
  AlertLevel,
  AlertLevelBGColor,
  AlertLevelColor,
} from "@/domain/notes/model/types";
import { AssessmentReason, SeverityScore } from "@/library/types/note";
import { PatientState } from "@/library/types/patientState";
import { arrClone } from "@/util/clone";
import { DateTime, FixedOffsetZone } from "luxon";
import {
  STALE_READING_TOOLTIP_MESSAGE,
  getBannerColorByLastObservation,
  getBannerColorBySeverityScore,
} from "../PatientBanners/constants";
import { Patient } from "../../model/types";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { FormikHelpers } from "formik";
import { LogisticMethod, Order } from "@/domain/order/model/types";
import {
  OrderFulfillment,
  OrderReplacement,
  OrderType,
  ReturnMethods,
} from "@/domain/kits/view/KitShipmentForm/constants";
import { OrderModel } from "@/domain/order/model";
import { Notification } from "@/components/notification/notification";
import { CURRENT_DATE, ERROR, SUCCESS } from "@/library/constants";
import {
  dispatchSetPatients,
  dispatchUpdatePatient,
} from "../../redux/actions";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { PatientModel } from "../../model";
import {
  PatientStateMachine,
  PatientStateTransition,
  PatientTransition,
} from "../form/manageState/types";
import { transitionState } from "../form/manageState/state";
import { dispatchSetReturnKitModalActive } from "@/domain/kits/redux/actions";
import { Writable } from "../form/manageProgram/types";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { Modal } from "rsuite";
import styles from "./styles.module.scss";
import { User } from "@/domain/user/model";
import { dateFormatter } from "@/pipes/date";
import { Dispatch, SetStateAction } from "react";
import { capitalize } from "@mui/material";

const MINUTES_PER_HOUR = 60;
const HOURS_PATHWAY_COMPLETION_DUE = 36;
export const SERVICE_LEVEL = {
  RemoteCareLive: "RCL",
  RemoteCare: "RC",
  DEFAULT: "SL",
};
export interface ErrorType {
  response?: any;
  status?: number;
}

export const getAssessmentBG = (patient: Patient): any => {
  const assessment = patient?.health?.assessment;
  let alertLevelColor: string = AlertLevelColor.NO_ALERT;
  let alertLevelBg: string = AlertLevelBGColor.NO_ALERT;
  if (patient?.lifecycle?.state === PatientState.Inactive) {
    alertLevelColor = AlertLevelColor.DISABLED_ALERT;
    alertLevelBg = AlertLevelBGColor.DISABLED_ALERT;
  } else if (assessment) {
    // evaluate color for when pathway has not been completed after 36 hours of being sent
    const deviceLastCommunicated = arrClone(
      patient?.deviceInfo || []
    ).reverse()[0];
    // get patient's device fixed timezone offset in minutes
    const zone = FixedOffsetZone.instance(
      (deviceLastCommunicated?.timezoneOffset ?? 0) * MINUTES_PER_HOUR
    );
    const timePathwaySent = DateTime.fromISO(assessment?.time, {
      zone,
    });
    const now = DateTime.fromISO(DateTime.now().toISO(), {
      zone,
    });
    // evaluate color for when pathway is completed
    if (
      assessment.reason === AssessmentReason.Completed &&
      assessment.severityScore === SeverityScore.SEVERITY_GREEN
    ) {
      alertLevelColor = AlertLevelColor.GREEN_ALERT;
      alertLevelBg = AlertLevelBGColor.GREEN_ALERT;
    } else if (
      assessment.reason === AssessmentReason.Completed &&
      assessment.severityScore === SeverityScore.SEVERITY_YELLOW
    ) {
      alertLevelColor = AlertLevelColor.YELLOW_ALERT;
      alertLevelBg = AlertLevelBGColor.YELLOW_ALERT;
    } else if (
      assessment.reason === AssessmentReason.Completed &&
      assessment.severityScore === SeverityScore.SEVERITY_RED
    ) {
      alertLevelColor = AlertLevelColor.RED_ALERT;
      alertLevelBg = AlertLevelBGColor.RED_ALERT;
    }
    if (
      now.diff(timePathwaySent, "hours").as("hours") >
      HOURS_PATHWAY_COMPLETION_DUE
    ) {
      alertLevelColor = AlertLevelColor.BLACK_ALERT;
      alertLevelBg = AlertLevelBGColor.BLACK_ALERT;
    }
  }
  return { primary: alertLevelColor, secondary: alertLevelBg };
};

export const getObservationsBG = (value: any) => {
  let bgColor: AlertLevel;
  const alertLevelByObservationTime = getBannerColorByLastObservation(
    value?.time
  );
  const alertLevelByTime = alertLevelByObservationTime;
  if (value === undefined) {
    bgColor = AlertLevel.DISABLED_ALERT;
  } else if (alertLevelByTime) {
    bgColor = alertLevelByTime;
  } else {
    bgColor = getBannerColorBySeverityScore(value?.severityScore);
  }
  return getPrimarySecondaryColors(bgColor);
};

export const getPrimarySecondaryColors = (color: AlertLevel) => {
  let bgColor: { primary: AlertLevelColor; secondary: AlertLevelBGColor };
  switch (color) {
    case AlertLevel.GREEN_ALERT:
      bgColor = {
        primary: AlertLevelColor.GREEN_ALERT,
        secondary: AlertLevelBGColor.GREEN_ALERT,
      };
      break;
    case AlertLevel.RED_ALERT:
      bgColor = {
        primary: AlertLevelColor.RED_ALERT,
        secondary: AlertLevelBGColor.RED_ALERT,
      };
      break;
    case AlertLevel.YELLOW_ALERT:
      bgColor = {
        primary: AlertLevelColor.YELLOW_ALERT,
        secondary: AlertLevelBGColor.YELLOW_ALERT,
      };
      break;
    case AlertLevel.BLACK_ALERT:
      bgColor = {
        primary: AlertLevelColor.BLACK_ALERT,
        secondary: AlertLevelBGColor.BLACK_ALERT,
      };
      break;
    case AlertLevel.DISABLED_ALERT:
      bgColor = {
        primary: AlertLevelColor.DISABLED_ALERT,
        secondary: AlertLevelBGColor.DISABLED_ALERT,
      };
      break;
    default:
      bgColor = {
        primary: AlertLevelColor.NO_ALERT,
        secondary: AlertLevelBGColor.NO_ALERT,
      };
  }
  return bgColor;
};

export const handleReturnKitModalSubmit = async (
  values: KitShipmentFormValues,
  form: FormikHelpers<KitShipmentFormValues>,
  patient: Patient,
  handleReturnKitModalClose: () => {},
  showConfirmModal: () => void,
  dispatch: Dispatch<
    SetStateAction<{ type: string; payload: { patient: Patient } }>
  >
) => {
  form.setSubmitting(true);
  setTimeout(() => form.setSubmitting(false), 500);
  const additionalFulfillmentEmailAddresses = values.contactEmail
    .split(",")
    .slice(1)
    .map((email) => email.trim());
  const returnKitOrderOwned: Order = {
    type: OrderType.Return,
    fulfillment: OrderFulfillment.Customer,
    patientId: patient.id!,
    method:
      values.returnMethod === ReturnMethods.pickup
        ? LogisticMethod.Manual
        : LogisticMethod.Courier,
    origin: {
      name: values.pickupName,
      address: values.pickupAddress,
      needsShippingMaterials:
        values.sendReplacementBoxes === OrderReplacement.Yes,
      requestedDate: DateTime.fromISO(values.pickupDate).toISODate(),
      requestedTimeWindow: values.pickupTime,
    },
    destination:
      values.shipToName && values.shipToAddress
        ? {
            name: values.shipToName,
            address: values.shipToAddress,
          }
        : undefined,
    deviceId: values.device?.id!,
    specialInstructions: values.specialInstructions,
    fulfillmentEmailAddress: values.contactEmail.split(",")[0],
    additionalFulfillmentEmailAddresses,
  };

  try {
    // initiate owned kit (customer order) return
    await OrderModel.createOrder(returnKitOrderOwned);
    Notification.notify(SUCCESS, "Return Order successfully created.");
    const updatedPatient = {
      ...patient,
      deviceInfo: undefined,
    };
    dispatch(dispatchUpdatePatient(updatedPatient));
    handleReturnKitModalClose();
    showConfirmModal();
  } catch (e: unknown) {
    const error = e as ErrorType;
    if (error.status || error.response) {
      Notification.notify(ERROR, RCAResponseErrorParser.parse(error).message());
    } else {
      throw e;
    }
  }
};

export const handleCommentChange = async (
  commentInput: string,
  patient: Patient,
  updatePatientComment: (comment: string | null | undefined) => void
) => {
  const comment = commentInput === "" ? null : commentInput;
  try {
    if (patient.comments !== comment) {
      await PatientModel.make(patient).modifyPatient<Patient, "comments">({
        comments: comment,
      });
      updatePatientComment(comment);
      Notification.notify(SUCCESS, "Comment saved successfully");
    } else if (comment === undefined) {
      updatePatientComment(comment);
    }
  } catch (e) {}
};

export const handleAccept = async (
  setDisableAcceptBtn: (value: boolean) => void,
  selectedStatus: PatientState | undefined,
  patient: Patient,
  setShowConfirmModal: (value: boolean) => void,
  dispatch: Dispatch<
    SetStateAction<
      | { type: string; payload: { returnKitModalActive: boolean } }
      | { type: string; payload: { patient: Patient } }
      | { type: string; payload: { patients: Patient[] } }
    >
  >,
  patients: Patient[]
): Promise<void> => {
  setDisableAcceptBtn(true);
  const patientModel = PatientModel.make(patient);
  const transition: PatientTransition | undefined = selectedStatus
    ? transitionState(
        patient.lifecycle.state,
        selectedStatus,
        PatientStateTransition
      )
    : undefined;

  if (transition) {
    try {
      await patientModel.updateLifecycleState({ transition });
      updateTransitionReduxState(
        transition,
        patient,
        selectedStatus,
        dispatch,
        patients
      );
      setShowConfirmModal(false);
      setDisableAcceptBtn(false);
      if (selectedStatus === PatientState.Completed && patient.deviceInfo) {
        dispatch(dispatchSetReturnKitModalActive(false));
      }
    } catch (e: unknown) {
      const error = e as ErrorType;
      setDisableAcceptBtn(false);
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
        return;
      } else {
        throw e;
      }
    }
  }
};

const updateTransitionReduxState = (
  transition: PatientTransition,
  patient: Patient,
  selectedStatus: PatientState | undefined,
  dispatch: Dispatch<
    SetStateAction<
      | { type: string; payload: { returnKitModalActive: boolean } }
      | { type: string; payload: { patient: Patient } }
      | { type: string; payload: { patients: Patient[] } }
    >
  >,
  patients: Patient[]
): void => {
  const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
  const updatedLifeCycle = {
    ...patient.lifecycle,
    state: PatientStateMachine[transition].resultingState,
    history: [
      ...(patient.lifecycle?.history ?? []),
      {
        time: convertDateToLocalTimezone(CURRENT_DATE).toUTC().toISO(),
        toState: selectedStatus!,
        transition: transition,
      },
    ],
  };
  const updatedPatient = {
    ...patient,
    lifecycle: updatedLifeCycle,
  };
  dispatch(dispatchUpdatePatient(updatedPatient));

  // update targeted patient in the patients banner list
  const updatedPatients = _patients.map((patientObj) => {
    if (patientObj.id === patient.id) {
      patientObj.lifecycle = updatedLifeCycle;
    }
    return patientObj;
  });

  dispatch(dispatchSetPatients(updatedPatients));
};

export const ShowConfirmModal = ({
  open,
  label,
  onCancel,
  onProceed,
}: {
  open: boolean;
  label: string;
  onCancel: () => void;
  onProceed: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      size="xs"
      enforceFocus
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className={styles.modalTitle}>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.menuTitle}>{label}</div>
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttons}>
          <button className={styles.noBtn} onClick={onCancel}>
            No
          </button>
          <button className={styles.yesBtn} onClick={onProceed}>
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const updatePatientList = (
  watchingUserIds: string[],
  dispatch: Dispatch<
    SetStateAction<
      | { type: string; payload: { patient: Patient } }
      | { type: string; payload: { patients: Patient[] } }
    >
  >,
  patients: Patient[],
  patient: Patient
) => {
  const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
  const updatedPatients = _patients.map((selectedPatient) => {
    if (selectedPatient.id === patient.id) {
      selectedPatient.watchingUserIds = watchingUserIds;
    }
    return selectedPatient;
  });
  dispatch(dispatchSetPatients(updatedPatients));
};

export const currentUserInPatientWatchList = (
  currentUser: User | null,
  patient: Patient
) => {
  return !!patient.watchingUserIds?.find(
    (id: string) => currentUser?._meta && currentUser._meta.id === id
  );
};

export const onWatchlistClick = async (
  dispatch: Dispatch<
    SetStateAction<
      | { type: string; payload: { patient: Patient } }
      | { type: string; payload: { patients: Patient[] } }
    >
  >,
  currentUser: User | null,
  patient: Patient,
  patients: Patient[]
) => {
  const currentUserId = currentUser?._meta?.id;
  if (currentUserId) {
    if (currentUserInPatientWatchList(currentUser, patient)) {
      await PatientModel.assignWatchers(patient.id!, {
        delete: [currentUserId],
      });
      const watchingUserIds = patient.watchingUserIds?.filter(
        (id: string) => id !== currentUserId
      );
      dispatch(
        dispatchUpdatePatient({
          ...patient,
          watchingUserIds,
        })
      );
      if (watchingUserIds)
        updatePatientList(watchingUserIds, dispatch, patients, patient);
      Notification.notify(SUCCESS, "Watchlist successfully un-assigned.");
    } else {
      await PatientModel.assignWatchers(patient.id!, {
        set: [currentUserId],
      });
      const watchingUserIds = patient.watchingUserIds
        ? [...patient.watchingUserIds, ...[currentUserId]]
        : [currentUserId];
      dispatch(
        dispatchUpdatePatient({
          ...patient,
          watchingUserIds,
        })
      );
      if (watchingUserIds) {
        updatePatientList(watchingUserIds, dispatch, patients, patient);
      }
      Notification.notify(SUCCESS, "Watchlist successfully assigned.");
    }
  }
};

export const getToolTip = (time: string | undefined) => {
  return getBannerColorByLastObservation(time)
    ? STALE_READING_TOOLTIP_MESSAGE(time)
    : dateFormatter({
        date: time as unknown as string,
        includeTime: true,
        toLocalTimezone: { enabled: true },
      });
};

export const capitalizeLabel = (label: string) => {
  return label ? capitalize(label?.toLowerCase()) : "";
};

export const RiskLevels = {
  High: "High",
  Medium: "Medium",
  Low: "Low",
  Unassign: "Unassign",
};
