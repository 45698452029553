import { AlertLevelLabels, AlertLevels } from "@/domain/notes/model/constants";
import { AlertLevel } from "@/domain/notes/model/types";
import { AlertLegendRow } from "./types";

export const DATA: AlertLegendRow[] = [
  {
    title: AlertLevelLabels[AlertLevel.GREEN_ALERT],
    description: "Within acceptable range",
    level: AlertLevels.Green,
  },
  {
    title: AlertLevelLabels[AlertLevel.YELLOW_ALERT],
    description: "Between Medium and High thresholds",
    level: AlertLevels.Yellow,
  },
  {
    title: AlertLevelLabels[AlertLevel.RED_ALERT],
    description: "Outside High threshold",
    level: AlertLevels.Red,
  },
];
