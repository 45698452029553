import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import {
  AlertLevel,
  AlertTableRowNew,
  BloodPreesureType,
  Props,
  RefProps,
} from "./types";
import cx from "clsx";
import styles from "./styles.module.scss";
import { CheckBoxNotSelected, CheckBox } from "@/assets/icons/dashboard";
import { generateAlertData } from "./helpers";
import { ArrayValidLength } from "./constants";
import { AlertType } from "../CreateNote";

export type observationsDataType = {
  typeDate?: string;
  typeDateDesc?: string;
  typeValue1?: string;
  typeValue2?: string;
  typeValue1P?: string;
  typeValue2P?: string;
  typeOperator?: string;
  typeValue?: string;
  typePriority?: string;
  date: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
  comment?: string;
  typeValue3?: string;
  typeValue3P?: string;
};

export interface AcceptedAlerts {
  date: string;
  comment: string;
  noteId: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
}
interface ObservationData {
  date: string;
  dateText: string;
  typeTitle: string;
  types: observationsDataType[][] | null;
  interventions: string[] | null | undefined;
  narrative: string;
  disposition?: AcceptedAlerts[] | null | undefined;
  comment?: string;
  typeDate?: string;
  typeDateDesc?: string;
  typeValue1?: string;
  typeValue2?: string;
  typeValue1P?: string;
  typeValue2P?: string;
  typeOperator?: string;
  typeValue?: string;
  typePriority?: string;
  typeValue3?: string;
  typeValue3P?: string;
  typeValue3Operator?: string;
}

export const ChartPriority = {
  High: "High",
  Medium: "Medium",
  Normal: "Normal",
  None: "None",
};
export const DATE_FORMAT = "MM/dd/yy | hh:mm:ss";

const getPriorityView = (priority: string = ChartPriority.Normal) => {
  return (
    <div
      className={
        priority === ChartPriority.High
          ? styles.highText
          : priority === ChartPriority.Medium
          ? styles.mediumText
          : styles.normalText
      }
    >
      {priority}
    </div>
  );
};

const getPriorityValue = (
  priority: string = ChartPriority.None,
  value: string | number = 0
) => {
  return (
    <div
      className={
        priority === ChartPriority.High
          ? styles.highValue
          : priority === ChartPriority.Medium
          ? styles.mediumValue
          : priority === ChartPriority.Normal
          ? styles.normalValue
          : styles.lowValue
      }
    >
      {value}
    </div>
  );
};

export const AlertsTable: React.FC<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<RefProps>) => {
    const [alertData, setAlertData] = useState(props.data);
    const [allSelected, setAllSelected] = useState(false);
    const [alertArray, setalertArray] = useState<ObservationData[]>([]);
    const [selectedArray, modifySelectedArray] = useState<AlertTableRowNew[]>(
      []
    );
    const [key, setKey] = useState(ArrayValidLength);

    useEffect(() => {
      setAlertData(props?.data);
      const alertArray = generateAlertData(props?.data, props.rulesbyType);
      setalertArray(alertArray);
      setKey(key + ArrayValidLength);
    }, [props?.data]);

    const onElementSelect = (noteId?: string) => {
      let array = selectedArray;
      if (checkElementPresentInArray(array, noteId)) {
        array = array.filter((item) => item.noteId !== noteId);
      } else {
        let element = alertData.find((item) => item.noteId === noteId);
        if (element) array.push(element);
      }
      modifySelectedArray(array);
      setKey(key + ArrayValidLength);
    };

    const selectAll = () => {
      let array: AlertTableRowNew[] = [];
      if (!allSelected) {
        array = alertData;
      }
      modifySelectedArray(array);
      setKey(key + ArrayValidLength);
      setAllSelected(!allSelected);
    };

    const checkElementPresentInArray = (
      array: AlertTableRowNew[],
      noteIds?: string
    ) => {
      return array.some((data: AlertTableRowNew) => data.noteId === noteIds);
    };

    return (
      <div className={styles.alertDataMainDiv}>
        <div
          className={cx(styles.selectAll)}
          key={key}
          onClick={() => selectAll()}
        >
          <img
            src={
              selectedArray.length === alertData.length
                ? CheckBox
                : CheckBoxNotSelected
            }
            alt="checkBox"
            className={cx(styles.checkBoxAlert)}
          />
          <label className={cx(styles.selectAllText)}>{`Select All`}</label>
        </div>
        <div className={cx(styles.alertDataDiv)}>
          {alertArray?.map((alert: AlertType) => {
            if (alert?.disposition && alert.disposition.length > 0) {
              return alert.disposition.map(
                (typeObject: ObservationData, index: number) => (
                  <div className={styles.mainTypeDiv}>
                    <div
                      className={styles.typeParentDivCheckBox}
                      onClick={() => {
                        onElementSelect(alert?.noteId);
                      }}
                    >
                      <img
                        src={
                          checkElementPresentInArray(
                            selectedArray,
                            alert.noteId
                          )
                            ? CheckBox
                            : CheckBoxNotSelected
                        }
                        alt="checkBox"
                        className={cx(styles.checkBoxAlertData)}
                      />
                    </div>
                    <div className={styles.typeParentDiv}>
                      <div className={styles.typeDiv} key={index}>
                        <div className="d-flex align-items-center">
                          <div
                            className={cx(styles.typeDateText, styles.margintp)}
                          >
                            {typeObject.typeDate}
                          </div>
                          <div className={styles.typeDatelbl}>
                            {typeObject.typeDateDesc}
                          </div>
                        </div>
                        <div className={cx(styles.typeDataDiv)}>
                          {typeObject?.typeValue1 &&
                            getPriorityValue(
                              typeObject?.typeValue1P,
                              typeObject?.typeValue1
                            )}
                          {typeObject?.typeValue3 && (
                            <span className={styles.operatorTxt}>
                              {typeObject.typeValue3Operator}
                            </span>
                          )}
                          {typeObject?.typeValue3 &&
                            getPriorityValue(
                              typeObject?.typeValue3P,
                              typeObject?.typeValue3
                            )}
                          {typeObject?.typeValue2 && (
                            <span className={styles.operatorTxt}>
                              {typeObject.typeOperator}
                            </span>
                          )}
                          {typeObject?.typeValue2 &&
                            getPriorityValue(
                              typeObject?.typeValue2P,
                              typeObject?.typeValue2
                            )}
                          <div className={styles.typeDateText}>
                            {typeObject.typeValue}
                          </div>
                          {getPriorityView(typeObject?.typePriority)}
                        </div>
                      </div>
                      <div className={styles.typeDiv} key={index}>
                        <div className="d-flex align-items-center">
                          <div
                            className={cx(styles.typeDateText, styles.margintp)}
                          >
                            {typeObject.typeDate}
                          </div>
                          <div className={styles.typeDatelbl}>
                            {alert.narrative}
                          </div>
                        </div>
                        <div className={cx(styles.typeDataDiv)}>
                          {getPriorityView(typeObject?.typePriority)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            } else if (alert?.types) {
              return alert.types.map((typeObjectArray: ObservationData[]) => {
                {
                  return (
                    <div className={styles.mainTypeDiv}>
                      <div
                        className={styles.typeParentDivCheckBox}
                        onClick={() => {
                          onElementSelect(alert?.noteId);
                        }}
                      >
                        <img
                          src={
                            checkElementPresentInArray(
                              selectedArray,
                              alert.noteId
                            )
                              ? CheckBox
                              : CheckBoxNotSelected
                          }
                          alt="checkBox"
                          className={cx(styles.checkBoxAlertData)}
                        />
                      </div>
                      <div className={styles.typeParentDiv}>
                        {typeObjectArray.map(
                          (type: ObservationData, index: number) => (
                            <div className={styles.typeDiv} key={index}>
                              <div className="d-flex align-items-center">
                                <div
                                  className={cx(
                                    styles.typeDateText,
                                    styles.margintp
                                  )}
                                >
                                  {type.typeDate}
                                </div>
                                <div className={styles.typeDatelbl}>
                                  {type.typeDateDesc}
                                </div>
                              </div>
                              <div className={cx(styles.typeDataDiv)}>
                                {type?.typeValue1 &&
                                  getPriorityValue(
                                    type?.typeValue1P,
                                    type?.typeValue1
                                  )}
                                {type?.typeValue3 && (
                                  <span className={styles.operatorTxt}>
                                    {type.typeValue3Operator}
                                  </span>
                                )}
                                {type?.typeValue3 &&
                                  getPriorityValue(
                                    type?.typeValue3P,
                                    type?.typeValue3
                                  )}
                                {type?.typeValue2 && (
                                  <span className={styles.operatorTxt}>
                                    {type.typeOperator}
                                  </span>
                                )}
                                {type?.typeValue2 &&
                                  getPriorityValue(
                                    type?.typeValue2P,
                                    type?.typeValue2
                                  )}
                                <div className={styles.typeDateText}>
                                  {type.typeValue}
                                </div>
                                {getPriorityView(type?.typePriority)}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                }
              });
            }
          })}
        </div>
        <div
          className={cx(
            styles.actionAlert,
            selectedArray.length < ArrayValidLength && styles.notAllowedClick
          )}
        >
          <div
            className={cx(
              styles.rejectButton,
              selectedArray.length >= ArrayValidLength && styles.cursorPointer
            )}
            onClick={() =>
              selectedArray.length >= ArrayValidLength
                ? props.rejectedAction(selectedArray)
                : () => {}
            }
          >{`Reject`}</div>
          <div
            className={cx(
              styles.acceptButton,
              selectedArray.length >= ArrayValidLength && styles.cursorPointer
            )}
            onClick={() =>
              selectedArray.length >= ArrayValidLength
                ? props.acceptedAction(selectedArray)
                : () => {}
            }
          >{`Accept`}</div>
        </div>
      </div>
    );
  }
);
