import { FunctionComponent } from "react";
import cx from "clsx";

import styles from "./styles.module.scss";
import { FilterItemDetail } from "@/domain/patient/model/types";
import { ReactComponent as FilterItemIcon } from "@/assets/icons/anelto/filteritem.svg";

type FilterItemProps = {
  item: FilterItemDetail;
  remove: (removedItem: FilterItemDetail) => void;
};

export const FilterItem: FunctionComponent<FilterItemProps> = (props) => {
  const { item, remove } = props;

  return (
    <div className={cx(styles["filter-item-parent"])}>
      <div className={cx(styles["filter-item"])}>
        {item.type} - {item.description}
        <button
          type="button"
          onClick={() => {
            remove(item);
          }}
        >
          <FilterItemIcon />
        </button>
      </div>
    </div>
  );
};
