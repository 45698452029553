import * as Yup from "yup";

import { Validation } from "@/components/form";

export const makeValidation = (isEditMode: boolean) =>
  Yup.object({
    id: Validation.id,
    name: Validation.name,
    type: Validation.practiceType,
    organization: isEditMode ? Validation.notRequired : Validation.required,
    address: Validation.addressOne,
    city: Validation.city,
    state: Validation.state,
    zip: Validation.zip,
    imageUrl: Validation.imageUrl,
    country: Validation.country,
    contactName: Validation.name,
    contactEmail: Validation.contactEmail,
    contactPosition: Validation.contactPosition,
    contactPhone: Validation.phone,
  });
