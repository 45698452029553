import { Routable, Router } from "./";
import { User, UserModel } from "../../domain/user/model";

/**
 * Dynamically infers landing uri based on user type.
 */
export class UserRouter extends Router {
  static routeMe = (user: User): Routable => {
    if (!user) {
      return { to: "" } as Routable;
    }

    return new UserRouter(UserModel.make(user)).route;
  };
}
