import { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { MultiselectConfig } from "@/components/form/select/multiselect.model";
import { Str } from "@/util/Str";
import { Organization } from "@/domain/organization/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { User, UserModel } from "@/domain/user/model";
import { FilterItemDetail, PatientFilters } from "@/domain/patient/model/types";
import {
  dispatchSetFilterItems,
  dispatchSetPatientFilters,
} from "@/domain/patient/redux/actions";
import {
  MY_PATIENTS,
  MY_WATCHLIST,
} from "@/domain/patient/view/PatientsPage/constants";

interface Props {
  onChange: (selectedPractices: string[], descriptions: string[]) => void;

  selectedOrganization: Organization;
  selectedPractices: Practice[];
  filters: PatientFilters;
  filterItems: FilterItemDetail[];

  currentUser: User;

  setPatientFilters: (filters: {}) => { type: string; payload: PatientFilters };
  setFilterItems: (filterItems: FilterItemDetail[]) => void;
}

enum UserFilterRole {
  Filter = "Filter",
  Users = "Users",
}

enum DefaultOptions {
  Unassigned = "unassigned",
  MyPatients = "My Patients",
  MyWatchlist = "My Watchlist",
}

interface State {
  users: { label: string; value: string; role: string }[];
  multiSelectConfig: MultiselectConfig;
  assignedUserIds: string[] | undefined;
}

class UsersFilter extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
      assignedUserIds: this.props.filters.assignedUserIds,
    };
  }

  withLoader = async (fetch: () => Promise<void>) => {
    await this.setState({
      multiSelectConfig: {
        showLoadingIndicator: true,
      },
    });
    await fetch();
    this.setState({
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    });
  };

  getUsers = async () => {
    const selectedOrg = JSON.parse(
      localStorage.getItem("selectedOrganization") as string
    );
    const allOrganizationUserModel: UserModel[] =
      await UserModel.fetchByOrganizationIds([selectedOrg._meta?.id!]);
    let allPracticeUserModel: UserModel[] = [];

    if (this.props.selectedPractices.length) {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        this.props.selectedPractices.map(
          (practice: Practice) => practice._meta?.id
        )
      );
    } else {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        (
          await PracticeModel.fetchByOrganizationIds([selectedOrg._meta?.id!])
        ).map((practiceModel) => practiceModel.pluck("_meta").id)
      );
    }

    const filterOptions = [
      {
        value: MY_PATIENTS,
        label: DefaultOptions.MyPatients,
        role: UserFilterRole.Filter,
      },
      {
        value: MY_WATCHLIST,
        label: DefaultOptions.MyWatchlist,
        role: UserFilterRole.Filter,
      },
      {
        value: DefaultOptions.Unassigned,
        label: Str.CapitalizeFirstLetter(DefaultOptions.Unassigned),
        role: UserFilterRole.Filter,
      },
    ];

    await this.setState({
      users: [
        ...filterOptions,
        // Removing the duplicate values from assignedUsers array and mapping it in multi select options array.
        ...[
          ...(new Set(
            allPracticeUserModel.concat(allOrganizationUserModel)
          ) as any),
        ]
          .filter(
            (userModel: UserModel) =>
              userModel.pluck("id") !== this.props.currentUser.id
          )
          .map((model: UserModel) => {
            return {
              value: model.pluck("id") as string,
              label: `${model.pluck("firstName")} ${model.pluck("lastName")}`,
              role: UserFilterRole.Users,
            };
          }),
      ],
    });
  };

  componentDidMount() {
    this.setState({
      users: [
        {
          value: this.props.currentUser.id || "",
          label: DefaultOptions.MyPatients,
          role: UserFilterRole.Filter,
        },
      ],
    });

    if (this.props.selectedOrganization)
      localStorage.setItem(
        "selectedOrganization",
        JSON.stringify(this.props.selectedOrganization)
      );
    this.getUsers();
  }

  render(): ReactNode {
    return (
      <>
        <BasicMultiSelect
          data={this.state.users}
          onChange={async (selectedPractices: string[]) => {
            this.setState((prevState) => ({
              ...prevState,
              assignedUserIds: selectedPractices,
            }));
            const descriptions = selectedPractices.map(
              (selectedPractice) =>
                this.state.users.find((user) => user.value === selectedPractice)
                  ?.label ?? ""
            );
            this.props.onChange(selectedPractices, descriptions);
          }}
          value={this.props.filters.assignedUserIds}
          isSearchable={true}
          placeholder="All Patients"
          onOpen={async () => {
            if (this.props.selectedOrganization) {
              this.withLoader(this.getUsers);
            }
          }}
          style={{
            bottom: " 5px",
            maxWidth: "285px !important",
            overflow: "hidden",
          }}
          groupBy="role"
          picker="check"
          config={this.state.multiSelectConfig}
          onSelect={async (value) => {}}
        />
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setPatientFilters: (filters: any) =>
      dispatch(dispatchSetPatientFilters(filters)),
    setFilterItems: (filterItems: FilterItemDetail[]) =>
      dispatch(dispatchSetFilterItems(filterItems)),
  };
};

const mapStateToProps = (state: {
  organization: { selectedOrganization: Organization };
  practice: { selectedPractices: Practice[] };
  user: { currentUser: User };
  patient: { filters: PatientFilters; filterItems: FilterItemDetail[] };
}) => {
  return {
    selectedOrganization: state.organization.selectedOrganization,
    selectedPractices: state.practice.selectedPractices,
    filters: state.patient.filters,
    currentUser: state.user.currentUser,
    filterItems: state.patient.filterItems,
  };
};
export default connect(mapStateToProps, mapDispatcherToProps)(UsersFilter);
