import {
  CREATE_USER,
  DESELECT_USER,
  SELECT_USER,
  SET_USERS,
  SET_USERS_PAGINATIONCONFIG,
  SET_USER_PAGE_LIMIT,
  SET_USERS_SEARCH_FILTER,
  SET_TARGET_USER,
  SET_CURRENT_USER,
  RESET_TARGET_USER,
  SET_USERS_SORTED_STATE,
} from "../../../library/constants";
import { PageLimit, PaginationConfig } from "../../../library/types";
import { UserState } from "../../../library/types/userState";

export const INITIAL_STATE = {
  users: [],
  selectedUser: null,
  currentUser: null,
  targetUserId: null,
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  userPageLimit: 50,
  filters: {
    isEnabled: undefined,
    userType: undefined,
    parentEntityId: undefined,
  },
  sortedState: {
    direction: undefined,
    sortBy: undefined,
  },
};

export const userReducer = (
  state: UserState = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };

    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case DESELECT_USER:
      return {
        ...state,
        selectedUser: undefined,
      };

    case SET_USER_PAGE_LIMIT:
      return {
        ...state,
        userPageLimit: action.payload as PageLimit,
      };

    case SET_USERS_PAGINATIONCONFIG:
      return {
        ...state,
        paginationConfig: action.payload as unknown as PaginationConfig,
      };
    case SET_USERS_SEARCH_FILTER:
      return {
        ...state,
        filters: action.payload,
      };

    case SET_TARGET_USER:
      return {
        ...state,
        targetUserId: action.payload,
      };
    case RESET_TARGET_USER:
      return {
        ...state,
        targetUserId: null,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_USERS_SORTED_STATE:
      return {
        ...state,
        sortedState: action.payload,
      };

    default:
      return state;
  }
};
