import { PatientTab } from "@/domain/patient/view/PatientDetails/types";

/**
 * MODEL CONSTANTS.
 */
export const MODEL_UPDATED = "MODEL_UPDATED";
export const MODELS_UPDATED = "MODELS_UPDATED";
export const MODEL_SAVED = "MODEL_SAVED";
export const MODEL_SYNCED = "MODEL_SYNCED";
export const SAVE_ERROR = "SAVE_ERROR";

/**
 * REDUX ACTION CONSTANTS.
 */
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const SET_ALL_ORGANIZATIONS = "SET_ALL_ORGANIZATIONS";
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION";
export const DESELECT_ORGANIZATION = "DESELECT_ORGANIZATION";
export const SET_ORGANIZATIONS_PAGINATIONCONFIG =
  "SET_ORGANIZATIONS_PAGINATIONCONFIG";
export const SET_ORGANIZATION_PAGE_LIMIT = "SET_ORGANIZATION_PAGE_LIMIT";
export const SET_TARGET_ORGANIZATION = "SET_TARGET_ORGANIZATION";
export const SET_ORGANIZATIONS_SEARCH_FILTER =
  "SET_ORGANIZATIONS_SEARCH_FILTER";
export const SET_ORGANIZATIONS_SORTED_STATE = "SET_ORGANIZATIONS_SORTED_STATE";

export const SET_PRACTICES = "SET_PRACTICES";
export const SET_TARGET_PRACTICE = "SET_TARGET_PRACTICE";
export const CREATE_PRACTICE = "CREATE_PRACTICE";
export const SELECT_PRACTICE = "SELECT_PRACTICE";
export const DESELECT_PRACTICE = "DESELECT_PRACTICE";
export const SET_ACTIVE_PRACTICE = "SET_ACTIVE_PRACTICE";
export const UNSET_ACTIVE_PRACTICE = "UNSET_ACTIVE_PRACTICE";
export const SELECT_ALL_PRACTICES = "SELECT_ALL_PRACTICES";
export const DESELECT_ALL_PRACTICES = "DESELECT_ALL_PRACTICES";
export const SET_PRACTICES_PAGINATIONCONFIG = "SET_PRACTICES_PAGINATIONCONFIG";
export const SET_PRACTICE_PAGE_LIMIT = "SET_PRACTICE_PAGE_LIMIT";
export const SET_PRACTICE_FILTERS = "SET_PRACTICE_FILTERS";
export const SET_PRACTICE_SORTED_STATE = "SET_PRACTICE_SORTED_STATE";
export const UPDATE_PRACTICE = "UPDATE_PRACTICE";

export const SET_PROVIDERS = "SET_PROVIDERS";
export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const SET_TARGET_PROVIDER = "SET_TARGET_PROVIDER";
export const SET_ACTIVE_PROVIDER = "SET_ACTIVE_PROVIDER";
export const UNSET_ACTIVE_PROVIDER = "UNSET_ACTIVE_PROVIDER";
export const SELECT_PROVIDER = "SELECT_PROVIDER";
export const DESELECT_PROVIDER = "DESELECT_PROVIDER";
export const SELECT_ALL_PROVIDERS = "SELECT_ALL_PROVIDERS";
export const DESELECT_ALL_PROVIDERS = "DESELECT_ALL_PROVIDERS";
export const SET_PROVIDERS_PAGINATIONCONFIG = "SET_PROVIDERS_PAGINATIONCONFIG";
export const SET_PROVIDERS_PAGE_LIMIT = "SET_PROVIDERS_PAGE_LIMIT";
export const SET_PROVIDER_FILTERS = "SET_PROVIDER_FILTERS";
export const SET_PROVIDER_SORTED_STATE = "SET_PROVIDER_SORTED_STATE";

export const SET_PATIENTS = "SET_PATIENTS";
export const SET_ASSIGNMENT_PATIENTS = "SET_ASSIGNMENT_PATIENTS";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const SELECT_PATIENT = "SELECT_PATIENT";
export const SELECT_ASSIGNMENT_PATIENT = "SELECT_ASSIGNMENT_PATIENT";
export const SELECT_ALL_PATIENTS = "SELECT_ALL_PATIENTS";
export const SELECT_ALL_ASSIGNMENT_PATIENTS = "SELECT_ALL_ASSIGNMENT_PATIENTS";
export const DESELECT_PATIENT = "DESELECT_PATIENT";
export const DESELECT_ASSIGNMENT_PATIENT = "DESELECT_ASSIGNMENT_PATIENT";
export const DESELECT_ALL_PATIENTS = "DESELECT_ALL_PATIENTS";
export const DESELECT_ALL_ASSIGNMENT_PATIENTS =
  "DESELECT_ALL_ASSIGNMENT_PATIENTS";
export const SET_PATIENTS_PAGINATIONCONFIG = "SET_PATIENTS_PAGINATIONCONFIG";
export const SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG =
  "SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG";
export const SET_PATIENTS_PAGE_LIMIT = "SET_PATIENTS_PAGE_LIMIT";
export const SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT =
  "SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT";
export const SET_TARGET_PATIENT = "SET_TARGET_PATIENT";
export const SET_PATIENT_FILTERS = "SET_PATIENT_FILTERS";
export const SET_ASSIGNMENT_PATIENT_FILTERS = "SET_ASSIGNMENT_PATIENT_FILTERS";

export const SET_USERS = "SET_USERS";
export const CREATE_USER = "CREATE_USER";
export const SELECT_USER = "SELECT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SELECT_ALL_USERS = "SELECT_ALL_USERS";
export const DESELECT_USER = "DESELECT_USER";
export const DESELECT_ALL_USERS = "DESELECT_ALL_USERS";
export const SET_USERS_PAGINATIONCONFIG = "SET_USERS_PAGINATIONCONFIG";
export const SET_USER_PAGE_LIMIT = "SET_USER_PAGE_LIMIT";
export const SET_TARGET_USER = "SET_TARGET_USER";
export const SET_USERS_SEARCH_FILTER = "SET_USERS_SEARCH_FILTER";
export const RESET_TARGET_USER = "RESET_TARGET_USER";
export const SET_USERS_SORTED_STATE = "SET_USERS_SORTED_STATE";

export const LOGOUT = "LOGOUT";

/**
 * NOTIFICATION CONSTANTS.
 */
export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const SOMETHING_WENT_WRONG = "Something went wrong.";

/**
 * VALIDATION CONSTANTS.
 */

export const MAX_HEIGHT = 90;
export const MAX_WEIGHT = 800;

/**
 * MISC
 */
export const DAYS_LIMIT = 14;
export const CURRENT_DATE = new Date();
export const MIN_FETCH_TIME_MS = 400;
export const PATIENT_BILLING_REPORT_TIMEZONE = "America/Los_Angeles";

export const PATIENT_TABS = [
  {
    label: "Patient Profile",
    value: 1,
    tab: PatientTab.Profile,
  },
  {
    label: "Monitoring",
    value: 2,
    tab: PatientTab.Monitoring,
  },
  {
    label: "Chart",
    value: 3,
    tab: PatientTab.Chart,
  },
  {
    label: "Parameters",
    value: 4,
    tab: PatientTab.Parameters,
  },
  {
    label: "Pathways",
    value: 5,
    tab: PatientTab.Pathways,
  },
  {
    label: "Billing",
    value: 6,
    tab: PatientTab.Billing,
  },
  {
    label: "Summary Report",
    value: 7,
    tab: PatientTab.Summary,
  },
  {
    label: "Audit Log",
    value: 8,
    tab: PatientTab.Audit,
  },
  {
    label: "Equipments",
    value: 9,
    tab: PatientTab.Equipment,
  },
];

export const PATIENT_TABS_NEW = [
  {
    label: "Patient Profile",
    value: 1,
    tab: PatientTab.Profile,
  },
  {
    label: "Monitoring",
    value: 2,
    tab: PatientTab.Monitoring,
  },
  {
    label: "Chart Notes",
    value: 3,
    tab: PatientTab.Chart,
  },
  {
    label: "Parameters",
    value: 4,
    tab: PatientTab.Parameters,
  },
  {
    label: "Pathways",
    value: 5,
    tab: PatientTab.Pathways,
  },
  {
    label: "Billing Report",
    value: 6,
    tab: PatientTab.Billing,
  },
  {
    label: "Summary Report",
    value: 7,
    tab: PatientTab.Summary,
  },
  {
    label: "Audit Log",
    value: 8,
    tab: PatientTab.Audit,
  },
  {
    label: "Equipments",
    value: 9,
    tab: PatientTab.Equipment,
  },
];

export const FILTER_STRING = "Filter";
