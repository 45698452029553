import { Attributes, Model, ModelCollection } from "@/library/model";
import { Order } from "@/domain/order/model/types";
import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Http } from "@/library/core/api/http";

export class OrderModel extends Model<Order> {
  static readonly path = "orders";

  /**
   * Makes a new instance of OrderModel. It requires only the attributes
   * that would be used to instantiate new Order Model.
   *
   * @param attributes
   */
  static make(attributes: Order): OrderModel {
    return new OrderModel(
      new Attributes<Order>(attributes),
      new RemoteCareAPISync<Order>(OrderModel.path),
      new EventEmitter()
    );
  }

  /**
   * create order
   * @param data
   */
  static async createOrder(data: Order) {
    return await Http().post(`${RemoteCareAPISync.host}/orders`, data);
  }
}
