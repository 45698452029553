import {
  Provider,
  ProviderForm,
  ProviderModel,
} from "../../../domain/provider/model";
import { Serializeable } from "../serializer";

/**
 * Serializer for creating new Provider.
 */
export class CreateProviderSerializer implements Serializeable<ProviderModel> {
  constructor(protected readonly formData: ProviderForm) {}

  serialize(): ProviderModel {
    return ProviderModel.make({
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      credentials: this.formData.credentials,
      npi: this.formData.npi,
      ptan: null,
      patients: undefined,
      practiceId: this.formData.practiceId,
      specialty: this.formData.specialty ? this.formData.specialty : null,
      phoneNumber: this.formData.phoneNumber ? this.formData.phoneNumber : null,
    } as Provider);
  }
}
