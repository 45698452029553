import React, { useEffect, useRef, useState } from "react";
import { List, Panel } from "rsuite";
import { useSelector } from "react-redux";
import cx from "clsx";

import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { ICONS } from "@/domain/rules/view/ObservationParameters/constants";
import { DeviceModel } from "@/domain/kits/models/device.model";
import { PatientDevice } from "@/domain/kits/models/device.interface";
import { AccessoryType } from "@/domain/kits/models/device.interface";
import { Patient } from "@/domain/patient/model/types";
import { TextInput } from "@/components/_inputs/TextInput";
import { CopyButton } from "@/components/_buttons/CopyButton";
import { phoneMask } from "@/components/form/validation/validation";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { dateFormatter } from "@/pipes/date/index";
import { mapAccessoryTypeToDisplay } from "./constants";
import LockIcon from "@mui/icons-material/Lock";

import styles from "./styles.module.scss";

type Props = {};

export const EquipmentTab: React.FC<Props> = () => {
  const patient: Patient | null = useSelector(selectFirstPatient);
  const [devices, setDevices] = useState<PatientDevice[]>([]);
  const msnRef = useRef<HTMLInputElement>(null);

  const [msnValue, setMsnValue] = useState<React.ReactNode>(null);
  const deviceInfo = patient?.deviceInfo?.length ? patient.deviceInfo[0] : null;

  const getServicelevel = () => {
    if (patient && patient.deviceInfo) {
      return patient.deviceInfo[0]?.serviceLevel;
    }
  };

  const fetchPatientDevices = async () => {
    if (!patient) return;

    LoadingIndicator.fire.show();
    const deviceModel: DeviceModel[] = await DeviceModel.fetchByPatientId(
      patient!.id!
    );
    const devices = deviceModel.map((dm) => dm.pluckAll());
    setDevices(devices);
    LoadingIndicator.fire.hide();
  };

  useEffect(() => {
    fetchPatientDevices();
  }, [patient?.id]);

  useEffect(() => {
    if (deviceInfo?.msn) {
      const first = String(deviceInfo.msn).slice(0, -5);
      const last = String(deviceInfo.msn).slice(-5);

      setMsnValue(
        <span>
          {first}
          <b>{last}</b>
        </span>
      );
    } else setMsnValue(null);
  }, [deviceInfo]);

  const renderHeading = (heading: string) => (
    <h3 className="text-center mb-1">{heading}</h3>
  );

  const renderTile = (args: { size: number; children: React.ReactNode }) => {
    const { size, children } = args;

    return (
      <div className={`col-md-${size} p-2`}>
        <Panel shaded className={cx("px-3 py-2", styles.panel)}>
          {children}
        </Panel>
      </div>
    );
  };

  const renderInputWithText = (text: string, val: string | number = "") => (
    <div className="row mt-4">
      <div className="col-5">
        <b>{text}</b>
      </div>
      <div className="col-7">
        <TextInput disabled type="text" className="form-control" value={val} />
      </div>
    </div>
  );

  const renderLabel = (label: string) => (
    <label className="d-block w-100 font-weight-bold">{label}</label>
  );

  const renderIcon = (svg: React.ReactNode) => (
    <img src={svg as string} className={cx(styles.iconImage, "img-fluid")} />
  );

  const hasAccesssories = (() => {
    return devices && devices.length && devices[0] && devices[0].accessories;
  })();

  return (
    <div className="container" id="equipment">
      <div className="tab-pane fade in active show mt-4">
        {renderHeading("Console Information")}
        <div className="mt-1">
          <div className="row">
            <div className="col-6">
              <div className="row">
                {renderTile({
                  size: 12,
                  children: (
                    <>
                      <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                          {renderLabel("MSN")}
                          <div
                            className={cx("input-group", styles.combinedInput)}
                          >
                            <TextInput
                              disabled
                              type="text"
                              className="form-control"
                              value={devices[0]?.msn}
                              containerClassName={styles.inputLeft}
                            />
                            <div className={styles.append}>
                              <CopyButton
                                content={deviceInfo?.msn || ""}
                                className={styles.copyButton}
                                tooltip="Copy MSN"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3" />
                      </div>
                      <div className="row mt-4">
                        <div className="col-6">
                          {renderLabel("ICCID")}
                          <div
                            className={cx("input-group", styles.combinedInput)}
                          >
                            <TextInput
                              disabled
                              type="text"
                              className="form-control"
                              value={devices[0]?.iccid}
                              containerClassName={styles.inputLeft}
                            />
                            <div className={styles.append}>
                              <CopyButton
                                content={devices[0]?.iccid || ""}
                                className={styles.copyButton}
                                tooltip="Copy ICCID"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          {renderLabel("Phone Number")}
                          <div
                            className={cx("input-group", styles.combinedInput)}
                          >
                            <TextInput
                              disabled
                              type="text"
                              className="form-control"
                              value={phoneMask(devices[0]?.msisdn || "")}
                              containerClassName={styles.inputLeft}
                            />
                            <div className={styles.append}>
                              <CopyButton
                                content={phoneMask(devices[0]?.msisdn || "")}
                                className={styles.copyButton}
                                tooltip="Copy Phone Number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6">
                          {renderLabel("Part Number (SKU)")}
                          <TextInput
                            disabled
                            type="text"
                            className="form-control"
                            value={devices[0]?.sku}
                          />
                        </div>
                        <div className="col-6">
                          {renderLabel("Service Level")}
                          <TextInput
                            disabled
                            type="text"
                            className="form-control"
                            value={getServicelevel()}
                          />
                        </div>
                      </div>
                    </>
                  ),
                })}

                {renderTile({
                  size: 12,
                  children: (
                    <>
                      <div className="row">
                        <div className="col-12">
                          {renderInputWithText(
                            "Microphone Volume",
                            devices[0]?.volume.microphone
                          )}
                        </div>
                        <div className="col-12">
                          {renderInputWithText(
                            "Speaker Volume",
                            devices[0]?.volume.speaker
                          )}
                        </div>
                        <div className="col-12">
                          {renderInputWithText(
                            "Current Power Source",
                            devices[0]?.powerSource || ""
                          )}
                        </div>
                        <div className="col-12">
                          {renderInputWithText(
                            "Battery",
                            devices[0]?.battery.level
                          )}
                        </div>
                        <div className="col-12">
                          {renderInputWithText(
                            "Last Network Communication",
                            dateFormatter({
                              date: devices[0]?.lastCommunication,
                              includeTime: true,
                              toLocalTimezone: { enabled: true },
                            })
                          )}
                        </div>
                      </div>
                    </>
                  ),
                })}
              </div>
            </div>
            <div className="col-6">
              {renderTile({
                size: 12,
                children: (
                  <>
                    {renderHeading("Connected Peripherals")}
                    <div className="row mt-4">
                      <List hover className="w-100">
                        {hasAccesssories ? (
                          devices[0].accessories.map((peripheral, index) => (
                            <List.Item key={index}>
                              <div className="row">
                                <div className="col">
                                  {peripheral.observationType?.map((obs) => (
                                    <>{renderIcon(ICONS[obs])}</>
                                  ))}
                                </div>
                                <div className="col">
                                  {
                                    mapAccessoryTypeToDisplay[
                                      peripheral.accessoryType as AccessoryType
                                    ]
                                  }
                                </div>
                                <div className="col">{peripheral.id}</div>
                              </div>
                            </List.Item>
                          ))
                        ) : (
                          <></>
                        )}
                      </List>
                    </div>
                  </>
                ),
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
