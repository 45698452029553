import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { TagModel } from "@/domain/tags/model";
import { LocationSelect } from "@/components/_selects/LocationSelect";
import React from "react";
import { useState } from "react";
import { Button } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { selectKits, selectSelectedKitIds } from "../../redux/selectors";
import { Notification, notify } from "@/components/notification/notification";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import {
  dispatchAssignLocation,
  dispatchUnassignLocation,
} from "@/domain/kits/redux/actions";

import styles from "./styles.module.scss";
import { ERROR, SOMETHING_WENT_WRONG } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";

interface Props {}

export const AssignKitLocation: React.FC<Props> = () => {
  const selectedKitIds = useSelector(selectSelectedKitIds);
  const locationTags = useSelector(selectLocationTags);
  const kits = useSelector(selectKits);
  const dispatch = useDispatch();

  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >();

  const handleLocationSelect = (location: any) => {
    setSelectedLocationId(location ? (location as string) : "");
  };

  const unassignOldLocationFromKits = async () => {
    const targetedKits = kits.filter((kit) =>
      selectedKitIds.includes(kit._meta.id)
    );
    const existingTagIds: Record<string, string[]> = {};
    for (let i = 0; i < targetedKits.length; i++) {
      const indexOfLocationTag = targetedKits[i].tags.findIndex(
        (tag) => tag.type === "location"
      );
      if (indexOfLocationTag !== -1) {
        const tagId = targetedKits[i].tags[indexOfLocationTag]._meta.id;
        existingTagIds[tagId] = [
          ...(existingTagIds[tagId] || []),
          targetedKits[i]._meta.id,
        ];
      }
    }

    for (let key in existingTagIds) {
      await TagModel.assignDevices(key, {
        delete: existingTagIds[key],
      });
    }
  };

  const assignTagToDevices = async () => {
    LoadingIndicator.fire.show();
    try {
      await unassignOldLocationFromKits();
      await TagModel.assignDevices(selectedLocationId!, {
        set: selectedKitIds,
      });
      const selectedLocationTag = locationTags.find(
        (tag) => tag._meta.id === selectedLocationId
      )!;
      dispatch(dispatchAssignLocation(selectedKitIds, selectedLocationTag));
      LoadingIndicator.fire.hide();
      notify("success", "Location saved.");
    } catch (e: any) {
      if (e.status || e.response) {
        Notification.notify(ERROR, RCAResponseErrorParser.parse(e).message());
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
      LoadingIndicator.fire.hide();
    }
  };

  const unassignTagToDevices = async () => {
    LoadingIndicator.fire.show();
    try {
      const targetedKits = kits.filter((kit) =>
        selectedKitIds.includes(kit._meta.id)
      );
      for (let i = 0; i < targetedKits.length; i++) {
        const kit = targetedKits[i];
        const tags = kit.tags.filter((tag) => tag.type === "location");

        if (kit?.id && tags.length) {
          await TagModel.assignDevices(tags[0]._meta.id, {
            delete: [kit.id],
          });
        }
      }
      LoadingIndicator.fire.hide();
      dispatch(dispatchUnassignLocation(selectedKitIds));
      notify("success", "Location unassigned.");
    } catch (e: any) {
      if (e.status || e.response) {
        Notification.notify(ERROR, RCAResponseErrorParser.parse(e).message());
      } else {
        Notification.notify(ERROR, SOMETHING_WENT_WRONG);
      }
      LoadingIndicator.fire.hide();
    }
  };

  return (
    <div className={styles.assignLocationContainer}>
      <LocationSelect
        data={[]}
        isSearchable={false}
        name="select-location"
        selectedId={selectedLocationId}
        placeholder="Select Location"
        onChange={handleLocationSelect}
        className={styles.locationSelect}
        disabled={!selectedKitIds.length}
      />
      <Button
        appearance="ghost"
        onClick={assignTagToDevices}
        className="mx-3"
        disabled={!selectedKitIds.length || !selectedLocationId}
      >
        Assign Location
      </Button>
      <Button
        appearance="ghost"
        color="red"
        onClick={unassignTagToDevices}
        disabled={!selectedKitIds.length}
      >
        Unassign Locations
      </Button>
    </div>
  );
};
