import { useEffect, useState } from "react";

interface Props {
  dob: string | undefined;
}

export function AgePipe(props: Props) {
  const [age, setAge] = useState(0);

  useEffect(() => {
    if (props.dob) {
      setAge(calculateAge(props.dob));
    }
  }, [props.dob]);

  const calculateAge = (dob: string): number => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return <>{props.dob ? age : ""}</>;
}
