import { HeaderContent, SortedState } from "@/components/table/head/sortable";

export const HEADERS: HeaderContent[] = [
  {
    name: "name",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "address",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "city",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "state",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "practiceId",
    isSortable: false,
    sortedState: SortedState.NoSort,
    label: "Entity / Branch",
  },
  {
    name: "contactName",
    isSortable: false,
    sortedState: SortedState.NoSort,
    label: "Contact",
  },
  {
    name: "contactEmail",
    isSortable: false,
    sortedState: SortedState.NoSort,
    label: "Contact Email",
  },
  {
    name: "contactPhone",
    isSortable: false,
    sortedState: SortedState.NoSort,
    label: "Contact Phone",
  },
  {
    name: "actions",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
];
