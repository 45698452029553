export const SET_KITS = "SET_KITS";
export const SET_KIT_FILTER = "SET_KIT_FILTER";
export const SET_KIT_SORTED_STATE = "SET_KIT_SORTED_STATE";
export const SET_KITS_PAGINATION_CONFIG = "SET_KITS_PAGINATION_CONFIG";
export const SET_RETURN_KIT_MODAL_ACTIVE = "SET_RETURN_KIT_MODAL_ACTIVE";
export const UNASSIGN_DEVICE = "UNASSIGN_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const SELECT_KIT = "SELECT_KIT";
export const DESELECT_KIT = "DESELECT_KIT";
export const ASSIGN_LOCATION = "ASSIGN_LOCATION";
export const UNASSIGN_LOCATION = "UNASSIGN_LOCATION";
export const SELECT_ALL_KITS = "SELECT_ALL_KITS";
export const DESELECT_ALL_KITS = "DESELECT_ALL_KITS";
