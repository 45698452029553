import { FunctionComponent } from "react";
import cx from "clsx";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

type Size = "lg" | "md" | "sm" | "xs";

interface ModalProps {
  children?: React.ReactNode;
  handleClose: () => void;
  show: boolean;
  heading: string;
  button?: string;
  actionButton?: {
    formik: any;
    name: string;
  };
  optionalButton?: React.ReactNode;
  description?: string;
  rightHeader?: any;
  size?: Size;
  className?: string;
  style?: React.CSSProperties;
}

export const Modal: FunctionComponent<ModalProps> = ({
  actionButton,
  children,
  handleClose,
  show,
  heading,
  optionalButton,
  description,
  rightHeader,
  size,
  className,
  style,
}) => {
  return (
    <div
      className={cx(styles.modal, show ? "d-block" : "d-none", className)}
      style={style}
    >
      <section
        className={cx(styles.modalMain, size && styles[`modal-${size}`])}
      >
        <div className="modal-header">
          <h5 className={globalStyles.pageHeading} id="gridModalLabel">
            {heading}
            {description && (
              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "14px",
                  fontWeight: "lighter",
                }}
              >
                {" "}
                - {description}
              </span>
            )}
          </h5>
          {rightHeader && (
            <div className="text-right manage-grow" style={{ flexGrow: 1 }}>
              {rightHeader}
            </div>
          )}
          <button
            onClick={handleClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        {show ? <div className={styles.content}>{children}</div> : null}
        {actionButton && (
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                handleClose();
                actionButton.formik?.resetForm();
              }}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={actionButton.formik?.isSubmitting}
              className="btn btn-primary"
              onClick={() => {
                actionButton.formik?.submitForm();
              }}
            >
              {actionButton.name}
            </button>
            {optionalButton}
          </div>
        )}
      </section>
    </div>
  );
};
