import React, { Component, ReactNode } from "react";

import { PaginationConfig } from "@/library/types";

type TableComponentProps = {
  data: any[];
  onNavigate: (query: {}) => any;
  onPageLimitChange?: (limit: number) => void;
  pageLimit: number;
  paginationConfig: PaginationConfig;

  resetSelections?: () => void;
  isNonScrollable?: boolean;
};

type TableComponentState = {
  totalResources: number;
  currentPage: number;
  lastPage: number;
  localPageLimit?: number;
};

class Paginate extends Component<TableComponentProps, TableComponentState> {
  constructor(props: TableComponentProps) {
    super(props);

    this.state = {
      totalResources: 1,
      currentPage: 1,
      lastPage: 1,
      localPageLimit: 0,
    };
  }

  async componentDidMount() {
    const { pageLimit } = this.props;
    const { localPageLimit } = this.state;

    if (localPageLimit !== pageLimit) {
      await this.setState({
        localPageLimit: pageLimit,
        currentPage: 1,
      });
    }
  }

  resetSelections = () => {
    if (this.props.resetSelections) {
      this.props.resetSelections();
    }
  };

  hasNext = () =>
    this.props.paginationConfig.lastPage !==
    this.props.paginationConfig.currentPage;
  hasPrevious = () => this.props.paginationConfig.currentPage !== 1;

  next = async () => {
    this.resetSelections();
    await this.props.onNavigate({
      currentPage: this.props.paginationConfig.currentPage + 1,
    });
  };

  previous = async () => {
    this.resetSelections();
    await this.props.onNavigate({
      currentPage: this.props.paginationConfig.currentPage - 1,
    });
  };

  jumpTo = async (page: number) => {
    this.resetSelections();
    await this.props.onNavigate({
      currentPage: page,
    });
  };

  generatePageNumbers = () => {
    const { pageLimit, paginationConfig } = this.props;
    const { localPageLimit } = this.state;

    const isCurrentPage = (count: number) => {
      return count === paginationConfig.currentPage;
    };

    let totalPages = 0;
    if (localPageLimit) {
      totalPages = Math.ceil(paginationConfig.totalResources / localPageLimit);
    }
    const listItems = [];
    for (let i = 0; i < totalPages; i++) {
      const pageCount = i + 1;
      listItems.push(
        <li
          key={i}
          className={`page-item ${
            isCurrentPage(pageCount) && "active disabled"
          }`}
        >
          <a
            className="page-link"
            href="#"
            onClick={() => this.jumpTo(pageCount)}
          >
            {pageCount}
          </a>
        </li>
      );
    }
    return listItems;
  };

  onPageLimitChange = async (pageLimit: number) => {
    const { localPageLimit } = this.state;
    this.props.onPageLimitChange &&
      (await this.props.onPageLimitChange(pageLimit));
    if (localPageLimit !== pageLimit) {
      await this.setState({
        localPageLimit: pageLimit,
        currentPage: 1,
      });
    }
  };

  render(): ReactNode {
    const { data, pageLimit, isNonScrollable } = this.props;
    return (
      <>
        {this.props.data.length > 0 && (
          <div className="row" style={{ padding: "5px 0px" }}>
            <div className="col-md-6">
              <nav aria-label="...">
                <ul className="pagination">
                  <li
                    className={`page-item ${!this.hasPrevious() && "disabled"}`}
                  >
                    <a className="page-link" href="#" onClick={this.previous}>
                      Previous
                    </a>
                  </li>
                  {this.generatePageNumbers()}
                  <li className={`page-item ${!this.hasNext() && "disabled"}`}>
                    <a className="page-link" href="#" onClick={this.next}>
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 d-flex justify-content-end">
              <div className="p-2">Display</div>
              <div className="form-inline" style={{ height: "30px" }}>
                <select
                  className=""
                  value={pageLimit}
                  onChange={async (e) =>
                    this.onPageLimitChange(parseInt(e.target.value))
                  }
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div className="p-2">Records</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Paginate;
