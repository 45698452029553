import { format } from "date-fns";
import { DateTime } from "luxon";

import { arrClone } from "@/util/clone";
import { DATE_FORMAT_ } from "@/pipes/date/constants";
import { snake2Camel } from "@/pipes/text";
import {
  PatientCondition,
  PatientConditionType,
  PatientLifecycle,
} from "@/domain/patient/model/types";
import { PatientDevice } from "@/domain/kits/models/device.interface";
import styles from "./styles.module.scss";
import { PatientState } from "@/library/types/patientState";

export const getPrimaryConditionName = (
  conditions?: PatientCondition[]
): string => {
  if (!conditions?.length) return "-";

  const primaryCondition = conditions.find(
    (condition) => condition.type === PatientConditionType.PRIMARY
  );

  return primaryCondition?.displayName || "-";
};

export const getRecentStatus = (lifecycle?: PatientLifecycle) => {
  if (lifecycle?.state === PatientState.Preactive) {
    return "Pre-Active";
  }

  return snake2Camel(lifecycle?.state || "-");
};

export const getRecentStatusDate = (lifecycle?: PatientLifecycle) => {
  const lifeCycleHistory = arrClone(lifecycle?.history || []).reverse();

  if (lifeCycleHistory.length) {
    const statusDate = lifeCycleHistory[0].time;
    return `${format(new Date(statusDate), DATE_FORMAT_)}`;
  }

  return "-";
};

export const getEncounterDays = (lifecycle?: PatientLifecycle) => {
  const now = DateTime.fromISO(DateTime.now().toISO());
  const lifeCycleHistory = arrClone(lifecycle?.history || []).reverse();

  if (lifeCycleHistory.length) {
    const timeStatusChanged = DateTime.fromISO(lifeCycleHistory[0].time);
    if (
      lifeCycleHistory[0].toState === PatientState.Active &&
      lifeCycleHistory[1].toState === PatientState.Inactive
    ) {
      for (let i = 2; i < lifeCycleHistory.length; i++) {
        if (lifeCycleHistory[i].toState === PatientState.Active) {
          const timeStatusChanged = DateTime.fromISO(lifeCycleHistory[i].time);
          return ` Day ${now
            .diff(timeStatusChanged, "days")
            .plus({ day: 1 })
            .as("days")
            .toFixed(0)}`;
        }
      }
    }
    return ` Day ${now
      .diff(timeStatusChanged, "days")
      .plus({ day: 1 })
      .as("days")
      .toFixed(0)}`;
  }
  return `-`;
};

export const getPatientServiceLevel = (devices?: PatientDevice[]) => {
  if (devices && devices.length > 0) {
    const [patientDevice] = devices;

    return (
      <>
        <span>{patientDevice.serviceLevel}: </span>
        <span className={styles.bold}>
          {(patientDevice.msn || "-").slice(-5)}
        </span>
      </>
    );
  }

  return (
    <>
      <span>Service Level: </span>
      <span className={styles.bold}>{"-"}</span>
    </>
  );
};
