import { Serializer } from "@/library/serializers/serializer";
import { RCAResourceActions } from "@/library/core/config/actions";
import { PatientModel } from "@/domain/patient/model";
import { PatientForm } from "@/domain/patient/model/types";
import { CreatePatientSerializer } from "./create.serializer";
import { ModifyBasicInfoSerializer } from "./modify.serializer";

/**
 * Patient domain serializer. It holds information about all serializers in this domain.
 */
export class PatientSerializer extends Serializer<PatientForm, PatientModel> {
  static for(action: RCAResourceActions, from: PatientForm): PatientSerializer {
    return new PatientSerializer(action, from);
  }

  serialize(): PatientModel {
    const serializer = new (this.get(this.action))(this.formData);
    return serializer.serialize();
  }

  /**
   * Map of currently supported resource actions to their respective serializers.
   * @protected
   */
  protected serializers = {
    [RCAResourceActions.Create]: CreatePatientSerializer,
    [RCAResourceActions.ModifyBasicInfo]: ModifyBasicInfoSerializer,
  };
}
