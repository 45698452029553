import { phone } from "@/assets/icons/observations";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";

export type OwnProps = {
  patient: Patient;
  patientHealth: HealthSnapshot;
};
export type Props = OwnProps & Partial<IconContainerProps>;

export const CallRequest: React.FC<Props> = (props) => {
  const { patient, patientHealth, ...rest } = props;

  return (
    <IconContainer
      {...rest}
      id={`obs-call-request-${patient?.id}`}
      size="xs"
      src={phone}
      color={"white"}
      tooltipContent="Feature coming soon."
    >
      <>-</>
    </IconContainer>
  );
};
