import React, {
  ForwardedRef,
  forwardRef,
  Ref,
  useState,
  useEffect,
  useImperativeHandle,
  SyntheticEvent,
} from "react";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Radio, RadioGroup, Toggle } from "rsuite";

import { TextArea, Validation } from "@/components/form";
import { Patient } from "@/domain/patient/model/types";
import {
  Intervention,
  Interventions,
  PatientNoteType,
} from "@/library/types/note";
import { Note, NoteDisposition } from "@/domain/notes/model/types";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { NoteModel } from "@/domain/notes/model";
import { ResourceType } from "@/library/core/config/resource";
import { RCAResourceActions } from "@/library/core/config/actions";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { MultiSelect } from "@/components/form/select/multiSelect";
import { NoteDispositions } from "@/domain/notes/model/constants";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import { AlertsOverview } from "@/domain/patient/view/forms/AlertsOverview";
import { snake2Camel } from "@/pipes/text";
import NoteTimer from "../NoteTimer";
import { useDispatch, useSelector } from "react-redux";
import { selectPatientTimer } from "@/domain/patient/redux/selectors";
import { RootState } from "@/types";
import { TimerState } from "@/components/countdown/types";
import { ValueType } from "rsuite/esm/Radio";

type RefProps = {
  submit: () => void;
};

export interface Props {
  patient: Patient;
  handleClose: () => void;
  replyToIds?: string[];
  replyToRows?: AlertTableRow[];
  disposition?: NoteDisposition;
  onSetFormik?: (form: any) => void;
  onDispositionChange?: (val: NoteDisposition) => void;
  updateParentNoteInReduxIfChild?: (note: Partial<Note>) => void;
  ref?: Ref<RefProps>;
  includeTimer?: boolean;
}

export const ClinicalNoteForm: React.FC<Props> = forwardRef(
  (props, ref: ForwardedRef<RefProps>) => {
    const activeTimers = useSelector(selectPatientTimer);
    const currentUser = useSelector(
      (state: RootState) => state.user.currentUser
    );
    const dispatch = useDispatch();

    const patientTimer = activeTimers[currentUser?.id! + props.patient.id];

    const [timerStopped, setTimerStopped] = useState(false);

    const {
      replyToIds = [],
      replyToRows = [],
      disposition,
      onDispositionChange,
    } = props;

    const initialValues = {
      patientId: props.patient.id as string,
      comment: "",
      directPatientContact: false,
      seconds: 0,
      intervention: [],
      replyToIds,
      disposition,
      type: PatientNoteType.Clinical,
    };

    const form = useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
        comment: Validation.notRequired.nullable(),
        disposition: Validation.notRequired.oneOf([
          NoteDispositions.Accepted,
          NoteDispositions.Rejected,
        ]),
        directPatientContact: Validation.notRequired,
        intervention: Validation.notRequiredArray,
        replyToIds: Validation.notRequiredArray,
      }),
      onSubmit: async (values: Partial<Note>, { setSubmitting, resetForm }) => {
        values.comment = values.comment === "" ? null : values.comment;
        setTimerStopped(true);
        await Invoker.make<Note>(
          Receiver.make(
            NoteModel.make(values as Note, true),
            ResourceType.note,
            RCAResourceActions.Create
          ),
          CleanTask.make(
            () => resetForm(initialValues as any),
            () => props.handleClose(),
            () =>
              props.updateParentNoteInReduxIfChild &&
              props.updateParentNoteInReduxIfChild(values as Note)
          ),
          FinallyTask.make(() => setSubmitting(false))
        ).invoke();
      },
    });

    useEffect(() => {
      if (props.onSetFormik) props.onSetFormik(form);
    }, [form.values]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (form) form.handleSubmit();
      },
    }));

    const handleRadioChange = (
      value: ValueType,
      _event: SyntheticEvent<Element, Event>
    ) => {
      if (onDispositionChange) onDispositionChange(value as NoteDisposition);
      else form.setFieldValue("disposition", value);
    };

    const calculateSeconds = (currentState: TimerState) => {
      const seconds = currentState.seconds + currentState.minutes * 60;
      form.setFieldValue("seconds", seconds);
    };

    return (
      <FormikProvider value={form}>
        <form className="container" onSubmit={form.handleSubmit}>
          <div className="row">
            {!replyToIds.length && props.includeTimer && (
              <div className="col-md-1">
                <div className="mt-2">
                  <NoteTimer
                    onChange={calculateSeconds}
                    isStopped={timerStopped}
                  />
                </div>
              </div>
            )}
            <div className="col-md-2">
              <div className="form-group">
                <div className="d-flex">
                  <label>Direct Patient Contact</label>
                </div>
                <div className="d-flex">
                  <Toggle
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={(value) => {
                      form.setFieldValue("directPatientContact", value);
                    }}
                    checked={form.values.directPatientContact}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Intervention(s)</label>
                <MultiSelect
                  data={Object.keys(Interventions)
                    .filter(
                      (intervention) =>
                        intervention !== Intervention.LeftPhoneMessage
                    )
                    .map((key) => {
                      return {
                        value: key,
                        label: Interventions[key as Intervention],
                      };
                    })}
                  className="alert-note-intervention"
                  onChange={(intervention: any) => {
                    if (
                      !form.values.directPatientContact &&
                      (intervention.includes("VIDEO_VISIT_COMPLETED") ||
                        intervention.includes("DIRECT_PATIENT_CONTACT"))
                    ) {
                      form.setFieldValue("directPatientContact", true);
                    }
                    form.setFieldValue(
                      "intervention",
                      intervention.filter((a: any) => !!a)
                    );
                  }}
                  value={form.values.intervention}
                  placeholder="Select Intervention"
                  picker="check"
                  name={"intervention"}
                />
              </div>
              {replyToIds?.length ? (
                <div className="form-group">
                  <label htmlFor="disposition">Disposition</label>
                  <RadioGroup
                    name="disposition"
                    value={disposition}
                    onChange={handleRadioChange}
                  >
                    <Radio value={NoteDispositions.Accepted}>Accepted</Radio>
                    <Radio value={NoteDispositions.Rejected}>Rejected</Radio>
                  </RadioGroup>
                </div>
              ) : null}
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <TextArea
                  label="Narrative"
                  name="comment"
                  required={false}
                  rows="5"
                />
              </div>
            </div>
          </div>
          {replyToIds.length && replyToRows.length ? (
            <div className="row">
              <div className="col-md-12">
                <AlertsOverview disposition={disposition} data={replyToRows} />
              </div>
            </div>
          ) : null}
        </form>
      </FormikProvider>
    );
  }
);
