import { Formik, Form } from "formik";
import * as Yup from "yup";
import React from "react";
import {
  extractphone,
  Validation,
} from "../../../components/form/validation/validation";
import { TextInput, TextInputMask } from "../../../components/form";
import { User, UserModel } from "../../user/model";
import { Profile, ProfileForm } from "../model/model";
import { Notification } from "../../../components/notification/notification";
import { ERROR, SUCCESS } from "../../../library/constants";
import { connect } from "react-redux";
import { setCurrentUser } from "../../user/redux";
import { RCAResponseErrorParser as ErrorParser } from "../../../library/error/parser/rca.error.parser";

type EditProfileProps = {
  handleClose: () => void;
  button: string;
  currentUser: User;
  setCurrentUser: (user: User) => { type: string; payload: User };
};

class Component extends React.Component<EditProfileProps, {}> {
  constructor(props: EditProfileProps) {
    super(props);
  }

  handleError = (error: any) =>
    Notification.notify(
      ERROR,
      ErrorParser.parse(error).message(),
      "There was a problem with updating user profile"
    );

  handleSave = (
    userModel: UserModel,
    resetForm: (initialValues: {}) => void
  ) => {
    this.props.setCurrentUser({
      ...this.props.currentUser,
      ...userModel.attributes.getAll(),
    });
    Notification.notify(
      SUCCESS,
      `Profile updated successfully. name: ${userModel.attributes.get(
        "firstName"
      )}`
    );
    this.props.handleClose();
  };

  handleSubmit = (
    values: Profile,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (initialValues: {}) => void
  ) => {
    const user = UserModel.make(values as User);
    user.updateMe(() => this.handleSave(user, resetForm), this.handleError);
    setSubmitting(false);
  };

  parseValues = (values: ProfileForm) => {
    return {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: extractphone(values.phone),
    };
  };

  render() {
    const user = this.props.currentUser;
    return (
      <Formik
        initialValues={{
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
        }}
        validationSchema={Yup.object({
          id: Validation.id,
          firstName: Validation.name,
          lastName: Validation.name,
          phone: Validation.phone,
        })}
        enableReinitialize
        validateOnMount
        validateOnBlur
        onSubmit={(values: ProfileForm, { setSubmitting, resetForm }) =>
          this.handleSubmit(this.parseValues(values), setSubmitting, resetForm)
        }
      >
        {(formik) => {
          return (
            <div className="modal-body">
              <Form className="d-flex justify-content-center">
                <div className="form-row">
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      type="text"
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <TextInputMask
                      type="tel"
                      label="Phone Number"
                      name="phone"
                      mask="+1 (999) 999-9999"
                      value={formik.values.phone}
                      onChange={(e: any) => {
                        formik.setFieldValue("phone", e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-5">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="btn btn-primary"
                    >
                      {this.props.button}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: { user: { currentUser: User } }) => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setCurrentUser: (user: User): { type: string; payload: User } =>
      dispatch(setCurrentUser(user)),
  };
};

export const EditProfileFormComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
