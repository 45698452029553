import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { Modal } from "@/components/modal";
import { PracticeForm } from "@/domain/practice/view/PracticeForm";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import {
  dispatchSelectAllPractices,
  dispatchDeselectAllPractices,
  dispatchSetPracticePageLimit,
  dispatchSetPracticesPaginationConfig,
  dispatchSetPracticeFilters,
  dispatchSetPracticeSortedState,
  dispatchSetActivePractice,
} from "@/domain/practice/redux/actions";
import { PracticeId } from "@/domain/practice/redux/types";
import { FILTER_STRING, MODEL_SYNCED } from "@/library/constants";
import { PaginationConfig, PracticeQuery } from "@/library/types";
import { ProviderModel, Provider } from "@/domain/provider/model";
import { Header } from "@/components/Header";
import { SortQuery } from "@/components/table/head/sortable";
import { ModelCollection } from "@/library/model";
import { RCAResourceActions } from "@/library/core/config/actions";
import { setPracticesThunk } from "@/domain/practice/redux/middleware";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { PracticeSortedState } from "@/domain/practice/redux/types";
import { ProviderFormComponent } from "@/domain/provider/view";
import { PracticeTable } from "@/domain/practice/view/PracticeTable";
import { LocationForm } from "@/domain/tags/view/LocationForm";
import { Tag } from "@/domain/tags/model/types";
import { dispatchSaveLocationTag } from "@/domain/tags/redux/actions";
import { RootState } from "@/types";
import { Props, State, ConnectedProps, DispatchProps } from "./types";
import RSModal from "rsuite/Modal";
import { PracticeRules } from "@/domain/rules/view/PracticeRules";

class Component extends React.PureComponent<Props, State> {
  state: Readonly<State> = {
    search: "",
    showCreateProviderModal: false,
    showCreatePracticeModal: false,
    showEditPracticeModal: false,
    showCreateLocationModal: false,
    reset: false,
    selectedPracticeId: null,
    showOverrideModal: false,
  };

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.selectedPractices.length !== this.props.selectedPractices.length
    ) {
      await this.execSearch({});
    }
  }

  execSearch = async (query: PracticeQuery) => {
    const {
      practiceFilters,
      selectedOrganization,
      setPractices,
      setPracticesPaginationConfig,
      sortedState,
    } = this.props;
    const { search } = this.state;

    if (!selectedOrganization) return;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });
    }

    LoadingIndicator.fire.show();
    const practiceCollection: ModelCollection<PracticeModel, Practice> =
      PracticeModel.makePracticeCollection();
    practiceCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    const paginationConfig: PaginationConfig = await practiceCollection.fetch({
      ...query,
      limit: this.props.practicePageLimit,
      includeProviderCount: true,
      name: search,
      organizationIds: [this.props.selectedOrganization?.id],
      ...practiceFilters,
      ...(!!this.props.selectedPractices.length
        ? {
            ids: this.props.selectedPractices.map(
              (practice: Practice) => practice.id
            ),
          }
        : {}),
      ...sortedState,
    });

    // set practices on redux state
    await setPractices(practiceCollection.container);

    await setPracticesPaginationConfig(paginationConfig);
    practiceCollection.trigger(MODEL_SYNCED);
  };

  setPageLimit = async (limit: number) => {
    await this.props.setPracticePageLimit(limit);
    await this.execSearch({});
  };

  onModalClose = () => {
    this.setState({
      showCreateProviderModal: false,
      showCreateLocationModal: false,
      showCreatePracticeModal: false,
      showEditPracticeModal: false,
      selectedPracticeId: null,
      showOverrideModal: false,
    });
  };

  onAddProvider = () => {
    this.setState({ showCreateProviderModal: true });
  };

  onAddPractice = () => {
    this.setState({ showCreatePracticeModal: true });
  };

  onEditPractice = () => {
    this.setState({ showEditPracticeModal: true });
  };

  onAddLocation = (practiceId: PracticeId) => {
    this.setState({
      showCreateLocationModal: true,
      selectedPracticeId: practiceId,
    });
  };

  onManageOverrides = (practice: Practice) => {
    this.setState({
      showOverrideModal: true,
      selectedPracticeId: practice.id!,
    });
  };

  render() {
    const {
      displayName,
      practices,
      togglePractice,
      setPracticeFilters,
      setPracticeSortedState,
      hidePractice,
      practicePageLimit,
      paginationConfig,
      onManageAssignmentClick,
      saveLocationTag,
    } = this.props;
    const {
      search,
      reset,
      showCreateLocationModal,
      showCreateProviderModal,
      showCreatePracticeModal,
      showEditPracticeModal,
      selectedPracticeId,
      showOverrideModal,
    } = this.state;

    return (
      <div className="card-body">
        <Header
          entity={{
            name: "Entity/Branch",
            subTitle: "Entity/Branch",
            displayName: displayName,
            selectedEntityLength: practices.length,
            totalEntityLength: paginationConfig.totalResources,
          }}
          button={{
            create: {
              onCreate: this.onAddPractice,
              shouldDisplay: true,
            },
            toggleCollapse: {
              onToggle: togglePractice,
              collapsed: hidePractice,
              shouldDisplay: true,
            },
          }}
          table={{ pageLimit: practicePageLimit }}
          search={{
            onSearch: this.execSearch,
            searchStr: search,
            filterStr: FILTER_STRING,
            filters: { type: undefined, status: undefined },
            placeholder: "Enter Entity/Branch name",
            sortedState: {
              direction: undefined,
              sortBy: undefined,
            } as SortQuery,
          }}
          filter={{
            onResetState: (newState: {}) => this.setState(newState),
            onResetFilters: setPracticeFilters as any,
            onResetSortedState: setPracticeSortedState as any,
          }}
        />

        <div
          id="practice-list"
          className={`container-fluid collapse ${
            hidePractice ? "hide" : "show"
          }`}
        >
          <PracticeTable
            pageLimit={practicePageLimit}
            paginationConfig={paginationConfig}
            onPageLimitChange={this.setPageLimit}
            data={practices}
            execSearch={this.execSearch}
            onCreateProvider={this.onAddProvider}
            onEditPractice={this.onEditPractice}
            onAddLocation={this.onAddLocation}
            reset={reset}
            onManageAssignmentClick={onManageAssignmentClick}
            onManageOverrides={this.onManageOverrides}
          />
        </div>

        <Modal
          handleClose={this.onModalClose}
          show={showCreatePracticeModal}
          heading="Create Entity/Branch"
          button="Create"
        >
          {showCreatePracticeModal && (
            <PracticeForm
              button="Create"
              handleClose={this.onModalClose}
              resourceAction={RCAResourceActions.Create}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.onModalClose}
          show={showEditPracticeModal}
          heading="Edit Entity/Branch"
          button="Save Changes"
        >
          {showEditPracticeModal && (
            <PracticeForm
              button="Save Changes"
              handleClose={this.onModalClose}
              resourceAction={RCAResourceActions.ModifyBasicInfo}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.onModalClose}
          show={showCreateProviderModal}
          heading="Create Provider"
          button="Create"
        >
          {showCreateProviderModal && (
            <ProviderFormComponent
              button="Create"
              handleClose={this.onModalClose}
              resourceAction={RCAResourceActions.Create}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.onModalClose}
          show={showCreateLocationModal}
          heading="Create Location"
          button="Create"
        >
          {showCreateLocationModal && (
            <LocationForm
              practiceId={selectedPracticeId}
              onClose={this.onModalClose}
              onSave={saveLocationTag}
            />
          )}
        </Modal>
        <RSModal
          size="lg"
          backdrop
          overflow={false}
          open={showOverrideModal}
          onClose={this.onModalClose}
        >
          <RSModal.Header closeButton>
            <RSModal.Title>Manage Parameter Overrides</RSModal.Title>
          </RSModal.Header>
          <RSModal.Body>
            <PracticeRules selectedPracticeId={selectedPracticeId} />
          </RSModal.Body>
        </RSModal>
      </div>
    );
  }
}

const mapDispatcherToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPractices: (practices: PracticeModel[]) =>
      dispatch(setPracticesThunk(practices) as any),
    setPracticePageLimit: (pageLimit: number) =>
      dispatch(dispatchSetPracticePageLimit(pageLimit)),
    setPracticesPaginationConfig: (paginationConfig: PaginationConfig) =>
      dispatch(dispatchSetPracticesPaginationConfig(paginationConfig)),
    deselectAllPractices: () => dispatch(dispatchDeselectAllPractices()),
    selectAllPractices: () => dispatch(dispatchSelectAllPractices()),
    setPracticeFilters: (practiceFilters: any) =>
      dispatch(dispatchSetPracticeFilters(practiceFilters)),
    setPracticeSortedState: (practiceSortedState: PracticeSortedState) =>
      dispatch(dispatchSetPracticeSortedState(practiceSortedState)),
    saveLocationTag: (tag: Tag) => dispatch(dispatchSaveLocationTag(tag)),
    setActivePractice: (practice: Practice) =>
      dispatch(dispatchSetActivePractice(practice)),
  };
};

const mapStateToProps = (state: RootState): ConnectedProps => {
  return {
    practices: state.practice.practices,
    paginationConfig: state.practice.paginationConfig,
    practicePageLimit: state.practice.practicePageLimit,

    organizations: state.organization.organizations,
    selectedOrganization: state.organization.selectedOrganization,

    selectedPractices: state.practice.selectedPractices,
    practiceFilters: state.practice.practiceFilters,

    providers: state.provider.providers,
    sortedState: state.practice.sortedState,
  };
};

export const PracticeComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
