import {
  BloodSugarLevel,
  FlowUnit,
  HeartRate,
  OBSERVATIONS_BOUNDS,
  ObservationType,
  PressureUnit,
  TemperatureUnit,
  VolumeUnit,
  WeightUnit,
} from "./types";

export const truncateObservationValue = (
  observation: ObservationType,
  value: number | undefined | string,
  fev1?: boolean | undefined
) => {
  if (!value) return "-";
  if (typeof value === "string") return value;

  if (observation === ObservationType.Spirometry && fev1) {
    return value.toFixed(OBSERVATIONS_BOUNDS["SpirometryFev"].precision);
  }

  const shouldTruncateWithDecimals =
    observation === ObservationType.Temperature ||
    observation === ObservationType.Weight;

  if (shouldTruncateWithDecimals)
    return value.toFixed(OBSERVATIONS_BOUNDS[observation].precision);

  return Math.trunc(value);
};

export const displayObsUnit = (
  obs: ObservationType
):
  | string
  | {
      pef: string;
      fev1: string;
    } => {
  return {
    [ObservationType.BloodPressure]: `BP (${PressureUnit.mmhg})`,
    [ObservationType.Spirometry]: {
      pef: `PEF (${FlowUnit.LitersPerMinute})`,
      fev1: `FEV1 (L)`,
    },
    [ObservationType.GlucoseLevel]: `Bld Gluc (${BloodSugarLevel.MilligramPerDeciliter})`,
    [ObservationType.HeartRate]: `HR (${HeartRate.BeatPerMinute})`,
    [ObservationType.PulseOximetry]: "O2 Sat (%)",
    [ObservationType.Temperature]: `Temp(º${TemperatureUnit.Fahrenheit})`,
    [ObservationType.Weight]: `Wt (${WeightUnit.Pounds})`,
  }[obs];
};
