import { FunctionComponent, ReactNode } from "react";
import SelectPicker from "rsuite/SelectPicker";
import CheckPicker from "rsuite/CheckPicker";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { useField } from "formik";

import { Placement } from "@/components/Tooltip/types";
import { MultiselectConfig } from "./multiselect.model";

type MultiSelectType = {
  data: MultiSelectDataType[];
  onChange: (value: any) => void;
  onOpen?: () => void;
  findValueBy?: any;
  placeholder?: string;
  onSelect?: (value: any) => void;
  isSearchable?: boolean;
  className?: string;
  style?: {};
  placement?: Placement;
  picker?: "check" | "select";
  sortable?: boolean;
  name: string;
  disabled?: boolean;
  optional?: boolean;
  defaultValue?: any;
  value?: any;
  hasError?: boolean;
};

export const MultiSelect: FunctionComponent<MultiSelectType> = (props) => {
  const { hasError, sortable, isSearchable, findValueBy, ...rest } = props;

  const [field, meta] = useField(props.name);
  let isValid =
    hasError === false || !(meta.touched && !props.disabled && meta.error);

  if (!hasError && props.optional === true) {
    isValid = true;
  }

  let data = props.data;
  if (props.sortable) {
    data = data.sort(ascending);
  }
  let searchField: HTMLElement = document.getElementsByClassName(
    "rs-picker-search-bar-input"
  )[0] as unknown as HTMLFormElement;
  const pickerProps = {
    ...field,
    ...rest,
    menuClassName: props.className,
    defaultValue: props.defaultValue,
    disabled: props.disabled,
    placement: props.placement ?? "auto",
    style: props.style
      ? { width: "100%", zIndex: 6, ...props.style }
      : {
          width: "100%",
          zIndex: 6,
          backgroundColor: "white",
          ...(!isValid ? { border: "1px solid red" } : {}),
        },
    data: data,
    onChange: async (value: any) => props.onChange(value),
    onOpen: () => {
      props.onOpen && props.onOpen();
      searchField = document.getElementsByClassName(
        "rs-picker-search-bar-input"
      )[0] as unknown as HTMLFormElement;
      searchField?.focus();
    },
    value:
      props.value ||
      (props.findValueBy === undefined
        ? []
        : [
            props.data.find(
              (dataElement: MultiSelectDataType) =>
                dataElement.value === props.findValueBy
            )?.value,
          ]),
    searchable: props.isSearchable,
    placeholder: props.placeholder,
    valueKey: "value",
    onSelect: async (value: string, item: any, event: any) => {
      props.onSelect && props.onSelect(value);
    },
    onClose: () => searchField?.blur(),
  };

  if (props.picker === "check") {
    return (
      <div className={props.className}>
        <CheckPicker {...pickerProps} />
        {!isValid ? (
          <div className="invalid-input-feedback">{meta.error}</div>
        ) : null}
      </div>
    );
  }
  return (
    <div className={props.className}>
      <SelectPicker {...pickerProps} />
      {!isValid ? (
        <div className="invalid-input-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

type MultiSelectDataType = { label: string; value: any; role?: string };

const ascending = (a: { label: string }, b: { label: string }) =>
  a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;

export type BasicMultiSelectType = {
  data: MultiSelectDataType[];
  onChange: (value: any) => void;
  onOpen?: () => void;
  findValueBy?: any;
  placeholder?: string;
  onSelect?: (value: any) => void;
  isSearchable: boolean;
  className?: string;
  style?: {};
  placement?: Placement;
  picker?: "check" | "select";
  sortable?: boolean;
  value?: any;
  renderExtraFooter?: (() => ReactNode) | undefined;
  groupBy?: string;
  disabled?: boolean;
  config?: MultiselectConfig;
  onClear?: () => void;
  menuStyle?: React.CSSProperties;
};

export const BasicMultiSelect: FunctionComponent<BasicMultiSelectType> = (
  props
) => {
  let data = props.data;
  if (props.sortable) {
    data = data.sort(ascending);
  }
  let searchField: HTMLElement = document.getElementsByClassName(
    "rs-picker-search-bar-input"
  )[0] as unknown as HTMLFormElement;

  const pickerProps = {
    data,
    placement: props.placement ?? undefined,
    style: props.style,
    onChange: async (value: any) => props.onChange(value),
    onOpen: () => {
      props.onOpen && props.onOpen();
      searchField = document.getElementsByClassName(
        "rs-picker-search-bar-input"
      )[0] as unknown as HTMLFormElement;
      searchField?.focus();
    },
    value:
      props.value ||
      (props.findValueBy === undefined
        ? []
        : [
            props.data.find(
              (dataElement: MultiSelectDataType) =>
                dataElement.value === props.findValueBy
            )?.value,
          ]),
    searchable: props.isSearchable,
    placeholder: props.placeholder,
    valueKey: "value",
    onSelect: async (value: string, item: any, event: any) => {
      props.onSelect && props.onSelect(value);
    },
    onClose: () => searchField?.blur(),
    renderExtraFooter: props.renderExtraFooter,
    groupBy: props.groupBy ? props.groupBy : undefined,
    disabled: props.disabled ? true : false,
    loading: props.config?.showLoadingIndicator,
    onClean: () => (props.onClear ? props.onClear() : () => {}),
    menuStyle: props.menuStyle,
  };

  const renderLoading = () => (
    <p style={{ padding: 10, color: "#999", textAlign: "center" }}>
      <SpinnerIcon spin /> loading...
    </p>
  );

  /** Spinner loading when fetching data from the server */
  const renderMenu = (menu: ReactNode) => {
    if (props.config?.showLoadingIndicator && data.length === 0) {
      return renderLoading();
    }
    return menu;
  };

  const Component = props.picker === "check" ? CheckPicker : SelectPicker;

  return (
    <div className={props.className}>
      <Component {...pickerProps} renderMenu={renderMenu} />
    </div>
  );
};
