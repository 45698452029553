import React, { ReactNode } from "react";
import { connect } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { MultiselectConfig } from "@/components/form/select/multiselect.model";
import { Organization } from "@/domain/organization/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { ProviderModel } from "@/domain/provider/model";
import { PatientFilters } from "@/domain/patient/model/types";

interface Props {
  onChange: (selectedProviderIds: string[], description: string[]) => void;
  filters: PatientFilters;
  selectedOrganization: Organization;
  selectedProviderIds?: string[];
}
interface State {
  providers: { value: string; label: string }[];
  practices: { value: string; label: string }[];
  multiSelectConfig: MultiselectConfig;
}

class ProviderFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      providers: [],
      practices: [],
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    };
  }

  withLoader = async (fetch: () => Promise<void>) => {
    await this.setState({
      multiSelectConfig: {
        showLoadingIndicator: true,
      },
    });
    await fetch();
    this.setState({
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    });
  };

  fetchPractices = async () => {
    if (this.props.selectedOrganization && this.props.selectedOrganization.id) {
      const fetchedPractices = (
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id,
        ])
      ).map((model: PracticeModel) => {
        return {
          value: model.pluck("id") as string,
          label: model.pluck("name"),
        };
      });
      this.setState((previousState, props) => ({
        ...previousState,
        practices: fetchedPractices,
      }));
      localStorage.setItem(
        "practicesBySelectedOrg",
        JSON.stringify(fetchedPractices)
      );
    }
  };

  fetchProviders = async () => {
    await this.setState(
      {
        ...this.state,
        practices: [],
      },
      async () => {
        await this.fetchPractices();
        const fetchedPractices = JSON.parse(
          localStorage.getItem("practicesBySelectedOrg") as string
        );
        const practices = fetchedPractices.map(
          (practice: { value: string }) => practice.value
        );
        const fetchedProviders = (
          await ProviderModel.fetchByPracticeIds(practices)
        ).map((model: ProviderModel) => {
          return {
            value: model.pluck("id") as string,
            label: `${model.pluck("firstName")} ${model.pluck(
              "lastName"
            )}, ${model.pluck("credentials")}`,
          };
        });
        this.setState((previousState, props) => ({
          ...previousState,
          providers: fetchedProviders,
        }));
      }
    );
  };

  getDefaultValue() {
    if (this.props.selectedProviderIds) {
      return this.props.selectedProviderIds;
    }

    return this.props.filters.providerIds &&
      this.props.filters.providerIds.length > 0
      ? this.props.filters.providerIds
      : undefined;
  }

  render(): ReactNode {
    return (
      <BasicMultiSelect
        data={this.state.providers}
        onChange={async (selectedProviderIds: string[]) => {
          const descriptions = selectedProviderIds.map(
            (selectedProviderId) =>
              this.state.providers.find(
                (provider) => provider.value === selectedProviderId
              )?.label ?? ""
          );
          this.props.onChange(selectedProviderIds, descriptions);
        }}
        value={this.getDefaultValue()}
        isSearchable={true}
        placeholder="All Providers"
        onOpen={async () => {
          this.withLoader(this.fetchProviders);
        }}
        picker="check"
        config={this.state.multiSelectConfig}
      />
    );
  }
}

const mapStateToProps = (state: {
  organization: { selectedOrganization: Organization };
  patient: { filters: PatientFilters };
}) => {
  return {
    filters: state.patient.filters,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatcherToProps)(ProviderFilter);
