import produce from "immer";
import { AnyAction } from "redux";

import { LOGOUT } from "@/library/constants";
import * as C from "./constants";
import { KitState } from "./types";

export const INITIAL_STATE: KitState = {
  kits: [],
  filters: {
    organizationIds: undefined,
    practiceIds: [],
    currentPage: 1,
    limit: 50,
    includeTagInfo: 1,
    tagIds: undefined,
  },
  sortedState: {
    direction: undefined,
    sortBy: undefined,
  },
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  returnKitModalActive: false,
  selectedKitIds: [],
};

export const kitsReducer = produce((state: KitState, action: AnyAction) => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (!action.payload) break;

      const { kits: kitsState = {} } = action.payload;
      const { kits, filters, sortedState, paginationConfig } = kitsState;

      state.kits = kits || [];
      state.filters = filters || INITIAL_STATE.filters;
      state.sortedState = sortedState || INITIAL_STATE.sortedState;
      state.paginationConfig =
        paginationConfig || INITIAL_STATE.paginationConfig;
      state.returnKitModalActive = false;
      state.selectedKitIds = [];
      break;
    }

    case C.SET_KITS:
      state.kits = action.payload.kits;
      break;

    case C.SET_KIT_FILTER:
      state.filters = action.payload.filters;
      break;

    case C.SET_KIT_SORTED_STATE:
      state.sortedState = action.payload.sortedState;
      break;

    case C.SET_KITS_PAGINATION_CONFIG:
      state.paginationConfig = action.payload.paginationConfig;
      break;

    case C.SET_RETURN_KIT_MODAL_ACTIVE:
      state.returnKitModalActive = action.payload.returnKitModalActive;
      break;

    case C.UNASSIGN_DEVICE:
      const indexOfDevice = state.kits.findIndex(
        (device) => device.id === action.payload
      );
      state.kits[indexOfDevice].patient = undefined;
      state.kits[indexOfDevice].patientId = null;
      break;
    case C.UPDATE_DEVICE:
      const index = state.kits.findIndex((d) => d.id === action.payload.id);
      if (index > -1) state.kits[index] = action.payload;
      break;
    case C.SELECT_KIT:
      state.selectedKitIds.push(action.payload);
      break;
    case C.DESELECT_KIT:
      state.selectedKitIds = state.selectedKitIds.filter(
        (kitId) => kitId !== action.payload
      );
      break;
    case C.ASSIGN_LOCATION:
      const { kitIds, tag } = action.payload;
      kitIds.forEach((id: string) => {
        const indexOfSelectedKit = state.kits.findIndex((kit) => kit.id === id);
        const tags = state.kits[indexOfSelectedKit].tags.filter(
          (tag) => tag.type !== "location"
        );
        state.kits[indexOfSelectedKit].tags = [...tags, tag];
      });
      state.selectedKitIds = [];
      break;
    case C.UNASSIGN_LOCATION:
      const kitsIds = action.payload;
      kitsIds.forEach((id: string) => {
        const selectedKitIndex = state.kits.findIndex((kit) => kit.id === id);
        const tags = state.kits[selectedKitIndex].tags.filter(
          (tag) => tag.type !== "location"
        );
        state.kits[selectedKitIndex].tags = tags;
      });
      state.selectedKitIds = [];
      break;
    case C.SELECT_ALL_KITS:
      state.selectedKitIds = state.kits.map((kit) => kit._meta.id);
      break;
    case C.DESELECT_ALL_KITS:
      state.selectedKitIds = [];
      break;
    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}, INITIAL_STATE);
