import { NoteObservation } from "@/domain/notes/model/types";
import { ObservationType } from "@/domain/observations/types";
import { PatientObservation } from "@/domain/patient/model/types";

type Props = {
  observation: NoteObservation | PatientObservation;
};
export const DeviceType: React.FC<Props> = (props): JSX.Element => {
  const { observation } = props;

  switch (observation.type) {
    case ObservationType.BloodPressure:
      return (
        <div>
          <div>SYSTOLIC</div>
          <div>DIASTOLIC</div>
        </div>
      );
    case ObservationType.GlucoseLevel:
      return <div>GLUCOSE LEVEL</div>;
    case ObservationType.HeartRate:
      return <div>HEART RATE</div>;
    case ObservationType.PulseOximetry:
      return <div>PULSE OXIMETRY</div>;
    case ObservationType.Spirometry:
      return (
        <div>
          <div>FEV1</div>
          <div>PEF</div>
        </div>
      );
    case ObservationType.Temperature:
      return <div>TEMPERATURE</div>;
    case ObservationType.Weight:
      return <div>WEIGHT</div>;
    default:
      // unrecognizable observation type
      return <></>;
  }
};
