import { extractphone } from "@/components/form";
import { UserForm, UserModel } from "@/domain/user/model";
import { Serializeable } from "@/library/serializers/serializer";

/**
 * Serializer for modifying User.
 */
export class ModifyUserSerializer implements Serializeable<UserModel> {
  constructor(protected readonly formData: UserForm) {}

  serialize(): UserModel {
    return UserModel.make({
      id: this.formData.id,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      credentials:
        this.formData.credentials === "none" ? null : this.formData.credentials,
      phone: extractphone(this.formData.phone),
      canAccumulateBillableTime: this.formData.canAccumulateBillableTime,
      notifications: this.formData.notifications,
      tags: this.formData.locationIds
        ? {
            set: this.formData.locationIds,
            delete: this.formData.deleteLocationIds?.length
              ? this.formData.deleteLocationIds
              : undefined,
          }
        : undefined,
    } as any);
  }
}
