import {
  heartRate,
  bloodPressure,
  bloodGlucose,
  bloodOxygenLevel,
  spirometry,
  temperature,
  weight,
} from "@/assets/icons/observations";
import { ObservationType } from "@/domain/observations/types";
import { OverrideLevel } from "./types";

export const OverrideLevels = {
  patient: "patient" as OverrideLevel,
  organization: "organization" as OverrideLevel,
  practice: "practice" as OverrideLevel,
  provider: "provider" as OverrideLevel,
};

export const ICONS: Record<ObservationType, React.ReactNode> = {
  [ObservationType.BloodPressure]: bloodPressure,
  [ObservationType.GlucoseLevel]: bloodGlucose,
  [ObservationType.HeartRate]: heartRate,
  [ObservationType.PulseOximetry]: bloodOxygenLevel,
  [ObservationType.Spirometry]: spirometry,
  [ObservationType.Temperature]: temperature,
  [ObservationType.Weight]: weight,
};

export const RuleVariables: Record<ObservationType, string[]> = {
  [ObservationType.BloodPressure]: [
    "systolicHighBloodPressureDiscrete",
    "systolicLowBloodPressureDiscrete",
    "diastolicHighBloodPressureDiscrete",
    "diastolicLowBloodPressureDiscrete",
  ],
  [ObservationType.GlucoseLevel]: [
    "highGlucoseLevelDiscrete",
    "lowGlucoseLevelDiscrete",
  ],
  [ObservationType.HeartRate]: [
    "highHeartRateDiscrete",
    "lowHeartRateDiscrete",
  ],
  [ObservationType.PulseOximetry]: ["oxygenSaturationDiscrete"],
  [ObservationType.Spirometry]: [],
  [ObservationType.Temperature]: [
    "highTemperatureDiscrete",
    "lowTemperatureDiscrete",
  ],
  [ObservationType.Weight]: [
    "dailyChangeDiscrete",
    "dailyChangePercent",
    "weeklyChangeDiscrete",
    "weeklyChangePercent",
  ],
};
