import { PaginationConfig } from "@/library/types";
import { Note } from "@/domain/notes/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import * as ActionConstants from "./constants";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import { AlertTableRowNew } from "@/domain/patient/view/AlertsTableNew/types";

export const createNote = (note: Note) => ({
  type: ActionConstants.CREATE_NOTE,
  payload: { note },
});

export const setNotePageLimit = (limit: number) => ({
  type: ActionConstants.SET_NOTE_PAGE_LIMIT,
  payload: { limit },
});

export const setNotesPaginationConfig = (
  paginationConfig: PaginationConfig
) => ({
  type: ActionConstants.SET_NOTES_PAGINATIONCONFIG,
  payload: { paginationConfig },
});

export const selectNote = (note: Note) => ({
  type: ActionConstants.SELECT_NOTE,
  payload: { note },
});

export const deselectNote = (note: Note) => ({
  type: ActionConstants.DESELECT_NOTE,
  payload: { note },
});

export const setTargetNoteId = (id: string) => ({
  type: ActionConstants.SET_TARGET_NOTE_ID,
  payload: { id },
});

export const setNotes = (notes: Note[]) => ({
  type: ActionConstants.SET_NOTES,
  payload: { notes },
});

export const setPatientNotes = (patientId: PatientId, notes: Note[]) => ({
  type: ActionConstants.SET_PATIENT_NOTES,
  payload: { patientId, notes },
});

export const dispatchAddPatientNote = (patientId: PatientId, note: Note) => ({
  type: ActionConstants.ADD_PATIENT_NOTE,
  payload: { patientId, note },
});

export const setUnresolvedPatientNotes = (
  patientId: PatientId,
  notes: Note[]
) => ({
  type: ActionConstants.SET_UNRESOLVED_PATIENT_NOTES,
  payload: { patientId, notes },
});

export const resolveNotes = (
  patientId: PatientId,
  noteIds: string[],
  rows: AlertTableRow[]
) => ({
  type: ActionConstants.RESOLVE_NOTES,
  payload: { patientId, noteIds, rows },
});

export const dispatchAddRejectedNoteId = (noteId: string) => ({
  type: ActionConstants.ADD_REJECTED_NOTE_ID,
  payload: { noteId },
});

export const dispatchSelectedNoteIds = (noteIds: AlertTableRowNew[]) => ({
  type: ActionConstants.ADD_SELECTED_NOTE_ID,
  payload: { noteIds },
});

export const dispatchSaveCreateNoteAlert = (text: string) => ({
  type: ActionConstants.SET_ALERT_CREATE_NOTE,
  payload: { text },
});
