import { User, UserModel, UserParentTypes } from "../../domain/user/model";

/**
 * Guards links and buttons based on user access level.
 */
export class Guard {
  constructor(private readonly accessLevel: UserParentTypes) {}

  static accessLevel(accessLevel: UserParentTypes) {
    return new Guard(accessLevel);
  }

  canActivate(user: User): boolean {
    const userModel: UserModel = UserModel.make(user);
    if (!user) {
      return false;
    }
    return userModel.parentType === this.accessLevel;
  }
}
