import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "clsx";

import { AlertLegend } from "@/domain/rules/view/AlertLegend";
import { ObservationParameters } from "@/domain/rules/view/ObservationParameters";
import { AssessmentModel } from "@/domain/assessment/model";
import { setPatientAssessments } from "@/domain/assessment/redux/actions";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { dispatchSetPatientRules } from "@/domain/rules/redux/actions";
import { selectRulesForPatient } from "@/domain/rules/redux/selectors";
import { OverrideLevels } from "@/domain/rules/view/ObservationParameters/constants";
import { RuleModel } from "@/domain/rules/model";
import { syncPatient } from "@/domain/patient/view/PatientDetails/helpers";
import {
  attachParentEntities,
  attachPatientRequestNotes,
} from "@/domain/patient/redux/middleware";
import { dispatchSelectPatient } from "@/domain/patient/redux/actions";
import { Rule } from "@/domain/rules/model/types";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export type Props = {};

export const ParametersTab: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const patient = useSelector(selectFirstPatient);
  const rules = useSelector(selectRulesForPatient(patient?.id));

  const [savedAt, setSavedAt] = useState<Record<string, Date | null>>({});

  const handleSavedAt = (rule: Rule) => (savedAt: Date | null) =>
    setSavedAt({
      ...savedAt,
      [rule.id as string]: savedAt,
    } as any);

  useEffect(() => {
    const fetchRules = async () => {
      if (!patient?.id) return;

      const assessments = await AssessmentModel.getAssessmentTemplates();
      const ruleModels =
        (await RuleModel.fetchByParams({
          limit: "100",
        })) || [];
      const rules = ruleModels.map((m) => m.pluckAll());
      const updatedPatient = await syncPatient(patient.id);
      const [patientWithParentEntities] = await attachParentEntities([
        updatedPatient,
      ]);

      const [patientWithRequestNotes] = await attachPatientRequestNotes([
        patientWithParentEntities,
      ]);

      dispatch(setPatientAssessments(patient.id, assessments));
      dispatch(dispatchSetPatientRules(patient.id, rules));
      dispatch(dispatchSelectPatient(patientWithRequestNotes));
    };

    fetchRules();
  }, [patient?.id, savedAt]);

  return (
    <div className={styles.container}>
      <div className={cx(styles.headerRow, "row")}>
        <div className="col-xl-6">
          <h3 className={cx(globalStyles.pageHeading, styles.header)}>
            Biometric Vital Thresholds
          </h3>
          <div className={styles.sectionContent}>
            <p className={styles.p}>
              The default values of the thresholds have been set by Anelto at an
              organizational level using medically accepted, evidence-based
              data. It is recommended to use the defaults, but these values can
              be modified for individual patients to match the parameters
              expected for their condition.
            </p>
          </div>
        </div>
        <div className="col-xl-6">
          <h3 className={cx(globalStyles.pageHeading, styles.header)}>
            Alert Level Colors
          </h3>
          <div className={styles.sectionContent}>
            <AlertLegend />
          </div>
        </div>
      </div>
      <div className={styles.colRight}>
        {rules.map((rule, index) => {
          return (
            <ObservationParameters
              key={`obsParams-${rule.name}-${index}`}
              rule={rule}
              savedAt={savedAt[rule.id as string]}
              overrideLevel={OverrideLevels.patient}
              onSave={handleSavedAt(rule)}
              containerClassName={styles.mt}
            />
          );
        })}
      </div>
    </div>
  );
};
