import React from "react";
import { connect } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { MultiselectConfig } from "@/components/form/select/multiselect.model";
import { ModelCollection } from "@/library/model";
import { OrganizationModel } from "@/domain/organization/model";
import { dispatchSelectOrganization } from "@/domain/organization/redux/actions";
import { Organization } from "@/domain/organization/model/types";

interface OrganizationDropdownProps {
  onChange: (value: string) => any;
  currentOrganization?: string;

  selectOrganization: (organization: Organization) => {
    type: string;
    payload: Organization;
  };
  setDefaultOrganization?: boolean;

  selectedOrganization: Organization;

  disabled?: boolean;

  placeholder?: string;
}

interface OrganizationDropdownState {
  organizationDropdownOptions: { value: string; label: string }[];
  multiSelectConfig: MultiselectConfig;
}

/**
 * This component can be used across multiple forms.
 * It needs two props: onChange - the function to perform when a new org is selected
 * and currentOrganization - text to display (probably Redux(this.props.selectedOrganization?.name) in most cases)
 */
class OrganizationDropdown extends React.Component<
  OrganizationDropdownProps,
  OrganizationDropdownState
> {
  constructor(props: OrganizationDropdownProps) {
    super(props);
    this.state = {
      organizationDropdownOptions: [],
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    };
  }

  componentDidMount() {
    this.buildOrganizationDropdownOptions();
  }

  withLoader = async (fetch: () => Promise<void>) => {
    await this.setState({
      multiSelectConfig: {
        showLoadingIndicator: true,
      },
    });
    await fetch();
    this.setState({
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
    });
  };

  buildOrganizationDropdownOptions = async () => {
    const organizationCollection: ModelCollection<
      OrganizationModel,
      Organization
    > = OrganizationModel.makeOrganizationCollection();
    const limit = 100;
    organizationCollection.withParams({ limit });

    const iterator = await organizationCollection.getMany();
    const allOrganizationModels = await iterator.getAll();

    const params = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const organizationId = params.get("organizationId");
    if (this.props.setDefaultOrganization) {
      //Setting default organization if no organization is selected.
      if (
        !this.props.selectedOrganization &&
        !organizationId &&
        allOrganizationModels &&
        allOrganizationModels.length &&
        allOrganizationModels.length > 0
      ) {
        window.history.replaceState(
          null,
          ``,
          `${path}?organizationId=${allOrganizationModels[0].pluck("id")}`
        );
        this.props.selectOrganization!(allOrganizationModels[0].pluckAll());
      } else if (!this.props.selectedOrganization && organizationId) {
        //Syncing the organization if there is organization ID exist in query params and set it as selected organization.
        window.history.replaceState(
          null,
          ``,
          `${path}?organizationId=${organizationId}`
        );
        const orgModel: OrganizationModel = await OrganizationModel.sync(
          organizationId
        );
        this.props.selectOrganization!(orgModel.pluckAll());
      }
    }

    const organizationDropdownOptions = allOrganizationModels.map(
      (orgModel: OrganizationModel) => {
        return {
          value: `${orgModel.attributes.get("id")}`,
          label: orgModel.attributes.get("name"),
        };
      }
    );
    this.setState({
      organizationDropdownOptions: organizationDropdownOptions,
    });
  };

  render() {
    return (
      <>
        <BasicMultiSelect
          data={this.state.organizationDropdownOptions}
          onChange={this.props.onChange}
          onOpen={() => {
            this.withLoader(this.buildOrganizationDropdownOptions);
          }}
          isSearchable={true}
          placeholder={
            this.props.selectedOrganization?.name ||
            this.props.currentOrganization
          }
          findValueBy={this.props.selectedOrganization?.id}
          sortable={true}
          style={{ width: "250px" }}
          disabled={this.props.disabled ? true : false}
          config={this.state.multiSelectConfig}
          onClear={() => {
            this.props.onChange("");
          }}
        />
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
  };
};

const mapStateToProps = (state: {
  organization: { selectedOrganization: Organization };
}) => {
  return {
    selectedOrganization: state.organization.selectedOrganization,
  };
};

export const OrganizationDropdownComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(OrganizationDropdown);
