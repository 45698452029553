import { useEffect, useState } from "react";
import {
  Row,
  Cell,
  ColumnDef,
  flexRender,
  CellContext,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  RowSelectionState,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import cx from "clsx";

import {
  selectNumDaysBetweenDates,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { makeDateColumns, makeExpanderColumn, makeLabelColumn } from "./helper";
import { AggregateData, PatientObservationProps } from "./types";

import styles from "./styles.module.scss";
import { ObservationTypeNew } from "@/domain/observations/types";
import { ObservationCellNew } from "../tables/ObservationCellNew";

export const ObservationTable: React.FC<PatientObservationProps> = (props) => {
  const { data, className, observationType } = props;
  const [hoveredCellId, setHoveredCellId] = useState("");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const numDays = useSelector(selectNumDaysBetweenDates);
  const isoEndDate = useSelector(selectMonitoringEndDate);

  const fromDate = DateTime.fromISO(isoEndDate);

  const handleCellHover = (id: string) => () => setHoveredCellId(id);
  const handleCellLeave = (id: string) => () => {
    if (hoveredCellId === id) setHoveredCellId("");
  };

  const columnHelper = createColumnHelper<AggregateData>();
  const renderCell =
    (date: string) => (info: CellContext<AggregateData, any>) =>
      (
        <ObservationCellNew
          info={info}
          date={date}
          hoveredCellId={hoveredCellId}
          onHover={handleCellHover(info.cell.id)}
          onLeave={handleCellLeave(info.cell.id)}
        />
      );
  // @ts-ignore
  let columns: ColumnDef<AggregateData>[] = [];
  if (observationType === ObservationTypeNew.Spirometry) {
    columns = [
      // makeExpanderColumn(),
      makeLabelColumn(columnHelper, observationType),
      ...makeDateColumns(columnHelper, fromDate, numDays, renderCell),
    ];
  } else {
    columns = [...makeDateColumns(columnHelper, fromDate, numDays, renderCell)];
  }

  const table = useReactTable<AggregateData>({
    data,
    columns,
    state: {
      rowSelection,
    },
    initialState: { expanded: true },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row) => {
      return !!(
        row.depth === 0 && (row.original.subRows as AggregateData[])?.length
      );
    },
    getSubRows: (row): AggregateData[] | undefined =>
      row.subRows as AggregateData[],
  });

  const { rows } = table.getRowModel();

  const renderHeader = () =>
    table.getHeaderGroups().map((headerGroup) => {
      return (
        <tr key={`headerGroup-${headerGroup.id}`}>
          {headerGroup.headers.map((header) => {
            return (
              <th key={`header-${header.id}`}>
                {header.isPlaceholder ? null : (
                  <>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      );
    });

  const renderRow = (row: Row<AggregateData>) => {
    return (
      <tr key={`aggregateRow-${row.id}`} className={cx(styles.mainRow)}>
        {row.getVisibleCells().map((cell: Cell<AggregateData, unknown>) => {
          return (
            <td
              id={cell.id}
              key={cell.id}
              className={cx(
                styles.td,
                cell.column.id === "label" ? styles.labelTd : null,
                cell.column.id === "expander" ? styles.expanderTd : null
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderEmpty = () => (
    <tr>
      <td colSpan={numDays + 2} className={styles.emptyTd}>
        No results.
      </td>
    </tr>
  );

  return (
    <>
      <table
        id="newAggregateBiometricTable"
        className={cx(styles.table, styles.aggregateTable, className)}
      >
        <thead>{renderHeader()}</thead>
        <tbody className="overflow-visible">
          {rows.length ? rows.map(renderRow) : renderEmpty()}
        </tbody>
      </table>
    </>
  );
};
