import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { selectProviders } from "@/domain/provider/redux/selectors";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";
import { ProviderModel } from "@/domain/provider/model";
import { PracticeModel } from "@/domain/practice/model";
import { ProgramModel } from "@/domain/program/model";
import { TagModel } from "@/domain/tags/model";
import { Practice } from "@/domain/practice/model/types";
import { Provider } from "@/domain/provider/model";
import { Tag } from "@/domain/tags/model/types";
import { Program } from "@/domain/program/model/types";
import { dispatchSetPractices } from "@/domain/practice/redux/actions";
import { setProviders as dispatchSetProviders } from "@/domain/provider/redux";
import { dispatchSetLocationTags } from "@/domain/tags/redux/actions";
import { Note } from "@/domain/notes/model/types";
import { DateTime } from "luxon";
import { PATIENT_BILLING_REPORT_TIMEZONE } from "@/library/constants";
import { getCurrentDate } from "@/domain/patient/view/tabs/summary/constants";
import { TimeLogTable } from "@/domain/patient/view/tabs/audit/timeLog.component";
import {
  PatientModificationsTableHeader,
  TimeLogTableHeader,
  AuditEquipmentTableHeader,
  TABLE_PAGE_LIMIT,
  ruleVariables,
  ruleVariableSymbols,
  ruleVariableSigns,
  variableKeysLookup,
  RESET_TO_DEFAULT,
  RULE,
  profileVariables,
  profileVariableKeys,
  logTypes,
} from "@/domain/patient/view/tabs/audit/constants";
import { PaginationConfig } from "@/library/types";
import { PatientModel } from "@/domain/patient/model";
import { DEFAULT_PAGINATION_CONFIG } from "@/components/pagination/constants";
import { dateFormatter } from "@/pipes/date";
import { padNumber } from "@/components/pathway/automations";
import { PatientNoteType } from "@/library/types/note";
import { ParameterModificationsTable } from "@/domain/patient/view/tabs/audit/parameterModificationLog.component";
import { ProfileModificationsLogTable } from "@/domain/patient/view/tabs/audit/profileModificationsLog.component";
import { EquipmentHistoryTable } from "@/domain/patient/view/tabs/audit/equipmentHistoryLog.component";
import {
  PatientModificationsData,
  TimeLogData,
  EquipmentHistoryData,
} from "@/domain/patient/view/tabs/audit/helper";
import { AuditModel } from "@/domain/audit/model";
import { DeviceModel } from "@/domain/kits/models/device.model";
import { UserModel } from "@/domain/user/model";
import { RuleModel } from "@/domain/rules/model";
import { Rule } from "@/domain/rules/model/types";
import { AuditEntryType, AuditLog } from "@/domain/audit/model/types";
import { unique } from "@/util/unique";
import { isEmpty } from "lodash";

type Props = {
  setNotePageLimit?: (limit: number) => void;
  setNotesPaginationConfig?: (paginationConfig: PaginationConfig) => void;
};

export const AuditTab: React.FC<Props> = (props) => {
  const patient = useSelector(selectFirstPatient);
  const organization = useSelector(selectActiveOrganization);
  const providers = useSelector(selectProviders);
  const locationTags = useSelector(selectLocationTags);
  const practices = useSelector(selectPractices);

  const dispatch = useDispatch();
  const setPractices = (practices: Practice[]) =>
    dispatch(dispatchSetPractices(practices));
  const setProviders = (providers: Provider[]) =>
    dispatch(dispatchSetProviders(providers));
  const setLocationTags = (tags: Tag[]) =>
    dispatch(dispatchSetLocationTags(tags));

  const [timeLogPageLimit, setTimeLogPageLimit] =
    useState<number>(TABLE_PAGE_LIMIT);
  const [paramModsPageLimit, setParamModsLogPageLimit] =
    useState<number>(TABLE_PAGE_LIMIT);
  const [profileModsPageLimit, setProfileModsLogPageLimit] =
    useState<number>(TABLE_PAGE_LIMIT);
  const [equipmentHistoryPageLimit, setEquipmentHistoryPageLimit] =
    useState<number>(TABLE_PAGE_LIMIT);
  const [programs, setPrograms] = useState<Program[]>();

  const prevTimeLogPageLimitRef = useRef<number>();
  const prevParamModsPageLimitRef = useRef<number>();
  const prevProfileModsPageLimitRef = useRef<number>();
  const prevEquipmentHistoryPageLimitRef = useRef<number>();

  const [timeLogPaginationConfig, setTimeLogPaginationConfig] =
    useState<PaginationConfig>(DEFAULT_PAGINATION_CONFIG);
  const [parameterModsPaginationConfig, setParameterModsPaginationConfig] =
    useState<PaginationConfig>(DEFAULT_PAGINATION_CONFIG);
  const [profileModsPaginationConfig, setProfileModsPaginationConfig] =
    useState<PaginationConfig>(DEFAULT_PAGINATION_CONFIG);
  const [
    equipmentHistoryPaginationConfig,
    setEquipmentHistoryPaginationConfig,
  ] = useState<PaginationConfig>(DEFAULT_PAGINATION_CONFIG);

  const [isTimeLogCollapsed, setIsTimeLogCollapsed] = useState(true);
  const [isParamLogCollapsed, setIsParamLogCollapsed] = useState(true);
  const [isProfileModCollapsed, setIsProfileModCollapsed] = useState(true);
  const [isEquipmentHistoryCollapsed, setIsEquipmentHistoryCollapsed] =
    useState(true);

  const [allTimeLogNotes, setAllTimeLogNotes] = useState([] as TimeLogData[]);
  const [allParamModLogs, setAllParamModLogs] = useState(
    [] as PatientModificationsData[]
  );
  const [allProfileModificationLogs, setAllProfileModificationLogs] = useState(
    [] as PatientModificationsData[]
  );
  const [allEquipmentLogNotes, setAllEquipmentLogNotes] = useState(
    [] as EquipmentHistoryData[]
  );

  const getBillingPeriod = () => {
    const start = DateTime.fromJSDate(getCurrentDate, {
      zone: PATIENT_BILLING_REPORT_TIMEZONE,
    }).startOf("month");
    const end = start.plus({ month: 1 });
    return [start, end];
  };

  const fetchRules = async () => {
    if (!patient || !patient.id) return;
    const ruleModels =
      (await RuleModel.fetchByParams({
        limit: `${TABLE_PAGE_LIMIT}`,
      })) || [];
    const rules = ruleModels.map((m) => m.pluckAll());
    const rulesById: Record<string, Rule> = {};
    rules.forEach((rule: Rule) => {
      if (rule.id) rulesById[`${rule.id}`] = rule;
    });
    return rulesById;
  };

  useEffect(() => {
    (async () => {
      const programs: Program[] =
        await ProgramModel.fetchAllProgramsWithIterator({});
      setPrograms(programs);
    })();
  }, []);

  const getTimeLogNotes = async (
    query: Record<string, unknown>,
    limit?: number
  ): Promise<[TimeLogData[], PaginationConfig]> => {
    LoadingIndicator.fire.show();
    const [startDate, endDate] = getBillingPeriod();
    const [notes, paginationConfig] = await PatientModel.getNotes(
      patient?._meta?.id!,
      startDate.toISO(),
      endDate.toISO(),
      [
        PatientNoteType.Timelog,
        PatientNoteType.Clinical,
        PatientNoteType.Assessment,
        PatientNoteType.Timelog,
        PatientNoteType.Assessment,
        PatientNoteType.Clinical,
        PatientNoteType.Observation,
        PatientNoteType.Reviewed,
      ],
      {
        ...query,
        limit: limit || timeLogPageLimit,
      }
    );
    const sorted = notes
      .sort((a, b) => {
        return a._meta?.created &&
          b._meta?.created &&
          a._meta?.created > b._meta?.created
          ? -1
          : 1;
      })
      .filter((note) => note.author !== undefined && note.seconds !== 0);
    let index = 0;
    let totalSeconds = 0;
    let totalBillableSeconds = 0;

    let cumulativeHours = 0;
    let cumulativeMinutes = 0;
    let cumulativeSeconds = 0;

    let cumulativeBillableHours = 0;
    let cumulativeBillableMinutes = 0;
    let cumulativeBillableSeconds = 0;

    const timeLogData = sorted.map((note: Note) => {
      totalSeconds =
        totalSeconds + (note.minutes ?? 0) * 60 + (note.seconds ?? 0);
      cumulativeHours = Math.floor(totalSeconds / 3600);
      cumulativeMinutes = Math.floor((totalSeconds % 3600) / 60);
      cumulativeSeconds = totalSeconds % 60;

      if (note.author?.canAccumulateBillableTime) {
        totalBillableSeconds =
          totalBillableSeconds + (note.minutes ?? 0) * 60 + (note.seconds ?? 0);

        cumulativeBillableHours = Math.floor(totalBillableSeconds / 3600);
        cumulativeBillableMinutes = Math.floor(
          (totalBillableSeconds % 3600) / 60
        );
        cumulativeBillableSeconds = totalBillableSeconds % 60;
      }
      const timeStamp = dateFormatter({
        date: note._meta?.created,
        includeTime: true,
        toLocalTimezone: { enabled: true },
      });
      const user = note.author?.name;
      const timeDuration =
        padNumber(
          Math.floor(((note.minutes ?? 0) * 60 + (note.seconds ?? 0)) / 60)
        ) +
        ":" +
        padNumber((note.seconds ?? 0) % 60);
      const billable = note.author?.canAccumulateBillableTime ? "yes" : "no";
      const billableAccumulatedTime =
        padNumber(cumulativeBillableHours) +
        ":" +
        padNumber(cumulativeBillableMinutes) +
        ":" +
        padNumber(cumulativeBillableSeconds);
      const totalAccumulatedTime =
        padNumber(cumulativeHours) +
        ":" +
        padNumber(cumulativeMinutes) +
        ":" +
        padNumber(cumulativeSeconds);
      return {
        id: index++,
        timeStamp,
        user,
        timeDuration,
        billable,
        billableAccumulatedTime,
        totalAccumulatedTime,
      } as unknown as TimeLogData;
    });

    setAllTimeLogNotes(timeLogData);
    setTimeLogPaginationConfig({
      ...paginationConfig,
    });
    LoadingIndicator.fire.hide();
    return [timeLogData, paginationConfig];
  };

  const getParamModificationsLog = async (
    query: Record<string, unknown>,
    limit?: number
  ): Promise<[PatientModificationsData[], PaginationConfig]> => {
    LoadingIndicator.fire.show();
    const [auditLog, paginationConfig] = await AuditModel.getAuditEntryLog({
      ...query,
      type: AuditEntryType.PatientParameterChange,
      patientId: patient?._meta?.id!,
      limit: limit || paramModsPageLimit,
    });
    const rules = await fetchRules();
    const logs = await formatPatientParamsLog(auditLog, rules);
    setAllParamModLogs(logs);
    setParameterModsPaginationConfig({
      ...paginationConfig,
    });
    LoadingIndicator.fire.hide();
    return [logs, paginationConfig];
  };

  const getProfileModificationsLog = async (
    query: Record<string, unknown>,
    limit?: number
  ): Promise<[PatientModificationsData[], PaginationConfig]> => {
    LoadingIndicator.fire.show();
    const [auditLog, paginationConfig] = await AuditModel.getAuditEntryLog({
      ...query,
      type: AuditEntryType.PatientProfileChange,
      patientId: patient?._meta?.id!,
      limit: limit || profileModsPageLimit,
    });
    const practices = await fetchPractices();
    const providers = await fetchProviders(practices);
    const locationTags = await fetchLocationTags(practices);
    const logs = await formatPatientProfileLog(
      auditLog,
      practices,
      providers,
      locationTags
    );
    setAllProfileModificationLogs(logs);
    setProfileModsPaginationConfig({
      ...paginationConfig,
    });
    LoadingIndicator.fire.hide();
    return [logs, paginationConfig];
  };

  const getEquipmentHistoryLog = async (
    query: Record<string, unknown>,
    limit?: number
  ): Promise<[EquipmentHistoryData[], PaginationConfig]> => {
    LoadingIndicator.fire.show();
    const [auditLog, paginationConfig] = await AuditModel.getAuditEntryLog({
      ...query,
      type: AuditEntryType.DeviceAssignment,
      patientId: patient?._meta?.id!,
      limit: limit || equipmentHistoryPageLimit,
    });

    // resolve devices
    const deviceIds = auditLog.map((log) => {
      return log.deviceId;
    });
    const deviceModel = await DeviceModel.syncMany(deviceIds as string[]);

    let device: DeviceModel | undefined;
    const logs: EquipmentHistoryData[] = auditLog.map((log) => {
      if (log.deviceId) {
        device = deviceModel.find(
          (device) => device?.pluck("_meta")?.id === log.deviceId
        );
      }
      return {
        time: dateFormatter({
          date: log.time.toString(),
          includeTime: true,
          toLocalTimezone: { enabled: true },
        }),
        msn: device?.pluck("msn"),
        iccid: device?.pluck("iccid"),
        assigned: log.assigned ? "Assign" : "Unassign",
      } as EquipmentHistoryData;
    });
    setAllEquipmentLogNotes(logs);
    setEquipmentHistoryPaginationConfig({
      ...paginationConfig,
    });
    LoadingIndicator.fire.hide();
    return [logs, paginationConfig];
  };

  const checkInProfileVariables = (variable: string) =>
    variable in profileVariables ? profileVariables[variable] : variable;

  const checkInVariableKeys = (key: string) =>
    key in variableKeysLookup ? variableKeysLookup[key] : key;

  const formatUnsetVariables = (variableNames: string[]) => {
    const translatedVariablesNames = variableNames.map((variableName: string) =>
      checkInRulesVariables(variableName)
    );
    return `${RESET_TO_DEFAULT} ${translatedVariablesNames.toString()}`;
  };

  const formatSetVariables = (log: AuditLog, rules: Record<string, Rule>) => {
    const { setVariables, ruleId } = log;
    let setVariablesString = "";
    if (setVariables) {
      Object.keys(setVariables).forEach((variable: string) => {
        let formattedString = "";
        if (
          setVariables[variable] &&
          typeof setVariables[variable] === "object"
        ) {
          formattedString += `${checkInRulesVariables(variable)}: `;
          Object.keys(setVariables[variable]).forEach((value: string) => {
            if (
              (setVariables[variable] as Record<string, string>)[value] !== null
            ) {
              formattedString += `${checkInVariableKeys(value)}: ${
                ruleVariableSigns[variable]
              } ${(setVariables[variable] as Record<string, string>)[value]}${
                ruleVariableSymbols[variable]
              }, `;
            }
          });
        } else {
          if (variable === "isEnabled" && ruleId && rules) {
            formattedString += !!setVariables[variable]
              ? `${RULE} ${variableKeysLookup.enabled}: ${rules[ruleId].name} `
              : `${RULE} ${variableKeysLookup.disabled}: ${rules[ruleId].name} `;
          } else {
            formattedString += `${checkInRulesVariables(variable)}: ${
              ruleVariableSigns[variable]
            } ${setVariables[variable]} ${ruleVariableSymbols[variable]}`;
          }
        }
        formattedString += "\n";
        setVariablesString += formattedString;
      });
    }
    return setVariablesString;
  };

  const checkInRulesVariables = (variable: string) =>
    variable in ruleVariables ? ruleVariables[variable] : variable;

  const formatProfileChanges = (
    modifiedProperties: Record<string, string | boolean | object>,
    practices: Practice[],
    providers: Provider[],
    locationTags: Tag[],
    recursiveCall?: boolean,
    parentKey?: string
  ) => {
    let variablesString = "";
    if (modifiedProperties) {
      Object.keys(modifiedProperties).forEach((variable: string) => {
        let formattedString = "";
        if (typeof modifiedProperties[variable] === "object") {
          if (
            !recursiveCall &&
            modifiedProperties[variable] !== null &&
            !isEmpty(modifiedProperties[variable])
          ) {
            formattedString += `\n${checkInProfileVariables(variable)}`;
          }
          formattedString += formatProfileChanges(
            modifiedProperties[variable] as Record<
              string,
              string | boolean | object
            >,
            practices,
            providers,
            locationTags,
            true,
            parentKey ? parentKey : variable
          );
        } else {
          let convertedId;
          if (parentKey === profileVariableKeys.tagIds) {
            const locationTag = locationTags?.find(
              (tag) => tag.id === modifiedProperties[variable]
            );
            convertedId = locationTag?.value;
          }
          if (variable === profileVariableKeys.programId) {
            const program = programs?.find(
              (program) => program.id === modifiedProperties[variable]
            );
            convertedId = program?.name;
          }
          if (variable === profileVariableKeys.providerId) {
            const provider = providers?.find(
              (provider) => provider.id === modifiedProperties[variable]
            );
            convertedId = `${provider?.firstName} ${provider?.lastName}`;
          }
          if (variable === profileVariableKeys.practiceId) {
            const practice = practices?.find(
              (practice) => practice.id === modifiedProperties[variable]
            );
            convertedId = `${practice?.name}`;
          }

          if (
            variable !== "id" &&
            modifiedProperties[variable] !== null &&
            !isEmpty(modifiedProperties[variable])
          ) {
            formattedString += `${checkInProfileVariables(variable)}: ${
              convertedId ? convertedId : modifiedProperties[variable]
            }; `;
          }
        }
        variablesString += formattedString;
      });
    }
    return variablesString;
  };

  const getUsersByKey: (
    actorIds: string[]
  ) => Promise<Record<string, UserModel | number>> = async (
    actorIds: string[]
  ) => {
    let userModel: Array<UserModel | number> = await UserModel.syncMany(
      actorIds
    );
    const usersByKey: Record<string, UserModel | number> = {};
    actorIds.forEach((actorId, index) => {
      const user = userModel[index];
      usersByKey[actorId] = user;
    });
    return usersByKey;
  };

  const resolveActorName = (
    log: AuditLog,
    usersByKey: Record<string, UserModel | number>
  ) => {
    let actorName: string | undefined;
    if (log.actorId && log.actorId in usersByKey) {
      const user = usersByKey[log.actorId];
      if (typeof user === "number" && user === 403) {
        actorName = "Root User";
      }
      if (typeof user !== "number") {
        actorName = `${user.pluck("firstName")} ${user.pluck("lastName")}`;
      }
    }
    return actorName || "System";
  };

  const getFormattedLogObject = (
    log: AuditLog,
    actorName: string | undefined
  ) => {
    return {
      id: log.id,
      time: dateFormatter({
        date: log.time.toString(),
        includeTime: true,
        toLocalTimezone: { enabled: true },
      }),
      actor: actorName || "System",
    };
  };

  const formatPatientProfileLog = async (
    auditLog: AuditLog[],
    practices: Practice[],
    providers: Provider[],
    locationTags: Tag[]
  ) => {
    const actorIds: string[] = auditLog
      .map((log) => {
        if (log.actorId) return `${log.actorId}`;
      })
      .filter(unique) as string[];
    const usersBykey: Record<string, UserModel | number> = await getUsersByKey(
      actorIds
    );
    return auditLog.map((log) => {
      let actorName = resolveActorName(log, usersBykey);
      return {
        ...getFormattedLogObject(log, actorName),
        actor: actorName,
        modifiedProperties: formatProfileChanges(
          log.modifiedProperties ? log.modifiedProperties : {},
          practices,
          providers,
          locationTags
        ),
      } as unknown as PatientModificationsData;
    });
  };

  const formatPatientParamsLog = async (
    auditLog: AuditLog[],
    rules?: Record<string, Rule>
  ) => {
    const actorIds = auditLog.map((log) => `${log.actorId}`).filter(unique);
    const usersBykey: Record<string, UserModel | number> = await getUsersByKey(
      actorIds
    );
    return auditLog.map((log) => {
      let actorName = resolveActorName(log, usersBykey);
      return {
        ...getFormattedLogObject(log, actorName),
        actor: actorName,
        modifiedProperties: log.unsetVariableNames?.length
          ? formatUnsetVariables(log.unsetVariableNames)
          : log.setVariables
          ? formatSetVariables(log, rules ? rules : {})
          : "",
      } as unknown as PatientModificationsData;
    });
  };

  const fetchPractices = async () => {
    if (!!(practices.length <= 0 && organization?.id)) {
      const practiceModels = (
        await PracticeModel.fetchByOrganizationIds([organization!.id!])
      ).map((model) => model.pluckAll());
      setPractices(practiceModels);
      return practiceModels;
    }
    return practices;
  };

  const fetchProviders = async (practices: Practice[]) => {
    if (!!(providers.length <= 0 && practices.length > 0)) {
      const providerModels = (
        await ProviderModel.fetchByPracticeIds(
          practices.map((practice) => practice!.id!)
        )
      ).map((providerModel) => providerModel.pluckAll());
      setProviders(providerModels);
      return providerModels;
    }
    return providers;
  };

  const fetchLocationTags = async (practices: Practice[]) => {
    if (!!(locationTags.length <= 0 && practices.length > 0)) {
      const tags = await TagModel.fetchAllTagsWithIterator({
        type: "location",
        practiceIds: practices.map((practice) => practice.id),
      });
      setLocationTags(tags);
      return tags;
    }
    return locationTags;
  };

  const toggleTableAndFetchLogs = async (type: string, value: boolean) => {
    if (!value) {
      if (type === logTypes.timeLog) {
        await getTimeLogNotes({
          currentPage: 1,
          limit: timeLogPageLimit,
        });
        prevTimeLogPageLimitRef.current = timeLogPageLimit;
      }
      if (type === logTypes.paramMods) {
        await getParamModificationsLog({
          currentPage: 1,
          limit: paramModsPageLimit,
        });
        prevParamModsPageLimitRef.current = paramModsPageLimit;
      }
      if (type === logTypes.profileLog) {
        await getProfileModificationsLog({
          currentPage: 1,
          limit: profileModsPageLimit,
        });
        prevProfileModsPageLimitRef.current = profileModsPageLimit;
      }
      if (type === logTypes.equipmentHistory) {
        await getEquipmentHistoryLog({
          currentPage: 1,
          limit: equipmentHistoryPageLimit,
        });
        prevEquipmentHistoryPageLimitRef.current = equipmentHistoryPageLimit;
      }
    }
    if (type === logTypes.timeLog) setIsTimeLogCollapsed(value);
    if (type === logTypes.paramMods) setIsParamLogCollapsed(value);
    if (type === logTypes.profileLog) setIsProfileModCollapsed(value);
    if (type === logTypes.equipmentHistory)
      setIsEquipmentHistoryCollapsed(value);
  };

  useEffect(() => {
    (async () => {
      LoadingIndicator.fire.show();
      //get patient time logs
      const getTimeLogPromise = getTimeLogNotes({});

      //get patient parameter modification logs
      const getParamModificationsLogPromise = getParamModificationsLog({
        currentPage: 1,
        limit: paramModsPageLimit,
      });

      // get patient profile modification logs
      const getProfileModificationsLogPromise = getProfileModificationsLog({
        currentPage: 1,
        limit: profileModsPageLimit,
      });

      // get patient equipment history
      const getEquipmentHistoryLogPromise = getEquipmentHistoryLog({
        currentPage: 1,
        limit: equipmentHistoryPageLimit,
      });

      await Promise.allSettled([
        getTimeLogPromise,
        getParamModificationsLogPromise,
        getProfileModificationsLogPromise,
        getEquipmentHistoryLogPromise,
      ]);
      LoadingIndicator.fire.hide();
    })();

    prevTimeLogPageLimitRef.current = timeLogPageLimit;
    prevParamModsPageLimitRef.current = paramModsPageLimit;
    prevProfileModsPageLimitRef.current = profileModsPageLimit;
    prevEquipmentHistoryPageLimitRef.current = equipmentHistoryPageLimit;
  }, [organization]);

  useEffect(() => {
    if (
      timeLogPageLimit !== prevTimeLogPageLimitRef.current &&
      prevTimeLogPageLimitRef.current !== undefined
    ) {
      (async () => {
        await getTimeLogNotes({}, timeLogPageLimit);
      })();
    }

    if (
      paramModsPageLimit !== prevParamModsPageLimitRef.current &&
      prevParamModsPageLimitRef.current !== undefined
    ) {
      (async () => {
        await getParamModificationsLog({}, paramModsPageLimit);
      })();
    }

    if (
      profileModsPageLimit !== prevProfileModsPageLimitRef.current &&
      prevProfileModsPageLimitRef.current !== undefined
    ) {
      (async () => {
        await getProfileModificationsLog({}, profileModsPageLimit);
      })();
    }

    if (
      equipmentHistoryPageLimit !== prevEquipmentHistoryPageLimitRef.current &&
      prevEquipmentHistoryPageLimitRef.current !== undefined
    ) {
      (async () => {
        await getEquipmentHistoryLog({}, equipmentHistoryPageLimit);
      })();
    }
  }, [
    timeLogPageLimit,
    paramModsPageLimit,
    profileModsPageLimit,
    equipmentHistoryPageLimit,
  ]);

  const handleTimeLogTablePageLimitChange = async (limit: number) => {
    prevTimeLogPageLimitRef.current = timeLogPageLimit;
    await setTimeLogPageLimit(limit);
  };

  const handleParamModsTablePageLimitChange = async (limit: number) => {
    prevParamModsPageLimitRef.current = paramModsPageLimit;
    await setParamModsLogPageLimit(limit);
  };

  const handleProfileModsTablePageLimitChange = async (limit: number) => {
    prevProfileModsPageLimitRef.current = profileModsPageLimit;
    await setProfileModsLogPageLimit(limit);
  };

  const handleEquipmentHistoryTablePageLimitChange = async (limit: number) => {
    prevEquipmentHistoryPageLimitRef.current = equipmentHistoryPageLimit;
    await setEquipmentHistoryPageLimit(limit);
  };

  return (
    <>
      <div>
        <TimeLogTable
          headers={TimeLogTableHeader}
          data={allTimeLogNotes}
          onNavigate={getTimeLogNotes}
          onPageLimitChange={handleTimeLogTablePageLimitChange}
          pageLimit={timeLogPageLimit}
          paginationConfig={timeLogPaginationConfig}
          isCollapsed={isTimeLogCollapsed}
          setIsCollapsed={toggleTableAndFetchLogs}
          type={logTypes.timeLog}
        />
      </div>
      <div>
        <ParameterModificationsTable
          data={allParamModLogs}
          onNavigate={getParamModificationsLog}
          headers={PatientModificationsTableHeader}
          onPageLimitChange={handleParamModsTablePageLimitChange}
          pageLimit={paramModsPageLimit}
          paginationConfig={parameterModsPaginationConfig}
          isCollapsed={isParamLogCollapsed}
          setIsCollapsed={toggleTableAndFetchLogs}
          type={logTypes.paramMods}
        />
      </div>
      <div>
        <ProfileModificationsLogTable
          data={allProfileModificationLogs}
          onNavigate={getProfileModificationsLog}
          headers={PatientModificationsTableHeader}
          onPageLimitChange={handleProfileModsTablePageLimitChange}
          pageLimit={profileModsPageLimit}
          paginationConfig={profileModsPaginationConfig}
          isCollapsed={isProfileModCollapsed}
          setIsCollapsed={toggleTableAndFetchLogs}
          type={logTypes.profileLog}
        />
      </div>
      <div>
        <EquipmentHistoryTable
          data={allEquipmentLogNotes}
          onNavigate={getEquipmentHistoryLog}
          headers={AuditEquipmentTableHeader}
          onPageLimitChange={handleEquipmentHistoryTablePageLimitChange}
          pageLimit={equipmentHistoryPageLimit}
          paginationConfig={equipmentHistoryPaginationConfig}
          isCollapsed={isEquipmentHistoryCollapsed}
          setIsCollapsed={toggleTableAndFetchLogs}
          type={logTypes.equipmentHistory}
        />
      </div>
    </>
  );
};
