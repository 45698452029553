export interface LoadingConfig {
  /**
   * Size of the loading indicator on the screen
   */
  height: string;
  /**
   * Position of loading indicator on the screen
   */
  position: string;
  /**
   * Set the top edge of the positioned of loading indicator of its nearest positioned ancestor
   */
  top: string;
  /**
   * Set the left edge of the positioned of loading indicator of its nearest positioned ancestor
   */
  left: string;
  /**
   * Set the width of the loading indicator backdrop
   */
  width: string;
  /**
   * Set the background color of loading indicator backdrop
   */
  background: string;
  /**
   * Set the color loading text of indicator
   */
  color: string;
  /**
   * The z-index property specifies the stack order of loading indicator.
   */
  zIndex: number;
}

/**
 * Defualt configuration of loading indicator
 */
export const defaultConfig: React.CSSProperties = {
  height: "100vh",
  position: "fixed",
  top: "0%",
  left: "0%",
  width: "100%",
  background: "#706b6b42",
  color: "white",
  zIndex: 999999,
};

export const FullScreenLoaderBg =
  "radial-gradient(circle, rgba(96,137,219,1) 0%, rgba(33,97,227,1) 55%, rgba(41,83,168,0.9500175070028011) 100%)";
