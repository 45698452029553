import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "@/domain/tags/model/types";
import { TagModel } from "@/domain/tags/model";
import { LocationTable } from "@/domain/tags/view/LocationTable";
import { LocationForm } from "@/domain/tags/view/LocationForm";
import { selectLocationTags } from "@/domain/tags/redux/selectors";
import { selectPractices } from "@/domain/practice/redux/selectors";
import { LoadingDimmer } from "@/components/LoadingDimmer";
import { DEFAULT_PAGINATION_CONFIG } from "@/components/pagination/constants";
import { Header } from "@/components/Header";
import { Modal } from "@/components/modal";
import {
  dispatchSaveLocationTag,
  dispatchSetLocationTags,
} from "@/domain/tags/redux/actions";
import { PaginationConfig } from "@/library/types";
import { FILTER_STRING } from "@/library/constants";

// import styles from './styles.module.scss';

export type Props = {
  collapsed: boolean;
  onToggle: () => void;
};

export const LocationComponent: React.FC<Props> = (props) => {
  const { collapsed, onToggle } = props;

  const [search, setSearch] = useState("");
  const [pending, setPending] = useState(false);
  const [editingTagId, setEditingTagId] = useState<string>();
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sortedState, setSortedState] = useState<any>();
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>(
    DEFAULT_PAGINATION_CONFIG
  );

  const practices = useSelector(selectPractices);
  const locationTags = useSelector(selectLocationTags);
  const dispatch = useDispatch();
  const setLocationTags = (tags: Tag[]) =>
    dispatch(dispatchSetLocationTags(tags));
  const saveTag = (tag: Tag) => dispatch(dispatchSaveLocationTag(tag));

  const editingTag = locationTags.find((tag) => tag._meta.id === editingTagId);

  useEffect(() => {
    const fetch = async () => {
      if (!practices.length) {
        setPaginationConfig(DEFAULT_PAGINATION_CONFIG);
        return;
      }

      const [tags, paginationConfig] = await TagModel.getTagsByType(
        "location",
        {
          practiceIds: practices.map((practice) => practice.id),
          pageLimit,
        }
      );

      setLocationTags(tags);
      setPaginationConfig(paginationConfig);
    };

    fetch();
  }, [practices, pageLimit]);

  const handleAddLocation = () => {};
  const handleEditLocation = (locationId: string) => {
    setEditingTagId(locationId);
    setShowCreateLocationModal(true);
  };

  const handleCloseEditLocationModal = () => {
    setShowCreateLocationModal(false);
    setEditingTagId(undefined);
  };

  const handlePageLimitChange = async (limit: number) => {
    await setPageLimit(limit);
    await execSearch({});
  };

  const execSearch = async (query: Record<string, unknown>): Promise<void> => {
    setSearch((query.name as string) || "");

    if (!practices.length) return;

    setPending(true);

    const params = {
      ...query,
      limit: pageLimit,
      name: search,
      practiceIds: practices.map((practice) => practice.id),
      ...sortedState,
    };

    const [tags, paginationConfig] = await TagModel.getTagsByType(
      "location",
      params
    );

    setLocationTags(tags);
    setPaginationConfig(paginationConfig);
    setPending(false);
  };

  return (
    <>
      <div className="card-body">
        <Header
          entity={{
            name: "location",
            displayName: "Locations",
            selectedEntityLength: locationTags.length,
            totalEntityLength: paginationConfig.totalResources,
          }}
          button={{
            create: {
              shouldDisplay: false,
            },
            toggleCollapse: {
              onToggle,
              collapsed,
              shouldDisplay: true,
            },
          }}
          table={{ pageLimit }}
          search={{
            onSearch: execSearch,
            searchStr: search,
            filterStr: FILTER_STRING,
            filters: { type: undefined, status: undefined },
            placeholder: "Enter location name",
            sortedState: sortedState,
          }}
          filter={{
            onResetState: (state: { search?: string }) => {
              if (state.search) {
                setSearch(state.search);
              }
            },
            // onResetFilters: (filters) => {},
            // onResetSortedState: () => {},
          }}
        />

        <div
          id="location-list"
          className={`container-fluid collapse ${collapsed ? "hide" : "show"}`}
        >
          <LocationTable
            data={locationTags}
            execSearch={execSearch}
            pageLimit={pageLimit}
            paginationConfig={paginationConfig}
            onPageLimitChange={handlePageLimitChange}
            onEditTag={handleEditLocation}
            setSortedState={setSortedState}
            sortedState={sortedState}
          />
        </div>

        <LoadingDimmer pending={pending} />
      </div>

      <Modal
        handleClose={handleCloseEditLocationModal}
        show={showCreateLocationModal}
        heading="Edit Location"
        button="Save Changes"
      >
        {showCreateLocationModal && (
          <LocationForm
            tag={editingTag}
            practiceId={editingTag?.practiceId}
            onClose={handleCloseEditLocationModal}
            onSave={saveTag}
          />
        )}
      </Modal>
    </>
  );
};
