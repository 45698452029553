import React, { useState, useEffect, useRef, FC } from "react";

export interface VirtualVisitTimerProps {
  getElapsedTime?: (seconds: number) => void;
  getElapsedTimeOnReset?: (seconds: number) => void;
  onReset: number;
}
export const VirtualVisitTimer: FC<VirtualVisitTimerProps> = ({
  getElapsedTime,
  getElapsedTimeOnReset,
  onReset,
}) => {
  const [seconds, setSeconds] = useState(0);
  const intervalRef = useRef<number | NodeJS.Timeout>();

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    // Run on component mount.
    if (getElapsedTime) {
      getElapsedTime(seconds);
    }

    // Start the timer when the component loads.
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Clear interval when component unmounts.
    return () => clearInterval(intervalRef.current as number);
  }, []); // Empty dependency array means this effect only runs on mount and unmount.

  useEffect(() => {
    if (onReset) {
      if (seconds > 0 && getElapsedTimeOnReset) {
        getElapsedTimeOnReset(seconds);
      }
      // Reset the timer when the onReset prop changes.
      setSeconds(0);
      clearInterval(intervalRef.current as number);
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current as number);
    };
  }, [onReset]); // Runs whenever onReset prop changes.

  useEffect(() => {
    if (getElapsedTime) {
      getElapsedTime(seconds);
      window.clearInterval(intervalRef.current as number);
    }
  }, [getElapsedTime]);

  useEffect(() => {
    if (seconds && getElapsedTime) {
      getElapsedTime(seconds);
    }
  }, [seconds]);

  return <div>{formatTime(seconds)}</div>;
};

export default VirtualVisitTimer;
