import { Func } from "../../types/callback";
import { Receiver } from "../receiver/receiver";
import { Task } from "./task";

/**
 * Responsible for running clean up tasks only after a Command was successfully executed.
 */
export class CleanTask<T> extends Task<T> {
  private cleanUps!: Func[];

  setCleanUps(cleanUps: Func[]): Task<T> {
    this.cleanUps = cleanUps;
    return this;
  }

  async run(receiver?: Partial<Receiver<T>>): Promise<void> {
    await Promise.all(
      this.cleanUps.map(async (cleanUp: Func) => await cleanUp())
    );
  }

  static make<T>(...cleanUps: Func[]): Task<T> {
    const cleanUpTask: CleanTask<T> = new CleanTask<T>();
    return cleanUpTask.setCleanUps(cleanUps);
  }
}
