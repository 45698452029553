import React, { ChangeEvent, useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { Button } from "rsuite";
import cx from "clsx";

import { Select } from "@/components/_selects/Select";
import { TextArea } from "@/components/_inputs/TextArea";
import {
  AddressPicker,
  defaultAddress,
} from "@/components/_inputs/AddressPicker";
import { mapAccessoryTypeToDisplay } from "@/domain/patient/view/tabs/equipment/constants";
import { DatePicker } from "@/components/_inputs/DatePicker";
import { Label } from "@/components/Label";
import { Address } from "@/library/types/address";
import { SelectOption } from "@/types";
import { Tooltip } from "@/components/Tooltip";
import { Props, KitShipmentFormValues, PickupTime } from "./types";
import {
  VALIDATION_SCHEMA,
  RETURN_METHOD_OPTIONS,
  FULFILLMENT_METHOD_OPTIONS,
  ReturnMethods,
  Workflows,
} from "./constants";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { TextField } from "@mui/material";
/**
 *
 * "Kit Fulfillment Workflow" facilitates the delivery of equipment from an
 * Anelto Customer (Practice/Entity) to an end user (Patient)
 *
 * "Kit Return Workflow" facilitates the return of equipment from an
 * end user (Patient) to an Anelto Customer (Practice/Entity)
 */
export const KitShipmentForm: React.FC<Props> = (props) => {
  const {
    workflow,
    onSubmit,
    onCancel,
    patient,
    selectedDevice,
    serviceLevel,
    hasBackButton,
  } = props;

  const [showDeliveryWarning, setShowDeliveryWarning] = useState(false);

  const peripherals = (props.selectedPeripherals || []).map(
    (accessory) => mapAccessoryTypeToDisplay[accessory]
  );

  const locationTag = selectedDevice?.tags?.find(
    (tag) => tag.type === "location"
  );

  const minPickupDate = DateTime.now()
    .startOf("day")
    .plus({ days: 1 })
    .toJSDate();
  const minDeliveryDate = DateTime.now()
    .startOf("day")
    .plus({ days: 2 })
    .toJSDate();
  const defaultDeliveryDate = DateTime.now()
    .startOf("day")
    .plus({ days: 4 })
    .toJSDate();

  const handleReturnMethodChange = (option: SelectOption | unknown) => {
    form.resetForm();
    form.setFieldValue("workflow", workflow);
    form.setFieldValue("returnMethod", (option as SelectOption).value);
    // Reset all other fields
    form.setFieldValue("pickupDate", "");
    form.setFieldValue("pickupTime", "");
    form.setFieldValue("pickupDate", minPickupDate.toISOString());
    form.setFieldValue("shipToName", "");
    form.setFieldValue("shipToAddress", "");
    form.setFieldValue("deliveryDate", "");
    form.setFieldValue("contactEmail", "");
    form.setFieldValue("sendReplacementBoxes", "");

    const patientName = `${patient.demographics.legalName.firstName} ${patient.demographics.legalName.lastName}`;
    const patientAddress = { ...patient?.address };
    const practiceContactName = patient?.practice?.contact?.name || "";
    const practiceContactEmail = patient?.practice?.contact?.email || "";
    const practiceContactAddress = patient?.practice?.address;
    const locationContactName =
      locationTag?.associatedData?.find((d) => d.key === "contactName")
        ?.value || "";
    const locationContactEmail =
      locationTag?.associatedData?.find((d) => d.key === "contactEmail")
        ?.value || "";

    const locationContactAddress = { ...defaultAddress };
    if (locationTag) {
      for (let i = 0; i < locationTag.associatedData.length; i++) {
        if (locationTag.associatedData[i].key === "address") {
          locationContactAddress["street"] = [
            locationTag.associatedData[i].value,
          ];
        }
        if (locationTag.associatedData[i].key === "state") {
          locationContactAddress["region"] =
            locationTag.associatedData[i].value;
        }
        if (locationTag.associatedData[i].key === "zip") {
          locationContactAddress["postalCode"] =
            locationTag.associatedData[i].value;
        }
        if (locationTag.associatedData[i].key === "city") {
          locationContactAddress["locality"] =
            locationTag.associatedData[i].value;
        }
        if (locationTag.associatedData[i].key === "country") {
          locationContactAddress["country"] =
            locationTag.associatedData[i].value;
        }
      }
    }
    // Pickup method
    const pickupName = patientName;
    const pickupAddress = patientAddress;

    // Defaults
    form.setFieldValue("pickupName", pickupName);
    form.setFieldValue("pickupAddress", pickupAddress);
    form.setFieldValue("pickupTime", "AM");
    form.setFieldValue(
      "contactEmail",
      locationContactEmail || practiceContactEmail
    );
    if ((option as SelectOption).value === ReturnMethods.ship) {
      // Ship method
      const shipToName =
        workflow === Workflows.return
          ? locationContactName || practiceContactName
          : patientName;
      const shipToAddress =
        workflow === Workflows.return
          ? locationContactAddress || practiceContactAddress
          : patientAddress;

      // Defaults
      form.setFieldValue("shipToName", shipToName);
      form.setFieldValue("shipToAddress", shipToAddress);
      form.setFieldValue(
        "contactEmail",
        locationContactEmail || practiceContactEmail
      );
      form.setFieldValue("deliveryDate", defaultDeliveryDate.toISOString());
    }
  };

  const handleAddressChange =
    (key: "pickupAddress" | "shipToAddress") => async (address: Address) => {
      await form.setFieldValue(key, {
        ...address,
        street:
          address.street.length && address.street[0] === ""
            ? []
            : address.street[1] === ""
            ? [address.street[0]]
            : address.street,
      });
      await form.validateField(key);
    };

  const handleTextChange =
    (key: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      form.setFieldValue(key, event.target.value);
    };

  const handleDateChange = (key: string) => (date: Date) => {
    if (!date) return;
    form.setFieldValue(key, date.toISOString());
  };

  const handleRadioChange =
    (key: keyof KitShipmentFormValues, option: "yes" | "no" | PickupTime) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        form.setFieldValue(key, option);
      }
    };

  const form = useFormik<KitShipmentFormValues>({
    initialValues: {
      workflow,
      returnMethod: "",
      pickupDate: "",
      pickupTime: "",
      pickupName: "",
      pickupAddress: "",
      specialInstructions: "",
      shipToName: "",
      shipToAddress: "",
      deliveryDate: "",
      contactEmail: "",
      sendReplacementBoxes: "",
      device: selectedDevice,
    },
    onSubmit,
    validateOnChange: false,
    validationSchema: VALIDATION_SCHEMA,
  });

  const { values } = form;
  const methodOptions =
    workflow === Workflows.fulfillment
      ? FULFILLMENT_METHOD_OPTIONS
      : RETURN_METHOD_OPTIONS;
  const selectedMethodOption = values.returnMethod
    ? methodOptions.find((option) => option.value === values.returnMethod)
    : null;

  useEffect(() => {
    if (
      values.deliveryDate &&
      DateTime.fromISO(values.deliveryDate).diffNow("days").get("days") <= 3
    ) {
      setShowDeliveryWarning(true);
    } else setShowDeliveryWarning(false);
  }, [values.deliveryDate]);

  const renderAddressErrors = (
    nameKey: keyof KitShipmentFormValues,
    addressKey: keyof KitShipmentFormValues
  ) => {
    return form.errors.hasOwnProperty(nameKey) ||
      form.errors.hasOwnProperty(addressKey) ? (
      <ul style={{ marginTop: 14 }}>
        {form.errors.hasOwnProperty(nameKey) ? (
          <li className={globalStyles.danger}>{form.errors[nameKey]}</li>
        ) : null}
        {Object.keys(form.errors[addressKey] || {}).map((key2) => {
          return (
            <li
              key={`formError-${addressKey}-${key2}`}
              className={globalStyles.danger}
            >
              {(form.errors[addressKey] as any)[key2]}
            </li>
          );
        })}
      </ul>
    ) : null;
  };

  return (
    <>
      {
        <div className={cx(styles.box, styles.infoContainer)}>
          <div className={styles.infoRow}>
            <span className={cx(styles.infoLabel, globalStyles.bold)}>
              Device(s):
            </span>
            {serviceLevel ? (
              <span className={styles.infoValue}>
                {serviceLevel}
                {peripherals.length ? ` + ${peripherals.join(", ")}` : ""}
              </span>
            ) : null}
          </div>
          <div className={styles.infoRow}>
            <span className={cx(styles.infoLabel, globalStyles.bold)}>
              MSN:
            </span>
            <span className={styles.infoValue}>
              {selectedDevice?.msn || ""}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={cx(styles.infoLabel, globalStyles.bold)}>
              Patient:
            </span>
            <span className={styles.infoValue}>
              {patient.demographics.legalName.lastName},{" "}
              {patient.demographics.legalName.firstName}
              {patient.demographics.preferredName
                ? ` (${patient.demographics.preferredName})`
                : ""}
            </span>
          </div>
        </div>
      }
      <FormikProvider value={form}>
        <form className={styles.returnKitForm} onSubmit={form.handleSubmit}>
          <div className="row" style={{ marginBottom: 21 }}>
            <div className="col-lg-6">
              <Select
                name="returnMethod"
                label={
                  workflow === Workflows.fulfillment
                    ? "Choose Delivery Method"
                    : "Choose Return Method"
                }
                placeholder={
                  workflow === Workflows.fulfillment
                    ? "Select Delivery Method..."
                    : "Select Return Method..."
                }
                options={methodOptions}
                optional={false}
                onChange={handleReturnMethodChange}
                value={selectedMethodOption}
                hasError={form.errors.hasOwnProperty("returnMethod")}
                errorLabel={form.errors["returnMethod"]}
              />
            </div>
          </div>

          {values.returnMethod ? (
            <div className="row">
              <div className="col-lg-6">
                <div
                  className={cx(
                    styles.box,
                    (form.errors.hasOwnProperty("shipToName") ||
                      form.errors.hasOwnProperty("shipToAddress")) &&
                      styles.hasError
                  )}
                >
                  <AddressPicker
                    title={
                      values.returnMethod === ReturnMethods.ship
                        ? "Ship To Address"
                        : workflow === Workflows.fulfillment
                        ? "Deliver To Address"
                        : "Pickup From Address"
                    }
                    to={
                      values.returnMethod === ReturnMethods.pickup
                        ? values.pickupName
                        : values.shipToName
                    }
                    address={
                      values.returnMethod === ReturnMethods.pickup
                        ? values.pickupAddress || defaultAddress
                        : values.shipToAddress || defaultAddress
                    }
                    onChange={handleAddressChange(
                      values.returnMethod === ReturnMethods.pickup
                        ? "pickupAddress"
                        : "shipToAddress"
                    )}
                    onNameChange={handleTextChange(
                      values.returnMethod === ReturnMethods.pickup
                        ? "pickupName"
                        : "shipToName"
                    )}
                  />
                  {renderAddressErrors("pickupName", "pickupAddress")}
                  {renderAddressErrors("shipToName", "shipToAddress")}
                </div>
              </div>
              <div className="col-lg-6">
                <TextArea
                  name="specialInstructions"
                  label="Special Instructions"
                  onChange={handleTextChange("specialInstructions")}
                />
              </div>
            </div>
          ) : null}

          {((workflow === Workflows.return && values.returnMethod) ||
            (workflow === Workflows.fulfillment &&
              values.returnMethod === ReturnMethods.pickup)) && (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <div className={styles.box}>
                    <h3 className={cx(globalStyles.pageHeading, styles.title)}>
                      {workflow === Workflows.fulfillment
                        ? "Requested Delivery Date and Time"
                        : "Requested Pickup Date and Time"}
                    </h3>

                    <div className="container" style={{ marginTop: 14 }}>
                      <div className="row">
                        <div className="col-xs-6">
                          <DatePicker
                            name="pickupDate"
                            label="Pickup Date"
                            onChange={handleDateChange("pickupDate")}
                            selected={
                              values.pickupDate
                                ? DateTime.fromISO(values.pickupDate).toJSDate()
                                : null
                            }
                            minDate={minPickupDate}
                            hasError={form.errors.hasOwnProperty("pickupDate")}
                          />
                        </div>
                        <div className={"col-xs-6"} style={{ paddingLeft: 14 }}>
                          <Label>
                            {workflow === Workflows.fulfillment
                              ? "Preferred Delivery Time"
                              : "Preferred Pickup Time"}
                          </Label>
                          <div
                            className={cx(
                              styles.radios,
                              form.errors.hasOwnProperty("pickupTime") &&
                                styles.hasError
                            )}
                          >
                            <input
                              id="AM"
                              value="AM"
                              type="radio"
                              name="pickupTime"
                              checked={values.pickupTime === "AM"}
                              onChange={handleRadioChange("pickupTime", "AM")}
                            />{" "}
                            <label htmlFor="AM">AM</label>
                            <input
                              id="PM"
                              value="PM"
                              type="radio"
                              name="pickupTime"
                              checked={values.pickupTime === "PM"}
                              onChange={handleRadioChange("pickupTime", "PM")}
                              style={{ marginLeft: 10 }}
                            />{" "}
                            <label htmlFor="PM">PM</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {workflow === Workflows.fulfillment &&
            values.returnMethod === ReturnMethods.ship && (
              <>
                <div className="row">
                  <div className="col-lg-8">
                    <div className={styles.box}>
                      <h3
                        className={cx(globalStyles.pageHeading, styles.title)}
                      >
                        Requested Delivery Date
                      </h3>
                      <div className={styles.infoRow}>
                        <div className={styles.infoValue}>
                          Ground Shipping with delivery 3-5 business days after
                          processing
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: 14 }}>
                        <div className="col-lg-7">
                          <DatePicker
                            name="deliveryDate"
                            label="Delivery Date"
                            onChange={handleDateChange("deliveryDate")}
                            selected={
                              values.deliveryDate
                                ? DateTime.fromISO(
                                    values.deliveryDate
                                  ).toJSDate()
                                : null
                            }
                            minDate={minDeliveryDate}
                            hasError={form.errors.hasOwnProperty(
                              "deliveryDate"
                            )}
                          />
                        </div>
                      </div>
                      {showDeliveryWarning ? (
                        <div
                          className={cx(
                            styles.infoValue,
                            globalStyles.warning,
                            globalStyles.bold
                          )}
                        >
                          Expedited shipping may incur additional charges
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}

          {workflow === Workflows.return && values.returnMethod ? (
            <div className="row">
              <div className="col-6">
                <div className={styles.box}>
                  <div className={styles.addressPicker}>
                    <h3 className={cx(globalStyles.pageHeading, styles.title)}>
                      Send Replacement Boxes to Patient
                    </h3>
                    <div className="mt-3">
                      {form.errors?.sendReplacementBoxes ? (
                        <Label className={globalStyles.danger}>
                          {form.errors?.sendReplacementBoxes}
                        </Label>
                      ) : null}
                      <div className="col-xs-6">
                        <div
                          className={cx(
                            styles.radios,
                            form.errors.hasOwnProperty(
                              "sendReplacementBoxes"
                            ) && styles.hasError
                          )}
                        >
                          <input
                            id="yes"
                            value="yes"
                            type="radio"
                            name="sendReplacementBoxes"
                            checked={values.sendReplacementBoxes === "yes"}
                            onChange={handleRadioChange(
                              "sendReplacementBoxes",
                              "yes"
                            )}
                          />{" "}
                          <label htmlFor="yes">Yes</label>
                          <input
                            id="no"
                            value="no"
                            type="radio"
                            name="sendReplacementBoxes"
                            checked={values.sendReplacementBoxes === "no"}
                            onChange={handleRadioChange(
                              "sendReplacementBoxes",
                              "no"
                            )}
                            style={{ marginLeft: 10 }}
                          />{" "}
                          <label htmlFor="no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {values.returnMethod ? (
            <div className="row">
              <div className="col-lg-6">
                <div className={styles.box}>
                  <div className={styles.addressPicker}>
                    <h3 className={cx(globalStyles.pageHeading, styles.title)}>
                      Contact Email(s)
                    </h3>
                    <div
                      className="mt-3"
                      data-toggle="tooltip"
                      title="Separate multiple email addresses with a comma"
                    >
                      <TextField
                        label="Email Address(es)"
                        name="contactEmail"
                        value={values.contactEmail}
                        placeholder="one@example.com, two@example.com"
                        autoComplete="off"
                        multiline
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={handleTextChange("contactEmail")}
                        error={form.errors.hasOwnProperty("contactEmail")}
                        helperText={form.errors.contactEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className={styles.actions}>
            <Button appearance="ghost" onClick={onCancel}>
              Cancel
            </Button>
            {hasBackButton && hasBackButton.enabled && (
              <Button appearance="ghost" onClick={hasBackButton.onClick}>
                Back
              </Button>
            )}
            <Button
              type="submit"
              appearance="primary"
              loading={form.isSubmitting}
              style={{ minWidth: 80 }}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};
