export const TimeLogTableHeader: string[] = [
  "Date/Time",
  "User",
  "Time Duration",
  "Billable",
  "Billable Accumulated /n Time",
  "Accumulated Time /n (Billable and Non-Billable)",
];
export const PatientModificationsTableHeader: string[] = [
  "Date/Time",
  "User",
  "Modified Properties",
];

export const AuditEquipmentTableHeader: string[] = [
  "Date/Time",
  "Kit ID",
  "Kit ICCID",
  "Action",
];

export const TABLE_PAGE_LIMIT = 50;

export const ruleVariables: Record<string, string> = {
  highGlucoseLevelDiscrete: "High Glucose Level",
  lowGlucoseLevelDiscrete: "Low Glucose Level",
  fev1Baseline: "FEV1 Baseline",
  pefBaseline: "PEF Baseline",
  lowFEV1PercentageDiscrete: "Low FEV1 Percentage",
  lowPEFPercentageDiscrete: "Low PEF Percentage",
  oxygenSaturationDiscrete: "Oxygen Saturation",
  baselineWeight: "Baseline Weight",
  dailyChangeDiscrete: "Daily Weight Change",
  weeklyChangeDiscrete: "Weekly Weight Change",
  systolicHighBloodPressureDiscrete: "Systolic High Blood Pressure",
  systolicLowBloodPressureDiscrete: "Systolic Low Blood Pressure",
  diastolicHighBloodPressureDiscrete: "Diastolic High Blood Pressure",
  diastolicLowBloodPressureDiscrete: "Diastolic Low Blood Pressure",
  highHeartRateDiscrete: "High Heart Rate",
  lowHeartRateDiscrete: "Low Heart Rate",
  highTemperatureDiscrete: "High Temperature",
  lowTemperatureDiscrete: "Low Temperature",
  isEnabled: "Rule Enabled",
};

export const variableKeysLookup: Record<string, string> = {
  high: "High Alert",
  medium: "Medium Alert",
  low: "Low Alert",
  enabled: "Enabled",
  disabled: "Disabled",
};

export const ruleVariableSymbols: Record<string, string> = {
  highGlucoseLevelDiscrete: "mg/dL",
  lowGlucoseLevelDiscrete: "mg/dL",
  fev1Baseline: "L",
  pefBaseline: "L/min",
  lowFEV1PercentageDiscrete: "%",
  lowPEFPercentageDiscrete: "%",
  oxygenSaturationDiscrete: "%",
  baselineWeight: "lbs",
  dailyChangeDiscrete: "lbs",
  weeklyChangeDiscrete: "lbs",
  systolicHighBloodPressureDiscrete: "mmHg",
  systolicLowBloodPressureDiscrete: "mmHg",
  diastolicHighBloodPressureDiscrete: "mmHg",
  diastolicLowBloodPressureDiscrete: "mmHg",
  highHeartRateDiscrete: "bpm",
  lowHeartRateDiscrete: "bpm",
  highTemperatureDiscrete: "°F",
  lowTemperatureDiscrete: "°F",
};

export const ruleVariableSigns: Record<string, string> = {
  highGlucoseLevelDiscrete: "≥",
  lowGlucoseLevelDiscrete: "≤",
  fev1Baseline: "",
  pefBaseline: "",
  lowFEV1PercentageDiscrete: "≤",
  lowPEFPercentageDiscrete: "≤",
  oxygenSaturationDiscrete: "≤",
  baselineWeight: "",
  dailyChangeDiscrete: "≥",
  weeklyChangeDiscrete: "≥",
  systolicHighBloodPressureDiscrete: "≥",
  systolicLowBloodPressureDiscrete: "≤",
  diastolicHighBloodPressureDiscrete: "≥",
  diastolicLowBloodPressureDiscrete: "≤",
  highHeartRateDiscrete: "≥",
  lowHeartRateDiscrete: "≤",
  highTemperatureDiscrete: "≥",
  lowTemperatureDiscrete: "≤",
};

export const RESET_TO_DEFAULT = "Reset to default:";
export const RULE = "Rule";

export const profileVariables: Record<string, string> = {
  comments: "Comments",
  mrn: "MRN",
  demographics: "Demographics",
  legalName: "Legal Name",
  lastName: "Last Name",
  firstName: "First Name",
  middleName: "Middle Name",
  prefix: "Prefix",
  suffix: "Suffix",
  preferredName: "Preferred Name",
  dateOfBirth: "Date of Birth",
  birthSex: "Birth Sex",
  race: "Race",
  gender: "Gender",
  address: "Address",
  street: "Street",
  locality: "Locality",
  region: "Region",
  postalCode: "Postal Code",
  phone: "Phone",
  primary: "Primary",
  number: "Number",
  programId: "Program",
  providerId: "Provider",
  practiceId: "Practice",
  billingType: "Billing Type",
  assignedUserId: "Watcher ID",
  priority: "Priority",
  designatedRepresentatives: "Emergency Contact",
  name: "Name",
  isSmsAllowed: "SMS Allowed",
  relation: "Relation",
  email: "Email",
  automations: "Automations",
  message: "Message",
  type: "Type",
  when: "When",
  conditions: "Conditions",
  displayName: "Name",
  code: "Code",
  description: "Description",
  ruleVariables: "Rule Variables",
  startDateTime: "Start Date Time",
  time: "Time",
  transition: "Transition",
  toState: "To State",
  state: "State",
  language: "Language",
  country: "Country",
  anthropometry: "Anthropometry",
};

export const profileVariableKeys: Record<string, string> = {
  tagIds: "tagIds",
  programId: "programId",
  providerId: "providerId",
  practiceId: "practiceId",
};

export const logTypes: Record<string, string> = {
  timeLog: "timeLog",
  paramMods: "paramMods",
  profileLog: "profileLog",
  equipmentHistory: "equipmentHistory",
};
