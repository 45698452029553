import { ProviderId } from "@/domain/provider/redux/types";
import { Practice } from "@/domain/practice/model/types";
import { Provider } from "@/domain/provider/model";
import { Language } from "@/library/types/language";
import { InsuranceForm } from "@/domain/patient/view/tabs/manageInsurance/model/insurance";
import { ContactForm } from "@/domain/patient/view/tabs/contact/model/contact";
import { InterdisciplinaryForm } from "@/domain/patient/view/tabs/interdisciplinary/model/interdisciplinary";
import { AllergyForm } from "@/domain/patient/view/tabs/medication/model/allergy";
import { MedicationForm } from "@/domain/patient/view/tabs/medication/model/medication";
import { Note, NoteDisposition } from "@/domain/notes/model/types";
import {
  PatientDevice,
  PatientDeviceAudit,
} from "@/domain/kits/models/device.interface";
import {
  AssessmentSnapshot,
  FlowUnit,
  ObservationOverviewSnapshot,
  ObservationType,
  PressureUnit,
  VolumeUnit,
} from "@/domain/observations/types";
import { BillingType } from "@/library/types/billing";
import {
  PatientState,
  PatientStateHistoryItem,
} from "@/library/types/patientState";
import { RuleOverrides } from "@/domain/rules/model/types";
import { Tag } from "@/domain/tags/model/types";
import { Automation } from "@/domain/automation/model/types";
import { FILTER_TYPES } from "../view/PatientsPage/constants";

export interface PatientCondition {
  id?: string;
  type: string;
  icd10: {
    code: string;
    description: string;
  };
  comment?: string;
  displayName: string | undefined;
  resolved?: string | Date | null;
}

export interface PatientEncounter {
  id?: string;
  startDateTime?: string | null;
  endDateTime?: string | null;
}

export interface PatientLifecycle {
  history: PatientStateHistoryItem[];
  state: PatientState;
}

export interface Patient {
  userId?: string | null;
  id?: string | undefined;
  mrn?: string | null;
  programId?: string | null;
  billingType?: BillingType | null;
  encounter?: PatientEncounter;
  lifecycle: PatientLifecycle;
  providerId: string;
  provider?: Provider;
  practiceId: string;
  practice?: Practice;
  facilityId?: string;
  demographics: {
    legalName: {
      firstName: string;
      lastName: string;
      middleName: string;
      prefix: string;
      suffix: string;
    };
    preferredName?: string;
    pronouns?: string;
    dateOfBirth: string;
    birthSex: string;
    gender?: string;
    race?: string;
    ethnicity?: string;
    language?: Language["abbreviation"];
  };
  address: {
    street: string[];
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  email?: string;
  phone: {
    primary: {
      number: string;
      isSmsAllowed: boolean;
    };
    secondary?: {
      number: string;
      isSmsAllowed: boolean;
    };
  };
  priority?: string;
  conditions: PatientCondition[];
  anthropometry: {
    height?: { value: string; unit: string };
    weight?: { value: string; unit: string };
  };
  height?: { value: string; unit: string };
  weight?: { value: string; unit: string };
  designatedRepresentatives: Array<{
    name: string;
    email?: string;
    phone: {
      primary: {
        number: string;
        isSmsAllowed: boolean;
      };
    };
    relation?: string;
  }>;
  _meta?: {
    id: string;
  };
  assignedUserId?: string;
  assignedUserName?: string;
  insurance?: InsuranceForm[];
  contacts?: ContactForm[];
  interdisciplinaryTeam?: InterdisciplinaryForm[];
  allergies?: AllergyForm[];
  medications?: MedicationForm[];
  notes?: Note[];
  billing?: PatientBilling;
  reimbursableEvents?: string;
  comments?: string | null;
  health?: HealthSnapshot;
  primaryInsurance?: string[];
  secondaryInsurance?: string[];
  primaryCondition?: string;
  secondaryCondition?: string;
  deviceInfo?: PatientDevice[];
  tagIds: string[];
  ruleVariables: Record<string, RuleOverrides>;
  lastReviewedOn: string | null;
  watchingUserIds?: string[];
  programDuration?: string | null;
  otherProgramDuration?: string | null;
  program?: string | null;
  location?: string;
  tags?: Tag[];
  automations?: Array<Automation> | null;
  sortScore: number;
  deviceHistory?: PatientDeviceAudit[];
}

export interface PatientForm {
  id: string | undefined;
  mrn?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  prefix: string;
  suffix: string;
  preferredName: string;
  birthSex: string;
  gender: string;
  race: string;
  dateOfBirth: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  email?: string;
  primaryPhone: string;
  secondaryPhone?: string;
  priority?: string;
  height: string;
  heightUnit: string;
  weight: string;
  weightUnit: string;
  ethnicity: string;
  representativesFirstName: string;
  representativesLastName: string;
  representativesEmail: string;
  representativesPhone: string;
  representativesAllowSms: boolean;
  representativesPhoneType: string;
  representativesRelation: string;
  condition?: Patient["conditions"];
  emergencyContact?: {
    firstName: string;
    lastName: string;
    relation: string;
    email: string;
    primaryPhone: string;
    allowSms: boolean;
  }[];
  allowSMS: {
    primary: boolean;
    secondary: boolean | undefined;
  };
  diagnosis: PatientConditionForm[];
  providerId: ProviderId;
  language: string;
  careTeam?: string;
  programStatus?: string;
  PRIMARY?: string;
  Practice?: string;
  billingType: string;
  location?: string;
  programId?: string | null;
  encounter?: PatientEncounter;
  programDuration?: string | null;
  otherProgramDuration?: string | null;
}

export interface PatientConditionForm {
  id?: string;
  label: string;
  required: boolean;
  displayName: string;
  icdCode: { label: string; value: string };
  type?: string;
  resolvedDate: string | Date | null;
  comment?: string;
  hasChanged?: boolean;
}

export interface PatientCondition {
  id?: string;
  type: string | PatientConditionType;
  icd10: {
    code: string;
    description: string;
  };
  displayName: string | undefined;
  resolved?: string | Date | null;
  comment?: string;
}

export enum PatientConditionType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface PatientFilters {
  programs?: string[];
  status?: string[];
  assignedUserIds?: string[];
  watchingUserIds?: string[];
  providerIds?: string[];
  practiceIds?: string[];
  alert?: string;
  tagIds?: string[];
  programIds?: string[];
}

export interface FilterItemDetail {
  type: FILTER_TYPES;
  description: string;
  value: string;
}

/**
 * User-specific patient priority level.
 */
export enum PatientPriority {
  High = "HIGH",
  Medium = "MEDIUM",
  Low = "LOW",
  None = "NONE",
}

/**
 * Stores value and unit data about a quantitative measurement.
 */
export type Measurement<U extends string> = {
  value: number;
  unit: U;
};

/**
 * A quantitative measure of length.
 */
export type Length = Measurement<"in" | "cm">;

/**
 * A quantitative measure of weight.
 */
export type Weight = Measurement<"lbs" | "kg">;

export interface PatientObservation {
  id: string;
  patientId: string;
  legacyDocumentId?: string | null;
  manualNoteId?: string | null;
  accessory?: {
    id: string;
    interfaceType: "BLUETOOTH" | null;
  };
  systolic?: {
    value: number;
    unit: `${PressureUnit.mmhg}` | `${PressureUnit.kpa}`;
  };
  diastolic?: {
    value: number;
    unit: `${PressureUnit.mmhg}` | `${PressureUnit.kpa}`;
  };
  fev1?: {
    value: number;
    unit: `${VolumeUnit.Liters}`;
  };
  pef?: {
    value: number;
    unit: `${FlowUnit.LitersPerMinute}`;
  };
  value?: number;
  unit?: string;
  type: ObservationType;
  _meta: {
    id: string;
    created: string;
  };
  severityScore?: number;
  timeOfObservation?: string;
  disposition?: NoteDisposition;
  source?: "ACCESSORY" | "CLINICIAN_ENTRY" | "PATIENT_ENTRY";
}

export interface PatientBilling {
  cpt99453: {
    training: boolean;
    reimbursableEvents: number;
  };
  cpt99454: {
    currentDays: number;
    eligibilityDate?: string;
    observedDays: number;
    periodStart?: string;
    periodEnd?: string;
    reimbursableEvents: number;
  };
  cpt99457: {
    currentMinutes: number;
    directPatientContact: boolean;
    reimbursableEvents: number;
  };
  cpt99458: {
    currentMinutes: number;
    currentMinuteBuckets: {
      bucket21: number;
      bucket41: number;
      bucket61: number;
    };
    reimbursableEvents: number;
  };
}

export enum PatientEndpoints {
  Modify = "modify",
  AssignUser = "assign-user",
  ModifyBasicInfo = "modify-basic-info",
}

export interface HealthSnapshot {
  alertScore: number;
  assessment?: AssessmentSnapshot;
  hasDeviceCommunication: boolean;
  hasIncompleteNotes: boolean;
  observations: ObservationOverviewSnapshot;
}

export enum AlertScoreColor {
  Red = "red",
  Yellow = "yellow",
  Green = "green",
  White = "white",
  Black = "black",
}

export enum PatientTableType {
  Assignment = "assignment",
  Clinical = "clinical",
  Admin = "admin",
}

export enum AssignedBy {
  Patient = "Patient",
  CareTeam = "CareTeam",
}
