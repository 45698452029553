import { AnyAction } from "redux";
import produce from "immer";

import { LOGOUT } from "@/library/constants";
import { AssessmentState } from "./types";
import * as C from "./constants";

export const INITIAL_STATE: AssessmentState = {
  assessmentsByPatientId: {},
};

export const assessmentReducer = produce(
  (state: AssessmentState, action: AnyAction) => {
    switch (action.type) {
      case "persist/REHYDRATE": {
        if (!action.payload) break;

        const { assessment: assessmentState = {} } = action.payload;
        const { assessmentsByPatientId } = assessmentState;

        // state.assessmentsByPatientId = assessmentsByPatientId || {};

        state = {
          assessmentsByPatientId: {},
        };

        break;
      }

      case C.SET_PATIENT_ASSESSMENTS: {
        const { patientId, assessments } = action.payload;
        state.assessmentsByPatientId[patientId] = assessments;

        break;
      }

      case LOGOUT:
        return INITIAL_STATE;

      default:
        return state;
    }
  },
  INITIAL_STATE
);
