import React, { useEffect, useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  chartConstants,
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { ObservationUnits } from "@/domain/patient/model/constants";
import {
  biometryEntryMethod,
  parseTooltipDate,
} from "@/domain/patient/view/tabsNew/monitoring/helpers";
import {
  getAlertLevelColor,
  getAlertLevelColorBG,
  getSeverityAlertLevel,
  getSeverityAlertLevelNew,
} from "@/domain/notes/model/constants";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";
import { sortObservationArrayByDate } from "../helper";
import { NoDataAvailable } from "../../tabsNew";
import { ChartProps } from "../types";
import { TIMEOUT_DATE_CHANGE } from "../../tabsNew/monitoring/constants";
import { makeChartOptionsNew } from "../constants";

export const WeightChart: React.FC<ChartProps> = (props) => {
  const { observationData, patientDetail } = props;
  const weights = observationData;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);
  const [allowChartUpdate, setAllowChartUpdate] = useState(false);

  useEffect(() => {
    setAllowChartUpdate(true);
    window.setTimeout(() => {
      setAllowChartUpdate(false);
    }, TIMEOUT_DATE_CHANGE);
  }, [isoStartDate, isoEndDate]);

  let weightAverage = 0;
  let weightAggregate = 0;
  weights.map((weight) => {
    weightAggregate += weight.value ?? 0;
  });
  weightAverage = weightAggregate / weights.length;
  sortObservationArrayByDate(weights);

  const chartConfig = useMemo(() => {
    return makeChartOptionsNew({
      chart: {
        spacingLeft: 10,
        spacingRight: 10,
        marginTop: 40,
      },
      yTitle: "",
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject) {
          let point = this.points ? this.points[0] : this.point;
          return parseTooltipDate(
            ObservationType.Weight,
            this,
            ObservationsUnitAbbrevation.WEIGHT,
            "",
            point.series?.userOptions
          );
        },
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
        labels: {
          format: "{value:%m/%d}",
        },
        gridLineWidth: 1,
      },
      yAxis: {
        gridLineDashStyle: "longdash",
        plotLines: [
          {
            color: "#1A91FF",
            value: weightAverage,
            width: "1",
            zIndex: 4,
          },
        ],
      },
      series: [
        {
          name: "Weight",
          color: chartConstants.COLOR.Line,
          marker: {
            symbol: "circle",
          },
          data: weights.map((wt) => {
            const { severityScore } = wt;
            const source = wt?.source ?? null;
            if (wt.value && wt.unit === ObservationUnits.Kilograms) {
              const poundsInOneKg = 2.205;
              wt.value = wt.value * poundsInOneKg;
            }
            return {
              x: Date.parse(
                new Date(
                  wt.timeOfObservation ? wt.timeOfObservation : wt._meta.created
                ).toISOString()
              ),
              y: wt.value,
              marker: {
                fillColor: getAlertLevelColorBG(
                  getSeverityAlertLevelNew(severityScore)
                ),
              },
              source,
            };
          }),
          showInLegend: false,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
    });
  }, [weights, patientDetail, isPrinting]);

  return (
    <div>
      {weights.length ? (
        <LineChart
          containerProps={{ id: "weight-chart", className: "graphChart" }}
          options={chartConfig.options}
          allowChartUpdate={allowChartUpdate}
        />
      ) : (
        <NoDataAvailable className="noDataAvailable" />
      )}
    </div>
  );
};
