import { FunctionComponent } from "react";
import { Button } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

type CreateButtonProps = {
  className: string;
  handleClick: () => any;
  text: string;
};

export const CreateEntityButton: FunctionComponent<CreateButtonProps> = ({
  className,
  handleClick,
  text,
}) => {
  const isPatientSlidingPaneEnabled =
    _env_.ENABLE_PATIENT_SLIDING_PANE === "true";

  const appearance: TypeAttributes.Appearance = isPatientSlidingPaneEnabled
    ? "primary"
    : "ghost";
  const color: TypeAttributes.Color | undefined = isPatientSlidingPaneEnabled
    ? "violet"
    : undefined;

  return (
    <div
      className={className}
      style={{
        padding: "0px !important",
        minWidth: 130,
      }}
    >
      <form className="form-inline">
        <Button appearance={appearance} color={color} onClick={handleClick}>
          <span style={{ fontSize: "14px" }}>{text}</span>
        </Button>
      </form>
    </div>
  );
};
