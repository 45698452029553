import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Attributes, Model } from "@/library/model";
import { ModelCollection } from "@/library/model/model.collection";
import { RCAResourceActions } from "@/library/core/config/actions";
import { PatientSerializer } from "@/library/serializers/patient/patient.serializer";
import { Http } from "@/library/core/api/http";
import { MODEL_SYNCED } from "@/library/constants";
import { Iterator } from "@/library/iteration/iterator";
import { ObservationModel } from "@/domain/observations/model";
import { PatientId } from "@/domain/patient/redux/types";
import {
  ProgramActions,
  ProgramType,
} from "@/domain/patient/view/form/manageProgram/types";
import { PatientDeserializer } from "@/library/deserializers/patient/patient.deserializer";
import {
  Patient,
  PatientForm,
  PatientEndpoints,
  PatientObservation,
} from "./types";
import { PatientTransition } from "@/domain/patient/view/form/manageState/types";
import { Note } from "@/domain/notes/model/types";
import {
  PostSetParameterOverrides,
  PostUnsetParameterOverrides,
} from "@/domain/organization/model/types";
import { NoteModel } from "@/domain/notes/model";
import { PaginationConfig } from "@/library/types";

export class PatientModel extends Model<Patient> {
  static readonly path = "patients";
  /**
   * Makes a new instance of PatientModel. It requires only the attributes
   * that would be used to instantiate new Patient Model.
   *
   * @param attributes
   */
  static make(attributes: Patient): PatientModel {
    return new PatientModel(
      new Attributes<Patient>(attributes),
      new RemoteCareAPISync<Patient>(PatientModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of patients using the provided resource url and a deserializer.
   * The entire patients list can be accessed in the models member of the Collection class.
   */
  static makePatientCollection(): ModelCollection<PatientModel, Patient> {
    return new ModelCollection<PatientModel, Patient>(
      `${RemoteCareAPISync.host}/${PatientModel.path}`,
      (entity: Patient) => this.deserialize(entity)
    );
  }

  /**
   * Sync patient by Id.
   * @param id
   */
  static sync = async (
    id: string,
    query?: { [key: string]: any }
  ): Promise<PatientModel> => {
    const queryParam =
      "?includeBilling=1&includeHealth=1&" +
      (query
        ? Object.keys(query || {})
            .map((q) => `${q}=${query[q]}`)
            .join("&")
        : "");
    return this.deserialize(
      (
        await new RemoteCareAPISync<Patient>(PatientModel.path).fetch(
          id + queryParam
        )
      ).data
    );
  };

  /**
   * Sync patients by Ids.
   *
   * @param id
   */
  static syncMany = async (ids: string[]): Promise<PatientModel[]> =>
    RemoteCareAPISync.concurrently(ids, PatientModel.path, this.make);

  /**
   * Takes a form data and serializes it based on intended RemoteCareAPI Resource action.
   *
   * @param data
   * @param action
   */
  static serialize = (
    data: PatientForm,
    action: RCAResourceActions
  ): PatientModel => {
    return PatientSerializer.for(action, data).serialize();
  };

  static deserialize = (data: Patient) =>
    PatientDeserializer.for(data, PatientModel.make).deserialize();

  /**
   * Takes patient's data and send it to the given API endpoint
   * @param data <T> Generic
   * @param endpoint (optional)
   */
  async modifyPatient<T extends Patient, K extends keyof T>(
    data: { [key in K]: T[K] },
    endpoint?: string
  ) {
    await Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${
        this.pluck("_meta")?.id
      }/actions/${endpoint || PatientEndpoints.Modify}`,
      data
    );
  }

  /**
   * create patient
   * @param data
   */
  static async createPatient(data: Patient) {
    return await Http().post(`${RemoteCareAPISync.host}/patients`, data);
  }

  /**
   * Set tag on patient
   * @param patientId string
   * @param data { set?: string[]; delete?: string[] }
   */
  static async assignTags(
    patientId: PatientId,
    data: { set?: string[]; delete?: string[] }
  ) {
    await Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/actions/assign-tags`,
      data
    );
  }

  /**
   * Assigns patients watchlist
   *
   * @param patientId
   * @param data
   */
  static async assignWatchers(
    patientId: PatientId,
    data: { set?: string[]; delete?: string[] }
  ) {
    await Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/actions/assign-watchers`,
      data
    );
  }

  /**
   * Fetch patient by assigned users id
   * @param ids
   * @returns
   */
  static async fetchByAssignedUserIds(ids: string[]): Promise<PatientModel[]> {
    if (!ids.length) {
      return new Promise((resolve) => resolve([]));
    }

    const patientCollection = await this.makePatientCollection();
    patientCollection.on(MODEL_SYNCED, () => {});
    patientCollection.withParams({
      assignedUserIds: ids,
      includeTagInfo: true,
    });

    const iterator: Iterator<PatientModel, Patient> =
      await patientCollection.getMany();
    return iterator.getAll();
  }

  /**
   * Fetch patients by practice ids
   * @param ids
   * @returns
   */
  static async fetchByPracticeIds(ids: string[]): Promise<PatientModel[]> {
    if (!ids.length) {
      return new Promise((resolve) => resolve([]));
    }

    const patientCollection = await this.makePatientCollection();
    patientCollection.on(MODEL_SYNCED, () => {});
    patientCollection.withParams({
      practiceIds: ids,
      includeTagInfo: true,
    });

    const iterator: Iterator<PatientModel, Patient> =
      await patientCollection.getMany();
    return iterator.getAll();
  }

  /**
   * Can modify patient insurance
   * @param insurance
   */
  static async syncObservations(
    patientId: string,
    startDate: string,
    endDate: string
  ): Promise<ObservationModel[]> {
    const observationCollection: ModelCollection<
      ObservationModel,
      PatientObservation
    > = await ObservationModel.sync(patientId);
    observationCollection.on(MODEL_SYNCED, () => {});
    observationCollection.withParams({
      startDate,
      endDate,
    });
    const observationIterator: Iterator<ObservationModel, PatientObservation> =
      await observationCollection.getMany();
    return observationIterator.getAll();
  }

  get enrollmentDate(): string | null {
    return "Find enrollment date here with patient new lifecycle";
  }

  async setProgramStatus(program: {
    type: ProgramType;
    transition: ProgramActions;
  }) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${
        this.pluck("_meta")?.id
      }/actions/set-program-status`,
      { ...program }
    );
  }

  async updateLifecycleState(transition: { transition: PatientTransition }) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${
        this.pluck("_meta")?.id
      }/actions/transition`,
      transition
    );
  }

  /**
   * Get observation / chart details
   * @param patientId string
   * @param startDate string
   * @param endDate string
   * @param types string[] | undefined;
   *
   * @param params
   */
  static async getNotes(
    patientId: string,
    startDate: string,
    endDate: string,
    types?: string[],
    params = {}
  ): Promise<[Note[], PaginationConfig]> {
    const noteCollection: ModelCollection<NoteModel, Note> =
      NoteModel.syncByPatientId(patientId);
    noteCollection.on(MODEL_SYNCED, () => {});
    const paginationConfig = await noteCollection
      .withParams({
        startDate,
        endDate,
        types,
        ...params,
      })
      .fetch();

    const notes = (noteCollection.container || []).map((note: NoteModel) =>
      note.pluckAll()
    );
    return [notes, paginationConfig];
  }

  async setReviewed() {
    return Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${
        this.pluck("_meta")?.id
      }/actions/set-reviewed`
    );
  }

  static async setParameterOverrides(
    patientId: string,
    payload: PostSetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/actions/set-parameter-override`,
      payload
    );
  }

  static async unsetParameterOverrides(
    patientId: string,
    payload: PostUnsetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PatientModel.path}/${patientId}/actions/unset-parameter-override`,
      payload
    );
  }
}
