import { ProviderId } from ".";
import {
  CREATE_PROVIDER,
  SET_PROVIDERS,
  SET_PROVIDERS_PAGINATIONCONFIG,
  SET_PROVIDERS_PAGE_LIMIT,
  SELECT_PROVIDER,
  DESELECT_PROVIDER,
  SET_TARGET_PROVIDER,
  SELECT_ALL_PROVIDERS,
  DESELECT_ALL_PROVIDERS,
  SET_PROVIDER_FILTERS,
  SET_PROVIDER_SORTED_STATE,
  SET_ACTIVE_PROVIDER,
  UNSET_ACTIVE_PROVIDER,
} from "@/library/constants";
import { PaginationConfig } from "@/library/types";
import { Provider, ProviderFilters } from "../model";
import { ProviderSortedState } from "./types";

export const createProvider = (provider: Provider) => ({
  type: CREATE_PROVIDER,
  payload: provider,
});

export const setProviders = (provider: Provider[]) => ({
  type: SET_PROVIDERS,
  payload: provider,
});

export const setProviderSortedState = (payload: ProviderSortedState) => {
  return {
    type: SET_PROVIDER_SORTED_STATE,
    payload: payload,
  };
};

export const selectProvider = (provider: Provider) => ({
  type: SELECT_PROVIDER,
  payload: provider,
});

export const deselectProvider = (provider: Provider) => ({
  type: DESELECT_PROVIDER,
  payload: provider,
});

export const selectAllProviders = () => ({
  type: SELECT_ALL_PROVIDERS,
});

export const deselectAllProviders = () => ({
  type: DESELECT_ALL_PROVIDERS,
});

export const dispatchSetTargetProvider = (id: ProviderId) => ({
  type: SET_TARGET_PROVIDER,
  payload: id,
});

export const dispatchSetActiveProvider = (provider: Provider) => ({
  type: SET_ACTIVE_PROVIDER,
  payload: provider,
});

export const dispatchUnActiveProvider = (provider: Provider) => ({
  type: UNSET_ACTIVE_PROVIDER,
  payload: provider,
});

export const setProvidersPaginationConfig = (
  paginationConfig: PaginationConfig
): { type: string; payload: PaginationConfig } => ({
  type: SET_PROVIDERS_PAGINATIONCONFIG,
  payload: paginationConfig,
});

export const setProviderPageLimit = (
  providerPageLimit: number
): { type: string; payload: number } => ({
  type: SET_PROVIDERS_PAGE_LIMIT,
  payload: providerPageLimit,
});

export const setProviderFilters = (
  filters: ProviderFilters
): { type: string; payload: ProviderFilters } => ({
  type: SET_PROVIDER_FILTERS,
  payload: filters,
});
