import { AnyAction } from "redux";
import produce from "immer";

import { LOGOUT } from "@/library/constants";
import { RulesState } from "./types";
import * as C from "./constants";

export const INITIAL_STATE: RulesState = {
  rulesByPatientId: {},
  rulesByOrganizationId: {},
  rulesByPracticeId: {},
  rulesByProviderId: {},
};

export const rulesReducer = produce((state: RulesState, action: AnyAction) => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (!action.payload) break;

      const { rules: rulesState = {} } = action.payload;
      const {
        rulesByPatientId,
        rulesByOrganizationId,
        rulesByPracticeId,
        rulesByProviderId,
      } = rulesState;

      state.rulesByPatientId = rulesByPatientId || {};
      state.rulesByOrganizationId = rulesByOrganizationId || {};
      state.rulesByPracticeId = rulesByPracticeId || {};
      state.rulesByProviderId = rulesByProviderId || {};

      break;
    }

    case C.SET_PATIENT_RULES: {
      const { patientId, rules } = action.payload;

      state.rulesByPatientId[patientId] = rules;
      break;
    }

    case C.SET_ORGANIZATION_RULES: {
      const { organizationId, rules } = action.payload;

      state.rulesByOrganizationId[organizationId] = rules;
      break;
    }

    case C.SET_PRACTICE_RULES: {
      const { practiceId, rules } = action.payload;

      state.rulesByPracticeId[practiceId] = rules;
      break;
    }

    case C.SET_PROVIDER_RULES: {
      const { providerId, rules } = action.payload;

      state.rulesByProviderId[providerId] = rules;
      break;
    }

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}, INITIAL_STATE);
