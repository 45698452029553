import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Track } from "@/components/virtualVisit/track";

export interface ParticipantProps {
  name: string;
  participant: any;
  localParticipant?: any;
  room?: any;
  isMicMutted: boolean;
  width?: string;
  height?: string;
}
export const Participant: FC<ParticipantProps> = ({
  room,
  name,
  participant,
  localParticipant,
  isMicMutted,
  width,
  height,
}) => {
  const existingPublications = Array.from(participant.tracks.values());
  const existingTracks = existingPublications.map(
    (publication: any) => publication.track
  );
  const nonNullTracks = existingTracks.filter((track) => track !== null);
  const [tracks, setTracks] = useState(nonNullTracks);
  const addTrack = (track: any) => {
    if (tracks.length === 2) {
      setTracks([]);
    }
    setTracks([...tracks, track]);
  };
  useEffect(() => {
    if (!localParticipant) {
      participant.on("trackSubscribed", (track: any) => addTrack(track));
    }
  }, [addTrack, localParticipant, participant]);

  return (
    <Box>
      {tracks.map((track: any) => (
        <Track
          width={width}
          height={height}
          isMicMutted={isMicMutted}
          key={track}
          track={track}
        />
      ))}
    </Box>
  );
};
