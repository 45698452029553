import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { Attributes, Model } from "@/library/model";
import { deserialize } from "@/library/model";
import { ModelCollection } from "@/library/model/model.collection";
import { PracticeSerializer } from "@/library/serializers/practice";
import { RCAResourceActions } from "@/library/core/config/actions";
import { MODEL_SYNCED } from "@/library/constants";
import { Iterator } from "@/library/iteration/iterator";
import { isEmptyArray } from "@/util/isEmptyArray";
import { Practice, PracticeForm, SupportiveInformation } from "./types";
import { Http } from "@/library/core/api/http";
import {
  PostSetParameterOverrides,
  PostUnsetParameterOverrides,
} from "@/domain/organization/model/types";

export class PracticeModel extends Model<Practice> {
  static readonly path = "practices";

  /**
   * Makes a new instance of PracticeModel. It requires only the attributes
   * that would be used to instatiate new Practice Model.
   *
   * @param attributes
   */
  static make(attributes: Practice): PracticeModel {
    return new PracticeModel(
      new Attributes<Practice>(attributes),
      new RemoteCareAPISync<Practice>(PracticeModel.path),
      new EventEmitter()
    );
  }

  static async fetchByOrganizationIds(ids: string[]): Promise<PracticeModel[]> {
    if (isEmptyArray(ids)) {
      return new Promise((resolve) => resolve([]));
    }

    const practiceCollection: ModelCollection<PracticeModel, Practice> =
      PracticeModel.makePracticeCollection();
    practiceCollection.on(MODEL_SYNCED, () => {});
    practiceCollection.withParams({
      organizationIds: ids,
    });
    const iterator: Iterator<PracticeModel, Practice> =
      await practiceCollection.getMany();
    const allPracticeModels: PracticeModel[] = await iterator.getAll();

    return allPracticeModels;
  }

  /**
   * Makes a list of practices using the provided resource url and a deserializer.
   * The entire practice list can be accessed in the models member of the Collection class.
   */
  static makePracticeCollection(): ModelCollection<PracticeModel, Practice> {
    return new ModelCollection<PracticeModel, Practice>(
      `${RemoteCareAPISync.host}/${PracticeModel.path}`,
      (entity: Practice) => deserialize(entity, PracticeModel.make)
    );
  }

  /**
   * Sync practice by Id.
   * @param id
   */
  static async sync(id: string): Promise<PracticeModel> {
    const practice: Practice = (
      await new RemoteCareAPISync<Practice>(PracticeModel.path).fetch(id)
    ).data;
    return deserialize(practice, PracticeModel.make);
  }

  /**
   * Sync practices by Ids.
   *
   * @param id
   */
  static syncMany = async (ids: string[]): Promise<PracticeModel[]> =>
    RemoteCareAPISync.concurrently(ids, PracticeModel.path, PracticeModel.make);

  /**
   * Takes a form data and serializes it based on intended RemoteCareAPI Resource action.
   *
   * @param data
   * @param action
   */
  static serialize = (
    data: PracticeForm,
    action: RCAResourceActions
  ): PracticeModel => {
    return PracticeSerializer.for(action, data).serialize();
  };

  static updateSupportInformation = async (
    practiceId: string,
    supportInformation: SupportiveInformation
  ) => {
    const path = `${RemoteCareAPISync.host}/${PracticeModel.path}/${practiceId}/actions/set-support-info`;
    await Http().post(path, supportInformation);
  };

  static async setParameterOverrides(
    orgId: string,
    payload: PostSetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PracticeModel.path}/${orgId}/actions/set-parameter-override`,
      payload
    );
  }

  static async unsetParameterOverrides(
    orgId: string,
    payload: PostUnsetParameterOverrides
  ) {
    return Http().post(
      `${RemoteCareAPISync.host}/${PracticeModel.path}/${orgId}/actions/unset-parameter-override`,
      payload
    );
  }
}
