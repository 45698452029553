import React from "react";
import { connect } from "react-redux";
import RSModal from "rsuite/Modal";

import { Modal } from "@/components/modal";
import { OrganizationFormComponent } from "@/domain/organization/view/form";
import { OrganizationTable } from "@/domain/organization/view/table";
import { CreateEntityButton } from "@/domain/organization/view/header/create-button";
import { PracticeForm } from "@/domain/practice/view/PracticeForm";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";
import {
  setOrganizationsPaginationConfig,
  setOrganizationPageLimit,
  setOrganizationsSearchFilters,
  setOrganizationsSortedState,
} from "@/domain/organization/redux/actions";
import { setOrganizationsThunk } from "@/domain/organization/redux/middleware";
import { FILTER_STRING, MODEL_SYNCED } from "@/library/constants";
import {
  PaginationConfig,
  OrganizationQuery,
  OrganizationsFilter,
  OrganizationsSortedState,
} from "@/library/types";
import { Practice } from "@/domain/practice/model/types";
import { ModelCollection } from "@/library/model";
import { RCAResourceActions } from "@/library/core/config/actions";
import { Header } from "@/components/Header";
import { SetOrganzationDispatch } from "@/domain/organization/redux/types";
import { SortQuery } from "@/components/table/head/sortable";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Props, State } from "./types";
import "@/domain/organization/organization.styles.css";
import { OrganizationRules } from "@/domain/rules/view/OrganizationRules";
import { PathwayContainer } from "@/components/pathway/container";

class Component extends React.PureComponent<Props, State> {
  state: Readonly<State> = {
    patientOptions: [],
    filter: false,
    search: "",
    reset: false,
    filters: {
      type: "",
      kitFulfillment: "",
    },

    showAddOrganizationModal: false,
    showEditOrganizationModal: false,
    showAddPracticeModal: false,
    showOverrideModal: false,
    showManageProgramsModal: false,
  };

  async componentDidMount() {
    this.execSearch({ limit: this.props.organizationPageLimit });
  }

  setPageLimit = async (limit: number) => {
    await this.props.setOrganizationPageLimit(limit);
    this.execSearch({});
  };

  execSearch = async (query: OrganizationQuery) => {
    const { setOrganizations, organizationsFilter, sortedState } = this.props;
    if (this.state.reset) {
      this.setState({ reset: false });
    }

    if (query.name !== undefined) {
      await this.setState({ search: query.name });
    }

    LoadingIndicator.fire.show();

    const orgCollection: ModelCollection<OrganizationModel, Organization> =
      OrganizationModel.makeOrganizationCollection();
    orgCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    const paginationConfig: PaginationConfig = await orgCollection.fetch({
      ...query,
      limit: this.props.organizationPageLimit,
      ...{ name: this.state.search },
      ...organizationsFilter,
      ...sortedState,
    });

    await setOrganizations(orgCollection.container);
    await this.props.setOrganizationsPaginationConfig(paginationConfig);
    orgCollection.trigger(MODEL_SYNCED);
  };

  resetFilters = () => {
    this.setState(
      { search: "", filters: { type: "", kitFulfillment: "" }, reset: true },
      () => {
        this.execSearch({});
      }
    );
  };

  onFilter = async (value: any) =>
    this.setState({ filter: !this.state.filter });

  onEditOrganization = () => {
    this.setState({
      showEditOrganizationModal: true,
    });
  };

  onModalClose = () => {
    this.setState({
      showAddOrganizationModal: false,
      showEditOrganizationModal: false,
      showAddPracticeModal: false,
      showOverrideModal: false,
      showManageProgramsModal: false,
      organization: undefined,
    });
  };

  onAddPractice = () => {
    this.setState({
      showAddPracticeModal: true,
    });
  };

  onManageOverrides = () => {
    this.setState({
      showOverrideModal: true,
    });
  };

  onManagePrograms = (organization: Organization) => {
    this.setState({
      organization: organization,
      showManageProgramsModal: true,
    });
  };

  onPracticeModalClose = () => {
    this.setState(
      {
        showAddPracticeModal: false,
      },
      () => {
        this.execSearch({});
      }
    );
  };

  onAddOrganization = () => this.setState({ showAddOrganizationModal: true });

  render() {
    const { organizations } = this.props;
    const {
      showAddOrganizationModal,
      showEditOrganizationModal,
      showAddPracticeModal,
      showOverrideModal,
      showManageProgramsModal,
    } = this.state;

    return (
      <>
        <Header
          isNav={true}
          entity={{
            name: "Organization",
            displayName: this.props.displayName,
            selectedEntityLength: this.props.organizations.length,
            totalEntityLength: this.props.paginationConfig.totalResources,
          }}
          button={{
            create: {},
            toggleCollapse: {
              onToggle: () => {},
              collapsed: false,
            },
          }}
          table={{ pageLimit: this.props.organizationPageLimit }}
          search={{
            onSearch: this.execSearch,
            searchStr: this.state.search,
            filterStr: FILTER_STRING,
            filters: {
              kitFulfillment: undefined,
              type: undefined,
              isEnabled: undefined,
            },
            sortedState: {
              direction: undefined,
              sortBy: undefined,
            } as SortQuery,
          }}
          filter={{
            onResetState: (newState: {}) => {
              this.setState(newState);
              this.resetFilters();
            },
            onResetFilters: this.props.setOrganizationsSearchFilters,
            onResetSortedState: this.props.setOrganizationsSortedState,
          }}
        >
          <CreateEntityButton
            className={"col d-flex flex-row-reverse pr-0"}
            handleClick={this.onAddOrganization}
            text={"Create Organization"}
          />
        </Header>
        <div className="card-body">
          <OrganizationTable
            onPageLimitChange={this.setPageLimit}
            data={organizations}
            execSearch={this.execSearch}
            onEditOrganization={this.onEditOrganization}
            onManageOverrides={this.onManageOverrides}
            onManagePrograms={this.onManagePrograms}
            onAddPractice={this.onAddPractice}
            reset={this.state.reset}
          />
        </div>

        <Modal
          handleClose={this.onModalClose}
          show={showAddOrganizationModal}
          heading="Create Organization"
          button="Create"
        >
          {showAddOrganizationModal && (
            <OrganizationFormComponent
              resourceAction={RCAResourceActions.Create}
              button="Create"
              handleClose={this.onModalClose}
            />
          )}
        </Modal>
        <Modal
          handleClose={this.onModalClose}
          show={showEditOrganizationModal}
          heading="Edit Organization"
          button="Save Changes"
        >
          {showEditOrganizationModal && (
            <OrganizationFormComponent
              resourceAction={RCAResourceActions.ModifyBasicInfo}
              button="Save Changes"
              handleClose={this.onModalClose}
            />
          )}
        </Modal>
        <Modal
          handleClose={this.onPracticeModalClose}
          show={showAddPracticeModal}
          heading="Create Entity/Branch"
          button="Create"
        >
          {showAddPracticeModal && (
            <PracticeForm
              resourceAction={RCAResourceActions.Create}
              button="Create"
              handleClose={this.onPracticeModalClose}
            />
          )}
        </Modal>

        <RSModal
          size="lg"
          backdrop
          overflow={false}
          open={showOverrideModal}
          onClose={this.onModalClose}
        >
          <RSModal.Header closeButton>
            <RSModal.Title>Manage Parameter Overrides</RSModal.Title>
          </RSModal.Header>
          <RSModal.Body>
            <OrganizationRules />
          </RSModal.Body>
        </RSModal>

        {this.state.organization && (
          <RSModal
            size="full"
            backdrop
            overflow={false}
            open={showManageProgramsModal}
            onClose={this.onModalClose}
          >
            <RSModal.Header closeButton>
              <RSModal.Title>Manage Programs</RSModal.Title>
            </RSModal.Header>
            <RSModal.Body>
              <PathwayContainer
                onSave={this.onModalClose}
                organization={this.state.organization}
              />
            </RSModal.Body>
          </RSModal>
        )}
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setOrganizations: (
      organizations: OrganizationModel[]
    ): ((dispatch: SetOrganzationDispatch) => Promise<void>) =>
      dispatch(setOrganizationsThunk(organizations)),
    setOrganizationsSearchFilters: (payload: any) =>
      dispatch(setOrganizationsSearchFilters(payload)),
    setOrganizationPageLimit: (
      pageLimit: number
    ): { type: string; payload: number } =>
      dispatch(setOrganizationPageLimit(pageLimit)),
    setOrganizationsPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setOrganizationsPaginationConfig(paginationConfig)),
    setOrganizationsSortedState: (
      organizationSortedState: OrganizationsSortedState
    ) => dispatch(setOrganizationsSortedState(organizationSortedState)),
  };
};

const mapStateToProps = (state: {
  practice: { practices: Practice[] };
  organization: {
    organizations: Organization[];
    filters: OrganizationsFilter;
    paginationConfig: PaginationConfig;
    organizationPageLimit: number;
    sortedState: OrganizationsSortedState;
  };
}) => {
  return {
    organizations: state.organization.organizations,
    paginationConfig: state.organization.paginationConfig,
    organizationPageLimit: state.organization.organizationPageLimit,
    organizationsFilter: state.organization.filters,
    sortedState: state.organization.sortedState,
  };
};

export const OrganizationComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
