import "./table.css";

interface Props {
  headers: JSX.Element[];
  subHeaders?: JSX.Element[];
  mergedHeaders?: (JSX.Element | undefined)[];
  records: (string | JSX.Element | undefined)[][];
  id?: string;
  sortedColumnIndex: number | undefined;

  recordsPerPage: number;
  totalRecords: number;
  currentPage: number;
  recordsOnPage: number;
}

export default function Table(props: Props) {
  const pageNumbers = [];

  for (
    let pages = 1;
    pages <= Math.ceil(props.totalRecords / props.recordsPerPage);
    pages++
  ) {
    pageNumbers.push(pages);
  }
  const pages = pageNumbers.length;
  const start = (props.currentPage - 1) * props.recordsPerPage + 1,
    end = Math.min(
      props.currentPage * props.recordsPerPage,
      props.totalRecords
    );
  return (
    <>
      <p
        className="text-small mb-1"
        style={{ fontSize: "13px" }}
      >{`Showing ${start} to ${end} of ${props.totalRecords} entries`}</p>
      <table
        className="table-sm table-striped w-100 table-bordered table-light table-hover client-table"
        id={props.id}
      >
        <thead>
          {props.mergedHeaders}
          <tr>{props.headers}</tr>
        </thead>
        <tbody id="body">
          {props.subHeaders && <tr id="filters">{props.subHeaders}</tr>}
          {props.records &&
            props.records.map((record, rowIndex) => (
              <tr>
                {record.map((content, colIndex) => (
                  <td
                    className={
                      colIndex === props.sortedColumnIndex
                        ? "td-text sorted_col"
                        : "td-text"
                    }
                  >
                    {content}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
