import { createRef, FC, useEffect } from "react";
import React from "react";

export interface TrackProps {
  track: any;
  isMicMutted: boolean;
  width?: string;
  height?: string;
}
export const Track: FC<TrackProps> = ({
  track,
  isMicMutted,
  width = "100%",
  height = "85vh",
}) => {
  const ref = createRef<HTMLDivElement>();
  useEffect(() => {
    if (track !== null) {
      const kind = track.kind;
      const child = track.attach();
      if (
        kind === "video" &&
        ref?.current?.getElementsByTagName("video").length
      ) {
        return;
      }
      if (kind === "audio") {
        child.setAttribute("muted", `${isMicMutted}`);
      }
      child.style.width = width;
      child.style.height = height;
      child.style.borderRadius = "16px 16px 16px 16px";
      ref?.current?.classList.add(track.kind);
      ref?.current?.appendChild(child);
    }
  }, [ref, track]);
  return <div ref={ref}></div>;
};
