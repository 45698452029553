/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Patient, PatientCondition, PatientPriority } from "../../model/types";
import styles from "./styles.module.scss";
import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuItem } from "@mui/material";
import cx from "clsx";
import {
  RiskLevels,
  SERVICE_LEVEL,
  ShowConfirmModal,
  capitalizeLabel,
  currentUserInPatientWatchList,
  getAssessmentBG,
  getObservationsBG,
  getToolTip,
  handleAccept,
  handleCommentChange,
  handleReturnKitModalSubmit,
  onWatchlistClick,
} from "./helpers";

import IC_ASSESSMENT from "../../../../assets/icons/observations/icassessment.svg";
import IC_ASSESSMENTW from "../../../../assets/icons/observations/icassessmentW.svg";
import IC_HR from "../../../../assets/icons/observations/icHR.svg";
import IC_HRW from "../../../../assets/icons/observations/icHRW.svg";
import IC_BP from "../../../../assets/icons/observations/icBP.svg";
import IC_BPW from "../../../../assets/icons/observations/icBPW.svg";
import IC_OL from "../../../../assets/icons/observations/icOL.svg";
import IC_OLW from "../../../../assets/icons/observations/icOLW.svg";
import IC_WT from "../../../../assets/icons/observations/icWT.svg";
import IC_WTW from "../../../../assets/icons/observations/icWTW.svg";
import IC_BG from "../../../../assets/icons/observations/icBG.svg";
import IC_BGW from "../../../../assets/icons/observations/icBGW.svg";
import IC_TP from "../../../../assets/icons/observations/icTP.svg";
import IC_TPW from "../../../../assets/icons/observations/icTPW.svg";
import IC_SP from "../../../../assets/icons/observations/icSP.svg";
import IC_SPW from "../../../../assets/icons/observations/icSPW.svg";

import { truncateObservationValue } from "@/domain/observations/helpers";
import { ObservationType } from "@/domain/observations/types";
import { AlertLevelColor } from "@/domain/notes/model/types";
import {
  getEncounterDays,
  getRecentStatusDate,
} from "../PatientBannerDetails/helpers";
import { calculateAge } from "../tabs/summary/constants";
import {
  PatientDevice,
  ServiceLevel,
} from "@/domain/kits/models/device.interface";
import { CreateNoteModal, NoteFormType } from "../CreateNoteModal";
import { Modal } from "@/components/modal";
import { Notification } from "@/components/notification/notification";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { KitReturnModal } from "@/domain/kits/view/KitReturnModal";
import { SUCCESS } from "@/library/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatients,
} from "../../redux/actions";
import { PatientModel } from "../../model";
import { RootState } from "@/types";
import {
  PatientState,
  getPatientNextState,
  patientTextType,
} from "../form/manageState/types";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { FormikHelpers } from "formik";
import { dateFormatter } from "@/pipes/date";
import { PatientBannerReviewedCheckbox } from "../PatientBannerReviewedCheckbox";
import { DateTime } from "luxon";
import _ from "lodash";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "MM/dd/yy";
const PatientStateText: {
  ADDED: patientTextType;
  PREACTIVE: patientTextType;
  ACTIVE: patientTextType;
  INACTIVE: patientTextType;
  COMPLETED: patientTextType;
} = {
  ADDED: {
    title: "Added",
    img: require("../../../../assets/icons/observations/icMarkAsComplete.svg"),
  },
  PREACTIVE: {
    title: "Pre-Active",
    img: require("../../../../assets/icons/observations/icMarkAsActive.svg"),
  },
  ACTIVE: {
    title: "Active",
    img: require("../../../../assets/icons/observations/icMarkAsActive.svg"),
  },
  INACTIVE: {
    title: "Inactive",
    img: require("../../../../assets/icons/observations/icMarkAsInactive.svg"),
  },
  COMPLETED: {
    title: "Complete",
    img: require("../../../../assets/icons/observations/icMarkAsComplete.svg"),
  },
};
interface ObservationTile {
  title: string;
  subTitle: string;
  img: any;
  primaryBG: string;
  secondaryBG: string;
  toolTip: string;
  label?: string;
  imgWhite: string;
  isComplete: boolean;
}

const PatientBannerRow = ({
  updatePatientComment,
  timer,
  setSelectedRow,
  selectedRow,
  patient,
  gotoProfile,
  gotoMonitoring,
  onVirtualVisit,
  includeTimer,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { provider, demographics } = patient || {};
  const { legalName, dateOfBirth, preferredName } = demographics || {};
  const { firstName, lastName } = legalName || {};
  const [videoCallStatus, setVideoCallStatus] = useState(false);
  const [showClinicalNoteModal, setShowClinicalNoteModal] = useState(false);
  const [showBiometricModal, setShowBiometricModal] = useState(false);
  const [showWatchlistModal, setShowWatchlistModal] = useState(false);
  const [showRiskLevelModal, setShowRiskLevelModal] = useState(false);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(
    PatientPriority.None
  );
  const [showPathwayAssessmentModal, setShowPathwayAssessmentModal] =
    useState(false);
  const [showReturnKitModal, setShowReturnKitModal] = useState(false);
  const hasTimerInNotes = !includeTimer;
  const dispatch = useDispatch();
  const patients = useSelector((state: RootState) => state.patient.patients);
  const selectedPatient = useSelector(
    (state: RootState) => state.patient.selectedPatients
  );
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [selectedStatus, setSelectedStatus] = useState<
    PatientState | undefined
  >();
  const [disableAcceptBtn, setDisableAcceptBtn] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const getServiceLevel = () => {
    return patient?.deviceInfo
      ? patient?.deviceInfo[0]?.serviceLevel === ServiceLevel.RemoteCareLive
        ? SERVICE_LEVEL.RemoteCareLive
        : patient?.deviceInfo[0]?.serviceLevel === ServiceLevel.RemoteCare
        ? SERVICE_LEVEL.RemoteCare
        : SERVICE_LEVEL.DEFAULT
      : SERVICE_LEVEL.DEFAULT;
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const dateDob = format(
    DateTime.fromISO(dateOfBirth.toString()).toJSDate(),
    "MM/dd/yyyy"
  );
  const medicalConditions = patient?.conditions
    ?.map((item: PatientCondition) => item.displayName)
    .join(", ");
  const serviceLevel = patient?.deviceInfo
    ?.map((item: PatientDevice) => (item.msn || "-").slice(-5))
    .join(", ");
  const profileInfo1 = [
    `DOB: ${dateDob.replaceAll("-", "/")}`,
    `${calculateAge(dateOfBirth)} Y`,
    `${
      medicalConditions.replaceAll(",", "").trim() !== ""
        ? medicalConditions
        : ""
    }`,
  ];
  const profileInfo2 = [
    `${getServiceLevel()} - ${serviceLevel ?? "Not available"}`,
    `${patient?.practice?.name ?? "-"}`,
  ];
  const assessmentBG = getAssessmentBG(patient);
  const heartRateBG = getObservationsBG(
    patient?.health?.observations?.heartRate
  );
  const bloodPressureBG = getObservationsBG(
    patient?.health?.observations?.bloodPressure
  );
  const pulseBG = getObservationsBG(
    patient?.health?.observations?.pulseOximetry
  );
  const weightBG = getObservationsBG(patient?.health?.observations?.weight);
  const glucoseBG = getObservationsBG(patient?.health?.observations?.glucose);
  const temperatureBG = getObservationsBG(
    patient?.health?.observations?.temperature
  );
  const spirometryBG = getObservationsBG(
    patient?.health?.observations?.spirometry
  );
  const videoCallDisabled =
    !patient.deviceInfo ||
    (patient.deviceInfo &&
      patient.deviceInfo[0]?.serviceLevel !== ServiceLevel.RemoteCareLive);
  const patientNotes =
    patient.notes?.filter((note: any) => {
      if ("requestType" in note) {
        if (
          !note.isComplete &&
          note.type === "PATIENT_REQUEST" &&
          note.requestType === "VIDEO"
        ) {
          return note;
        }
      }
    }) || [];

  useEffect(() => {
    setVideoCallStatus(patientNotes.length >= 1);
  }, [patientNotes]);

  useEffect(() => {
    setSelectedRow(anchorEl != null ? patient?._meta?.id : null);
  }, [anchorEl]);

  const patientObservations = [
    {
      title: patient?.health?.assessment?.time
        ? format(new Date(patient?.health?.assessment?.time), TIME_FORMAT)
        : "-",
      subTitle: patient?.health?.assessment?.time
        ? format(new Date(patient?.health?.assessment?.time), DATE_FORMAT)
        : "-",
      img: IC_ASSESSMENT,
      imgWhite: IC_ASSESSMENTW,
      primaryBG: assessmentBG.primary,
      secondaryBG: assessmentBG.secondary,
      isComplete: patient?.health?.assessment?.isComplete,
      toolTip: patient?.health?.assessment ? (
        <>
          <div>{patient?.health?.assessment?.name ?? ""}</div>
          <div>
            {dateFormatter({
              date: patient?.health?.assessment?.time,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })}{" "}
          </div>
        </>
      ) : (
        ""
      ),
    },
    {
      title: patient?.health?.observations?.bloodPressure
        ? Math.trunc(
            patient?.health?.observations?.bloodPressure?.observation?.systolic
              ?.value
          ) +
          "/" +
          Math.trunc(
            patient?.health?.observations?.bloodPressure?.observation?.diastolic
              ?.value
          )
        : "-",
      subTitle: "mmHg",
      img: IC_HR,
      imgWhite: IC_HRW,
      primaryBG: bloodPressureBG.primary,
      secondaryBG: bloodPressureBG.secondary,
      isComplete: patient?.health?.observations?.bloodPressure?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.bloodPressure?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.HeartRate,
        patient?.health?.observations?.heartRate?.observation?.value
      ),
      subTitle: "bpm",
      img: IC_BP,
      imgWhite: IC_BPW,
      primaryBG: heartRateBG.primary,
      secondaryBG: heartRateBG.secondary,
      isComplete: patient?.health?.observations?.heartRate?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.heartRate?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.PulseOximetry,
        patient?.health?.observations?.pulseOximetry?.observation?.value
      ),
      subTitle: "%",
      img: IC_OL,
      imgWhite: IC_OLW,
      primaryBG: pulseBG.primary,
      secondaryBG: pulseBG.secondary,
      isComplete: patient?.health?.observations?.pulseOximetry?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.pulseOximetry?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.Weight,
        patient?.health?.observations?.weight?.observation?.value
      ),
      subTitle: "lbs",
      img: IC_WT,
      imgWhite: IC_WTW,
      primaryBG: weightBG.primary,
      secondaryBG: weightBG.secondary,
      isComplete: patient?.health?.observations?.weight?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.weight?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.GlucoseLevel,
        patient?.health?.observations?.glucose?.observation?.value
      ),
      subTitle: "mg/dL",
      img: IC_BG,
      imgWhite: IC_BGW,
      primaryBG: glucoseBG.primary,
      secondaryBG: glucoseBG.secondary,
      isComplete: patient?.health?.observations?.glucose?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.glucose?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.Temperature,
        patient?.health?.observations?.temperature?.observation?.value
      ),
      subTitle: `${"\u00b0"}F`,
      img: IC_TP,
      imgWhite: IC_TPW,
      primaryBG: temperatureBG.primary,
      secondaryBG: temperatureBG.secondary,
      isComplete: patient?.health?.observations?.temperature?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.temperature?.time),
    },
    {
      title:
        patient?.health?.observations?.spirometry?.observation?.pef?.value ??
        "-",
      label:
        patient?.health?.observations?.spirometry?.observation?.fev1?.value?.toFixed(
          2
        ) ?? "-",
      subTitle: "L/min - L",
      img: IC_SP,
      imgWhite: IC_SPW,
      primaryBG: spirometryBG.primary,
      secondaryBG: spirometryBG.secondary,
      isComplete: patient?.health?.observations?.spirometry?.isComplete,
      toolTip: getToolTip(patient?.health?.observations?.spirometry?.time),
    },
  ];

  const options = [
    {
      ic: require("../../../../assets/icons/observations/icCreateNote.svg"),
      label: "Create Note",
      handleClick: () => setShowClinicalNoteModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icManualBiometricEntry.svg"),
      label: "Direct Biometric Entry",
      handleClick: () => setShowBiometricModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icManualPathway.svg"),
      label: "Direct Pathway Entry",
      handleClick: () => setShowPathwayAssessmentModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icRiskLevel.svg"),
      label: "Risk Level",
    },
    {
      label: "Mark",
      ic: require("../../../../assets/icons/observations/icChangeStatus.svg"),
    },
  ];

  const updatePatientRiskLevel = async (priority: PatientPriority) => {
    LoadingIndicator.fire.show();
    try {
      const patientPriorty = priority === "NONE" ? null : priority;
      await PatientModel.make(patient).modifyPatient<Patient, "priority">({
        priority: patientPriorty as string,
      });
      await updateReduxState(patientPriorty);
      Notification.notify(SUCCESS, `Risk level updated successfully. `);
    } catch (e) {}

    LoadingIndicator.fire.hide();
  };

  const updateReduxState = async (priority: PatientPriority | null) => {
    const patientToBeEdited = patients.find(
      (entity: any) => entity.id === patient.id
    );
    const editedPatient = { ...patientToBeEdited, ...{ priority } };

    updatePatientDetailState({
      ...patient,
      priority: priority as string,
    });
    const updated = patients.map((patient: Patient) => {
      return patient.id === editedPatient.id ? editedPatient : patient;
    });
    dispatch(dispatchSetPatients(updated as Patient[]));
  };

  const updatePatientDetailState = (editedPatient: Partial<Patient>) => {
    if (selectedPatient && selectedPatient.length > 0) {
      selectedPatient.forEach((patient: Patient) => {
        if (patient.id === editedPatient.id) {
          patient = { ...patient, ...editedPatient };
          dispatch(dispatchDeselectAllPatients());
          dispatch(dispatchSelectPatient(patient));
        }
      });
    }
  };

  const patientNextState = getPatientNextState(
    patient?.lifecycle?.state as PatientState
  );
  const handleChooseStatus = (selectedState: PatientState): void => {
    setSelectedStatus(selectedState);
    selectedState === PatientState.Completed &&
      patient.deviceInfo &&
      setShowReturnKitModal(true);
    selectedState === PatientState.Completed &&
      !patient.deviceInfo &&
      setShowConfirmModal(true);
    selectedState !== PatientState.Completed && setShowConfirmModal(true);
    setDisableAcceptBtn(false);
  };

  const patientState = capitalizeLabel(patient?.lifecycle?.state);

  return (
    <li key={patient?.id}>
      <div
        className={styles.patientRowDiv}
        style={{ zIndex: patient?._meta?.id === selectedRow ? 802 : "auto" }}
      >
        <div
          className={styles.patientRow}
          style={{
            borderColor:
              patient?.priority === PatientPriority.Low
                ? "#66C793"
                : patient?.priority === PatientPriority.Medium
                ? "#FFCE1F"
                : patient?.priority === PatientPriority.High
                ? "#F76363"
                : "#030575",
          }}
        >
          <button
            type="button"
            className={styles.patientRowInfo}
            onClick={gotoProfile}
          >
            <div className={"d-flex justify-content-between"}>
              <div className={styles.ptName}>
                {`${lastName}${firstName ? ", " + firstName : ""} ${
                  preferredName && preferredName !== "null"
                    ? "(" + preferredName + ")"
                    : ""
                }`}
              </div>
              {includeTimer && timer}
            </div>
            <div className={cx("d-flex", "flex-wrap")}>
              <div className={styles.ptStatusDiv}>
                <div className={styles.ptText12}>{`ID - ${
                  patient?.encounter?.id || "-"
                }`}</div>
              </div>
              {profileInfo1.map((pract) => profileDetails(pract))}
            </div>
            <div className={cx("d-flex", "flex-wrap")}>
              <Tooltip
                placement={"top"}
                arrow={true}
                title={`${getRecentStatusDate(
                  patient?.lifecycle
                )} / ${getEncounterDays(patient?.lifecycle)}`}
              >
                <div
                  className={
                    patient?.lifecycle?.state === PatientState.Active
                      ? styles.ptStatusActive
                      : patient?.lifecycle?.state === PatientState.Inactive
                      ? styles.ptStatusInactive
                      : patient?.lifecycle?.state === PatientState.Completed
                      ? styles.ptStatusComplete
                      : styles.ptStatusDiv
                  }
                >
                  <div
                    className={
                      patient?.lifecycle?.state === PatientState.Active
                        ? styles.ptText12Active
                        : patient?.lifecycle?.state === PatientState.Inactive
                        ? styles.ptText12Inactive
                        : patient?.lifecycle?.state === PatientState.Completed
                        ? styles.ptText12Complete
                        : styles.ptText12
                    }
                  >
                    {patientState === "" ? "-" : patientState}
                  </div>
                </div>
              </Tooltip>
              {profileInfo2.map((pract) => profileDetails(pract))}
            </div>
            <div className="d-flex">
              <div className={cx(styles.ptStatusDiv)}>
                <div className={styles.ptText12}>
                  {`${provider?.firstName} ${provider?.lastName}, ${provider?.credentials}`}
                </div>
                <div
                  className={styles.ptText12}
                >{`${provider?.phoneNumber}`}</div>
              </div>
              <div className={styles.reviewDiv}>
                <PatientBannerReviewedCheckbox patient={patient} />
              </div>
            </div>
          </button>
          <div className="d-flex">
            {patientObservations.map((Observation) =>
              showPatientObservations(
                Observation as ObservationTile,
                gotoMonitoring
              )
            )}
          </div>
          <div className="d-flex">
            <div className={cx(styles.sideBorder)} style={{ display: "grid" }}>
              <Tooltip
                placement={"top"}
                arrow={true}
                title={"Feature coming soon."}
              >
                <button
                  className={styles.opac04}
                  style={{ cursor: "not-allowed" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img
                    src={
                      require("../../../../assets/icons/observations/icCallH.svg")
                        .default
                    }
                    alt={"call"}
                  />
                </button>
              </Tooltip>
              <Tooltip
                placement={"top"}
                arrow={true}
                title={
                  videoCallDisabled
                    ? "Patient does not have a video-capable device"
                    : ""
                }
              >
                <button
                  className={cx(
                    videoCallStatus
                      ? styles.bgCallDis
                      : videoCallDisabled
                      ? styles.opac04
                      : styles.bgWhite,
                    styles.topBorder
                  )}
                  style={{
                    cursor: videoCallDisabled ? "not-allowed" : "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onVirtualVisit();
                  }}
                >
                  <img
                    className={cx(styles.vcall)}
                    src={
                      require("../../../../assets/icons/observations/icVcalls.svg")
                        .default
                    }
                    alt={"videocall"}
                  />
                  {videoCallStatus && (
                    <img
                      className={styles.callWar}
                      src={
                        require("../../../../assets/icons/observations/icWR.svg")
                          .default
                      }
                      alt={"warning"}
                    />
                  )}
                </button>
              </Tooltip>
            </div>
            <textarea
              defaultValue={patient?.comments}
              className={styles.textComment}
              placeholder="Enter comment..."
              maxLength={250}
              name="comment"
              rows={5}
              onBlur={(e) =>
                handleCommentChange(
                  e.target.value?.trim() || "",
                  patient,
                  updatePatientComment
                )
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <div className={cx("mr-2")} style={{ display: "grid" }}>
              <div className="align-content-center">
                <button className={styles.bgWhite} onClick={handleClick}>
                  <img
                    className={cx(styles.paddingIC, " align-self-center")}
                    src={
                      require("../../../../assets/icons/observations/icmore.svg")
                        .default
                    }
                    alt={"more"}
                  />
                </button>
              </div>
              <Menu
                disableAutoFocusItem
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={styles.optionsMenuPatient}
                slotProps={{
                  paper: {
                    style: {
                      borderRadius: "6px",
                      width: "239px",
                    },
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.label}
                    onClick={handleClose}
                    disableRipple
                    sx={[
                      { "&:hover": { backgroundColor: "transparent" } },
                      {
                        marginTop:
                          option.label === "Create Note" ? "10px" : "3px",
                      },
                    ]}
                    className={cx(
                      styles.patientListItem,
                      option.label === "Mark" &&
                        patient?.lifecycle?.state === PatientState.Completed &&
                        "d-none"
                    )}
                  >
                    {option.label === "Mark" &&
                    patient?.lifecycle?.state !== PatientState.Completed ? (
                      <div className={cx(styles.fullWidth)}>
                        <div className={cx("d-flex", styles.changeStatusTitle)}>
                          <div className={styles.menuTitlePopup}>
                            Change Status
                          </div>
                        </div>
                        <div className={cx(styles.fullWidth)}>
                          {patientNextState.map((state, index) => {
                            return (
                              <div
                                className={cx(
                                  "d-flex",
                                  index < patientNextState?.length - 1 &&
                                    styles.changeStatusTitle,
                                  styles.optionsHover
                                )}
                                onClick={() => handleChooseStatus(state)}
                              >
                                <img
                                  className={styles.menuIc}
                                  src={PatientStateText[state].img.default}
                                  alt={"more"}
                                />
                                <div className={styles.menuOptionText}>
                                  {" "}
                                  {PatientStateText[state].title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : option.label === "Risk Level" ? (
                      <div className={cx(styles.borderTopBt, styles.fullWidth)}>
                        <div className={cx("d-flex", styles.riskLevelDiv)}>
                          <img
                            className={styles.menuIc}
                            src={option.ic.default}
                            alt={"more"}
                          />
                          <div className={styles.menuTitlePopup}>
                            {option.label}
                          </div>
                        </div>
                        <div className={cx("d-flex", styles.fullWidth)}>
                          <button
                            className={cx("d-flex", styles.radioButton)}
                            onClick={() => {
                              setSelectedRiskLevel(PatientPriority.High);
                              setShowRiskLevelModal(true);
                            }}
                          >
                            <img
                              className={styles.menuIcRadioButton}
                              alt={"raido"}
                              src={
                                patient?.priority === PatientPriority.High
                                  ? require("../../../../assets/icons/observations/radioOn.svg")
                                      .default
                                  : require("../../../../assets/icons/observations/radioOff.svg")
                                      .default
                              }
                            />
                            <div className={styles.menuSbTitle}>
                              {RiskLevels.High}
                            </div>
                          </button>
                          <button
                            className={cx("d-flex", styles.radioButton)}
                            onClick={() => {
                              setSelectedRiskLevel(PatientPriority.Medium);
                              setShowRiskLevelModal(true);
                            }}
                          >
                            <img
                              className={styles.menuIcRadioButton}
                              alt={"raido"}
                              src={
                                patient?.priority === PatientPriority.Medium
                                  ? require("../../../../assets/icons/observations/radioOn.svg")
                                      .default
                                  : require("../../../../assets/icons/observations/radioOff.svg")
                                      .default
                              }
                            />
                            <div className={styles.menuSbTitle}>
                              {RiskLevels.Medium}
                            </div>
                          </button>
                        </div>
                        <div
                          className={cx(
                            "d-flex",
                            styles.riskLevelDivider,
                            styles.fullWidth
                          )}
                        >
                          <button
                            className={cx("d-flex", styles.radioButton)}
                            onClick={() => {
                              setSelectedRiskLevel(PatientPriority.Low);
                              setShowRiskLevelModal(true);
                            }}
                          >
                            <img
                              className={styles.menuIcRadioButton}
                              alt={"raido"}
                              src={
                                patient?.priority === PatientPriority.Low
                                  ? require("../../../../assets/icons/observations/radioOn.svg")
                                      .default
                                  : require("../../../../assets/icons/observations/radioOff.svg")
                                      .default
                              }
                            />
                            <div className={styles.menuSbTitle}>
                              {RiskLevels.Low}
                            </div>
                          </button>
                          <button
                            className={cx("d-flex", styles.radioButton)}
                            onClick={() => {
                              setSelectedRiskLevel(PatientPriority.None);
                              setShowRiskLevelModal(true);
                            }}
                          >
                            <img
                              className={styles.menuIcRadioButton}
                              alt={"raido"}
                              src={
                                patient?.priority == null
                                  ? require("../../../../assets/icons/observations/radioOn.svg")
                                      .default
                                  : require("../../../../assets/icons/observations/radioOff.svg")
                                      .default
                              }
                            />
                            <div className={styles.menuSbTitle}>
                              {RiskLevels.Unassign}
                            </div>
                          </button>
                        </div>
                      </div>
                    ) : option.label !== "Mark" ? (
                      <button
                        className={cx(
                          "d-flex",
                          styles.bgWhite,
                          styles.fullWidth,
                          styles.optionsHover
                        )}
                        onClick={option.handleClick}
                      >
                        <img
                          className={styles.menuIc}
                          src={option.ic.default}
                          alt={"info"}
                        />
                        <div className={styles.menuTitlePopup}>
                          {option.label}
                        </div>
                      </button>
                    ) : null}
                  </MenuItem>
                ))}
              </Menu>
              <button
                className={styles.watchListIc}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowWatchlistModal(true);
                }}
              >
                <img
                  className=" align-self-center"
                  src={
                    currentUserInPatientWatchList(currentUser, patient)
                      ? require("../../../../assets/icons/observations/icWatchlist.svg")
                          .default
                      : require("../../../../assets/icons/observations/icWatchlistoff.svg")
                          .default
                  }
                  alt={"watchlist"}
                />
              </button>
            </div>
          </div>
        </div>
        <ShowConfirmModal
          open={showWatchlistModal}
          label={
            currentUserInPatientWatchList(currentUser, patient)
              ? "Are you sure you want to remove this patient from watchlist?"
              : "Are you sure you want to add this patient to watchlist?"
          }
          onCancel={() => setShowWatchlistModal(false)}
          onProceed={() => {
            onWatchlistClick(dispatch, currentUser, patient, patients);
            setShowWatchlistModal(false);
          }}
        />
        <ShowConfirmModal
          open={showRiskLevelModal}
          label={`Are you sure you want to change risk level of this patient to '${
            selectedRiskLevel
              ? selectedRiskLevel === PatientPriority.None
                ? "Unassign"
                : _.capitalize(selectedRiskLevel.toString())
              : selectedRiskLevel
          }'?`}
          onCancel={() => setShowRiskLevelModal(false)}
          onProceed={() => {
            updatePatientRiskLevel(selectedRiskLevel);
            setShowRiskLevelModal(false);
          }}
        />
        <CreateNoteModal
          formType={NoteFormType.ClinicalNote}
          includeNoteTimer={hasTimerInNotes}
          show={showClinicalNoteModal}
          patient={patient}
          handleClose={() => setShowClinicalNoteModal(false)}
        />
        <CreateNoteModal
          formType={NoteFormType.ObservationNote}
          includeNoteTimer={hasTimerInNotes}
          show={showBiometricModal}
          patient={patient}
          handleClose={() => setShowBiometricModal(false)}
        />
        <CreateNoteModal
          formType={NoteFormType.AssessmentNote}
          includeNoteTimer={hasTimerInNotes}
          show={showPathwayAssessmentModal}
          patient={patient}
          handleClose={() => setShowPathwayAssessmentModal(false)}
        />
        {showReturnKitModal && (
          <KitReturnModal
            open={showReturnKitModal}
            onClose={() => setShowReturnKitModal(false)}
            onSubmit={(
              values: KitShipmentFormValues,
              form: FormikHelpers<KitShipmentFormValues>
            ) =>
              handleReturnKitModalSubmit(
                values,
                form,
                patient,
                async () => setShowReturnKitModal(false),
                () => setShowConfirmModal(true),
                dispatch
              )
            }
            patient={patient}
          />
        )}
        <Modal
          size={"xs"}
          handleClose={() => setShowConfirmModal(false)}
          show={showConfirmModal}
          heading={"Confirm change status"}
          button={"Accept"}
        >
          {showConfirmModal && (
            <>
              <p
                style={{
                  padding: "10px",
                  fontSize: "17px",
                }}
              >
                You are about to change the patient's status to{" "}
                <b>{selectedStatus}</b>.
              </p>
              <div className="row">
                <div className="col-12 mt-4 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    onClick={() => setShowConfirmModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={disableAcceptBtn}
                    onClick={() =>
                      handleAccept(
                        setDisableAcceptBtn,
                        selectedStatus,
                        patient,
                        setShowConfirmModal,
                        dispatch,
                        patients
                      )
                    }
                    className="btn btn-primary mr-2"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal>
      </div>
    </li>
  );
};

const showPatientObservations = (
  observation: ObservationTile,
  gotoMonitoring: () => void
) => {
  const isAlertCompleted =
    observation.isComplete &&
    (observation.primaryBG === AlertLevelColor.GREEN_ALERT ||
      observation.primaryBG === AlertLevelColor.RED_ALERT ||
      observation.primaryBG === AlertLevelColor.YELLOW_ALERT);
  return (
    <Tooltip placement={"top"} arrow={true} title={observation.toolTip}>
      <button
        className={
          observation.primaryBG === AlertLevelColor.NO_ALERT ||
          observation.primaryBG === AlertLevelColor.DISABLED_ALERT
            ? cx(styles.patientRowInf, styles.borderGrey)
            : observation.primaryBG === AlertLevelColor.BLACK_ALERT
            ? cx(styles.patientRowInf, styles.borderDoted)
            : isAlertCompleted &&
              observation.primaryBG === AlertLevelColor.GREEN_ALERT
            ? cx(styles.patientRowInf, styles.borderGreen)
            : isAlertCompleted &&
              observation.primaryBG === AlertLevelColor.RED_ALERT
            ? cx(styles.patientRowInf, styles.borderRed)
            : isAlertCompleted &&
              observation.primaryBG === AlertLevelColor.YELLOW_ALERT
            ? cx(styles.patientRowInf, styles.borderYellow)
            : styles.patientRowInf
        }
        onClick={gotoMonitoring}
      >
        <div
          className={styles.rowInfoIc}
          style={{
            background:
              observation.primaryBG === AlertLevelColor.BLACK_ALERT
                ? "black"
                : isAlertCompleted
                ? "white"
                : observation.primaryBG,
          }}
        >
          <img
            className={cx(styles.actIc, " align-self-center")}
            src={
              observation.primaryBG === AlertLevelColor.BLACK_ALERT
                ? observation.imgWhite
                : observation.img
            }
            alt={"next"}
          />
        </div>
        <div
          style={{
            background:
              observation.secondaryBG === AlertLevelColor.BLACK_ALERT ||
              isAlertCompleted
                ? "white"
                : observation.secondaryBG,
            borderRadius: 4,
            height: "70px",
            textAlign: "center",
            placeContent: "center",
          }}
        >
          {observation?.label ? (
            <div style={{ padding: 3, paddingLeft: 5 }}>
              <div className={styles.resultSubLbl}>PEF</div>
              <div className={styles.vitalsp}>
                <div className={styles.patientTopTitle}>
                  {observation.title === "-" ? "--" : observation.title}
                </div>
                <div className={cx(styles.marginL2, styles.resultSubLbl)}>
                  {observation.title === "-" ? "" : "L/min"}
                </div>
              </div>
              <div className={styles.resultSubLbl}>FEV 1</div>
              <div className={styles.vitalsp}>
                <div className={styles.patientTopTitle}>
                  {observation.label === "-" ? "--" : observation.label}
                </div>
                <div className={cx(styles.marginL2, styles.resultSubLbl)}>
                  {observation.label === "-" ? "" : "L"}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.patientTopTitle}>
                {observation.title === "-" ? "--" : observation.title}
              </div>
              <div className={styles.resultLbl}>
                {observation.subTitle === "-" ? "--" : observation.subTitle}
              </div>
            </div>
          )}
        </div>
      </button>
    </Tooltip>
  );
};

export const profileDetails = (label: string) => {
  return label !== "" ? (
    <div className={cx(styles.ptStatusDiv, styles.maxLength)}>
      <div className={cx(styles.ptText12, styles.ellipsis)}>{label}</div>
    </div>
  ) : null;
};

export default PatientBannerRow;
