import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { RestartAlt as RestartAltIcon } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  primary: string;
  secondary?: string;
  patientCount: number;
  actionButton?: ReactNode;
  resetButton?: ReactNode;
  noAction?: boolean;
  actionButtonDescription?: string;
}
export const WatchListItem: FC<Props> = ({
  actionButtonDescription,
  noAction = false,
  primary,
  secondary,
  patientCount,
  actionButton = <span />,
  resetButton = <RestartAltIcon />,
}) => {
  const [hovered, setHovered] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  return (
    <ListItem
      {...(isDirty ? { sx: { background: "rgba(25, 118, 210, 0.08)" } } : {})}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ListItemIcon>
        <Avatar>{patientCount}</Avatar>
      </ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
      {!noAction && hovered && (
        <IconButton
          {...{ sx: { marginRight: "300px" } }}
          edge="end"
          aria-label="add"
        >
          {isDirty ? (
            <Tooltip title="Resets to previous state">
              <IconButton onClick={() => setIsDirty(false)}>
                {resetButton}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={actionButtonDescription}>
              <IconButton onClick={() => setIsDirty(true)}>
                {actionButton}
              </IconButton>
            </Tooltip>
          )}
        </IconButton>
      )}
    </ListItem>
  );
};
