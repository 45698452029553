import { format } from "date-fns";

import { extractphone } from "@/components/form";
import { PatientModel } from "@/domain/patient/model";
import {
  Patient,
  PatientConditionForm,
  PatientForm,
} from "@/domain/patient/model/types";
import { Serializeable } from "../serializer";
import {
  getPatientDesignatedRepresentatives,
  patientDateOfBirth,
} from "./utils";
/**
 * Serializer for modifying Patient basic info.
 */
export class ModifyBasicInfoSerializer implements Serializeable<PatientModel> {
  constructor(protected readonly formData: PatientForm) {}

  serialize(): PatientModel {
    return PatientModel.make({
      id: this.formData.id,
      mrn: this.formData.mrn === "" ? null : this.formData.mrn,
      providerId: this.formData.providerId,
      assignedUserId:
        this.formData.careTeam === "" ? null : this.formData.careTeam,
      priority:
        this.formData.priority === "NONE" ? null : this.formData.priority,
      billingType:
        this.formData.billingType === "" ? null : this.formData.billingType,
      demographics: {
        legalName: {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          middleName:
            this.formData.middleName === "" ? null : this.formData.middleName,
          prefix: this.formData.prefix === "" ? null : this.formData.prefix,
          suffix: this.formData.suffix === "" ? null : this.formData.suffix,
        },
        dateOfBirth: patientDateOfBirth(this.formData.dateOfBirth),
        birthSex:
          this.formData.birthSex === "UNKNOWN"
            ? null
            : this.formData.birthSex.toUpperCase(),
        gender: this.formData.gender === "" ? null : this.formData.gender,
        race: this.formData.race === "UNKNOWN" ? null : this.formData.race,
        language: this.formData.language,
        ethnicity:
          this.formData.ethnicity === "" ? null : this.formData.ethnicity,
        preferredName:
          this.formData.preferredName === ""
            ? null
            : this.formData.preferredName,
      },
      address: {
        street: [this.formData.address],
        locality: this.formData.city,
        region: this.formData.state,
        postalCode: this.formData.zip,
        country: this.formData.country,
      },
      designatedRepresentatives: getPatientDesignatedRepresentatives(
        this.formData.emergencyContact
      ),
      email: this.formData.email === "" ? null : this.formData.email,
      height:
        this.formData.height === ""
          ? null
          : { value: this.formData.height, unit: this.formData.heightUnit },
      weight:
        this.formData.weight === ""
          ? null
          : { value: this.formData.weight, unit: this.formData.weightUnit },
      phone: {
        primary: {
          number: extractphone(this.formData.primaryPhone),
          isSmsAllowed: this.formData.allowSMS?.primary,
        },
        ...(this.formData.secondaryPhone
          ? {
              secondary: {
                number: extractphone(this.formData.secondaryPhone),
                isSmsAllowed: this.formData.allowSMS?.secondary,
              },
            }
          : {}),
      },
      conditions:
        this.formData.diagnosis && this.formData.diagnosis.length > 0
          ? this.formData.diagnosis
              .filter(
                (condition: PatientConditionForm) => !!condition.displayName
              )
              .map((condition: PatientConditionForm) => {
                return {
                  type: condition.type,
                  icd10:
                    !condition.icdCode || condition.icdCode.value === ""
                      ? null
                      : {
                          code: condition.icdCode.value,
                          description: condition.icdCode.label,
                        },
                  displayName: condition.displayName,
                  resolved: condition.resolvedDate
                    ? format(
                        new Date(condition.resolvedDate) as Date,
                        "yyyy-MM-dd"
                      )
                    : null,
                };
              })
          : [],
      programId: this.formData.programId,
      encounter: this.formData.encounter,
    } as unknown as Patient);
  }
}
