import TableWithPagination from "../../../../components/table/datatable/tableWithPagination.component";
import { SortedState } from "../../../../components/table/head/sortable";
import { NpiProvider, NpiTableHeaderContent } from "./npiLookup";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

interface Props {
  npiResults: NpiProvider[];
  handleClose: () => void;
  onSelectProvider: (provider: NpiProvider) => void;
}

function NpiLookup(props: Props) {
  const { npiResults, handleClose, onSelectProvider } = props;

  return (
    <div className="mt-4" id="left-slide">
      <div
        className="container pr-md-10"
        style={{ maxHeight: "600px", overflow: "scroll" }}
      >
        <i
          className="fa fa-arrow-left fa-2x back-btn mb-1"
          onClick={handleClose}
          aria-hidden="true"
        ></i>
        <TableWithPagination
          config={{
            recordsPerPage: 10,
            isSortable: false,
          }}
          header={NpiTableHeaderContent.map((content) => {
            return {
              name: content,
              isSortable: false,
              sortedState: SortedState.NoSort,
            };
          })}
          records={npiResults.map((provider: NpiProvider) => {
            return [
              <span>
                <a
                  className="text-primary pointer"
                  onClick={() => {
                    onSelectProvider(provider);
                  }}
                >
                  {provider.npi}
                </a>
              </span>,
              <span>{provider.firstName}</span>,
              <span>{provider.lastName}</span>,
              <span>{provider.credential}</span>,
              <span>
                {provider.address1 || ""} {provider.address2 || ""}
              </span>,
              <span>{provider.city}</span>,
              <span>{provider.state}</span>,
              <span>{provider.postalCode}</span>,
            ];
          })}
        />
        {npiResults.length === 0 && (
          <h1 className="text-center text-secondary h3 py-5 bg-light">
            <ZoomOutIcon className="d-block text-secondary fa-3x mb-2" />
            No result found!
          </h1>
        )}
      </div>
    </div>
  );
}

export default NpiLookup;
