import React from "react";

import { ChartColors } from "@/components/charts/chartsColor";
import BarChart from "@/components/charts/HighChart.component";
import { Patient } from "@/domain/patient/model/types";

interface Props {
  patients: Patient[];
}
interface State {
  chartConfig: any;
}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chartConfig: this.drawChart(),
    };
  }

  drawChart() {
    let [
      lessThan5Incomplete,
      lessThan10Incomplete,
      lessThan15Incomplete,
      lessThan20Incomplete,
      greaterThan20Incomplete,
    ] = [0, 0, 0, 0, 0];
    let [
      lessThan5Contacted,
      lessThan10Contacted,
      lessThan15Contacted,
      lessThan20Contacted,
      greaterThan20Contacted,
      greaterThan20NotContacted,
    ] = [0, 0, 0, 0, 0, 0];

    this.props.patients.map((patient) => {
      const accumulatedMinutes = patient.billing!.cpt99457.currentMinutes;
      const directPatientContact =
        patient.billing?.cpt99457.directPatientContact;

      if (accumulatedMinutes < 5) {
        if (directPatientContact === true) {
          lessThan5Contacted++;
        } else {
          lessThan5Incomplete++;
        }
      } else if (accumulatedMinutes < 10) {
        if (directPatientContact === true) {
          lessThan10Contacted++;
        } else {
          lessThan10Incomplete++;
        }
      } else if (accumulatedMinutes < 15) {
        if (directPatientContact === true) {
          lessThan15Contacted++;
        } else {
          lessThan15Incomplete++;
        }
      } else if (accumulatedMinutes < 20) {
        if (directPatientContact === true) {
          lessThan20Contacted++;
        } else {
          lessThan20Incomplete++;
        }
      } else {
        if (directPatientContact === true) {
          greaterThan20Contacted++;
        } else {
          greaterThan20NotContacted++;
        }
      }
      return {
        lessThan5Incomplete,
        lessThan10Incomplete,
        lessThan15Incomplete,
        lessThan20Incomplete,
        greaterThan20Incomplete,
        lessThan5Contacted,
        lessThan10Contacted,
        lessThan15Contacted,
        lessThan20Contacted,
        greaterThan20Contacted,
      };
    });

    return {
      options: {
        chart: {
          type: "column",
          height: 550,
        },
        title: {
          text: "CPT 99457",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          categories: ["< 5", "5 to 9", "10 to 14", "15 to 19", ">20 minutes"],
          title: {
            text: "Number of RPM Minute Month-to-Date",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Current Enrollees",
          },
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function (this: { y: number }) {
                if (this.y > 0) return this.y;
              },
            } as { enabled: boolean; formatter: () => number },
          },
        },
        tooltip: {
          borderWidth: 0,
          stickOnContact: true,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        series: [
          {
            name: "Not contacted",
            color: ChartColors.RED,
            data: [
              {
                y: lessThan5Incomplete,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(false, 5, undefined);
                  },
                },
              },
              {
                y: lessThan10Incomplete,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(false, 5, 10);
                  },
                },
              },
              {
                y: lessThan15Incomplete,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(false, 10, 15);
                  },
                },
              },
              {
                y: lessThan20Incomplete,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(false, 15, 20);
                  },
                },
              },
              {
                y: greaterThan20NotContacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(false, 20, undefined);
                  },
                },
              },
            ],
          },
          {
            name: "Contacted",
            color: ChartColors.GREEN,
            data: [
              {
                y: lessThan5Contacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(true, 5, undefined);
                  },
                },
              },
              {
                y: lessThan10Contacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(true, 5, 10);
                  },
                },
              },
              {
                y: lessThan15Contacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(true, 10, 15);
                  },
                },
              },
              {
                y: lessThan20Contacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(true, 15, 20);
                  },
                },
              },
              {
                y: greaterThan20Contacted,
                events: {
                  mouseOver: () => {
                    this.createRPMTableByColumns(true, 20, undefined);
                  },
                },
              },
            ],
          },
        ],
      },
    };
  }

  createRPMTableByColumns(
    contacted: boolean,
    lowerLimit: number | undefined,
    upperLimit: number | undefined
  ) {
    const patients = this.props.patients.filter((patient) => {
      if (contacted) {
        if (lowerLimit === 5 && !upperLimit) {
          return (
            patient.billing?.cpt99457.directPatientContact === true &&
            patient.billing?.cpt99457.currentMinutes < lowerLimit
          );
        } else if (lowerLimit === 20 && !upperLimit) {
          return (
            patient.billing?.cpt99457.directPatientContact === true &&
            patient.billing?.cpt99457.currentMinutes >= lowerLimit
          );
        } else {
          return (
            patient.billing?.cpt99457.directPatientContact === true &&
            patient.billing?.cpt99457.currentMinutes >= lowerLimit! &&
            patient.billing?.cpt99457.currentMinutes < upperLimit!
          );
        }
      } else {
        if (lowerLimit === 5 && !upperLimit) {
          return (
            patient.billing?.cpt99457.directPatientContact === false &&
            patient.billing?.cpt99457.currentMinutes < lowerLimit
          );
        } else if (lowerLimit === 20 && !upperLimit) {
          return (
            patient.billing?.cpt99457.directPatientContact === false &&
            patient.billing?.cpt99457.currentMinutes >= lowerLimit
          );
        } else {
          return (
            patient.billing?.cpt99457.directPatientContact === false &&
            patient.billing?.cpt99457.currentMinutes >= lowerLimit! &&
            patient.billing?.cpt99457.currentMinutes < upperLimit!
          );
        }
      }
    });

    const patientsTable = patients
      .sort((a, b) =>
        a.demographics.legalName.firstName > b.demographics.legalName.firstName
          ? 1
          : b.demographics.legalName.firstName >
            a.demographics.legalName.firstName
          ? -1
          : 0
      )
      .map((patient) => {
        return (
          '<tr><td><a href="/patient/' +
          patient.id +
          '" target="_blank">' +
          patient.demographics.legalName.firstName +
          " " +
          patient.demographics.legalName.lastName +
          "</a></td>" +
          "<td>" +
          (patient.assignedUserName ?? "Unassigned") +
          " </td>" +
          "<td>" +
          patient.billing!.cpt99457.currentMinutes +
          " </td>" +
          "<td>" +
          patient.billing!.cpt99457.directPatientContact +
          " </td></tr>"
        );
      });

    this.setState((prevState) => ({
      ...prevState,
      chartConfig: {
        options: {
          tooltip: {
            headerFormat:
              "<b>{point.x}</b><br/><table>" +
              "<thead><tr><td><b>Name </b></td><td><b>Care Team </b></td><td><b>Minutes </b></td><td><b>Contacted </b></td></tr></thead>",
            pointFormat:
              "{series.name}: {point.y}<tbody>" +
              patientsTable.toString().replaceAll(",", " ") +
              "</tbody>",
            footerFormat: "</table>",
            useHTML: true,
            style: {
              pointerEvents: "auto",
              position: "auto",
            },
          },
        },
      },
    }));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.patients !== this.props.patients) {
      this.setState({
        chartConfig: this.drawChart(),
      });
    }
  }

  render() {
    return (
      <div id="CPT-chart">
        <BarChart options={this.state.chartConfig.options} />
      </div>
    );
  }
}

export const RpmChartComponent = Component;
