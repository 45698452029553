import { PatientState } from "@/library/types/patientState";
import { PatientTransition } from "@/library/types/patientState";

export enum AuditEntryType {
  DeviceAssignment = "DEVICE_ASSIGNMENT",
  PatientLifecycleTransition = "PATIENT_LIFECYCLE_TRANSITION",
  PatientProfileChange = "PATIENT_PROFILE_CHANGE",
  PatientParameterChange = "PARAMETER_CHANGE",
}

export interface AuditLog {
  id?: string;
  type:
    | AuditEntryType.DeviceAssignment
    | AuditEntryType.PatientLifecycleTransition
    | AuditEntryType.PatientProfileChange
    | AuditEntryType.PatientParameterChange;
  time: Date;
  organizationId: string;
  practiceId: string | null;
  actorId: string | null;
  patientId: string | null;
  deviceId?: string;
  toState?: PatientState;
  transition?: PatientTransition;
  modifiedProperties?: Record<string, string | boolean | object>;
  ruleId?: string;
  setVariables?: Record<string, string | boolean | object>;
  unsetVariableNames?: Array<string>;
  assigned?: boolean;
}
