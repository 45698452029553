import React from "react";
import cx from "clsx";

import { Props } from "./types";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";

export const TextButton: React.FC<Props> = (props: Props) => {
  const {
    onClick,
    disabled,
    iconLeft,
    iconRight,
    children,
    className,
    style,
    ...buttonProps
  } = props;

  const isDisabled = disabled || !onClick;
  const left = iconLeft ? (
    <div className={styles.iconLeftContainer}>{iconLeft}</div>
  ) : null;
  const right = iconRight ? (
    <div className={styles.iconRightContainer}>{iconRight}</div>
  ) : null;

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={cx(
        globalStyles.buttonReset,
        styles.link,
        isDisabled && globalStyles.disabled,
        className
      )}
      style={style}
      {...buttonProps}
    >
      {left}
      {children}
      {right}
    </button>
  );
};
