import { ObservationTypes } from "@/domain/patient/model/constants";

export const AssessmentType = "PATHWAY";

export const AlertTypeLabelMap = {
  [ObservationTypes.BloodPressure]: "BloodPr",
  [ObservationTypes.GlucoseLevel]: "Gluc",
  [ObservationTypes.HeartRate]: "HR",
  [ObservationTypes.PulseOximetry]: "PulseOx",
  [ObservationTypes.Spirometry]: "Spir",
  [ObservationTypes.Temperature]: "Temp",
  [ObservationTypes.Weight]: "Wt",
  [AssessmentType]: "Pthwy",
};
export const REQUEST_PARAMS_LENGTH_LIMIT = 250;
