import { useEffect, useState } from "react";
import { Button } from "rsuite";
import cx from "clsx";

import styles from "./styles.module.scss";
import { snake2Camel } from "@/pipes/text/index";

export type Props = {
  title: string;
  children: string | JSX.Element;
  visible?: boolean;
  headerRight?: string | JSX.Element;
  isLast?: boolean;
  onToggle?: () => void;
};

export const Figure = ({
  title,
  isLast,
  visible = true,
  headerRight,
  children,
  onToggle,
}: Props): JSX.Element => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(!visible);
  }, [visible]);

  const handleToggleClick = () => {
    setIsHidden(!isHidden);
    if (onToggle) onToggle();
  };

  return (
    <div
      className={cx(
        styles.figContainer,
        isHidden && styles.collapsed,
        !isLast && styles.border
      )}
    >
      <div className={styles.inner}>
        <div className={styles.left}>
          <Button onClick={handleToggleClick} appearance="primary">
            {isHidden ? "Show Vital" : "Hide Vital"}
          </Button>
        </div>
        <div className={styles.title}>{snake2Camel(title)}</div>
        <div className={styles.right}>{headerRight}</div>
      </div>
      {!isHidden ? <div className={styles.content}>{children}</div> : null}
    </div>
  );
};
