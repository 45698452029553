import React, { FC } from "react";
import { Controls } from "@/components/virtualVisit/controls";
import { Participant } from "@/components/virtualVisit/participant";
import { Box, Skeleton } from "@mui/material";
import VirtualVisitTimer from "@/components/virtualVisit/timer";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export interface VirtualVisitMainMediaProps {
  leave: () => void;
  room?: any;
  getElapsedTime?: (seconds: number) => void;
  getElapsedTimeOnReset?: (seconds: number) => void;
  onReset: number;
  isMicMutted: boolean;
  toggleMic: () => void;
}
export const VirtualVisitMainMedia: FC<VirtualVisitMainMediaProps> = ({
  leave,
  room,
  getElapsedTime,
  onReset,
  getElapsedTimeOnReset,
  isMicMutted,
  toggleMic,
}) => {
  const remoteP = room?.participants ? [...room.participants.values()] : [];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        maxHeight: "90vh",
        textAlign: "right",
      }}
    >
      {room && room.localParticipant && (
        <VirtualVisitTimer
          getElapsedTimeOnReset={getElapsedTimeOnReset}
          getElapsedTime={getElapsedTime}
          onReset={onReset}
        />
      )}
      {room && !!remoteP.length ? (
        <Participant
          room={room}
          name={""}
          participant={remoteP[0]}
          key={remoteP[0].identity}
          isMicMutted={isMicMutted}
        />
      ) : (
        <Box>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            variant="rectangular"
            width={800}
            height="85vh"
          />
        </Box>
      )}
      {room && room.localParticipant && (
        <Controls toggleMicControl={toggleMic} room={room} leave={leave} />
      )}
    </Box>
  );
};
