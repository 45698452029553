import {
  TextInput,
  Select,
  TextInputMask,
  Validation,
  TextArea,
} from "@/components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ContactForm } from "./model/contact";
import { useState } from "react";
interface Props {
  handleClose: () => void;
  handleSubmit: (contact: ContactForm, editMode: boolean) => Promise<void>;
  modalBtn: string;
  targetedContact?: ContactForm;
  targetedContactId?: number;
}

function CreateContactForm(props: Props) {
  const { handleClose } = props;
  const isEditMode = props.modalBtn === "Save Changes";

  const initialValues: ContactForm = {
    firstName: "",
    lastName: "",
    relationship: "",
    mobile: "",
    email: "",
    notes: "",
    isActive: true,
  };

  const getInitialValues = (): ContactForm => {
    if (isEditMode && props.targetedContact) {
      const contact = props.targetedContact;
      return {
        firstName: contact.firstName || "",
        lastName: contact.lastName || "",
        relationship: contact.relationship || "",
        mobile: contact.mobile || "",
        email: contact.email || "",
        notes: contact.notes || "",
        isActive: true,
      };
    }
    return initialValues;
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          firstName: Validation.required,
          lastName: Validation.lastName,
          relationship: Validation.required,
          mobile: Validation.notRequired,
          email: Validation.emailNotRequired,
          notes: Validation.notRequired,
        })}
        onSubmit={async (values: ContactForm, { setSubmitting, resetForm }) => {
          await props.handleSubmit(values, isEditMode);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="modal-body">
                <div className="subject-scroll">
                  <div className="form-row d-flex justify-content-start">
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="First Name"
                        name="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Last Name"
                        name="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Relationship"
                        name="relationship"
                        type="text"
                        placeholder="Enter Relationship"
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextInput
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Enter Email Id"
                        required={false}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <TextInputMask
                        value={formik.values.mobile}
                        label="Phone Number"
                        name="phone"
                        type="tel"
                        mask="+1 (999) 999-9999"
                        onChange={(e: any) => {
                          formik.setFieldValue("mobile", e.target.value);
                        }}
                        required={false}
                      />
                    </div>

                    <div className="col-md-8 mb-3">
                      <TextArea
                        label="Notes"
                        name="notes"
                        placeholder=""
                        required={false}
                        rows={1}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4 d-flex justify-content-start"></div>
                    <div className="col-6 mt-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        {props.modalBtn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CreateContactForm;
