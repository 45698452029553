import { connect } from "react-redux";
import React from "react";
import cx from "clsx";

import { Guard } from "@/library/guard/Guard";
import { User, UserParentTypes } from "@/domain/user/model";
import { OrganizationDropdownComponent } from "@/components/dropdown/organizationDropdown.component";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Provider, ProviderModel } from "@/domain/provider/model";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { ModelCollection } from "@/library/model";
import { MultiselectConfig } from "@/components/form/select/multiselect.model";
import { CURRENT_DATE, MODEL_SYNCED } from "@/library/constants";
import { PaginationConfig } from "@/library/types";
import {
  deselectOrganization,
  dispatchSelectOrganization,
} from "@/domain/organization/redux/actions";
import { setPatientsThunk } from "@/domain/patient/redux/middleware";
import ReportType from "./report-type";
import MonthSelect from "./month-select";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { ReportTypes } from "../../model/reports";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import globalStyles from "@/styles/globals.module.scss";
import { dateFormatter } from "@/pipes/date";
import { TagModel } from "@/domain/tags/model";
import { Tag } from "@/domain/tags/model/types";
import { Program } from "@/domain/program/model/types";
import { ProgramModel } from "@/domain/program/model";

interface Props {
  selectOrganization: (organization: Organization) => {
    type: string;
    payload: Organization;
  };
  selectedOrganization: Organization | undefined;
  selectedPractice: (practice: Practice) => void;
  selectedReportDateRange: (date: Date) => void;
  handleChangeReportType: (reportType: ReportTypes) => void;

  setPatients: (patientModel: PatientModel[]) => void;
  setLocation: (locations: Tag[]) => void;
  setPatientsState: (
    patientCollection: ModelCollection<PatientModel, Patient>
  ) => void;
  setPractices: (practices: Practice[]) => void;
  setProviders: (providers: Provider[]) => void;
  patients: Patient[];
  currentUser: User;
  handlePDFExport: () => void;
  handleCSVExport: () => void;
  setProgramFunction: (programs: Program[]) => void;
}

interface State {
  globalFilters: {
    practiceIds: string[] | undefined;
    providerIds: string[] | undefined;
    userIds: string[] | undefined;
  };
  reportDateRange: Date;
  practices: Practice[];
  providers: Provider[];
  programs: Program[];
  users: any[];
  patients: Patient[];
  practicePlaceholder: string;
  multiSelectConfig: MultiselectConfig;
  reportType: ReportTypes;
  selectedPractice: Practice | undefined;
}

class Component extends React.Component<Props, State> {
  private headerRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
        userIds: undefined,
      },
      practices: [],
      providers: [],
      programs: [],
      users: [],
      patients: [],
      practicePlaceholder: "All Entity/Branch",
      multiSelectConfig: {
        showLoadingIndicator: false,
      },
      reportType: ReportTypes.rpm,
      reportDateRange: new Date(),
      selectedPractice: undefined,
    };
    this.headerRef = React.createRef();
  }

  clearGlobalFilters = () => {
    this.setState({
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
        userIds: undefined,
      },
      practices: [],
      providers: [],
      users: [],
    });
  };

  fetchPractices = async () => {
    if (this.props.selectedOrganization && this.props.selectedOrganization.id) {
      const allPracticeModels: PracticeModel[] =
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id as string,
        ]);
      const practices = allPracticeModels.map((practiceModel) =>
        practiceModel.pluckAll()
      );
      this.setState((previousState) => ({
        ...previousState,
        practices,
      }));
      this.props.setPractices(practices);
    }
  };

  selectedPractice = async () => {
    const { practiceIds } = this.state.globalFilters;

    if (practiceIds) {
      const practiceId = practiceIds.toString();
      const selectedPractice = (
        await PracticeModel.sync(practiceId)
      ).pluckAll();
      this.setState((prevState) => ({
        ...prevState,
        selectedPractice,
      }));
      this.props.selectedPractice(selectedPractice);
    }
  };

  fetchProviders = async () => {
    let practiceIds: string[];
    if (
      !this.state.globalFilters.practiceIds?.length &&
      this.props.selectedOrganization &&
      this.state.practices.length === 0
    ) {
      const practiceModelsInOrganizationScope =
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id!,
        ]);
      practiceIds = practiceModelsInOrganizationScope.map(
        (practiceModel) => practiceModel.pluck("_meta")?.id!
      );
    } else if (this.state.globalFilters.practiceIds) {
      practiceIds = this.state.globalFilters.practiceIds || [];
    } else {
      practiceIds = this.state.practices.map(
        (practice) => practice.id
      ) as string[];
    }

    if (!practiceIds.length) {
      this.setState((previousState) => ({ ...previousState, providers: [] }));
      return;
    }

    const allProviderModels: ProviderModel[] =
      await ProviderModel.fetchByPracticeIds(practiceIds);

    const providers = allProviderModels.map((providerModel) =>
      providerModel.pluckAll()
    );
    this.setState((previousState) => ({
      ...previousState,
      providers,
    }));
    this.props.setProviders(providers);
  };

  isFilterSelected = (): boolean => {
    const filters = this.state.globalFilters;
    let isFiltered = false;
    for (const [filter, value] of Object.entries(filters)) {
      if (value && value.length !== 0) {
        isFiltered = true;
        break;
      }
    }
    return isFiltered;
  };

  handleSelectOrganization = async () => {
    LoadingIndicator.fire.show();
    this.clearGlobalFilters();
    await this.fetchPractices();
    //await this.fetchProviders();
    const patientCollection: ModelCollection<PatientModel, Patient> =
      PatientModel.makePatientCollection();
    patientCollection.setCustomLimit(500);
    patientCollection.on(MODEL_SYNCED, () => {
      // Complete
    });
    const isPracticeUser = Guard.accessLevel(
      UserParentTypes.Practice
    ).canActivate(this.props.currentUser!);
    if (isPracticeUser) {
      this.setState({
        ...this.state,
        practicePlaceholder: `${this.state.practices[0].name} `,
        globalFilters: {
          ...this.state.globalFilters,
          practiceIds: this.state.practices.map(
            (practice) => practice.id
          ) as string[],
        },
      });
    }
    //const providerIds = this.state.providers.map((provider) => provider.id);
    const practiceIds = this.state.practices.map((practice) => practice.id);
    if (practiceIds && practiceIds.length !== 0) {
      let currentPage = 0;
      let lastPage = 1;
      while (currentPage++ < lastPage) {
        const paginationConfig: PaginationConfig =
          await patientCollection.fetch({
            practiceIds,
            includeBilling:
              this.state.reportType === ReportTypes.rpm ||
              this.state.reportType === ReportTypes.rpmComprehensive
                ? 1
                : undefined,
            billingMonth: this.state.reportDateRange.getMonth() + 1, //getMonth() start from 0 so add 1 to get the correct selected month
            billingYear: this.state.reportDateRange.getFullYear(),
            includeTagInfo: true,
            includeDeviceSnapshot: true,
            currentPage,
          });
        lastPage = paginationConfig?.lastPage;
      }
      this.props.setPatientsState(patientCollection);
      const locations = await TagModel.fetchAllTagsWithIterator({
        type: "location",
        practiceIds: practiceIds,
      });
      this.props.setLocation(locations);
    } else {
      this.props.setPatientsState(patientCollection);
    }
  };

  execPatientSearch = async () => {
    if (this.isFilterSelected()) {
      LoadingIndicator.fire.show();
      const { practiceIds, providerIds, userIds } = this.state.globalFilters;
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.setCustomLimit(500);
      patientCollection.on(MODEL_SYNCED, () => {
        // Complete
      });
      if (
        (practiceIds && practiceIds.length !== 0) ||
        (providerIds && providerIds.length !== 0) ||
        (userIds && userIds.length !== 0)
      ) {
        let currentPage = 0;
        let lastPage = 1;
        while (currentPage++ < lastPage) {
          const paginationConfig: PaginationConfig =
            await patientCollection.fetch({
              practiceIds,
              providerIds,
              assignedUserIds: userIds,
              includeBilling:
                this.state.reportType === ReportTypes.rpm ||
                this.state.reportType === ReportTypes.rpmComprehensive
                  ? 1
                  : undefined,
              billingMonth: this.state.reportDateRange.getMonth() + 1, //getMonth() start from 0 so add 1 to get the correct selected month
              billingYear: this.state.reportDateRange.getFullYear(),
              includeTagInfo: true,
              includeDeviceSnapshot: true,
              currentPage,
            });
          lastPage = paginationConfig?.lastPage;
        }
        this.props.setPatientsState(patientCollection);
      }
    } else {
      await this.handleSelectOrganization();
    }
  };

  handleReportTypeChange = async (type: ReportTypes) => {
    this.setState(() => ({
      reportType: type,
    }));
    await this.execPatientSearch();
    this.props.handleChangeReportType(type);
  };

  handleReportMonthYearChange = async (date: Date) => {
    this.setState(() => ({
      reportDateRange: date,
    }));
    this.props.selectedReportDateRange(date);
    await this.execPatientSearch();
  };

  async componentDidMount() {
    if (this.props.selectedOrganization) {
      const path = window.location.pathname;
      window.history.replaceState(
        null,
        ``,
        `${path}?organizationId=${this.props.selectedOrganization.id}`
      );
      await this.handleSelectOrganization();
      const programs: Program[] = await this.fetchPrograms();
      this.props.setProgramFunction(programs);
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.selectedOrganization &&
      prevProps.selectedOrganization !== this.props.selectedOrganization
    ) {
      await this.handleSelectOrganization();
    }
    const programs: Program[] = await this.fetchPrograms();
    this.props.setProgramFunction(programs);
  }

  getReportName(): string {
    switch (this.state.reportType) {
      case ReportTypes.rpm:
        return `${ReportTypes.rpm} BILLING REPORT SUMMARY`;
      case ReportTypes.rpmComprehensive:
        return `${ReportTypes.rpmComprehensive} BILLING REPORT`;
      case ReportTypes.alert:
        return `PATIENT ${ReportTypes.alert} REPORT`;
      case ReportTypes.outcomes:
        return `PATIENT ${ReportTypes.outcomes} REPORT`;
      case ReportTypes.patientStatus:
        return `${ReportTypes.patientStatus} REPORT`;
      default:
        return `PATIENT ${ReportTypes.rpm} BILLING REPORT`;
    }
  }
  fetchPrograms = async () => {
    const allPrograms: Program[] =
      await ProgramModel.fetchAllProgramsWithIterator({});
    const programs: Program[] = allPrograms.filter(
      (p: Program) => p.organizationId === this.props.selectedOrganization?.id!
    );
    return programs;
  };

  render() {
    const datetime = convertDateToLocalTimezone(CURRENT_DATE);
    return (
      <>
        <div style={{ marginBottom: "70px" }}>
          <nav
            ref={this.headerRef}
            style={{ zIndex: 7 }}
            className="navbar w-100 navbar-expand-lg navbar-light bg-white sticky-top shadow-sm nav-filters"
          >
            <h6 className={cx(globalStyles.pageHeading, "pl-2")}>Reports</h6>
            <div
              className="collapse navbar-collapse"
              id="navbarText"
              role="tablist"
            >
              <ul
                className="navbar-nav mx-auto"
                id="pills-tab"
                style={{ fontSize: "15px" }}
              >
                <li className="nav-item px-3">
                  <a className="nav-link">
                    {!Guard.accessLevel(UserParentTypes.Practice).canActivate(
                      this.props.currentUser!
                    ) && (
                      <OrganizationDropdownComponent
                        disabled={
                          !Guard.accessLevel(UserParentTypes.Admin).canActivate(
                            this.props.currentUser!
                          )
                        }
                        setDefaultOrganization={true}
                        currentOrganization={
                          this.props.selectedOrganization?.name!
                        }
                        onChange={async (value: string) => {
                          // Sync organization with organizationId
                          const orgModel: OrganizationModel =
                            await OrganizationModel.sync(value);

                          const path = window.location.pathname;
                          window.history.replaceState(
                            null,
                            ``,
                            `${path}?organizationId=${value}`
                          );

                          // make selected organization the default
                          this.props.selectOrganization(orgModel.pluckAll());
                          const programs: Program[] =
                            await this.fetchPrograms();
                          this.props.setProgramFunction(programs);
                        }}
                      />
                    )}
                  </a>
                </li>
                <li className="nav-item px-3">
                  <a className="nav-link">
                    <BasicMultiSelect
                      data={this.state.practices.map((practice: Practice) => ({
                        value: practice._meta?.id,
                        label: practice.name,
                      }))}
                      onChange={async (value: string[]) => {
                        await this.setState({
                          globalFilters: {
                            ...this.state.globalFilters,
                            providerIds: undefined,
                            userIds: undefined,
                            practiceIds: value,
                          },
                        });
                        await this.execPatientSearch();
                      }}
                      findValueBy={this.state.globalFilters.practiceIds}
                      value={this.state.globalFilters.practiceIds}
                      isSearchable={true}
                      sortable={true}
                      placeholder={this.state.practicePlaceholder}
                      onOpen={async () => {
                        await this.fetchPractices();
                      }}
                      style={{ width: 200 }}
                      picker="check"
                      disabled={Guard.accessLevel(
                        UserParentTypes.Practice
                      ).canActivate(this.props.currentUser!)}
                    />
                  </a>
                </li>
                <li className="nav-item px-3">
                  <a className="nav-link">
                    <ReportType
                      reportTypePlaceHolder={this.state.reportType}
                      handleReportTypeChange={this.handleReportTypeChange}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {this.props.currentUser && (
          <div
            className="user-management text-center  mb-2"
            style={{ marginTop: "100px" }}
          >
            {" "}
            <div className="report-select-wrapper">
              <div>
                <div className="row">
                  <div className="col-4 text-center">
                    <MonthSelect
                      reportMonthYear={this.state.reportDateRange}
                      handleReportMonthYearChange={
                        this.handleReportMonthYearChange
                      }
                    />
                  </div>
                  <div className="col-4 text-center">
                    <button
                      className="btn btn-glow-primary btn-primary btn-md add-user mr-0"
                      onClick={this.props.handlePDFExport}
                    >
                      <span>Export to PDF</span>
                    </button>
                  </div>
                  <div className="col-4 text-center">
                    <button
                      className="btn btn-glow-primary btn-primary btn-md add-user mr-0"
                      onClick={this.props.handleCSVExport}
                    >
                      <span>Export to Excel</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h6>{this.getReportName()}</h6>
            <p className="mb-0">
              For{" "}
              {convertDateToLocalTimezone(
                this.state.reportDateRange
              ).toLocaleString({ month: "long", year: "numeric" })}
            </p>
            <p>
              Report generated:{" "}
              {dateFormatter({
                date: this.state.reportDateRange.toDateString(),
                toLocalTimezone: { enabled: true },
              })}{" "}
              By:{" "}
              <strong>
                {this.props.currentUser.firstName +
                  " " +
                  this.props.currentUser.lastName}
              </strong>
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
    deselectOrganization: (): any => dispatch(deselectOrganization()),

    setPatients: (patientCollection: PatientModel[]) =>
      dispatch(setPatientsThunk(patientCollection)),
  };
};

const mapStateToProps = (state: {
  organization: {
    organizations: Organization[];
    selectedOrganization: Organization;
  };
  user: { currentUser: User };
  patient: { patients: Patient[] };
}) => {
  return {
    selectedOrganization: state.organization.selectedOrganization,
    currentUser: state.user.currentUser,
    patients: state.patient.patients,
  };
};

export const ReportHeader = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
