import React from "react";
import { connect } from "react-redux";

import { Patient, PatientFilters } from "@/domain/patient/model/types";
import { Table } from "@/components/table";
import { PaginationConfig } from "@/library/types";
import { Provider, ProviderModel } from "@/domain/provider/model";
import {
  dispatchDeselectAllAssignmentPatients,
  dispatchDeselectAssignmentPatient,
  dispatchSelectAllAssignmentPatients,
  dispatchSelectAssignmentPatient,
  dispatchSetAssignmentPatientFilters,
  dispatchSetTargetPatient,
} from "@/domain/patient/redux/actions";
import { PatientId } from "@/domain/patient/redux/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Model, ModelCollection } from "@/library/model";
import { Organization } from "@/domain/organization/model/types";
import { User, UserModel } from "@/domain/user/model";
import { Iterator } from "@/library/iteration/iterator";
import { PatientModel } from "@/domain/patient/model";
import "./table.styles.css";

type TableProps = {
  headers: string[];
  data: any[];
  execSearch: (query: {}) => any;
  onPageLimitChange: (limit: number) => any;
  pageLimit: number;
  paginationConfig: PaginationConfig;

  selectedAssignmentPatients: Patient[];
  setAssignmentPatientFilters: (patientFilter: PatientFilters) => any;
  selectAssignmentPatient: (patient: Patient) => {
    type: string;
    payload: Patient;
  };
  deselectAssignmentPatient: (patient: Patient) => {
    type: string;
    payload: Patient;
  };

  selectedOrganization: Organization;
  assignmentPatientFilters: PatientFilters;
  selectAllAssignmentPatients: () => { type: string };
  deselectAllAssignmentPatients: () => { type: string };
  assignmentPatients: Patient[];

  setTargetPatient: (id: PatientId) => { type: string; payload: PatientId };
  onAssignPatient: () => void;
  onEditPatient: () => void;

  selectedPractices: Practice[];
};

type TableState = {
  practices: { value: string; label: string }[];
  providers: { value: string; label: string }[];
  users: { value: string; label: string }[];
};
class Component extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);
    this.state = {
      practices: [],
      providers: [],
      users: [],
    };
  }

  assignPatient = (targetPatient: PatientId) => {
    this.props.setTargetPatient(targetPatient);
    this.props.onAssignPatient();
  };

  EditPatient = (targetPatient: PatientId) => {
    this.props.setTargetPatient(targetPatient);
    this.props.onEditPatient();
  };

  onResetSelectedPatients = () => {
    this.props.deselectAllAssignmentPatients();
  };

  render() {
    const {
      headers,
      data,
      execSearch,
      onPageLimitChange,
      pageLimit,
      paginationConfig,
      selectedAssignmentPatients,
      selectAllAssignmentPatients,
      setAssignmentPatientFilters,
      assignmentPatientFilters,
      deselectAllAssignmentPatients,
      selectAssignmentPatient,
      deselectAssignmentPatient,
      assignmentPatients,
    } = this.props;

    const headerContents = [
      <th
        key={"patient-select-all-button"}
        className="bg-primary text-white small"
      >
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="select-all-assignment-patients"
            onChange={async (event) => {
              if (event.target.checked) {
                await selectAllAssignmentPatients();
              } else {
                await deselectAllAssignmentPatients();
              }
            }}
            checked={
              !!selectedAssignmentPatients.length &&
              selectedAssignmentPatients.length === assignmentPatients.length
            }
          />
          <label
            className="custom-control-label"
            htmlFor="select-all-assignment-patients"
          />
        </div>
      </th>,
      ...(headers || []).map((content, key) => (
        <th key={key} className="bg-primary text-white small">
          <span>{content}</span>
        </th>
      )),
    ];

    const fetchPractices = async () => {
      const practiceCollection: ModelCollection<PracticeModel, Practice> =
        PracticeModel.makePracticeCollection();
      await practiceCollection.fetch({
        limit: 100, // max limit that api supports
        organizationIds: [this.props.selectedOrganization.id],
      });

      const fetchedPractices = await practiceCollection.container.map(
        (model: any) => {
          return {
            value: model.attributes.get("id") as string,
            label: model.attributes.get("name"),
          };
        }
      );
      this.setState((previousState, props) => ({
        ...previousState,
        practices: fetchedPractices,
      }));
    };

    const getAssignedPatients = async () => {
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.withParams({
        limit: 100,
      });
      const iterator: Iterator<PatientModel, Patient> =
        await patientCollection.getMany();
      const allPatientModel = await iterator.getAll();

      /**
       * Getting all the patents whose assignedUserId is not undefined
       */
      return allPatientModel.filter((model: Model<Patient>) =>
        model.pluck("assignedUserId")
      );
    };

    const getPracticeModels = async (): Promise<PracticeModel[]> => {
      if (!this.props.selectedOrganization) {
        return [];
      }
      const practiceCollection: ModelCollection<PracticeModel, Practice> =
        PracticeModel.makePracticeCollection();
      practiceCollection.withParams({
        organizationIds: [this.props.selectedOrganization.id],
      });
      const iterator: Iterator<PracticeModel, Practice> =
        await practiceCollection.getMany();
      const allPracticeModels: PracticeModel[] = await iterator.getAll();

      return allPracticeModels;
    };

    const fetchUsers = async () => {
      const organizationUserCollection: ModelCollection<UserModel, User> =
        UserModel.makeUserCollection();
      organizationUserCollection.withParams({
        organizationIds: [this.props.selectedOrganization._meta?.id],
      });
      const organizationUserCollectionIterator: Iterator<UserModel, User> =
        await organizationUserCollection.getMany();
      const allOrganizationUserModel =
        await organizationUserCollectionIterator.getAll();

      const practiceUserCollection: ModelCollection<UserModel, User> =
        UserModel.makeUserCollection();
      practiceUserCollection.withParams({
        practiceIds: (await getPracticeModels()).map(
          (practiceModel: PracticeModel) => practiceModel.pluck("_meta")?.id
        ),
      });
      const practiceUserCollectionIterator: Iterator<UserModel, User> =
        await practiceUserCollection.getMany();
      const allPracticeUserModel =
        await practiceUserCollectionIterator.getAll();

      await this.setState({
        // Removing the duplicate values from assignedUsers array and mapping it in multi select options array.
        users: [
          ...(new Set(
            allPracticeUserModel.concat(allOrganizationUserModel)
          ) as any),
        ].map((model: Model<User>) => {
          return {
            value: model.pluck("id") as string,
            label: `${model.pluck("firstName")} ${model.pluck("lastName")}`,
          };
        }),
      });
    };

    const fetchProviders = async () => {
      // since no practice was selected, do not populate providers
      if (!this.props.assignmentPatientFilters.practiceIds) {
        this.setState((previousState) => {
          return {
            ...previousState,
            providers: [],
          };
        });
        return;
      }
      // TODO: Use iterator here.
      const providerCollection: ModelCollection<ProviderModel, Provider> =
        ProviderModel.makeProviderCollection();
      await providerCollection.fetch({
        limit: 100, // max limit that api supports
        practiceIds: this.props.assignmentPatientFilters.practiceIds,
      });

      const fetchedProviders = await providerCollection.container.map(
        (model: any) => {
          return {
            value: model.attributes.get("id") as string,
            label:
              model.attributes.get("firstName") +
              " " +
              model.attributes.get("lastName"),
          };
        }
      );

      this.setState((previousState) => {
        return {
          ...previousState,
          providers: fetchedProviders,
        };
      });
    };

    const subHeader = [
      <tr key={"patientTableHeader"}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <BasicMultiSelect
            data={this.state.practices}
            onChange={async (value: string) => {
              setAssignmentPatientFilters({ practiceIds: value as any });
              this.props.execSearch({});
            }}
            findValueBy={this.props.assignmentPatientFilters.practiceIds}
            isSearchable={true}
            placeholder="All"
            onOpen={() => fetchPractices()}
            picker="check"
          />
        </td>
        <td>
          <BasicMultiSelect
            data={this.state.providers}
            onChange={async (value: string) => {
              setAssignmentPatientFilters({ providerIds: value as any });
              this.props.execSearch({});
            }}
            findValueBy={this.props.assignmentPatientFilters.providerIds}
            isSearchable={true}
            placeholder="All"
            onOpen={() => fetchProviders()}
            picker="check"
          />
        </td>
        <td>
          <BasicMultiSelect
            data={this.state.users}
            onChange={async (value: string) => {
              setAssignmentPatientFilters({ assignedUserIds: value as any });
              this.props.execSearch({});
            }}
            findValueBy={assignmentPatientFilters.assignedUserIds}
            isSearchable={true}
            placeholder="All"
            onOpen={() => fetchUsers()}
            onSelect={async (value: string) => {}}
            picker="check"
          />
        </td>
      </tr>,
    ];

    const dataContents = (data || [])
      .map((patient) => patient as Patient)
      .map((patient, key) => {
        const provider = (async () => {
          const providerModel = await ProviderModel.sync(patient.providerId);
          return providerModel.pluckAll();
        })();
        const miliSeconds = new Date().getTime();
        return (
          <tr key={patient.id}>
            <td>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${patient.id}${miliSeconds}`}
                  onChange={(event) => {
                    if (event.target.checked) {
                      return selectAssignmentPatient(patient);
                    }
                    deselectAssignmentPatient(patient);
                  }}
                  checked={
                    !!selectedAssignmentPatients.length &&
                    (selectedAssignmentPatients.length ===
                      assignmentPatients.length ||
                      !!selectedAssignmentPatients.filter(
                        (selectedAssignmentPatients: Patient) =>
                          selectedAssignmentPatients.id === patient.id
                      ).length)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${patient.id}${miliSeconds}`}
                ></label>
              </div>
            </td>
            <td>{patient.demographics.legalName.lastName}</td>
            <td>{patient.demographics.legalName.firstName}</td>
            <td>
              {(() => {
                const primaryCondition = patient.conditions.filter(
                  (dx: { type: string }) => dx.type === "PRIMARY"
                );
                if (primaryCondition.length) {
                  const condition = primaryCondition[0];
                  if (condition) {
                    const icd10 = condition.icd10;
                    if (icd10) {
                      return icd10?.code ?? "-";
                    }
                  }
                }
                return "No Diagnosis";
              })()}
              ...
            </td>
            <td>{patient.practice?.name}</td>
            <td>{`${patient.provider?.firstName} ${patient.provider?.lastName}`}</td>
            <td>{patient.assignedUserName ?? "-"}</td>
          </tr>
        );
      });

    const tableData = subHeader.concat(dataContents);

    return (
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headerContents}
        data={tableData}
        onNavigate={execSearch}
        resetSelections={this.onResetSelectedPatients}
      />
    );
  }
}

const mapStateToProps = (state: {
  practice: { selectedPractices: Practice[] };
  patient: {
    assignmentPatientFilters: PatientFilters;
    assignmentPatients: Patient[];
    selectedAssignmentPatients: Patient[];
  };
  organization: { selectedOrganization: Organization };
}) => {
  return {
    selectedPractices: state.practice.selectedPractices,
    selectedAssignmentPatients: state.patient.selectedAssignmentPatients,
    assignmentPatients: state.patient.assignmentPatients,
    selectedOrganization: state.organization.selectedOrganization,
    assignmentPatientFilters: state.patient.assignmentPatientFilters,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setTargetPatient: (id: PatientId): any =>
      dispatch(dispatchSetTargetPatient(id)),
    selectAssignmentPatient: (patient: Patient) =>
      dispatch(dispatchSelectAssignmentPatient(patient)),
    deselectAssignmentPatient: (patient: Patient) =>
      dispatch(dispatchDeselectAssignmentPatient(patient)),
    selectAllAssignmentPatients: () =>
      dispatch(dispatchSelectAllAssignmentPatients()),
    deselectAllAssignmentPatients: () =>
      dispatch(dispatchDeselectAllAssignmentPatients()),
    setAssignmentPatientFilters: (patientFilter: PatientFilters) =>
      dispatch(dispatchSetAssignmentPatientFilters(patientFilter)),
  };
};

export const PatientAssignmentTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
