import { useContext, Component, createContext, ReactNode } from "react";
import {
  TimerActivityState,
  TimerState,
  TimerUserState,
} from "@/components/countdown/types";
import { UserPatientTimerState } from "@/domain/patient/redux/types";

export const AppContext = createContext<AppWrapperState>({});

export type AppWrapperState = {
  virtualVisitNote?: string;
  setVirtualVisitNote?: (note: string) => void;
  isIdleDetectorOn?: boolean;
  setIsIdleDetectorOn?: (on: boolean) => void;
  isVirtualVisitRunning?: boolean;
  setIsVirtualVisitRunning?: (isRunning: boolean) => void;
  workTimerState?: UserPatientTimerState;
  setWorkTimerState?: (
    timerState: TimerState & {
      userPatientId: string;
      timerActivityState: TimerActivityState;
      timerUserState: TimerUserState;
    }
  ) => void;
};

export type AppWrapperprops = {
  children: ReactNode;
};

export class AppWrapper extends Component<AppWrapperprops, AppWrapperState> {
  constructor(props: AppWrapperprops) {
    super(props);
    this.state = {
      virtualVisitNote: "",
      setVirtualVisitNote: (note: string) =>
        this.setState((prevState: AppWrapperState) => ({
          ...prevState,
          virtualVisitNote: note,
        })),
      workTimerState: {
        userPatientId: "",
        seconds: 0,
        hours: 0,
        minutes: 0,
        timerUserState: TimerUserState.ENABLED,
        timerActivityState: TimerActivityState.INITIAL,
      },
      setWorkTimerState: (
        timerState: TimerState & {
          userPatientId: string;
          timerActivityState: TimerActivityState;
          timerUserState: TimerUserState;
        }
      ) =>
        this.setState((prevState: AppWrapperState) => {
          return {
            ...prevState,
            workTimerState: {
              ...prevState.workTimerState,
              ...{
                seconds: timerState.seconds,
                minutes: timerState.minutes,
                hours: timerState.hours,
                timerUserState: timerState.timerUserState,
                timerActivityState: timerState.timerActivityState,
                userPatientId: timerState.userPatientId,
              },
            },
          };
        }),
      isVirtualVisitRunning: false,
      setIsVirtualVisitRunning: (isRunning: boolean) =>
        this.setState((prevState: AppWrapperState) => ({
          ...prevState,
          isVirtualVisitRunning: isRunning,
        })),
      isIdleDetectorOn: true,
      setIsIdleDetectorOn: (on: boolean) =>
        this.setState((prevState: AppWrapperState) => ({
          ...prevState,
          isIdleDetectorOn: on,
        })),
    };
  }

  override render() {
    return (
      <AppContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const useGlobalAppContext = (): AppWrapperState => {
  return useContext<AppWrapperState>(AppContext);
};
