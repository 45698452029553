import { Role } from "../../domain/role/role.interface";
import { RoleModel } from "../../domain/role/role.model";
import { Policy, PolicyParser } from "./policy.interface";
import { Scope, ScopeParser } from "./scope";

export class UserPolicy extends PolicyParser {
  static async parse(policy: Policy): Promise<UserPolicy> {
    let scope = Object.values(policy)[0];
    if (scope) {
      return new UserPolicy(new Scope(scope.roles));
    }

    return Promise.resolve(new NullUserPolicy(new Scope([])));
  }

  static parseSync(policy: Policy): UserPolicy {
    let scope = Object.values(policy)[0];
    if (scope) {
      return new UserPolicy(new Scope(scope.roles));
    }

    return new NullUserPolicy(new Scope([]));
  }

  static async parseMany(policy: Policy): Promise<UserPolicy[]> {
    const scopeMap = new Map(Object.entries(policy));
    const scopes = Object.keys(policy);
    if (scopes) {
      return scopes.map(
        (scope) =>
          new UserPolicy(
            new Scope(scopeMap.get(scope)?.roles!, ScopeParser.parse(scope))
          )
      );
    }

    return Promise.resolve([new NullUserPolicy(new Scope([]))]);
  }

  /**
   * Gets any scope within this user's policy.
   */
  get anyScope(): Scope {
    return this.scope;
  }
}

export class NullUserPolicy extends PolicyParser {
  get anyScope(): Scope {
    this.scope.role = (): Promise<RoleModel> => {
      return Promise.resolve(RoleModel.make({ name: "-" } as Role));
    };

    return this.scope;
  }
}
