import { TextInput, Select, TextInputMask } from "../../../../components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./form.styles.css";
import React, { FunctionComponent, useState } from "react";
import { Validation } from "../../../../components/form";
import { OrganizationModel } from "../../model";
import { Organization, OrganizationForm } from "../../model/types";
import {
  setOrganizations,
  setOrganizationsPaginationConfig,
} from "../../redux/actions";
import { connect, useSelector } from "react-redux";
import { CountryCodes, usStates } from "../../../../library/types/address";
import Swal from "sweetalert2";
import { OrganizationType, PaginationConfig } from "../../../../library/types";
import { Invoker } from "../../../../library/common/invoker/invoker";
import { RCAResourceActions } from "../../../../library/core/config/actions";
import { ResourceType } from "../../../../library/core/config/resource";
import { CleanTask, FinallyTask } from "../../../../library/common/task";
import { Receiver } from "../../../../library/common/receiver/receiver";
import { OrganizationSupportInformation } from "@/domain/organization/view/form/supportInformation";
import { RootState } from "@/types";

interface Props {
  handleClose: (organization?: OrganizationModel) => void;
  button: string;
  organizations: Organization[];

  selectedOrganization: Organization;

  organizationPageLimit: number;
  paginationConfig: PaginationConfig;

  setOrganizations: (organizations: Organization[]) => {
    type: string;
    payload: Organization[];
  };
  setOrganizationsPaginationConfig: (paginationConfig: PaginationConfig) => {
    type: string;
    payload: PaginationConfig;
  };

  resourceAction: RCAResourceActions;
}

const Component: FunctionComponent<Props> = ({ handleClose, ...props }) => {
  const [photo, setPhoto] = useState(undefined);
  const [imageURL, setImageURL] = useState("");
  const selectedOrganization = useSelector<RootState, Organization | undefined>(
    (state: RootState) => state.organization.selectedOrganization
  );
  const organizations = useSelector<RootState, Organization[]>(
    (state) => state.organization.organizations
  );
  const organization = organizations.find(
    (org: Organization) => org.id === selectedOrganization?.id
  ) as Organization;

  const isEditMode = props.button === "Save Changes";

  const initialValues = {
    id: undefined,
    name: "",
    domain: "",
    type: "",
    kitFulfillment: "",
    status: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    imageUrl: "",
    country: "US",
    contactName: "",
    contactEmail: "",
    contactPosition: "",
    contactPhone: "",
  };

  const isRootOrganization = () => {
    return OrganizationModel.make(props.selectedOrganization).isRootOganization;
  };

  const getInitialValues = () => {
    const organization = props.selectedOrganization;
    // check if an organization is selected, then grab all of its properties
    if (isEditMode && organization) {
      // Indicates form is in edit mode
      return {
        id: organization.id,
        name: organization.name,
        domain: organization.domain,
        type: organization.type,
        kitFulfillment: organization.kitFulfillment ?? "No Kit",
        status: organization.isEnabled,
        address: organization.address.street[0],
        city: organization.address.locality,
        state: organization.address.region,
        zip: organization.address.postalCode,
        imageUrl: "",
        country: organization.address.country,
        contactName: organization.contact?.name ?? ("" as string),
        contactEmail: organization.contact?.email ?? ("" as string),
        contactPosition: organization.contact?.position ?? ("" as string),
        contactPhone: organization.contact?.phone ?? ("" as string),
      };
    }
    return initialValues;
  };

  const disableOrganization = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, disable it!",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Disabled!",
        text: "Organization has been disabled.",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const validateOrganizationType = (): boolean => {
    return !isEditMode || !isRootOrganization();
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={Yup.object({
        id: Validation.id,
        domain: Validation.domain,
        name: Validation.name,
        type: validateOrganizationType()
          ? Validation.organizationType
          : Validation.notRequired,
        kitFulfillment: Validation.fulfillment,
        address: Validation.addressOne,
        city: Validation.city,
        state: Validation.state,
        zip: Validation.zip,
        imageUrl: Validation.imageUrl,
        country: Validation.country,
        contactName: Validation.name,
        contactEmail: Validation.contactEmail,
        contactPosition: Validation.contactPosition,
        contactPhone: Validation.phone,
      })}
      onSubmit={async (
        values: OrganizationForm,
        { setSubmitting, resetForm }
      ) => {
        const serializedOrganizationModel = OrganizationModel.serialize(
          values,
          props.resourceAction
        );
        await Invoker.make<Organization>(
          Receiver.make(
            serializedOrganizationModel,
            ResourceType.organization,
            props.resourceAction
          ),
          CleanTask.make(
            () => resetForm(getInitialValues()),
            () => handleClose(serializedOrganizationModel)
          ),
          FinallyTask.make(() => setSubmitting(false))
        ).invoke();
      }}
    >
      {(formik) => {
        return (
          <Form>
            <div className="modal-body" id="org_form">
              <div className="subject-scroll">
                <div className="form-row d-flex justify-content-center">
                  <div className="col-md-4 mb-3">
                    <TextInput
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Organization Name"
                      required
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <TextInput
                      label="Email Domain"
                      name="domain"
                      type="text"
                      placeholder="exampleorg.com"
                      required={!isEditMode}
                      disabled={isEditMode}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <Select
                      label="Type"
                      name="type"
                      required
                      disabled={isEditMode && isRootOrganization()}
                    >
                      <option value="">Select Organization Type</option>
                      {isEditMode && isRootOrganization() && (
                        <option value="Root">Root</option>
                      )}
                      {Object.values(OrganizationType)
                        .filter((type) => type !== OrganizationType.Root)
                        .map((type) => (
                          <option value={type}>{type}</option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-2 mb-3">
                    <Select
                      label="Kit Fulfillment"
                      name="kitFulfillment"
                      required={!isEditMode}
                      disabled={isEditMode}
                    >
                      <option value="">Select Kit Fulfillment</option>
                      <option value="Anelto">Anelto</option>
                      <option value="Self">Self</option>
                      <option value="No Kit">No Kit</option>
                    </Select>
                  </div>
                  <div className="col-md-10 col-lg-10 mb-3">
                    <TextInput
                      label="Address"
                      name="address"
                      type="text"
                      placeholder="Full Address Including Apt. #"
                      required
                    />
                  </div>
                  <div className="col-md-10 col-lg-10 mb-3">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <TextInput
                          label="City"
                          name="city"
                          type="text"
                          placeholder="City"
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <Select label="State" name="state" required>
                          <option value="">Select State</option>
                          {usStates.map((state, key) => (
                            <option key={key} value={state}>
                              {state}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group col-md-2">
                        <TextInput
                          label="Zip Code"
                          name="zip"
                          type="text"
                          placeholder="Zip Code"
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <Select label="Country" name="country" required>
                          {CountryCodes.filter(
                            (country) => country.code === "US"
                          ).map((country, key) => (
                            <option key={key} selected value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 col-lg-10 mb-3">
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <TextInput
                          label="Contact Name"
                          name="contactName"
                          type="text"
                          placeholder="Contact Name"
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <TextInput
                          label="Contact Email"
                          name="contactEmail"
                          type="text"
                          placeholder="Contact Email"
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <TextInput
                          label="Contact Position"
                          name="contactPosition"
                          type="text"
                          placeholder="Contact Position"
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <TextInputMask
                          type="tel"
                          value={formik.values.contactPhone}
                          label="Contact Phone"
                          name="contactPhone"
                          mask="+1 (999) 999-9999"
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "contactPhone",
                              e.target.value
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {/* Let's hide the code for the upload of organization image  for now so we can use it later when we will integrate upload image feature */}
                  <div className="col-lg-10 col-md-10 d-none">
                    <div className="row d-flex justify-content-start">
                      <div className="col-md-12 col-lg-12 mb-3">
                        <label>Logo</label>
                        <div className="form-inline ml-1 row">
                          <div className="col-md-5 col-sm-4 d-flex justify-content-start mb-2">
                            <TextInput
                              label=""
                              name="photo"
                              type="file"
                              className="hidden"
                              onChange={(event: any) => {
                                setPhoto(event.currentTarget.files[0]);
                                setImageURL(
                                  URL.createObjectURL(event.target.files[0])
                                );
                              }}
                              required={false}
                            />
                          </div>
                          <div className="col-md-2 col-sm-4 mb-2">
                            <div>
                              {imageURL && photo ? (
                                <img
                                  style={{ height: "50px", width: "80px" }}
                                  src={imageURL}
                                />
                              ) : (
                                <div
                                  className="form-control d-flex justify-content-center"
                                  style={{
                                    height: "50px",
                                    padding: "18px",
                                    fontSize: "10px",
                                    width: "100%",
                                  }}
                                >
                                  Preview
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-6 col-sm-4">
                            <div className="row d-flex justify-content-reverse">
                              <button
                                onClick={() => {}}
                                type="button"
                                className=" btn btn- rc-btn mt-1"
                              >
                                <i className="feather icon-upload"></i>Upload
                              </button>
                              <button
                                onClick={() => {
                                  setPhoto(undefined);
                                }}
                                type="button"
                                className=" btn btn-danger mt-1"
                              >
                                <i className="feather icon-x"></i>Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 col-lg-10 mb-3">
                    <div className="row">
                      {isEditMode && (
                        <OrganizationSupportInformation
                          organization={organization}
                          supportInformation={organization.supportInformation}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-2">
                    {isEditMode && (
                      <button
                        disabled
                        type="button"
                        className="btn btn-danger sweet-multiple"
                        onClick={disableOrganization}
                      >
                        Disable
                      </button>
                    )}
                  </div>

                  <div className="col-6 mt-2 d-flex justify-content-end">
                    <button
                      onClick={() => {
                        handleClose();
                        formik.resetForm(initialValues);
                      }}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="btn rc-btn"
                    >
                      {props.button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: {
  organization: {
    organizations: Organization[];
    selectedOrganization: Organization;
    paginationConfig: PaginationConfig;
    organizationPageLimit: number;
  };
}) => {
  return {
    organizations: state.organization.organizations,
    organizationPageLimit: state.organization.organizationPageLimit,
    paginationConfig: state.organization.paginationConfig,

    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setOrganizations: (organizations: Organization[]): any =>
      dispatch(setOrganizations(organizations)),
    setOrganizationsPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setOrganizationsPaginationConfig(paginationConfig)),
  };
};

export const OrganizationFormComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
