import React from "react";
import MultiRangeSlider from "@/components/slider/multiRangeSlider.component";
import "./styles.css";

interface Props {}
interface State {
  name: string;
}

export class ThresholdTab extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "Blood Pressure",
    };
  }

  render() {
    return (
      <>
        <div className="charts-area  p-3">
          <h6 className="text-center pb-4">Blood Pressure</h6>
          <p className="threshold-range text-muted">Threshold Ranges</p>
          <div className="blood-pressure-threshold">
            <div className="d-flex">
              <p className="vertical-change">Systolic</p>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                }}
              >
                <MultiRangeSlider
                  name="Systolic"
                  MIN={0}
                  range={[19, 50, 77, 88]}
                  MAX={100}
                />
              </div>
            </div>

            <div className="d-flex mt-5">
              <p className="vertical-change">Diastolic</p>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                }}
              >
                <MultiRangeSlider
                  name="Diastolic"
                  MIN={0}
                  range={[3, 30, 66, 80]}
                  MAX={100}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
