import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Toggle } from "rsuite";
import { connect } from "react-redux";

import { Patient } from "@/domain/patient/model/types";
import {
  Intervention,
  Interventions,
  PatientNoteType,
} from "@/library/types/note";
import { Note, NoteObservation } from "@/domain/notes/model/types";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { NoteModel } from "@/domain/notes/model";
import { ResourceType } from "@/library/core/config/resource";
import { RCAResourceActions } from "@/library/core/config/actions";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { TextArea, Validation } from "@/components/form";
import { MultiSelect } from "@/components/form/select/multiSelect";
import { ObservationFormFields } from "./components/observationFormFields";
import { dispatchSetPatientTimer } from "@/domain/patient/redux/actions";
import { PatientId, UserPatientTimerState } from "@/domain/patient/redux/types";
import { RootState } from "@/types";
import { User } from "@/domain/user/model";
import { ObservationType } from "@/domain/observations/types";
import { TimerState } from "@/components/countdown/types";
import { validateObservationsFields } from "./helpers";
import NoteTimer from "../NoteTimer";
import { dispatchAddPatientNote } from "@/domain/notes/redux/actions";

interface State {
  observationType: ObservationType | undefined | string;
  unit: string | undefined;
  timerStopped: boolean;
}

type OwnProps = {
  onSetFormik: (formik: any) => void;
  replyToIds: string[];
  handleClose: () => void;
  patient: Patient;
  updateParentNoteInReduxIfChild: (note: Partial<Note>) => void;
  includeTimer?: boolean;
};
type ConnectedProps = {
  patientTimer: { [key: string]: UserPatientTimerState };
  currentUser: User | null;
};
type DispatchProps = {
  setTimer: (timer: UserPatientTimerState) => void;
  addPatientNote: (patientId: PatientId, note: Note) => void;
};
type Props = ConnectedProps & DispatchProps & OwnProps;

class ObservationNoteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      observationType: "",
      unit: undefined,
      timerStopped: false,
    };
  }

  get initialValues() {
    return {
      patientId: this.props.patient.id as string,
      comment: "",
      directPatientContact: false,
      seconds: 0,
      intervention: [],
      replyToIds: this.props.replyToIds,
      type: PatientNoteType.Observation,

      observations: [
        {
          patientId: this.props.patient.id as string,
        },
      ],
    } as any;
  }

  setObservationsFieldValue(
    formik: any,
    index: number,
    nextObservation: NoteObservation
  ) {
    const observations = formik.values.observations as NoteObservation[];

    formik.setFieldValue(
      "observations",
      observations.map((previousObservation: NoteObservation, i: number) => {
        if (index === i) {
          return nextObservation;
        }
        return previousObservation;
      })
    );
  }

  calculateSeconds(formik: any, timerState: TimerState) {
    const seconds = timerState.seconds + timerState.minutes * 60;
    formik.setFieldValue("seconds", seconds);
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.includeTimer) {
      const patientTimer =
        this.props.patientTimer[
          this.props.currentUser!.id! + this.props.patient.id
        ];
    }
  }

  handleSubmit = async (
    values: Partial<Note>,
    form: FormikHelpers<Partial<Note>>
  ) => {
    const { patient } = this.props;
    const { setSubmitting, resetForm } = form;
    this.setState({
      timerStopped: true,
    });
    values.comment = values.comment === "" ? null : values.comment;
    if (validateObservationsFields(values)) {
      await Invoker.make<Note>(
        Receiver.make(
          NoteModel.make(values as Note, true),
          ResourceType.note,
          RCAResourceActions.Create
        ),
        CleanTask.make(
          () => resetForm(this.initialValues),
          () => this.props.handleClose(),
          () => this.props.updateParentNoteInReduxIfChild(values as Note),
          () =>
            patient?.id
              ? this.props.addPatientNote(patient.id, values as Note)
              : null
        ),
        FinallyTask.make(() => setSubmitting(false))
      ).invoke();
    }
  };

  render() {
    return (
      <>
        <Formik
          initialValues={this.initialValues}
          validationSchema={Yup.object({
            comment: Validation.notRequired.nullable(),
            directPatientContact: Validation.notRequired,
            intervention: Validation.notRequiredArray,
            replyToIds: Validation.notRequiredArray,
          })}
          onSubmit={this.handleSubmit}
        >
          {(formik) => {
            this.props.onSetFormik(formik);
            return (
              <Form>
                <div className="modal-body">
                  <div className="subject-scroll">
                    <div className="row">
                      {this.props.includeTimer && (
                        <div className="col-md-1">
                          <div className="mt-2">
                            <NoteTimer
                              onChange={(currentState: TimerState) =>
                                this.calculateSeconds(formik, currentState)
                              }
                              isStopped={this.state.timerStopped}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Direct Patient Contact</label>
                          <div>
                            <Toggle
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "directPatientContact",
                                  value
                                );
                              }}
                              checked={formik.values.directPatientContact}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Intervention(s)</label>
                          <div>
                            <MultiSelect
                              data={Object.keys(Interventions)
                                .filter(
                                  (intervention) =>
                                    intervention !==
                                    Intervention.LeftPhoneMessage
                                )
                                .map((key) => {
                                  return {
                                    value: key,
                                    label: Interventions[key as Intervention],
                                  };
                                })}
                              onChange={(intervention: any) => {
                                if (
                                  !formik.values.directPatientContact &&
                                  (intervention.includes(
                                    "VIDEO_VISIT_COMPLETED"
                                  ) ||
                                    intervention.includes(
                                      "DIRECT_PATIENT_CONTACT"
                                    ))
                                ) {
                                  formik.setFieldValue(
                                    "directPatientContact",
                                    true
                                  );
                                }
                                formik.setFieldValue(
                                  "intervention",
                                  intervention.filter((a: any) => !!a)
                                );
                              }}
                              value={formik.values.intervention}
                              isSearchable={false}
                              placeholder="Select Intervention"
                              sortable={false}
                              findValueBy={(option: any) => option.value}
                              picker="check"
                              name={"intervention"}
                              optional={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <TextArea
                            label="Narrative"
                            name="comment"
                            type="checkbox"
                            required={false}
                            rows="7"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          {formik.values.observations?.map(
                            (o: NoteObservation, index: number) => (
                              <ObservationFormFields
                                key={`observForm${index}`}
                                setObservationsFieldValue={(
                                  index: number,
                                  nextObservation: NoteObservation
                                ) =>
                                  this.setObservationsFieldValue(
                                    formik,
                                    index,
                                    nextObservation
                                  )
                                }
                                index={index}
                              />
                            )
                          )}
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary font-weight-bold btn-rounded"
                              onClick={() => {
                                formik.setFieldValue("observations", [
                                  ...(formik.values.observations || []),
                                  {
                                    patientId: this.props.patient.id as string,
                                  },
                                ]);
                              }}
                            >
                              +
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary font-weight-bold btn-rounded"
                              onClick={() => {
                                const observations: NoteObservation[] =
                                  formik.values.observations || [];
                                observations.pop();
                                formik.setFieldValue(
                                  "observations",
                                  observations
                                );
                              }}
                            >
                              -
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  patientTimer: state.patient.timerByUserPatientId,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTimer: (time: UserPatientTimerState) =>
      dispatch(dispatchSetPatientTimer(time)),
    addPatientNote: (patientId: PatientId, note: Note) =>
      dispatch(dispatchAddPatientNote(patientId, note)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationNoteForm);
