import React from "react";
import styles from "./styles.module.scss";
import { BiometricType } from "@/domain/patient/view/tabs/summary/constants";

type Props = {
  includeCharts: boolean;
  setIncludeCharts: (included: boolean) => void;
  includeTables: boolean;
  setIncludeTables: (included: boolean) => void;
  addBiometricOptions: (biometricType: BiometricType) => void;
  removeBiometricOptions: (biometricType: BiometricType) => void;
};

const BiometricOptions: React.FC<Props> = ({
  includeCharts,
  setIncludeCharts,
  includeTables,
  setIncludeTables,
  addBiometricOptions,
  removeBiometricOptions,
}) => {
  const handleGraphCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setIncludeCharts(isChecked);
  };
  const handleTablesCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setIncludeTables(isChecked);
  };
  return (
    <div>
      <div className="text-center font-weight-bold h6">
        Include Biometric Options
      </div>
      <div className="border p-3 mt-2">
        <div className="row">
          <div className="col-5">
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                checked={includeCharts}
                onClick={handleGraphCheckboxChange}
              />{" "}
              Graphs
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                checked={includeTables}
                onClick={handleTablesCheckboxChange}
              />{" "}
              Tables
            </div>
          </div>
          <div className="col-1">
            <div
              style={{ borderLeft: "1px solid", width: "1px", height: "300px" }}
            ></div>
          </div>
          <div className="col-5">
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.HeartRate);
                  } else {
                    removeBiometricOptions(BiometricType.HeartRate);
                  }
                }}
              />{" "}
              Heart Rate
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.BloodPressure);
                  } else {
                    removeBiometricOptions(BiometricType.BloodPressure);
                  }
                }}
              />{" "}
              Blood Pressure
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.PulseOximetry);
                  } else {
                    removeBiometricOptions(BiometricType.PulseOximetry);
                  }
                }}
              />{" "}
              Pulse Oximetry
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.Weight);
                  } else {
                    removeBiometricOptions(BiometricType.Weight);
                  }
                }}
              />{" "}
              Weight
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.GlucoseLevel);
                  } else {
                    removeBiometricOptions(BiometricType.GlucoseLevel);
                  }
                }}
              />{" "}
              Glucose Level
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.Temperature);
                  } else {
                    removeBiometricOptions(BiometricType.Temperature);
                  }
                }}
              />{" "}
              Temperature
            </div>
            <div className={styles.summaryCheckbox}>
              <input
                type="checkbox"
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    addBiometricOptions(BiometricType.Spirometry);
                  } else {
                    removeBiometricOptions(BiometricType.Spirometry);
                  }
                }}
              />{" "}
              Spirometry
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiometricOptions;
