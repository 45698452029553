import {
  AppointmentLocation,
  AssessmentReason,
  CallDirection,
  CallLocation,
  ObservationReason,
  Outcome,
  Intervention,
  PatientNoteType,
} from "@/library/types/note";
import { PatientObservation } from "@/domain/patient/model/types";
import {
  Assessment,
  AssessmentQuestion,
} from "@/domain/assessment/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import {
  VolumeUnit,
  FlowUnit,
  PressureUnit,
  ObservationType,
} from "@/domain/observations/types";
import { ChangeDataType } from "@/domain/patient/view/AlertsTableNew/types";

export interface Note {
  id?: string;

  /**
   * ID of the patient who took this assessment.
   */
  patientId?: PatientId;

  /**
   * Type of note.
   */
  type: PatientNoteType;

  /**
   * If a note was manually entered by a clinician, the author is recorded.
   */
  author?: NoteAuthor | null;
  comment?: string | null;
  severityScore: number;
  directPatientContact?: boolean;
  outcome?: Outcome[] | null;
  intervention?: Intervention[] | null;
  minutes?: number | null;
  seconds?: number | null;

  /**
   * The patient fulfilled a scheduled appointment.
   */
  appointment?: {
    location: AppointmentLocation;
  } | null;

  /**
   * The patient completed or missed a scheduled assessment.
   */
  assessment?: {
    reason: AssessmentReason;
    assessment: {
      id: string;
      name: string;
    } | null;
  } | null;

  /**
   * A clinician placed or received a phone call.
   */
  call?: {
    direction: CallDirection;
    location: CallLocation;
  } | null;

  /**
   * A freeform note added by a clinician to the patient's record.
   */
  replyToIds?: string[];

  /**
   * Describes if Note was created manually.
   *
   */
  isManual?: boolean;

  /**
   * A clinician sent a message.
   */
  message?: {
    location: CallLocation;
  } | null;

  /**
   * The patient either:
   *  - completed an observation which is inside its acceptable ranges
   *  - completed an observation which falls outside its acceptable ranges
   *  - missed a scheduled observation
   *
   * Alternatively, a clinician manually entered an observation.
   */
  observation?: {
    reason: ObservationReason;
    observations: NoteObservation[];
  } | null;

  /**
   * Note's resolved observations. Available only on observation type of Note.
   */
  resolvedObservations?: PatientObservation[];

  /**
   * Note's resolved assessment. Available only on observation type of Note.
   */
  resolvedAssessment?: Assessment;

  _meta?: {
    id: string;
    created: string;
  };
  parentId: string | undefined;
  replies: number | undefined;
  original?: Note;

  // only used in createNote MiddleWare
  observations?: (NoteObservation & {
    type: ObservationType;
  })[];
  /**
   * Describes if a not has been replied to.
   *
   */
  isComplete?: boolean;
  /**
   * Information of child notes
   */
  repliedToNotes: RepliedToNotes[];
  /**
   * disposition of note
   */
  disposition: NoteDisposition;
  /**
   * Whether a patient is requesting a video or call visit
   */
  requestType?: PatientRequestType;
  data?: ChangeDataType;
}

export interface NoteAuthor {
  name: string;
  credentials: string;
  email?: string | null;
  id?: string | null;
  canAccumulateBillableTime?: boolean;
}

export interface NoteObservation {
  /**
   * Type of note observation
   */
  type: ObservationType;
  /**
   * Unit of observation
   */
  unit: string;
  /**
   * Value of observation
   */
  value: number;
  /**
   * ID of the patient this observation was recorded for.
   */
  patientId: string;

  /**
   * ID of the document in the legacy `telehealth_events` collection this
   * observation was migrated from. This value is not present for
   * manually-entered observations.
   */
  legacyDocumentId?: string | null;

  /**
   * ID of the manually entered note that triggered this event.
   * This value is not present for telehealth_events observations.
   */
  manualNoteId?: string | null;

  /**
   * Information about the accessory used to make the observation. This
   * value is not present for manually-entered observations.
   */
  accessory?: {
    /**
     * A special identifier for the accessory. For bluetooth accessories,
     * this is the MAC address of the bluetooth radio.
     */
    id: string;
    /**
     * Type of interface used to connect the accessory to the patient's
     * hub device. If the interface type is unknown, this value will be `null`.
     */
    interfaceType: "BLUETOOTH" | null;
  };
  /**
   * Identifies the method by which biometric measurements were transmitted.
   */
  source: "ACCESSORY" | "PATIENT_ENTRY" | "CLINICIAN_ENTRY" | null;
  actorUserId?: string;
  timeOfObservation?: string;
  fev1?: {
    value: number;
    unit: `${VolumeUnit.Liters}`;
  };
  pef?: {
    value: number;
    unit: `${FlowUnit.LitersPerMinute}`;
  };
  systolic?: {
    value: number;
    unit: `${PressureUnit.mmhg}` | `${PressureUnit.kpa}`;
  };
  diastolic?: {
    value: number;
    unit: `${PressureUnit.mmhg}` | `${PressureUnit.kpa}`;
  };
  data?: ChangeDataType;
}

export enum NoteDisposition {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Historical = "HISTORICAL",
}

export enum NoteAction {
  CreateAppointmentNote = "Appointment Note",
  CreateCallNote = "Call Note",
  CreateClinicalNote = "Clinical Note",
  CreateMessageNote = "Message Note",
  CreateObservationNote = "Observation Note",
  CreateAssessmentNote = "Assessment Note",
}

export enum NoteStatus {
  Complete = "Complete",
  Incomplete = "Incomplete",
}

export enum PatientRequestType {
  Phone = "PHONE",
  Video = "VIDEO",
}

export interface NoteForm extends Note {
  reason?: string;
  location?: string;
  timeOfObservation?: string;
  questions?: AssessmentQuestion[];
}

export enum AlertLevel {
  NO_ALERT = "white",
  GREEN_ALERT = "green",
  RED_ALERT = "red",
  YELLOW_ALERT = "yellow",
  BLACK_ALERT = "black",
  DISABLED_ALERT = "gray",
}

export enum SeverityLevel {
  SEVERITY_RED = 5,
  SEVERITY_YELLOW = 1,
  SEVERITY_GREEN = 0,
}

export interface RepliedToNotes {
  type: PatientNoteType;
  comment: string;
  created: string;
  seconds: number;
  intervention: Intervention[];
  directPatientContact: boolean;
  _meta: Note["_meta"];
}

export enum AlertLevelColor {
  NO_ALERT = "#FFFFFF",
  GREEN_ALERT = "#66C793",
  RED_ALERT = "#F76363",
  YELLOW_ALERT = "#FFCE1F",
  BLACK_ALERT = "#000000",
  DISABLED_ALERT = "#BBBBBB",
}

export enum AlertLevelBGColor {
  NO_ALERT = "#FFFFFF",
  GREEN_ALERT = "#EDFFF5",
  RED_ALERT = "#FFEBEB",
  YELLOW_ALERT = "#FFF7E8",
  BLACK_ALERT = "#000000",
  DISABLED_ALERT = "#000000",
}

export enum AlertLevelNew {
  GREEN_ALERT = "Normal",
  RED_ALERT = "High",
  YELLOW_ALERT = "Medium",
}
