import { Serializer } from "./../serializer";
import { PracticeModel } from "@/domain/practice/model";
import { PracticeForm } from "@/domain/practice/model/types";
import { CreatePracticeSerializer, ModifyPracticeSerializer } from "./";
import { RCAResourceActions } from "../../core/config/actions";

/**
 * Practice domain serializer. It holds information about all serializers in this domain.
 */
export class PracticeSerializer extends Serializer<
  PracticeForm,
  PracticeModel
> {
  static for(
    action: RCAResourceActions,
    from: PracticeForm
  ): PracticeSerializer {
    return new PracticeSerializer(action, from);
  }

  serialize(): PracticeModel {
    const serializer = new (this.get(this.action))(this.formData);
    return serializer.serialize();
  }

  /**
   * Map of currently supported resource actions to their respective serializers.
   * @protected
   */
  protected serializers = {
    [RCAResourceActions.Create]: CreatePracticeSerializer,
    [RCAResourceActions.ModifyBasicInfo]: ModifyPracticeSerializer,
  };
}
