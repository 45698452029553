import HighchartsReact from "highcharts-react-official";
import React, { FunctionComponent } from "react";
import Highcharts from "highcharts/highstock";

// Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/no-data-to-display.src")(Highcharts);

interface Props {
  options: {};
  containerProps?: {};
  allowChartUpdate?: boolean;
}

const HighChart: FunctionComponent<Props> = ({
  options,
  containerProps,
  allowChartUpdate = true,
}) => {
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={options}
        containerProps={containerProps}
        allowChartUpdate={allowChartUpdate}
      />
    </>
  );
};

export default HighChart;
