import { useEffect, useRef, useState } from "react";
import cx from "clsx";
import styles from "./styles.module.scss";
import { ClearSelect } from "@/assets/icons/dashboard";
import { Intervention, Interventions } from "@/library/types/note";
import { MultiSelectDataType } from "@/domain/patient/view/CreateNote/multiSelect";

interface InterventionProps {
  data: string;
  onClick: (item: MultiSelectDataType) => void;
}

export const InterventionComponent = (props: InterventionProps) => {
  return (
    <>
      <div className={cx(styles.intervention)}>
        <label>{Interventions[props.data as Intervention]}</label>
        <div
          className={cx(styles.clearSelect)}
          onClick={() =>
            props.onClick({
              label: Interventions[props.data as Intervention],
              value: props.data,
            })
          }
        >
          <img src={ClearSelect} alt="clear" />
        </div>
      </div>
    </>
  );
};
