import { AxiosPromise, AxiosInstance } from "axios";
import axios, { AxiosResponse } from "axios";

import { unique } from "@/util/unique";
import { deserialize } from "@/library/model";
import { CanMake } from "@/library/model/model.interface";
import { HasId } from "@/library/types";
import { Http } from "./http";

/**
 * RemoteCareAPISync is the core of the remoteCare API syncing mechanism.
 * It assists in saving new entities in form of models and fetching saved entities.
 * It knows If the model has been saved before, it simply updates it.
 */
export class RemoteCareAPISync<T extends HasId> {
  static readonly host = (window as any)._env_.API_URL;

  constructor(private path: string, private alternateUrl?: string) {}

  /**
   * Fetch entity from remoteCare API.
   *
   * @param id
   */
  fetch(id: string): AxiosPromise {
    return this.http.get(
      this.alternateUrl ? `${this.alternateUrl}/${id}` : `${this.url}/${id}`
    );
  }

  /**
   * Get instance of axios configured withCredentials.
   */
  get http(): AxiosInstance {
    return Http();
  }

  /**
   * Save entity to remoteCareAPI. If the entity have been saved before, it simply updates it.
   * Hence the name, Sync.
   *
   * @param data
   */
  save(data: T): AxiosPromise {
    const { id } = data;

    if (id !== undefined) {
      delete data.id;
      if (this.path === "patients") {
        return this.http.post(`${this.url}/${id}/actions/modify`, data);
      }
      return this.http.post(
        `${this.url}/${id}/actions/modify-basic-info`,
        data
      );
    }

    return this.http.post(
      this.alternateUrl ? this.alternateUrl : this.url,
      data
    );
  }

  /**
   * Build full URL.
   *
   * @private
   */
  private get url() {
    return `${RemoteCareAPISync.host}/${this.path}`;
  }

  /**
   * Concurrently resolves list of Ids for a given entity.
   *
   * @param ids
   * @param path
   * @param make
   */
  static async concurrently<E, M>(
    ids: string[],
    path: string,
    make: CanMake<E, M>
  ): Promise<M[]> {
    return (
      await axios.all(
        ids.filter(unique).map((id) => new RemoteCareAPISync<E>(path).fetch(id))
      )
    ).map((response: AxiosResponse) => deserialize(response.data, make));
  }
}
