/**
 * Location of an appointment.
 */
export enum AppointmentLocation {
  /**
   * Patient's home.
   */
  Home = "HOME",
  /**
   * Medical office.
   */
  Office = "OFFICE",
  /**
   * Remote telemedicine.
   */
  Telemedicine = "TELEMEDICINE",
}

/**
 * Reason an assessment note was generated.
 */
export enum AssessmentReason {
  /**
   * The patient completed a scheduled assessment.
   */
  Completed = "COMPLETED",
  /**
   * The patient missed a scheduled assessment.
   */
  Missed = "MISSED",
}

/**
 * Direction of a phone call.
 */
export enum CallDirection {
  /**
   * Inbound to a clinician.
   */
  Inbound = "INBOUND",
  /**
   * Outbound from a clinician.
   */
  Outbound = "OUTBOUND",
}

/**
 * Location to/from which a call was placed.
 */
export enum CallLocation {
  Caregiver = "CAREGIVER",
  HomeCare = "HOME_CARE",
  Other = "OTHER",
  Patient = "PATIENT",
  Provider = "PROVIDER",
}

/**
 * Reason an observation note was generated.
 */

export enum ObservationReason {
  /**
   * The patient is compliant with instructions to take measurements.
   */
  InRange = "IN_RANGE",
  /**
   * An observation was manually entered by a clinician.
   */
  Manual = "MANUAL",
  /**
   * The patient missed a scheduled measurement.
   */
  Missed = "MISSED",
  /**
   * A patient's measurement falls outside the acceptable bounds.
   */
  OutOfRange = "OUT_OF_RANGE",
}

/**
 * An outcome is a description of the action taken by a medical clinician in
 * response to one or more patient events.
 */
export enum Outcome {
  /**
   * No action was taken.
   */
  NoAction = "NO_ACTION",
  /**
   * Patient was provided with guided device setup and user training.
   */
  DeviceSetupAndTraining = "DEVICE_SETUP_AND_TRAINING",
  /**
   * Patient was recommended to visit an emergency room or urgent care center.
   */
  EmergencyCareRecommendation = "EMERGENCY_CARE_RECOMMENDATION",
  /**
   * Patient was scheduled for a home visit.
   */
  HomeVisit = "HOME_VISIT",
  /**
   * Patient was scheduled to be admitted to a hospital.
   */
  HospitalAdmittance = "HOSPITAL_ADMITTANCE",
  /**
   * Patient was given medical intervention.
   */
  Intervention = "INTERVENTION",
  /**
   * Patient was scheduled for an office visit.
   */
  OfficeVisit = "OFFICE_VISIT",
  /**
   * Action was taken that is not included in this list.
   */
  Other = "OTHER",
  /**
   * Patient was scheduled for a telehealth call.
   */
  TelehealthVisit = "TELEHEALTH_VISIT",
}

export enum PatientNoteType {
  Appointment = "APPOINTMENT",
  Call = "CALL",
  Message = "MESSAGE",
  Clinical = "CLINICAL",
  Observation = "OBSERVATION",
  Assessment = "ASSESSMENT",
  Timelog = "TIMELOG",
  PatientRequest = "PATIENT_REQUEST",
  Reviewed = "REVIEWED",
}

/**
 * Note types which has location
 */
export type HasLocation = "appointment" | "message" | "call";

export enum Intervention {
  NoActionTaken = "NO_ACTION",
  DeviceSetupAndTraining = "DEVICE_SETUP_AND_TRAINING",
  RecommendedEDUrgentCare = "EMERGENCY_CARE_REFERRED",
  PreventedAnEDVisit = "EMERGENCY_CARE_PREVENTED",
  RefferedForHomeNursingVisit = "HOME_VISIT_REFERRED",
  PreventedNursingVisit = "NURSING_VISIT_PREVENTED",
  PatientHospitalized = "PATIENT_HOSPITALIZED",
  OrderReceived = "MEDICATION_ORDER_RECEIVED",
  RefferedForOfficeClinicVisit = "OFFICE_VISIT_REFERRED",
  LeftMessageOnPatientConsole = "DEVICE_MESSAGE",
  LeftPhoneMessage = "PHONE_MESSAGE",
  ProviderContacted = "PROVIDER_CONTACTED",
  ScheduledVideoVisit = "VIDEO_VISIT_SCHEDULED",
  VideoVisitAttempted = "VIDEO_VISIT_ATTEMPTED",
  VideoVisitConducted = "VIDEO_VISIT_COMPLETED",
  ContactedPatientPerPhone = "DIRECT_PATIENT_CONTACT",
  AttemptedToPatientContactPerPhone = "DIRECT_PATIENT_CONTACT_ATTEMPTED",
  PatientEducationProvided = "PATIENT_EDUCATION",
  Other = "OTHER",
}

export const Interventions: Record<Intervention, string> = {
  [Intervention.PatientEducationProvided]: "Patient education provided",
  [Intervention.DeviceSetupAndTraining]: "Kit set-up and training done",
  [Intervention.ScheduledVideoVisit]: "Video visit scheduled",
  [Intervention.VideoVisitAttempted]: "Video visit attempted",
  [Intervention.VideoVisitConducted]: "Video visit conducted",
  [Intervention.AttemptedToPatientContactPerPhone]: "Telephone call attempted ",
  [Intervention.ContactedPatientPerPhone]: "Telephone call conducted",
  [Intervention.LeftMessageOnPatientConsole]: "Left message for patient",
  [Intervention.ProviderContacted]:
    "Contacted provider, no new orders received",
  [Intervention.OrderReceived]: "Contacted provider, new orders received",
  [Intervention.RecommendedEDUrgentCare]: "Recommended ED/Urgent Care",
  [Intervention.PreventedAnEDVisit]: "Prevented ED visit",
  [Intervention.RefferedForHomeNursingVisit]: "Referred for home nursing visit",
  [Intervention.PreventedNursingVisit]: "Prevented home nursing visit",
  [Intervention.RefferedForOfficeClinicVisit]:
    "Referred for office/clinic visit",
  [Intervention.PatientHospitalized]: "Patient hospitalized",
  [Intervention.NoActionTaken]: "No Action Taken",
  [Intervention.Other]: "Other",
  [Intervention.LeftPhoneMessage]: "Telephone message left",
};

export enum SeverityScore {
  SEVERITY_RED = 5,
  SEVERITY_YELLOW = 2,
  SEVERITY_GREEN = 0,
}
