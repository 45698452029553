import { RemoteCareAPISync } from "../core/api";

/**
 * Uses a name and state to return an array of all matching providers and their NPI numbers.
 * @param firstName first name of provider
 * @param lastName last name of provider
 * @param state state provider's practice is in
 * @returns array of providers matching criteria
 */
export async function npiLookup(
  firstName: string,
  lastName: string,
  state: string
) {
  const apiSync = new RemoteCareAPISync<any>(
    `npi?firstName=${firstName}&lastName=${lastName}&state=${state}`
  );
  const results = await apiSync.fetch(``);
  return results.data.lookupResults;
}
