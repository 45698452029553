import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { RootState, SelectOption } from "@/types";

import styles from "./styles.module.scss";
import { fetchProgramsForOrganization } from "@/components/pathway/functions";
import { Program } from "@/domain/program/model/types";

interface Props {
  onChange: (selectedProgramId: string, description: string) => void;
  selectedProgramId?: string;
  currentProgram: string;
}

export const ProgramFilter: React.FC<Props> = ({
  onChange,
  selectedProgramId,
  currentProgram,
}) => {
  const filters = useSelector((state: RootState) => state.patient.filters);
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );

  const [programOptions, setProgramOptions] = useState<SelectOption[]>([]);
  const [pending, setPending] = useState(false);

  const fetchPrograms = async () => {
    if (selectedOrganization?.id) {
      setPending(true);
      const programs = await fetchProgramsForOrganization(
        selectedOrganization.id
      );

      const options = programs.map((model: Program) => {
        return {
          value: model._meta?.id as string,
          label: model.name,
        };
      });

      setProgramOptions(options);
      setPending(false);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  useEffect(() => {
    if (selectedOrganization) {
      fetchPrograms();
    }
  }, [selectedOrganization]);

  return (
    <BasicMultiSelect
      data={programOptions}
      onChange={async (selectedProgramId: string) => {
        const description =
          programOptions.find(
            (programOption) => programOption.value === selectedProgramId
          )?.label ?? "";
        onChange(selectedProgramId, description);
      }}
      value={filters.programIds ? filters.programIds[0] : selectedProgramId}
      isSearchable={true}
      placeholder={currentProgram}
      onOpen={fetchPrograms}
      config={{ showLoadingIndicator: pending }}
      className={styles.select}
    />
  );
};

export default ProgramFilter;
