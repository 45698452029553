/**
 * The status of a patient's program.
 */
export enum PatientState {
  Added = "ADDED",
  Preactive = "PREACTIVE",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Completed = "COMPLETED",
}
/**
 * A history item detailing a transition in the patient lifecycle.
 */
export interface PatientStateHistoryItem {
  time: string;
  toState: PatientState;
  transition: PatientTransition;
}

/**
 * The possible transitions from one state to another.
 */
export enum PatientTransition {
  /**
   * Triggered by 'create patient' endpoint.
   * May also be manually activated if a 'COMPLETED' patient re-enrolls.
   * Will generate a new encounter ID.
   */
  AddPatient = "ADD_PATIENT",
  /**
   * Confirms a patient for a program.
   */
  ActivatePatient = "ACTIVATE_PATIENT",
  /**
   * Delete test or duplicate patients.
   */
  DeletePatient = "DELETE_PATIENT",
  /**
   * Patient declines to participate in a program.
   */
  PatientDeclined = "PATIENT_DECLINED",
  /**
   * When the patient completes their first vital measurement or assessment,
   * the patient is automatically activated.
   */
  FirstBiometric = "FIRST_BIOMETRIC",
  /**
   * Temporarily deactivates a patient.
   */
  DeactivatePatient = "DEACTIVATE_PATIENT",
  /**
   * Reactivate an inactive patient.
   */
  ReactivatePatient = "REACTIVATE_PATIENT",
  /**
   * Complete a patient program.
   */
  CompletePatient = "COMPLETE_PATIENT",
}
