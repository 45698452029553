import React from "react";
import { connect } from "react-redux";

import { User, UserModel } from "@/domain/user/model";
import { Iterator } from "@/library/iteration/iterator";
import { PatientModel } from "@/domain/patient/model";
import {
  Patient,
  PatientEndpoints,
  PatientFilters,
} from "@/domain/patient/model/types";
import { Organization } from "@/domain/organization/model/types";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { ModelCollection } from "@/library/model";
import { Button } from "rsuite";
import { PatientsTable } from "@/domain/patient/view/PatientsPage/table/table.component";

type Props = {
  selectedOrganization: Organization;
  assignmentPatientFilters: PatientFilters;
  assignmentPatients: Patient[];
  selectedAssignmentPatients: Patient[];
};

type PatientComponentState = {
  search: string;
  collapsed: boolean;
  assignPatientsConfig: {
    collapsed: boolean;
  };
  careTeamTabConfig: {
    collapsed: boolean;
  };
  userModels: UserModel[];
  reload: boolean;
  userName: string | undefined;
};

class Component extends React.Component<Props, PatientComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: "",
      collapsed: false,
      assignPatientsConfig: {
        collapsed: false,
      },
      careTeamTabConfig: {
        collapsed: false,
      },
      userModels: [],
      reload: false,
      userName: "",
    };
  }

  async componentDidMount() {
    this.updateUserState();
  }

  async updateUserState(): Promise<void> {
    const userModels: UserModel[] = await this.getUsers();
    this.setState((previousState, props) => ({
      ...previousState,
      userModels,
    }));
  }

  getPracticeModels = async (): Promise<PracticeModel[]> => {
    if (!this.props.selectedOrganization) {
      return [];
    }
    const practiceCollection: ModelCollection<PracticeModel, Practice> =
      PracticeModel.makePracticeCollection();
    practiceCollection.withParams({
      organizationIds: [this.props.selectedOrganization.id],
    });
    const iterator: Iterator<PracticeModel, Practice> =
      await practiceCollection.getMany();
    const allPracticeModels: PracticeModel[] = await iterator.getAll();

    return allPracticeModels;
  };

  getUsers = async (): Promise<UserModel[]> => {
    const organizationUserCollection: ModelCollection<UserModel, User> =
      UserModel.makeUserCollection();
    organizationUserCollection.withParams({
      organizationIds: [this.props.selectedOrganization._meta?.id],
    });
    const organizationUserCollectionIterator: Iterator<UserModel, User> =
      await organizationUserCollection.getMany();
    const allOrganizationUserModel =
      await organizationUserCollectionIterator.getAll();

    const practiceUserCollection: ModelCollection<UserModel, User> =
      UserModel.makeUserCollection();
    practiceUserCollection.withParams({
      practiceIds: (await this.getPracticeModels()).map(
        (practiceModel: PracticeModel) => practiceModel.pluck("_meta")?.id
      ),
    });
    const practiceUserCollectionIterator: Iterator<UserModel, User> =
      await practiceUserCollection.getMany();
    const allPracticeUserModel = await practiceUserCollectionIterator.getAll();

    const allUserModel = allPracticeUserModel.concat(allOrganizationUserModel);
    const allUsers = await Promise.all(
      allUserModel.map(async (userModel: UserModel) => {
        const patientCollection = await PatientModel.makePatientCollection();
        patientCollection.withParams({
          assignedUserIds: [userModel.pluck("_meta")?.id],
        });

        const iterator: Iterator<PatientModel, Patient> =
          await patientCollection.getMany();
        const allPatientModel: PatientModel[] = await iterator.getAll();
        userModel.setValues("assignedPatientCount")(allPatientModel.length);

        return userModel;
      })
    );

    return allUsers.sort((a: UserModel, b: UserModel) => {
      return a.pluck("lastName").toUpperCase() >
        b.pluck("lastName").toUpperCase()
        ? 1
        : -1;
    });
  };

  renderHeaderButton = () => (
    <div className="right-text">
      {
        <div className="dropdown">
          <div className="row form-inline">
            <div className="col">
              <BasicMultiSelect
                data={this.state.userModels.map((userModel: UserModel) => {
                  return {
                    label: `${userModel.pluck(
                      "assignedPatientCount"
                    )} ${userModel.pluck("lastName")}, ${userModel.pluck(
                      "firstName"
                    )} ${
                      userModel.pluck("credentials")
                        ? `(${userModel.pluck("credentials")})`
                        : ""
                    }`,
                    value: `${userModel.pluck("_meta")?.id}`,
                  };
                })}
                onChange={async (value: string) => {
                  this.setState((previousState) => ({
                    ...previousState,
                    userName: value,
                  }));
                }}
                findValueBy={this.state.userName}
                isSearchable={true}
                placeholder="Assign user"
                onSelect={async (value: string) => {
                  // get all selectedAssignmentPatients, loop over them and assign this user to each of them
                  await Promise.all(
                    this.props.selectedAssignmentPatients.map(
                      async (patient: Patient) => {
                        const patientModel = PatientModel.make(patient);
                        await patientModel.modifyPatient<Patient, "userId">(
                          { userId: value as string },
                          PatientEndpoints.AssignUser
                        );
                      }
                    )
                  );
                  this.setState((previousState) => ({
                    ...previousState,
                    reload: !previousState.reload,
                  }));
                  this.setState((previousState) => ({
                    ...previousState,
                    userName: undefined,
                  }));
                }}
              />
            </div>
            <div className="col">
              <Button
                appearance="ghost"
                onClick={async () => {
                  await Promise.all(
                    this.props.selectedAssignmentPatients.map(
                      async (patient: Patient) => {
                        const patientModel = PatientModel.make(patient);
                        await patientModel.modifyPatient<Patient, "userId">(
                          { userId: null },
                          PatientEndpoints.AssignUser
                        );
                      }
                    )
                  );
                  this.setState((previousState) => ({
                    ...previousState,
                    reload: !previousState.reload,
                  }));
                }}
              >
                <i className="fa fa-user-times" aria-hidden="true" />
                Unassign
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );

  render() {
    return (
      <div className="modal-body">
        <div className="subject-scroll">
          <div className="card-body" style={{ paddingTop: "0px" }}>
            <div id="patient-list" className="collapse show">
              <PatientsTable
                reload={this.state.reload}
                hidePatient={false}
                togglePatient={() => {}}
                tableType={"assignment"}
                displayName={"Patient Assignment"}
                collapsible={false}
                headerButton={this.renderHeaderButton()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  patient: {
    assignmentPatientFilters: PatientFilters;
    assignmentPatients: Patient[];
    selectedAssignmentPatients: Patient[];
  };
  organization: { selectedOrganization: Organization };
}) => {
  return {
    selectedAssignmentPatients: state.patient.selectedAssignmentPatients,
    assignmentPatients: state.patient.assignmentPatients,
    selectedOrganization: state.organization.selectedOrganization,
    assignmentPatientFilters: state.patient.assignmentPatientFilters,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {};
};

export const PatientAssignment = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
