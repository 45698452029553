import React, { ChangeEvent } from "react";

import { Address, CountryCodeOptions } from "@/library/types/address";
import { SelectOption } from "@/types";
import { Select } from "@/components/_selects/Select";
import { TextInput } from "@/components/_inputs/TextInput";

import styles from "./styles.module.scss";

export type Props = {
  street: string[];
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  onChange: (key: keyof Address, value: string, index?: number) => void;
};

export const AddressInputs: React.FC<Props> = (props) => {
  const { street, locality, region, postalCode, country, onChange } = props;

  const handleInputChange =
    (key: keyof Address, index?: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(key, event.target.value, index);
    };

  const handleSelectChange =
    (key: keyof Address) => (option: SelectOption | unknown) => {
      onChange(key, (option as SelectOption).value);
    };

  const selectedCountryOption = CountryCodeOptions.find(
    (option) => option.value === country
  );

  return (
    <div className={styles.addressInputs}>
      <TextInput
        name="street"
        value={street[0]}
        label="Street Address"
        onChange={handleInputChange("street", 0)}
        containerClassName={styles.input}
      />
      <TextInput
        name="street"
        value={street[1]}
        label="Apt, suite, etc (optional)"
        onChange={handleInputChange("street", 1)}
        containerClassName={styles.input}
      />
      <TextInput
        name="locality"
        value={locality}
        label="City"
        onChange={handleInputChange("locality")}
        containerClassName={styles.input}
      />
      <TextInput
        name="region"
        value={region}
        label="State"
        onChange={handleInputChange("region")}
        containerClassName={styles.input}
      />
      <TextInput
        name="postalCode"
        value={postalCode}
        label="Zip / postcode"
        onChange={handleInputChange("postalCode")}
        containerClassName={styles.input}
      />
      <Select
        label="Country"
        name="country"
        options={CountryCodeOptions}
        value={selectedCountryOption}
        placeholder="Select country"
        onChange={handleSelectChange("country")}
      />
    </div>
  );
};
