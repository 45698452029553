export const DATE_FORMAT = "MM-dd-yyyy";
export const DATE_FORMAT_ = "MM/dd/yyyy";
export const DATE_AND_TIME_FORMAT = "MM-dd-yyyy HH:mm:ss";
export const DATE_TIME_HOUR_MIN_FORMAT = "MM-dd-yyyy HH:mm";

/**
 * Time formats
 */
export const TWELVE_HOUR_FORMAT = 12;
export const TWENTY_FOUR_HOUR_FORMAT = 24;

/**
 * Rsuite Date picker formats
 */
export const RSUITE_TWELVE_HOUR_FORMAT = "MM-dd-yyyy HH:mm:ss";

/**
 * Time conversions
 */
export const MILLISECONDS_PER_MINUTE = 60000;

export const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
