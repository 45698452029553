import {
  Attributes,
  deserialize,
  Model,
  ModelCollection,
} from "../../../library/model";
import { RemoteCareAPISync } from "../../../library/core/api";
import { EventEmitter } from "../../../library/core/event";
import { MODEL_SYNCED } from "../../../library/constants";
import { Iterator } from "../../../library/iteration/iterator";
import { PatientDevice } from "./device.interface";
import { Http } from "@/library/core/api/http";

export class DeviceModel extends Model<PatientDevice> {
  static readonly path = "devices";
  /**
   * Makes a new instance of DeviceModel. It requires only the attributes
   * that would be used to instantiate new Device Model.
   *
   * @param attributes
   */
  static make(attributes: PatientDevice): DeviceModel {
    return new DeviceModel(
      new Attributes<PatientDevice>(attributes),
      new RemoteCareAPISync<PatientDevice>(DeviceModel.path),
      new EventEmitter()
    );
  }

  /**
   * Makes a list of devices using the provided resource url and a deserializer.
   * The entire devices list can be accessed in the models member of the Collection class.
   */
  static makeDevicesCollection(): ModelCollection<DeviceModel, PatientDevice> {
    return new ModelCollection<DeviceModel, PatientDevice>(
      DeviceModel.buildUrl(),
      (entity: PatientDevice) => deserialize(entity, DeviceModel.make)
    );
  }

  static buildUrl = (): string =>
    `${RemoteCareAPISync.host}/${DeviceModel.path}`;

  /**
   * Fetch patient devices by patient id
   *
   * @param id
   * @returns
   */
  static async fetchByPatientId(id: string): Promise<DeviceModel[]> {
    if (!id) {
      return new Promise((resolve) => resolve([]));
    }

    const deviceCollection = await this.makeDevicesCollection();
    deviceCollection.on(MODEL_SYNCED, () => {});
    deviceCollection.withParams({
      patientId: id,
      includeTagInfo: 1,
    });

    const iterator: Iterator<DeviceModel, PatientDevice> =
      await deviceCollection.getMany();
    return iterator.getAll();
  }
  /**
   * Sync device by Id.
   * @param id
   */
  static async sync(id: string): Promise<DeviceModel> {
    const device: PatientDevice = (
      await new RemoteCareAPISync<PatientDevice>(DeviceModel.path).fetch(id)
    ).data;
    return deserialize(device, DeviceModel.make);
  }

  /**
   * Sync Devices by Ids.
   *
   * @param id
   */
  static syncMany = async (ids: string[]): Promise<DeviceModel[]> =>
    RemoteCareAPISync.concurrently(ids, DeviceModel.path, DeviceModel.make);

  public async unassignedDevice() {
    await Http().post(
      `${RemoteCareAPISync.host}/devices/${
        this.pluck("_meta")?.id
      }/actions/assign-patient`,
      { patientId: null }
    );
  }

  public async assignDevice(patientId: string) {
    await Http().post(
      `${RemoteCareAPISync.host}/devices/${
        this.pluck("_meta")?.id
      }/actions/assign-patient`,
      { patientId }
    );
  }

  /**
   * Fetch patient devices by practice ids
   *
   * @param id
   * @returns
   */
  static async fetchByPracticeIds(ids: string[]): Promise<DeviceModel[]> {
    if (!ids || !ids.length) {
      return new Promise((resolve) => resolve([]));
    }

    const deviceCollection = await this.makeDevicesCollection();
    deviceCollection.on(MODEL_SYNCED, () => {});
    deviceCollection.withParams({
      practiceIds: ids,
      includeTagInfo: 1,
    });

    const iterator: Iterator<DeviceModel, PatientDevice> =
      await deviceCollection.getMany();
    return iterator.getAll();
  }

  get patientRemoteCareId() {
    return {
      remoteCareId: this.pluck("iccid") || "None",
      remoteCareNumber: this.pluck("msisdn") || "None",
    };
  }

  /**
   * Set tag on device
   * @param deviceId string
   * @param data { set?: string[]; delete?: string[] }
   */
  static async assignTags(
    deviceId: string,
    data: { set?: string[]; delete?: string[] }
  ) {
    await Http().post(
      `${RemoteCareAPISync.host}/${DeviceModel.path}/${deviceId}/actions/assign-tags`,
      data
    );
  }
}
