import { FormikValues } from "formik";

import {
  Rule,
  RuleOverrides,
  VariableDefault,
  VariableDefaultObject,
  VariableOverride,
} from "@/domain/rules/model/types";
import {
  OverrideValue,
  PostVariableOverride,
} from "@/domain/organization/model/types";
import { ParameterFormValues } from "./types";

/**
 * The form is first populated by these initial values
 */
export const makeInitialValues = (
  rule: Rule | null,
  overrides: RuleOverrides = {}
): ParameterFormValues => {
  const initialValues: FormikValues = {
    assessmentId: null,
  };

  const variableKeys = Object.keys(rule?.variables || {});

  for (let i = 0; i < variableKeys.length; i++) {
    const key = variableKeys[i] as keyof RuleOverrides;
    const variable = rule?.variables[key];
    if (!variable) continue;

    const override = overrides ? (overrides[key] as VariableOverride) : null;
    const ruleOverride: VariableDefault =
      override?.override !== null && override?.override !== undefined
        ? override?.override
        : override?.default;
    const isScalar =
      ruleOverride === null ||
      ["string", "number", "boolean", "undefined"].includes(
        typeof ruleOverride
      );

    if (isScalar) {
      initialValues[key] = ruleOverride || null;
    } else {
      const { high, medium } = ruleOverride as VariableDefaultObject;
      const { high: defaultHigh, medium: defaultMedium } =
        variable.default as VariableDefaultObject;

      initialValues[`${key}-high`] = high || defaultHigh || null;
      initialValues[`${key}-medium`] = medium || defaultMedium || null;
    }
  }

  return initialValues;
};

/**
 * Individual form inputs (scalars and thresholds) can be reset to these default values
 */
export const makeDefaultValues = (
  rule: Rule | null,
  overrides: RuleOverrides = {}
): ParameterFormValues => {
  const defaultValues: FormikValues = {
    assessmentId: null,
  };

  const variableKeys = Object.keys(rule?.variables || {});

  for (let i = 0; i < variableKeys.length; i++) {
    const key = variableKeys[i] as keyof RuleOverrides;
    const variable = rule?.variables[key];
    if (!variable) continue;

    const override = overrides ? (overrides[key] as VariableOverride) : null;
    const ruleDefault: VariableDefault = override?.default;
    const isScalar =
      ruleDefault === null ||
      ["string", "number", "boolean", "undefined"].includes(typeof ruleDefault);

    if (isScalar) {
      defaultValues[key] = ruleDefault;
    } else {
      const { high, medium } = ruleDefault as VariableDefaultObject;

      defaultValues[`${key}-high`] = high || null;
      defaultValues[`${key}-medium`] = medium || null;
    }
  }

  return defaultValues;
};

export const makePostVariables = (
  values: ParameterFormValues,
  scalarVariables: string[],
  variablesToUnset: string[],
  touched: Record<string, boolean>
): Record<string, OverrideValue> => {
  const { assessmentId = "" } = values;
  const variables: Record<string, OverrideValue> = {};

  const unwanted = ["assessmentId"];
  const keys = Object.keys(values).filter((key) => !unwanted.includes(key));

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const split = key.split("-");
    const keyId = split[0];
    const threshold: keyof PostVariableOverride =
      split[1] as keyof PostVariableOverride;

    if (variablesToUnset.includes(keyId) || !touched.hasOwnProperty(key))
      continue;

    const value = values[key as keyof ParameterFormValues];

    if (scalarVariables.includes(key)) {
      // Scalar vars
      variables[keyId] = (value !== null ? value : "") as OverrideValue;
    } else {
      // Threshold vars
      if (!variables.hasOwnProperty(keyId)) variables[keyId] = { assessmentId };

      (variables[keyId] as any)[threshold] = value;
    }
  }

  return variables;
};
