import { PatientModel } from "@/domain/patient/model";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import { CanMake } from "@/library/model/model.interface";
import { Deserializer, DeserializerStack } from "../deserializer";

export class PatientDeserializer extends Deserializer<Patient, PatientModel> {
  /**
   * Makes an instance of patient deserializer
   * @param patient
   * @param makeModel
   * @returns
   *
   */
  static for(patient: Patient, makeModel: CanMake<Patient, PatientModel>) {
    return new PatientDeserializer(patient, makeModel);
  }
  /**
   * Stack of syncronous deserializing functions.
   */
  protected deserializers: DeserializerStack<PatientModel> = {
    /**
     * @NOTE NEVER ADD ASYNC METHODS IN THE DESERIALIZER STACK.
     * USE PATIENT MIDDLEWARE FOR ASYNCRONOUS TASKS.
     *
     */
    programHistory: () => this.remodelProgramHistory(),
    healthObservation: () => this.remodePatientHealthObservation(),
  };

  /**
   * Convert all dates to local timezone of patient program history
   * @returns
   *
   */
  private remodelProgramHistory() {}

  private remodePatientHealthObservation() {
    const patientHealth: HealthSnapshot | undefined =
      this.entityModel.pluck("health");
    if (patientHealth) {
      const observations: any = patientHealth.observations;
      Object.keys(observations).forEach((observation) => {
        if (observations[observation]) {
          observations[observation].time = this.convertLocalTimezone(
            observations[observation].time
          );
        }
      });
      const remodelHealth = {
        ...patientHealth,
        observations,
      };

      this.setValue("health", remodelHealth);
    }
  }
}
