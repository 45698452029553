import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "rsuite";

import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Notification } from "@/components/notification/notification";
import { SUCCESS } from "@/library/constants";
import { PatientModel } from "@/domain/patient/model";
import { PatientPriority, Patient } from "@/domain/patient/model/types";
import { RootState } from "@/types";
import { dispatchSetPatients } from "@/domain/patient/redux/actions";

interface Props {
  patient: Patient;
  updatePatientDetailState: (patient: Partial<Patient>) => void;
}

export const RiskLevel = (props: Props) => {
  const { patient } = props;

  const patients = useSelector((state: RootState) => state.patient.patients);
  const dispatch = useDispatch();

  const updatePatientRiskLevel =
    (priority: PatientPriority | "NONE") => async () => {
      LoadingIndicator.fire.show();
      try {
        const patientPriorty = priority === "NONE" ? null : priority;
        await PatientModel.make(patient).modifyPatient<Patient, "priority">({
          priority: patientPriorty as string,
        });
        await updateReduxState(patientPriorty);
        Notification.notify(SUCCESS, `Risk level updated successfully. `);
      } catch (e) {}

      LoadingIndicator.fire.hide();
    };

  const updateReduxState = async (priority: PatientPriority | null) => {
    const patientToBeEdited = patients.find(
      (entity: any) => entity.id === patient.id
    );
    const editedPatient = { ...patientToBeEdited, ...{ priority } };

    props.updatePatientDetailState({
      ...patient,
      priority: priority as string,
    });
    const updated = patients.map((patient: Patient) => {
      return patient.id === editedPatient.id ? editedPatient : patient;
    });
    dispatch(dispatchSetPatients(updated as Patient[]));
  };

  return (
    <>
      <Dropdown.Item onSelect={updatePatientRiskLevel(PatientPriority.High)}>
        High
      </Dropdown.Item>
      <Dropdown.Item onSelect={updatePatientRiskLevel(PatientPriority.Medium)}>
        Medium
      </Dropdown.Item>
      <Dropdown.Item onSelect={updatePatientRiskLevel(PatientPriority.Low)}>
        Low
      </Dropdown.Item>
      <Dropdown.Item onSelect={updatePatientRiskLevel(PatientPriority.None)}>
        None
      </Dropdown.Item>
    </>
  );
};
