import React, { useEffect } from "react";
import { Modal } from "../../../components/modal";
import { UserTable } from "../view/table";
import "../user.styles.css";
import { User, UserActions, UserModel, UserParentTypes } from "../model";
import { connect } from "react-redux";
import {
  setUsersPaginationConfig,
  setUserPageLimit,
  setUsersSearchFilters,
  setUsersSortedState,
} from "../redux";
import { FILTER_STRING, MODEL_SYNCED } from "../../../library/constants";
import {
  OrganizationType,
  PaginationConfig,
  UserQuery,
  UsersFilter,
} from "../../../library/types";
import { UserForm } from "./form";
import { Header } from "../../../components/Header";
import { DropdownButton } from "../../../components/_buttons/DropdownButton";
import { ModelCollection } from "../../../library/model";
import { RCAResourceActions } from "../../../library/core/config/actions";
import { setUsersThunk } from "../redux/user.middleware";
import { OrganizationModel } from "../../organization/model";
import { PracticeModel } from "../../practice/model";
import { SetUserDispatch } from "../model/types";
import { UsersSortedState } from "../../../library/types/userState";
import { SortQuery } from "../../../components/table/head/sortable";
import { LoadingIndicator } from "../../../components/loadingIndicator/loadingIndicator";
import { Organization } from "../../organization/model/types";
import { dispatchSelectOrganization } from "../../organization/redux/actions";
import { Guard } from "../../../library/guard/Guard";
import { EnableDisableUserComponent } from "./enable-disable-user.component";
import headerStyles from "@/components/Header/styles.module.scss";

type UserProps = {
  users: User[];
  userPageLimit: number;
  paginationConfig: PaginationConfig;
  usersFilter: UsersFilter;

  setUsers: (
    users: UserModel[]
  ) => (dispatch: SetUserDispatch) => Promise<void>;
  setUsersPaginationConfig: (paginationConfig: PaginationConfig) => {
    type: string;
    payload: PaginationConfig;
  };
  setUserPageLimit: (orgPageLimit: number) => { type: string; payload: number };
  setUsersSearchFilters: (payload: {}) => {
    type: string;
    payload: UsersFilter;
  };
  displayName: string;

  loggedInUser: User;
  sortedState: UsersSortedState;
  setUsersSortedState: (usersSortedState: UsersSortedState) => {
    type: string;
    payload: UsersSortedState;
  };
  selectedOrganization: Organization;
  selectOrganization: (organization: Organization | undefined) => {
    type: string;
    payload: Organization;
  };
};

type UserComponentState = {
  search: string;

  userModalConfig: {
    show: boolean;
    title: string;
    modalButton: string;
    userAction?: UserActions | undefined;
    resourceAction: RCAResourceActions | undefined;
    defaultOrganizationIds: string[];
    defaultPracticeIds: string[];
    targetUser: UserModel | null;
  };
  enableDisableUserModalConfig: {
    show: boolean;
    title: string;
    selectedUser: User | undefined;
    content: string;
  };
  reset: boolean;
  formik: any;
  onSubmitForm: () => void;
};

class Component extends React.Component<UserProps, UserComponentState> {
  constructor(props: UserProps) {
    super(props);
    this.state = {
      search: "",

      userModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        userAction: undefined,
        resourceAction: undefined,
        defaultOrganizationIds: [],
        defaultPracticeIds: [],
        targetUser: null,
      },

      enableDisableUserModalConfig: {
        show: false,
        title: "",
        selectedUser: undefined,
        content: "",
      },

      formik: undefined,
      onSubmitForm: () => {},
      reset: false,
    };
  }

  componentDidMount = async () => {
    const params = new URLSearchParams(window.location.search);
    const organizationId = params.get("organizationId");
    const path = window.location.pathname;
    if (!this.props.selectedOrganization && organizationId) {
      //Syncing the organization if there is organization ID exist in query params and set it as selected organization.
      window.history.replaceState(
        null,
        ``,
        `${path}?organizationId=${organizationId}`
      );
      const orgModel: OrganizationModel = await OrganizationModel.sync(
        organizationId
      );
      await this.props.selectOrganization!(orgModel.pluckAll());
    }
    await this.execSearch({ limit: this.props.userPageLimit });
  };

  setPageLimit = async (limit: number) => {
    await this.props.setUserPageLimit(limit);
    this.execSearch({});
  };

  calculateUserType = async (
    id: string
  ): Promise<{
    userType: string | undefined;
    practiceIds: string[] | undefined;
    organizationIds: string[] | undefined;
    classifications: string[] | undefined;
  }> => {
    let organizationModel: OrganizationModel | undefined;
    let practiceModel: PracticeModel | undefined;
    try {
      if (id) organizationModel = await OrganizationModel.sync(id);
      if (id) practiceModel = await PracticeModel.sync(id);
    } catch (e) {}

    if (practiceModel) {
      return {
        classifications: undefined,
        userType: "Practice",
        practiceIds: [id],
        organizationIds: undefined,
      };
    }

    if (organizationModel && organizationModel.pluck("type") === "Root") {
      return {
        classifications: ["Root"],
        userType: undefined,
        organizationIds: [id],
        practiceIds: undefined,
      };
    }

    return {
      classifications: Object.values(OrganizationType).filter(
        (type) => type !== OrganizationType.Root
      ),
      userType: "Organization",
      practiceIds: undefined,
      organizationIds: [id],
    };
  };

  execSearch = async (query: UserQuery) => {
    const { setUsers, sortedState } = this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });
    }

    LoadingIndicator.fire.show();

    let practiceIds: string[] = [];
    if (this.props.selectedOrganization) {
      const practices = await PracticeModel.fetchByOrganizationIds([
        this.props.selectedOrganization.id!,
      ]);
      practiceIds = practices.map(
        (practiceModel: PracticeModel) => practiceModel.pluck("id")!
      );
    }

    const userCollection: ModelCollection<UserModel, User> =
      UserModel.makeUserCollection();
    userCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    const paginationConfig: PaginationConfig = await userCollection.fetch({
      ...query,
      includeParentInfo: true,
      limit: this.props.userPageLimit,
      ...{ name: this.state.search },
      ...this.props.usersFilter,
      ...{ parentEntityId: undefined }, // delete parentEntityId from the query because it is no longer being supported by RCA API
      ...(this.props.usersFilter.userType === "Admin"
        ? { userType: undefined, classifications: ["Root"] }
        : {}),
      ...(this.props.usersFilter.userType === "Practice"
        ? {
            practiceIds: this.props.usersFilter.parentEntityId
              ? [this.props.usersFilter.parentEntityId]
              : undefined,
          }
        : {}),
      ...(this.props.usersFilter.userType === "Organization"
        ? {
            classifications: Object.values(OrganizationType).filter(
              (type) => type !== OrganizationType.Root
            ),
            organizationIds: this.props.usersFilter.parentEntityId
              ? [this.props.usersFilter.parentEntityId]
              : undefined,
          }
        : {}),
      ...(!this.props.usersFilter.userType &&
      !!this.props.usersFilter.parentEntityId
        ? await this.calculateUserType(this.props.usersFilter.parentEntityId)
        : {}),
      ...sortedState,
      ...(this.props.selectedOrganization
        ? { organizationIds: [this.props.selectedOrganization.id] }
        : undefined),
      ...(practiceIds.length > 0 && !this.props.usersFilter.parentEntityId
        ? { practiceIds }
        : undefined),
    });
    setUsers(userCollection.container);

    await this.props.setUsersPaginationConfig(paginationConfig);
    userCollection.trigger(MODEL_SYNCED);
  };

  onModalClose = () =>
    this.setState({
      userModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
        defaultOrganizationIds: [],
        defaultPracticeIds: [],
        targetUser: null,
      },
    });

  onEnableDisableUserModalClose = async () => {
    this.setState({
      reset: true,
      enableDisableUserModalConfig: {
        title: "",
        selectedUser: undefined,
        content: "",
        show: false,
      },
    });
  };

  onAddUser = (title: UserActions) => {
    this.setState({
      userModalConfig: {
        show: true,
        title: `Create ${title}`,
        modalButton: "Create",
        userAction: title,
        resourceAction: RCAResourceActions.Create,
        defaultOrganizationIds: [],
        defaultPracticeIds: [],
        targetUser: null,
      },
    });
  };

  onEditUser = (
    title: UserActions,
    defaultOrganizationIds: string[],
    defaultPracticeIds: string[],
    syncedUser: UserModel
  ) => {
    this.setState({
      userModalConfig: {
        show: true,
        title: "Edit User",
        userAction: title,
        modalButton: "Save Changes",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
        defaultOrganizationIds: defaultOrganizationIds,
        defaultPracticeIds: defaultPracticeIds,
        targetUser: syncedUser,
      },
    });
  };

  onEnableDisableUser = (
    title: string,
    selectedUser: User | undefined,
    content: string
  ) => {
    this.setState({
      enableDisableUserModalConfig: {
        show: true,
        title,
        selectedUser,
        content,
      },
    });
  };

  currentUser(): UserModel {
    return UserModel.make(this.props.loggedInUser);
  }

  setFormik = (formik: any) => this.setState({ formik: formik });

  render() {
    const { users } = this.props;

    const initialValues = {
      id: undefined,
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      organization: "",
      medical_credential: "",
      facility: "",
      notifications: {
        isEmailEnabled: false,
        isSmsEnabled: false,
        notifyOnCompliance: false,
      },
      phone: "",
    };

    return (
      <>
        <Header
          isNav={true}
          entity={{
            name: "user",
            displayName: this.props.displayName,
            selectedEntityLength: this.props.users.length,
            totalEntityLength: this.props.paginationConfig.totalResources,
          }}
          button={{
            create: {},
            toggleCollapse: {
              onToggle: () => {},
              collapsed: false,
            },
          }}
          table={{ pageLimit: this.props.userPageLimit }}
          search={{
            onSearch: this.execSearch,
            searchStr: this.state.search,
            filterStr: FILTER_STRING,
            filters: {
              userType: undefined,
              parentEntityId: undefined,
              isEnabled: undefined,
            },
            sortedState: {
              direction: undefined,
              sortBy: undefined,
            } as SortQuery,
          }}
          filter={{
            onResetState: (newState: {}) => {
              this.setState(newState);
              this.setState({ reset: !this.state.reset });
            },
            onResetFilters: this.props.setUsersSearchFilters,
            onResetSortedState: this.props.setUsersSortedState,
          }}
          organizationFilter={{
            enabled: Guard.accessLevel(UserParentTypes.Admin).canActivate(
              this.props.loggedInUser
            ), //Enbales for admin users only
            onSelectOrganization: async (organizationId: string) => {
              if (!organizationId || organizationId === "") {
                window.history.replaceState(null, ``, `/user-management`);
                await this.props.selectOrganization(undefined);
              } else {
                window.history.replaceState(
                  null,
                  ``,
                  `/user-management?organizationId=${organizationId}`
                );
                const orgModel: OrganizationModel =
                  await OrganizationModel.sync(organizationId);
                await this.props.selectOrganization(orgModel.pluckAll());
              }
              this.props.setUsersSearchFilters({
                userType: undefined,
                parentEntityId: undefined,
                isEnabled: undefined,
              });
              await this.execSearch({});
            },
            selectedOrganization: this.props.selectedOrganization,
          }}
        >
          <div className="col">
            <div
              className={headerStyles.col}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <DropdownButton
                placement={"leftStart"}
                title="Create User"
                trigger={["click"]}
                onSelect={(eventKey: any) => this.onAddUser(eventKey)}
                items={[
                  UserActions.CreatePracticeUser,
                  UserActions.CreateOrganizationUser,
                  UserActions.CreateAdminUser,
                ].filter(this.currentUser().canPerform)}
                style={{ width: "auto" }}
              />
            </div>
          </div>
        </Header>
        <div className="card-body">
          <div id="user-list" className="collapse show">
            <UserTable
              reset={this.state.reset}
              onPageLimitChange={this.setPageLimit}
              data={users}
              execSearch={this.execSearch}
              onEnableDisableUser={(
                title: string,
                user: User | undefined,
                content: string
              ) => this.onEnableDisableUser(title, user, content)}
              onEditUser={this.onEditUser}
            />
            <Modal
              handleClose={this.onModalClose}
              show={this.state.userModalConfig.show}
              heading={this.state.userModalConfig.title}
              button={this.state.userModalConfig.modalButton}
              actionButton={{
                formik: this.state.formik,
                name: this.state.userModalConfig.modalButton,
              }}
            >
              {this.state.userModalConfig.show && (
                <UserForm
                  userAction={this.state.userModalConfig.userAction}
                  execSearch={this.execSearch}
                  button={this.state.userModalConfig.modalButton}
                  handleClose={this.onModalClose}
                  onSetFormik={(formik: any) =>
                    useEffect(() => this.setFormik(formik), [])
                  }
                  resourceAction={
                    this.state.userModalConfig
                      .resourceAction as RCAResourceActions
                  }
                  defaultOrganizationIds={
                    this.state.userModalConfig.defaultOrganizationIds
                  }
                  defaultPracticeIds={
                    this.state.userModalConfig.defaultPracticeIds
                  }
                  targetUser={this.state.userModalConfig.targetUser}
                />
              )}
            </Modal>
            <Modal
              handleClose={this.onEnableDisableUserModalClose}
              show={this.state.enableDisableUserModalConfig.show}
              heading={this.state.enableDisableUserModalConfig.title}
              button={""}
              size="lg"
            >
              {this.state.enableDisableUserModalConfig.show && (
                <EnableDisableUserComponent
                  selectedUser={
                    this.state.enableDisableUserModalConfig.selectedUser
                  }
                  modalContent={this.state.enableDisableUserModalConfig.content}
                  onModalClose={this.onEnableDisableUserModalClose}
                />
              )}
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setUsers: (
      users: UserModel[]
    ): ((dispatch: SetUserDispatch) => Promise<void>) =>
      dispatch(setUsersThunk(users)),
    setUsersSearchFilters: (payload: any) =>
      dispatch(setUsersSearchFilters(payload)),
    setUserPageLimit: (pageLimit: number): { type: string; payload: number } =>
      dispatch(setUserPageLimit(pageLimit)),
    setUsersPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setUsersPaginationConfig(paginationConfig)),
    setUsersSortedState: (usersSortedState: UsersSortedState) =>
      dispatch(setUsersSortedState(usersSortedState)),
    selectOrganization: (organization: Organization | undefined): any =>
      dispatch(dispatchSelectOrganization(organization)),
  };
};

const mapStateToProps = (state: {
  user: {
    users: User[];
    currentUser: User;
    filters: UsersFilter;
    paginationConfig: PaginationConfig;
    userPageLimit: number;
    sortedState: UsersSortedState;
  };
  organization: { selectedOrganization: Organization };
}) => {
  return {
    users: state.user.users,
    loggedInUser: state.user.currentUser,
    paginationConfig: state.user.paginationConfig,
    userPageLimit: state.user.userPageLimit,
    usersFilter: state.user.filters,
    sortedState: state.user.sortedState,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

export const UserManagementComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
