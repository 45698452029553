import React from "react";
import cx from "clsx";

import { PaginationConfig } from "@/library/types";
import Paginate from "./pagination";
import styles from "./styles.module.scss";

export type Props = {
  header?: React.ReactNode;
  data: any[];
  onNavigate: (query: {}) => any;
  onPageLimitChange?: (limit: number) => void;
  pageLimit: number;
  paginationConfig: PaginationConfig;
  resetSelections?: () => void;
  isNonScrollable?: boolean;
  containerClassName?: string;
  tableClassName?: string;
};

export const Table: React.FC<Props> = (props) => {
  const { header, data, isNonScrollable, containerClassName, tableClassName } =
    props;

  return (
    <div className={cx(styles.tableContainer, containerClassName)}>
      <div className="card-body p-0">
        <div className={isNonScrollable ? "table" : "table-responsive"}>
          <div style={{ position: "relative" }}>
            <table
              style={{ border: "0" }}
              className={cx(
                `table-sm table-striped table-bordered table-light table-hover`,
                styles.table,
                tableClassName
              )}
            >
              <thead className="thead">
                <tr>{header}</tr>
              </thead>
              <tbody>{data}</tbody>
            </table>
          </div>
        </div>
      </div>

      <Paginate
        data={data}
        onNavigate={props.onNavigate}
        pageLimit={props.pageLimit}
        paginationConfig={props.paginationConfig}
        onPageLimitChange={props.onPageLimitChange}
      />
    </div>
  );
};
