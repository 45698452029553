import { Credential } from "../../../library/types/credential";
import { Practice } from "../../practice/model/types";
import {
  HeaderContent,
  SortedState,
  SortBy,
} from "@/components/table/head/sortable";
import { RuleOverrides } from "@/domain/rules/model/types";

export const ProviderTableHeader: HeaderContent[] = [
  {
    name: "first name",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.FirstName,
  },
  {
    name: "last name",
    isSortable: true,
    sortedState: SortedState.NoSort,
    sortBy: SortBy.LastName,
  },
  {
    name: "credentials",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "npi",
    isSortable: true,
    sortedState: SortedState.NoSort,
  },
  {
    name: "entity / branch",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "thresholds",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "patients",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
  {
    name: "actions",
    isSortable: false,
    sortedState: SortedState.NoSort,
  },
];

export interface Provider {
  id?: string | undefined;
  firstName: string;
  lastName: string;
  npi?: string | null;
  ptan?: string | null;
  practiceId?: string;
  practice: Practice;
  patients?: number;
  patientCount?: number;
  isEnabled?: boolean;
  credentials: Credential["abbreviation"];
  thresholds?: string;
  _meta: { id: string };
  specialty?: string | null;
  phoneNumber?: string | null;
  ruleVariables?: Record<string, RuleOverrides>;
}

export interface ProviderForm {
  id?: string | undefined;
  firstName: string;
  lastName: string;
  credentials: Credential["abbreviation"];
  npi?: string | null;
  practiceId?: string;
  specialty?: string | null;
  patients?: number;
  practiceName?: string;
  phoneNumber?: string | null;
}

export interface ProviderFilters {
  isEnabled: string | undefined;
}

export interface ProviderOptions {
  label: string | null;
  value: string | undefined;
}
