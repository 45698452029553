import { extractphone } from "@/components/form";
import { OrganizationModel } from "@/domain/organization/model";
import {
  Organization,
  OrganizationForm,
} from "@/domain/organization/model/types";
import { OrganizationType } from "../../types";
import { Serializeable } from "../serializer";

/**
 * Serializer for modifying Organization basic info.
 */
export class ModifyBasicInfoSerializer
  implements Serializeable<OrganizationModel>
{
  constructor(protected readonly formData: OrganizationForm) {}

  serialize(): OrganizationModel {
    return OrganizationModel.make({
      id: this.formData.id,
      ...(this.formData.type !== OrganizationType.Root
        ? { type: this.formData.type as OrganizationType }
        : undefined),
      name: this.formData.name,
      address: {
        street: [this.formData.address],
        locality: this.formData.city,
        region: this.formData.state,
        postalCode: this.formData.zip,
        country: this.formData.country,
      },
      contact: {
        name: this.formData.contactName,
        position: this.formData.contactPosition || null,
        email: this.formData.contactEmail || null,
        phone: extractphone(this.formData.contactPhone),
      },
    } as Organization);
  }
}
