import { FC } from "react";
import { ListItem, ListItemText } from "@mui/material";

export interface PatientInfoProp {
  name: string;
  gender: "Male" | "Female";
  age: number;
}
export const PatientInfo: FC<PatientInfoProp> = ({ name, gender, age }) => {
  return (
    <ListItem disablePadding>
      <ListItemText primary={name} secondary={`${gender || " "}  ${age} y.o`} />
    </ListItem>
  );
};
