import {
  Attributes,
  deserialize,
  Model,
  ModelCollection,
} from "@/library/model";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { EventEmitter } from "@/library/core/event";
import { PostTag, Tag } from "./types";
import { PaginationConfig } from "@/library/types";
import { MODEL_SYNCED } from "@/library/constants";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Iterator } from "@/library/iteration";

export class TagModel extends Model<Tag> {
  static readonly path = "tags";

  static make(attributes: Tag): TagModel {
    return new TagModel(
      new Attributes<Tag>(attributes),
      new RemoteCareAPISync<Tag>(TagModel.path),
      new EventEmitter()
    );
  }

  static async getTagsByType(
    type: string,
    params = {}
  ): Promise<[Tag[], PaginationConfig]> {
    const response = await Http().get(
      `${RemoteCareAPISync.host}/${TagModel.path}`,
      {
        params: {
          type,
          ...params,
        },
      }
    );

    const totalResources = parseInt(response.headers["total-resources"]);
    const currentPage = parseInt(response.headers["current-page"]);
    const lastPage = parseInt(response.headers["last-page"]);

    const paginationConfig = {
      totalResources,
      currentPage,
      lastPage,
    };

    return [response.data, paginationConfig];
  }

  static async getByTagId(tagId: string): Promise<Tag[]> {
    const response = await Http().get(
      `${RemoteCareAPISync.host}/${TagModel.path}/${tagId}`
    );

    return response.data;
  }

  static async createLocationTag(
    tag: PostTag
  ): Promise<{ _self: Record<string, string> }> {
    const response = await Http().post(
      `${RemoteCareAPISync.host}/${TagModel.path}`,
      tag
    );

    return response.data;
  }

  static async updateLocationTag(
    tagId: string,
    tag: PostTag
  ): Promise<{ _self: Record<string, string> }> {
    const response = await Http().post(
      `${RemoteCareAPISync.host}/${TagModel.path}/${tagId}/actions/modify`,
      tag
    );

    return response.data;
  }

  static async assignDevices(
    tagId: string,
    data: { set?: string[]; delete?: string[] }
  ) {
    const response = await Http().post(
      `${RemoteCareAPISync.host}/${TagModel.path}/${tagId}/actions/assign-devices`,
      data
    );

    return response.data;
  }

  /**
   * Makes a list of tags using the provided resource url and a deserializer.
   * The entire tag list can be accessed in the models member of the Collection class.
   */
  static makeTagCollection(): ModelCollection<TagModel, Tag> {
    return new ModelCollection<TagModel, Tag>(
      `${RemoteCareAPISync.host}/${TagModel.path}`,
      (entity: Tag) => deserialize(entity, TagModel.make)
    );
  }

  static fetchAllTagsWithIterator = async (params: {}): Promise<Tag[]> => {
    const tagCollection: ModelCollection<TagModel, Tag> =
      TagModel.makeTagCollection();
    tagCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    tagCollection.withParams(params);

    const iterator: Iterator<TagModel, Tag> = await tagCollection.getMany();
    const allTags = await iterator.getAll();

    return allTags.map((t: TagModel) => t.pluckAll());
  };
}
