import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Patient, PatientFilters } from "@/domain/patient/model/types";
import { PaginationConfig } from "@/library/types";
import { Provider } from "@/domain/provider/model";
import {
  dispatchDeselectAllPatients,
  dispatchDeselectPatient,
  dispatchSelectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatientFilters,
  dispatchSetTargetPatient,
} from "@/domain/patient/redux/actions";
import { PatientId } from "@/domain/patient/redux/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { Table } from "@/components/table";
import { Model } from "@/library/model";
import { Organization } from "@/domain/organization/model/types";
import { Tooltip } from "@/components/Tooltip";
import {
  getAlertLevelBgColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import "./table.styles.css";
import { dateFormatter } from "@/pipes/date";
import { AgePipe } from "@/pipes/age";

type TableProps = {
  headers: (string | string[])[];
  data: any[];
  execSearch: (query: {}) => any;
  onPageLimitChange: (limit: number) => any;
  pageLimit: number;
  paginationConfig: PaginationConfig;
  selectedProviders: Provider[];
  selectedPatients: Patient[];
  selectedPractices: Practice[];
  selectedOrganization: Organization;
  filters: PatientFilters;

  selectPatient: (patient: Patient) => { type: string; payload: Patient };
  deselectPatient: (patient: Patient) => { type: string; payload: Patient };

  selectAllPatients: () => { type: string };
  deselectAllPatients: () => { type: string };
  patients: Patient[];
  setTargetPatient: (id: PatientId) => { type: string; payload: PatientId };
  onAssignPatient: () => void;
  onEditPatient: () => void;
  setPatientFilters: (patientFilter: PatientFilters) => {
    type: string;
    payload: PatientFilters;
  };
};
type PatientTableComponentState = {
  patientDetails: any[];
  show: boolean;
};
class Component extends React.Component<
  TableProps,
  PatientTableComponentState
> {
  constructor(props: TableProps) {
    super(props);
    this.state = {
      patientDetails: [],
      show: false,
    };
  }

  assignPatient = (targetPatient: PatientId) => {
    this.props.setTargetPatient(targetPatient);
    this.props.onAssignPatient();
  };

  EditPatient = (targetPatient: PatientId) => {
    this.props.setTargetPatient(targetPatient);
    this.props.onEditPatient();
  };

  onResetSelectedPatients = () => {
    this.props.deselectAllPatients();
  };

  onPatientModalClose = () => {
    this.setState({
      ...this.state,
      show: false,
    });
  };

  getPrimaryDX(condition: any) {
    const primaryDX = condition.filter(
      (dx: { type: string }) => dx.type === "PRIMARY"
    );
    return primaryDX[0] ? primaryDX[0].icd10.code : "";
  }

  fetchPractices = async () => {
    if (this.props.selectedOrganization && this.props.selectedOrganization.id) {
      const fetchedPractices = (
        await PracticeModel.fetchByOrganizationIds([
          this.props.selectedOrganization.id,
        ])
      ).map((model: Model<Practice>) => {
        return {
          value: model.pluck("id") as string,
          label: model.pluck("name"),
        };
      });
      this.setState((previousState) => ({
        ...previousState,
        practices: fetchedPractices,
      }));
    }
  };

  getObservationBgColor = (severityScore: number | undefined): string => {
    let className = "font-bold observation observation-td";
    const alertLevel = getSeverityAlertLevel(severityScore);
    className += ` ${getAlertLevelBgColor(alertLevel)}`;
    return className;
  };

  render() {
    const {
      headers,
      data,
      execSearch,
      onPageLimitChange,
      pageLimit,
      paginationConfig,
    } = this.props;
    const headerContents = [
      ...(headers || []).map((header: string | string[], key) => (
        <th key={key} className="bg-primary text-white">
          {Array.isArray(header) ? (
            header
              .filter((headings) => headings !== "observation")
              .map((h) => <div className="th-text">{h}</div>)
          ) : (
            <div className="th-text">{header}</div>
          )}
        </th>
      )),
    ];

    const dataContents = (data || [])
      .map((patient) => patient)
      .map((patient, key) => {
        return (
          <tr key={patient.id} className="td-text">
            <td>
              <Link to={`/patient/${patient.id}`}>
                {patient.demographics.legalName.lastName}
              </Link>
            </td>
            <td>
              <Link to={`/patient/${patient.id}`}>
                {patient.demographics.legalName.firstName}
              </Link>
            </td>
            <td>
              <span className="mr-1">{patient.demographics.dateOfBirth}</span>
              <span
                className={
                  patient.demographics.dateOfBirth ? "d-inline" : "d-none"
                }
              >
                (<AgePipe dob={patient.demographics.dateOfBirth} />)
              </span>
            </td>
            <td>{patient.demographics.gender}</td>
            <td></td>
            <td></td>
            <td>{patient.priority}</td>
            <td
              severity-score={
                patient.health?.observations.weight?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.weight?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.weight?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.weight?.observation.value?.toFixed(
                  1
                )}
              </Tooltip>
            </td>

            <td
              severity-score={
                patient.health?.observations.bloodPressure?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.bloodPressure?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.bloodPressure?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.bloodPressure
                  ? Math.trunc(
                      patient.health?.observations.bloodPressure.observation
                        .systolic?.value
                    ) +
                    "/" +
                    Math.trunc(
                      patient.health?.observations.bloodPressure.observation
                        .diastolic?.value
                    )
                  : ""}
              </Tooltip>
            </td>

            <td
              severity-score={
                patient.health?.observations.heartRate?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.heartRate?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.heartRate?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.heartRate?.observation.value
                  ? Math.trunc(
                      patient.health?.observations.heartRate?.observation.value
                    )
                  : ""}
              </Tooltip>
            </td>

            <td
              severity-score={
                patient.health?.observations.pulseOximetry?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.pulseOximetry?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.pulseOximetry?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.pulseOximetry?.observation.value
                  ? Math.trunc(
                      patient.health?.observations.pulseOximetry?.observation
                        .value
                    )
                  : ""}
              </Tooltip>
            </td>
            <td
              severity-score={
                patient.health?.observations.glucose?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.glucose?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.glucose?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.glucose?.observation.value
                  ? Math.trunc(
                      patient.health?.observations.glucose?.observation.value
                    )
                  : ""}
              </Tooltip>
            </td>
            <td
              severity-score={
                patient.health?.observations.temperature?.severityScore
              }
              className={this.getObservationBgColor(
                patient.health?.observations.temperature?.severityScore
              )}
            >
              <Tooltip
                tooltipContent={
                  <>
                    {dateFormatter({
                      date: patient.health?.observations.temperature?.time,
                      includeTime: true,
                      toLocalTimezone: { enabled: true },
                    })}
                  </>
                }
              >
                {patient.health?.observations.temperature?.observation.value?.toFixed(
                  1
                )}
              </Tooltip>
            </td>
          </tr>
        );
      });

    return (
      <Table
        paginationConfig={paginationConfig}
        pageLimit={pageLimit}
        onPageLimitChange={onPageLimitChange}
        header={headerContents}
        data={dataContents}
        onNavigate={execSearch}
        resetSelections={this.onResetSelectedPatients}
      />
    );
  }
}

const mapStateToProps = (state: {
  organization: { selectedOrganization: Organization };
  practice: { selectedPractices: Practice[] };
  provider: { selectedProviders: Provider[] };
  patient: {
    patients: Patient[];
    selectedPatients: Patient[];
    filters: PatientFilters;
  };
}) => {
  return {
    selectedPractices: state.practice.selectedPractices,
    selectedProviders: state.provider.selectedProviders,
    selectedPatients: state.patient.selectedPatients,
    patients: state.patient.patients,
    filters: state.patient.filters,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setTargetPatient: (id: PatientId): any =>
      dispatch(dispatchSetTargetPatient(id)),
    selectPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectPatient: (patient: Patient) =>
      dispatch(dispatchDeselectPatient(patient)),
    selectAllPatients: () => dispatch(dispatchSelectAllPatients()),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
    setPatientFilters: (patientFilter: PatientFilters) =>
      dispatch(dispatchSetPatientFilters(patientFilter)),
  };
};

export const PatientClinicalTableComponent = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
