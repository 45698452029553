import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { LeftPanel } from "@/components/virtualVisit/leftPanel";
import { RightPanel } from "@/components/virtualVisit/rightPanel";
import { connect } from "twilio-video";
import { TwilioError } from "twilio-video/tsdef/TwilioError";

export interface VirtualVisitCanvasProps {
  patientName: string;
  patientGender: "Male" | "Female";
  patientAge: number;
  onNoteChanged: (event: SyntheticEvent) => void;
  onLeaveCall: (isMultiParticipants: boolean) => Promise<void>;
  token: string;
  roomName: string;
  getElapsedTime?: (seconds: number) => void;
  getElapsedTimeOnReset?: (seconds: number) => void;
  onReset: number;
  onMultiParticipant: () => void;
  handleError: (error: TwilioError) => void;
}
export const VirtualVisitCanvas: FC<VirtualVisitCanvasProps> = ({
  patientName,
  patientGender,
  patientAge,
  onNoteChanged,
  onLeaveCall,
  token,
  roomName,
  onMultiParticipant,
  getElapsedTimeOnReset,
  getElapsedTime,
  onReset,
  handleError,
}) => {
  const [isMicMutted, setIsMicMutted] = useState(true);
  const [room, setRoom] = useState<any>(null);
  const [remoteParticipants, setRemoteParticipants] = useState<any[]>([]);
  const [isMultiParticipants, setIsMultiParticipants] = useState(false);
  const [jwt, setJwt] = useState(token);

  const leaveRoom = async () => {
    room?.disconnect();
    await onLeaveCall(isMultiParticipants);
    setRoom(null);
  };

  useEffect(() => {
    (async () => {
      try {
        // Request access to the user's camera and microphone
        const stream = await window.navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        // Stop all tracks to make sure the devices are available for Twilio
        stream.getTracks().forEach((track) => track.stop());
        const room = await connect(jwt, {
          name: roomName,
          audio: true,
          video: true,
        });
        setRoom(room);
        room.on("participantConnected", (participant: any) => {
          setRemoteParticipants((prevParticipants: any[]) => [
            ...prevParticipants,
            participant,
          ]);
        });

        room.on("participantDisconnected", (participant: any) => {
          setRemoteParticipants((prevParticipants: any) =>
            prevParticipants.filter(
              (p: any) => p.identity !== participant.identity
            )
          );
        });

        room.on("disconnected", (room, error) => {
          if (error) {
            handleError(error);
          }
        });
        room.participants.forEach((participant: any, key: string, map: any) => {
          setRemoteParticipants((prevParticipants: any) => [
            ...prevParticipants,
            participant,
          ]);
        });
      } catch (e) {
        handleError(e as unknown as TwilioError);
      }
    })();

    return () => {
      if (room) {
        room.removeAllListeners();
        room.disconnect();
      }
    };
  }, [jwt, roomName]);

  useEffect(() => {
    if (!!remoteParticipants.length) {
      onMultiParticipant();
    }
  }, [remoteParticipants]);

  const toggleMic = () => {
    setIsMicMutted(!isMicMutted);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        position: "absolute",
        width: "96.5vw",
        height: "95vh",
        boxShadow: "0px 0px 0px 1px #E0E0E0",
        borderRadius: "16px",
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LeftPanel
            isMicMutted={isMicMutted}
            toggleMic={toggleMic}
            isMultiParticipants={isMultiParticipants}
            room={room}
            remoteParticipants={remoteParticipants}
            onNoteChanged={onNoteChanged}
            patientName={patientName}
            patientGender={patientGender}
            patientAge={patientAge}
          />
        </Grid>
        <Grid item xs={9}>
          <Box>
            <RightPanel
              getElapsedTimeOnReset={getElapsedTimeOnReset}
              isMicMutted={isMicMutted}
              toggleMic={toggleMic}
              leave={leaveRoom}
              room={room}
              onReset={onReset}
              getElapsedTime={getElapsedTime}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
