import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import React, { Fragment, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {
  Assessment,
  AssessmentQuestion,
} from "@/domain/assessment/model/types";
import {
  renderComment,
  renderCreated,
} from "@/domain/patient/view/forms/AlertsOverview/helpers";

export function PathwayHistoryRow(props: { assessment: Assessment }) {
  const { assessment } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          {assessment.template?.name || "Not available"}
        </TableCell>
        <TableCell align="left">
          {renderCreated(assessment._meta?.created as string)}
        </TableCell>
        <TableCell align="left">
          {renderComment(
            assessment.questions
              .map(
                (q: AssessmentQuestion) => `${q.prompt}: ${q.answer.value}`
              )[0]
              ?.toString() || `-`
          )}
        </TableCell>
        {assessment.questions.length ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {assessment.questions && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Assessment Response
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Question</TableCell>
                      <TableCell align="right">Answer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(assessment.questions || []).map(
                      (question: AssessmentQuestion) => (
                        <TableRow key={question.id}>
                          <TableCell align="left">{question.prompt}</TableCell>
                          <TableCell align="right">
                            {question.answer.value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}
