// @ts-nocheck
import {
  ColumnDef,
  ColumnDefTemplate,
  ColumnHelper,
  Row,
} from "@tanstack/react-table";
import { DateTime } from "luxon";

import { ExpandIcon } from "../ExpandIcon";
import { AggregateData } from "./types";

export const makeExpanderColumn = (): ColumnDef<AggregateData> => ({
  id: "expander",
  header: () => null,
  cell: (props) => {
    const { row } = props;

    return <ExpandIcon showExpand={row.depth === 0} row={row} />;
  },
});

export const makeDateColumn = (columnHelper: ColumnHelper<AggregateData>) =>
  columnHelper.display({
    id: "label",
    header: () => "",
    minSize: 200,
    cell: (cell) => {
      const { row } = cell;
      const { original } = row;
      const { title } = original;
      if (!title) return "";
      const date = DateTime.fromISO(title as string);
      return (
        <span>
          <strong>{date.toFormat("M/d")}</strong>
        </span>
      );
    },
  });

export const makeLabelColumn = (
  columnHelper: ColumnHelper<AggregateData>,
  biometricOptionsToInclude: string[],
  renderCell: (str: string) => ColumnDefTemplate<any>
): any => {
  const columns = [];
  for (let i = 0; i < biometricOptionsToInclude.length; i++) {
    const bioOption = biometricOptionsToInclude[i] as string;
    const [reading, unit] = bioOption.split(" ");
    const col = columnHelper.accessor<string, any>(bioOption, {
      cell: renderCell(biometricOptionsToInclude[i]),
      header: () => (
        <>
          <span title={biometricOptionsToInclude[i]}>{reading}</span>
          <br />
          <span title={biometricOptionsToInclude[i]}>{unit}</span>
        </>
      ),
    });
    columns.push(col);
  }
  return columns;
};
