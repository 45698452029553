import React from "react";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import {
  PatientProgramState,
  StateDisplayName,
} from "@/domain/patient/view/form/manageProgram/types";

import styles from "./styles.module.scss";

interface Props {
  selectedStatus: string[];
  onChange: (
    program: string | undefined,
    description: string | undefined
  ) => void;
}

export const PatientStatusFilter: React.FC<Props> = (props) => {
  const { selectedStatus, onChange } = props;

  const data: { label: string; value: string | undefined }[] = [
    {
      label: "None",
      value: undefined,
    },
    ...Object.keys(PatientProgramState).map((status) => {
      return {
        label: StateDisplayName[status],
        value: status,
      };
    }),
  ];

  return (
    <>
      <BasicMultiSelect
        data={data}
        onChange={(selectedStatus: string) => {
          if (selectedStatus) {
            // BasicMultiSelect returns a string containing JSON for some values such as undefined.
            try {
              const selectedItem = data.find(
                (d) => d.value === selectedStatus
              ) ?? {
                label: undefined,
                value: undefined,
              };
              onChange(selectedItem.value, selectedItem?.label);
            } catch (e) {
              // If not json maintain original value; this will be the case for most drop-down options
              const description =
                data.find(
                  (patientStatusOption) =>
                    patientStatusOption.value === selectedStatus
                )?.label ?? "";
              onChange(selectedStatus, description);
            }
          }
        }}
        value={selectedStatus?.length ? selectedStatus[0] : ""}
        isSearchable={true}
        placeholder={"All Patient Status"}
        onOpen={() => undefined}
        onClear={() => {
          onChange(undefined, undefined);
        }}
        className={styles.select}
      />
    </>
  );
};
