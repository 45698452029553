import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";

export interface TabContainerProps {
  children?: ReactNode;
  index: number;
  value: number;
}
export const TabContainer: FC<TabContainerProps> = ({
  children,
  value,
  index,
}) => {
  return <div>{value === index && <Box>{children}</Box>}</div>;
};
