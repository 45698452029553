import { Organization } from "@/domain/organization/model/types";
import { PatientFilters } from "@/domain/patient/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { Practice } from "@/domain/practice/model/types";
import { MODEL_SYNCED } from "@/library/constants";
import { ModelCollection } from "@/library/model";
import { Iterator } from "@/library/iteration/iterator";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";

export const generateClinicalFilter = (
  filter: PatientFilters
): PatientFilters => {
  let patientFilter: PatientFilters = {};
  patientFilter = { ...filter };
  if (patientFilter.providerIds && patientFilter.providerIds.length > 0) {
    patientFilter.practiceIds = undefined;
  }
  if (patientFilter.practiceIds?.length === 0) {
    delete patientFilter.practiceIds;
  }
  if (patientFilter.providerIds?.length === 0) {
    delete patientFilter.providerIds;
  }
  if (patientFilter.programIds?.length === 0) {
    delete patientFilter.programIds;
  }
  return patientFilter;
};

export const getPracticeModels = async (
  selectedOrganization?: Organization
): Promise<PracticeModel[]> => {
  if (!selectedOrganization) {
    return [];
  }
  const practiceCollection: ModelCollection<PracticeModel, Practice> =
    PracticeModel.makePracticeCollection();
  practiceCollection.on(MODEL_SYNCED, () => {
    LoadingIndicator.fire.hide();
  });
  practiceCollection.withParams({
    organizationIds: [selectedOrganization?.id],
  });
  const iterator: Iterator<PracticeModel, Practice> =
    await practiceCollection.getMany();
  const allPracticeModels: PracticeModel[] = await iterator.getAll();

  return allPracticeModels;
};

export const getPracticeIds = async (): Promise<string[]> => {
  const allPracticeModels = await getPracticeModels();

  return allPracticeModels.map(
    (model: PracticeModel) => model.pluck("_meta")?.id as string
  );
};
